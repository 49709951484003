import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { UserCard } from '@conrati/ui';
import style from './style.module.scss';
import { ReactComponent as ShortRightArrow } from './assets/right-arrow.svg';
import { fetchTherapistDataFromProd } from './services/fetchTherapistProfileFromProd';

export default function RecommendedTherapists({ usernames }) {
  const [therapistsData, setTherapistsData] = useState([]);

  useEffect(() => {
    const requestPromises = usernames.map((username) => fetchTherapistDataFromProd(username));

    let isAborted = false;

    Promise.all(requestPromises)
      .then((data) => data.map((datum) => datum.result))
      .then((results) => {
        if (!isAborted) {
          setTherapistsData(results);
        }
      })
      .catch();

    return () => {
      isAborted = true;
    };
  }, [usernames]);

  /**
   * This element will hold whatever that's gonna be inside `therapistsContent`
   */
  let content;

  if (therapistsData.length > 0) {
    content = (
      <>
        <div className={style.therapistCards}>
          {therapistsData.map((profile, index) => {
            if (Array.isArray(profile)) {
                return <Fragment key={`fragment-${index}`} />;
            }

            return (
                <UserCard key={`recommended-${profile.userId}`} user={profile} />
              )
          })}
          <div className={style.therapistsFooter}>
            <Link
              to="/search?&rate=&category=&skills=industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor&languages="
              className={style.seeMore}
            >
              SEE MORE
              <div className={style.seeMoreIcon}>
                <ShortRightArrow />
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <div className={style.therapistCards}>
        <ContentLoader className={style.therapistPlaceholder} backgroundColor="#ccc" />
        <ContentLoader className={style.therapistPlaceholder} backgroundColor="#ccc" />
        <ContentLoader className={style.therapistPlaceholder} backgroundColor="#ccc" />
      </div>
    );
  }

  return (
    <div className={style.therapistsContainer}>
      <h1 className={style.therapistsHeading}>Recommended Therapists</h1>
      <div className={style.therapistsContent}>{content}</div>
    </div>
  );
}

RecommendedTherapists.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
