import moment from 'moment';
import {
  FETCH_USER,
  SWITCH_ACCOUNT,
  kycConfirm,
  FETCH_BUISNESS_DETAILS_USER,
  ACCOUNT_COMPLETE,
  REGISTER_USER,
  ERROR,
  LOG_OUT,
  SIGN_IN,
  USERTYPE_REGISTER,
  oauthData,
  FORGET_PASSWORD,
  EDIT_PROFILE,
  UPDATE_PROFILE,
  ONBOARDING_STEPS,
  UPDATE_ADVERT,
  NEW_REQUEST,
  USER_FORM_HANDLING,
  FETCH_IPINFO_DETAILS,
  NEW_ONGOING_BOOKING,
  CLEAR_ONGOING_BOOKING,
  HANDLE_OPT_IN,
  HANDLE_AR_ONCHANGE,
} from '../actions/types';

const initialState = {
  user: {
    currencyId: 0,
    business: {
      isSmallEnterprise: false,
      isVatRegistered: false,
    },
  },
  isLoggedIn: false,
  oauthData: {},
  upcomingSessions: [],
  buisnessDetails: {},
  accountCompleted: 0,
  stepsCompleted: false,
  onboardingStepsCompleted: 0,
  kycConfirm: false,
  analytics: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  },
  isLoading: false,
  isError: false,
  currency: {
    currencyCode: '',
    currencyName: '',
    currencySymbol: '',
  },
  country: {
    countryCode: '',
    countryName: '',
  },
  lastUpdatedCurrency: '',
  ongoingBooking: {},
  optinClicked: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IPINFO_DETAILS: {
      let { currency } = action.payload;
      let { country } = action.payload;

      if (state.isLoggedIn && state.user.currencyId > 0) {
        currency = state.user.currency;
        country = state.user.country;
      }

      return {
        ...state,
        currency,
        country,
        lastUpdatedCurrency: moment().unix(),
      };
    }
    case NEW_ONGOING_BOOKING:
      return {
        ...state,
        ongoingBooking: action.payload,
      };
    case CLEAR_ONGOING_BOOKING:
      return {
        ...state,
        ongoingBooking: {},
      };
    case FETCH_USER:
      return {
        ...state,
        users: action.payload,
      };
    case REGISTER_USER: {
      window.localStorage.setItem('app_status', true);

      // Intercom Config
      const { email, firstName, lastName, userId, primaryImg, personalDetailsCompleted } =
        action.payload.user;

      let dataObject = {
        email,
        user_id: userId,
        created_at: moment().unix(),
      };

      const userObject = {
        name: `${firstName} ${lastName}`,
        avatar: {
          type: 'avatar',
          image_url: primaryImg,
        },
      };

      if (personalDetailsCompleted) {
        dataObject = { ...dataObject, ...userObject };
      }

      window.Intercom('update', dataObject);

      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        isError: false,
      };
    }
    case HANDLE_AR_ONCHANGE: {
      const updateObject = {};

      if (action.payload.name === 'business') {
        updateObject[action.payload.name] = { ...state.user.business, ...action.payload.value };
      } else {
        updateObject[action.payload.name] = action.payload.value;
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...updateObject,
        },
      };
    }
    case SIGN_IN: {
      window.localStorage.setItem('app_status', true);

      // Intercom Config
      const { email, firstName, lastName, userId, primaryImg, personalDetailsCompleted } =
        action.payload.user;

      let dataObject = {
        email,
        user_id: userId,
        created_at: moment().unix(),
      };

      const userObject = {
        name: `${firstName} ${lastName}`,
        avatar: {
          type: 'avatar',
          image_url: primaryImg,
        },
      };

      if (personalDetailsCompleted) {
        dataObject = { ...dataObject, ...userObject };
      }

      window.Intercom('update', dataObject);

      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        isError: false,
      };
    }
    case LOG_OUT:
      window.localStorage.setItem('app_status', false);
      window.Intercom('shutdown');
      return {
        currency: state.currency,
        country: state.country,
        user: {
          currencyId: 0,
        },
        isLoggedIn: false,
        oauthData: {},
        upcomingSessions: [],
        buisnessDetails: {},
        accountCompleted: 0,
        stepsCompleted: false,
        onboardingStepsCompleted: 0,
        kycConfirm: false,
        analytics: {
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
        },
        isLoading: false,
        isError: false,
        lastUpdatedCurrency: '',
        ongoingBooking: {},
        optinClicked: false,
      };
    case SWITCH_ACCOUNT:
      if (state.accountCompleted === 5) {
        return {
          ...state,
          user: { ...state.user, ...action.payload.user },
          isLoggedIn: true,
          error: {},
          oauthData: {},
          upcomingSessions: [],
          buisnessDetails: {},
          stepsCompleted: true,
          accountCompleted: 5,
          onboardingStepsCompleted: 0,
          isLoading: false,
          isError: false,
        };
      }
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        isLoggedIn: true,
        error: {},
        oauthData: {},
        upcomingSessions: [],
        buisnessDetails: {},
        stepsCompleted: false,
        accountCompleted: 0,
        onboardingStepsCompleted: 0,
        isLoading: false,
        isError: false,
      };

    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case USERTYPE_REGISTER:
      return {
        ...state,
        user: action.payload.user,
      };
    case oauthData: {
      return {
        ...state,
        oauthData: action.payload.oauthData,
      };
    }
    case EDIT_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case FETCH_BUISNESS_DETAILS_USER:
      return {
        ...state,
        buisnessDetails: action.payload,
      };
    case ACCOUNT_COMPLETE:
      if (state.accountCompleted === 5) {
        return {
          ...state,
          accountCompleted: 5,
          stepsCompleted: true,
        };
      }
      return {
        ...state,
        accountCompleted: action.payload,
        stepsCompleted: action.payload >= 4 ? true : state.stepsCompleted,
      };

    case ONBOARDING_STEPS:
      return {
        ...state,
        onboardingStepsCompleted: action.payload,
      };
    case FORGET_PASSWORD: {
      return state;
    }
    case kycConfirm: {
      return {
        ...state,
        kycConfirm: action.payload,
      };
    }
    case UPDATE_ADVERT: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.payload,
        },
      };
    }
    case NEW_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case USER_FORM_HANDLING: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case HANDLE_OPT_IN: {
      return {
        ...state,
        optinClicked: action.payload,
      };
    }
    default:
      return state;
  }
};
