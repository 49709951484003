import React, { Component } from 'react';
import { OTPublisher, OTSubscriber } from 'opentok-react';
import cx from 'classnames';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import moment from 'moment';
import styles from './LiveCall.module.css';
import SessionHeader from '../../SessionHeader/SessionHeader';
import VideoToolbar from '../../toolbar/toolbar';
import enums from '../../../../config/enum';
import SessionCallTab from '../../SessionCallTab/SessionCallTab';
import SessionGrace from '../SessionGrace/SessionGrace';
import DisconnectCall from './DisconnectCall';
import { weburl, alert } from '../../../../utils';
import { monitorSession } from '../../../../services/userService';

const customStyles = {
  overlay: {
    zIndex: '1',
  },
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '130px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

Modal.setAppElement('#root');

class LiveCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streams: [],
      publishVideo: false,
      publishAudio: false,
      video: false,
      activeTab: 'info',
      fullscreen: false,
      videoShow: false,
      videoSource: 'camera',
      modalIsOpen: false,
      activeStream: [],
      shareScreen: false,
    };

    this.otSharePublisher = React.createRef();

    this.publisherEventHandlers = {
      streamDestroyed: (event) => {
        event.preventDefault();
        this.recieveNewUpdate(event);
      },
    };

    this.shareScreenEventHandlers = {
      streamDestroyed: (event) => {
        event.preventDefault();
        if (event.reason === 'mediaStopped') {
          this.otSharePublisher.current.getPublisher().destroy();
          this.setState({ shareScreen: false });
        }
      },
    };
  }

  componentDidMount() {
    if (this.props.sessionHelper) {
      const { session } = this.props.sessionHelper;
      session.on('streamCreated', (stream) => {
        this.recieveNewStreams(stream);
      });

      session.on('streamDestroyed', (event) => {
        event.preventDefault();
      });

      session.on('signal:text-chat', (event) => {
        this.recieveNewMessage(event);
      });

      session.on('signal:share-file', (event) => {
        this.recieveNewMessage(event);
      });

      session.on('signal:disconnect', (event) => {
        this.receiveDisconnectSignal(event);
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ streams: newProps.streams });

    const screenshare = newProps.streams.filter((stream) => stream.videoType === 'screen');

    if (screenshare.length > 0) {
      this.setState({ activeStream: screenshare });
    } else {
      this.setState({ activeStream: newProps.streams });
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  shareScreenError = (event) => {
    if (event.code === 1500) {
      this.setState({ shareScreen: false });
    }
  };

  receiveDisconnectSignal = (event) => {
    const connectionId = this.props.sessionHelper.session.connection.connectionId || '';

    if (connectionId !== event.from.connectionId) {
      return window.location.replace(`${weburl}/session/call/complete`);
    }

    return false;
  };

  recieveNewMessage = (event) => {
    const connectionId = this.props.sessionHelper.session.connection.connectionId || '';

    if (connectionId === event.from.connectionId) {
      const time = moment().unix();
      const icMsg = JSON.parse(event.data);

      if (icMsg.type === 'file') {
        const newmsg = {
          filename: icMsg.file.originalname,
          link: icMsg.file.location,
          type: '3',
          sender: event.from.data,
          time,
        };
        this.props.onNewMessage(newmsg);
      } else {
        const newmsg = {
          msg: icMsg.text,
          type: '1',
          sender: event.from.data,
          time,
        };
        this.props.onNewMessage(newmsg);
      }
    } else {
      const time = moment().unix();
      const icMsg = JSON.parse(event.data);

      if (icMsg.type === 'file') {
        const newmsg = {
          filename: icMsg.file.originalname,
          link: icMsg.file.location,
          type: '4',
          sender: event.from.data,
          time,
        };
        this.props.onNewMessage(newmsg);
      } else {
        const newmsg = {
          msg: icMsg.text,
          type: '2',
          sender: event.from.data,
          time,
        };
        this.props.onNewMessage(newmsg);
      }
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleToolBarBtn = (type) => {
    if (type === 'mic') {
      this.setState({ publishAudio: !this.state.publishAudio });
    }
    if (type === 'video') {
      this.setState({ publishVideo: !this.state.publishVideo });
    }
  };

  onEndCall = (e) => {
    e.preventDefault();

    if (document.fullscreenElement) {
      return document.exitFullscreen();
    }

    if (this.props.user.userType === enums.userType.ar) {
      return this.setState({ modalIsOpen: true });
    }

    return toast.warn('Your advice reciever can only disconnect a session', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  onConfirmDisconnect = () => {
    this.setState({ modalIsOpen: false });

    if (this.state.video) {
      if (this.props.user.userType === enums.userType.ar) {
        const data = {
          userToken: this.props.user.userToken,
          booking_id: this.props.activeBooking.bookingId,
          status: enums.bookingStatus.SUCCESSFUL,
        };

        const dataMsg = {
          sentOn: moment().unix(),
          text: 'AR disconnected the session',
          sender: {
            alias: this.props.user.email,
            id: this.props.sessionHelper.session.connection.connectionId,
          },
          type: 'disconnect',
        };

        this.props.sessionHelper.session.signal({
          type: 'disconnect',
          data: JSON.stringify(dataMsg),
        });

        return this.props.onDisconnectSession(data, this.props.activeBooking.apUserData.username);
      }
      return toast.warn('Only the Advice Receiver has the authority to disconnect the session', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return true;
  };

  onTimeUp = () => {
    if (this.props.user.userType === enums.userType.ar) {
      const data = {
        userToken: this.props.user.userToken,
        booking_id: this.props.activeBooking.bookingId,
        status: enums.bookingStatus.SUCCESSFUL,
      };

      return this.props.onDisconnectSession(data, this.props.activeBooking.apUserData.username);
    }
    return window.location.replace(`${weburl}/session/call/complete`);
  };

  handleTabClick = (e, type) => {
    e.preventDefault();
    this.setState({ activeTab: type });
  };

  openFullScreen = () => {
    const elem = this.videosession;

    if (!this.state.fullscreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        this.setState({ fullscreen: true });
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
        this.setState({ fullscreen: true });
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
        this.setState({ fullscreen: true });
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
        this.setState({ fullscreen: true });
      }
    }

    if (this.state.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.setState({ fullscreen: false });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        this.setState({ fullscreen: false });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        this.setState({ fullscreen: false });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        this.setState({ fullscreen: false });
      }
    }
  };

  recieveNewStreams = (event) => {
    const currenUserType = this.props.user.userType;
    const userConnected = event.stream.connection.data.split(',');
    let bookedUser;

    if (currenUserType === enums.userType.ap) {
      bookedUser = this.props.activeBooking.arUserData;
    }

    if (currenUserType === enums.userType.ar) {
      bookedUser = this.props.activeBooking.apUserData;
    }

    if (bookedUser.email === userConnected[1]) {
      const { activeBooking } = this.props;

      monitorSession({
        booking_id: activeBooking.bookingId,
        session_status: enums.tokboxSessionStatus.STARTED,
      });

      this.setState({
        videoShow: true,
        video: true,
        publishAudio: true,
        publishVideo: true,
      });
    }
  };

  recieveNewUpdate = (event) => {
    if (event.type === 'streamDestroyed' && event.stream.videoType === 'screen') {
      return this.setState({ videoSource: 'camera' });
    }
    return 'Event type is not a screen';
  };

  getversion = () => {
    const ua = window.navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) M.splice(1, 1, tem[1]);
    return M;
  };

  onScreenShare = () => {
    const browser = this.getversion();

    if (browser[0] === 'Chrome' && browser[1] >= 72) {
      this.setState({ shareScreen: true });
    } else if (browser[0] === 'Firefox' && browser[1] >= 52) {
      this.setState({ shareScreen: true });
    } else {
      alert(
        'Your browser does not support screen sharing functionality. Please update your browser to the latest version of chrome or firefox'
      );
    }
  };

  render() {
    const { from, to, title } = this.props.activeBooking;
    return (
      <div
        className={styles.videosession}
        ref={(e) => {
          this.videosession = e;
        }}
      >
        {!this.state.videoShow && (
          <SessionGrace
            From={from}
            To={to}
            Title={title}
            activeSession={this.props.activeSession}
            user={this.props.user}
            history={this.props.history}
            sessionHelper={this.props.sessionHelper}
            onDisconnectSession={this.props.onDisconnectSession}
            activeBooking={this.props.activeBooking}
          />
        )}

        {this.state.videoShow && (
          <SessionHeader
            onEndCall={this.onEndCall}
            tabClick={this.handleTabClick}
            activeTab={this.state.activeTab}
            openFullscreen={this.openFullScreen}
            onScreenShare={this.onScreenShare}
          />
        )}

        <div
          className={
            this.state.videoShow
              ? styles.maincontainer
              : cx(styles.maincontainer, styles.sesionfalse)
          }
        >
          {this.props.activeSession.token && (
            <div className={styles.vcontainer}>
              <div className={styles.publisher}>
                {this.state.activeStream.map((stream, id) => {
                  if (id === 0) {
                    return (
                      <OTSubscriber
                        key={stream.id}
                        session={this.props.sessionHelper.session}
                        stream={stream}
                        properties={{
                          insertMode: 'append',
                          width: '100%',
                          height: 'calc(100vh - 70px)',
                          showControls: false,
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className={styles.subscriber}>
                <div className={styles.subscribers}>
                  <div className={styles.subscriberscon}>
                    <OTPublisher
                      session={this.props.sessionHelper.session}
                      properties={{
                        insertMode: 'append',
                        width: '136px',
                        height: '83px',
                        showControls: false,
                        publishVideo: this.state.publishVideo,
                        publishAudio: this.state.publishAudio,
                      }}
                    />
                  </div>
                  {this.state.shareScreen && (
                    <div className={styles.subscriberscon}>
                      <OTPublisher
                        session={this.props.sessionHelper.session}
                        eventHandlers={this.shareScreenEventHandlers}
                        onError={this.shareScreenError}
                        ref={this.otSharePublisher}
                        properties={{
                          insertMode: 'append',
                          width: '136px',
                          height: '83px',
                          showControls: false,
                          videoSource: 'screen',
                        }}
                      />
                    </div>
                  )}
                  {this.state.streams.length > 1 &&
                    this.state.streams.map((stream) => {
                      if (stream.videoType !== 'screen') {
                        return (
                          <div className={styles.subscriberscon} key={stream.id}>
                            <OTSubscriber
                              key={stream.id}
                              session={this.props.sessionHelper.session}
                              stream={stream}
                              properties={{
                                insertMode: 'append',
                                width: '136px',
                                height: '83px',
                                showControls: false,
                              }}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
              <VideoToolbar
                handleToolBarBtn={this.handleToolBarBtn}
                {...this.props.activeSession}
                activeBooking={this.props.activeBooking}
                onTimeUp={this.onTimeUp}
              />
            </div>
          )}

          {this.props.activeSession.token && (
            <div className={styles.calltab}>
              <SessionCallTab
                activeTab={this.state.activeTab}
                session={this.props.sessionHelper}
                onNewMessage={this.props.onNewMessage}
                activeChat={this.props.activeChat}
                activeBooking={this.props.activeBooking}
              />
            </div>
          )}
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Session Info"
        >
          <DisconnectCall closeModal={this.closeModal} submitModal={this.onConfirmDisconnect} />
        </Modal>
      </div>
    );
  }
}

export default LiveCall;
