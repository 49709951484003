import React from 'react';
import styles from './UserHeadEdit.module.css';
import usernull from '../../Common/assets/usernull.svg';
import background from '../../Common/assets/blue-large.svg';

const UserHeadEdit = (props) => {
  const { firstName, lastName, primaryImg, email } = props;
  return (
    <div style={{ backgroundImage: `url(${background})` }} className={styles.userhead}>
      <p className={styles.name}>{`${firstName} ${lastName}`}</p>
      <p className={styles.compname}>{!firstName && !lastName ? email : null}</p>
      <img
        onClick={props.onClickImg}
        className={styles.pimg}
        src={primaryImg || usernull}
        alt="User"
        height="112px"
        width="112px"
      />
    </div>
  );
};

export default UserHeadEdit;
