const reactSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? '#111111' : '#555555',
    padding: 10,
    fontSize: '14px',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    marginLeft: '-5px',
    width: '100%',
    maxWidth: '275px',
    height: '42px',
    borderRadius: '43px',
    background: '#f4f4f4',
    padding: '0 23px',
    display: 'flex',
    alignitems: 'center',
    color: '#555555',
    fontSize: '14px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#14428F!important',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '300',
  }),
};

const name = 'ReactConfig';

export { reactSelectStyle, name };
