import React from 'react';
import styles from '../Dashboard.module.css';
import arrow_white_yellow from '../../Common/assets/arrow_white_yellow.svg';
import session_canceled from '../../Common/assets/session_canceled.svg';
import enums from '../../../config/enum';

const Cancel = (props) => {
  return (
    <>
      {props.user.userType === enums.userType.ar ? (
        <div className={styles.newbooking}>
          <div className={styles.newbookingnot}>
            <img src={session_canceled} alt="New Booking" />
            <div className={styles.info}>
              <p className={styles.infot}>Session Canceled!</p>
              <div className={styles.infostd}>
                <p className={styles.infost}>
                  You have successfully canceled a session. Visit the consultant's profile{' '}
                </p>
                <img
                  src={arrow_white_yellow}
                  alt="Visit"
                  className={styles.dcanimg}
                  onClick={(e) => {
                    props.history.push(`/${props.username}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.newbooking}>
          <div className={styles.newbookingnot}>
            <img src={session_canceled} alt="New Booking" />
            <div className={styles.info}>
              <p className={styles.infot}>Session Canceled!</p>
              <div className={styles.infostd}>
                <p className={styles.infost}>You have successfully canceled a session.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cancel;
