/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import EditInput from '../../Common/EditInput/EditInput';
import globalProptypes from '../../../config/proptype';
import EditSelect from '../../Common/EditSelect/EditSelect';

function ConsumerForm(props) {
  const { businessDetails, onChange, countriesMasterdata, selectedCountry } = props;
  const { address, city, postalCode } = businessDetails;

  return (
    <div className={styles.formBusiness}>
      <div className={styles.form}>
        <div className={styles.left}>
          <EditInput
            htmlFor="Address"
            label="Address"
            value={address}
            placeholder="Address"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="address"
            required
          />
          <EditInput
            htmlFor="Postal Code"
            label="Postal Code"
            value={postalCode}
            placeholder="Postal Code"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="postalCode"
            required
          />
        </div>
        <div className={styles.right}>
          <EditInput
            htmlFor="City"
            label="City"
            value={city}
            placeholder="City"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="city"
            required
          />

          <EditSelect
            label="Country"
            options={countriesMasterdata}
            selected={selectedCountry}
            placeholder="Select Country"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            onActive={() => false}
            isDisabled={!!selectedCountry}
          />
        </div>
      </div>
    </div>
  );
}

ConsumerForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountry: PropTypes.shape({}).isRequired,
  businessDetails: globalProptypes.businessDetailsState,
};

ConsumerForm.defaultProps = {
  businessDetails: {
    businessId: 0,
    businessName: '',
    businessRegistrationNo: '',
    typeOfBusiness: null,
    vatNo: '',
    userId: null,
    address: '',
    city: '',
    region: '',
    countryId: 0,
    postalCode: '',
    countryCode: '',
    isSmallEnterprise: false,
  },
};

export default ConsumerForm;
