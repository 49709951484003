import React, { Component } from 'react';
import axios from 'axios';
import styles from '../Static.module.css';
import contact_mail from '../../Common/assets/contact_mail.svg';
import contact_phone from '../../Common/assets/contact_phone.svg';
import email_verified from '../../Common/assets/email_verified.svg';
import { apiurl } from '../../../utils';

class contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      description: '',
      sent: false,
      uploading: false,
      type: 9,
      ticketid: '',
    };
  }

  componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);

    if (search.has('type') && search.get('type') === 'dispute') {
      this.setState({
        name: search.get('name'),
        email: search.get('email'),
      });
    }
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  isValidEmail = (email) => {
    const emailreg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return emailreg.test(email);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      uploading: true,
    });

    const data = {
      email: this.state.email,
      name: this.state.name,
      type: this.state.type,
      subject: this.state.subject,
      description: this.state.description,
    };

    axios
      .post(`${apiurl}/v1/support/web`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (result.data.success) {
          const data = result.data.result[0];
          return this.setState({
            sent: true,
            ticketid: data.ticketId,
            uploading: false,
          });
        }
        throw new Error('Error');
      })
      .catch((err) => {
        return this.setState({
          sent: true,
          uploading: false,
        });
      });
  };

  render() {
    return (
      <div className={styles.brpage}>
        <div className={styles.brheader}>
          <h1>Get in contact with us</h1>
        </div>
        <div className={styles.brcontainer}>
          <div className={styles.containerbody}>
            <div className={styles.sectionone}>
              <div className={styles.item}>
                <img src={contact_mail} alt="Email" />
                <p>
                  <a href="mailto:support@conrati.com">support@conrati.com</a>
                </p>
              </div>
              <div className={styles.item}>
                <img src={contact_phone} alt="Phone" />
                <p>
                  <a href="tel:+4915124014801">+27 612 304 143</a>
                </p>
              </div>
            </div>
            {!this.state.sent && (
              <div className={styles.sectiontwo}>
                <p className={styles.cftitle}>Need help ? Send us a message.</p>
                <div className={styles.form}>
                  <div className={styles.cformcontrola}>
                    <input
                      type="text"
                      value={this.state.name}
                      placeholder="Name"
                      name="name"
                      className={styles.cfinput}
                      onChange={this.onChange}
                    />
                    <input
                      type="email"
                      value={this.state.email}
                      placeholder="Email"
                      name="email"
                      className={styles.cfinput}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className={styles.cformcontrol}>
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      className={styles.cfinput}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className={styles.cformcontrol}>
                    <select
                      name="type"
                      value={this.state.type}
                      placeholder="Reason for enquiry"
                      className={styles.cfinput}
                      onChange={this.onChange}
                    >
                      <option value="9" disabled>
                        Reason for enquiry
                      </option>
                      <option value="0">Account</option>
                      <option value="1">App software</option>
                      <option value="2">Billing</option>
                      <option value="3">Suggestion</option>
                      <option value="4">Performance</option>
                      <option value="5">Functionality not working</option>
                      <option value="6">Others</option>'
                    </select>
                  </div>
                  <div className={styles.cformcontrol}>
                    <textarea
                      type="text"
                      placeholder="Comment goes here"
                      name="description"
                      className={styles.cfinputxt}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className={styles.sbtn} onClick={this.onSubmit}>
                  <button
                    disabled={
                      !(
                        this.state.name &&
                        this.isValidEmail(this.state.email) &&
                        this.state.type >= 0 &&
                        this.state.email &&
                        this.state.subject &&
                        this.state.description &&
                        !this.state.uploading
                      )
                    }
                    className={styles.abtn}
                  >
                    {this.state.uploading ? 'SUBMITING' : 'SUBMIT'}
                  </button>
                </div>
              </div>
            )}
            {this.state.sent && (
              <div className={styles.emailsent}>
                <img src={email_verified} alt="Support" />
                <p>Thank you for contacting us your ticket id is {this.state.ticketid}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default contact;
