import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Email.module.css';
import emailsent from '../../Common/assets/email-sent.png';

class Email extends Component {
  handleSkip = () => {
    const { history, isLoggedIn } = this.props;

    if (isLoggedIn) {
      return history.replace('/register/confirm-auth');
    }

    return history.replace('/signin');
  };

  render() {
    const { email } = this.props;
    return (
      <div>
        <div className={styles.signin}>
          <div className={styles.rinfo}>
            <p>Check your inbox, you’ve got mail</p>
          </div>
          <div className={styles.ebox}>
            <div className={styles.emsg}>
              <p className={styles.etext}>
                An email has been sent to
                <span className={styles.emailid}> {email}</span>. Please click on the link to
                validate your email address.
              </p>
            </div>
            <div className={styles.eimg}>
              <img src={emailsent} alt="Email sent" width="100%" height="100%" />
            </div>
          </div>
          <div className={styles.snext}>
            <button className={styles.snextbtn} onClick={(e) => this.handleSkip(e)} type="button">
              Skip
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  email: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default Email;
