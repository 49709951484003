import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Headers from '../Headers/Headers';
import styles from './EmailActivate.module.css';
import emailico from '../Common/assets/email_verified.svg';
import linkbrokenico from '../Common/assets/link-broken.svg';
import { updateProfile } from '../../actions/userAction';
import globalPropTypes from '../../config/proptype';

class EmailActivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeCounter: 10,
      intervalId: null,
      emailVerify: false,
    };

    this.redirectCounter = this.redirectCounter.bind(this);
  }

  componentDidMount() {
    const { user, onUpdateProfile, isLoggedIn, location } = this.props;
    if ((user, isLoggedIn)) {
      onUpdateProfile();
    }

    const intervalId = setInterval(this.redirectCounter, 1000);
    this.setState({ intervalId });

    const urlParams = new URLSearchParams(location.search);
    const success = urlParams.get('success');
    if (success === 'true') {
      this.setState({ emailVerify: true });
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  }

  redirectCounter() {
    const { timeCounter, intervalId } = this.state;
    const { history, isLoggedIn } = this.props;

    if (timeCounter === 1) {
      if (isLoggedIn) {
        return history.replace('/register/confirm-auth');
      }

      clearInterval(intervalId);
      return history.replace('/');
    }
    const updateTime = timeCounter - 1;
    this.setState({ timeCounter: updateTime });

    return true;
  }

  render() {
    const { emailVerify, timeCounter } = this.state;
    const { isLoggedIn } = this.props;
    return (
      <div className={styles.activate}>
        <Headers />
        <div className={styles.container}>
          <div className={styles.alert}>
            <div className={styles.alertbody}>
              {emailVerify ? (
                <div className={styles.alertcon}>
                  <p className={styles.title}>Email Address Verified</p>
                  <p className={styles.sub}>
                    Thanks for verifying your email. Please sign in to start using Conrati.
                  </p>
                  <p className={styles.sub}>Will redirect to home page in {timeCounter} seconds</p>

                  <img src={emailico} alt="Email" height="70px" width="72px" />
                </div>
              ) : (
                <div className={styles.alertcon}>
                  <p className={styles.title}>Invalid Verification Link</p>
                  <p className={styles.sub}>The link you provided seems to be broken or expired</p>
                  <p className={styles.sub}>Will redirect to home page in {timeCounter} seconds</p>

                  <img src={linkbrokenico} alt="Email" height="80px" width="100%" />
                </div>
              )}

              <div className={styles.sbtn}>
                <Link to={isLoggedIn ? '/register/confirm-auth' : ''} className={styles.btn}>
                  SKIP
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailActivate.propTypes = {
  user: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  location: globalPropTypes.location.isRequired,
  history: globalPropTypes.history.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    accountCompleted: state.userState.accountCompleted,
    isLoggedIn: state.userState.isLoggedIn,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(EmailActivate);
