/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import enums from '../config/enum';
import globalProptype from '../config/proptype';
import Notfound from '../components/Notfound/Notfound';

const ProtectedRoute = ({ component: Component, userState, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (userState.isLoggedIn) {
          if (role) {
            if (role === enums.userTypeText[userState.user.userType]) {
              return <Component {...props} />;
            }
            return <Notfound />;
          }
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  userState: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.shape({
      userType: PropTypes.number,
    }),
  }).isRequired,
  location: globalProptype.location.isRequired,
  component: PropTypes.elementType.isRequired,
  role: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  role: '',
};

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
