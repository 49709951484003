import { searchApiRequest } from '../../utils';

export const fetchUsers = (page = 1, currency) => {
  return new Promise((resolve, reject) => {
    searchApiRequest(
      'GET',
      `/search?query=&page=${page}&currencyCode=${currency.currencyCode}&currencySymbol=${currency.currencySymbol}`
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUsersWithQuery = (query, page, currency) => {
  return new Promise((resolve, reject) => {
    searchApiRequest(
      'GET',
      `/search?query=${query}&page=${page}&currencyCode=${currency.currencyCode}&currencySymbol=${currency.currencySymbol}`,
      {}
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUsersWithFilter = (query, page, currency) => {
  return new Promise((resolve, reject) => {
    searchApiRequest(
      'GET',
      `/search${query ? query : '?'}&page=${page}&currencyCode=${
        currency.currencyCode
      }&currencySymbol=${currency.currencySymbol}`,
      {}
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
