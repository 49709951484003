/* ....................User Types..................... */
export const FETCH_USER = 'user:FETCH_USERS';
export const REGISTER_USER = 'user:REGISTER_USER';
export const FORGET_PASSWORD = 'user:FORGET_PASSWORD';
export const ERROR = 'user:ERROR';
export const LOG_OUT = 'user:LOG_OUT';
export const SIGN_IN = 'user:SIGN_IN';
export const USERTYPE_REGISTER = 'user:USERTYPE_REGISTER';
export const oauthData = 'user:oauthData';
export const EDIT_PROFILE = 'user:EDIT_PROFILE';
export const UPDATE_PROFILE = 'user:updateProfile';
export const FETCH_BUISNESS_DETAILS_USER = 'user:FETCH_BUISNESS_DETAILS_USER';
export const UPDATE_BUISNESS_DETAILS_USER = 'user:FETCH_BUISNESS_DETAILS_USER';
export const ACCOUNT_COMPLETE = 'user:ACCOUNT_COMPLETE';
export const ONBOARDING_STEPS = 'user:ONBOARDING_STEPS';
export const SWITCH_ACCOUNT = 'user:SWITCH_ACCOUNT';
export const kycConfirm = 'user:kycConfirm';
export const UPDATE_ADVERT = 'user:UPDATE_ADVERT';
export const NEW_REQUEST = 'user:NEW_REQUEST';
export const ISERROR = 'user:ISERROR';
export const USER_FORM_HANDLING = 'user:USER_FORM_HANDLING';
export const HANDLE_OPT_IN = 'user:HANDLE_OPT_IN';
export const HANDLE_AR_ONCHANGE = 'user:HANDLE_AR_ONCHANGE';

/* ....................Helper Types..................... */
export const LOADING = 'helper:LOADING';
export const SUCCESS = 'helper:SUCCESS';
export const ERROR_R = 'helper:ERROR_R';
export const CLEAR_ER = 'helper:CLEAR_ER';

/* ....................Profile Types..................... */
export const FETCH_ALL_PROFILE = 'profile:FETCH_ALL_PROFILE';
export const RANDOM_PROFILE = 'profile:RANDOM_PROFILE';
export const RANDOM_PROFILE_HOMEPAGE = 'profile:RANDOM_PROFILE_HOMEPAGE';
export const FETCH_PROFILE = 'profile:FETCH_ROFILE';
export const PROFILE_REQUEST = 'profile:PROFILE_REQUEST';

/* ....................Avalabilty Types..................... */
export const AVAILABLITY_DAY = 'profile:AVAILABLITY_DAY';
export const AVAILABLITY_ALL = 'profile:AVAILABLITY_ALL';

/* ....................User Session..................... */
export const UPCOMING_SESSION = 'session:UPCOMING_SESSION';
export const JOIN_SESSION = 'session:JOIN_SESSION';
export const ACTIVE_CHAT = 'session:ACTIVE_CHAT';
export const ACTIVE_BOOKING = 'session:ACTIVE_BOOKING';
export const DISCONNECT_SESSION = 'session:DISCONNECT_SESSION';
export const BOOKING_HISTORY = 'session:BOOKING_HISTORY';
export const NEW_ONGOING_BOOKING = 'session:NEW_ONGOING_BOOKING';
export const CLEAR_ONGOING_BOOKING = 'session:CLEAR_ONGOING_BOOKING';

/* ....................Temp Session..................... */
export const FETCH_USER_CARD = 'temp:FETCH_USER_CARD';
export const FETCH_BANK_ACCOUNT = 'temp:FETCH_BANK_ACCOUNT';
export const FETCH_KYC_DOCUMENT = 'temp:FETCH_KYC_DOCUMENT';
export const RESET_USER_PASSWORD = 'temp:RESET_USER_PASSWORD';
export const CALCULATE_VAT = 'temp:CALCULATE_VAT';
export const FETCH_BALANCE = 'temp:FETCH_BALANCE ';
export const FETCH_KYCHELPTEXT = 'temp:FETCH_KYCHELPTEXT ';
export const FETCH_ALL_COUNTRIES = 'temp:FETCH_ALL_COUNTRIES ';
export const FETCH_ALL_UBODOC = 'temp:FETCH_ALL_UBODOC ';

/* ....................Master Data..................... */
export const FETCH_MASTER_DATA = 'master:FETCH_MASTER_DATA';
export const FETCH_IPINFO_DETAILS = 'master:FETCH_IPINFO_DETAILS';
export const FETCH_ALL_CURRENCY = 'master:FETCH_ALL_CURRENCY';
