import React from 'react';
import PropTypes from 'prop-types';
import styles from '../style.module.css';

function PopUp(props) {
  const { onCancel, onSubmit } = props;
  return (
    <div className={styles.aPopUp}>
      <p className={styles.aText}>
        Please ensure that you have improved your profile information related to your skills,
        consulting topics, education, service description, experience and certifications before
        submitting your profile to be reviewed for unfreezing.
      </p>

      <div className={styles.button}>
        <button type="submit" className={styles.cbtn} onClick={onCancel}>
          CANCEL
        </button>
        <button type="submit" className={styles.subtn} onClick={onSubmit}>
          SUBMIT
        </button>
      </div>
    </div>
  );
}

PopUp.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PopUp;
