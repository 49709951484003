import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import conratiLogo from '../../../components/Common/assets/logo-yellow.png';
import chatLight from '../../../components/Common/assets/chat_light.svg';
import chatDark from '../../../components/Common/assets/chat_dark.svg';
import infoLight from '../../../components/Common/assets/info_light.svg';
import infoDark from '../../../components/Common/assets/info_dark.svg';
// import shareScreenIcon from '../../../components/Common/assets/sharescreen.svg';
import fullScreenIcon from '../../../components/Common/assets/fullscreen.svg';
import endCallIcon from '../../../components/Common/assets/endcall.svg';
import { sendEvent } from '../../utils/event';

const activeActionBtns = {
  chat: 1,
  file: 2,
  info: 3,
};

class SessionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAction: activeActionBtns.info,
    };
  }

  onSetActiveBtn = (activeAction) => {
    this.setState({
      activeAction,
    });

    const { onActionBtnChange } = this.props;
    onActionBtnChange(activeAction);
  };

  render() {
    const { activeAction } = this.state;

    return (
      <div className={style.SessionHeader}>
        <div className={style.logo}>
          <img src={conratiLogo} alt="Conrati Logo" />
        </div>
        <div className={style.rightMenu}>
          <div className={style.callBtns}>
            {/* <button type="button" onClick={() => sendEvent('screen-share')}>
              <img src={shareScreenIcon} alt="Share Screen" />
            </button> */}
            <button type="button" onClick={() => sendEvent('full-screen')}>
              <img src={fullScreenIcon} alt="Full Screen" />
            </button>
            <button type="button" onClick={() => sendEvent('end-call')}>
              <img src={endCallIcon} alt="End Call" />
            </button>
          </div>
          <div className={style.actionBtns}>
            <button
              type="button"
              className={activeAction === activeActionBtns.chat ? style.active : ''}
              onClick={() => this.onSetActiveBtn(activeActionBtns.chat)}
            >
              <img src={activeAction === activeActionBtns.chat ? chatDark : chatLight} alt="Chat" />
              <p className={style.title}>Chat</p>
            </button>
            <button
              type="button"
              className={activeAction === activeActionBtns.info ? style.active : ''}
              onClick={() => this.onSetActiveBtn(activeActionBtns.info)}
            >
              <img src={activeAction === activeActionBtns.info ? infoDark : infoLight} alt="info" />
              <p className={style.title}>Info</p>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SessionHeader.propTypes = {
  onActionBtnChange: PropTypes.func.isRequired,
};

export default SessionHeader;
