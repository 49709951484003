import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './style.module.scss';
import arrowicon from '../../Common/assets/arrow-down.svg';
import logo from '../../Common/assets/logo-yellow.png';
import Ar from './Ar';
import enums from '../../../config/enum';
import AP from './AP';
import SearchBar from '../SearchBar';

class AuthHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      activePage: '',
    };
  }

  componentDidMount() {
    const { router } = this.props;
    if (router) {
      this.setState({ activePage: router.location.pathname });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideMenuClick, false);
  }

  handleMenuClick = () => {
    const { menu } = this.state;

    this.setState({ menu: !menu });

    if (!menu) {
      document.addEventListener('click', this.handleOutsideMenuClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideMenuClick, false);
    }
  };

  handleOutsideMenuClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.handleMenuClick();
  };

  handleOnLogout = () => {
    const { onLogOut } = this.props;
    onLogOut(this.props.userState.user);
  };

  render() {
    const { router } = this.props;
    const { location } = router;
    const { menu } = this.state;

    return (
      <header className={styles.headers}>
        <div
          className={styles.container}
          ref={(node) => {
            this.node = node;
          }}
        >
          <Link to={this.props.userState.user.userType === enums.userType.ar ? '/' : '/dashboard'}>
            <img src={logo} alt="conrati-logo" width="110px" className={styles.logo} />
          </Link>
          <nav className={styles.links}>
            {location.pathname !== '/search' && <SearchBar router={router} />}

            <Link to="/dashboard" className={styles.upcomingBtn}>
              Upcoming Sessions
            </Link>
            <div className={styles.borderLeft} />
            <div className={styles.navmenu}>
              <button className={styles.navbtn} onClick={() => this.handleMenuClick()}>
                <p className={menu ? styles.navbtnpc : styles.navbtnp}>
                  Welcome{' '}
                  {`${this.props.userState.user.firstName} ${this.props.userState.user.lastName}`}
                </p>
                <img
                  className={menu ? styles.arrowiconi : styles.arrowicon}
                  src={arrowicon}
                  alt="arrow-icon"
                  height="11px"
                  width="11px"
                />
              </button>
              <div className={menu ? cx(styles.showbtn, styles.navmenuitem) : styles.navmenuitem}>
                {this.props.userState.user.userType === enums.userType.ar ? (
                  <Ar handleOnLogout={this.handleOnLogout.bind(this)} {...this.props} />
                ) : (
                  <AP handleOnLogout={this.handleOnLogout.bind(this)} />
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default AuthHeader;
