import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './collapse.module.css';
import arrow from '../assets/arrow-down.svg';

class Collapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
    this.expandHandler = this.expandHandler.bind(this);
  }

  componentDidMount() {
    const { expand } = this.props;

    if (expand) {
      this.setState({ expand });
    }
  }

  expandHandler() {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  }

  render() {
    const rotateico = cx(styles.cimg, styles.rotate);
    const { title, content, contentClassName, titleClassName } = this.props;
    const { expand } = this.state;
    return (
      <div className={styles.cexpand}>
        <div className={styles.cexp}>
          <p className={`${styles.exptitle} ${titleClassName || ''}`}>{title}</p>
          <div
            className={expand ? rotateico : styles.cimg}
            onClick={this.expandHandler}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <img src={arrow} alt="collapse" />
          </div>
        </div>
        {expand ? <p dangerouslySetInnerHTML={{ __html: content }} className={`${styles.cexpandcon} ${contentClassName || ''}`} /> : ''}
      </div>
    );
  }
}

Collapse.propTypes = {
  expand: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string
};

Collapse.defaultProps = {
  expand: false,
};

export default Collapse;
