export const paymentFAQData = {
	// For variant o, t and s
	variantFirstOption: [
		{
			title: 'Is this video therapy session free? Why am I asked for credit card details?',
			details:
				'Yes, Conrati offers a 100% free video therapy session with a matched therapist provided you show up at your scheduled booking. Your card will not be charged when making this booking. <br><br>You are only charged a no-show fee of 15 GBP/EUR/USD if you fail to show up to your scheduled session without reason.<br><br> Additionally, if you choose to make a follow up paid therapy booking after your first therapy session, we will fully reimburse you if you are not happy with the service - NO QUESTIONS ASKED',
		},
		{
			title:
				'If I book a follow up paid session, will I be reimbursed if I am not happy with the service',
			details:
				'Yes, Conrati offers a 100% money back guarantee if you are unsatisfied in any way after your follow up paid session. By this time you would have had 2 sessions with your therapist absolutely risk-free!',
		},
		{
			title: 'What payment methods are accepted?',
			details:
				'Payments are made via a debit or credit card only which offers a quick, easy, safe payment process.',
		},
		{
			title: 'Can I trust Conrati with my credit / debit card details?',
			details:
				'Conrati makes use of MANGOPAY - our trusted third party provider to handle card details and payments in the most secure way. All payment data is encrypted and never saved with Conrati.',
		},
		{
			title: 'What will be shown on my credit / debit card statement?',
			details: 'The transaction description will be “Conrati"',
		},
	],
	// For variant m and l
	variantSecondOption: [
		{
			title: 'Can I be reimbursed if I am not happy with the service?',
			details:
				'Yes, Conrati offers a 100% money back guarantee if you are unsatisfied in any way after your first paid session',
		},
		{
			title: 'What payment methods are accepted?',
			details:
				'Payments are made via a debit or credit card only which offers a quick, easy, safe payment process.',
		},
		{
			title: 'Can I trust Conrati with my credit / debit card details?',
			details:
				'Conrati makes use of MANGOPAY - our trusted third party provider to handle card details and payments in the most secure way. All payment data is encrypted and never saved with Conrati.',
		},
		{
			title: 'What will be shown on my credit / debit card statement?',
			details: 'The transaction description will be “Conrati"',
		},
	],
	// For variant n and u
	variantThirdOption: [
		{
			title: 'Can I be reimbursed if I am not happy with the service?',
			details:
				'Yes, Conrati offers a 100% money back guarantee if you are unsatisfied in any way after your first paid session',
		},
		{
			title: 'When am I charged the amount for the package?',
			details:
				'You are charged upfront. Keep in mind we offer a 100% money back guarantee if you are unsatisfied in any way after your first paid session.',
		},
		{
			title: 'Can I switch therapists if I am not satisfied?',
			details:
				'Absolutely, kindly email <a href="mailto:support@conrati.com">support@conrati.com</a> to request a switch of therapists should you be unsatisfied',
		},
		{
			title: 'What payment methods are accepted?',
			details:
				'Payments are made via a debit or credit card only which offers a quick, easy, safe payment process.',
		},
		{
			title: 'Can I trust Conrati with my credit / debit card details?',
			details:
				'Conrati makes use of MANGOPAY - our trusted third party provider to handle card details and payments in the most secure way. All payment data is encrypted and never saved with Conrati.',
		},
		{
			title: 'What will be shown on my credit / debit card statement?',
			details: 'The transaction description will be “Conrati”',
		},
	],
};
