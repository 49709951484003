import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from '../style.module.css';
import arrowIcon from '../../Common/assets/arrow_gray_white.svg';
import ProfileCard from '../../Home/ProfileCard/ProfileCard';

function DepressionResult({ result, resultLong, profiles }) {
  return (
    <div>
      <p className={style.introContentTitle}>Depression Test Result</p>
      <div className={style.resultBox}>
        <p className={style.resultText}>
          YOUR RESULT: <strong>{result}</strong>
        </p>
        <p className={style.resultLong}>{resultLong}</p>
        <p>
          <strong>
            Conrati offers therapists that you can TALK to about depression. Some therapists offer a
            free first-time session. See more below.
          </strong>
        </p>
      </div>
      <div className={style.findButton}>
        <a
          href="/categories/psychologist?utm_source=self&utm_medium=page&utm_campaign=depression_test"
          target="_open"
        >
          Find a therapist
        </a>
      </div>
      <div className={style.benefits}>
        <div className={style.ciaction}>
          <div className={style.ciahead}>
            <p className={style.ctit}>Recommended Psychologists & Therapists</p>
            <Link
              to="/search?&rate=&category=&skills=industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor&languages="
              className={style.cba}
            >
              Find More <img className={style.aic} src={arrowIcon} alt="" />
            </Link>
          </div>
          <div className={style.cards}>
            <ProfileCard profiles={profiles} />
          </div>
        </div>
      </div>
    </div>
  );
}

DepressionResult.propTypes = {
  result: PropTypes.string.isRequired,
  resultLong: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DepressionResult;
