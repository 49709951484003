import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Oauth.module.css';
import Loader from '../../Common/Loader/Loader';

class OauthShare extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (window.opener) {
      const message = {
        source: 'oauth-share',
        payload: location.search,
      };

      window.opener.postMessage(message);
    }

    return window.close();
  }

  render() {
    return (
      <div>
        <header className={styles.aheader}>
          <div className={styles.alink} />
        </header>
        <div className={styles.atab}>
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>
      </div>
    );
  }
}

OauthShare.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default OauthShare;
