import React, { Component } from 'react';
import OT from '@opentok/client';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'react-modal';
import SessionCall from '../../components/SessionCall';
import SessionPermission from '../../components/SessionPermission';
import style from './style.module.scss';
import { emitter, sendEvent } from '../../utils/event';
import globalProptypes from '../../../config/proptype/index';
import {
  disconnectVideoSession,
  getBookingDetails,
  joinVideoSession,
} from '../../../services/userService';
import enums from '../../../config/enum';
import { alert, appActivityTracker, checkIsMobileBrowser, connectAppUrl, tokboxapikey } from '../../../utils';
import DisconnectCall from '../../components/DisconnectSession';
import alertSound from '../../assets/audio/alert.mp3';
import { Track } from '@conrati/tracking';

const tokboxData = {
  apiKey: tokboxapikey,
};

const sessionSteps = {
  earlyJoin: 1, // Before time
  preWaiting: 2, // 5min before time
  waiting: 3, // Grace period 10 min
  joined: 4, // Joined session
  noShow: 5, // When one party did not join
  expired: 6,
};

const messageType = {
  sent: 1,
  recieve: 2,
};

const activeActionBtnsEnum = {
  chat: 1,
  file: 2,
  info: 3,
};

// Modal style
const modalStyles = {
  overlay: {
    zIndex: '2',
  },
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '130px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

class Session extends Component {
  constructor(props) {
    super(props);
    this.tokbox = null;
    this.publisher = null;
    this.videoShow = React.createRef();
    this.tokboxSession = null;
    this.interval = null;
    this.earlyJoin = React.createRef();
    this.joinWaiting = React.createRef();
    this.state = {
      tokboxSessionState: null,
      joinCall: false,
      sessionData: {
        sessionId: '',
        token: '',
        email: '',
        bookingData: {
          title: '',
          dateOfBooking: '',
          from: '',
          to: '',
        },
      },
      activeSessionStep: 0,
      rejoinTime: null,
      rejoined: false,
      isFullScreen: false,
      isModalOpen: false,
      requestProcessing: false,
      bookingData: {},
      fromBookingTime: null,
      graceBookingTime: null,
      msgStack: [],
      activeActionBtn: activeActionBtnsEnum.info,
      isReady: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    this.earlyJoin.current = false;
    this.joinWaiting.current = false;
    const queryParams = new URLSearchParams(search);
    const bookingId = queryParams.get('sessionId');
    const joinViaBtn = queryParams.get('action') ? '&action=join' : '';

    // Check if mobile device
    if (bookingId) {
      const url = `${connectAppUrl}?sessionId=${bookingId}&appToken=${this.props.userData.userToken}${joinViaBtn}`;
      window.location.href = url;
      return;
    }else {
      this.setState({ isReady: true })
    }

    if (joinViaBtn) {
        Track('join-session-via-link', {
            email: this.props.userData.email,
            userId: this.props.userData.userId,
            userType: this.props.userData.userType,
            bookingId,
            isMobile: false,
        });
    }
    
    this.fetchSessionDetails(bookingId);
    this.loadEventListener(bookingId);
  }

  componentWillUnmount() {
    if (this.tokboxSession) {
      this.tokboxSession.disconnect();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  loadEventListener = () => {
    emitter.on('mic-on', (data) => this.handleOnAudioBtnClick(data));
    emitter.on('video-on', (data) => this.handleOnVideoBtnClick(data));
    emitter.on('screen-share', () => this.handleScreenShare());
    emitter.on('full-screen', () => this.handleFullScreen());
    emitter.on('end-call', () => this.handleOnEndCall());
    emitter.on('permisson-given', () => this.handlePermissonGiven());
    emitter.on('send-message', (msg) => this.sendMessage(msg));
    emitter.on('action-btn-change', (btn) => this.setState({ activeActionBtn: btn }));
  };

  handlePermissonGiven = () => {
    setTimeout(() => {
      this.loadEdgeCases();
      this.interval = setInterval(() => {
        this.intervalDelay();
      }, 2000);
    }, 4000);
  };

  intervalDelay = () => {
    this.loadEdgeCases();
  };

  loadEdgeCases = async () => {
    const { fromBookingTime, graceBookingTime } = this.state;

    const currentTime = moment();

    // From >= Current && From <= Grace
    if (
      currentTime.isSameOrAfter(fromBookingTime) &&
      currentTime.isSameOrBefore(graceBookingTime)
    ) {
      await this.onJoinVideoSession();
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.intializeSession();
      this.setState({
        activeSessionStep: sessionSteps.waiting,
      });
      return;
    }

    // Current < From
    if (currentTime.isBefore(fromBookingTime)) {
      this.setState({
        activeSessionStep: sessionSteps.earlyJoin,
      });

      return;
    }

    // Current > Grace
    if (currentTime.isAfter(graceBookingTime)) {
      await this.onJoinVideoSession();

      const { sessionData } = this.state;
      const { isSessionActive } = sessionData;

      if (isSessionActive === enums.tokboxSessionStatus.STARTED) {
        if (!this.tokboxSession) {
          // Rejoin
          this.setState({
            rejoinTime: moment(),
            rejoined: true,
            activeSessionStep: sessionSteps.waiting,
          });

          this.intializeSession();
        }
      }

      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  };

  handleFullScreen = () => {
    const sessionElem = this.sessionElement;
    const { isFullScreen } = this.state;

    if (!isFullScreen) {
      if (sessionElem.requestFullscreen) {
        sessionElem.requestFullscreen();
      } else if (sessionElem.webkitRequestFullscreen) {
        /* Safari */
        sessionElem.webkitRequestFullscreen();
      } else if (sessionElem.mozRequestFullScreen) {
        /* Mozilla */
        sessionElem.mozRequestFullScreen();
      }

      this.setState({ isFullScreen: true });
    }

    if (isFullScreen) {
      document.exitFullscreen();
      this.setState({ isFullScreen: false });
    }
  };

  handleOnEndCall = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    const { userData } = this.props;

    if (userData.userType === enums.userType.ar) {
      return this.setState({ isModalOpen: true });
    }

    return alert('Your advice receiver can only disconnect a session');
  };

  onJoinVideoSession = async () => {
    try {
      const { userData } = this.props;
      const { firstName, lastName, email } = userData;
      const { bookingData } = this.state;
      const { bookingId } = bookingData;

      const postData = {
        booking_id: bookingId,
        first_name: firstName,
        last_name: lastName,
        email,
      };

      const respData = await joinVideoSession(postData);
      this.setState({ sessionData: respData });
      return Promise.resolve(true);
    } catch (err) {
      alert(err.message);
      setTimeout(() => {
        window.location.href = '/bookinghistory';
      }, 2000);
      return Promise.reject(err);
    }
  };

  fetchSessionDetails = (sessionId) => {
    getBookingDetails(sessionId).then((resp) => {
      const bookingData = resp[0];

      this.setState({
        bookingData,
      });

      const { from } = bookingData;
      const fromBooking = moment.unix(from);
      const graceTime = moment.unix(from).add(10, 'minute');

      this.setState({
        fromBookingTime: fromBooking,
        graceBookingTime: graceTime,
      });
    });
  };

  sessionJoinHandler = async () => {
    const { sessionData, bookingData } = this.state;

    const { from } = bookingData;

    // const parsedDateOfBooking = moment(dateOfBooking, 'YYYY-MM-DD');
    const currentDateAndTime = moment();
    const fromBooking = moment.unix(from);
    const graceTime = fromBooking.clone().add(10, 'minutes');

    // Join Booking
    if (currentDateAndTime.isSameOrAfter(fromBooking)) {
      await this.onJoinVideoSession();
      if (
        currentDateAndTime.isSameOrBefore(graceTime) &&
        currentDateAndTime.isSameOrAfter(fromBooking)
      ) {
        if (!this.tokboxSession) {
          this.intializeSession();
        }

        return this.setState({
          activeSessionStep: sessionSteps.waiting,
        });
      }

      // Already Joined
      const { isSessionActive } = sessionData;
      if (isSessionActive === enums.tokboxSessionStatus.STARTED) {
        if (!this.tokboxSession) {
          clearInterval(this.interval);

          // Rejoin
          this.setState({
            rejoinTime: moment(),
            rejoined: true,
          });

          return this.intializeSession();
        }
      }
    }

    return null;
  };

  sendSignal = (type, data = '') => {
    return this.tokboxSession.signal({
      type,
      data,
    });
  };

  sendMessage = (msg) => {
    this.sendSignal(enums.tokboxSignalType.Message, msg);
    this.sendSignal('session-chat', msg.message)
  };

  onJoinSession = () => {
    this.intializeSession();
  };

  handleOnVideoBtnClick = (isVideoOn) => {
    this.publisher.publishVideo(isVideoOn);
  };

  handleOnAudioBtnClick = (isMicOn) => {
    this.publisher.publishAudio(isMicOn);
  };

  handleScreenShare = () => {
    this.screenShare();
  };

  intializeSession = () => {
    const { sessionData } = this.state;

    this.tokboxSession = OT.initSession(tokboxData.apiKey, sessionData.sessionId);
    this.setState({
      tokboxSessionState: this.tokboxSession,
    });
    this.connectSession();
    this.signalListener();
    this.listenStreamDataChanges();
  };

  connectSession = () => {
    const { sessionData } = this.state;
    this.tokboxSession.connect(sessionData.token, (err) => {
      if (err) {
        this.handleError(err);
      }
    });
  };

  loadSessionScreen = () => {
    this.setState({
      joinCall: true,
      activeSessionStep: sessionSteps.joined,
    });

    clearInterval(this.interval);
    this.publshSessionData();
  };

  publshSessionData = () => {
    const publisherProperties = {
      insertMode: 'append',
      width: '160px',
      height: '90px',
      showControls: false,
      publishVideo: true,
      publishAudio: true,
    };

    this.publisher = OT.initPublisher('publisher-stream', publisherProperties);

    this.tokboxSession.publish(this.publisher, (err) => this.handleError(err));
  };

  listenStreamDataChanges = () => {
    this.tokboxSession.on('streamCreated', (event) => {
      this.subscribeToStream(event.stream);
    });

    this.tokboxSession.on('connectionCreated', (event) => {
      const { activeSessionStep } = this.state;

      if (activeSessionStep !== sessionSteps.joined) {
        const eventData = event.connection.data.split(',');
        const email = eventData[1];

        const { userData } = this.props;

        if (email !== userData.email) {
          this.loadSessionScreen();
        }
      }
    });

    this.tokboxSession.on('streamDestroyed', () => {});
  };

  subscribeToStream = (streamData) => {
    const subscriberProperties = {
      insertMode: 'append',
      width: '100%',
      height: 'calc(100vh - 77px)',
      showControls: false,
    };

    this.tokboxSession.subscribe(streamData, 'subscriber-stream', subscriberProperties);
  };

  signalListener = () => {
    this.tokboxSession.on('signal', (event) => {
      if (event.type === `signal:${enums.tokboxSignalType.Joined}`) {
        const { userData } = this.props;

        if (event.data !== userData.userId) {
          this.loadSessionScreen();
          this.setState({
            activeSessionStep: sessionSteps.joined,
          });
        }
      }

      if (event.type === `signal:${enums.tokboxSignalType.Message}`) {
        const { data } = event;
        const msgData = data;

        const { userData } = this.props;
        const { email } = userData;

        if (email !== msgData.email) {
          msgData.type = messageType.recieve;
        } else {
          msgData.type = messageType.sent;
        }

        const { msgStack } = this.state;

        const msgDataValues = [...msgStack, msgData];

        this.setState({
          msgStack: msgDataValues,
        });

        sendEvent('recieve-message');

        const { activeActionBtn } = this.state;

        if (activeActionBtn !== activeActionBtnsEnum.chat) {
          const alertSoundAudio = new Audio(alertSound);
          alertSoundAudio.play();
          alert('You have received a new message');
        }
      }
      if (event.type === `signal:${enums.tokboxSignalType.DisconnectSession}` || event.data === enums.tokboxSignalType.SessionEndedByAdviceReceiver) {
        const { sessionData } = this.state;
        const { bookingData } = sessionData;

        window.location.href = `/session/disconnect?sessionStatus=${enums.sessionDisconnectType.Successful}&apUserName=${bookingData.apUserData.username}`;
      }
    });
  };

  screenShare = () => {
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported) {
        alert('Screen share not supported');
      } else {
        const screenSharePublisher = OT.initPublisher('publisher-stream', {
          videoSource: 'screen',
        });

        this.tokboxSession.publish(screenSharePublisher, (err) => this.handleError(err));
      }
    });
  };

  disconnectSessionConfirm = () => {
    this.setState({ requestProcessing: true });

    const { location, userData } = this.props;

    const queryParams = new URLSearchParams(location.search);

    const sessionId = queryParams.get('sessionId') || '';

    const reqData = {
      booking_id: sessionId,
      status: enums.bookingStatus.SUCCESSFUL,
    };

    // Send a tracking request if the user confirms
    Track("session/session-leave", {
        bookingId: sessionId,
        userId: userData.userId,
    });

    disconnectVideoSession(reqData)
      .then(() => {
        this.sendSignal(enums.tokboxSignalType.DisconnectSession, userData.userId);

        const { sessionData } = this.state;
        const { bookingData } = sessionData;

        window.location.href = `/session/disconnect?sessionStatus=${enums.sessionDisconnectType.Successful}&apUserName=${bookingData.apUserData.username}`;

        return this.setState({ requestProcessing: false });
      })
      .catch(() => {
        this.setState({ requestProcessing: false });
      });
  };

  render() {
    const { userData, location } = this.props;
    const {
      joinCall,
      sessionData,
      tokboxSessionState,
      activeSessionStep,
      rejoinTime,
      rejoined,
      isModalOpen,
      requestProcessing,
      bookingData,
      msgStack,
      isReady,
    } = this.state;

    if (activeSessionStep === sessionSteps.earlyJoin && !this.earlyJoin.current) {
        this.earlyJoin.current = true;
        
        Track('session/early-join', {
            email: userData.email,
            userId: userData.userId,
            userType: userData.userType,
            bookingId: bookingData.bookingId,
        });
    }
    
    if (activeSessionStep === sessionSteps.waiting && !this.joinWaiting.current) {
        this.joinWaiting.current = true;

        appActivityTracker(
            'session/active-session', 
            {
                email: userData.email,
                userId: userData.userId,
                userType: userData.userType,
                bookingId: bookingData.bookingId,
                sessionId: sessionData.sessionId,
            }, 
            20 * 1000,  // 20secs in milliseconds
        );
    }

    return (
      <>
        {
          !isReady && (
            <div className={style.centerLoading}>Loading...</div>
          )
        }

        {
          isReady && (
            <div
              className={style.sessionPage}
              ref={(e) => {
                this.sessionElement = e;
              }}
            >
              {joinCall && (
                <SessionCall bookingData={bookingData} sessionId={sessionData.sessionId} userData={userData} msgStack={msgStack} />
              )}
              {!joinCall && (
                <SessionPermission
                  onJoinSession={this.onJoinSession}
                  sessionData={sessionData}
                  tokboxSession={tokboxSessionState}
                  activeSessionStep={activeSessionStep}
                  userData={userData}
                  rejoinTime={rejoinTime}
                  rejoined={rejoined}
                  location={location}
                />
              )}

              <Modal
                isOpen={isModalOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={modalStyles}
                contentLabel="Session Info"
              >
                <DisconnectCall
                  closeModal={() => this.setState({ isModalOpen: false })}
                  submitModal={this.disconnectSessionConfirm}
                  requestProcessing={requestProcessing}
                />
              </Modal>
            </div>
          )
        }
      </>
    )
  }
}

Session.propTypes = {
  location: globalProptypes.location.isRequired,
  userData: globalProptypes.user.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userData: state.userState.user,
    activeSession: state.session.activeSession,
  };
};

export default connect(mapStateToProps, null)(Session);
