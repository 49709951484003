import React from 'react';
import styles from './Header.module.scss';
import { Icon } from '@conrati/ui';

export default function Header(): JSX.Element {
	return (
		<header data-testid="quiz-header" className={styles.homeHeader}>
			<div className={styles.container}>
				<div className={styles.logo}>
					<Icon name="Logo" width={140} height={70} title="Conrati Logo" />
				</div>
				<nav className={styles.links}>
					<a
						href="https://blog.conrati.com"
						className={styles.blogButton}
						target="_blank"
						rel="noreferrer noopener"
					>
						BLOG
					</a>
					<div className={styles.separator} />
					<a href="/signin" className={styles.secondaryBtn}>
						SIGN IN
					</a>
					<a href="/register" className={styles.primaryBtn}>
						REGISTER
					</a>
				</nav>
			</div>
		</header>
	);
}
