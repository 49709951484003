import React, { useEffect, useState } from 'react';
import styles from './TherapistInAction.module.scss';
import { getTherapistUserNames } from '../../common/constants';
import { getUser } from '../../resources/user';
import { User, UserCard } from '@conrati/ui';
import Arrow from '../arrow/Arrow';

async function fetchTherapistInActionUsers(): Promise<User[]> {
	const requestBuilder: Promise<User>[] = [];
	getTherapistUserNames().forEach(username => requestBuilder.push(getUser(username)));

	const users = await Promise.all(requestBuilder);
	return users;
}

export default function TherapistInAction(): JSX.Element {
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		fetchTherapistInActionUsers().then(users => setUsers(users));
	}, []);

	return (
		<div className={styles.rootContainer}>
			<div className={styles.container}>
				<h1 data-testid="quiz-how-works-title">Therapists in Action</h1>

				<div className={styles.therapist}>
					{
						users.map(user => (
							<UserCard user={user} key={user.username} />
						))
					}
				</div>

				<div className={styles.seeMoreLink}>
					<a
						data-testid="quiz-world-therapist-link"
						href={'/categories/psychologist'}
					>
						SEE MORE<Arrow hasBackground />
					</a>
				</div>
			</div>
		</div>
	);
}
