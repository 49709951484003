import React from 'react';
import PropTypes from 'prop-types';
import { LegacyNativeUserCard as UserCard } from '@conrati-ui/native';

const CardProfiles = (props) => {
  const { profiles, isFreeSessionConsumed } = props;

  const profileCard = profiles.map((profile, index) => (
    <UserCard key={`profile-index-${index.toString()}`} user={profile} />
  ));

  return profileCard;
};

CardProfiles.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CardProfiles;
