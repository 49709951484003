/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class OauthPopUp extends Component {
  constructor(props) {
    super(props);
    // STEP 1: create a container <div>
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
    this.oauthInterval = null;
  }

  componentDidMount() {
    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open(this.props.redirectUrl, 'Oauth', 'width=800,height=400');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);

    this.externalWindow.addEventListener('unload', this.onWindowUnload);
    this.externalWindow.focus();

    this.oauthInterval = window.setInterval(() => {
      if (this.externalWindow.closed) {
        window.clearInterval(this.oauthInterval);
        this.onCheckWindowStatus();
      }
    }, 1000);

    window.addEventListener('message', this.receiveMessage, false);
  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window

    window.removeEventListener('message', this.receiveMessage, false);
    this.externalWindow.close();
  }

  onWindowUnload = () => {};

  onCheckWindowStatus = () => {
    this.props.onCloseModal();
  };

  receiveMessage = (event) => {
    const { data } = event;
    if (data.source === 'oauth-code') {
      this.props.onCloseModal(data.payload);
    }
  };

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}

OauthPopUp.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default OauthPopUp;
