import moment from 'moment';
import {
  FETCH_ALL_PROFILE,
  FETCH_PROFILE,
  AVAILABLITY_DAY,
  AVAILABLITY_ALL,
  ACCOUNT_COMPLETE,
  PROFILE_REQUEST,
  RANDOM_PROFILE,
  RANDOM_PROFILE_HOMEPAGE,
} from './types';
import { apiRequest as API, alert } from '../utils';
import { DateTime } from 'luxon';

export const fetchAllProfiles = (query, cancelToken, data) => (dispatch) => {
  dispatch({
    type: PROFILE_REQUEST,
    payload: {
      loading: true,
    },
  });

  API(
    'POST',
    `/v1.1/profile/search${query || '?'}`,
    {
      ...data,
    },
    {},
    {
      cancelToken,
    }
  )
    .then((res) => {
      dispatch({
        type: FETCH_ALL_PROFILE,
        payload: res.result,
      });
    })
    .catch(() => {
      dispatch({
        type: PROFILE_REQUEST,
        payload: {
          error: true,
          loading: false,
        },
      });
    });
};

export const fetchRandomProfiles =
  (data, query = '', limit = 3) =>
  (dispatch) => {
    API('POST', `/v1.1/profile/random?limit=${limit}&query=${query}`, {
      ...data,
    })
      .then((res) => {
        dispatch({
          type: RANDOM_PROFILE,
          payload: res.result,
          query,
        });
      })
      .catch((err) => {
        return alert(err.message);
      });
  };

export const fetchRandomProfilesHomePage =
  (data, query = '', limit = 3) =>
  (dispatch) => {
    API('POST', `/v1.1/profile/random?limit=${limit}&query=${query}`, {
      ...data,
    })
      .then((res) => {
        dispatch({
          type: RANDOM_PROFILE_HOMEPAGE,
          payload: res.result,
          query,
        });
      })
      .catch((err) => {
        return alert(err.message);
      });
  };

export const fetchSingleProfile = (username, data) => (dispatch) => {
  API('POST', `/v1.1/profile/${username}`, { ...data })
    .then((res) => {
      dispatch({
        type: FETCH_PROFILE,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchUserAvailablity = (user) => (dispatch) => {
  const data = {
    user_id: user.user_id,
    date: user.date,
    is_weekend: user.is_weekend,
    time_zone: DateTime.local().zoneName,
  };

  API('POST', '/v1/user/availability/date', data)
    .then((res) => {
      dispatch({
        type: AVAILABLITY_DAY,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchUserAvailablityAll = () => (dispatch) => {
  API('GET', '/v1/user/availability/all')
    .then((res) => {
      dispatch({
        type: AVAILABLITY_ALL,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const getuserCalendardata = (username, currency) => (dispatch) => {
  dispatch({
    type: PROFILE_REQUEST,
    payload: {
      loading: true,
    },
  });

  let reqBody = {};

  if (currency && currency.currencyCode) {
    reqBody = {
      currency,
    };
  }

  API('POST', `/v1.1/profile/${username}/booking`, {
    ...reqBody,
  })
    .then((res) => {
      dispatch({
        type: FETCH_PROFILE,
        payload: res.result,
      });
    })
    .catch((err) => {
      dispatch({
        type: PROFILE_REQUEST,
        payload: {
          loading: false,
          error: true,
          errorData: err || {},
        },
      });
      return alert(err.message);
    });
};

export const syncAvailabiltyAll = (availlabilty) => (dispatch) => {
  dispatch({
    type: AVAILABLITY_ALL,
    payload: availlabilty,
  });

  if (availlabilty.availability) {
    return dispatch({
      type: ACCOUNT_COMPLETE,
      payload: 4,
    });
  }
  return false;
};

export const clearProfiles = () => (dispatch) => {
  dispatch({
    type: FETCH_ALL_PROFILE,
    payload: [],
  });
};
