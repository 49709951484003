/*
 * Set app payment return url
 **/
function setAppPaymentReturnUrl(url: string): void {
	window.localStorage.setItem('appPaymentReturnUrl', url);
}

/*
 * Get app payment return url
 **/
function getAppPaymentReturnUrl(): string | null {
	const appPaymentReturnUrl = window.localStorage.getItem(
		'appPaymentReturnUrl'
	);

	if (appPaymentReturnUrl) {
		window.localStorage.removeItem('appPaymentReturnUrl');
		return appPaymentReturnUrl;
	}

	return null;
}

export { setAppPaymentReturnUrl, getAppPaymentReturnUrl };
