import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './PopUp.module.css';
import { alert, isFreeSession } from '../../../../../utils';
import message from '../../../../../config/message';

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusl: false,
      focusr: false,
      selectedStart: '',
      selectedEnd: '',
      timeDiff: '',
      timeRange: [],
      timeRangeRight: [],
    };

    this.handleOnCLickControl = this.handleOnCLickControl.bind(this);
    this.getTimeSlots = this.getTimeSlots.bind(this);
    this.selectTimeSlot = this.selectTimeSlot.bind(this);
    this.editTimeSlots = this.editTimeSlots.bind(this);
  }

  componentDidMount() {
    const { start, end } = this.props;

    this.getTimeSlots(start, end);
  }

  getTimeSlots(start, end) {
    const today = moment().add(24, 'hours');
    const startTime = moment(start);
    const minutes = today.minutes();

    let nextslot;

    if (minutes > 30) {
      nextslot = today.startOf('hour').add(60, 'minutes');
    } else {
      nextslot = today.startOf('hour').add(30, 'minutes');
    }

    if (today.isAfter(startTime)) {
      const currentMoment = moment(nextslot);
      const endMoment = moment(end);
      const timezone = [];

      while (currentMoment.isSameOrBefore(endMoment, 'minutes')) {
        timezone.push(currentMoment.format());
        currentMoment.add(30, 'minutes');
      }

      const timeRangeRight = [...timezone];

      timezone.pop();
      timeRangeRight.shift();

      return this.setState({
        timeRange: timezone,
        timeRangeRight,
        selectedStart: timezone[0],
        selectedEnd: timeRangeRight[0],
        timeDiff: moment.duration(moment(timeRangeRight[0]).diff(timezone[0])).asHours(),
      });
    }
    const currentMoment = moment(start);
    const endMoment = moment(end);
    const timezone = [];

    while (currentMoment.isSameOrBefore(endMoment, 'minutes')) {
      timezone.push(currentMoment.format());
      currentMoment.add(30, 'minutes');
    }

    const timeRangeRight = [...timezone];

    timezone.pop();
    timeRangeRight.shift();

    return this.setState({
      timeRange: timezone,
      timeRangeRight,
      selectedStart: timezone[0],
      selectedEnd: timeRangeRight[0],
      timeDiff: moment.duration(moment(timeRangeRight[0]).diff(timezone[0])).asHours(),
    });
  }

  handleOnCLickControl = (e, type) => {
    const { focusl, focusr } = this.state;
    if (type === 1) {
      this.setState({ focusr: false, focusl: !focusl });
    }

    if (type === 2) {
      this.setState({ focusr: !focusr, focusl: false });
    }
  };

  handleButton = (e, type) => {
    const { onCancel } = this.props;
    if (type === 'cancel') {
      onCancel(e);
    }
  };

  editTimeSlots(start, end) {
    const currentMoment = moment(start);
    const endMoment = moment(end);
    const timezone = [];

    while (currentMoment.isSameOrBefore(endMoment, 'minutes')) {
      timezone.push(currentMoment.format());
      currentMoment.add(30, 'minutes');
    }

    const timeRangeRight = [...timezone];
    timeRangeRight.shift();

    return this.setState({ timeRangeRight });
  }

  selectTimeSlot(time, type) {
    const { end } = this.props;
    if (type === 1) {
      const selectedEnd = moment(time).add(30, 'minutes').format();
      this.editTimeSlots(selectedEnd, end);
      this.setState({
        selectedStart: time,
        selectedEnd,
        focusl: false,
      });
      this.setState({
        timeDiff: moment.duration(moment(selectedEnd).diff(time)).asHours(),
      });
    }

    if (type === 2) {
      this.setState({ selectedEnd: time, focusr: false });
      this.setState((prevState) => ({
        timeDiff: moment.duration(moment(time).diff(prevState.selectedStart)).asHours(),
      }));
    }
  }

  render() {
    const { start, onBook, profile, user, isLoggedIn } = this.props;
    const { isFreeSessionOpted } = profile;
    const { isFreeSessionConsumed } = user;

    const { focusl, focusr, selectedStart, timeRange, selectedEnd, timeRangeRight, timeDiff } =
      this.state;

    const sborder = cx(styles.selectcontrol, styles.scontrolborder);
    return (
      <div className={styles.popup}>
        <div className={styles.lightbg} />
        <div className={styles.pbody}>
          <div className={styles.pcard}>
            <div className={styles.pcdate}>
              <p>{moment(start).format('DD MMMM YYYY')}</p>
            </div>
            <div className={styles.pselect}>
              <div className={styles.pico}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+dJREFUWAnNWEFPE0EUbgvSkBJM9KJiTGhMTDhoNBDxYICDlxItUtoDHPwPJl6UaCRw5eZPKIfSIiGRi0QhHkQl4WBCgjFwEeTihdCDSMHvm7632XZ3S0tbdJLp7LyZ933fvn07M1uf7z8p/kp0pFKppkAg0Hd0dPQAfh1oL6FlZdn2+/3baNfQzh0eHr5PJBL7ZqSMn7KEQMAFYD1HHUFtLQOXU3ZRk6hjELRDQ6lSUsj8/Hwwm80+w50/BkiIQLjbVfRnGxoaPuB66+DgYDsYDPpzudxF2NvQ3oV9ANc3hTiL/mQoFJqIRCK/xeZoPIVIFF7Do1u80mhHcXfrDhQXA/yvwTyOOiTDy2gfekXHVUg6nb6OO3qDehnOm7j74VgsRqCKSyaT6UaUpuDYjsj8AGYEYr4WAzmEMBJw+CIilhobG2ODg4O/ih0r6c/MzJzHI8zAp0fEdBVHpkAIc2Jvb28RDnwcS+Fw+F5nZ+cfXFddVlZWzmxsbLwFUA/qcktLS689ZwJ2BiYm+hSxyUjUSgQ5iNXc3Mx82UTtFi4OmWJFRJLzO6wh5MSdk+aE4Ho2kjMfMSGLelUfkT0iXCf4iqbrJQLYPsHmG0gucppihCAaTehxsWIZzTfV/QLzHOo7Vhck5RgRbp8RwmUbk1uR0asIVVnrhAu4ZaIIYC3AQNyz1oBckINc6LYKd14IXlXuHT60szLXtcH6MjA9PR13HRSjigAWV9ZvWHXvu81XLuXWHOngZC7bbk5qw0b2Co7gSvHtchQXEX3RaJQboaPYuAy3EQJws4MiXFsOj0LDE3RzqOPFYioRQUjlUm6NiBHCDYyTvAqe7RQAHmG8QEylIoiPiPwUHsOtQsR2fBOPx5N2MciZCfQXcGeaE56PoxS6CjGRwGpq1JVy4JhdDAQ8PYkIHhuEx3AbIbgj0wFg23EidNwuBrZ1vB0VRUK5lFsjskYCHmqUqJyWYiDgCjawG15vhxeOjctwa0TmxCHq5ehlpwD7Luo1r9iOSAzQhtZwGyE86MK2i3oLbwBPVnUt5JC82hXu/MqK13IfyqaEnce7ehflSJKbZJojXN5fos+teYhbNQfrUQSb5xJyjSmHJQTKdhCVSQ4gXEke73RSrVpiyvmVuTFJTsW2hNDAIz8mfEJ0wnBI83inE6ttiUVM4LSjLpPLjum3d3iNRLIOzxC1iKV4qBaHZ4rADfYCkyd5x+G5ICIUIo+oXxx6AfC5mpyhL/YwfhUYEcDttz8ScrI4IpI3W5GZBcBtsZ3+B5aKKfeTk/O5T0F07T85VQxbyZsXIBpG9/Q/wu1iRFDd/pYo5vpn/b+UAxeKzwSaBwAAAABJRU5ErkJggg=="
                  alt="select"
                  width="26px"
                  height="26px"
                />
              </div>
              <div className={styles.select}>
                <div
                  className={focusl ? sborder : styles.selectcontrol}
                  onClick={(e) => this.handleOnCLickControl(e, 1)}
                  onKeyPress={() => console.log('Key pressed')}
                  role="button"
                  tabIndex="0"
                >
                  <p className={styles.default}>{moment(selectedStart).format('hh:mm a')}</p>
                </div>
                {focusl && (
                  <div className={styles.selectdata}>
                    {timeRange.map((time, index) => (
                      <p
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={styles.sitem}
                        onClick={() => this.selectTimeSlot(time, 1)}
                        onKeyPress={() => console.log('Key pressed')}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex="0"
                      >
                        {moment(time).format('hh:mm a')}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.pto}>
                <p>to</p>
              </div>
              <div className={styles.pico}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+dJREFUWAnNWEFPE0EUbgvSkBJM9KJiTGhMTDhoNBDxYICDlxItUtoDHPwPJl6UaCRw5eZPKIfSIiGRi0QhHkQl4WBCgjFwEeTihdCDSMHvm7632XZ3S0tbdJLp7LyZ933fvn07M1uf7z8p/kp0pFKppkAg0Hd0dPQAfh1oL6FlZdn2+/3baNfQzh0eHr5PJBL7ZqSMn7KEQMAFYD1HHUFtLQOXU3ZRk6hjELRDQ6lSUsj8/Hwwm80+w50/BkiIQLjbVfRnGxoaPuB66+DgYDsYDPpzudxF2NvQ3oV9ANc3hTiL/mQoFJqIRCK/xeZoPIVIFF7Do1u80mhHcXfrDhQXA/yvwTyOOiTDy2gfekXHVUg6nb6OO3qDehnOm7j74VgsRqCKSyaT6UaUpuDYjsj8AGYEYr4WAzmEMBJw+CIilhobG2ODg4O/ih0r6c/MzJzHI8zAp0fEdBVHpkAIc2Jvb28RDnwcS+Fw+F5nZ+cfXFddVlZWzmxsbLwFUA/qcktLS689ZwJ2BiYm+hSxyUjUSgQ5iNXc3Mx82UTtFi4OmWJFRJLzO6wh5MSdk+aE4Ho2kjMfMSGLelUfkT0iXCf4iqbrJQLYPsHmG0gucppihCAaTehxsWIZzTfV/QLzHOo7Vhck5RgRbp8RwmUbk1uR0asIVVnrhAu4ZaIIYC3AQNyz1oBckINc6LYKd14IXlXuHT60szLXtcH6MjA9PR13HRSjigAWV9ZvWHXvu81XLuXWHOngZC7bbk5qw0b2Co7gSvHtchQXEX3RaJQboaPYuAy3EQJws4MiXFsOj0LDE3RzqOPFYioRQUjlUm6NiBHCDYyTvAqe7RQAHmG8QEylIoiPiPwUHsOtQsR2fBOPx5N2MciZCfQXcGeaE56PoxS6CjGRwGpq1JVy4JhdDAQ8PYkIHhuEx3AbIbgj0wFg23EidNwuBrZ1vB0VRUK5lFsjskYCHmqUqJyWYiDgCjawG15vhxeOjctwa0TmxCHq5ehlpwD7Luo1r9iOSAzQhtZwGyE86MK2i3oLbwBPVnUt5JC82hXu/MqK13IfyqaEnce7ehflSJKbZJojXN5fos+teYhbNQfrUQSb5xJyjSmHJQTKdhCVSQ4gXEke73RSrVpiyvmVuTFJTsW2hNDAIz8mfEJ0wnBI83inE6ttiUVM4LSjLpPLjum3d3iNRLIOzxC1iKV4qBaHZ4rADfYCkyd5x+G5ICIUIo+oXxx6AfC5mpyhL/YwfhUYEcDttz8ScrI4IpI3W5GZBcBtsZ3+B5aKKfeTk/O5T0F07T85VQxbyZsXIBpG9/Q/wu1iRFDd/pYo5vpn/b+UAxeKzwSaBwAAAABJRU5ErkJggg=="
                  alt="select"
                  width="26px"
                  height="26px"
                />
              </div>
              <div className={styles.select}>
                <div
                  className={focusr ? sborder : styles.selectcontrol}
                  onClick={(e) => this.handleOnCLickControl(e, 2)}
                  onKeyPress={() => console.log('Key pressed')}
                  role="button"
                  tabIndex="0"
                >
                  <p className={styles.default}>{moment(selectedEnd).format('hh:mm a')}</p>
                </div>
                {focusr && (
                  <div className={styles.selectdata}>
                    {timeRangeRight.map((time, index) => (
                      <p
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={styles.sitem}
                        onClick={() => this.selectTimeSlot(time, 2)}
                        onKeyPress={() => console.log('Key pressed')}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex="0"
                      >
                        {moment(time).format('hh:mm a')}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.sslot}>
              <p>
                Selected slot{' '}
                {`${moment(selectedStart).format('hh:mm a')} ${moment(selectedEnd).format(
                  'hh:mm a'
                )} (${timeDiff} hours)`}
              </p>
              {isFreeSession(isFreeSessionOpted, isFreeSessionConsumed, isLoggedIn) && (
                <p className={styles.binfo}>
                  Only FIRST consultations that are up to 30 min are free
                </p>
              )}
            </div>
            <div className={styles.pbtn}>
              <div className={styles.cancel}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={(e) => this.handleButton(e, 'cancel')}
                >
                  Cancel
                </button>
              </div>
              <div className={styles.book}>
                {isFreeSession(isFreeSessionOpted, isFreeSessionConsumed, isLoggedIn) &&
                timeDiff <= 0.5 ? (
                  <button
                    type="button"
                    className={styles.buttonfree}
                    onClick={(e) =>
                      onBook(e, {
                        start: selectedStart,
                        end: selectedEnd,
                        timeDiff,
                        isFreeSession: isFreeSession(
                          isFreeSessionOpted,
                          isFreeSessionConsumed,
                          isLoggedIn
                        ),
                      })
                    }
                  >
                    BOOK FREE SESSION
                  </button>
                ) : (
                  <button
                    type="button"
                    className={styles.button}
                    onClick={(e) =>
                      onBook(e, {
                        start: selectedStart,
                        end: selectedEnd,
                        timeDiff,
                        isFreeSession: isFreeSession(
                          isFreeSessionOpted,
                          isFreeSessionConsumed,
                          isLoggedIn
                        ),
                      })
                    }
                  >
                    BOOK
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PopUp.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  onCancel: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    isFreeSessionOpted: PropTypes.bool.isRequired,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    isFreeSessionConsumed: PropTypes.bool,
  }).isRequired,
};

export default PopUp;
