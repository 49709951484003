/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.css';
import EditInput from '../../Common/EditInput/EditInput';
import globalProptypes from '../../../config/proptype';
import EditSelect from '../../Common/EditSelect/EditSelect';
import ToggleSwitch from '../../Common/ToggleSwitch';
import info from '../../Common/assets/info.svg';

function SoleTraderForm(props) {
  const { businessDetails, onChange, countriesMasterdata, selectedCountry, user } = props;
  const { businessName, businessRegistrationNo, vatNo, address, city, postalCode } =
    businessDetails;

  const [isVatRegistered, setisVatRegistered] = useState(false);
  const [isSme, setisSme] = useState(true);
  const [isSmeDisabled, setisSmeDisabled] = useState(true);

  const { countryCode } = user.country;

  return (
    <div className={styles.formBusiness}>
      <ReactTooltip
        id="smetip"
        place="top"
        type="warning"
        effect="solid"
        className={styles.toolttip}
      />
      <div className={styles.form}>
        <div className={styles.left}>
          <EditInput
            htmlFor="Business Name"
            label="Business Name"
            value={businessName}
            placeholder="Business Name"
            name="businessName"
            required
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
          />
          <EditInput
            htmlFor="Address"
            label="Address"
            value={address}
            placeholder="Address"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="address"
            required
          />
          <EditInput
            htmlFor="Postal Code"
            label="Postal Code"
            value={postalCode}
            placeholder="Postal Code"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="postalCode"
            required
          />

          <div className={styles.vat}>
            <div className={styles.vatTextGroup}>
              <p className={styles.vtext}>My business is registered for VAT</p>
              <ToggleSwitch
                onChange={(toggle) => {
                  setisVatRegistered(toggle);
                  if (toggle) {
                    setisSme(false);
                    onChange({ name: 'business', value: { isVatRegistered: toggle } });
                    onChange({ name: 'business', value: { isSmallEnterprise: false } });
                    if (
                      countryCode === 'MT' ||
                      countryCode === 'GR' ||
                      countryCode === 'FR' ||
                      countryCode === 'BE'
                    ) {
                      setisSmeDisabled(false);
                    }
                  } else {
                    setisSme(true);
                    setisSmeDisabled(true);
                    onChange({
                      name: 'business',
                      value: { isSmallEnterprise: true, isVatRegistered: toggle },
                    });
                  }
                }}
                switchStyle={styles.switchStyle}
                checkboxStyle={styles.checkboxStyle}
                isChecked={isVatRegistered}
                name="isVatRegistered"
              />
            </div>
            {isVatRegistered && (
              <EditInput
                htmlFor="VAT number"
                label="VAT number"
                value={vatNo}
                placeholder="VAT number"
                onChange={(e) =>
                  onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
                }
                required
                name="vatNo"
              />
            )}
          </div>

          <div className={styles.vat}>
            <div className={styles.vatTextGroup}>
              <p className={styles.vtext}>Are you an SME exempt from VAT?</p>
              <img
                data-for="smetip"
                data-tip="Are you a small or medium-sized enterprise that is exempt from VAT obligations in your country of establishment. Selection of this field may be disabled due to applicable country-specific VAT rules applied to your provided business details."
                src={info}
                width="15px"
                height="15px"
                alt="Info"
                style={{ marginRight: '10px' }}
              />
              <ToggleSwitch
                onChange={(toggle) => {
                  onChange({ name: 'business', value: { isSmallEnterprise: toggle } });
                }}
                switchStyle={styles.switchStyle}
                checkboxStyle={styles.checkboxStyle}
                isChecked={isSme}
                isDisabled={isSmeDisabled}
                name="sme"
              />
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <EditInput
            htmlFor="Business Registration Number"
            label="Business Registration Number"
            value={businessRegistrationNo}
            placeholder="Business Registration Number"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="businessRegistrationNo"
          />
          <EditInput
            htmlFor="City"
            label="City"
            value={city}
            placeholder="City"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            name="city"
            required
          />

          <EditSelect
            label="Country"
            options={countriesMasterdata}
            selected={selectedCountry}
            placeholder="Select Country"
            onChange={(e) =>
              onChange({ name: 'business', value: { [e.target.name]: e.target.value } })
            }
            onActive={() => false}
            isDisabled={!!selectedCountry}
          />
        </div>
      </div>
    </div>
  );
}

SoleTraderForm.propTypes = {
  user: globalProptypes.user.isRequired,
  onChange: PropTypes.func.isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountry: PropTypes.shape({}).isRequired,
  businessDetails: globalProptypes.businessDetailsState,
};

SoleTraderForm.defaultProps = {
  businessDetails: {
    businessId: 0,
    businessName: '',
    businessRegistrationNo: '',
    typeOfBusiness: null,
    vatNo: '',
    userId: null,
    address: '',
    city: '',
    region: '',
    countryId: 0,
    postalCode: '',
    countryCode: '',
    isSmallEnterprise: false,
  },
};

export default SoleTraderForm;
