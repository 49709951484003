import React, { Component } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styles from './AccountSettings.module.css';
import settings_ico from '../../Common/assets/settings.svg';
import change_password from '../../Common/assets/change_password.svg';
import delete_ico from '../../Common/assets/delete_ico.svg';
import DeleteAccount from './DeleteAccount';
import { apiurl } from '../../../utils';
import ChangePassword from './ChangePassword';
import enums from '../../../config/enum';

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};

Modal.setAppElement('#root');

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: 0,
      changepass: false,
      error: false,
      deleting: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  openModal(e, type) {
    this.setState({ modalIsOpen: true, type });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  deleteAccount() {
    this.setState({ deleting: true });

    const { userToken } = this.props.user;

    const headers = {
      'Content-Type': 'application/json',
    };

    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    headers.Authorization = `Bearer ${userToken}`;

    axios
      .put(`${apiurl}/v1/user/delete`, {}, { headers })
      .then((result) => {
        if (result.data.success) {
          this.setState({ delete: true, deleting: false });
          this.props.onDeleteAccount();
          this.props.history.replace('/');
        }
      })
      .catch((err) => {
        this.setState({ error: true });
      });
  }

  onClickDelete() {
    this.deleteAccount();
  }

  render() {
    return (
      <div className={styles.personal}>
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={settings_ico} alt="settings" />
          <p className={styles.atitle}>Account Settings</p>
        </div>
        <div className={styles.abody}>
          {this.props.user.loginType === enums.loginType.email && (
            <div className={styles.changepas} onClick={(e) => this.openModal(e, 1)}>
              <img src={change_password} alt="Change Password" className={styles.chngpas} />
              <p className={styles.chngpp}>Change Password</p>
            </div>
          )}
          <div className={styles.changepas} onClick={(e) => this.openModal(e, 2)}>
            <img src={delete_ico} alt="Change Password" className={styles.chngpas} />
            <p className={styles.chngpp}>Delete Account</p>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Account Settings"
        >
          {this.state.type === 2 && (
            <DeleteAccount
              closeModal={this.closeModal}
              submitModal={this.onClickDelete}
              deleting={this.state.deleting}
            />
          )}
          {this.state.type === 1 && (
            <ChangePassword
              user={this.props.user}
              closeModal={this.closeModal}
              submitModal={this.onChangePassword}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default AccountSettings;
