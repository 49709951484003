import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Filter.module.css';

const getSuggestions = (value, categories) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : categories.filter((lang) => lang.label.toLowerCase().slice(0, inputLength) === inputValue);
};

const getSuggestionValue = (suggestion) => suggestion.label;

const renderSuggestion = (suggestion) => <div>{suggestion.label}</div>;

class CategoryFilter extends Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { suggestion } = this.props;
    this.setState({
      suggestions: suggestion,
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { suggestion } = this.props;
    if (value.length > 0) {
      this.setState({
        suggestions: getSuggestions(value, suggestion),
      });
    } else {
      this.setState({
        suggestions: suggestion,
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleKeyPress = (e) => {
    const { suggestions, value } = this.state;
    const { onAddCategoryFilter } = this.props;

    if (e.key === 'Enter' && suggestions.length === 0) {
      onAddCategoryFilter(value);
    }
  };

  handleOnSuggestionSelected = (e, value) => {
    e.preventDefault();
    const { onAddCategoryFilter } = this.props;
    onAddCategoryFilter(value.suggestionValue);
  };

  render() {
    const { value, suggestions } = this.state;
    const { placeholder, label, header, categoryShow, categories } = this.props;

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onKeyPress: this.handleKeyPress,
    };

    const rangelisif =
      categoryShow && categories.length > 0
        ? cx(styles.rangelis, styles.rangeop, styles.rangelisif)
        : cx(styles.rangelis, styles.rangeop);

    return (
      <div
        className={categoryShow ? rangelisif : styles.rangelis}
        ref={(node) => {
          this.categoryfilter = node;
        }}
      >
        <div className={styles.rtit}>
          <p>{label}</p>
        </div>
        <div className={styles.rbodyc}>
          <p className={styles.rbodytc}>{header}</p>
          <div className={styles.filinputlis}>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              theme={{
                container: styles.categorysuggest,
                input: styles.inputsuggest,
                suggestionsContainer: styles.suggestionsContainer,
                suggestionsContainerOpen: styles.suggestionsContainerOpen,
                suggestionsList: styles.suggestionsList,
                suggestion: styles.suggestion,
                suggestionHighlighted: styles.suggestionHighlighted,
                inputFocused: styles.inputFocused,
              }}
              onSuggestionSelected={this.handleOnSuggestionSelected}
              alwaysRenderSuggestions
            />
          </div>
        </div>
      </div>
    );
  }
}

CategoryFilter.propTypes = {
  suggestion: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddCategoryFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  categoryShow: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CategoryFilter;
