import React from 'react';
import styles from './style.module.scss';
import sessionwelcome from '../../../components/Common/assets/session_welcome.svg';

function Welcome() {
  return (
    <div className={styles.welcome}>
      <img src={sessionwelcome} alt="welcome" height="119px" width="119px" />
      <p className={styles.weltit}>Welcome</p>
      <p className={styles.welsub}>Start the conversation by typing below or sending a file</p>
    </div>
  );
}

export default Welcome;
