import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import conratiIconWhite from '../../assets/conratiWhite.svg';
import globalProptypes from '../../../config/proptype/index';
import enums from '../../../config/enum';

function DisconnectSuccessful({ apLink, userData }) {
  const { userType } = userData;

  return (
    <div className={style.disconnectSession}>
      <div className={style.card}>
        <div className={style.icon}>
          <img src={conratiIconWhite} alt="Conrati Logo" />
        </div>
        <div className={style.message}>
          <h6>Session Complete!</h6>
          <p className={style.msgtxt}>
            Thank you for using Conrati for your consulting needs. We would love to hear your
            feedback{' '}
            <span>
              <a className={style.link} href="mailto:contact@conrati.com">
                contact@conrati.com
              </a>
            </span>
          </p>
        </div>

        {userType === enums.userType.ar && (
          <div className={style.btns}>
            <a href="/search">Search</a>
            <a href={`/${apLink}`}>Book Again</a>
          </div>
        )}
        {userType === enums.userType.ap && (
          <div className={style.btnsc}>
            <a href="/bookinghistory">Booking History</a>
          </div>
        )}
      </div>
    </div>
  );
}

DisconnectSuccessful.propTypes = {
  apLink: PropTypes.string.isRequired,
  userData: globalProptypes.user.isRequired,
};

export default DisconnectSuccessful;
