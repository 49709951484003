import React from 'react';
import cm from 'classnames';
import styles from '../styles.module.scss';

function EarlyJoin() {
  return (
    <p className={cm(styles.ititle, styles.blinkMe)}>
      You have joined early. The session will begin at the allocated time.
    </p>
  );
}

export default EarlyJoin;
