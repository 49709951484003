import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from '../style.module.scss';
import clockIcon from '../../../../components/Common/assets/clock_white.svg';
import enums from '../../../../config/enum';
import globalProptypes from '../../../../config/proptype/index';
import { disconnectVideoSession } from '../../../../services/userService';

function Timer({ bookingData, userData }) {
  const { to } = bookingData;
  const currentTime = moment();
  const endTime = moment.unix(to);
  const [duration, setDuration] = useState(moment.duration(endTime.diff(currentTime)));

  useEffect(() => {
    const interVal = setInterval(() => {
      const hours = duration.hours();
      const min = duration.minutes();
      const seconds = duration.seconds();

      if (hours === 0 && min === 0 && seconds === 0) {
        const { bookingId } = bookingData;
        const reqData = {
          booking_id: bookingId,
          status: enums.bookingStatus.SUCCESSFUL,
        };
        disconnectVideoSession(reqData);

        window.location.href = `/session/disconnect?sessionStatus=${enums.sessionDisconnectType.Successful}&apUserName=${bookingData.apUserData.username}`;
        return 'redirected';
      }

      return setDuration(moment.duration(endTime.diff(currentTime)));
    }, 1000);
    return () => {
      clearInterval(interVal);
    };
  }, [
    currentTime,
    endTime,
    userData.userType,
    duration,
    bookingData.apUserData.username,
    bookingData,
  ]);

  return (
    <div className={style.clockTimer}>
      <div className={style.whiteBg} />
      <img src={clockIcon} alt="Clock Icon" />
      <p className={style.timer}>
        {duration.hours()}:{duration.minutes()}:{duration.seconds()}
      </p>
    </div>
  );
}

Timer.propTypes = {
  bookingData: PropTypes.shape({
    bookingId: PropTypes.string.isRequired,
    to: PropTypes.string,
    apUserData: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
  userData: globalProptypes.user.isRequired,
};

export default Timer;
