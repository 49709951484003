import React from 'react';
import style from './UserCard.module.scss';
import countryIcon from '../assets/location.svg';
import worldIcon from '../assets/world.svg';
import forwardCircleArrow from '../assets/forward-circle-arrow.svg';
import playBtn from '../assets/play-btn.svg';
import { User } from './user';

const CONRATI_VIDEO_ID = 'o0X_xJU7LUE';

interface UserCardProps {
	user: User;
}

function UserCard(props: UserCardProps): JSX.Element {
	const { user } = props;
	const skills = user.skills || [];

	const [skillsView, setSkillsView] = React.useState<string[]>(
		skills.slice(0, 6)
	);
	const [expanded, setExpanded] = React.useState<boolean>(false);

	const onUpdateSkillsView = (): void => {
		if (expanded) {
			setSkillsView(skills.slice(0, 6));
			setExpanded(false);
			return;
		}

		if (skills.length > 6) {
			setSkillsView(skills.slice(6, 12));
			setExpanded(true);
		}
	};

	return (
		<div className={style.userCardComponent}>
			<div className={style.userCardContainer}>
				<div className={style.topSection}>
					<div className={style.profileImgContainer}>
						<img
							src={user.primaryImg}
							alt="User"
							className={style.primaryImg}
							data-testid="ui-user-card-profile-img"
						/>
						{user.videoBio && !user.videoBio.includes(CONRATI_VIDEO_ID) && (
							<a
								href={`/${user.username}`}
								target="_blank"
								rel="noreferrer"
								className={style.bioPlayButton}
							>
								<img src={playBtn} alt="Play bio" />
							</a>
						)}
					</div>

					<div className={style.rate}>
						<p className={style.rateData}>
							<span
								className={style.hourlyRate}
								data-testid="ui-user-card-hourly-rate"
							>
								{user.hourlyRateWithSymbol}
							</span>{' '}
							<span>p/h</span>
						</p>
					</div>
				</div>
				<div className={style.container}>
					<div className={style.emptySpace} />

					<div className={style.content}>
						<div>
							<h6
								data-testid="ui-user-card-headline"
								className={style.headline}
								title={user.headline}
							>
								{user.headline}
							</h6>

							<h4
								data-testid="ui-user-card-user-name"
								className={style.name}
							>
								{user.firstName} {user.lastName}
							</h4>

							<div className={style.locationGroup}>
								<div className={style.country}>
									<img src={countryIcon} alt="Country" />
									<p className={style.countryName}>
										{user.country.countryName}
									</p>
								</div>
								<div className={style.language}>
									<img src={worldIcon} alt="Country" />
									{user.languages &&
										user.languages.map((lang) => (
											<p
												key={`lang-li-${lang.code}`}
												className={style.countryName}
											>
												{lang.language}
											</p>
										))}
								</div>
							</div>

							<div className={style.skillBlock}>
								{skillsView.map((skill, ind) => (
									<div
										key={`skill-li-${ind}`}
										className={style.skillData}
									>
										<div />
										<p className={style.skill}>{skill}</p>
									</div>
								))}

								{user.skills && user.skills.length > 6 && (
									<button
										type="button"
										className={
											expanded
												? `${style.expandBtn} ${style.rotate}`
												: style.expandBtn
										}
										onClick={(): void =>
											onUpdateSkillsView()
										}
									>
										<img
											src={forwardCircleArrow}
											alt="Expand"
										/>
									</button>
								)}
							</div>

							<div className={style.serviceDescription}>
								<p
									data-testid="ui-user-card-description"
									className={style.serviceText}
								>
									{user.serviceDescription}
									<a
										href={`/${user.username}`}
										target="_blank"
										rel="noreferrer"
										className={style.serviceShowMore}
									>
										more
									</a>
								</p>
							</div>
						</div>

						<div className={style.actionButtons}>
							<a
								href={`/${user.username}`}
								target="_blank"
								rel="noreferrer"
								className={`${style.actionBtn} ${style.previewBtn}`}
							>
								VIEW PROFILE
							</a>
							<a
								href={`/${user.username}/calendar`}
								target="_blank"
								rel="noreferrer"
								data-testid="ui-user-card-booking-btn"
								className={
									user.isFreeSessionOpted &&
									!user.isFreeSessionConsumed
										? `${style.actionBtn} ${style.bookingBtn}`
										: style.actionBtn
								}
							>
								{user.isFreeSessionOpted &&
								!user.isFreeSessionConsumed
									? 'BOOK FOR FREE'
									: 'BOOK SESSION'}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

UserCard.displayName = 'User Card';

export { UserCard };
