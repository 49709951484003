import React from 'react';
import styles from './TherapyHealthSection.module.scss';
import checkMarkSVG from '../../assets/svg/check-mark.svg';
import therapistCoachImg from '../../assets/svg/therapist-coach.svg';
import { consultingTherapyCategories } from '../../common/constants/data';
import Star from '../star';
import TherapyCategoryItem from './TherapyCategoryItem';

export default function TherapyHealthSection(): JSX.Element {
	return (
		<div className={styles.therapyHealthSection}>
			<h1 data-testid="quiz-mental-health-title" className={styles.title}>
				Meet our Network of Mental Health Professionals
			</h1>
			<p data-testid="quiz-mental-health-sub-title" className={styles.subTitle}>
				Our network of providers covers a range of specialities to meet your
				specific needs. Get matched today!
			</p>
			<div className={styles.container}>
				<div className={styles.therapyCategories}>
					{consultingTherapyCategories.map((therapyHealthItem, index) => (
						<TherapyCategoryItem
							category={therapyHealthItem}
							key={`mental-health-${index}`}
						/>
					))}
				</div>
				<div className={styles.getStarted}>
					<div className={styles.getStartedBtnContainer}>
						<button className={styles.getStartedBtn} type="button">
							GET MATCHED
						</button>
					</div>
					<div className={styles.therapist}>
						<div className={styles.therapistUser}>
							<img
								data-testid="quiz-mental-health-coach"
								src={therapistCoachImg}
								alt="Therapist Coach"
							/>
						</div>
						<img src={checkMarkSVG} alt="Check mark" />
						<div className={styles.therapistDetails}>
							<div className={styles.therapistStars}>
								<Star />
								<Star />
								<Star />
								<Star />
							</div>
							<div
								data-testid="quiz-mental-health-therapist-title"
								className={styles.therapistTitle}
							>
								Rudsel Mduro, Psychologist
							</div>
							<div className={styles.therapistTopics}>
								<img src={checkMarkSVG} alt="Check mark" />
								<span data-testid="quiz-mental-health-therapist-topics">
									Depression, Anxiety, Burnout
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
