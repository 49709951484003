import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './EditInput.module.css';

class EditTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      focused: false,
      required: false,
      noticeText: props.notice,
    };
    this.showLabel = this.showLabel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    if (!this.props.value && this.props.required) {
      this.setState({ required: true });
    }

    const height = this.textarea.offsetHeight;
    const scrollheight = this.textarea.scrollHeight;

    if (height < scrollheight) {
      this.textarea.style.height = `${scrollheight}px`;
    }

    this.limitNotice(this.props.value ? this.props.value.length : 0);
  }

  showLabel(e, label) {
    this.setState({
      label: this.props.label,
      focused: true,
    });
  }

  hideLabel(e, label) {
    this.setState({
      label: this.props.label ? '' : label,
      focused: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  limitNotice(wordCount) {
    if (!this.props.required) {
      return;
    }

    let noticeText = '';

    if (wordCount >= 1) {
      if (wordCount === 1) {
        noticeText = '1 Character';
      } else if (wordCount < this.props.minLength || wordCount > this.props.maxLength) {
        noticeText = `${wordCount} Characters`;
      }

      if (noticeText) {
        noticeText += ' (Min 300 to Max 1000 Characters Required)';
      }
    } else if (wordCount === 0) {
      noticeText = this.props.notice;
    }

    this.setState({
      noticeText,
    });
  }

  onChange(e) {
    if (this.props.required && e.target.value.trim().length === 0) {
      this.setState({ required: true });
    } else {
      this.setState({ required: false });
    }

    this.limitNotice(e.target.value.trim().length);
    this.props.onChange(e);
  }

  onKeyUp(e) {
    e.preventDefault();
    const height = this.textarea.offsetHeight;
    const scrollheight = this.textarea.scrollHeight;

    if (height < scrollheight) {
      this.textarea.style.height = `${scrollheight}px`;
    }
  }

  render() {
    const {
      classes,
      style,
      htmlFor,
      label,
      value,
      placeholder,
      error,
      readOnly,
      name,
      type,
      notice,
      ...rest
    } = this.props;

    const stylescss = cx(styles.input, this.state.noticeText ? styles.inputField : '');
    const stylescss_focused = cx(
      styles.input,
      styles.inputf,
      this.state.noticeText ? styles.inputField : ''
    );
    return (
      <div className={styles.inputel}>
        <label htmlFor={htmlFor} className={styles.label}>
          {this.props.label}
        </label>
        <textarea
          {...rest}
          type={type || 'text'}
          className={this.state.focused ? stylescss_focused : stylescss}
          placeholder={placeholder}
          name={name}
          id={htmlFor}
          onFocus={(e) => this.showLabel(e, label)}
          onBlur={(e) => this.hideLabel(e, label)}
          onChange={(e) => this.onChange(e)}
          value={value}
          ref={(textarea) => {
            this.textarea = textarea;
          }}
          onKeyUp={this.onKeyUp}
        />

        {this.state.required && <p className={styles.error}>{label} Required</p>}
        {!this.state.required && this.state.noticeText && (
          <p className={styles.error}>{this.state.noticeText}</p>
        )}
      </div>
    );
  }
}

EditTextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.any,
  refInput: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
};

export default EditTextArea;
