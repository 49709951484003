/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ProfileHead from '../ProfileHead/ProfileHead';
import Header from '../../Headers/Headers';
import {
  fetchUserAvailablity,
  syncAvailabiltyAll,
  getuserCalendardata,
} from '../../../actions/profileAction';
import UserCalendar from './UserCalendar/UserCalendar';
import styles from './ProfileCalendar.module.css';
import { isObjectEmpty } from '../../../utils';
import PageLoader from '../../Common/PageLoader/PageLoader';
import enums from '../../../config/enum';
import globalProptype from '../../../config/proptype';

class ProfileCaledar extends Component {
  componentDidMount() {
    this.getUserId();
  }

  getUserId() {
    const { match, user, history, onFetchProfile, currency } = this.props;
    if (match) {
      const { username } = match.params;
      if (user.userType === enums.userType.ap) {
        const { pathname, search } = window.location;
        const encodeURL = encodeURIComponent(`${pathname}${search}`);

        history.replace({
          pathname: '/myaccount/switch',
          search: `?redirect=${encodeURL}`,
        });
      }

      onFetchProfile(username, currency);
    }
  }

  render() {
    const { loading, profile, user } = this.props;

    return (
      <div className={styles.profilemain}>
        <Header />
        {loading && <PageLoader />}
        {!loading && (
          <div>
            <ProfileHead {...profile} userData={user} />
            <div className={styles.usercalendar}>
              <div className={styles.calendarshow}>
                {!isObjectEmpty(profile) && <UserCalendar {...this.props} />}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProfileCaledar.propTypes = {
  match: globalProptype.match.isRequired,
  history: globalProptype.history.isRequired,
  user: PropTypes.shape({
    userType: PropTypes.number,
  }).isRequired,
  onFetchProfile: PropTypes.func.isRequired,
  onFetchAvailabilty: PropTypes.func.isRequired,
  profile: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  currency: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : state.userState.currency;

  return {
    profile: state.profiles.profile,
    availbiltyDay: state.profiles.availbiltyDay,
    user: state.userState.user,
    availabilityAll: state.profiles.availabilityAll,
    loading: state.profiles.loading,
    currency,
    isLoggedIn: state.userState.isLoggedIn,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchProfile: getuserCalendardata,
      onFetchAvailabilty: fetchUserAvailablity,
      onSyncAvailabilty: syncAvailabiltyAll,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(ProfileCaledar);
