import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import styles from './Chat.module.css';
import sendAtive from '../../../Common/assets/video_send_active.svg';
import attachActive from '../../../Common/assets/video_attach_active.svg';
import sessionwelcome from '../../../Common/assets/session_welcome.svg';
import Sent from './sent';
import Sentfile from './sentfile';
import Recieved from './recieved';
import Recievedfile from './recievedfile';
import { apiurl } from '../../../../utils';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      all_messages: [],
      connectionId: '',
    };

    this.sendMessage = this.sendMessage.bind(this);
    this.hanldeSubmitMsg = this.hanldeSubmitMsg.bind(this);
    this.recieveMessage = this.recieveMessage.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onClickOnShare = this.onClickOnShare.bind(this);
    this.handleSharefile = this.handleSharefile.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    if (this.props.activeChat.length > 0) {
      this.setState({
        all_messages: this.props.activeChat,
      });
    }

    this.scrollToBottom();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.activeChat.length > 0) {
      this.setState({
        all_messages: newProps.activeChat,
      });
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  sendMessage = (e) => {
    e.preventDefault();
    this.setState({ msg: e.target.value });
  };

  hanldeSubmitMsg = () => {
    const { connectionId } = this.props.session.session.connection;
    const alias = this.props.session.session.connection.data.split(',');

    const dataMsg = {
      sentOn: moment().unix(),
      text: this.state.msg,
      sender: {
        alias: alias[0],
        id: connectionId,
      },
      type: 'msg',
    };

    this.props.session.session.signal({
      type: 'text-chat',
      data: JSON.stringify(dataMsg),
    });

    this.setState({ msg: '' });
  };

  onClickOnShare = () => {
    this.fileUpload.click();
  };

  onFileChange = (e) => {
    const file = e.target.files[0];
    this.handleSharefile(file);
  };

  handleSharefile = (image) => {
    let toastId = null;

    toastId = toast.warn('Uploading', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const formData = new FormData();

    formData.append('file', image);

    axios
      .post(`${apiurl}/v1/booking/session/upload`, formData, {
        headers,
        // onUploadProgress: p => {
        //     const progress = p.loaded / p.total;

        //     // check if we already displayed a toast
        //     if(toastId === null){
        //         toastId = toast.warn('Uploading', {
        //         position: "top-center",
        //         autoClose: false,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //       });
        //     } else {
        //       toast.update(toastId, {
        //         toastId = toast.warn('Uploading', {
        //         position: "top-center",
        //         autoClose: false,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //       })
        //     }
        // }
      })
      .then((result) => {
        toast.update(toastId, {
          render: 'Uploaded',
          autoClose: 1000,
        });

        const { connectionId } = this.props.session.session.connection;
        const alias = this.props.session.session.connection.data.split(',');

        const dataMsg = {
          sentOn: moment().unix(),
          file: result.data.result[0],
          sender: {
            alias: alias[0],
            id: connectionId,
          },
          type: 'file',
        };

        this.props.session.session.signal({
          type: 'share-file',
          data: JSON.stringify(dataMsg),
        });
      });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.hanldeSubmitMsg();
    }
  };

  recieveMessage = (data) => {
    this.props.onNewMessage(data);
  };

  scrollToBottom = () => {
    this.chatScreen.scrollTop = this.chatScreen.scrollHeight;
  };

  render() {
    const welcome = (
      <div className={styles.welcome}>
        <img src={sessionwelcome} alt="welcome" height="119px" width="119px" />
        <p className={styles.weltit}>Welcome</p>
        <p className={styles.welsub}>Start the conversation by typing below or sending a file</p>
      </div>
    );
    return (
      <div className={styles.chat}>
        <div className={styles.chatmessage}>
          <div
            className={styles.chatscreen}
            ref={(el) => {
              this.chatScreen = el;
            }}
          >
            {this.state.all_messages.length === 0 && welcome}

            {this.state.all_messages.length > 0 &&
              this.state.all_messages.map((message, index) => {
                if (message.type === '1') {
                  return (
                    <Sent
                      key={index}
                      message={message.msg}
                      from={message.sender}
                      time={message.time}
                    />
                  );
                }
                if (message.type === '2') {
                  return (
                    <Recieved
                      key={index}
                      message={message.msg}
                      from={message.sender}
                      time={message.time}
                    />
                  );
                }
                if (message.type === '3') {
                  return (
                    <Sentfile
                      key={index}
                      filename={message.filename}
                      link={message.link}
                      from={message.sender}
                      time={message.time}
                    />
                  );
                }
                if (message.type === '4') {
                  return (
                    <Recievedfile
                      key={index}
                      filename={message.filename}
                      link={message.link}
                      from={message.sender}
                      time={message.time}
                    />
                  );
                }
                return null;
              })}
          </div>
        </div>

        <div className={styles.sinput}>
          <input
            className={styles.inputt}
            type="text"
            value={this.state.msg}
            placeholder="Type here to chat ..."
            onKeyPress={this.handleKeyPress}
            onChange={this.sendMessage}
          />
          <div className={styles.sico}>
            <div className={styles.scbtn}>
              <button onClick={this.hanldeSubmitMsg}>
                <img
                  data-tip="Send"
                  data-for="chat"
                  src={sendAtive}
                  alt="send"
                  width="100%"
                  height="100%"
                />
              </button>
            </div>
            <div className={styles.scbtn}>
              <button onClick={this.onClickOnShare}>
                <input
                  type="file"
                  accept="*"
                  onChange={this.onFileChange}
                  style={{ display: 'none' }}
                  ref={(ref) => {
                    this.fileUpload = ref;
                  }}
                />
                <img
                  data-tip="Share File"
                  data-for="chat"
                  src={attachActive}
                  alt="attach"
                  width="100%"
                  height="100%"
                />
              </button>
            </div>
          </div>
        </div>
        <ReactTooltip place="left" id="chat" className={styles.customeTheme} effect="solid" />
      </div>
    );
  }
}

export default Chat;
