/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import './calendarcss.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import styles from './UserCalendar.module.css';
import Toolbar from './Toolbar/Toolbar';
import CalendarEvents from './Events/CalendarEvents';
import {
  isWeekend,
  alert,
  timeToNumber,
  getRandomID,
  numToTime,
  checkIsMobileBrowser,
} from '../../../../utils';
import PopUp from './PopUp/PopUp';
import messages from '../../../../config/message';
import globalProptype from '../../../../config/proptype';
import { fetchDailyAvailabilty, fetchUserAvailablity } from '../../../../services/userService';

class UserCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDate: moment().add(1, 'day'),
      activeView: 'month',
      month: moment().month(),
      events: [],
      dateOfMonth: moment(),
      eventpopUp: false,
      selectedEvent: {},
      fetching: false,
    };
  }

  componentDidMount() {
    this.onFetchMonthEvents();
  }

  onNavigate = async (activeDate) => {
    const { activeView } = this.state;
    if (activeView === 'day') {
      await this.setState({ activeDate });
      this.onFetchDayEvens(activeDate);
    }

    if (activeView === 'month') {
      this.setState({ activeDate: moment().set({ month: activeDate.month(), date: 1, year: activeDate.year() }) });
    }
  };

  onFetchMonthEvents = () => {
    const { profile } = this.props;
    const { activeDate } = this.state;
    const { isWeekdayOn, isWeekendOn } = profile.availability;
    const currentDate = activeDate.clone();

    const numberOfDaysEvent = currentDate.daysInMonth() + currentDate.add(1, 'M').daysInMonth();

    const events = [];

    for (let counterDate = activeDate.date(); counterDate < numberOfDaysEvent; counterDate += 1) {
      const itrDate = moment().set({
        date: counterDate,
        month: activeDate.month(),
        year: activeDate.year(),
      });

      const event = {
        id: counterDate,
        title: checkIsMobileBrowser(navigator.userAgent) ? 'Book' : 'Available For Booking',
        date: itrDate.toDate(),
        start: itrDate.toDate(),
        end: itrDate.toDate(),
      };

      if (isWeekdayOn && isWeekendOn) {
        events.push(event);
      }

      if (isWeekdayOn && !isWeekendOn) {
        if (!isWeekend(itrDate)) {
          events.push(event);
        }
      }

      if (!isWeekdayOn && isWeekendOn) {
        if (isWeekend(itrDate)) {
          events.push(event);
        }
      }
    }

    this.setState({ events });
  };

  // Future Cancel previous pending network request if date is changed

  onFetchDayEvens = (date) => {
    const { profile } = this.props;
    const { activeDate } = this.state;

    if (isWeekend(date) && !profile.availability.isWeekendOn) {
      return alert(messages.noAvailbleSlots);
    }

    if (!isWeekend(date) && !profile.availability.isWeekdayOn) {
      return alert(messages.noAvailbleSlots);
    }

    this.setState({ fetching: true });

    fetchUserAvailablity(profile.userId, {
      date: date.format('YYYY-MM-DD'),
      userTimeZone: profile.timeZone,
    })
      .then((resp) => {
        const userEvents = [];
        const events = resp.data;

        if (events.length === 0) {
          throw new Error(messages.noAvailbleSlots);
        }

        events.forEach((event) => {
          const buildEvent = {
            id: getRandomID(),
            title: 'Available For Booking',
            date: activeDate.toDate(),
            start: moment(event.startTime).toDate(),
            end: moment(event.endTime).toDate(),
          };

          userEvents.push(buildEvent);
        });

        this.setState({ events: userEvents, fetching: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
        alert(messages.noAvailbleSlots);
      });
  };

  onSelectEvent = async (event) => {
    const { activeView } = this.state;
    if (activeView === 'month') {
      await this.setState({
        activeDate: moment(event.start),
        activeView: 'day',
        events: [],
      });

      this.onFetchDayEvens(moment(event.start));
    }

    if (activeView === 'day') {
      const { end } = event;
      const today = moment().add(24, 'hours');
      const endTime = moment(end);

      const minutes = today.minutes();

      let nextslot;

      if (minutes > 30) {
        nextslot = today.startOf('hour').add(60, 'minutes');
      } else {
        nextslot = today.startOf('hour').add(30, 'minutes');
      }

      if (endTime.isBefore(nextslot) || moment(nextslot).isSame(endTime, 'minute')) {
        alert(messages.pastTimeSlot);
      } else {
        this.setState({ selectedEvent: event, eventpopUp: true });
      }
    }
  };

  changeView = async (view) => {
    if (view === 'day') {
      await this.setState({
        activeDate: moment(),
        dateOfMonth: moment(),
        activeView: view,
        month: moment().month(),
        events: [],
      });

      this.onFetchDayEvens(moment());
    }

    if (view === 'month') {
      const { activeDate } = this.state;
      const currentDate = moment();
      let newDate = activeDate;
      if (activeDate.month() === currentDate.month()) {
        newDate = moment();
      } else {
        newDate = moment().set({ month: activeDate.month(), date: 1 });
      }

      await this.setState({ activeDate: newDate, activeView: view, events: [] });
      this.onFetchMonthEvents();
    }
  };

  // Open a specific date in view
  openDateEvents = async (date) => {
    await this.setState({ activeDate: date, activeView: 'day' });
    this.onFetchDayEvens(date);
  };

  eventPropGetter = (event) => {
    const { activeView } = this.state;
    if (activeView === 'day') {
      let eventColor = '';
      let color = '';

      switch (event.type) {
        case 'custom':
          eventColor = '#ffffff';
          color = '#555555';
          break;
        case 'recurring':
          eventColor = '#ffffff';
          color = '#555555';
          break;
        case 'external':
          eventColor = '#ffffff';
          color = '#555555';
          break;
        case 'booking':
          eventColor = '#ffffff';
          color = '#555555';
          break;
        default:
          break;
      }

      return {
        style: {
          boxSizing: 'border-box',
          border: 'none',
          display: 'block',
          backgroundColor: `${eventColor}`,
          backgroundImage: `linear-gradient(${eventColor} 24px, #fff 1px)`,
          backgroundSize: '1px 1px',
          color: `${color}`,
          borderRadius: '3px',
        },
      };
    }

    return {};
  };

  getNextMonth = async () => {
    const { month, dateOfMonth } = this.state;
    await this.setState({
      dateOfMonth: moment(dateOfMonth).add(1, 'months').startOf('month'),
      month: month + 1,
    });

    this.onFetchMonthEvents();
  };

  getPrevMonth = async () => {
    const { month, dateOfMonth } = this.state;
    if (month >= moment().format('M')) {
      await this.setState({
        dateOfMonth: moment(dateOfMonth).subtract(1, 'months').startOf('month'),
        month: month - 1,
      });

      this.onFetchMonthEvents();
    }
  };

  renderToolbar = () => {
    const { activeDate, activeView, month } = this.state;
    const ToolbarWithProps = (props) => (
      <Toolbar
        date={activeDate}
        activeView={activeView}
        month={month}
        onNavigate={(navigateDate) => this.onNavigate(navigateDate)}
        changeView={(view) => this.changeView(view)}
        getNextMonth={this.getNextMonth}
        getPrevMonth={this.getPrevMonth}
        label={props.label}
      />
    );
    return ToolbarWithProps;
  };

  renderEvent = () => {
    const EventWithProps = (props) => <CalendarEvents {...props} />;
    return EventWithProps;
  };

  onSelectSlotCalendar = (select) => {
    const { activeView } = this.state;
    const selectdate = moment(select.start).add(1, 'day');

    if (!moment().isBefore(selectdate)) {
      return toast.warn('You have selected a past date', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (activeView === 'month') {
      return this.openDateEvents(moment(select.start));
    }

    return false;
  };

  onPopUpCancel = () => {
    this.setState({ eventpopUp: false });
  };

  onBook = (e, event) => {
    const { history, profile } = this.props;

    if (event.timeDiff > 0.5) {
      return history.push({
        pathname: '/book',
        state: {
          start: event.start,
          end: event.end,
          apUserData: profile,
          timeDiff: event.timeDiff,
          isFreeSession: false,
        },
      });
    }

    return history.push({
      pathname: '/book',
      state: {
        start: event.start,
        end: event.end,
        apUserData: profile,
        timeDiff: event.timeDiff,
        isFreeSession: event.isFreeSession,
      },
    });
  };

  render() {
    const formats = {
      weekdayFormat: 'dddd',
      timeGutterFormat: 'hh a',
      dayFormat: (date) => {
        const momentDate = moment(date);
        const dayOfMonth = momentDate.format('DD');
        const month = momentDate.format('MM');
        const dayOfWeek = momentDate.format('ddd');
        return `${dayOfMonth}/${month} ${dayOfWeek}`;
      },
      eventTimeRangeFormat: ({ start, end }) =>
        `${moment(start).format('h:mma')} – ${moment(end).format('h:mma')}`,
    };

    const { selectedEvent, activeView, eventpopUp, events, activeDate, fetching } = this.state;
    const scrollSlot = events.length > 0 ? moment(events[0].start).subtract(1, 'h') : moment();
    const localizer = momentLocalizer(moment);

    const { user, profile, isLoggedIn } = this.props;

    return (
      <div className={styles.calcontainer}>
        {fetching && (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderWarning}>
              <p className={styles.loaderWarningText}>Fetching available slots</p>
              <div className={styles.loader} />
            </div>
            <div className={styles.loaderBg} />
          </div>
        )}

        <div style={{ height: '720px', width: '100%' }}>
          {eventpopUp && (
            <PopUp
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...selectedEvent}
              onCancel={this.onPopUpCancel}
              onBook={this.onBook}
              user={user}
              profile={profile}
              isLoggedIn={isLoggedIn}
            />
          )}
          <Calendar
            localizer={localizer}
            eventPropGetter={this.eventPropGetter}
            events={events}
            views={['month', 'day']}
            date={activeDate.toDate()}
            style={{
              height: '100%',
            }}
            formats={formats}
            selectable={false}
            onNavigate={this.onNavigate}
            culture="en"
            onSelectEvent={this.onSelectEvent}
            showMultiDayTimes={false}
            components={{
              toolbar: this.renderToolbar(),
              // event: this.renderEvent()
            }}
            scrollToTime={scrollSlot.toDate()}
            onView={this.changeView}
            view={activeView}
            onSelectSlot={this.onSelectSlotCalendar}
          />
        </div>
      </div>
    );
  }
}

UserCalendar.propTypes = {
  profile: PropTypes.shape({
    availability: PropTypes.object,
    userId: PropTypes.any,
    isFreeSessionOpted: PropTypes.bool.isRequired,
  }).isRequired,
  history: globalProptype.history.isRequired,
  user: PropTypes.shape({
    isFreeSessionConsumed: PropTypes.bool,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default UserCalendar;
