import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const DisconnectCall = ({ closeModal, submitModal, requestProcessing }) => {
  return (
    <div className={styles.disconnectSession}>
      <div className={styles.content}>
        <p>Do you want to end the session ?</p>
      </div>
      <div className={styles.buttons}>
        <button type="button" className={styles.btnc} onClick={() => closeModal()}>
          BACK
        </button>
        <button
          type="button"
          disabled={requestProcessing}
          className={styles.btn}
          onClick={() => submitModal()}
        >
          {requestProcessing ? 'Disconnecting' : 'Disconnect'}
        </button>
      </div>
    </div>
  );
};

DisconnectCall.propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
  requestProcessing: PropTypes.bool.isRequired,
};

export default DisconnectCall;
