/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import './range.css';
import InputRange from 'react-input-range';
import cx from 'classnames';
import moment from 'moment';
import pull from 'lodash/pull';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import styles from './Filter.module.css';
import arrowdown from '../../Common/assets/down_arrow.svg';
import arrowup from '../../Common/assets/arrow_up_white.svg';
import arrowgraywhite from '../../Common/assets/arrow_gray_white.svg';
import deleteactive from '../../Common/assets/delete_ACTIVE.svg';
import CategoryFilter from './CategoryFilter';
import DateFilter from './DateFilter';
import globalProptype from '../../../config/proptype';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeMin: 0,
      rangeMax: 0,
      rangeShow: false,
      rate: false,
      categoryShow: false,
      categories: [],
      skills: [],
      skillsShow: false,
      languagesShow: false,
      languages: [],
      dateShow: false,
      startDate: '',
      endDate: '',
      skillsMore: false,
      languagesMore: false,
      displatRangeMax: 0,
      displayrangeMin: 0,
      freeSessionFilter: false,
    };
  }

  componentDidMount() {
    const { location, currency } = this.props;
    const search = new URLSearchParams(location.search);
    if (search.has('free')) {
      this.setState({
        freeSessionFilter: true,
      });
    }

    if (search.has('rate')) {
      const rate = search.get('rate').split(',');
      if (rate.length === 2) {
        this.setState({
          rate: true,
          rangeMin: Number(rate[0]),
          rangeMax: Number(rate[1]),
        });
      }
    }

    if (search.has('category')) {
      const categories = search.get('category').split(',');
      if (categories.length > 0 && search.get('category').length > 0) {
        this.setState({
          categories,
        });
      }
    }

    if (search.has('languages')) {
      const languages = search.get('languages').split(',');
      if (languages.length > 0 && search.get('languages').length > 0) {
        this.setState({
          languages,
        });
      }
    }

    if (search.has('skills')) {
      const skills = search.get('skills').split(',');
      if (skills.length > 0 && search.get('skills').length > 0) {
        this.setState({
          skills,
        });
      }
    }

    if (search.has('startdate')) {
      const startdate = search.get('startdate');
      const enddate = search.get('enddate');

      this.setState({
        startDate: startdate,
        endDate: enddate,
      });
    } else {
      this.setState({
        startDate: '',
        endDate: '',
      });
    }

    this.calculateDisplayRange(currency.currencyCode);
  }

  componentDidUpdate(prevProps) {
    const { location, currency } = this.props;

    if (currency.currencyCode !== prevProps.currency.currencyCode) {
      this.calculateDisplayRange(currency.currencyCode);
    }

    if (!isEqual(location.search, prevProps.location.search)) {
      const search = new URLSearchParams(location.search);
      if (search.has('rate')) {
        const rate = search.get('rate').split(',');
        if (rate.length === 2) {
          this.setState({
            rate: true,
            rangeMin: Number(rate[0]),
            rangeMax: Number(rate[1]),
          });
        } else {
          this.setState({ rate: false });
        }
      }

      if (search.has('category')) {
        const categories = search.get('category').split(',');
        if (categories.length > 0 && search.get('category').length > 0) {
          this.setState({
            categories,
          });
        }
      } else {
        this.setState({
          categories: [],
        });
      }

      if (search.has('languages')) {
        const languages = search.get('languages').split(',');
        if (languages.length > 0 && search.get('languages').length > 0) {
          this.setState({
            languages,
          });
        }
      } else {
        this.setState({
          languages: [],
        });
      }

      if (search.has('skills')) {
        const skills = search.get('skills').split(',');
        if (skills.length > 0 && search.get('skills').length > 0) {
          this.setState({
            skills,
          });
        }
      } else {
        this.setState({
          skills: [],
        });
      }

      if (search.has('startdate')) {
        const startdate = search.get('startdate');
        const enddate = search.get('enddate');

        this.setState({
          startDate: startdate,
          endDate: enddate,
        });
      } else {
        this.setState({
          startDate: '',
          endDate: '',
        });
      }
    }
  }

  calculateDisplayRange = (countryCode) => {
    switch (countryCode) {
      case 'EUR':
        return this.setState({
          displatRangeMax: 300,
          displayrangeMin: 0,
        });
      case 'GBP':
        return this.setState({
          displatRangeMax: 300,
          displayrangeMin: 0,
        });
      case 'ZAR':
        return this.setState({
          displatRangeMax: 6500,
          displayrangeMin: 0,
        });
      default:
        return true;
    }
  };

  onChange = (value) => {
    this.setState({
      rangeMax: value.max,
      rangeMin: value.min,
    });
  };

  onClickFilterSelect = (type) => {
    const { rangeShow, categoryShow, skillsShow, languagesShow, dateShow } = this.state;
    if (type === 'range') {
      return this.setState({
        dateShow: false,
        rangeShow: !rangeShow,
        categoryShow: false,
        skillsShow: false,
        languagesShow: false,
      });
    }

    if (type === 'categories') {
      return this.setState({
        dateShow: false,
        categoryShow: !categoryShow,
        rangeShow: false,
        skillsShow: false,
        languagesShow: false,
      });
    }

    if (type === 'skills') {
      return this.setState({
        dateShow: false,
        skillsShow: !skillsShow,
        languagesShow: false,
        categoryShow: false,
        rangeShow: false,
      });
    }

    if (type === 'languages') {
      return this.setState({
        dateShow: false,
        skillsShow: false,
        languagesShow: !languagesShow,
        categoryShow: false,
        rangeShow: false,
      });
    }

    if (type === 'date') {
      return this.setState({
        dateShow: !dateShow,
        skillsShow: false,
        languagesShow: false,
        categoryShow: false,
        rangeShow: false,
      });
    }

    return true;
  };

  onChangeCompleteRange = () => {
    const {
      rangeMax,
      rangeMin,
      languages,
      skills,
      categories,
      startDate,
      endDate,
      freeSessionFilter,
    } = this.state;
    this.setState({
      rangeShow: false,
    });

    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { query, history, onFetchAllProfiles } = this.props;

    const queryBuilder = `?${datesearch}&rate=${ratesearch}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&query=${query}&free=${freeSessionFilter} ||
      ''}`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  onDeleteChipsSelected = (index, type) => {
    let { rangeMax, rangeMin, startDate, endDate } = this.state;
    const { languages, skills, categories, freeSessionFilter } = this.state;
    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (type === 'categories') {
      pull(categories, categories[index]);
      this.setState({ categories });
    }

    if (type === 'skills') {
      pull(skills, skills[index]);
      this.setState({ skills });
    }

    if (type === 'languages') {
      pull(languages, languages[index]);
      this.setState({ languages });
    }

    if (type === 'range') {
      rangeMax = 0;
      rangeMin = 0;

      this.setState({ rangeMin: 0, rangeMax: 0 });
    }

    if (type === 'date') {
      startDate = '';
      endDate = '';

      this.setState({ startDate: '', endDate: '' });
    }

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { history, onFetchAllProfiles } = this.props;

    const queryBuilder = `?${datesearch}&rate=${ratesearch}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&free=${freeSessionFilter}`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  onAddLanguagesFilter = (value) => {
    const {
      languages,
      skills,
      categories,
      rangeMax,
      rangeMin,
      startDate,
      endDate,
      freeSessionFilter,
    } = this.state;
    languages.push(value);

    let languageMoreUpdate;

    if (languages.length < 3) {
      languageMoreUpdate = false;
    }

    this.setState({
      languages,
      languagesMore: languageMoreUpdate,
      languagesShow: false,
    });

    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { history, onFetchAllProfiles } = this.props;

    const queryBuilder = `?${datesearch}&rate=${ratesearch}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&free=${freeSessionFilter}`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  onAddSkillsFilter = (value) => {
    const {
      languages,
      skills,
      categories,
      rangeMax,
      rangeMin,
      startDate,
      endDate,
      skillsMore,
      freeSessionFilter,
    } = this.state;
    skills.push(value);

    let skillsMoreUpdate;

    if (skills.length < 3) {
      skillsMoreUpdate = skillsMore;
    }

    this.setState({ skills, skillsMore: skillsMoreUpdate, skillsShow: false });

    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { history, onFetchAllProfiles } = this.props;
    const queryBuilder = `?${datesearch}&rate=${ratesearch}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&free=${freeSessionFilter}`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  onAddDateFilter = (ranges) => {
    const { languages, skills, categories, rangeMax, rangeMin, freeSessionFilter } = this.state;
    const startDate = moment(ranges.startDate).format('YYYY-MM-DD');
    const endDate = moment(ranges.endDate).format('YYYY-MM-DD');

    this.setState({
      startDate,
      endDate,
      dateShow: false,
    });

    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { history, onFetchAllProfiles } = this.props;
    const queryBuilder = `?${datesearch}&rate=${ratesearch}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&free=${freeSessionFilter}`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  onClickMore = (type) => {
    if (type === 'skills') {
      return this.setState({ skillsMore: true });
    }
    if (type === 'languages') {
      return this.setState({ languagesMore: true });
    }

    return true;
  };

  onFreeFilterClick = async () => {
    await this.setState((prevState) => ({
      freeSessionFilter: !prevState.freeSessionFilter,
    }));
    const { freeSessionFilter } = this.state;
    const { rangeMax, rangeMin, languages, skills, categories, startDate, endDate } = this.state;
    this.setState({
      rangeShow: false,
    });

    let ratesearch;
    let categorysearch;
    let skillsearch;
    let languagessearch;
    let datesearch;

    if (rangeMax) {
      ratesearch = `${rangeMin},${rangeMax}`;
    } else {
      ratesearch = '';
    }

    if (categories) {
      categorysearch = categories.join(',');
    } else {
      categorysearch = '';
    }

    if (skills) {
      skillsearch = skills.join(',');
    } else {
      skillsearch = '';
    }

    if (languages) {
      languagessearch = languages.join(',');
    } else {
      languagessearch = '';
    }

    if (startDate) {
      datesearch = `startdate=${startDate}&enddate=${endDate}`;
    } else {
      datesearch = '';
    }

    const { query, history, onFetchAllProfiles } = this.props;

    const queryBuilder = `?${datesearch}&rate=${ratesearch}&free=${freeSessionFilter}&category=${categorysearch.toLowerCase()}&skills=${skillsearch.toLowerCase()}&languages=${languagessearch.toLowerCase()}&query=${
      query || ''
    }`;

    const { currency } = this.props;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    history.push(queryBuilder);
    onFetchAllProfiles(queryBuilder, '', reqData);
  };

  render() {
    const {
      rate,
      categories,
      dateShow,
      startDate,
      endDate,
      rangeShow,
      rangeMax,
      rangeMin,
      languages,
      languagesMore,
      languagesShow,
      skills,
      skillsMore,
      skillsShow,
      displatRangeMax,
      displayrangeMin,
      freeSessionFilter,
    } = this.state;
    const { languagesMasterData, skillsMasterData } = this.props;
    const rangelif =
      rate || categories.length > 0
        ? cx(styles.range, styles.rangeop, styles.rangelisif)
        : cx(styles.range, styles.rangeop);

    const filteredLanguagesMasterdata = languagesMasterData.map((language) => ({
      value: language.languageId,
      label: language.language,
    }));

    const filteredSkillsMasterdata = skillsMasterData.map((skill) => ({
      value: skill.skillId,
      label: skill.skillName,
    }));

    const { currency, isFreeSessionConsumed } = this.props;

    return (
      <div className={styles.filter}>
        <div className={styles.items}>
          <div className={styles.item}>
            <div
              className={dateShow ? styles.itemcactive : styles.itemc}
              onClick={() => this.onClickFilterSelect('date')}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              <p>Date</p>
              <img src={dateShow ? arrowup : arrowdown} alt="Arrow down" className={styles.img} />
            </div>
          </div>
          {startDate && (
            <div className={styles.selected}>
              <div className={styles.chips}>
                <p className={styles.chipp}>
                  {startDate} - {endDate}
                </p>
                <img
                  src={deleteactive}
                  alt="more"
                  className={styles.chipimg}
                  onClick={() => this.onDeleteChipsSelected(0, 'date')}
                />
              </div>
            </div>
          )}
          {dateShow && (
            <DateFilter
              dateShow={dateShow}
              onAddDateFilter={this.onAddDateFilter}
              startDate={startDate}
            />
          )}
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
              className={rangeShow ? styles.itemcactive : styles.itemc}
              onClick={() => this.onClickFilterSelect('range')}
            >
              <p>Rate</p>
              <img src={rangeShow ? arrowup : arrowdown} alt="Arrow down" className={styles.img} />
            </div>
          </div>
          {rate && (
            <div className={styles.selected}>
              <div className={styles.chips}>
                <p className={styles.chipp}>
                  {currency.currencySymbol}
                  {rangeMin} - {currency.currencySymbol}
                  {rangeMax}
                </p>
                <img
                  src={deleteactive}
                  alt="more"
                  className={styles.chipimg}
                  onClick={() => this.onDeleteChipsSelected(0, 'range')}
                />
              </div>
            </div>
          )}

          {rangeShow && (
            <div className={rangeShow ? rangelif : styles.range}>
              <div className={styles.rtit}>
                <p>Choose your budget</p>
                {!isFreeSessionConsumed && (
                  <div className={styles.freeSession}>
                    <input
                      type="checkbox"
                      name="Free"
                      onChange={this.onFreeFilterClick}
                      checked={freeSessionFilter}
                    />
                    <p className={styles.rbodyt} style={{ marginLeft: '8px' }}>
                      Free First Session
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.rbody}>
                <p className={styles.rbodyt}>Adjust the slider below to choose a budget</p>
                <div className={styles.filinput}>
                  <InputRange
                    maxValue={displatRangeMax}
                    minValue={displayrangeMin}
                    value={{ max: rangeMax, min: rangeMin }}
                    onChange={this.onChange}
                    step={5}
                    formatLabel={(v) => {
                      return `${currency.currencySymbol} ${v}`;
                    }}
                  />
                </div>
              </div>
              <div className={styles.rfot}>
                <div
                  className={styles.clbtn}
                  onClick={() => {
                    this.setState({ rangeMin: 0, rangeMax: 0 });
                  }}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <p>Clear</p>
                  <img src={arrowgraywhite} alt="clear" />
                </div>
                <div
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                  className={styles.clbtn}
                  onClick={this.onChangeCompleteRange}
                >
                  <p>Apply</p>
                  <img src={arrowgraywhite} alt="clear" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.items}>
          <div className={styles.item}>
            <div
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
              className={skillsShow ? styles.itemcactive : styles.itemc}
              onClick={() => this.onClickFilterSelect('skills')}
            >
              <p>Skills</p>
              <img src={skillsShow ? arrowup : arrowdown} alt="Arrow down" className={styles.img} />
            </div>
          </div>
          {skills.length > 0 && (
            <div className={styles.selectedc}>
              {!skillsMore &&
                skills.map((cat, index) => {
                  if (index < 3) {
                    return (
                      <div className={styles.chips} key={index}>
                        <p className={styles.chipp}>{cat}</p>
                        <img
                          src={deleteactive}
                          alt="more"
                          className={styles.chipimg}
                          onClick={() => this.onDeleteChipsSelected(index, 'skills')}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              {skillsMore &&
                skills.map((cat, index) => (
                  <div className={styles.chips} key={index}>
                    <p className={styles.chipp}>{cat}</p>
                    <img
                      src={deleteactive}
                      alt="more"
                      className={styles.chipimg}
                      onClick={() => this.onDeleteChipsSelected(index, 'skills')}
                    />
                  </div>
                ))}
              {skills.length > 3 && !skillsMore && (
                <div
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                  className={styles.chipsm}
                  onClick={() => this.onClickMore('skills')}
                >
                  <p className={styles.chippm}>+{skills.length - 3}</p>
                </div>
              )}
            </div>
          )}
          {skillsShow && (
            <CategoryFilter
              placeholder="Skill (e.g: leadership development)"
              label="Choose preferred skills"
              header="Type skill and press enter"
              categories={skills}
              categoryShow={skillsShow}
              suggestion={filteredSkillsMasterdata}
              onAddCategoryFilter={this.onAddSkillsFilter}
            />
          )}
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
              className={languagesShow ? styles.itemcactive : styles.itemc}
              onClick={() => this.onClickFilterSelect('languages')}
            >
              <p>Languages</p>
              <img
                src={languagesShow ? arrowup : arrowdown}
                alt="Arrow down"
                className={styles.img}
              />
            </div>
          </div>
          {languages.length > 0 && (
            <div className={styles.selectedc}>
              {!languagesMore &&
                languages.map((cat, index) => {
                  if (index < 3) {
                    return (
                      <div className={styles.chips} key={index}>
                        <p className={styles.chipp}>{cat}</p>
                        <img
                          src={deleteactive}
                          alt="more"
                          className={styles.chipimg}
                          onClick={() => this.onDeleteChipsSelected(index, 'languages')}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              {languagesMore &&
                languages.map((cat, index) => (
                  <div className={styles.chips} key={index}>
                    <p className={styles.chipp}>{cat}</p>
                    <img
                      src={deleteactive}
                      alt="more"
                      className={styles.chipimg}
                      onClick={() => this.onDeleteChipsSelected(index, 'languages')}
                    />
                  </div>
                ))}
              {languages.length > 3 && !languagesMore && (
                <div
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                  className={styles.chipsm}
                  onClick={() => this.onClickMore('languages')}
                >
                  <p className={styles.chippm}>+{languages.length - 3}</p>
                </div>
              )}
            </div>
          )}
          {languagesShow && (
            <CategoryFilter
              placeholder="Languages"
              label="Choose preferred languages"
              header="Type to see and select a suggested language."
              categories={languages}
              categoryShow={languagesShow}
              suggestion={filteredLanguagesMasterdata}
              onAddCategoryFilter={this.onAddLanguagesFilter}
            />
          )}
        </div>
      </div>
    );
  }
}

Filter.propTypes = {
  location: globalProptype.location.isRequired,
  history: globalProptype.history.isRequired,
  onFetchAllProfiles: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  languagesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
    currencySymbol: PropTypes.string,
  }),
  isFreeSessionConsumed: PropTypes.bool.isRequired,
};

Filter.defaultProps = {
  currency: {
    currencyCode: 'EUR',
    currencySymbol: '€',
  },
};

export default Filter;
