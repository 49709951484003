import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import cx from 'classnames';
import Modal from 'react-modal';
import styles from './KycDetails.module.css';
import idcardicon from '../../Common/assets/id-card.svg';
import regprooficon from '../../Common/assets/reg-prof.svg';
import aoaicon from '../../Common/assets/aoa.svg';
import shdicon from '../../Common/assets/shd.svg';
import upbtn from '../../Common/assets/upload_light.svg';
import loader from '../../Common/assets/ellipse.svg';
import { apiurl } from '../../../utils/index';
import enums from '../../../config/enum';
import Uboadd from './Uboadd';

import Uboupdate from './Uboupdate';

const customStyles = {
  content: {
    top: '43%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    maxHeight: '800px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};

Modal.setAppElement('#root');

class Buisness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfDocument: '',
      file: '',
      image1: '',
      imgFile: '',
      uploading: false,
      kycDocuments: [],
      modalIsOpen: false,
      updateModalIsOpen: false,
      updateData: {},
      ubosubmission: false,
    };
  }

  componentDidMount() {
    this.props.onFetchAllUboDocs({ userToken: this.props.user.userToken });
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.kycDocuments && newprops.kycDocuments.length > 0) {
      this.setState({
        kycDocuments: newprops.kycDocuments,
      });
    }
  }

  onClickImg = (e, typeOfDocument) => {
    if (typeOfDocument === enums.kycDocumentTypeText.SHAREHOLDER_DECLARATION) {
      this.setState({ modalIsOpen: true, typeOfDocument });
      this.props.modalOpen();
    } else {
      this.setState({ typeOfDocument });
      this.fileUpload.click();
    }
  };

  onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    const { typeOfDocument } = this.state;

    reader.onload = () => {
      this.setState({
        file: reader.result,
        imgFile: file,
      });

      if (typeOfDocument === enums.kycDocumentTypeText.IDENTITY_PROOF && file.size <= 33000) {
        return toast.warn('File should be greater then 32kb for IDENTITY PROOF', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      if (file.size <= 7000000) {
        this.onUploadImage();
      } else {
        toast.warn('File should be less then 7mb', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };

    reader.readAsDataURL(file);
  };

  onUploadImage() {
    let toastid = null;

    this.setState({
      uploading: true,
    });

    toastid = toast.warn('Uploading', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (this.state.imgFile) {
      const headers = {
        'Content-Type': 'application/json',
      };

      headers['Content-Type'] = 'application/x-www-form-urlencoded';
      headers.Authorization = `Bearer ${this.props.user.userToken}`;

      const data = {
        type: this.state.typeOfDocument,
        file: this.state.file,
      };

      const formData = new FormData();
      formData.append('image1', this.state.imgFile);
      formData.append('document_type', data.type);

      axios
        .post(`${apiurl}/v1/payment/kyc/web`, formData, { headers })
        .then((result) => {
          if (result.data.success) {
            this.props.onFetchKycDocuments(this.props.user);
            toast.update(toastid, {
              render: result.data.message,
              autoClose: 3000,
            });

            this.setState({
              uploading: false,
            });

            this.fileUpload.value = null;
          } else {
            toast.update(toastid, {
              render: result.data.message,
              autoClose: 3000,
            });

            this.fileUpload.value = null;

            throw new Error('Error');
          }
        })
        .catch(() => {
          this.fileUpload.value = null;
          toast.update(toastid, {
            render: 'Upload Failed',
            autoClose: 3000,
          });

          this.setState({
            uploading: false,
          });
        });
    }
  }

  onUboSubmission = (e) => {
    e.preventDefault();

    this.setState({ ubosubmission: true });

    const currentubo = this.props.allubodocs;

    if (currentubo.length > 0 && currentubo[0].ubos.length > 0) {
      const data = {
        ubodeclartion_id: this.props.allubodocs[0].id,
      };

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Beare ${this.props.user.userToken}`,
      };

      return axios
        .post(`${apiurl}/v1/payment/kyc/ubo/verify`, data, { headers })
        .then((result) => {
          if (result.data.success) {
            this.setState({ ubosubmission: false });

            this.props.onFetchAllUboDocs({
              userToken: this.props.user.userToken,
            });

            return toast.warn(result.data.message, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          throw new Error(result.data.message);
        })
        .catch((err) => {
          this.setState({ ubosubmission: false });
          return toast.warn(err.message || 'Failed in adding ubo data', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
    return toast.warn('You need upload atleast one shareholder declartion for verification', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  closeModal = () => {};

  closeModalRequest = () => {
    this.setState({ modalIsOpen: false, updateModalIsOpen: false });
  };

  openUpdateUboPopUp = (e, data, declarationid) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    data.declaration_id = declarationid;
    this.setState({ updateModalIsOpen: true, updateData: data });
    this.props.modalOpen();
  };

  render() {
    return (
      <div className={styles.Soletrader}>
        {this.props.edit ? (
          <div className={styles.edit}>
            <p className={styles.alisubikb}>
              Please ensure that you merge singular documents into one file for each of the sections
              below.
            </p>
            <input
              type="file"
              accept=".pdf,.jpeg,.jpg,.gif,.png"
              onChange={this.onFileChange}
              className={styles.imgupl}
              ref={(ref) => {
                this.fileUpload = ref;
              }}
            />
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={idcardicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>ID Proof</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.IDENTITY_PROOF;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>
                          {enums.kycDocumentType[kyc.documentType]}
                        </p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
              </div>

              <button
                disabled={this.state.uploading}
                className={styles.upbtn}
                onClick={(e) => this.onClickImg(e, enums.kycDocumentTypeText.IDENTITY_PROOF)}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Click to upload a file</p>
              </button>

              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  {this.props.kychelptext.soleTrader &&
                    this.props.kychelptext.business.find(
                      (doc) => doc.kycDocumentType === enums.kycDocumentTypeText.IDENTITY_PROOF
                    ).text}
                </p>
              </div>
            </div>
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={regprooficon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Registration Proof</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.REGISTRATION_PROOF;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>
                          {enums.kycDocumentType[kyc.documentType]}
                        </p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
              </div>
              <button
                disabled={this.state.uploading}
                onClick={(e) => this.onClickImg(e, enums.kycDocumentTypeText.REGISTRATION_PROOF)}
                className={styles.upbtn}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Click to upload a file</p>
              </button>
              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  {this.props.kychelptext.soleTrader &&
                    this.props.kychelptext.business.find(
                      (doc) => doc.kycDocumentType === enums.kycDocumentTypeText.REGISTRATION_PROOF
                    ).text}
                </p>
              </div>
            </div>
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={aoaicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Articles of Association</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.ARTICLES_OF_ASSOCIATION;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>
                          {enums.kycDocumentType[kyc.documentType]}
                        </p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
              </div>
              <button
                disabled={this.state.uploading}
                onClick={(e) =>
                  this.onClickImg(e, enums.kycDocumentTypeText.ARTICLES_OF_ASSOCIATION)
                }
                className={styles.upbtn}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Click to upload a file</p>
              </button>
              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  {this.props.kychelptext.soleTrader &&
                    this.props.kychelptext.business.find(
                      (doc) =>
                        doc.kycDocumentType === enums.kycDocumentTypeText.ARTICLES_OF_ASSOCIATION
                    ).text}
                </p>
              </div>
            </div>
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={shdicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Shareholder’s Declaration</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.SHAREHOLDER_DECLARATION;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>
                          {enums.kycDocumentType[kyc.documentType]}
                        </p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
                {this.props.allubodocs.map((ubo, index) => (
                  <div key={index} className={styles.kycfile}>
                    <div>
                      <p className={styles.statustext}>{enums.kycDocumentType[3]}</p>
                      {/* <p className={styles.statustextp}>{ubo.ubos[0].firstName+' '+ubo.ubos[0].lastName}</p> */}
                      <p className={styles.statustextp}>
                        ID: {moment.unix(ubo.creationDate).format('DDMMYY')}-{ubo.id}
                      </p>
                    </div>
                    <p
                      className={
                        ubo.status === 'REFUSED' || ubo.status === 'INCOMPLETE'
                          ? cx(styles.statustexticon, styles.refused)
                          : styles.statustexticon
                      }
                    >
                      {ubo.status}
                    </p>
                  </div>
                ))}
                {this.props.allubodocs.length > 0 &&
                  this.props.allubodocs[0].ubos.map((ubo, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div className={styles.uboleft}>
                        <p className={styles.statustextp}>{ubo.id}</p>
                        <p className={styles.statustextp}>{`${ubo.firstName} ${ubo.lastName}`}</p>
                        <p className={styles.statustextp}>
                          Address:{' '}
                          {`${ubo.address.addressLine1}, ${ubo.address.city}, ${ubo.address.postalCode}, ${ubo.address.country}`}
                        </p>
                      </div>
                      <div
                        className={styles.uboright}
                        onClick={(e) =>
                          this.openUpdateUboPopUp(e, ubo, this.props.allubodocs[0].id)
                        }
                      >
                        <button className={styles.btnupdate}>UPDATE</button>
                      </div>
                    </div>
                  ))}
              </div>
              <button
                disabled={this.state.uploading}
                onClick={(e) =>
                  this.onClickImg(e, enums.kycDocumentTypeText.SHAREHOLDER_DECLARATION)
                }
                className={styles.upbtn}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Add shareholder details</p>
              </button>

              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  Please click on add shareholder details to complete the details of the first
                  shareholder in your electronic shareholder’s declaration. Please add all
                  individuals with more than 25 % company shares or voting rights.
                </p>
              </div>

              {this.props.allubodocs.length > 0 &&
                (this.props.allubodocs[0].status === 'INCOMPLETE' ||
                  this.props.allubodocs[0].status === 'CREATED') && (
                  <>
                    <button
                      disabled={this.state.ubosubmission}
                      onClick={this.onUboSubmission}
                      className={styles.upbtnsu}
                    >
                      {this.state.ubosubmission ? (
                        <img src={loader} alt="loading" height="100%" />
                      ) : (
                        <p className={styles.upbtntext}>Submit For Verification</p>
                      )}
                    </button>

                    <div className={styles.doctypes}>
                      <p className={styles.doctypespw}>
                        Submit your shareholder declaration for verification once you have
                        accurately added the details of all individuals possessing more than 25%
                        shares or voting rights.
                      </p>
                    </div>
                  </>
                )}

              {this.props.allubodocs.length > 0 &&
              (this.props.allubodocs[0].status === 'INCOMPLETE' ||
                this.props.allubodocs[0].status === 'CREATED') ? null : (
                <div>
                  {this.props.allubodocs.length > 0 && (
                    <button disabled className={styles.upbtnsu}>
                      <p className={styles.upbtntext}>
                        {this.props.allubodocs.length > 0 ? this.props.allubodocs[0].status : ''}
                      </p>
                    </button>
                  )}
                </div>
              )}

              {this.state.modalIsOpen && (
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Add Ubo Details"
                >
                  <Uboadd
                    closeModal={this.closeModalRequest}
                    onConfirm={this.onConfirm}
                    {...this.props}
                  />
                </Modal>
              )}

              {this.state.updateModalIsOpen && (
                <Modal
                  isOpen={this.state.updateModalIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Update Ubo Details"
                >
                  <Uboupdate
                    updateData={this.state.updateData}
                    closeModal={this.closeModalRequest}
                    onConfirm={this.onConfirm}
                    {...this.props}
                  />
                </Modal>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.view}>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={idcardicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>ID Proof</p>
              </div>
            </div>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={regprooficon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Registration Proof</p>
              </div>
            </div>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={aoaicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Articles of Association</p>
              </div>
            </div>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={shdicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Shareholder’s Declaration</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Buisness;
