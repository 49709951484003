import {
  FETCH_USER_CARD,
  FETCH_ALL_UBODOC,
  FETCH_ALL_COUNTRIES,
  UPCOMING_SESSION,
  FETCH_KYCHELPTEXT,
  FETCH_BALANCE,
  FETCH_BANK_ACCOUNT,
  FETCH_KYC_DOCUMENT,
  CALCULATE_VAT,
  LOG_OUT,
  SWITCH_ACCOUNT,
} from '../actions/types';

const initialState = {
  userCards: [],
  upcomingSessions: [],
  bankAccounts: {},
  kycDocuments: [],
  vatDetails: {},
  balance: {},
  kychelptext: [],
  allcountries: [],
  allubodocs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_CARD:
      return {
        ...state,
        userCards: action.payload,
      };
    case FETCH_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: action.payload,
      };
    case FETCH_KYC_DOCUMENT:
      return {
        ...state,
        kycDocuments: action.payload,
      };

    case FETCH_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };

    case UPCOMING_SESSION: {
      return {
        ...state,
        upcomingSessions: action.payload,
      };
    }
    case FETCH_KYCHELPTEXT: {
      return {
        ...state,
        kychelptext: action.payload,
      };
    }
    case FETCH_ALL_COUNTRIES: {
      return {
        ...state,
        allcountries: action.payload,
      };
    }
    case FETCH_ALL_UBODOC: {
      return {
        ...state,
        allubodocs: action.payload,
      };
    }
    case CALCULATE_VAT: {
      return {
        ...state,
        vatDetails: action.payload,
      };
    }
    case SWITCH_ACCOUNT: {
      return {
        ...state,
        ...initialState,
      };
    }
    case LOG_OUT: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};
