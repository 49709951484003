import React from 'react';
import Chip from '../chip';
import Search from '../search';
import styles from './HeroSection.module.scss';
import PtsdHeroImage from '../../assets/img/ptsd-hero.png';
import { searchHeroSectionChips } from '../../common/constants';

export default function HeroSection(): JSX.Element {
	return (
		<div className={styles.heroSection}>
			<div className={styles.container}>
				<div className={styles.right}>
					<h1 data-testid="quiz-hero-section-title">
						Start Feeling Better Today
					</h1>
					<h2 data-testid="quiz-hero-section-sub-title">
						Book the therapist you need
					</h2>
					<Search />

					<div className={styles.popularChips}>
						<div className={styles.popular}>Popular:</div>
						<div className={styles.chips}>
							{searchHeroSectionChips.map((chip, index) => (
								<Chip key={`chip-${index}`} name={chip} />
							))}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.imgBackgrnd}>
				<img
					data-testid="quiz-hero-section-bg-image"
					src={PtsdHeroImage}
					alt=""
				/>
			</div>
		</div>
	);
}
