import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from '../EditMyProfile.module.css';
import yourdetails from '../../../Common/assets/your_details.svg';
import EditTooltip from '../../../Common/EditTooltip/EditTooltip';
import EditInput from '../../../Common/EditInput/EditInput';
import EditSelectMulti from '../../../Common/EditSelectMulti/EditSelectMulti';
import { alert } from '../../../../utils';
import messages from '../../../../config/message';
import EditNumber from '../../../Common/EditNumber';

class YourDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      required: false,
      hourlyRate: '',
      categories: '',
      languages: '',
      selectactive: false,
      selectedLanguages: [],
      firstName: '',
      lastName: '',
      headline: '',
      currency: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { hourlyRate, categories, languages, firstName, lastName, headline, currency } = user;

    const selectedLanguages = languages
      ? languages.map((language) => ({ value: language.languageId, label: language.language }))
      : [];

    this.setState({
      hourlyRate,
      selectedLanguages,
      languages,
      categories,
      firstName,
      lastName,
      headline,
      currency,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { required, requiredIn } = this.props;
    if (required && requiredIn !== 'yd') {
      document.removeEventListener('click', this.handleOutsideClick, false);
      if (prevState.edit) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ edit: false });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onTooltipClick = () => {
    const { edit } = this.state;
    if (!edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    return this.setState({ edit: !edit });
  };

  onChange = (e) => {
    const { value } = e.target;

    let inputValue = '';

    if (value.trim()) {
      inputValue = value.replace(/\s+/g, ' ');
    }

    this.setState({ [e.target.name]: inputValue });
  };

  onSelectChange = (selectedOption, name) => {
    const { languagesMasterData } = this.props;
    if (name === 'languages') {
      const lang = [];
      if (selectedOption) {
        selectedOption.forEach((option) => {
          lang.push(languagesMasterData.find((language) => language.languageId === option.value));
        });
      }

      this.setState({
        languages: lang,
        selectedLanguages: selectedOption !== null ? selectedOption : [],
      });
    }
  };

  onSelActive = (type) => {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  };

  onSubmitData = async () => {
    const { hourlyRate, categories, languages, firstName, lastName, headline } = this.state;
    const { user, onEditProfile, onUpdateProfile } = this.props;

    const userState = {
      hourlyRate: Math.trunc(hourlyRate),
      categories,
      languages,
      firstName,
      lastName,
      headline,
    };

    const userStateServer = {
      hourly_rate: Math.trunc(hourlyRate),
      languages,
      first_name: firstName,
      last_name: lastName,
      headline,
      currency_code: user.currency.currencyCode,
    };

    const userSync = { ...user, ...userState };

    if (languages.length > 0 && headline && firstName && lastName && hourlyRate > 0) {
      this.setState({ required: false });
      onEditProfile(userSync);
      onUpdateProfile(userStateServer);
    } else {
      await this.setState({ required: true });
    }
  };

  handleOutsideClick = (e) => {
    const { selectactive } = this.state;
    if (selectactive) {
      return true;
    }

    if (this.editelem && this.editelem.contains(e.target)) {
      return true;
    }

    this.onSubmitData();

    const { required } = this.state;
    const { onRequired } = this.props;
    if (required) {
      alert(messages.requiredFieldsMissing);
      return onRequired(true, 'yd');
    }
    onRequired(false, '');
    return this.onTooltipClick();
  };

  render() {
    const { languagesMasterData } = this.props;
    const { edit, selectedLanguages } = this.state;
    const { firstName, lastName, hourlyRate, currency, languages, headline } = this.state;

    const languagesMasterdata = languagesMasterData.map((language) => ({
      value: language.languageId,
      label: language.language,
    }));

    return (
      <div
        className={edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={yourdetails} alt="Details" />
          <p className={styles.atitle}>Your Details</p>
          <p className={styles.rerror}>* Required</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={edit} />
          </div>
        </div>
        {!edit && (
          <div className={styles.acbody}>
            {firstName && lastName && hourlyRate > 0 && (
              <div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>First Name</p>
                  <p className={styles.alisub}>{firstName}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Last Name</p>
                  <p className={styles.alisub}>{lastName}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Headline</p>
                  <p className={styles.alisub}>{headline}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Hourly Rate</p>
                  <p className={styles.alisub}>
                    {hourlyRate ? `${currency.currencySymbol} ${hourlyRate}` : hourlyRate}
                  </p>
                </div>
                {/* <div className={styles.alitem}>
                  <p className={styles.alitit}>Category</p>
                  <ul className={styles.ucategory}>
                    {categories
                      ? categories.map(category => (
                          <li key={category.categoryId} className={styles.lilistitem}>
                            {category.categoryName}
                          </li>
                        ))
                      : ''}
                  </ul>
                </div> */}
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Languages</p>
                  <ul className={styles.ucategory}>
                    {languages &&
                      languages.map((language) => (
                        <li key={language.languageId} className={styles.lilistitem}>
                          {language.language}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        {edit && (
          <div className={styles.acedit}>
            <EditInput
              htmlFor="First Name"
              label="First Name"
              value={firstName}
              placeholder="First Name"
              onChange={this.onChange}
              name="firstName"
              required
            />
            <EditInput
              htmlFor="Last Name"
              label="Last Name"
              value={lastName}
              placeholder="Last Name"
              onChange={this.onChange}
              name="lastName"
              required
            />
            <EditInput
              htmlFor="Headline"
              label="Headline"
              value={headline}
              placeholder="Headline"
              onChange={this.onChange}
              name="headline"
              required
              maxLength={120}
            />
            <EditNumber
              htmlFor="Hourly Rate"
              label={`Hourly Rate (${currency.currencyCode})`}
              value={hourlyRate}
              placeholder="Hourly Rate (Euro)"
              onChange={this.onChange}
              name="hourlyRate"
              type="number"
              required
            />
            {/* <EditSelectMulti
              label="Categories"
              selected={selectedCategories}
              selecttype="fixed"
              options={categoriesMasterdata}
              placeholder="Select Category"
              onChange={e => this.onSelectChange(e, 'categories')}
              onActive={this.onSelActive}
              required
            /> */}
            <EditSelectMulti
              label="Languages"
              selected={selectedLanguages}
              selecttype="fixed"
              options={languagesMasterdata}
              placeholder="Add Language"
              onChange={(e) => this.onSelectChange(e, 'languages')}
              onActive={this.onSelActive}
              required
            />
          </div>
        )}
      </div>
    );
  }
}

YourDetails.propTypes = {
  user: PropTypes.shape({
    hourlyRate: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    headline: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
      currencyName: PropTypes.string,
      currencySymbol: PropTypes.string,
    }),
  }).isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onRequired: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  requiredIn: PropTypes.string.isRequired,
  languagesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default YourDetails;
