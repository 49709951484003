import React from 'react';
import styles from './SelfAssessment.module.scss';
import { consultationTopics } from '../../common/constants';
import ConsultationTopic from '../consultation-topic';

export default function SelfAssessment(): JSX.Element {
	return (
		<div className={styles.selfAssessment}>
			<h1 data-testid="quiz-self-assessment-title">
				Take a Free Self-Assessment
			</h1>
			<div className={styles.subTitle}>
				<p data-testid="quiz-self-assessment-subtitle">
					Short online mental health evaluations to help you
				</p>
				<p>
					determine if you should seek help from a mental health professional
				</p>
			</div>

			<div className={styles.topics}>
				{consultationTopics.map((consultationTopic, index) => {
					return (
						<ConsultationTopic
							key={`consultation-${index}`}
							topic={consultationTopic}
						/>
					);
				})}
			</div>
		</div>
	);
}
