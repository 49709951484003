import { TrackingApiService } from './resource';
import { browserInfo } from '@conrati/utils';

/*
 * Track a new event
 * @param {string} eventName - The name of the event
 * @param {object} eventProperties - The properties of the event
 * @returns {void}
 **/
function Track(
	eventName: string,
	eventProperties: { [key: string]: unknown }
): void {
	TrackingApiService({
		...eventProperties,
		event_name: eventName,
		browser_info: browserInfo(),
	});
}

export { Track };
