import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const Calendar = () => {
  return (
    <div>
      <p className={styles.ftitle}>Calendar</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How can I maintain my hourly availability to be booked by clients?"
          content={`
                    • An Advice Provider can maintain his hourly availability by indicating his available hours in the application.
                    • Recurring availability is configured for during the week and weekend 
                    • Conrati removes your availability from the Conrati application in the scenario that you have a meeting that appears in your integrated Google or Microsoft Exchange calendar during a time slot when you indicated that you are available. This makes it easier for you to configure your availability without constantly requiring to update your availability when your schedule gets booked up.
                    • The more availability you set, the higher the probability of receiving bookings
                    • If you would like to remove your availability in the Conrati application for a specific period of time without any changes required to your configured weekly availability (for instance, you will be unavailable) simply create an event in your external synchronised calendar that extends over the time period that you wish to be unavailable.                    
                `}
          expand
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is it possible to switch calendar providers?"
          content={`
                    Yes, it is now possible to switch calendar providers once you have opted for either Google Calendar or Microsoft Exchange.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How is my availability used during searches?"
          content={`
                    When an Advice Seeker searches for a specific skill (keyword) together with availability - the system will check whether the consultant is available on the date that was searched for and search results will be filtered according. 
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How am I notified when booked?"
          content={`
                    As soon as an Advice Receiver books a consultation with an Advice Provider, both parties receive a calendar invitation by email. It is assumed by the system that both parties will accept the calendar invitation in order to save the booking details (containing the link to attend the online meeting)
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What is the minimum amount of time which can be booked?"
          content={`
                   Advice Receivers can book a thirty-minute online consultation as the minimum amount of time.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How do I share my calendar with someone that is not on the Conrati Platform?"
          content={`
                   You can share your availability calendar with your clients by opening your availability calendar on the Conrati platform - copying the link and sending this to your clients.
                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default Calendar;
