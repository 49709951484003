export const capitalize = (s: string): string => {
	if (typeof s !== 'string') return '';

	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

/**
 * Convert input string to camel case naming convention
 * (i.e If input is `outlined-default` then would return `outlinedDefault`)
 * @param input
 */
export function toCamelCase(input: string): string {
	const split = input.split('-');

	const firstElement = split.shift();

	return `${firstElement}${split.map((value) => capitalize(value)).join('')}`;
}
