/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../ViewProfile.module.css';
import headbg from '../../Common/assets/small.svg';
import addimage from '../../Common/assets/addimage.svg';
import freeIcon from '../../Common/assets/free.png';
import { isFreeSession } from '../../../utils';

const ProfileHead = (props) => {
  const {
    firstName,
    lastName,
    headline,
    country,
    primaryImg,
    languages,
    onUploadImage,
    hourlyRateWithSymbol,
    isFreeSessionOpted,
    userData,
    isLoggedIn,
    history,
    skills,
    username,
  } = props;

  const location = history ? history.location : '';
  const pathname = location ? location.pathname : '';

  return (
    <div>
      <div style={{ backgroundImage: `url(${headbg})` }} className={styles.head}>
        <div className={styles.name}>
          <p className={styles.title}>
			{username && <Link to={`/${username}`} replace>{`${firstName} ${lastName}`}</Link>}
          </p>
          <p className={styles.sub}>{headline}</p>
        </div>
        <div className={styles.namecard}>
          <div className={styles.category}>
            <p className={styles.ntitle}>Skills</p>
            {skills && skills.length === 0 && <p className={styles.lilistitemm}>None added yet</p>}
            <ul className={styles.ucategory}>
              {skills
                ? skills.map((skill, ind) => (
                    <li key={ind} className={styles.lilistitem}>
                      {skill}
                    </li>
                  ))
                : ''}
            </ul>
          </div>
          <div className={styles.vrline} />
          <div className={styles.categoryr}>
            <p className={styles.ntitle}>Rate</p>
            {!hourlyRateWithSymbol ? (
              <p className={styles.lilistitemm}>Not added yet</p>
            ) : (
              <p className={styles.nsub}>
                {hourlyRateWithSymbol}
                /hour
              </p>
            )}
          </div>
          {pathname === '/myprofile/edit/step' || pathname === '/myprofile/edit/step/'
            ? null
            : isFreeSession(isFreeSessionOpted, userData.isFreeSessionConsumed, isLoggedIn) && (
                <img src={freeIcon} alt="free" className={styles.fimg} />
              )}

          {primaryImg ? (
            <div
              onKeyPress={() => console.log('Key Pressed')}
              role="button"
              tabIndex="0"
              className={styles.nusrimg}
              onClick={onUploadImage}
            >
				{!onUploadImage ? (
					<Link to={`/${username}`} replace>
					  <img src={primaryImg} alt="User" className={styles.nusrimgimg} />
					</Link>
				) : (
					<img src={primaryImg} alt="User" className={styles.nusrimgimg} />
				)}
            </div>
          ) : (
            <div
              onKeyPress={() => console.log('Key Pressed')}
              role="button"
              tabIndex="0"
              className={styles.nusrimgadd}
              onClick={onUploadImage}
            >
				{!onUploadImage ? (
					<Link to={`/${username}`}>
					  <img src={addimage} alt="User" />
					</Link>
				) : (
					<img src={addimage} alt="User" />
				)}
            </div>
          )}
          <div className={styles.categoryl}>
            <p className={styles.ntitle}>Location</p>
            {!country.countryName && <p className={styles.lilistitemm}>Not added yet</p>}
            <p className={styles.nsub}>{country && country.countryName}</p>
          </div>
          <div className={styles.vrline} />
          <div className={styles.category}>
            <p className={styles.ntitle}>Language</p>
            {languages && languages.length === 0 && (
              <p className={styles.lilistitemm}>None added yet</p>
            )}
            <ul className={styles.ucategory}>
              {languages &&
                languages.map((language) => (
                  <li key={language.languageId} className={styles.lilistitem}>
                    {language.language}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileHead.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  headline: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
    currencyName: PropTypes.string,
    currencySymbol: PropTypes.string,
  }),
  country: PropTypes.shape({
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    countryId: PropTypes.number,
  }),
  primaryImg: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.object),
  onUploadImage: PropTypes.func,
  hourlyRateWithSymbol: PropTypes.string,
  userData: PropTypes.shape({
    isFreeSessionConsumed: PropTypes.bool,
  }),
  skills: PropTypes.arrayOf(PropTypes.string),
};

ProfileHead.defaultProps = {
  firstName: '',
  lastName: '',
  headline: '',
  categories: [],
  primaryImg: '',
  languages: [],
  currency: {
    currencyCode: '',
    currencyName: '',
    currencySymbol: '',
  },
  country: {
    countryCode: '',
    countryName: '',
  },
  hourlyRateWithSymbol: '',
  onUploadImage: undefined,
  userData: {
    isFreeSessionConsumed: false,
  },
  skills: [],
};

export default ProfileHead;
