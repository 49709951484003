import React, { Component } from 'react';
import { createSession, preloadScript } from 'opentok-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { newMessage, disconnectSession } from '../../../actions/sessionAction';
import LiveCall from './LiveCall/LiveCall';
import { tokboxapikey } from '../../../utils';

class SessionCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streams: [],
      publishVideo: true,
      publishAudio: true,
      activeTab: 'info',
      fullscreen: false,
      ap_ar_no_show: false,
      videoShow: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.activeSession.token && this.props.activeSession.sessionId) {
      this.setState({ video: true });
      const { sessionId, token } = this.props.activeSession;
      this.sessionHelper = createSession({
        apiKey: tokboxapikey,
        sessionId,
        token,
        onStreamsUpdated: (streams) => {
          this.setState({ streams });
        },
      });
    } else {
      this.props.history.push('/session?error=true');
    }
  }

  componentWillUnmount() {
    if (this.state.video && this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  }

  render() {
    return (
      <div>
        <LiveCall {...this.props} streams={this.state.streams} sessionHelper={this.sessionHelper} />
      </div>
    );
  }
}

SessionCall.propType = {
  user: PropTypes.object.isRequired,
  activeSession: PropTypes.object.isRequired,
  activeBooking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    activeSession: state.session.activeSession,
    activeChat: state.session.activeChat,
    activeBooking: state.session.activeBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onNewMessage: newMessage,
      onDisconnectSession: disconnectSession,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(preloadScript(SessionCall));
