// Spinner component
import React from 'react';
import styles from './styles.module.scss';
import {ReactComponent as RollerSvgIcon} from '../../assets/roller.svg';

interface SpinnerProps {
	className?: string;
}

export const Spinner = ({ className }: SpinnerProps): JSX.Element => {
	return (
		<div
			className={`${styles.spinnerRoot} ${className || ''}`}
			data-testid="native-ui-spinner"
		>
			<RollerSvgIcon />
		</div>
	);
};
