import axios, { AxiosError, AxiosInstance } from 'axios';

interface CurrentUserSession {
	isLoggedIn: boolean;
	user?: {
		userToken: string;
	};
}

class AxiosConfig {
	private static instance: AxiosConfig;
	private ENV: string;
	private apiInstance: AxiosInstance | null;
	private searchInstance: AxiosInstance | null;

	private constructor() {
		this.ENV =
			process.env.REACT_APP_STAGE || process.env.NODE_ENV || 'production';
		this.apiInstance = null;
		this.searchInstance = null;
	}

	public static getInstance = (): AxiosConfig => {
		if (!AxiosConfig.instance) {
			AxiosConfig.instance = new AxiosConfig();
		}
		return AxiosConfig.instance;
	};

	private getSearchApiUrl = (): string => {
		if (this.ENV === 'dev') {
			return 'http://localhost:4000/api';
		}

		if (['test', 'development'].includes(this.ENV)) {
			return 'https://api-search.twisery.com';
		}

		return 'https://api-search.conrati.com';
	};

	private getApiUrl = (): string => {
		if (this.ENV === 'dev') {
			return 'http://localhost:4000/api';
		}

		if (['test', 'development'].includes(this.ENV)) {
			return 'https://api-test.twisery.com/api';
		}

		return 'https://api.conrati.com/api';
	};

	public setEnvironment = (env: string): void => {
		this.ENV = env;
		this.apiInstance = null;
		this.searchInstance = null;
	};

	public getSearchAxiosInstance = (): AxiosInstance => {
		if (!this.searchInstance) {
			this.searchInstance = axios.create({
				baseURL: this.getSearchApiUrl(),
			});

			this.setSearchInterceptors();
		}

		return this.searchInstance;
	};

	public getApiAxiosInstance = (): AxiosInstance => {
		if (!this.apiInstance) {
			this.apiInstance = axios.create({
				baseURL: this.getApiUrl(),
			});

			this.setApiInterceptors();
		}
		return this.apiInstance;
	};

	private setApiInterceptors = (): void => {
		this.apiInstance?.interceptors.request.use((config) => {
			const userSessionString = localStorage.getItem('currentUserSession');
			const appToken = localStorage.getItem('appToken');

			let userToken = '';

			if (userSessionString) {
				const userSession: CurrentUserSession = JSON.parse(userSessionString);
				userToken = userSession?.user?.userToken || '';
			} else if (appToken) {
				userToken = appToken;
			}

			if (!userToken) {
				return config;
			}

			config.headers = {
				...config.headers,
				Authorization: `Bearer ${userToken}`,
			};

			return config;
		});

		this.apiInstance?.interceptors.response.use(
			(response) => {
				return response;
			},
			(error: AxiosError) => {
				if (error && error.response && error.response.status === 401) {
					window.location.href = `/?auth=signin&redirectUrl=${window.location.pathname}`;
				}

				return Promise.reject(error);
			}
		);
	};

	private setSearchInterceptors = (): void => {
		this.searchInstance?.interceptors.request.use((config) => {
			// Get token from local storage
			const userSessionString =
				localStorage.getItem('currentUserSession');

			if (!userSessionString) {
				return config;
			}

			// Add token to request
			const userSession: CurrentUserSession =
				JSON.parse(userSessionString);

			if (userSession && userSession.user && config.headers) {
				config.headers.Authorization = `Bearer ${userSession.user.userToken}`;
			}

			return config;
		});
	};
}

export { AxiosConfig };
