import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import './index.css';
import './v2/styles/styles.scss';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import store, { history, persistor } from './store';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
  gtmId: 'GTM-PCWX6X4', // GTM code
};

const reactAppEnv = process.env.REACT_APP_STAGE || 'production';

if (reactAppEnv === 'production') {
  Sentry.init({
    dsn: 'https://2e45b02c779849ec872303d24157b605@o295523.ingest.sentry.io/4504610923741184',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  TagManager.initialize(tagManagerArgs);
}

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
