import React, { Component } from 'react';
import styles from '../Static.module.css';

class Privacypolicy extends Component {
  render() {
    return (
      <div className={styles.brpage}>
        <div className={styles.brheader}>
          <h1>Privacy Policy</h1>
        </div>
        <div className={styles.brcontainer}>
          <div
            style={{
              marginBottom: '50px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              color: '#555',
            }}
          >
            <div>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '42.5pt',
                  marginBottom: '0pt',
                  textIndent: '-42.5pt',
                  textAlign: 'right',
                  lineHeight: '112%',
                  widows: 0,
                  orphans: 0,
                  fontSize: '10pt',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>&nbsp;</span>
              </p>
            </div>
            <p style={{ marginTop: '6pt', marginBottom: '14pt', fontSize: '14pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Conrati Privacy Policy</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati has created this privacy policy in order to demonstrate our firm commitment
                to privacy. The following discloses the information gathering and dissemination
                practices for the Conrati site.
              </span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                By using the Conrati platform and accepting the Terms and Conditions you also agree
                to this Privacy Policy. If you do not agree to this Privacy Policy, you must not use
                the Conrati site. The terms "We," "Us," "Our," or "Conrati" includes the marketplace{' '}
              </span>
              <a style={{ textDecoration: 'none' }} href="http://www.conrati.com">
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#0000ff' }}>www.conrati.com</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                as well as the firm Conrati GmbH &amp; Co. KG.
              </span>
            </p>
            <p style={{ marginTop: '0pt', marginBottom: '6.75pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Last Updated: 1 July 2019</span>
              </strong>
            </p>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>1.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  What information do we collect?
                </span>
              </em>
            </h1>
            <ol style={{ margin: '0pt', paddingLeft: '0pt' }} type="i">
              <li
                style={{
                  marginTop: '14pt',
                  marginLeft: '10.56pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Conrati collects your information when you register on the site and when you visit
                pages on our website.
              </li>
              <li
                style={{
                  marginLeft: '13.23pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Personal Information: We may collect the following types of personal information in
                order to provide you with the use and access of the Conrati site, services and
                tools, and for any additional purposes set out in this Privacy Policy:
              </li>
            </ol>
            <div style={{ marginLeft: '19.5pt' }}>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '18pt',
                  marginBottom: '0pt',
                  textIndent: '-24.04pt',
                  textAlign: 'justify',
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: '"Noto Sans Symbols"', fontSize: '10pt' }}>●</span>
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: 'Roboto' }}>
                  Name, and contact information, such as email address, mobile telephone number,
                  physical address, and (depending on the service used) sometimes financial
                  information, such as bank account numbers;{' '}
                </span>
              </p>
            </div>
            <div style={{ marginLeft: '19.5pt' }}>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '18pt',
                  marginBottom: '0pt',
                  textIndent: '-24.04pt',
                  textAlign: 'justify',
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: '"Noto Sans Symbols"', fontSize: '10pt' }}>●</span>
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: 'Roboto' }}>
                  transactional information based on your activities on the sites (such as
                  consultations booked), billing and other information you provide to make a
                  booking;
                </span>
              </p>
            </div>
            <div style={{ marginLeft: '19.5pt' }}>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '18pt',
                  marginBottom: '0pt',
                  textIndent: '-24.04pt',
                  textAlign: 'justify',
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: '"Noto Sans Symbols"', fontSize: '10pt' }}>●</span>
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: 'Roboto' }}>
                  personal information you provide to us through correspondence, chats, dispute
                  resolution, or shared by you from other social applications, services or websites;
                </span>
              </p>
            </div>
            <div style={{ marginLeft: '19.5pt' }}>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '18pt',
                  marginBottom: '0pt',
                  textIndent: '-24.04pt',
                  textAlign: 'justify',
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: '"Noto Sans Symbols"', fontSize: '10pt' }}>●</span>
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: 'Roboto' }}>
                  personal information from your Google or Microsoft Exchange calendar including the
                  dates of calendar events and the time of those events.{' '}
                </span>
              </p>
            </div>
            <div style={{ marginLeft: '19.5pt' }}>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '18pt',
                  marginBottom: '0pt',
                  textIndent: '-24.04pt',
                  textAlign: 'justify',
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: '"Noto Sans Symbols"', fontSize: '10pt' }}>●</span>
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                </span>
                <span style={{ fontFamily: 'Roboto' }}>
                  information from your interaction with our sites, services, content and
                  advertising, including, but not limited to, device ID, device type, location,
                  geo-location information, computer and connection information, statistics on page
                  views, traffic to and from the sites, ad data, IP address and standard web log
                  information.
                </span>
              </p>
            </div>
            <ol style={{ margin: '0pt', paddingLeft: '0pt' }} start={3} type="i">
              <li
                style={{
                  marginLeft: '15.9pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Aggregate Information: Conrati collects non-identifying, general, generic and
                aggregate information to better design our marketplace and services.
              </li>
              <li
                style={{
                  marginLeft: '15.89pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Non-Personal Information: Conrati may collect non-personal information. When you use
                the site, Conrati, third-party service providers (e.g. Google Analytics), or other
                partners may receive and record non-personal information from cookies, server logs,
                and similar technology from your browser or mobile device, including your IP
                address.
              </li>
              <li
                style={{
                  marginLeft: '13.22pt',
                  marginBottom: '14pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We may combine some Non-Personal Information with the Personal Information we
                collect. Where we do so, we will treat the combined information as Personal
                Information if the resulting combination may be used to readily identify or locate
                you in the same manner as Personal Information alone.
              </li>
            </ol>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>2.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  How do we store your information?
                </span>
              </em>
            </h1>
            <p
              style={{
                marginTop: '6pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '10pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                We use Amazon Web Services as hosting provider; therefore your information may be
                transferred to and stored on servers in various locations both in and outside of
                Europe. Conrati maintains control of your information. We protect your information
                using technical and administrative security measures to reduce the risks of loss,
                misuse, unauthorized access, disclosure and alteration. Some of the safeguards we
                use are firewalls and data encryption, physical access controls to our data centres,
                and information access authorization controls.
              </span>
            </p>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>3.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  How do we use your information?
                </span>
              </em>
            </h1>
            <ol style={{ margin: '0pt', paddingLeft: '0pt' }} type="i">
              <li
                style={{
                  marginTop: '14pt',
                  marginLeft: '10.56pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Advice Providers that register on Conrati do so in order to create a profile and
                maintain hourly availability which are publicly available. They do so in order to be
                found in searches and booked by prospective clients. As such – Advice Providers
                agree that all profile information and hourly availability maintained on Conrati can
                be made publicly available through Conrati or third party websites.
              </li>
              <li
                style={{
                  marginLeft: '13.23pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Advice Receivers that register on Conrati do so in order to book consultations with
                Advice Providers. Their account information are not made public and are for their
                private usage. We may use the personal contact information for notifications,
                communication and payment purposes. The contact details of Advice Receivers are
                shared with Advice Providers when a booking has been made.
              </li>
              <li
                style={{
                  marginLeft: '15.9pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We add personal information from your Conrati account to your Google or Microsoft
                Exchange calendar for online bookings including event name, event date, event time
                and event description. We also use your existing integrated Google or Microsoft
                Exchange calendar events to automatically remove your availability from the Conarati
                application to ease the process of configuring your availability.
              </li>
              <li
                style={{
                  marginLeft: '15.89pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Email Communications for Operational Purposes: We may send you a welcome email to
                verify your account and other transactional emails for operational purposes, such as
                billing, account management, or system maintenance. You may only stop those emails
                by requesting termination of your account. It is not possible to terminate your
                account manually – this need to be requested by contacting Conrati at the following
                address:{' '}
                <a style={{ textDecoration: 'none' }} href="mailto:support@conrati.com">
                  <u>
                    <span style={{ color: '#1155cc' }}>support@conrati.com</span>
                  </u>
                </a>
                .
              </li>
              <li
                style={{
                  marginLeft: '13.22pt',
                  marginBottom: '14pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Email Communications for other purposes: We may also send you promotions, surveys,
                newsletters, developer updates, marketing material, product evaluations, and event
                information or other marketing or commercial e-mails. You can opt out of receiving
                these kinds of email communications from Conrati at any time by unsubscribing using
                the unsubscribe link within each email.
              </li>
            </ol>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>4.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Sharing your information?
                </span>
              </em>
            </h1>
            <ol style={{ margin: '0pt', paddingLeft: '0pt' }} type="i">
              <li
                style={{
                  marginTop: '14pt',
                  marginLeft: '10.56pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We disclose personal information to respond to legal requirements, enforce our
                policies, respond to claims that a listing or other content violates the rights of
                others, or protect anyone's rights, property, or safety. Such information will be
                disclosed in accordance with applicable laws and regulations. As stated above, we do
                not disclose your personal information to third parties for their marketing purposes
                without your explicit consent.
              </li>
              <li
                style={{
                  marginLeft: '13.23pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We may disclose personal information to our related bodies corporate and third party
                suppliers and service providers located overseas for some of the purposes listed
                above. We take reasonable steps to ensure that the overseas recipients of your
                personal information do not breach the privacy obligations relating to your personal
                information.
              </li>
              <li
                style={{
                  marginLeft: '15.9pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We may disclose your personal information upon a business transition; At the sale or
                transfer of Conrati and/or all or part of its assets, your personal information may
                be among the items sold or transferred.
              </li>
              <li
                style={{
                  marginLeft: '15.89pt',
                  marginBottom: '14pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                We may share aggregate data with advertisers and other third parties. Conrati uses
                industry-standard encryption technologies when transferring and receiving consumer
                and transaction data exchanged with our site.
              </li>
            </ol>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>5.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Cookies</span>
              </em>
            </h1>
            <ol style={{ margin: '0pt', paddingLeft: '0pt' }} type="i">
              <li
                style={{
                  marginTop: '14pt',
                  marginLeft: '10.56pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                Cookies are pieces of data assigned by a web server that uniquely identify the
                browser on your PC. Conrati may use cookies to enable the site to remember you on
                subsequent visits, speeding up or enhancing your experience of services or functions
                offered. Cookies also enable our systems to gather information about your
                navigational patterns through the site. You have the option to disable cookies at
                any time through your personal browsers.
              </li>
              <li
                style={{
                  marginLeft: '13.23pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                The third party vendors (e.g. Google Analytics) may receive and record non-personal
                information from cookies, server logs, and similar technology from your browser or
                mobile device, including your IP address.
              </li>
              <li
                style={{
                  marginLeft: '15.9pt',
                  marginBottom: '14pt',
                  textAlign: 'justify',
                  paddingLeft: '13pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                }}
              >
                If you access our platform with your login credentials from a social networking site
                (e.g., Linkedin or Xing) or if you otherwise agree to associate your Conrati account
                with a social networking account, we may receive personal information about you from
                such social networking site, in accordance with the terms of use and privacy policy
                of the social networking site.
              </li>
            </ol>
            <h1
              style={{
                marginTop: '12pt',
                marginLeft: '18pt',
                marginBottom: '0pt',
                textIndent: '-18pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>6.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Accessing and updating your information
                </span>
              </em>
            </h1>
            <p
              style={{
                marginTop: '6pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '10pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                You can update your information through your account and/or profile settings. If you
                believe that personal information we hold about you is incorrect, incomplete or
                inaccurate, then you may request us to amend it.
              </span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                Additionally, You may request access to any personal information we hold about you
                at any time by contacting us at{' '}
              </span>
              <a style={{ textDecoration: 'none' }} href="mailto:support@conrati.com">
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>support</span>
                </u>
              </a>
              <a style={{ textDecoration: 'none' }} href="mailto:support@conrati.com">
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>@conrati.com</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                . Where we hold information that you are entitled to access, we will try to provide
                you with suitable means of accessing it (for example, by mailing or emailing it to
                you). We may charge you a fee to cover our administrative and other reasonable costs
                in providing the information to you. We will not charge for simply making the
                request and will not charge for making any corrections to your personal information
                that you cannot make yourself.{' '}
              </span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                There may be instances where we cannot grant you access to the personal information
                we hold. For example, we may need to refuse access if granting access would
                interfere with the privacy of others or if it would result in a breach of
                confidentiality. If that happens, we will give you written reasons for any refusal.
              </span>
            </p>
            <h3
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                Changes to our privacy policy
              </span>
            </h3>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '6.75pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                We may change this privacy policy from time to time. Any updated versions of this
                privacy policy will be posted on our website. Please review it regularly.
              </span>
            </p>
            <p
              style={{
                marginTop: '6pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '10.5pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacypolicy;
