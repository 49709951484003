export default {
  copyProfileLink: 'Profile Link Copied!',
  copyProfileLinkCompleteWarning: 'Please complete your profile details to copy profile link!',
  postShared: 'Post shared succesfully!',
  postShareFailed: 'Post shared succesfully!',
  sharingPost: 'Sharing Post...',
  sharingPostWarning: 'Please complete your profile details to share your profile!',
  imageUploadSuccess: 'Profile picture updated.',
  imageUploadWarning: 'Please upload profile picture to continue.',
  error: 'Something went wrong. Please try again',
  alertAccountType: 'Please select an account type.',
  accessDenied: 'User denied access.',
  arIncompleteAccunt: 'Please complete your account details to book an Advice Provider.',
  apIncompleteAccunt: 'Please complete your profile details to access this section.',
  pastTimeSlot: 'Please select a time slot that is at least 24 hours from the current time.',
  noAvailbleSlots:
    'No available slots found on the particular date. Please switch to a monthly view to check availability.',
  requiredFieldsMissing: 'Please fill all the required details',
  verifyEmail: 'Please verify your email id to continue.',
  personalDetailsIncomplete: 'Please complete your personal details to continue.',
  businessDetailsIncomplete: 'Please complete your business details to continue.',
  calendarDetailsIncomplete: 'Please complete your calendar settings to continue.',
  kycNotVerified: 'In order to trigger a payout, please complete your KYC first.  ',
  payoutAmountInvalid: 'You do not have sufficient balance to trigger a payout.',
  availabilityTimeInvalid:
    'Available to time should be greater then Available from time. The maximum Available to time allowed is 11:30 pm.',
};
