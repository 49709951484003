import React, { Component } from 'react';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styles from '../Myaccount.module.css';
import EditTooltip from '../../Common/EditTooltip/EditTooltip';
import freeIcon from '../../Common/assets/free.png';
import info from '../../Common/assets/info.svg';
import EditRadio from '../../Common/EditRadio/EditRadio';
import { editProfile } from '../../../services/userService';

class CalendarDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      isFreeSessionOpted: false,
      freeSessionAnimate: false,
    };
  }

  componentDidMount() {
    const { user, location } = this.props;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const setting = searchParams.get('setting') || '';

    if (setting === 'free_consultaion') {
      this.setState({
        edit: true,
        freeSessionAnimate: true,
      });

      window.scrollTo(0, this.editelem.offsetTop);
    }

    const { isFreeSessionOpted } = user;
    this.setState({
      isFreeSessionOpted,
    });
  }

  onTooltipClick = () => {
    const { edit } = this.state;

    if (!edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    return this.setState({ edit: !edit });
  };

  handleOutsideClick = (e) => {
    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onTooltipClick();
  };

  onClickRadio = (isFreeSessionOpted) => {
    let toastId = null;

    toastId = toast.warn('Updating Free Consultation Settings..', {
      position: 'top-center',
      hideProgressBar: true,
      autoClose: false,
    });

    const { onEditProfile } = this.props;
    editProfile({
      is_free_session_opted: isFreeSessionOpted,
      opt_notification_email: isFreeSessionOpted ? 1 : 2,
    })
      .then((response) => {
        toast.update(toastId, {
          render: 'Free Consultation Settings Updated',
          autoClose: 1000,
        });
        onEditProfile(response.result);
      })
      .catch(() => {
        toast.update(toastId, {
          render: 'Free Consultation Settings Update Failed.',
          autoClose: 1000,
        });
      });
  };

  render() {
    const { edit, isFreeSessionOpted, freeSessionAnimate } = this.state;

    const styleA = freeSessionAnimate
      ? cx(styles.personal, styles.cardborder, styles.animateShake)
      : cx(styles.personal, styles.cardborder);

    return (
      <div
        className={edit ? styleA : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={freeIcon} alt="calendar" />
          <p className={styles.atitle}>Free Consultation Settings</p>

          <p className={styles.rerrorc}>* Required</p>

          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={edit} />
          </div>
        </div>
        {edit ? (
          <div className={styles.acbody}>
            <div className={styles.consultSettings}>
              <ReactTooltip
                id="actip"
                place="top"
                type="warning"
                effect="solid"
                className={styles.toolttip}
              />
              <div>
                <p className={styles.emailct}>Consultation Settings</p>
                <div style={{ display: 'flex' }}>
                  <p className={styles.alisub}>Allow 30 min free consultation on first booking</p>
                  <img
                    data-for="actip"
                    data-tip="Conrati recommends this configuration. Consultants who offer free 30-minute bookings get scheduled for paid consultations more often. It is a fantastic tool to market yourself to new prospects."
                    src={info}
                    width="15px"
                    height="15px"
                    alt="Info"
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
              <EditRadio
                checked={isFreeSessionOpted}
                onCheck={() => {
                  this.onClickRadio(!isFreeSessionOpted);
                  this.setState({
                    isFreeSessionOpted: !isFreeSessionOpted,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.alitem}>
            <div className={styles.acbody}>
              <div className={styles.consultSettings}>
                <ReactTooltip
                  id="actip"
                  place="top"
                  type="warning"
                  effect="solid"
                  className={styles.toolttip}
                />
                <div>
                  <p className={styles.emailct}>Consultation Settings</p>
                  <div style={{ display: 'flex' }}>
                    <p className={styles.alisub}>Allow 30 min free consultation on first booking</p>
                    <img
                      data-for="actip"
                      data-tip="Conrati recommends this configuration. Consultants who offer free 30-minute bookings get scheduled for paid consultations more often. It is a fantastic tool to market yourself to new prospects."
                      src={info}
                      width="15px"
                      height="15px"
                      alt="Info"
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </div>
                <EditRadio
                  checked={isFreeSessionOpted}
                  onCheck={() => {
                    this.setState({
                      isFreeSessionOpted: !isFreeSessionOpted,
                    });
                  }}
                  disabled
                />
              </div>
            </div>{' '}
          </div>
        )}
      </div>
    );
  }
}

CalendarDetails.propTypes = {
  user: PropTypes.shape({
    isFreeSessionOpted: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  onEditProfile: PropTypes.func.isRequired,
};

export default CalendarDetails;
