import React, { Component } from 'react';
import { connect } from 'react-redux';
import Headers from '../../Headers/Headers';
import UnauthHeader from '../../Headers/UnauthHeader/UnauthHeader';

class StaticHeader extends Component {
 constructor(props) {
    super(props);

    this.state = {
      mobileApp: false,
    };
  }

  componentDidMount() {
    // Get is open_in_app from url href
    const urlParams = new URLSearchParams(window.location.search);

    if(urlParams.get('open_in_app') === 'true') {
      this.setState({ mobileApp: true });
    }
    
  }

  render() {
    const { mobileApp } = this.state;
    <>
      {
        !mobileApp && (
          <>
              {
                this.props.user.isLoggedIn ? <Headers /> : <UnauthHeader />
                
              }

          </>
        )
      }
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState,
  };
};

export default connect(mapStateToProps)(StaticHeader);
