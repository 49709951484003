/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../style.module.css';

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlider: 0,
      activeImg: '',
      activeImg2x: null,
      activeLink: '',
    };

    this.sliderInterval = null;
  }

  componentDidMount() {
    this.sliderInterval = setInterval(this.onChangeSliderImg, 7000);

    const { sliderData } = this.props;
    const { sliderUserList } = sliderData;

    // Set default pic to 1st item of array
    this.setState({
      activeSlider: 1,
      activeImg: sliderUserList[0].img,
      activeImg2x: sliderUserList[0].img2x,
      activeLink: sliderUserList[0].link,
    });
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }

  onChangeSliderImg = () => {
    const { activeSlider } = this.state;
    const { sliderData } = this.props;
    const { sliderUserList } = sliderData;

    if (activeSlider === sliderUserList.length) {
      this.setState({
        activeSlider: 1,
        activeImg: sliderUserList[0].img,
        activeImg2x: sliderUserList[0].img2x,
        activeLink: sliderUserList[0].link,
      });
    } else {
      this.setState({
        activeSlider: activeSlider + 1,
        activeImg: sliderUserList[activeSlider].img,
        activeImg2x: sliderUserList[activeSlider].img2x,
        activeLink: sliderUserList[activeSlider].link,
      });
    }
  };

  onCLickSliderDot = (sliderId) => {
    // Clear interval
    clearInterval(this.sliderInterval);
    const { sliderData } = this.props;
    const { sliderUserList } = sliderData;

    const clickedSlide = sliderUserList.find((slide) => slide.id === sliderId);
    this.setState({
      activeSlider: clickedSlide.id,
      activeImg: clickedSlide.img,
      activeLink: clickedSlide.link,
    });

    // Restart interval
    this.sliderInterval = setInterval(this.onChangeSliderImg, 10000);
  };

  render() {
    const { activeImg, activeLink, activeImg2x } = this.state;
    const { sliderData } = this.props;
    const { sliderTitle, sliderSubTitle, popularItemsList, themeTitle } = sliderData;

    return (
      <div className={styles.slider}>
        <div className={styles.imageCardMain}>
          <div className={styles.imageCardContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.textHeaders}>
                <p className={styles.title}>{sliderTitle}</p>
                <p className={styles.subtitle}>{sliderSubTitle}</p>
              </div>
              <div className={styles.popularlist}>
                <p className={styles.ptitle}>Popular</p>
                <ul className={styles.popList}>
                  {popularItemsList.map((item, index) => (
                    <li className={styles.pliitem} key={index}>
                      <a
                        href={`/search?&rate=&category=&skills=${item.toLowerCase()}&languages=`}
                        target="_open"
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={styles.rightContainer}>
              <a href={`${activeLink}`} target="_open">
                <img
                  className={styles.userImg}
                  src={activeImg}
                  srcSet={`${activeImg2x} 2x`}
                  alt={themeTitle}
                />
              </a>
            </div>
          </div>
        </div>

        {/* <div className={styles.sliderDots}>
          {sliderUserList.map(slide => {
            return (
              <div
                key={slide.id}
                className={slide.id === activeSlider ? styles.dotsActive : styles.dots}
                role="button"
                tabIndex={0}
                onKeyPress={() => true}
                aria-label="Slide"
                onClick={() => this.onCLickSliderDot(slide.id)}
              />
            );
          })}
        </div> */}
      </div>
    );
  }
}

Slider.propTypes = {
  sliderData: PropTypes.shape({
    sliderSubTitle: PropTypes.string,
    sliderTitle: PropTypes.string,
    sliderUserList: PropTypes.array,
    popularItemsList: PropTypes.array,
    themeTitle: PropTypes.string,
  }).isRequired,
};

export default Slider;
