import React, { Component } from 'react';
import styles from '../Static.module.css';
import general_ico from '../../Common/assets/upcoming.svg';
import general_active_ico from '../../Common/assets/general_active.svg';
import calendar_ico from '../../Common/assets/calendar.svg';
import calendar_active_ico from '../../Common/assets/calendar_active_faq.svg';
import profile_ico from '../../Common/assets/userblue.svg';
import profile_active_ico from '../../Common/assets/profile_active.svg';
import dashboard_ico from '../../Common/assets/dashboardred.svg';
import dashboard_active_ico from '../../Common/assets/dashboard_active.svg';
import payment_ico from '../../Common/assets/payment.svg';
import payment_active_ico from '../../Common/assets/payment_active.svg';
import session_ico from '../../Common/assets/session.svg';
import session_active_ico from '../../Common/assets/session_active.svg';
import General from './Questions/General';
import Profile from './Questions/Profile';
import Dashboard from './Questions/Dashboard';
import Calendar from './Questions/Calendar';
import Session from './Questions/Session';
import Payments from './Questions/Payments';
import cx from 'classnames';

class support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: 'general',
    };
  }

  componentDidMount() {
    let searchparams = new URLSearchParams(this.props.location.search);
    if (searchparams.get('section')) {
      this.setState({ activetab: searchparams.get('section') });
    } else {
      this.props.history.push(`/help/faq?section=general`);
    }
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    let searchparams = new URLSearchParams(newprops.location.search);
    this.setState({ activetab: searchparams.get('section') });
  }

  onClickTab = (e, tabname) => {
    e.preventDefault();
    this.props.history.push(`/help/faq?section=${tabname}`);
  };

  render() {
    let activetab = '';

    switch (this.state.activetab) {
      case 'general':
        activetab = <General />;
        break;
      case 'profile':
        activetab = <Profile />;
        break;
      case 'dashboard':
        activetab = <Dashboard />;
        break;
      case 'calendar':
        activetab = <Calendar />;
        break;
      case 'sessions':
        activetab = <Session />;
        break;
      case 'payments':
        activetab = <Payments />;
        break;
      default:
        activetab = <General />;
        break;
    }

    return (
      <div>
        <div className={styles.brheader}>
          <h1>How may we help you?</h1>
        </div>
        <div className={styles.brcontainer}>
          <div className={styles.sumenu}>
            <div className={styles.sucategories}>
              <div
                className={
                  this.state.activetab === 'general'
                    ? cx(styles.suitems, styles.gactive)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'general')}
              >
                <img
                  src={this.state.activetab === 'general' ? general_active_ico : general_ico}
                  alt="general"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>General</p>
              </div>
              <div
                className={
                  this.state.activetab === 'profile'
                    ? cx(styles.suitems, styles.practive)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'profile')}
              >
                <img
                  src={this.state.activetab === 'profile' ? profile_active_ico : profile_ico}
                  alt="profile"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>Profile</p>
              </div>
              <div
                className={
                  this.state.activetab === 'dashboard'
                    ? cx(styles.suitems, styles.dactive)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'dashboard')}
              >
                <img
                  src={this.state.activetab === 'dashboard' ? dashboard_active_ico : dashboard_ico}
                  alt="dashboard"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>Dashboard</p>
              </div>
              <div
                className={
                  this.state.activetab === 'calendar'
                    ? cx(styles.cactive, styles.suitems)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'calendar')}
              >
                <img
                  src={this.state.activetab === 'calendar' ? calendar_active_ico : calendar_ico}
                  alt="calendar"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>Calendar</p>
              </div>
              <div
                className={
                  this.state.activetab === 'sessions'
                    ? cx(styles.sactive, styles.suitems)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'sessions')}
              >
                <img
                  src={this.state.activetab === 'sessions' ? session_active_ico : session_ico}
                  alt="sessions"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>Sessions</p>
              </div>
              <div
                className={
                  this.state.activetab === 'payments'
                    ? cx(styles.pactive, styles.suitems)
                    : styles.suitems
                }
                onClick={(e) => this.onClickTab(e, 'payments')}
              >
                <img
                  src={this.state.activetab === 'payments' ? payment_active_ico : payment_ico}
                  alt="payments"
                  className={styles.suitemico}
                />
                <p className={styles.suitemtitle}>Payments</p>
              </div>
            </div>
          </div>
          <div className={styles.sumenu}>{activetab}</div>
        </div>
      </div>
    );
  }
}

export default support;
