import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styles from './BookingDetails.module.css';
import Headers from '../Headers/Headers';
import calenderico from '../Common/assets/icon_calendar.svg';
import timeico from '../Common/assets/icon_clock_2.svg';
import { bookingDetails } from '../../actions/sessionAction';
import Cancel from './Cancel/Cancel';
import globalPropTypes from '../../config/proptype';
import { alert, checkIsMobileBrowser } from '../../utils';
import usernull from '../Common/assets/usernull.svg';
import { confirmBooking } from '../../services/userService';

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '258px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};

class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { location, onFetchBookingDetails } = this.props;

    const urlparams = new URLSearchParams(location.search);
    const bookingId = urlparams.get('booking_id');
    onFetchBookingDetails(bookingId);

    const type = urlparams.get('type');

    if (type) {
      if (type === 'cancel') {
        this.openModal();
      }

      if (type === 'confirm') {
        confirmBooking(bookingId);
        alert('Booking Confirmed');
      }
    }

    if (checkIsMobileBrowser(navigator.userAgent)) {
      customStyles.content.width = '90%';
      customStyles.content.boxSizing = 'border-box';
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { activeBooking, history, user } = this.props;
    const { modalIsOpen } = this.state;

    const { apUserData, isSessionFree } = activeBooking;
    const invoiceData = activeBooking.invoiceData || {};

    return (
      <div>
        <Headers />
        <div className={styles.book}>
          {apUserData && (
            <div className={styles.bcontroller}>
              <div className={styles.bookbox}>
                <div className={styles.bookcard}>
                  <div className={styles.dtit}>
                    <p className={styles.db}>
                      Booking Reference Number: {activeBooking.bookingId}{' '}
                    </p>
                  </div>
                  <div className={styles.head}>
                    <p>Booking Confirmation</p>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.userdetails}>
                      <img
                        src={apUserData.primaryImg ? apUserData.primaryImg : usernull}
                        alt="userimg"
                      />
                      <div className={styles.usrd}>
                        <p className={styles.ctit}>
                          {`${apUserData.firstName} ${apUserData.lastName}`}
                        </p>
                      </div>
                      <div className={styles.usrrate}>
                        <p className={styles.ctit}>Hourly Rate</p>
                        <p>
                          {`${apUserData.currency.currencySymbol} ${invoiceData.booking.hourlyRate}`}
                        </p>
                      </div>
                    </div>
                    <div className={styles.userdatetime}>
                      <div className={styles.tpdiv}>
                        <p className={styles.tptit}>Topic Title</p>
                        <p className={styles.tptitsub}>{activeBooking.title}</p>
                      </div>
                      <div className={styles.tpdiv}>
                        <p className={styles.tptit}>Description</p>
                        <p className={styles.tptitsub}>{activeBooking.topicDescription}</p>
                      </div>

                      <div className={styles.tpdiv}>
                        <p className={styles.tptit}>Session date and time</p>
                        <div className={styles.time}>
                          <div className={styles.tdate}>
                            <img src={calenderico} alt="calendar" />
                            <p className={styles.tptitsubd}>
                              {moment.unix(activeBooking.from).format('DD MMMM YYYY')}
                            </p>
                          </div>
                          <div className={styles.tdate}>
                            <img src={timeico} alt="calendar" height="10px" />
                            <p className={styles.tptitsubd}>
                              {moment.unix(activeBooking.from).format('hh:mm a')} –{' '}
                              {moment.unix(activeBooking.to).format('hh:mm a')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.form}>
                      <div className={styles.transaction}>
                        <div className={styles.stransaction}>
                          <div className={styles.sdbody}>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitle}>Description</p>
                              <p className={styles.sub}>Net value</p>
                              <p className={styles.sub}>
                                VAT ({invoiceData.vat.vatType.toUpperCase()} -{' '}
                                {invoiceData.vat.percentage}%)
                              </p>
                            </div>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitle}>Hours</p>
                              <p className={styles.sub}>{activeBooking.hours}</p>
                            </div>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitle}>Hourly Rate</p>
                              <p className={styles.sub}>
                                {`${apUserData.currency.currencySymbol} ${invoiceData.booking.hourlyRate}`}
                              </p>
                            </div>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitlet}>Total</p>
                              <p className={styles.subt}>
                                {invoiceData.transaction.totalWithCurrency}
                              </p>
                              <p className={styles.subt}>{invoiceData.vat.vatAmountWithCurrency}</p>
                            </div>
                          </div>
                          <div className={styles.gtotal}>
                            <div className={styles.price}>
                              <p className={styles.gtotaltxt}>
                                <b>Grand Total</b>
                              </p>
                              <p className={styles.gamnt}>
                                {invoiceData.transaction.grandTotalWithCurrency}
                              </p>
                            </div>
                          </div>
                          {isSessionFree && (
                            <p className={styles.inoticef}>
                              *** This is a free consultation, therefore, no rates apply.
                            </p>
                          )}
                          <p className={styles.inotice}>
                            *** Invalid for vat purpose until the booking is converted to invoice.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <div
                      className={styles.cancel}
                      onClick={() => {
                        if (history.length > 1) {
                            history.goBack()
                        }

                        if (history.length <= 1) {
                            history.replace('/dashboard')
                        }
                      }}
                      onKeyPress={() => console.log('Key Pressed')}
                      role="button"
                      tabIndex="0"
                    >
                      <button type="button" className={styles.button}>
                        Back
                      </button>
                    </div>
                    <div
                      className={styles.cancel}
                      onClick={() => {
                        this.setState({ modalIsOpen: true });
                      }}
                      onKeyPress={() => console.log('Key Pressed')}
                      role="button"
                      tabIndex="-1"
                    >
                      <button type="button" className={styles.button} onClick={this.onSubmit}>
                        Cancel Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Cancel Booking"
        >
          <Cancel
            history={history}
            user={user}
            booking={activeBooking}
            closeModal={this.closeModal}
          />
        </Modal>
      </div>
    );
  }
}

BookingDetails.propTypes = {
  user: PropTypes.shape({}).isRequired,
  location: globalPropTypes.location.isRequired,
  onFetchBookingDetails: PropTypes.func.isRequired,
  activeBooking: globalPropTypes.booking.isRequired,
  history: globalPropTypes.history.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    request: state.request,
    activeBooking: state.session.activeBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchBookingDetails: bookingDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(BookingDetails);
