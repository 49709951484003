import React, { Component } from 'react';
import styles from './SessionCallTab.module.css';
import Info from './Info/Info';
import Share from './Share/Share';
import Chat from './Chat/Chat';

class SessionCallTab extends Component {
  render() {
    let active = <Info activeBooking={this.props.activeBooking} />;

    if (this.props.activeTab === 'chat') {
      active = (
        <Chat
          session={this.props.session}
          onNewMessage={this.props.onNewMessage}
          activeChat={this.props.activeChat}
        />
      );
    } else if (this.props.activeTab === 'share') {
      active = <Share />;
    } else {
      active = <Info activeBooking={this.props.activeBooking} />;
    }

    return <div className={styles.calltab}>{active}</div>;
  }
}

export default SessionCallTab;
