import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  signinUser,
  userOauthData,
  clearNewRequest,
  registerUser,
  socialRegisterUserType,
} from '../../actions/userAction';
import { clearOngoingBooking } from '../../actions/sessionAction';
import Register from './Register/Register';
import Signin from './Signin/Signin';
import styles from './Auth.module.css';
import whitelogo from '../Common/assets/whiteyellow-logo.png';
import bluelarge from '../Common/assets/blue-large.svg';
import BookingDetails from './BookingDetails';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScreen: 'signin',
      fromBooking: false,
    };
  }

  componentDidMount() {
    const { userState, onClearNewRequest, history, location } = this.props;
    // Scroll the window on active
    window.scrollTo(0, 0);

    // Clear user reducer
    onClearNewRequest();

    if (userState.isLoggedIn) {
      return history.replace('/dashboard');
    }

    // Switch screens between register and signin
    if (location.pathname === '/register') {
      this.setState({ activeScreen: 'register' });
    }

    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('redirect') && searchParams.get('redirect') === 'booking') {
      const { ongoingBookingDetails, onClearOngoingBooking } = this.props;
      const { start } = ongoingBookingDetails;

      if (!start || moment().isAfter(start)) {
        onClearOngoingBooking();
      } else {
        this.setState({
          fromBooking: true,
        });
      }
    }

    return true;
  }

  componentWillUnmount() {
    const { onClearNewRequest } = this.props;
    onClearNewRequest();
  }

  onTabSwitch = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { id } = e.target;
    if (id === 'sigin') {
      history.push('/signin');
    }

    if (id === 'register') {
      history.push('/register');
    }
  };

  loaderComponent = () => (
    <div>
      <div className={styles.aload}>
        <div className={styles.aloader} />
      </div>
    </div>
  );

  render() {
    const abtnactive = cx(styles.abtnlinkl, styles.abtnactive);
    const abtnactiver = cx(styles.abtnlinkr, styles.abtnactive);
    const {
      isError,
      isLoading,
      onSigninUser,
      onUserOauthData,
      onRegisterUser,
      onSocialRegisterUserType,
      history,
      location,
      userState,
    } = this.props;
    const { activeScreen, fromBooking } = this.state;

    return (
      <>
        <div className={styles.auth}>
          <header className={styles.aheader} style={{ backgroundImage: `url(${bluelarge})` }}>
            <Link to="/" className={styles.alink}>
              <img src={whitelogo} width="158px" alt="" />
            </Link>
          </header>
          <div className={styles.authBox}>
            {fromBooking && <BookingDetails />}
            <div className={styles.abox}>
              <section className={isError ? cx(styles.atab, styles.ataberror) : styles.atab}>
                {isLoading && this.loaderComponent()}
                <div className={styles.abtn}>
                  <a
                    href={`/signin${location.search}`}
                    className={activeScreen === 'signin' ? abtnactive : styles.abtnlinkl}
                    id="sigin"
                  >
                    Sign in
                  </a>
                  <a
                    href={`/register${location.search}`}
                    className={activeScreen !== 'signin' ? abtnactiver : styles.abtnlinkr}
                    id="register"
                  >
                    Register
                  </a>
                </div>
                <div>
                  {activeScreen === 'signin' ? (
                    <Signin
                      onSigninUser={onSigninUser}
                      onUserOauthData={onUserOauthData}
                      onError={this.onError}
                      history={history}
                      location={location}
                    />
                  ) : (
                    <Register
                      history={history}
                      location={location}
                      onError={this.onError}
                      onRegisterUser={onRegisterUser}
                      onUserOauthData={onUserOauthData}
                      onSocialRegisterUserType={onSocialRegisterUserType}
                      userState={userState}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Auth.propTypes = {
  userState: PropTypes.shape({
    user: PropTypes.object,
    isLoggedIn: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    replace: PropTypes.func,
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onSigninUser: PropTypes.func.isRequired,
  onUserOauthData: PropTypes.func.isRequired,
  onClearNewRequest: PropTypes.func.isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  onSocialRegisterUserType: PropTypes.func.isRequired,
  ongoingBookingDetails: PropTypes.shape({
    start: PropTypes.string,
  }).isRequired,
  onClearOngoingBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    isLoading: state.userState.isLoading,
    isError: state.userState.isError,
    ongoingBookingDetails: state.userState.ongoingBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onSigninUser: signinUser,
      onUserOauthData: userOauthData,
      onClearNewRequest: clearNewRequest,
      onRegisterUser: registerUser,
      onSocialRegisterUserType: socialRegisterUserType,
      onClearOngoingBooking: clearOngoingBooking,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Auth);
