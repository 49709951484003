import React, { Component } from 'react';
import styles from './LoaderButton.module.css';
import roller from '../../Common/assets/roller.svg';
import Line from '../../Common/assets/LineDark.svg';
import PropTypes from 'prop-types';

class LoaderButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      loading: false,
      success: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.response) {
      this.setState({ active: false, loading: false, success: true });
    }

    if (newProps.retry) {
      this.setState({ active: true, loading: false, success: false });
    }
  }

  onSubmit() {
    this.setState({ active: false, loading: true });
    this.props.onClick();
  }

  render() {
    let color = this.props.color === 'success' ? '#fff' : '#555555';
    let bgcolor = this.props.color === 'success' ? '#F6B343' : '#fff';

    return (
      <div>
        {this.state.active && (
          <button
            style={{ backgroundColor: bgcolor, color: color }}
            disabled={this.props.disabled ? this.props.disabled : false}
            className={styles.btn}
            onClick={this.onSubmit}
          >
            {this.props.active || 'SUBMIT'}
          </button>
        )}
        {this.state.loading && (
          <button
            style={{ backgroundColor: bgcolor, color: color }}
            disabled={true}
            className={styles.btn}
            onClick={this.onSubmit}
          >
            <img src={roller} alt="Loading" className={styles.loader} />
          </button>
        )}
        {this.state.success && (
          <button
            style={{ backgroundColor: bgcolor, color: color }}
            disabled={true}
            className={styles.btnc}
            onClick={this.onSubmit}
          >
            <img src={Line} alt="Sent" className={styles.sent} />
            {this.props.success || 'SUBMITED'}
          </button>
        )}
      </div>
    );
  }
}

LoaderButton.propTypes = {
  active: PropTypes.string,
  success: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default LoaderButton;
