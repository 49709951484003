import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const Payments = () => {
  return (
    <div>
      <p className={styles.ftitle}>Payments</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="When does the Advice Receiver actually pay for the consultation?"
          content={`
                    When an Advice Receiver books an online consultation, his credit card is pre-authorised but the payment is only made when there has been a successful consultation or the Advice Receiver did not show up for the consultation and the Advice Provider was present in the meeting
                `}
          expand
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What currency will be charged on the Advice Receiver’s credit card?"
          content={`
                    Advice Provider’s set their hourly rates according to their relevant currency. To uphold best accounting business practice, Conrati's generation of invoices and deduction of funds on behalf of Advice Providers are always in the relevant Advice Provider’s currency.

                    Advice Receivers are shown an estimate or quotation of a consultation based on their selected currency with which they registered. Whilst Conrati updates it’s conversion rates hourly through the use of a third-party conversion service, the estimated converted cost could differ slightly from the actual amount charged on the credit card due to fluctuations and discrepancies in real-time exchange rates.
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="When does the Advice Provider receive his money for the consultation?"
          content={`
                    The Advice Provider is paid after each successful consultation as long as he has been successfully KYC verified. If the user is not successfully KYC verified, his/her money is parked into a dedicated personal wallet in MangoPay’s environment. Once successfully KYC verified, the payment is triggered. The duration of time taken for the payment to reflect depends on the country, and bank speed.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What is KYC, and what documents are required?"
          content={`
                    KYC stands for Know Your Customer and entails the whole verification process of your users. This process is part of European anti-money laundering regulation, known as AML, which requires institutions such as our third party payment providers (MANGOPAY) to make sure that money transacting through their system has been acquired and distributed legally.

                    Only Advice Providers have to submit KYC documents as only Advice Provider’s receive pay outs. Advice Providers will require to upload the following documents:
                    
                    Sole Trader:
                        • ID Proof / Passport
                        • Proof of Registration 

                    Business:
                        • ID Proof / Passport
                        • Proof of Registration
                        • Articles of Association 
                        • Shareholder Declaration

                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Does Conrati handle the money or a third party payment provider?"
          content={`
                    Conrati makes use of Mangopay.com and thus Conrati never holds the money of the Advice Receiver or of the Advice Provider - but is responsible for the correct configuration of the Mango system to ensure pay-ins and payouts according to legally compliant processes. 
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are the rules that determine whether VAT or Sales Tax apply to an online consultation?"
          content={`
                    The VAT or Sales Tax that is displayed on quotation and invoices generated on the system is automatically calculated considering factors such as the Advice Provider and Advice Receiver’s location of business, whether the business is registered for VAT, as well as the type of service being provided. The system calculates in accordance with rules and assumptions on international tax regulations. It is up you to notify Conrati should the quotation or invoice apply the incorrect determination of VAT or Sales Tax
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is there a difference between the initial quotation for a consultation and the final invoice?"
          content={`
          Advice Receivers are shown a converted estimate or quotation of a consultation based on their selected currency with which they registered. Estimated conversion cost could be slightly different from the actual amount charged due to fluctuations and discrepancies in exchange rates. The initial quotation is a booking summary which when accepted triggers a credit card pre-authorisation to be executed based on this amount. This renders the balance as unavailable on a Advice Receiver’s card until the outcome of the consultation is known. Even though VAT is shown on the booking quotation and confirmation, it cannot be used as a tax invoice as the payment across to the Advice Provider has not actually taken place yet nor has there been an exchange of services. 

                    The final invoice of the Advice Provider to the Advice Receiver is generated after a successful consultation or Advice Receiver no-show (provided the Advice Provider is present in the consultation). The system then triggers the flow of funds and the creation of a legitimate tax invoice with an invoice number.
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Who carries the responsibility to ensure the information on the invoice to the Advice Receiver is correct?"
          content={`
                    The Advice Receiver - by agreeing to the terms and conditions of Conrati - makes use of the system to generate quotations and tax invoices on his behalf - however, the responsibility to ensure that the system generated the invoice 100% correct and legally compliant remains that of the Advice Receiver, who is obliged to inform Conrati of any mistakes within 48 hours so that it can be corrected. 

                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How can an incorrect invoice be corrected?"
          content={`
              The Advice Providers must inform Conrati within 2 working days from receipt of the invoice if any invoice generated by the platform is incorrect, or has been incorrectly issued, or the VAT or other similar taxes have been incorrectly included or excluded.

              The Advice Provider can email support@conrati.com with reference to booking or invoice number as well as the query. 
              
              Transactions will not be reversed or corrected, nor will any credit notes or new or additional invoices be issued by the Platform where an Advice Provider fails to notify Conrati within 2 days from the receipt of an invoice that such invoice is incorrect, or has been incorrectly issued, or the VAT or other similar taxes have been incorrectly included or excluded.
                  

                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Which currencies and countries are supported by the Conrati application?"
          content={`
          We support the United Kingdom (£), South African (R) as well as countries in Europe that trade using the Euro (€) including Austria, Belgium, Cyprus, Estonia, Finland, France, Germany, Greece, Italy, Ireland, Latvia, Lithuania, Luxembourg, Malta, the Netherlands, Portugal, Slovakia, Slovenia and Spain. Soon the platform will be rolled out into more countries. 


To uphold best accounting business practice, Conrati's generation of invoices and deduction of funds on behalf of Advice Providers are always in the relevant Advice Provider’s currency. This ensures that consultants receive faster payouts, do not incur unnecessary conversion costs on payouts and are able to account for every cent of income earned through Conrati as the numerical amounts on invoices match the exact flow of funds.

Advice Receivers are shown a converted estimate or quotation of a consultation based on their selected currency with which they registered.

                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is any income tax deducted on a payment to an Advice Provider?"
          content={`
                    No, no income tax is deducted on a payment to an Advice Provider. It remains the responsibility of the Advice Provider to pay income tax in his country of residence / wherever else he may need to pay income tax. 

                    Conrati will share information of payments to Advice Providers with legal authorities when asked to do so to remain compliant - and Advice Providers need to note that in order to continue the usage of this platform their own tax compliance is of the utmost importance.
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Does Conrati charge an Advice Receiver to make use of the system? "
          content={`
                    No charge is made to an Advice Receiver for using the system
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Does Conrati charge an Advice Provider to make use of the system? "
          content={`
                    Yes, Conrati charges an Advice Provider a 10% platform fee. This fee is only based on money that the Advice Provider earns on our system. In other words, Advice Providers will only be charged this fee for a successful consultation or a consultation when there is a no-show by the Advice Reciever and the Advice Provider is present in the consultation.

                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default Payments;
