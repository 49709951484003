import React, { useRef, useState } from 'react';
import styles from './PaymentCard.module.css';
import creditCardIcon from '../../Common/assets/newcard.svg';
import mangoPayIcon from '../../Common/assets/mangopay.png';
import padlock from '../../Common/assets/padlock.svg';
import { addNewCard, browserInfo } from '@conrati/utils';
import { alert } from '../../../utils';
import loader from '../../Common/assets/roller.svg';
import { chargeCard } from '../../../services/userService';
import { setAppPaymentReturnUrl } from '@conrati/utils';
import { Track } from '@conrati/tracking';
import { useLocation } from 'react-router-dom';

// Loader spinner component
function Loader() {
  return <img src={loader} height={30} />;
}

// Format error message from mangopay
function formatErrorMessage(error) {
    if (error.ResultMessage){
        return error.ResultMessage.replace(/_/g, ' ');
    }

    return '';
}

// Get Params from url
function deleteFirstItem(obj) {
  const [[firstKey, _]] = Object.entries(obj);
  delete obj[firstKey];
}

// Charge card
function chargeCardWithId(paymentData, additonalParams = null) {
  const { variant, card_id, mangopay_id, currency, ip_address, user_id } = paymentData;

  

  if (additonalParams) {
    deleteFirstItem(additonalParams);
  }

  const reqData = {
    variant,
    card_id,
    mangopay_id,
    currency,
    ip_address,
    browser_info: browserInfo(),
    user_id,
    additonal_params: additonalParams
  };

  return chargeCard(reqData);
}

//Supported currencies
const supportedCurrencies = ['EUR', 'GBP'];

// Add card for supported currencies
function addCardForSupportedCurrencies(currency, mangopayId, cardNumber, expiry, cvc) {
  // Remove the currency which is added already
  const supportedCurrenciesWithoutCurrent = supportedCurrencies.filter((curr) => curr !== currency);

  // Add card
  supportedCurrenciesWithoutCurrent.forEach((curr) => {
    addNewCard(
      {
        mango_pay_id: mangopayId,
        currency: curr,
      },
      {
        cardNumber: cardNumber,
        cardExpirationDate: expiry,
        cardCvx: cvc,
      }
    );
  });
}

//Generate button text
function generateButtonText(variant) {
  if (variant === 'o' || variant === 't' || variant === 'x') {
    return 'ADD CARD';
  }

  return 'PAY';
}

function PaymentCard(props) {
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCVC] = useState('');
  const [addingCard, setAddingCard] = useState(false);

  // Location state
  const location = useLocation();
  
  // Get all Params from url
  const params = new URLSearchParams(location.search);

  // All params from url as object
  const allParamsObject = Object.fromEntries(params);

  const paymentFormRef = useRef(null);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      setAddingCard(true);
      setAppPaymentReturnUrl(window.location.href); // Set return url if failed payment

      // const { mangopayId, currencyCode, variant, userId } = props;

      const { mangopayId, variant, userId } = props;

      Track('payment/experiment/payment-page-view-add-card', { ...props });

      const card = await addNewCard(
        {
          mango_pay_id: mangopayId,
          currency: "EUR", // Add card in euro only https://conrati.atlassian.net/browse/JCONRATI-502
        },
        {
          cardNumber: cardNumber,
          cardExpirationDate: expiry,
          cardCvx: cvc,
        }
      );

      // Add card for remaining supported currencies
      // addCardForSupportedCurrencies(currencyCode, mangopayId, cardNumber, expiry, cvc);

      const paymentDetails = await chargeCardWithId({
        variant,
        card_id: card.CardId,
        mangopay_id: mangopayId,
        currency: "EUR", // Charge only in euro https://conrati.atlassian.net/browse/JCONRATI-502
        user_id: userId,
      },allParamsObject);

      setAddingCard(false);

      if (paymentDetails.returnUrl) {
        window.localStorage.setItem('experimentReturnUrl', paymentDetails.returnUrl);
        window.localStorage.setItem('experimentVariant', variant);
      }

      // Redirect to thank you page if variant is x
      if (variant === 'x') {
        return (window.location.href = 'https://pages.conrati.com/thank-you-for-booking-ec007/');
      }

      return (window.location.href = paymentDetails.redirectUrl);
    } catch (err) {
      
      Track('payment/experiment/payment-page-view-add-card-failed', { ...props });
      setAddingCard(false);

      alert(
       formatErrorMessage(err) ||
        err.message ||
        'Something went wrong. Contact support.'
      );
    }
  };

  return (
    <div className={styles.paymentCardContainer}>
      <div className={styles.paymentCardTitle}>
        <div className={styles.creditCardIcon}>
          <img src={creditCardIcon} alt="Credit card" />
        </div>
        Card Details {props.isFreeSession ? '(Incase Of A No-Show)' : ''}
      </div>
      <div className={styles.paymentCardFields}>
        <div className={styles.paymentCardFieldsTitle}>Fill in the card details</div>
        <div>
          <form onSubmit={handleSubmit} ref={paymentFormRef}>
            <div className={styles.paymentCardField}>
              <label htmlFor="name-on-card">Name on card</label>
              <input
                type="text"
                className={styles.paymentFormControl}
                id="name-on-card"
                placeholder="Name on card"
                value={nameOnCard}
                onChange={(event) => setNameOnCard(event.target.value)}
              />
            </div>
            <div className={styles.paymentCardField}>
              <label htmlFor="card-number">Card number</label>
              <input
                type="number"
                className={styles.paymentFormControl}
                autoComplete="off"
                id="card-number"
                placeholder="Card number"
                value={cardNumber}
                onChange={(event) => setCardNumber(event.target.value)}
              />
            </div>

            <div className={styles.paymentCardFieldGroup}>
              <div className={`${styles.paymentCardField} ${styles.paymentCardExpiry}`}>
                <label htmlFor="name-on-card">Expiry</label>
                <input
                  type="string"
                  className={styles.paymentFormControl}
                  autoComplete="off"
                  id="name-on-card"
                  placeholder="MMYY"
                  value={expiry}
                  onChange={(event) => setExpiry(event.target.value)}
                  maxLength={4}
                />
              </div>
              <div className={`${styles.paymentCardField} ${styles.paymentCardCVC}`}>
                <label htmlFor="name-on-card">CVC</label>
                <input
                  type="text"
                  className={styles.paymentFormControl}
                  id="name-on-card"
                  placeholder="***"
                  value={cvc}
                  onChange={(event) => setCVC(event.target.value)}
                />
              </div>
            </div>
            <div className={styles.paymentBtn}>
              <button
                className={styles.payBtn}
                onClick={() => handleSubmit}
                disabled={
                  !(
                    !addingCard &&
                    cardNumber.length > 0 &&
                    cvc.length > 0 &&
                    expiry.length > 0 &&
                    nameOnCard.length > 0
                  )
                }
              >
                {addingCard ? <Loader /> : generateButtonText(props.variant)}
              </button>
            </div>
          </form>
        </div>
        <div className={styles.paymentSecured}>
          <button
            type="button"
            onClick={() => {
              window.open('https://www.mangopay.com/privacy/');
            }}
          >
            <img src={mangoPayIcon} alt="mangopay" height="32px" width="151px" />
          </button>
          <img src={padlock} alt="secure" height="27px" width="24px" />
        </div>
        <div className={styles.paymentSecuredDescription}>
          Conrati entrust Mangopay (a secure third payment provider) to store and handle all payment
          related data
        </div>
      </div>
    </div>
  );
}

export default PaymentCard;
