/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './Headers.module.css';
import AuthHeader from './AuthHeader/AuthHeader';
import UnauthHeader from './UnauthHeader/UnauthHeader';
import { logOutUser, swicthAccount, updateProfile } from '../../actions/userAction';
import warningIco from '../Common/assets/warning.png';
import LoaderButton from '../Common/LoaderButton/LoaderButton';
import { apiurl } from '../../utils';
import enums from '../../config/enum';
import globalProptypes from '../../config/proptype';
import AlertFrozenProfile from './AlertFrozenProfile';

class Headers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };

    this.onSubmitEmail = this.onSubmitEmail.bind(this);
  }

  componentDidMount() {
    const { userState, onUpdateProfile } = this.props;
    const { user, isLoggedIn } = userState;
    const { isEmailVerified } = user;

    if (isLoggedIn && !isEmailVerified) {
      onUpdateProfile();
    }
  }

  onSubmitEmail() {
    const { userState } = this.props;
    const { userToken } = userState.user;
    const headers = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${userToken}`;

    axios.put(`${apiurl}/v1/user/email/resend`, {}, { headers }).then((result) => {
      if (result.data.success) {
        this.setState({ sent: true });
      }
    });
  }

  // Temporarly hide email notification until we find workarounds
  isSearchPage = () => {
    const { router } = this.props;
    const { location } = router;
    const { pathname } = location;

    if (pathname === '/search') {
      return true;
    }

    return false;
  };

  render() {
    const { userState } = this.props;
    const { sent } = this.state;
    return (
      <>
        {userState.isLoggedIn ? <AuthHeader {...this.props} /> : <UnauthHeader {...this.props} />}
        {!this.isSearchPage() && !userState.user.isEmailVerified && userState.isLoggedIn && (
          <div className={styles.notify}>
            <div className={styles.notifybody}>
              <div className={styles.mcon}>
                <img src={warningIco} alt="Warning" className={styles.notifyimg} />
                <p className={styles.ptext}>
                  Your email is not verified. Please check your email for the activation link.{' '}
                </p>
              </div>
              <div className={styles.sbtn}>
                <LoaderButton
                  active="RESEND"
                  success="SENT"
                  response={sent}
                  onClick={this.onSubmitEmail}
                />
              </div>
            </div>
          </div>
        )}
        {!this.isSearchPage() &&
          userState.isLoggedIn &&
          userState.user.social &&
          userState.user.social.oauthTokenStatus === enums.oauthTokenStatus.INVALID && (
            <div className={styles.notify}>
              <div className={styles.notifybody}>
                <div className={styles.mcon}>
                  <img src={warningIco} alt="Warning" className={styles.notifyimg} />
                  <p className={styles.ptext}>
                    Your calendar authorization has expired. Please re authorize your calendar from
                    my account section{' '}
                  </p>
                </div>
              </div>
            </div>
          )}
        {!this.isSearchPage() &&
          userState.isLoggedIn &&
          (userState.user.accountStatus === enums.accountStatus.review ||
            userState.user.accountStatus === enums.accountStatus.frozen) && <AlertFrozenProfile />}
      </>
    );
  }
}

Headers.propTypes = {
  userState: globalProptypes.userState.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onSwitchAccount: PropTypes.func.isRequired,
  experiment: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    router: state.router,
  };
};

const mapActionToPrps = (dispatch) => {
  return bindActionCreators(
    {
      onLogOut: logOutUser,
      onSwitchAccount: swicthAccount,
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToPrps)(Headers);
