import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.css';
import searchBlueIcon from '../../Common/assets/search-blue.svg';

function SearchBar() {
  return (
    <Link className={style.searchbar} to="/search?input=active">
      <img src={searchBlueIcon} alt="search" />
      <input type="text" name="searchbar" placeholder="Describe the expert you need" />
    </Link>
  );
}

export default SearchBar;
