import React from 'react';
import styles from './styles.module.scss';
import { Button } from '../../button';

interface SelectBottomButtonProps {
	onSelect(): void;
	isDisabled?: boolean;
}

export const SelectBottomButton = ({
	onSelect,
	isDisabled,
}: SelectBottomButtonProps): JSX.Element => {
	return (
		<div
			className={styles.selectBottomButton}
			data-testid="ui-native-select-bottom-floating-bar"
		>
			<div
				className={styles.selectBottomButtonBtnContainer}
				data-testid="ui-native-select-bottom-floating-btn-container"
			>
				<Button
					type="button"
					onClick={onSelect}
					variant="primary"
					disabled={isDisabled}
					className={styles.selectBottomButtonBtn}
					data-testid="ui-native-select-bottom-floating-btn"
				>
					SELECT
				</Button>
			</div>
		</div>
	);
};
