/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import styles from './ConsultationTopic.module.scss';

type ConsultationTopicProps = {
	topic: string;
};

export default function ConsultationTopic(props: ConsultationTopicProps): JSX.Element {
	const { topic } = props;

	// @ts-ignore
	const src = require(`../../assets/img/${topic.toLowerCase()}.png`);

	// Category Test link 
	const categoryTestLink = `${topic.toLowerCase().replace(' ', '-')}-test`;

	return (
		<a
			href={categoryTestLink}
			data-testid="quiz-consultation-topic"
			className={styles.topic}
			title={topic}
		>
			<div className={styles.imgContainer}>
				<img
					data-testid="quiz-consultation-topic-img"
					src={src}
					alt={topic}
				/>
			</div>
			<div
				data-testid="quiz-consultation-topic-title"
				className={styles.topicName}
			>
				{topic}
			</div>
		</a>
	);
}
