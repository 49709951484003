import {
  JOIN_SESSION,
  ACTIVE_CHAT,
  ACTIVE_BOOKING,
  DISCONNECT_SESSION,
  BOOKING_HISTORY,
} from '../actions/types';

const initialState = {
  activeSession: {},
  activeChat: [],
  activeBooking: {},
  bookingHistory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case JOIN_SESSION:
      return {
        ...state,
        activeSession: action.payload,
      };
    case ACTIVE_CHAT:
      return {
        ...state,
        activeChat: [...state.activeChat, action.payload],
      };
    case ACTIVE_BOOKING:
      return {
        ...state,
        activeBooking: action.payload,
      };
    case BOOKING_HISTORY:
      return {
        ...state,
        bookingHistory: action.payload,
      };
    case DISCONNECT_SESSION:
      return state;
    default:
      return state;
  }
};
