import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import styles from './MyaccountStep.module.css';
import Headers from '../../Headers/Headers';
import ProfileHead from '../../ViewProfile/ProfileHead/ProfileHead';
import enums from '../../../config/enum';
import { alert } from '../../../utils';
import messages from '../../../config/message';
import globalProptype from '../../../config/proptype';

import {
  updateProfile,
  editProfile,
  uploadUserProfileImage,
  updateBuisnessDetails,
  userBuisnessDetails,
  userOauthData,
} from '../../../actions/userAction';

import { fetchCountryCurrencyDetails } from '../../../actions/masterAction';

import {
  PersonalDetailsNew,
  BusinessDetailsNew,
  CalendarDetailsNew,
} from '../../Myprofile/EditMyProfile/Newonbaording';
import ImageUploadModal from '../../Common/ImageUploadModal/ImageUploadModal';
import AvailabilityOnboard from './AvailabilityOnboard/AvailabilityOnboard';
import ImproveProfile from './ImproveProfile';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '850x',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

Modal.setAppElement('#root');

class MyaccountStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalopen: false,
      required: false,
      activeStep: 0,
      completedStep: 0,
      requiredStep: 0,
      nextStep: 0,
      alertuser: false,
      showAvailabilty: false,
      showFinalStep: false,
    };
  }

  componentDidMount() {
    const {
      user,
      onFetchUserBuisnessDetails,
      onboardingStepsCompleted,
      onUpdateProfile,
      onFetchCountryCurrencyDetails,
    } = this.props;

    onFetchCountryCurrencyDetails();
    onUpdateProfile();

    if (user.isBusinessRegistered) {
      onFetchUserBuisnessDetails();
    }

    if (onboardingStepsCompleted > 0) {
      this.setState({
        completedStep: onboardingStepsCompleted,
        nextStep: onboardingStepsCompleted + 1,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, onboardingStepsCompleted } = this.props;
    const { completedStep, activeStep, showAvailabilty, showFinalStep } = prevState;
    if (onboardingStepsCompleted !== completedStep && onboardingStepsCompleted > completedStep) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        completedStep: onboardingStepsCompleted,
        nextStep: onboardingStepsCompleted + 1,
      });
      this.onAlertUser();
    }

    if (
      user.personalDetailsCompleted &&
      user.isBusinessRegistered &&
      onboardingStepsCompleted === 4 &&
      activeStep !== 5
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeStep: enums.onboardingStep.Availabilty,
      });

      if (!showAvailabilty || !showFinalStep) {
        this.onShowFinal('Availabilty');
      }
    }

    if (
      user.personalDetailsCompleted &&
      user.isBusinessRegistered &&
      onboardingStepsCompleted === 5 &&
      activeStep !== 6
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeStep: enums.onboardingStep.CONGRATS,
      });
    }
  }

  onClose = () => {
    this.setState({ modalopen: false });
  };

  onRequired = (required, step) => {
    this.setState({ required, activeStep: step, requiredStep: step });
  };

  onTooltipClick = (step) => {
    const { required } = this.state;
    if (!required) {
      this.setState({ activeStep: Number(step) });
    }
  };

  onCompleteEditing = () => {
    this.setState({
      activeStep: 0,
    });
  };

  onShowFinal = (type) => {
    if (type === 'Availabilty') {
      this.setState({
        showAvailabilty: true,
        showFinalStep: false,
      });
    }

    if (type === 'Final') {
      this.setState({
        showAvailabilty: false,
        showFinalStep: true,
      });
    }
  };

  onAlertUser = () => {
    this.setState({ alertuser: true });
    setTimeout(() => {
      return this.setState({ alertuser: false });
    }, 820);
  };

  onAlert = () => {
    const { user } = this.props;
    const { completedStep } = this.state;
    if (!user.isEmailVerified) {
      return alert(messages.verifyEmail);
    }

    switch (completedStep) {
      case 0:
        this.onAlertUser();
        return alert(messages.imageUploadWarning);
      case 1:
        this.onAlertUser();
        return alert(messages.personalDetailsIncomplete);
      case 2:
        this.onAlertUser();
        return alert(messages.businessDetailsIncomplete);
      case 3:
        this.onAlertUser();
        return alert(messages.calendarDetailsIncomplete);
      default:
        return false;
    }
  };

  onUploadImage = (e) => {
    e.preventDefault();
    const { user } = this.props;

    ReactGA.pageview('/myprofile/ap/profile_image');
    if (user.isEmailVerified) {
      this.setState({ modalopen: true, activeStep: 1 });
    } else {
      this.onAlert();
    }
  };

  render() {
    const {
      modalopen,
      required,
      requiredStep,
      completedStep,
      activeStep,
      nextStep,
      alertuser,
      showAvailabilty,
      showFinalStep,
    } = this.state;
    const {
      user,
      onProfileImageUpload,
      history,
      buisnessDetails,
      onUpdateUserBuisnessDetails,
      countries,
      countriesMasterdata,
      onSignInclickOauth,
      onUpdateProfile,
      ipCountry,
      ipCurrency,
    } = this.props;
    return (
      <>
        <Headers />
        {modalopen ? (
          <ImageUploadModal
            user={user}
            onProfileImageUpload={onProfileImageUpload}
            onClose={this.onClose}
          />
        ) : null}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ProfileHead onUploadImage={this.onUploadImage} {...user} history={history} />
        <div className={styles.dtit}>
          <p className={styles.db}>MY PUBLIC PROFILE</p>
        </div>
        <div className={styles.container}>
          <h6 className={styles.headtitle}>
            <span className={styles.headtitled}>
              You are a step away from making your profile bookable!
            </span>{' '}
            <br />
            Quickly complete the following to make your profile immediately searchable and bookable
            for your very first paid consultation
          </h6>
          <div className={styles.editaccount}>
            <div className={styles.editaccounta}>
              <div className={styles.leftrow}>
                <div className={styles.details}>
                  <PersonalDetailsNew
                    onRequired={this.onRequired}
                    required={required}
                    onTooltipClick={this.onTooltipClick}
                    onCompleteEditing={this.onCompleteEditing}
                    requiredStep={requiredStep}
                    completedStep={completedStep}
                    onAlert={this.onAlert}
                    open={activeStep === enums.onboardingStep.PersonalDetails}
                    alertuser={nextStep === enums.onboardingStep.PersonalDetails && alertuser}
                    ipCountry={ipCountry}
                    ipCurrency={ipCurrency}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                  />
                </div>
              </div>

              <div className={styles.rightrow}>
                <div className={styles.details}>
                  <BusinessDetailsNew
                    onRequired={this.onRequired}
                    required={required}
                    onTooltipClick={this.onTooltipClick}
                    onCompleteEditing={this.onCompleteEditing}
                    requiredStep={requiredStep}
                    completedStep={completedStep}
                    onAlert={this.onAlert}
                    open={activeStep === enums.onboardingStep.BusinessDetails}
                    alertuser={nextStep === enums.onboardingStep.BusinessDetails && alertuser}
                    user={{
                      firstName: user.firstName,
                      lastName: user.lastName,
                      countryId: user.countryId,
                      country: user.country,
                      isBusinessRegistered: user.isBusinessRegistered,
                    }}
                    buisnessDetails={buisnessDetails}
                    onUpdateUserBuisnessDetails={onUpdateUserBuisnessDetails}
                    countries={countries}
                    countriesMasterdata={countriesMasterdata}
                  />

                  <CalendarDetailsNew
                    requiredStep={requiredStep}
                    open={activeStep === enums.onboardingStep.CalendarSettings}
                    completedStep={completedStep}
                    required={required}
                    onTooltipClick={this.onTooltipClick}
                    onAlert={this.onAlert}
                    onCompleteEditing={this.onCompleteEditing}
                    onSignInclickOauth={onSignInclickOauth}
                    user={{
                      social: user.social,
                      userType: user.userType,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onShowFinal={this.onShowFinal}
                    alertuser={nextStep === enums.onboardingStep.CalendarSettings && alertuser}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showAvailabilty || showFinalStep}
          style={customStyles}
          contentLabel="Availabilty"
        >
          {showAvailabilty && (
            <AvailabilityOnboard onShowFinal={this.onShowFinal} history={history} />
          )}

          {showFinalStep && (
            <ImproveProfile
              user={{
                primaryImg: user.primaryImg,
                userToken: user.userToken,
                firstName: user.firstName,
                lastName: user.lastName,
                headline: user.headline,
                seoImage: user.seoImage,
                username: user.username,
              }}
              history={history}
              onUpdateProfile={onUpdateProfile}
            />
          )}
        </Modal>
      </>
    );
  }
}

MyaccountStep.propTypes = {
  user: PropTypes.shape({
    isBusinessRegistered: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    personalDetailsCompleted: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    countryId: PropTypes.number,
    country: PropTypes.shape({}),
    social: PropTypes.shape({}),
    userType: PropTypes.number,
    primaryImg: PropTypes.string,
    userToken: PropTypes.string,
    headline: PropTypes.string,
    seoImage: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  onboardingStepsCompleted: PropTypes.number.isRequired,
  history: globalProptype.history.isRequired,
  onFetchUserBuisnessDetails: PropTypes.func.isRequired,
  onProfileImageUpload: PropTypes.func.isRequired,
  buisnessDetails: globalProptype.businessDetailsState.isRequired,
  onUpdateUserBuisnessDetails: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSignInclickOauth: PropTypes.func.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  ipCountry: PropTypes.shape({}).isRequired,
  ipCurrency: PropTypes.shape({}).isRequired,
  onFetchCountryCurrencyDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const categoriesMasterdata = state.masterdata.masterData.categories.map((category) => ({
    value: category.categoryId,
    label: category.categoryName,
  }));

  const languagesMasterdata = state.masterdata.masterData.languages.map((language) => ({
    value: language.languageId,
    label: language.language,
  }));

  const currenciesMasterdata = state.masterdata.masterData.currencies.map((currency) => ({
    value: currency.currencyId,
    label: `${currency.currencyCode} (${currency.currencyName})`,
    code: currency.currencyCode,
    symbol: currency.currencySymbol,
  }));

  const skillsMasterdata = state.masterdata.masterData.skills.map((skill) => ({
    value: skill.skillId,
    label: skill.skillName,
  }));

  const countriesMasterdata = state.masterdata.masterData.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
    code: country.countryCode,
    currencyCode: country.currencyCode,
  }));

  return {
    user: state.userState.user,
    ipCurrency: state.userState.currency,
    ipCountry: state.userState.country,
    request: state.request,
    categories: state.masterdata.masterData.categories || [],
    currencies: state.masterdata.masterData.currencies,
    countries: state.masterdata.masterData.countries,
    skills: state.masterdata.masterData.skills,
    languages: state.masterdata.masterData.languages,
    accountCompleted: state.userState.accountCompleted,
    onboardingStepsCompleted: state.userState.onboardingStepsCompleted,
    buisnessDetails: state.userState.buisnessDetails,
    categoriesMasterdata,
    languagesMasterdata,
    currenciesMasterdata,
    skillsMasterdata,
    countriesMasterdata,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
      onEditProfile: editProfile,
      onProfileImageUpload: uploadUserProfileImage,
      onUpdateUserBuisnessDetails: updateBuisnessDetails,
      onFetchUserBuisnessDetails: userBuisnessDetails,
      onSignInclickOauth: userOauthData,
      onFetchCountryCurrencyDetails: fetchCountryCurrencyDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(MyaccountStep);
