import React from 'react';
import Headers from '../Headers/Headers';
import BookingSessionDetails from './BookingSessionDetails';
import PaymentCard from './PaymentCard/PaymentCard';
import styles from './PaymentPage.module.css';
import { Track } from '@conrati/tracking';
import PaymentFAQs from './PaymentFAQs/PaymentFAQs';

// Booking session details state object
const bookingSessionDetailsState = {
  offerDescription: '',
  bookingDescription: '',
  videoCallLength: '',
  amount: '',
  amountText: '',
  mangopayId: '',
  currencyCode: '',
  currencySymbol: '',
  variant: '',
  userId: '',
  firstName: '',
  isFreeSession: false,
};

// Events for tracking
const events = {
  'payment/experiment/payment-page-view': 'payment/experiment/payment-page-view',
};

function PaymentPage() {
  const [mobileBtnText, setMobileBtnText] = React.useState('CONFIRM');
  const [bookingSessionDetails, setBookingSessionDetails] = React.useState(
    bookingSessionDetailsState
  );

  const fetchSessionDetailsFromUrl = () => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Get the query parameters from the URL
    const queryParams = url.searchParams;

    // Get the first parameter name
    const sessionDetailsEncrypted = queryParams.keys().next().value;

    // Decrypt base64 encoded string
    const sessionDetailsDecrypted = window.atob(sessionDetailsEncrypted);

    //Get data from url params
    const sessionDetailsUrl = new URLSearchParams(sessionDetailsDecrypted);

    const variant = sessionDetailsUrl.get('variant');

    console.log('Variant:', variant);

    const makeBookingSessionDetails = {
      offerDescription: sessionDetailsUrl.get('offer'),
      bookingDescription: sessionDetailsUrl.get('description'),
      videoCallLength: sessionDetailsUrl.get('call_length'),
      amount: sessionDetailsUrl.get('amount'),
      amountText: sessionDetailsUrl.get('amount_text'),
      mangopayId: sessionDetailsUrl.get('mangopayId'),
      currencyCode: sessionDetailsUrl.get('currencyCode'),
      currencySymbol: sessionDetailsUrl.get('currencySymbol'),
      variant,
      userId: sessionDetailsUrl.get('userId'),
      firstName: sessionDetailsUrl.get('firstName'),
      isFreeSession: ['o', 't', 's', 'x'].includes(variant),
    };

    setBookingSessionDetails(makeBookingSessionDetails);
  };

  React.useEffect(() => {
    // Page view tracker
    Track(events['payment/experiment/payment-page-view'], { ...bookingSessionDetails });

    fetchSessionDetailsFromUrl();
  }, []);

  function switchPage() {
    setMobileBtnText('PAY');
  }

  return (
    <div>
      <Headers experiment={true} />
      <main className={styles.mainContainer}>
        <div className={styles.paymentFormContainer}>
          <h1>
            GREAT{bookingSessionDetails.firstName ? ` ${bookingSessionDetails.firstName}` : ''}! 
            Please Add Your Card Details {bookingSessionDetails.isFreeSession ? '(Incase Of A No-Show)' : ''}
          </h1>
          <div className={styles.formColumns}>
            <div className={styles.sections}>
                <div
                className={styles.bookingDetails}
                style={{
                    display: mobileBtnText !== 'PAY' ? 'block' : 'none',
                }}
                >
                    <div className={styles.bookingDetailsTitle}>THERAPY OFFER DETAILS</div>

                    <BookingSessionDetails
                        offerDescription={bookingSessionDetails.offerDescription}
                        bookingDescription={bookingSessionDetails.bookingDescription}
                        videoCallLength={bookingSessionDetails.videoCallLength}
                        amountText={bookingSessionDetails.amountText}
                        amount={bookingSessionDetails.amount}
                        mangopayId={bookingSessionDetails.mangopayId}
                        currencyCode={bookingSessionDetails.currencyCode}
                        currencySymbol={bookingSessionDetails.currencySymbol}
                        variant={bookingSessionDetails.variant}
                    />
                    
                </div>
                <div
                className={styles.cardDetails}
                style={{
                    display: mobileBtnText === 'PAY' ? 'block' : 'none',
                }}
                >
                    <PaymentCard
                        mangopayId={bookingSessionDetails.mangopayId}
                        currencyCode={bookingSessionDetails.currencyCode}
                        currencySymbol={bookingSessionDetails.currencySymbol}
                        variant={bookingSessionDetails.variant}
                        userId={bookingSessionDetails.userId}
                        isFreeSession={bookingSessionDetails.isFreeSession}
                    />
                </div>
                <div
                className={`${styles.actionBtnContainer} ${
                    mobileBtnText === 'PAY' ? styles.hideActionButton : ''
                    }`}
                >
                    <button onClick={() => switchPage()} className={styles.actionButton}>
                        {mobileBtnText}
                    </button>
                </div>
                <PaymentFAQs variant={bookingSessionDetails.variant} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PaymentPage;
