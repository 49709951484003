import React from 'react';
import styles from './Chat.module.css';
import moment from 'moment';
const recieved = (props) => {
  let initails = props.from.split(' ');
  return (
    <div className={styles.recieved}>
      <div className={styles.minfo}>
        <p className={styles.mtime}>{moment.unix(props.time).format('HH:mm')}</p>
        <p className={styles.mname}>{initails[0]}</p>
        <div className={styles.usl}>{initails[0].charAt(0) + initails[1].charAt(0)}</div>
      </div>
      <div className={styles.message}>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default recieved;
