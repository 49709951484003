/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import image1 from './images/image1.jpeg';
import image2 from './images/image2.jpeg';
import image3 from './images/image3.jpeg';
import image4 from './images/image4.jpeg';
import image5 from './images/image5.jpeg';
import Card from './Card';

const mockData = {
  userId: '686',
  email: 'joe2gregor@gmail.com',
  userType: 1,
  loginType: 2,
  personalDetailsCompleted: true,
  firstName: 'Joe',
  lastName: 'Mcgregor',
  mobileNumber: '14370969',
  countryId: 1107,
  timeZone: '+02:00',
  address: '',
  postalCode: '',
  city: null,
  calendarType: 0,
  currencyId: 3,
  dateOfBirth: '1985/10/10',
  personalTaxNo: '2163457TW',
  isBusinessRegistered: true,
  isKycRegistered: true,
  isCardRegistered: false,
  isBankDetailsRegistered: true,
  username: 'joemcgregor',
  isMarketting: true,
  isKycVerified: false,
  isAccountExtended: false,
  mobileCode: '+353',
  registrationStepsCompleted: 5,
  onboardedDate: '2019-08-28T06:37:08.447Z',
  seoImage:
    'https://conratidev.ams3.cdn.digitaloceanspaces.com/seo/conrati_user_seo_1568291145451.png',
  availability: {
    userId: 686,
    weekdayFrom: '1564117203',
    weekdayTo: '1564171203',
    weekendFrom: '1564117203',
    weekendTo: '1564171203',
    isWeekdayOn: true,
    isWeekendOn: true,
  },
  buisnessDetails: {
    businessId: 225,
    businessName: 'Global Coach and Trainer',
    businessRegistrationNo: '',
    typeOfBusiness: 0,
    vatNo: '',
    userId: 686,
    address: 'Graigue Killeigh Tullamore',
    city: 'Tullamore',
    region: '',
    countryId: 1107,
    postalCode: '38393',
    countryCode: 'IE',
  },
  languages: [
    {
      code: 'en',
      language: 'English',
      createdAt: '2019-05-07T06:40:35.069Z',
      searchKey: 'english',
      updatedAt: '2019-05-07T06:40:35.069Z',
      languageId: 224,
    },
    {
      code: 'fr',
      language: 'French',
      createdAt: '2019-05-07T06:40:36.793Z',
      searchKey: 'french',
      updatedAt: '2019-05-07T06:40:36.793Z',
      languageId: 234,
    },
  ],
  hourlyRate: '49',
  serviceDescription:
    'I specialise in the provision of premium mentoring for senior executives by offering the highest level of professional coaching which enhances senior managers capabilities through immediate, expert and independent advice. I also assist many young entrepreneurs who are struggling to break through the hard times and reach the tipping point of success.  ',
  videoBio: 'https://youtu.be/fdlZJyR8Xks',
  certification: [
    {
      to: 'May 2019',
      from: 'January 2019',
      name: 'Executive Mentor Certification (EM)',
      about: 'Mangement Mentors Certification',
    },
  ],
  education: [
    {
      to: 'December 2018',
      from: 'January 2017',
      field: 'Master of Business Administration (MBA)',
      university: 'University of Edinburgh',
    },
    {
      to: 'June 2016',
      from: 'January 2015',
      field: 'Masters in Organizational Psychology ',
      university: 'University of London',
    },
    {
      to: 'December 2014',
      from: 'January 2013',
      field: 'Management MSc',
      university: 'University of Edinburgh',
    },
  ],
  experience: [
    {
      to: 'August 2019',
      from: 'January 2018',
      title: 'Global Coach and Trainer',
      company: 'Mentorship Matters',
    },
    {
      to: 'December 2017',
      from: 'January 2016',
      title: 'Founder & CEO',
      company: 'Health Cube',
    },
    {
      to: 'December 2015',
      from: 'July 2014',
      title: 'Country Manager',
      company: 'CT Aviation',
    },
    {
      to: 'June 2014',
      from: 'February 2011',
      title: 'Senior Business Consultant',
      company: 'STEP boutique consultancy',
    },
  ],
  skills: [
    'leadership',
    'public speaking',
    'mentorship',
    'coaching',
    'professional development',
    'career advisor',
  ],
  topics: [
    {
      title: 'Time management',
      topicDescription:
        'Good time management enables an individual to complete more in a shorter period of time, lowers stress, and leads to career success.',
    },
    {
      title: 'How can I become better at managing people?',
      topicDescription:
        'Managing people is no easy task. But no matter what business you’re in, you’re going to be working with people, and effectively managing them is critical to the success of your business.',
    },
    {
      title: 'How to maintain a balanced life at an executive level?  collapse',
      topicDescription:
        'What does it mean to have a balanced life? Balance comes in physical forms, emotional forms, and a spiritual form. Being in balance may mean something different to each individual but essentially achieving balance is grounded on the same fundamentals.',
    },
    {
      title: 'Getting the most out of your current position',
      topicDescription:
        'I assist with tips and strategies on how to get the most out of your new job.',
    },
  ],
  headline: 'Business Strategist & Coach',
  categories: [
    {
      createdAt: '2019-05-07T06:34:20.486Z',
      searchKey: 'management',
      updatedAt: '2019-05-07T06:34:20.486Z',
      categoryId: 51,
      categoryName: 'Management',
    },
    {
      createdAt: '2019-05-07T06:34:20.488Z',
      searchKey: 'mentorship',
      updatedAt: '2019-05-07T06:34:20.488Z',
      categoryId: 63,
      categoryName: 'Mentorship',
    },
    {
      createdAt: '2019-05-07T06:34:20.488Z',
      searchKey: 'other',
      updatedAt: '2019-05-07T06:34:20.488Z',
      categoryId: 64,
      categoryName: 'Other',
    },
  ],
  currency: {
    currencyId: 3,
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
  },
  country: {
    countryId: 1107,
    countryCode: 'IE',
    countryName: 'Ireland',
  },
};

const selectRandomImages = () => {
  const number = Math.floor(Math.random() * (5 - 1 + 1) + 1);

  switch (number) {
    case 1:
      return image1;
    case 2:
      return image2;
    case 3:
      return image3;
    case 4:
      return image4;
    case 5:
      return image5;
    default:
      return image1;
  }
};

const randomCards = (numberOfPlaceholder) => {
  const mockCards = [];
  for (let i = 1; i <= numberOfPlaceholder; i += 1) {
    mockCards.push(<Card key={i} {...mockData} primaryImg={selectRandomImages()} />);
  }
  return mockCards;
};

const Placeholder = (props) => {
  const { numberOfPlaceholder } = props;
  return randomCards(numberOfPlaceholder);
};

Placeholder.propTypes = {
  numberOfPlaceholder: PropTypes.number.isRequired,
};

export default Placeholder;
