import { PaymentCardInterface } from './payment-cards';

export const PAYMENT_MOCK_CARDS: PaymentCardInterface[] = [
	{
		nameOnCard: 'Rob Muelu',
		active: false,
		alias: '497010XXXXXX1119',
		bankCode: 'unknown',
		cardProvider: 'VISA',
		cardType: 'CB_VISA_MASTERCARD',
		country: 'FRA',
		creationDate: 1682338848,
		currency: 'EUR',
		expirationDate: '1039',
		fingerprint: '36c74d2e60ba474eab6d6f6d46a642b0',
		id: '172144608',
		product: 'I',
		tag: null,
		userId: '172119893',
		validity: 'INVALID',
	},
	{
		nameOnCard: 'Woza Unknown',
		active: false,
		alias: '497010XXXXXX2349',
		bankCode: 'unknown',
		cardProvider: 'VISA',
		cardType: 'CB_VISA_MASTERCARD',
		country: 'FRA',
		creationDate: 1682338851,
		currency: 'ZAR',
		expirationDate: '1039',
		fingerprint: '36c74d2e60ba474eab6d6f6d46a642b0',
		id: '172144613',
		product: 'I',
		tag: null,
		userId: '172119893',
		validity: 'INVALID',
	},
	{
		nameOnCard: 'Snow Fish',
		active: true,
		alias: '375989XXXXX3419',
		bankCode: 'unknown',
		cardProvider: 'AMEX',
		cardType: 'CB_VISA_MASTERCARD',
		country: 'unknown',
		creationDate: 1689881695,
		currency: 'ZAR',
		expirationDate: '1224',
		fingerprint: '94c478f42735441fb4d1356ef18989bf',
		id: '198232575',
		product: 'unknown',
		tag: null,
		userId: '172119893',
		validity: 'UNKNOWN',
	},
];
