import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './EditInput.module.css';

class EditNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      required: false,
      erromsg: '',
      error: false,
    };
    this.showLabel = this.showLabel.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { value, required, error, errormsg, type } = this.props;
    if (!value && required) {
      this.setState({ required: true });
    }

    if (error) {
      this.setState({
        error: true,
        erromsg: errormsg,
      });
    }

    if (type && type === 'number') {
      if (value === 0) {
        this.setState({ required: true });
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.error && newprops.errormsg) {
      this.setState({
        error: true,
        erromsg: newprops.errormsg,
      });
    } else {
      this.setState({
        error: false,
      });
    }

    if (newprops.required && !newprops.value.trim()) {
      this.setState({
        required: true,
      });
    } else {
      this.setState({
        required: false,
      });
    }
  }

  onChange(e) {
    const { required, onChange } = this.props;

    if (required && e.target.value.trim().length === 0) {
      this.setState({ required: true });
    } else {
      this.setState({ required: false });
    }

    e.target.value = e.target.value.trim() ? e.target.value.replace(/\s+/g, ' ') : '';

    onChange(e);
  }

  showLabel() {
    this.setState({
      focused: true,
    });
  }

  hideLabel(e) {
    this.setState({
      focused: false,
    });

    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  }

  render() {
    const {
      classes,
      style,
      htmlFor,
      label,
      value,
      placeholder,
      error,
      refInput,
      readOnly,
      name,
      type,
      ...rest
    } = this.props;

    const stylescss = cx(styles.input, this.state.required ? styles.inputField : '');
    const stylescss_focused = cx(
      styles.input,
      styles.inputf,
      this.state.required ? styles.inputField : ''
    );

    return (
      <div className={styles.inputel}>
        <label htmlFor={htmlFor} className={styles.label}>
          {this.props.label}
        </label>
        <input
          {...rest}
          ref={refInput}
          type={type || 'text'}
          className={this.state.focused ? stylescss_focused : stylescss}
          placeholder={placeholder}
          value={value}
          name={name}
          onFocus={(e) => this.showLabel(e, label)}
          onBlur={(e) => this.hideLabel(e, label)}
          onChange={(e) => this.onChange(e)}
        />
        {this.state.required && <p className={styles.error}>{label} Required</p>}
        {this.state.error && <p className={styles.error}>{this.state.erromsg}</p>}
      </div>
    );
  }
}

EditNumber.propTypes = {
  placeholder: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  maxlimit: PropTypes.number,
  required: PropTypes.bool,
  errormsg: PropTypes.string,
  type: PropTypes.string,
};

EditNumber.defaultProps = {
  value: '',
  error: false,
  name: '',
  maxlimit: 255,
  required: false,
  errormsg: '',
  type: 'string',
};

export default EditNumber;
