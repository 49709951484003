/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EU from './Eub';
import Other from './Other';
import Gb from './Gb';

function BankDetails(props) {
  const { userCountry } = props;
  const { countryCode } = userCountry;

  if (countryCode === 'ZA') {
    return <Other {...props} />;
  }

  if (countryCode === 'GB') {
    return <Gb {...props} />;
  }

  return <EU {...props} />;
}

BankDetails.propTypes = {
  userCountry: PropTypes.shape({
    countryCode: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    userCountry: state.userState.user.country,
  };
};

export default connect(mapStateToProps)(BankDetails);
