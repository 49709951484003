import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const Session = () => {
  return (
    <div>
      <p className={styles.ftitle}>Sessions</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is it possible to cancel an already booked consultation?"
          content={`
                    Yes, both an Advice Provider and Advice Receiver can cancel an already booked consultation. If the consultation is cancelled before the consultation starts, then there are no penalties to either party. 
                `}
          expand={true}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is it possible to reschedule an already booked consultation?"
          content={`
                    If an already booked consultation needs to be rescheduled, you need to cancel the existing consultation first and book a new consultation
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What if something happens and the Advice Receiver cannot attend the scheduled online consultation?"
          content={`
                    If an Advice Receiver cannot attend the scheduled online consultation, he needs to cancel the consultation before it starts. If the consultation is not cancelled, and the Advice Receiver does not attend the scheduled consultation, the Advice Receiver will be charged the full amount of the time booked and the Advice Receiver will be paid.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What if something happens and the Advice Provider cannot attend the scheduled consultation?"
          content={`
                    If the Advice Provider cannot attend the scheduled consultation, he should cancel the consultation before it starts. However, if he does not show up during the first 10 minutes of the consultation, the system will record an ‘AP no show’ and the Advice Receiver will not pay for the consultation.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are the rules for a no-show?"
          content={`
                    Both parties have a 10 minute grace period to join the consultation. If the consultation is not joined by both parties within the first 10 minutes, the system will record a no-show for the relevant party/ies. 

                    In case the Advice Receiver does not show up - he will still pay for the consultation.
                    In case the Advice Provider does not show up - the Advice Receiver will not pay for the consultation and the Advice Provider will not get any money for the consultation.                     
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Are the consultations recorded?"
          content={`
                    At this point in time the system does not record consultations, but this functionality will be available shortly.                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What if the client needs an NDA before disclosing information during a consultation?"
          content={`
                    If an Advice Receiver requires a Non-disclosure-agreement to be signed by an Advice Provider before disclosing sensitive information during a consultation - he may upload an NDA into the session, to be downloaded, printed, signed and uploaded by the Advice Provider.             
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Can consultations be extended/prolonged? "
          content={`
                    Not yet - but this functionality may be available in the next release.             
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Are Advice Providers rated after a consultation? "
          content={`
                    No not yet - we are planning to make feedback and ratings of consultations part of the software in the near future         
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What if there is a technical issue and the consultation cannot continue? "
          content={`
                    If there is a technical issue and the consultation cannot continue, it is recorded as a system failure and the Advice Receiver will not be charged for the consultation, and the Advice Provider will not be paid for the consultation - regardless of how far into the meeting the consultation has progressed. 
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Is it possible to schedule a free consultation?"
          content={`
          Yes, each Advice Receiver is entitled to one free consultation. Please note that only the Advice Providers who have a free icon on their profile offer a free consultation.
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Can an Advice Receiver book an Advice Provider immediately?"
          content={`
                    No, an Advice Receiver can book an Advice Provider only after 24 hours from the current time.   
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Does an Advice Provider need to confirm the booking?"
          content={`
          Yes, an Advice Provider has to confirm the booking, within 23 hours of the Advice Receiver making a booking. If the Advice Provider fails to confirm the booking, the booking shall stand cancelled.    ̰  
                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default Session;
