import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TimePicker from 'rc-time-picker';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Availability.module.css';
import 'rc-time-picker/assets/index.css';
import { updateProfile } from '../../actions/userAction';
import LargeWarning from '../Common/LargeWarning/LargeWarning';
import calendarico from '../Common/assets/calendarico.svg';
import sessionnew from '../Common/assets/mycalendar.svg';
import EditTooltip from '../Common/EditTooltip/EditTooltip';
import EditRadio from '../Common/EditRadio/EditRadio';
import { alert, timeToNumber } from '../../utils';
import Headers from '../Headers/Headers';
import { updateAvailabilty } from '../../services/userService';
import globalProptype from '../../config/proptype';
import messages from '../../config/message';

const format = 'h:mm a';

class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekdayFrom: '',
      weekdayTo: '',
      weekendFrom: '',
      weekendTo: '',
      isWeekdayOn: false,
      isWeekendOn: false,
      edit: false,
      selectactive: false,
      required: false,
    };
  }

  componentDidMount() {
    const {
      stepsCompleted,
      history,
      // onUpdateProfile,
    } = this.props;
    if (!stepsCompleted) {
      alert(messages.apIncompleteAccunt);
      return history.replace('/myprofile/edit/step');
    }

    // onUpdateProfile();
    return this.onCheckNewAvailabilty();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onCheckNewAvailabilty = () => {
    const { user } = this.props;
    let {
      weekdayFrom,
      weekdayTo,
      weekendFrom,
      weekendTo,
      weekdayFromString,
      weekdayToString,
      weekendFromString,
      weekendToString,
    } = user.availability;

    const { isWeekdayOn, isWeekendOn } = user.availability;

    if (isWeekdayOn) {
      const [hourFrom, minuteFrom] = weekdayFromString.split(':');
      const [hourTo, minuteTo] = weekdayToString.split(':');

      weekdayFrom = moment().set({
        hour: Number(hourFrom),
        minute: Number(minuteFrom),
        second: 0,
      });

      weekdayTo = moment().set({
        hour: Number(hourTo),
        minute: Number(minuteTo),
        second: 0,
      });
    }

    if (isWeekendOn) {
      const [hourFrom, minuteFrom] = weekendFromString.split(':');
      const [hourTo, minuteTo] = weekendToString.split(':');

      weekendFrom = moment().set({
        hour: Number(hourFrom),
        minute: Number(minuteFrom),
        second: 0,
      });
      weekendTo = moment().set({
        hour: Number(hourTo),
        minute: Number(minuteTo),
        second: 0,
      });
    }

    if (isWeekdayOn) {
      this.setState({
        weekdayFrom,
        weekdayTo,
        isWeekdayOn: true,
      });
    }

    if (isWeekendOn) {
      this.setState({
        weekendFrom,
        weekendTo,
        isWeekendOn: true,
      });
    }
  };

  onChange = (value, type) => {
    const { weekdayTo, weekendTo } = this.state;

    if (type === 'weekdayFrom') {
      const weekdayFromTime = timeToNumber(value);
      const weekdayToTime = timeToNumber(weekdayTo);

      if (weekdayToTime > 23.5 || weekdayToTime < weekdayFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekdayFrom: value, required: false });
    }
    if (type === 'weekdayTo') {
      const { weekdayFrom } = this.state;
      const weekdayToTime = timeToNumber(value);
      const weekdayFromTime = timeToNumber(weekdayFrom);

      if (weekdayToTime === 0) {
        alert(messages.availabilityTimeInvalid);
        return this.setState({ weekdayTo: value.subtract(30, 'm'), required: false });
      }

      if (weekdayToTime > 23.5 || weekdayToTime < weekdayFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekdayTo: value, required: false });
    }
    if (type === 'weekendFrom') {
      const weekendFromTime = timeToNumber(value);
      const weekendToTime = timeToNumber(weekendTo);

      if (weekendToTime > 23.5 || weekendToTime < weekendFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekendFrom: value, required: false });
    }
    if (type === 'weekendTo') {
      const { weekdayFrom } = this.state;
      const weekendToTime = timeToNumber(value);
      const weekendFromTime = timeToNumber(weekdayFrom);

      if (weekendToTime === 0) {
        alert(messages.availabilityTimeInvalid);
        return this.setState({ weekendTo: value.subtract(30, 'm'), required: false });
      }

      if (weekendToTime > 23.5 || weekendToTime < weekendFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekendTo: value, required: false });
    }

    return true;
  };

  onCheck = (value, type) => {
    if (type === 1) {
      this.setState({ isWeekdayOn: value, weekdayFrom: null, weekdayTo: null });
    }

    if (type === 2) {
      this.setState({ isWeekendOn: value, weekendFrom: null, weekendTo: null });
    }
  };

  onSelActive = (status, type) => {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  };

  onTooltipClick = () => {
    const { edit } = this.state;
    if (!edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    return this.setState({ edit: !edit });
  };

  handleOutsideClick = (e) => {
    const { selectactive, required } = this.state;
    if (selectactive) {
      return;
    }

    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onSubmitRecuring();

    if (required) {
      alert('You are required to maintain atleast one availabilty');
    }

    this.onTooltipClick();
  };

  onSubmitRecuring = async () => {
    let { weekdayFrom, weekdayTo, weekendFrom, weekendTo } = this.state;
    const { isWeekdayOn, isWeekendOn } = this.state;
    const { onUpdateProfile, user } = this.props;

    weekdayFrom = moment(weekdayFrom).unix();
    weekdayTo = moment(weekdayTo).unix();
    weekendFrom = moment(weekendFrom).unix();
    weekendTo = moment(weekendTo).unix();

    let data = {};

    if (isWeekdayOn && isWeekendOn) {
      if (weekdayFrom && weekdayTo && weekendFrom && weekendTo) {
        data = {
          weekday_from: weekdayFrom,
          weekday_to: weekdayTo,
          weekend_from: weekendFrom,
          weekend_to: weekendTo,
        };
      }
    }

    if (isWeekdayOn && !isWeekendOn) {
      if (weekdayFrom && weekdayTo) {
        data = {
          weekday_from: weekdayFrom,
          weekday_to: weekdayTo,
        };
      } else {
        return this.setState({
          required: true,
        });
      }
    }

    if (isWeekendOn && !isWeekdayOn) {
      if (weekendFrom && weekendTo) {
        data = {
          weekend_from: weekendFrom,
          weekend_to: weekendTo,
        };
      } else {
        return this.setState({
          required: true,
        });
      }
    }

    if (!isWeekendOn && !isWeekdayOn) {
      return this.setState({
        required: true,
      });
    }

    data.timeZone = user.timeZone;

    updateAvailabilty(data).then((result) => {
      if (result.success) {
        onUpdateProfile();
      }
    });

    return true;
  };

  disabledHours = (from) => {
    if (from) {
      const currentTime = from.format('H');
      const arr = [];

      for (let i = 0; i < currentTime; i += 1) {
        arr.push(i);
      }

      return arr;
    }

    return [];
  };

  disabledMinutes = (h, from) => {
    if (from) {
      const currentTime = Number(from.format('H'));

      if (h === currentTime) {
        const arr = [];
        for (let i = 0; i < 30; i += 1) {
          arr.push(i);
        }

        return arr;
      }
      return [];
    }

    return [];
  };

  handleViewMyCalendarClick = () => {
    const { edit } = this.state;
    const { history } = this.props;
    if (edit) {
      this.onSubmitRecuring();
    }
    return history.push('/mycalendar');
  };

  render() {
    const { user } = this.props;

    const { weekdayFrom, weekdayTo, weekendFrom, weekendTo, isWeekdayOn, isWeekendOn, edit } =
      this.state;

    return (
      <div>
        <Headers />
        <div className={styles.head}>
          <p className={styles.ptime}>{moment().format('hh:mm dddd')}</p>
          <p className={styles.pdate}>{moment().format('DD MMMM YYYY')}</p>
        </div>
        <div className={styles.dtit}>
          <p className={styles.db}>
            {user.hourlyRate ? `AVAILABILTY - € ${user.hourlyRate} P/H` : 'AVAILABILTY'}
          </p>
        </div>
        <div className={styles.availabiltybody}>
          <LargeWarning
            hideclose
            icon={sessionnew}
            title="Make yourself bookable by adding availability!"
            body="Please set your general availability below. Conrati automatically removes availability when you have events in your synchronised calendar. The more availability you set, the greater the chance of bookings."
            link="/help/faq?section=calendar"
            linktext="For more information, read our FAQ’s on availability"
          />

          <div className={styles.calendarWeekend}>
            <div
              className={edit ? cx(styles.weekend, styles.cardborder) : styles.weekend}
              ref={(editelem) => {
                this.editelem = editelem;
              }}
            >
              <div className={styles.tooltip}>
                <EditTooltip onClick={this.onTooltipClick} active={edit} />
              </div>
              {!edit && (
                <div className={styles.weekflex}>
                  <div className={styles.calinfo}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={calendarico} alt="My calendar" />
                      <p className={styles.wphead}>General Availability</p>
                    </div>

                    <p className={styles.wpheadr}>YOUR TIME ZONE - {user.timeZone}</p>
                  </div>
                  <div className={styles.weekflexe}>
                    <div className={styles.lbox}>
                      <div className={styles.scheck}>
                        <p className={styles.wtit}>Weekdays (Mon - Fri)</p>
                      </div>
                      <p className={styles.wsub}>Available From</p>
                      <div className={styles.fromt}>
                        <p>{isWeekdayOn ? weekdayFrom.format('hh:mm a') : 'NOT SET'}</p>
                        <p className={styles.to}>TO</p>
                        <p>{weekdayTo ? weekdayTo.format('hh:mm a') : 'NOT SET'}</p>
                      </div>
                    </div>
                    <div className={styles.rbox}>
                      <div className={styles.scheck}>
                        <p className={styles.wtit}>Weekends (Sat - Sun)</p>
                      </div>
                      <p className={styles.wsub}>Available From</p>
                      <div className={styles.fromt}>
                        <p>{weekendFrom ? weekendFrom.format('hh:mm a') : 'NOT SET'}</p>
                        <p className={styles.to}>TO</p>
                        <p>{weekendTo ? weekendTo.format('hh:mm a') : 'NOT SET'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {edit && (
                <div className={styles.weekflex}>
                  <div className={styles.calinfo}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={calendarico} alt="My calendar" />
                      <p className={styles.wphead}>General Availability</p>
                    </div>
                  </div>
                  <div className={styles.weekflexe}>
                    <div className={styles.lbox}>
                      <div className={styles.scheck}>
                        <p className={styles.wtit}>Weekdays (Mon - Fri) </p>
                        <div className={styles.tbtn}>
                          <EditRadio
                            tooltipmsg={isWeekdayOn ? 'Disable Weekdays' : 'Enable Weekdays'}
                            label=""
                            onCheck={(e) => this.onCheck(e, 1)}
                            checked={!!isWeekdayOn}
                          />
                          <p className={styles.etext}>{isWeekdayOn ? 'Enabled' : 'Disabled'}</p>
                        </div>
                      </div>
                      <p className={styles.wsub}>Available From</p>
                      <div className={styles.from}>
                        <TimePicker
                          showSecond={false}
                          disabled={!isWeekdayOn}
                          defaultValue={weekdayFrom || moment().startOf('date')}
                          value={weekdayFrom || null}
                          className="xxx"
                          onChange={(e) => this.onChange(e, 'weekdayFrom')}
                          format={format}
                          use12Hours
                          inputReadOnly
                          onOpen={(e) => this.onSelActive(e, 1)}
                          onClose={(e) => this.onSelActive(e, 2)}
                          minuteStep={30}
                        />

                        <p className={styles.to}>TO</p>

                        <TimePicker
                          showSecond={false}
                          disabled={!(isWeekdayOn || weekdayFrom)}
                          defaultValue={weekdayTo || moment().startOf('date')}
                          value={weekdayTo || null}
                          className="xxx"
                          onChange={(e) => this.onChange(e, 'weekdayTo')}
                          format={format}
                          use12Hours
                          inputReadOnly
                          onOpen={(e) => this.onSelActive(e, 1)}
                          onClose={(e) => this.onSelActive(e, 2)}
                          disabledHours={() => this.disabledHours(weekdayFrom)}
                          disabledMinutes={(e) => this.disabledMinutes(e, weekdayFrom)}
                          minuteStep={30}
                        />
                      </div>
                    </div>
                    <div className={styles.rbox}>
                      <div className={styles.scheck}>
                        <p className={styles.wtit}>Weekends (Sat - Sun) </p>
                        <div className={styles.tbtn}>
                          <EditRadio
                            tooltipmsg={isWeekendOn ? 'Disable Weekend' : 'Enable Weekend'}
                            label=""
                            onCheck={(e) => this.onCheck(e, 2)}
                            checked={!!isWeekendOn}
                          />
                          <p className={styles.etext}>{isWeekendOn ? 'Enabled' : 'Disabled'}</p>
                        </div>
                      </div>
                      <p className={styles.wsub}>Available From</p>
                      <div className={styles.from}>
                        <TimePicker
                          showSecond={false}
                          disabled={!isWeekendOn}
                          defaultValue={weekendFrom || moment().startOf('date')}
                          value={weekendFrom || null}
                          className="xxx"
                          onChange={(e) => this.onChange(e, 'weekendFrom')}
                          format={format}
                          use12Hours
                          inputReadOnly
                          onOpen={(e) => this.onSelActive(e, 1)}
                          onClose={(e) => this.onSelActive(e, 2)}
                          minuteStep={30}
                        />

                        <p className={styles.to}>TO</p>

                        <TimePicker
                          showSecond={false}
                          disabled={!(isWeekendOn || weekendFrom)}
                          defaultValue={weekendTo || moment().startOf('date')}
                          value={weekendTo || null}
                          className="xxx"
                          onChange={(e) => this.onChange(e, 'weekendTo')}
                          format={format}
                          use12Hours
                          inputReadOnly
                          onOpen={(e) => this.onSelActive(e, 1)}
                          onClose={(e) => this.onSelActive(e, 2)}
                          disabledHours={() => this.disabledHours(weekendFrom)}
                          disabledMinutes={(e) => this.disabledMinutes(e, weekendFrom)}
                          minuteStep={30}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.acbtn}>
            <button
              type="button"
              onClick={() => {
                this.handleViewMyCalendarClick();
              }}
              disabled={!(isWeekdayOn || isWeekendOn)}
              className={styles.vcalbtn}
            >
              VIEW MY CALENDAR
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Availability.propTypes = {
  stepsCompleted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    hourlyRate: PropTypes.string,
    timeZone: PropTypes.string,
    availability: PropTypes.object,
  }).isRequired,
  history: globalProptype.history.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles.profile,
    user: state.userState.user,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Availability);
