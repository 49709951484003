import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.css';
import globalProptypes from '../../../config/proptype';

function BookingDetails(props) {
  const { bookingDetails } = props;
  const { apUserData, start, end, isFreeSession } = bookingDetails;

  const { firstName, lastName, headline, primaryImg, hourlyRateWithSymbol } = apUserData;

  return (
    <div className={styles.bookingDetails}>
      {isFreeSession ? (
        <div className={styles.sectionOneFree}>
          <p className={styles.title}>Register or sign in to complete your free booking</p>
        </div>
      ) : (
        <div className={styles.sectionOne}>
          <p className={styles.title}>Register or sign in to complete your booking</p>
        </div>
      )}
      <div className={styles.sectionTwo}>
        <div className={styles.group1}>
          <div className={styles.pdetails}>
            <img src={primaryImg} alt="User Profile Pic" />
            <div className={styles.userInfo1}>
              <p className={styles.name}>
                {firstName} {lastName}
              </p>
              <p className={styles.headline}>{headline}</p>
            </div>
            <div className={styles.userInfo2}>
              <p className={styles.name}>Hourly Rate</p>
              <p className={styles.headlinea}>{hourlyRateWithSymbol}</p>
            </div>
          </div>
        </div>
        <div className={styles.group2}>
          <div className={styles.userInfoa}>
            <p className={styles.name}>Session Date</p>
            <p className={styles.headlinea}>{moment(start).format('DD MMM YYYY')}</p>
          </div>
        </div>
        <div className={styles.group3}>
          <div className={styles.userInfoa}>
            <p className={styles.name}>Session Time</p>
            <p className={styles.headlinea}>
              {moment(start).format('hh:mm A')} - {moment(end).format('hh:mm A')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

BookingDetails.propTypes = {
  bookingDetails: PropTypes.shape({
    apUserData: globalProptypes.user,
    start: PropTypes.string,
    end: PropTypes.string,
    isFreeSession: PropTypes.bool,
  }),
};

BookingDetails.defaultProps = {
  bookingDetails: {
    apUserData: {
      firstName: '',
      lastName: '',
      headline: '',
      primaryImg: '',
      hourlyRateWithSymbol: '',
    },
    start: '',
    end: '',
    isFreeSession: false,
  },
};

const mapStateToProps = (state) => {
  const bookingDetailsState = state.userState.ongoingBooking;

  return {
    bookingDetails: bookingDetailsState,
  };
};

export default connect(mapStateToProps)(BookingDetails);
