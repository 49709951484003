import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { apiurl } from '../../../utils';
import styles from './Cancel.module.css';
import enums from '../../../config/enum';

class Cancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      cancel_reason: '',
      canceled_ap_url: '',
    };
  }

  onCancelBooking = (e) => {
    e.preventDefault();

    const booking_from = moment.unix(this.props.booking.from);
    const current_time = moment();

    if (current_time.isAfter(booking_from)) {
      return toast.warn('Sorry, You cannot cancel an active session !', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    this.setState({ uploading: true });
    const headers = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${this.props.user.userToken}`;

    const data = {
      booking_id: this.props.booking.bookingId,
      cancel_reason_text: this.state.cancel_reason,
      cancel_reason_status: this.props.user.userType === enums.userType.ap ? enums.bookingStatus.AP_CANCELLED : enums.bookingStatus.AR_CANCELLED,
    };

    axios
      .post(`${apiurl}/v1.1/booking/cancel`, data, { headers })
      .then((result) => {
        if (result.data.success) {
          this.setState({ uploading: false });
          this.props.history.replace(
            `/dashboard?cancel=true&ap_forward_username=${this.props.booking.apUserData.username}`
          );
        } else {
          return toast.warn(result.data.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        return toast.warn('Sorry your booking could not be cancelled', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  render() {
    return (
      <div className={styles.cancel}>
        <p className={styles.label}>
          You are about to cancel a booking. Please describe your reason below for cancellation.
        </p>
        <textarea
          name="cancel"
          className={styles.cancelinput}
          placeholder="Describe your reason.."
          onChange={(e) => {
            this.setState({
              cancel_reason: e.target.value,
            });
          }}
        />
        <div className={styles.buttons}>
          <button className={styles.btnc} onClick={this.props.closeModal}>
            BACK
          </button>
          <button
            className={styles.btn}
            disabled={!!(this.state.uploading || this.state.cancel_reason.length < 1)}
            onClick={this.onCancelBooking}
          >
            {this.state.uploading ? 'CANCELING' : 'CANCEL BOOKING'}
          </button>
        </div>
      </div>
    );
  }
}

export default Cancel;
