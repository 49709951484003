/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.scss';
import { fetchDataLocal, storeDataLocal } from '../../utils/LocalStore';
import EarlyJoin from './EarlyJoin';
import Waiting from './Waiting';
import globalProptypes from '../../../config/proptype/index';
import { onCloseCameraFeedback } from '../../utils';
import ReJoin from './ReJoin';
import { getBookingDetails } from '../../../services/userService';
import { alert } from '../../../utils';
import { sendEvent } from '../../utils/event';
import { Track } from '@conrati/tracking';

const sessionSteps = {
  earlyJoin: 1, // Before time
  preWaiting: 2, // 5min before time
  waiting: 3, // Grace period 10 min
  joined: 4, // Joined session
  noShow: 5, // When one party did not join
};

class SessionPermisson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUpPermisson: true,
      bookingData: {},
      isFetchingBookingDetails: false,
    };
    this.videoShow = React.createRef();
    this.streamData = null;
  }

  componentDidMount() {
    this.checkCameraPermssion();
    this.fetchBookingDetails();
  }

  componentWillUnmount() {
    if (this.streamData) {
      onCloseCameraFeedback(this.streamData);
    }
  }

  fetchBookingDetails = async () => {
    const { location } = this.props;
    const { search } = location;

    const queryParams = new URLSearchParams(search);
    const bookingId = queryParams.get('sessionId');

    getBookingDetails(bookingId)
      .then((response) => {
        const bookingDetails = response[0];
        this.setState({
          bookingData: bookingDetails,
          isFetchingBookingDetails: true,
        });
      })
      .catch(() => {
        alert('Invalid booking id. Please contact support ');
      });
  };

  checkCameraPermssion = async () => {
    const { userData, sessionData, location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const bookingId = queryParams.get('sessionId');

    try {
      const prevPermission = fetchDataLocal('AudioVideoPermission');

      if (!prevPermission) {
        // Show pop up
        this.setState({
          showPopUpPermisson: true,
        });
      }

      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: 'self',
        },
      });

      sendEvent('permisson-given');

      this.setState({
        showPopUpPermisson: false,
      });

      storeDataLocal('AudioVideoPermission', true);

      this.videoShow.current.srcObject = streamData;
      this.streamData = streamData;

      Track('session/permission-given', {
            accepted: true,
            bookingId,
            email: userData.email,
            loginType: userData.loginType,
            userId: userData.userId,
            permission: "camera and audio",
      });
    } catch (err) {
      this.setState({
        showPopUpPermisson: true,
      });

      Track('session/permission-given', {
            accepted: false,
            bookingId,
            email: userData.email,
            loginType: userData.loginType,
            userId: userData.userId,
            error: err,
      });
    }
  };

  onJoinSessionBtnClick = async () => {
    // Stop camera feedback
    // await this.stopCameraFeedback(this.streamData);
    const { onJoinSession } = this.props;

    onJoinSession();
  };

  render() {
    const { showPopUpPermisson, bookingData, isFetchingBookingDetails } = this.state;
    const { sessionData, activeSessionStep, userData, rejoined } = this.props;
    const { title, dateOfBooking, from, to } = bookingData;

    return (
      <div className={styles.sessionPermission}>
        {!bookingData && <p>Loading session details</p>}
        {bookingData && (
          <div className={styles.videoPlaceholder}>
            <div className={styles.videoTest} ref={this.videoTest}>
              <video autoPlay ref={this.videoShow} muted>
                <track kind="captions" />
              </video>
            </div>

            {isFetchingBookingDetails && (
              <div className={styles.sessionInfo}>
                <p className={styles.wcome}>Welcome</p>
                <p className={styles.bdtime}>Session Details: </p>
                <h3 className={styles.btitle}>{title}</h3>
                <p className={styles.bdtimeb}>
                  Date: {moment(dateOfBooking, 'YYYY-MM-DD').format('DD MMM YYYY')}{' '}
                  {moment.unix(from).format('hh:mmA')} - {moment.unix(to).format('hh:mmA')}
                </p>
              </div>
            )}

            <div className={styles.sessionAction}>
              {activeSessionStep === sessionSteps.earlyJoin && <EarlyJoin />}
              {rejoined && <ReJoin userData={userData} />}
              {activeSessionStep === sessionSteps.waiting && !rejoined && (
                <Waiting sessionData={sessionData} userData={userData} />
              )}
            </div>
          </div>
        )}
        {showPopUpPermisson && (
          <div className={styles.permissionModal}>
            <div className={styles.bgDark} />
            <div className={styles.modal}>
              <h6>Why should you enable access to your camera and microphone on conrati?</h6>
              <p className={styles.message}>
                To have a successful video consultation on Conrati, the other participant must see
                and hear you. If you disable access to your camera and microphone, you will NOT be
                able to make use of Conrati&apos;s services and the consultation will result in a
                NO-SHOW on your part.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SessionPermisson.propTypes = {
  onJoinSession: PropTypes.func.isRequired,
  sessionData: PropTypes.shape({
    bookingData: PropTypes.shape({
      title: PropTypes.string,
      dateOfBooking: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
  tokboxSession: PropTypes.any,
  activeSessionStep: PropTypes.number.isRequired,
  userData: globalProptypes.user.isRequired,
  rejoined: PropTypes.bool.isRequired,
  location: globalProptypes.location.isRequired,
};

SessionPermisson.defaultProps = {
  sessionData: {
    bookingData: {
      title: '',
      dateOfBooking: '',
      from: '',
      to: '',
    },
  },
  tokboxSession: {},
};

export default SessionPermisson;
