import React from 'react';
import styles from './style.module.scss';
import './phone-input.scss';
import { NativeInputValidation } from '../input';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface PhoneInputData {
	countryCode: string;
	dialCode: string;
	name: string;
	format: string;
}

export type PhoneInputInterface = Omit<PhoneInputData, 'format'> & {
	number: string;
};

interface NativePhoneInputProps {
	name: string;
	countryCode: string;
	className?: string;
	label?: string;
	id?: string;
	searchPlaceholder?: string;
	onInputChange: (phoneEventData: PhoneInputInterface) => void;
	validate?: NativeInputValidation;
	hideLabel?: boolean;
	defaultValue?: string;
	value?: string;
}

export const NativePhoneInput = (props: NativePhoneInputProps): JSX.Element => {
	const {
		name,
		countryCode,
		label,
		id,
		searchPlaceholder,
		validate,
		hideLabel,
		onInputChange,
		className,
		value,
	} = props;

	const onChange = (value: string, eventData: PhoneInputData): void => {
		onInputChange({
			countryCode: eventData.countryCode,
			dialCode: eventData.dialCode,
			name: eventData.name,
			number: value,
		});
	};

	const showError = validate && !validate.isValid && validate.errorMessage;

	return (
		<div
			className={`${styles.inputContainer} ${className || ''} ${
				showError ? styles.inputError : ''
			}`}
		>
			<label
				className={`${styles.inputLabel} ${
					hideLabel ? styles.hideLabel : ''
				}`}
				data-testid="ui-native-input-label"
				htmlFor={id}
			>
				{label}
			</label>
			<ReactPhoneInput
				country={countryCode}
				value={value}
				id={id}
				enableSearch
				containerClass="react-tel-input phoneInputContainer"
				searchClass={styles.phoneSearchInput}
				buttonClass={styles.inputButton}
				searchNotFound="No results"
				disableSearchIcon
				data-testid="ui-native-input"
				onChange={onChange}
				inputProps={{
					name,
					required: true,
					autoFocus: true,
				}}
				searchPlaceholder={searchPlaceholder}
			/>

			{showError && (
				<div
					className={styles.inputErrorField}
					data-testid="ui-native-input-error-text"
				>
					{validate.errorMessage}
				</div>
			)}
		</div>
	);
};
