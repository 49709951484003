import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './style.module.scss';
import switch_ar from '../../Common/assets/switch.svg';
import enums from '../../../config/enum';

const Ar = (props) => {
  const { userState, handleOnLogout } = props;
  const { user } = userState;

  const history = useHistory();

  const onAccountSwitch = (event, path) => {
    event.preventDefault();

    const { pathname, search } = window.location;
    const encodeURL = encodeURIComponent(`${pathname}${search}`);

    history.push({
      pathname: path,
      search: `?redirect=${encodeURL}`,
    });
  };

  // Can see switch account to AP button
  const canSeeSwitchToAP = () => {
    if (user && user.config && !user.config.switchToAp) {
      return false;
    }

    if (
      user.isBusinessRegistered &&
      user.business &&
      user.business.typeOfBusiness === enums.typeOfBusinessText['Individual / Consumer']
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className={styles.nmitemgs}>
        <p className={styles.menuheader}>Advice Receiver Menu</p>
      </div>
      <div className={styles.nitemgn}>
        <div className={styles.nmitem}>
          <Link to="/myaccount">My account</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/bookinghistory">Booking History</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/dashboard">Upcoming Sessions</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/help/faq">FAQ</Link>
        </div>
        <div className={styles.nmitem}>
          <a href="https://blog.conrati.com" target="_blank" rel="noreferrer noopener">
            Blog
          </a>
        </div>
      </div>

      {canSeeSwitchToAP() && (
        <div className={styles.nmitemg}>
          <button type="button" onClick={(e) => onAccountSwitch(e, '/myaccount/switch')}>
            <div className={styles.switch}>
              <img src={switch_ar} alt="Switch Account" width="22px" />
              <p>Switch Account AP</p>
            </div>
          </button>
        </div>
      )}

      <div className={styles.signout}>
        <button className={styles.signoutbtn} onClick={handleOnLogout}>
          Sign out
        </button>
      </div>
    </>
  );
};

export default Ar;
