/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SearchPage from '../../v2/pages/Search';
import MobileSearchPageOld from '../../components/Search/Search';
import { checkIsMobileBrowser } from '../common/utils';

function Search(props) {
  const isDeviceMobile = checkIsMobileBrowser(navigator.userAgent);
  return isDeviceMobile ? <MobileSearchPageOld {...props} /> : <SearchPage {...props} />;
}

export default Search;
