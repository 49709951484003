import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import momenttz from 'moment-timezone';
import styles from './Book.module.css';
import Headers from '../Headers/Headers';
import calenderIco from '../Common/assets/icon_calendar.svg';
import timeIco from '../Common/assets/icon_clock_2.svg';
import { alert } from '../../utils';
import messages from '../../config/message';
import globalProptype from '../../config/proptype';
// import { fetchQuotation } from '../../services/userService';
import LoaderButton from '../Common/LoaderButton/LoaderButton';
import { bookSession, newOngoingBooking } from '../../actions/sessionAction';
import PageLoader from '../Common/PageLoader/PageLoader';
import { DateTime } from 'luxon';

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicTitle: '',
      topicDescription: '',
      isLoader: false,
    };
  }

  componentDidMount() {
    // if (history.action !== 'PUSH') {
    //   history.replace('/search');
    // }
    // this.fetchQuationRate();
  }

  // fetchQuationRate = async () => {
  //   const { user, location } = this.props;
  //   const { start, end, apUserData } = location.state;

  //   const reqQuotationData = {
  //     ar_business_details: user.business,
  //     ap_business_details: apUserData.business,
  //     ar_currency: user.currency,
  //     ap_currency: apUserData.currency,
  //     from: moment(start).unix(),
  //     to: moment(end).unix(),
  //     hourly_rate: apUserData.hourlyRate,
  //   };

  //   fetchQuotation(reqQuotationData).then(data => {
  //     const quotation = data;
  //     quotation.isGenerated = true;

  //     this.setState({
  //       quotation,
  //     });
  //   });
  // };

  componentWillUnmount() {
    this.setState({
      isLoader: false,
    });
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    const { topicTitle, topicDescription } = this.state;
    const { onNewOngoingBooking } = this.props;
    if (topicTitle.length > 0 && topicDescription.length > 0) {
      const { location, isLoggedIn, user, history } = this.props;
      const { start, end, apUserData, timeDiff, isFreeSession } = location.state;

      onNewOngoingBooking({
        start,
        end,
        apUserData,
        topicTitle,
        topicDescription,
        timeDiff,
        isFreeSession,
      });

      if (isLoggedIn) {
        if (user.personalDetailsCompleted && user.isBusinessRegistered) {
          if (isFreeSession) {
            return this.onFreeBooking(location.state);
          }

          return history.push({
            pathname: '/book/confirm',
          });
        }

        return history.push({
          pathname: '/quick-registration-ar',
        });
      }

      window.location.href = '/register?redirect=booking';
      return false;
    }

    return alert(messages.requiredFieldsMissing);
  };

  onFreeBooking = (bookingDetailsProps) => {
    this.setState({
      isLoader: true,
    });

    const { start, end, apUserData } = bookingDetailsProps;
    const { topicTitle, topicDescription } = this.state;
    const { onBookSession, user } = this.props;

    const reqBookingData = {
      ar_id: user.userId,
      ap_id: apUserData.userId,
      hourly_rate: apUserData.hourlyRate,
      date_of_booking: moment(start).format('YYYY-MM-DD'),
      title: topicTitle,
      topic_description: topicDescription,
      from: moment(start).unix(),
      to: moment(end).unix(),
      time_zone: DateTime.local().zoneName,
      is_session_free: true,
    };

    return onBookSession(reqBookingData);
  };

  // onSubmit = () => {
  //   const { user, location, history } = this.props;
  //   const { topicTitle, topicDescription, quotation } = this.state;
  //   if (user.personalDetailsCompleted && user.isBusinessRegistered) {
  //     if (topicTitle.length > 0 && topicDescription.length > 0) {
  //       const { start, end, apUserData } = location.state;
  //       if (!quotation.isGenerated) {
  //         return this.fetchQuationRate();
  //       }

  //       return history.replace({
  //         pathname: '/book/confirm',
  //         state: {
  //           start,
  //           end,
  //           apUserData,
  //           topicTitle,
  //           topicDescription,
  //           quotation,
  //         },
  //       });
  //     }
  //   } else {
  //     alert(messages.arIncompleteAccunt);
  //   }

  //   return true;
  // };

  render() {
    const { location, history } = this.props;

    if (!location.state) {
        return history.replace('/search');
    }

    const { start, end } = location.state;
    const { firstName, lastName, primaryImg, headline, hourlyRateWithSymbol } =
      location.state.apUserData;
    const { topicDescription, topicTitle, isLoader } = this.state;

    const { isFreeSession } = location.state;

    return (
      <div className={styles.book}>
        <Headers />
        {isLoader ? (
          <PageLoader />
        ) : (
          <div className={styles.bcontroller}>
            <div className={styles.bookbox}>
              <div className={styles.bookcard}>
                <div className={styles.head}>
                  <p>Advice Provider session booking</p>
                </div>
                <div className={styles.body}>
                  <div className={styles.userdetails}>
                    <img src={primaryImg} alt="userimg" />
                    <div className={styles.usrd}>
                      <p className={styles.ctit}>{`${firstName} ${lastName}`}</p>
                      <p>{headline}</p>
                    </div>
                    <div className={styles.usrrate}>
                      <p className={styles.ctit}>Hourly Rate</p>
                      <p>{hourlyRateWithSymbol}/hour</p>
                    </div>
                  </div>
                  <div className={styles.userdatetime}>
                    <p>Session date and time</p>
                    <div className={styles.time}>
                      <div className={styles.tdate}>
                        <img src={calenderIco} alt="calendar" />
                        <p>{moment(start).format('DD MMMM YYYY')}</p>
                      </div>
                      <div className={styles.tdate}>
                        <img src={timeIco} alt="calendar" />
                        <p>
                          {moment(start).format('hh:mm a')} – {moment(end).format('hh:mm a')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.form}>
                    <p>Describe your consultation needs</p>
                    <div className={styles.formgroup}>
                      <input
                        name="topicTitle"
                        type="text"
                        className={styles.formcontrol}
                        placeholder="Topic Title"
                        onChange={this.handleInput}
                      />
                      <textarea
                        name="topicDescription"
                        className={styles.formcontroltxt}
                        placeholder="Topic Description"
                        onChange={this.handleInput}
                      />
                    </div>
                    <p className={styles.inotice}>
                      By booking a consultation you agree to Conrati’s{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/legal/terms-and-condtions"
                      >
                        Terms and Conditions
                      </a>
                      ,{' '}
                      <a target="_blank" rel="noopener noreferrer" href="/legal/code-of-conduct">
                        Code of Conduct
                      </a>
                      ,{' '}
                      <a target="_blank" rel="noopener noreferrer" href="/legal/privacy-policy">
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a target="_blank" rel="noopener noreferrer" href="/company/costs-billing">
                        Costs and Billing.
                      </a>
                    </p>
                  </div>
                </div>
                <div className={styles.footer}>
                  <div
                    className={styles.cancel}
                    onClick={() => {
                      history.goBack();
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <button type="button" className={styles.button}>
                      Cancel
                    </button>
                  </div>
                  <div className={styles.booknow}>
                    {isFreeSession ? (
                      <button type="button" className={styles.sbtn} onClick={this.onSubmit}>
                        BOOK FREE SESSION
                      </button>
                    ) : (
                      <LoaderButton
                        onClick={this.onSubmit}
                        color="success"
                        active="NEXT"
                        success="NEXT"
                        disabled={!(topicDescription && topicTitle)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Book.propTypes = {
  history: globalProptype.history.isRequired,
  user: PropTypes.shape({
    personalDetailsCompleted: PropTypes.bool,
    isBusinessRegistered: PropTypes.bool,
    userId: PropTypes.string,
    isFreeSessionConsumed: PropTypes.bool,
  }).isRequired,
  location: globalProptype.location.isRequired,
  onNewOngoingBooking: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onBookSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    isLoggedIn: state.userState.isLoggedIn,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onNewOngoingBooking: newOngoingBooking,
      onBookSession: bookSession,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Book);
