import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import Headers from '../../Headers/Headers';
import styles from './BookConfirm.module.css';
import addcard from '../../Common/assets/addcard.svg';
import newcardimg from '../../Common/assets/newcard.svg';
import info from '../../Common/assets/info.svg';
import { addNewCard } from '../../../actions/userAction';
import { bookSession, newOngoingBooking } from '../../../actions/sessionAction';
import { fetchUserCardData } from '../../../actions/tempAction';
import { clearError } from '../../../actions/requestAction';
import globalProptype from '../../../config/proptype';

import AddCard from '../../Myaccount/AddCard/AddCard';
import ListCardsSelect from '../../Myaccount/ListCardsSelect/ListCardsSelect';
import LoaderButton from '../../Common/LoaderButton/LoaderButton';
import { fetchQuotation, getUserProfileData } from '../../../services/userService';
import PageLoader from '../../Common/PageLoader/PageLoader';
import enums from '../../../config/enum';
import { DateTime } from 'luxon';

class BookConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newcard: false,
      selectedCard: '',
      quotation: {},
      isQuotationGenerated: false,
      isFreeSession: false,
    };
  }

  componentDidMount() {
    const { user, onFetchUserCardData, bookingDetails } = this.props;
    const { isFreeSession } = bookingDetails;
    const { isFreeSessionConsumed } = user;

    if (isFreeSession) {
      if (!isFreeSessionConsumed) {
        this.onFreeSessionBooking();
      }
      this.setState({
        isFreeSession: true,
      });

      window.addEventListener('scroll', this.noScroll);
    }

    const userData = {
      mangoPayUserId: user.mangopayUserId,
    };

    onFetchUserCardData(userData);

    if (bookingDetails.start) {
      this.getUserData();
    }

    return false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.request.submit && newProps.request.error && newProps.request.errorObject.message) {
      toast.warn(newProps.request.errorObject.message, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      const { onClearError } = this.props;
      onClearError();

      // eslint-disable-next-line react/no-unused-state
      this.setState({ sent: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.noScroll);
  }

  onClickFreeSessionMOdal = (type) => {
    window.removeEventListener('scroll', this.noScroll);
    const { history } = this.props;

    this.setState({
      isFreeSession: false,
    });

    if (type === 'cancel') {
      return history.replace('/search');
    }

    return false;
  };

  getUserData = async () => {
    try {
      const { bookingDetails, user } = this.props;
      const { apUserData } = bookingDetails;

      let reqBody = {};

      if (user.currency && user.currency.currencyCode) {
        reqBody = {
          currency: user.currency,
        };
      }

      const apData = await getUserProfileData(reqBody, apUserData.username);

      const { start, end } = bookingDetails;

      const reqQuotationData = {
        ar_business_details: user.business,
        ap_business_details: apUserData.business,
        ar_currency: user.currency,
        ap_currency: apData.currency,
        from: moment(start).unix(),
        to: moment(end).unix(),
        hourly_rate: apData.hourlyRate,
        ap_user_id: apUserData.userId,
      };

      const quotation = await fetchQuotation(reqQuotationData);

      this.setState({
        quotation,
        isQuotationGenerated: true,
      });
    } catch (err) {
      this.setState({
        isQuotationGenerated: false,
      });
    }
  };

  addcard = () => {
    this.setState({ newcard: true });
  };

  selectCard = (card) => {
    this.setState({
      selectedCard: card,
    });
  };

  onAddCardSuccess = (status) => {
    if (status) {
      this.setState({ newcard: false });
    }
  };

  handleSubmit = () => {
    const { onBookSession, user, bookingDetails, onNewOngoingBooking } = this.props;
    const toUser = user;
    const { selectedCard, quotation } = this.state;
    const { start, end, topicTitle, topicDescription, apUserData } = bookingDetails;

    const reqBookingData = {
      ar_id: toUser.userId,
      ap_id: apUserData.userId,
      hourly_rate: quotation.transaction.hourlyRate,
      card_id: selectedCard.id,
      date_of_booking: moment(start).format('YYYY-MM-DD'),
      title: topicTitle,
      topic_description: topicDescription,
      from: moment(start).unix(),
      to: moment(end).unix(),
      time_zone: DateTime.local().zoneName,
    };

    onNewOngoingBooking(bookingDetails);
    onBookSession(reqBookingData);
  };

  onFreeSessionBooking = () => {
    const { onBookSession, user, bookingDetails, onNewOngoingBooking } = this.props;
    const toUser = user;
    const { start, end, topicTitle, topicDescription, apUserData } = bookingDetails;

    const reqBookingData = {
      ar_id: toUser.userId,
      ap_id: apUserData.userId,
      hourly_rate: apUserData.hourlyRate,
      date_of_booking: moment(start).format('YYYY-MM-DD'),
      title: topicTitle,
      topic_description: topicDescription,
      from: moment(start).unix(),
      to: moment(end).unix(),
      time_zone: DateTime.local().zoneName,
      is_session_free: true,
    };

    onNewOngoingBooking(bookingDetails);
    onBookSession(reqBookingData);
  };

  handleCancel = () => {
    const { bookingDetails, history } = this.props;
    const { apUserData } = bookingDetails;
    history.replace(`/${apUserData.username}`);
  };

  noScroll = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { user, cardData, request, onFetchUserCardData, onAddNewCard, bookingDetails } =
      this.props;
    const { selectedCard, newcard, quotation, isQuotationGenerated, isFreeSession } = this.state;
    const { start, end, topicTitle, apUserData } = bookingDetails;
    const { transaction } = quotation;

    const toUser = user;
    const businessdetailsAr = user.business;

    const currencyCode = transaction ? transaction.apCurrencyCode : '';

    return (
      <div className={styles.book}>
        <Headers />
        {!isQuotationGenerated ? (
          <PageLoader />
        ) : (
          <>
            {isFreeSession && (
              <div className={styles.freeSessionConsumed}>
                <div className={styles.blur} />
                <div className={styles.notifyCard}>
                  <div className={styles.card}>
                    <p className={styles.sub}>
                      Only FIRST consultations that are up to 30 min are free. Looks like you
                      already had your free session.
                    </p>
                    <div className={styles.btn}>
                      <button
                        type="button"
                        className={styles.fbtn}
                        onClick={this.onClickFreeSessionMOdal}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.bcontroller}>
              <ReactTooltip
                id="grandtotal"
                place="top"
                type="warning"
                effect="solid"
                className={styles.toolttip}
              />

              <div className={styles.bookbox}>
                <div className={styles.bookcard}>
                  <div className={styles.head}>
                    <p>Booking Confirmation</p>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.userdetails}>
                      <div className={styles.userinfo}>
                        <p className={styles.from}>FROM</p>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Advice Provider</p>
                          <p
                            className={styles.sub}
                          >{`${apUserData.firstName} ${apUserData.lastName}`}</p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Business Name</p>
                          <p className={styles.sub}>
                            {apUserData.business.businessName
                              ? apUserData.business.businessName
                              : 'NA'}
                          </p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>VAT Number</p>
                          <p className={styles.sub}>
                            {apUserData.business.vatNo ? apUserData.business.vatNo : 'NA'}
                          </p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Address</p>
                          <p className={styles.sub}>
                            {apUserData.business.address}
                            <br />{' '}
                            {`${apUserData.business.city} - ${apUserData.business.postalCode}`}
                            <br />
                            {apUserData.country.countryName}
                          </p>
                        </div>
                      </div>
                      <div className={styles.userinfo}>
                        <p className={styles.from}>TO</p>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Advice Receiver</p>
                          <p className={styles.sub}>{`${toUser.firstName} ${toUser.lastName}`}</p>
                        </div>
                        {businessdetailsAr.typeOfBusiness !==
                          enums.typeOfBusinessText['Individual / Consumer'] && (
                          <>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitle}>Business Name</p>
                              <p className={styles.sub}>{businessdetailsAr.businessName}</p>
                            </div>
                            <div className={styles.textgroup}>
                              <p className={styles.ptitle}>VAT Number</p>
                              <p className={styles.sub}>
                                {businessdetailsAr.vatNo ? businessdetailsAr.vatNo : 'NA'}
                              </p>
                            </div>
                          </>
                        )}

                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Address</p>
                          <p className={styles.sub}>
                            {businessdetailsAr.address}
                            <br /> {`${businessdetailsAr.city} - ${businessdetailsAr.postalCode}`}
                            <br />
                            {toUser.country.countryName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.servicedescription}>
                      <p className={styles.sdp}>Service Description</p>
                      <div className={styles.sdbody}>
                        {/* <div className={styles.textgroup}>
                          <p className={styles.ptitle}>BoP Code: 289</p>
                          <p className={styles.sub}>Payment for management consulting</p>
                      </div> */}
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Topic Title</p>
                          <p className={styles.sub}>{topicTitle}</p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Service Date</p>
                          <p className={styles.sub}>{moment(start).format('DD MMMM YYYY')}</p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Time of Consultation</p>
                          <p className={styles.sub}>
                            {moment(start).format('hh:mm a')} - {moment(end).format('hh:mm a')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.transaction}>
                    <div className={styles.stransaction}>
                      <p className={styles.sdp}>Transaction</p>
                      <div className={styles.sdbody}>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Description</p>
                          <p className={styles.sub}>Net value</p>
                          <p className={styles.sub}>
                            VAT{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {transaction.type || transaction.rate
                                ? `( ${transaction.type || ''} ${transaction.rate || ''})`
                                : ''}
                            </span>
                          </p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Hours</p>
                          <p className={styles.sub}>{`${transaction.totalHours} hours `}</p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Hourly Rate</p>
                          <p className={styles.sub}>{`${transaction.hourlyRateWithCurrency}`}</p>
                        </div>
                        <div className={styles.textgroup}>
                          <p className={styles.ptitle}>Total</p>
                          <p className={styles.sub}>{transaction.totalWithCurrency}</p>
                          <p className={styles.sub}>{transaction.vatAmountWithCurrency}</p>
                        </div>
                      </div>
                      <div className={styles.gtotal}>
                        <div className={styles.price}>
                          <p className={styles.gtotaltxt}>
                            <b>Grand Total</b>
                          </p>
                          <p className={styles.gamnt}>{transaction.grandTotalWithCurrency}</p>
                          <img
                            data-for="grandtotal"
                            data-tip="The grand total amount is displayed based on the currency of your registered country. The amount and currency charged on the selected credit card as invoiced to you will be in the currency of Advice Provider. The estimated conversion cost could be different from the actual amount charged due to fluctuations and discrepancies in exchange rates."
                            src={info}
                            width="30px"
                            height="30px"
                            alt="Info"
                          />
                        </div>
                      </div>
                      {/* <p className={styles.inotice}>Other Transaction Fees.</p> */}
                    </div>
                  </div>
                  <div className={styles.cardpayment}>
                    <p className={styles.sdpmp}>Method of Payment</p>
                    <div className={styles.payment}>
                      <div className={styles.pcards}>
                        <div
                          onKeyPress={() => {}}
                          role="button"
                          tabIndex="0"
                          className={styles.pcardhead}
                          onClick={this.addcard}
                        >
                          <img src={addcard} alt="Add" width="50px" height="50px" />
                          <p>Add a new card</p>
                        </div>
                        <div className={styles.pcardbody}>
                          <ListCardsSelect
                            onClick={this.selectCard}
                            cardData={cardData}
                            currencyCode={currencyCode}
                          />
                        </div>
                      </div>
                      {newcard && (
                        <div className={styles.pcards}>
                          <div className={styles.pcardhead}>
                            <img src={newcardimg} alt="new" width="50px" height="50px" />
                            <p>New card details</p>
                          </div>
                          <div className={styles.pcardbody}>
                            <AddCard
                              onAddCardSuccess={this.onAddCardSuccess}
                              handleSuccess
                              onAddNewCard={onAddNewCard}
                              mangopayUserId={user.mangopayUserId}
                              currency={user.currency}
                              onFetchUserCardData={onFetchUserCardData}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <div
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex="0"
                      className={styles.cancel}
                      onClick={this.handleCancel}
                    >
                      <button type="button" className={styles.button}>
                        Cancel
                      </button>
                    </div>
                    <div className={styles.booknow}>
                      <LoaderButton
                        onClick={this.handleSubmit}
                        color="success"
                        active="BOOK SESSION"
                        success="BOOKED"
                        disabled={!selectedCard}
                        retry={!request.loading && request.error}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

BookConfirm.propTypes = {
  user: PropTypes.shape({
    mangopayUserId: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
    }).isRequired,
    business: globalProptype.businessDetailsState,
    isFreeSessionConsumed: PropTypes.bool.isRequired,
  }).isRequired,
  onBookSession: PropTypes.func.isRequired,
  onFetchUserCardData: PropTypes.func.isRequired,
  onAddNewCard: PropTypes.func.isRequired,
  onClearError: PropTypes.func.isRequired,
  history: globalProptype.history.isRequired,
  vatDetails: PropTypes.shape({
    vatRate: PropTypes.number,
    vatType: PropTypes.string,
  }).isRequired,
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
  request: PropTypes.shape({
    submit: PropTypes.bool,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
  onNewOngoingBooking: PropTypes.func.isRequired,
  bookingDetails: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    apUserData: globalProptype.user,
    topicTitle: PropTypes.string,
    topicDescription: PropTypes.string,
    isFreeSession: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    cardData: state.temp.userCards,
    vatDetails: state.temp.vatDetails,
    request: state.request,
    businessdetailsAr: state.userState.buisnessDetails,
    bookingDetails: state.userState.ongoingBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onBookSession: bookSession,
      onFetchUserCardData: fetchUserCardData,
      onAddNewCard: addNewCard,
      onClearError: clearError,
      onNewOngoingBooking: newOngoingBooking,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(BookConfirm);
