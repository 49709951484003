import React from 'react';
import styles from './LiveCall.module.css';

const DisconnectCall = (props) => {
  return (
    <div className={styles.disconnectsess}>
      <div className={styles.content}>
        <p>Do you want to end the session ?</p>
      </div>
      <div className={styles.buttons}>
        <button className={styles.btnc} onClick={props.closeModal}>
          BACK
        </button>
        <button className={styles.btn} onClick={props.submitModal}>
          DISCONNECT
        </button>
      </div>
    </div>
  );
};

export default DisconnectCall;
