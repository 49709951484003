/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
import moment from 'moment';
import mangopay from 'mangopay-cardregistration-js-kit';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import axios from 'axios';
import { push } from 'connected-react-router';
import snakeCase from 'snakecase-keys';
import store from '../store';
import { UPDATE_ADVERT, LOG_OUT } from '../actions/types';
import messages from '../config/message';
import { loading, success, error } from '../actions/requestAction';
import { Track } from '@conrati/tracking';

/* ....................Static Variables..................... */
let { CancelToken } = axios;
let source = CancelToken.source();

let reactAppEnv = process.env.REACT_APP_STAGE || 'production';
let analyticsTrackingCode = '';
let apiurl = '';
let weburl = '';
let tokboxapikey = '';
let MangoPayBaseURL = '';
let MangoPayClientId = '';
let searchApiurl = '';
let connectAppUrl = '';

switch (reactAppEnv) {
  case 'dev':
    apiurl = 'http://localhost:4000/api';
    searchApiurl = 'http://localhost:4100/v1/conrati-search';
    weburl = 'http://localhost:3000';
    tokboxapikey = '46163402';
    MangoPayBaseURL = 'https://api.sandbox.mangopay.com';
    MangoPayClientId = 'conrati123test';
    analyticsTrackingCode = 'UA-152925997-1';
    connectAppUrl = 'https://connect.twisery.com';
    break;
  case 'dev-server':
    apiurl = 'https://api-dev.twisery.com/api';
    searchApiurl = 'https://api-search.twisery.com/v1/conrati-search';
    weburl = 'https://dev.twisery.com';
    tokboxapikey = '46163402';
    MangoPayBaseURL = 'https://api.sandbox.mangopay.com';
    MangoPayClientId = 'conrati123test';
    analyticsTrackingCode = 'UA-152925997-1';
    connectAppUrl = 'https://connect.twisery.com';
    break;
  case 'test':
    apiurl = 'https://api-test.twisery.com/api';
    searchApiurl = 'https://api-search.twisery.com/v1/conrati-search';
    weburl = 'https://test.twisery.com';
    tokboxapikey = '46163402';
    MangoPayBaseURL = 'https://api.sandbox.mangopay.com';
    MangoPayClientId = 'conrati123test';
    analyticsTrackingCode = 'UA-152925997-1';
    connectAppUrl = 'https://connect.twisery.com';
    break;
  case 'production':
    reactAppEnv = 'production';
    apiurl = 'https://api.conrati.com/api';
    searchApiurl = 'https://api-search.conrati.com/v1/conrati-search';
    weburl = 'https://www.conrati.com';
    tokboxapikey = '46193742';
    MangoPayBaseURL = 'https://api.mangopay.com';
    MangoPayClientId = 'conratigmbhcokgprod';
    analyticsTrackingCode = 'UA-122412247-1';
    connectAppUrl = 'https://connect.conrati.com';
    break;
  default:
    apiurl = 'http://localhost:4000/api';
    searchApiurl = 'http://localhost:4100/v1/conrati-search';
    weburl = 'https://localhost:3000';
    tokboxapikey = '46163402';
    MangoPayBaseURL = 'https://api.sandbox.mangopay.com';
    MangoPayClientId = 'conrati123test';
    analyticsTrackingCode = 'UA-152925997-1';
    connectAppUrl = 'https://connect.twisery.com';
    break;
}

export {
  reactAppEnv,
  apiurl,
  weburl,
  tokboxapikey,
  analyticsTrackingCode,
  MangoPayBaseURL,
  MangoPayClientId,
  searchApiurl,
  connectAppUrl,
};

/**
 * Show alert messages to user
 * @param {string} msg messae to show.
 * @returns {object} Returns toast alert
 */
export const alert = (msg) => {
  return toast.info(msg || messages.error, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: { backgroundColor: '#FCB612', color: '#ffffff' },
    bodyStyle: { color: '#fff' },
    icon: false,
  });
};

/**
 * Api request.
 * @param {string} method `method` is the request method to be used when making the request.
 * @param {string} url `url` is the server URL that will be used for the request.
 * @param {Object} data `data` is the data to be sent as the request body.
 * @param {string} headers `headers` are custom headers to be sent.
 * @param {string} options `options` any additional configuration.
 * @returns {Promise.<Array>} Return response or error
 */
export const apiRequest = (method, url, data = {}, headers = {}, options = {}) => {
  let modifyBody = true;

  if (options.modifyBody === false) {
    modifyBody = false;
  }
  if (modifyBody) {
    // eslint-disable-next-line no-param-reassign
    data = snakeCase(data, {
      deep: true,
    });

    // Delete user token key
    // eslint-disable-next-line no-param-reassign
    if (data.user_token) {
      delete data.user_token;
    }
  }

  return new Promise((resolve, reject) => {
    store.dispatch(loading());
    const headersConfig = {
      'Content-Type': 'application/json',
      ...headers,
    };

    if (store.getState().userState.isLoggedIn) {
      headersConfig.Authorization = `Bearer ${store.getState().userState.user.userToken}`;
    }

    if (!options.cancelToken) {
      // eslint-disable-next-line no-param-reassign
      options.cancelToken = source.token;
    }

    axios({
      method,
      url: `${apiurl}${url}`,
      headers: headersConfig,
      data,
      ...options,
      maxRedirects: 10,
    })
      .then((response) => {
        if (response.status < 400) {
          store.dispatch(success());
          resolve(response.data);
        } else {
          throw response.data;
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          store.dispatch(error(err));
          if (err.response && err.response.status === 401) {
            source.cancel('All previous request canceled');
            CancelToken = axios.CancelToken;
            source = CancelToken.source();
            alert('Session Expired');
            store.dispatch({
              type: LOG_OUT,
              payload: {
                user: {},
              },
            });
            return store.dispatch(push('/signin'));
          }
          return reject(err);
        }

        return reject(err);
      });
  });
};

/**
 * Search Api request.
 * @param {string} method `method` is the request method to be used when making the request.
 * @param {string} url `url` is the server URL that will be used for the request.
 * @param {string} data `data` is the data to be sent as the request body.
 * @param {string} headers `headers` are custom headers to be sent.
 * @param {string} options `options` any additional configuartion.
 * @returns {Promise.<Array>} Return response or error
 */
export const searchApiRequest = (method, url, data = {}, headers = {}, options = {}) => {
  let modifyBody = true;

  if (options.modifyBody === false) {
    modifyBody = false;
  }
  if (modifyBody) {
    // eslint-disable-next-line no-param-reassign
    data = snakeCase(data, {
      deep: true,
    });

    // Delete user token key
    // eslint-disable-next-line no-param-reassign
    delete data.user_token;
  }

  return new Promise((resolve, reject) => {
    store.dispatch(loading());
    const headersConfig = {
      'Content-Type': 'application/json',
      ...headers,
    };

    if (store.getState().userState.isLoggedIn) {
      headersConfig.Authorization = `Bearer ${store.getState().userState.user.userToken}`;
    }

    if (!options.cancelToken) {
      // eslint-disable-next-line no-param-reassign
      options.cancelToken = source.token;
    }

    axios({
      method,
      url: `${searchApiurl}${url}`,
      headers: headersConfig,
      data,
      ...options,
    })
      .then((response) => {
        if (response.data.status) {
          store.dispatch(success());
          resolve(response.data.data);
        } else {
          throw response.data;
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          store.dispatch(error(err));
          if (err.response && err.response.status === 401) {
            source.cancel('All previous request canceled');
            CancelToken = axios.CancelToken;
            source = CancelToken.source();
            alert('Session Expired');
            store.dispatch({
              type: LOG_OUT,
              payload: {
                user: {},
              },
            });
            return store.dispatch(push('/signin'));
          }
          return reject(err);
        }

        return reject(err);
      });
  });
};

/**
 * Cancel all axios request
 */

export const axiosCancelToken = () => {
  source.cancel('All request canceled');
  CancelToken = axios.CancelToken;
  source = CancelToken.source();
};

/**
 * Check whether an object is empty or not.
 * @param {Object} obj The object to check
 * @returns {boolean} Return true or false
 */

export const isObjectEmpty = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

/**
 * Validate email address.
 * @param {string} email Email id.
 * @returns {boolean} Return true or false.
 */
export const isValidEmail = (email) => {
  const emailRegex = /^[a-z-A-Z_0-9]+(?:\.\w+)*@[a-zA-Z0-9_-]+(?:\.\w+)+$/gm;
  // const emailreg = /^\w+(?:\.\w+)*@\w+(?:\.\w+)+$/gm;
  return emailRegex.test(email);
};

/**
 * Validate password.
 * @param {string} password Password.
 * @returns {boolean} Return true or false.
 */
export const isValidPassword = (password) => {
  const passwordtest = /(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[+!@#$%^&*?()-]))(?=.{6,})/gm;
  return passwordtest.test(password);
};

/**
 * Get youtube video id from url.
 * @param {string} url Youtube video url.
 * @returns {string} Returns video id.
 */
export const getYoutubeVideoId = (url) => {
  const urlsplit = url.split('/');

  if (urlsplit.length >= 4) {
    if (urlsplit[3].startsWith('watch')) {
      const watchcheck = urlsplit[3].split('=');
      return watchcheck[1];
    }

    if (urlsplit[3].startsWith('embed')) {
      return urlsplit[4];
    }

    if (urlsplit.length === 4 && !urlsplit[3].startsWith('watch')) {
      return urlsplit[3];
    }
  }

  return 'Invalid url';
};

/**
 * Check whether an date is weekday or weekend.
 * @param {string} date The date to check
 * @returns {boolean} Return true or false
 */
export const isWeekend = (date) => {
  const day = moment(date).day();
  if (day === 0 || day === 6) {
    return true;
  }
  return false;
};

/**
 * Convert time to number.
 * @param {string} date The date to check
 * @returns {number} Return number
 */
export const timeToNumber = (date) => {
  return Number(moment(date).format('HH')) + Number(moment(date).format('mm')) / 60;
};

/**
 * Convert num to time object.
 * @param {number} num The number to civert
 * @returns {object} Return time object
 */
export const numToTime = (num) => {
  const time = {
    hour: Number(`0${Math.floor(num) % 24}`.slice(-2)),
    minute: Number(`${(num % 1) * 60}0`.slice(0, 2)),
  };
  return time;
};

/**
 * Mangopay add new card.
 * @param {Object} carddata Card data.
 * @returns {Promise<string[]>} Promise fullfilled of added card details.
 */
export const addNewCard = (carddata) => {
  return new Promise((resolve, reject) => {
    mangopay.cardRegistration.registerCard(
      carddata,
      // eslint-disable-next-line func-names
      function () {
        resolve(carddata);
      },
      // eslint-disable-next-line func-names
      function (err) {
        reject(err);
      }
    );
  });
};

/**
 * Get random id
 * @returns {number} Returns random id
 */

export const getRandomID = () => {
  return Number(moment().unix()) + Math.floor(Math.random() * 9000000000) + 1000000000;
};

/**
 * Get time
 * @param {number} num Hour.
 * @returns {Object} Returns time objecte
 */
function getTime(num) {
  const tempHour = String(Math.trunc(num / 60));
  const hour = tempHour + ''.length === 1 ? `0${tempHour}` : tempHour;
  const min = num % 60 === 0 ? '00' : num % 60;
  return { num, time: `${hour}:${min}` };
}

/**
 * Get time slots
 * @param {Array.<Object>} blockTimes Blocked slots.
 * @param {boolean} showTimeAsString Show time as string.
 * @param {string} interval Interval of sslots.
 * @param {boolean} includeStartBlockedTime Whether to include blocked time.
 * @param {boolean} includeEndBlockedTime Whether to include endtime.
 * @returns {Array.<Object>} Returns free slots
 */
export function getTimeSlots(
  blockTimes,
  showTimeAsString,
  interval,
  includeStartBlockedTime,
  includeEndBlockedTime
) {
  let times = 1;
  let sums = 60;
  // eslint-disable-next-line no-param-reassign
  includeStartBlockedTime = includeStartBlockedTime === true;
  // eslint-disable-next-line no-param-reassign
  includeEndBlockedTime = includeEndBlockedTime === true;
  switch (interval) {
    case 'tenth':
      times = 6;
      sums = 10;
      break;
    case 'quarter':
      times = 4;
      sums = 15;
      break;
    case 'half':
      times = 2;
      sums = 30;
      break;
    case 'one':
      times = 1;
      sums = 60;
      break;
    case 'two':
      times = 1 / 2;
      sums = 120;
      break;
    case 'three':
      times = 1 / 3;
      sums = 180;
      break;
    case 'four':
      times = 1 / 4;
      sums = 240;
      break;
    default:
      times = 1;
      sums = 60;
      break;
  }
  let start = 0;
  let dateTimes = Array(Math.round(24 * times))
    .fill(0)
    // eslint-disable-next-line func-names
    .map(function () {
      start += sums;
      return start;
    });
  // eslint-disable-next-line no-param-reassign
  blockTimes = Array.isArray(blockTimes) === true && blockTimes.length > 0 ? blockTimes : [];
  if (blockTimes.length > 0) {
    // eslint-disable-next-line func-names
    dateTimes = blockTimes.reduce(function (acc, x) {
      return (
        acc
          // eslint-disable-next-line func-names
          .filter(function (y) {
            return includeStartBlockedTime === true ? y <= x[0] : y < x[0];
          })
          .concat(
            // eslint-disable-next-line func-names
            acc.filter(function (y) {
              return includeEndBlockedTime === true ? y >= x[1] : y > x[1];
            })
          )
      );
    }, dateTimes);
  }
  if (showTimeAsString === true) {
    return (
      dateTimes
        // eslint-disable-next-line func-names
        .map(function (x) {
          return getTime(x);
        })
        // eslint-disable-next-line func-names
        .reduce(function (accc, element) {
          // eslint-disable-next-line no-param-reassign
          accc[`${element.num}`] = element.time;
          return accc;
        }, {})
    );
  }
  return dateTimes;
}

/**
 * Verify whether browser is mobile or not
 * @param {string} useragent useragent.
 * @returns {boolean} Returns true or false
 */
export const checkIsMobileBrowser = (useragent) => {
  const regex = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/;
  if (regex.test(useragent)) {
    return true;
  }
  return false;
};

/**
 * Send analytical event to server
 * @param {string} category Typically the object that was interacted with (e.g. 'Video').
 * @param {string} action The type of interaction (e.g. 'play').
 * @param {string} label Useful for categorizing events (e.g. 'Fall Campaign')
 * @returns {object} Sends event data
 */
export const sendAnalyticsEvent = (category, action, label = '') => {
  return ReactGA.event({
    category,
    action,
    label,
  });
};

/**
 * Clear analytics data from redux store
 */
export const clearAnalytics = () => {
  return store.dispatch({
    type: UPDATE_ADVERT,
    payload: {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
    },
  });
};

/**
 * Send analytics data to redux store
 * @param {Object} analytics messae to show.
 * @returns {object} Send data to redux store
 */
export const sendAnalyticsEventRegistration = (analytics) => {
  if (analytics.utm_source) {
    // eslint-disable-next-line camelcase
    const utm_medium = analytics.utm_medium ? analytics.utm_medium : '';
    // eslint-disable-next-line camelcase
    const utm_campaign = analytics.utm_campaign ? `_${analytics.utm_campaign}` : '';

    // eslint-disable-next-line camelcase
    const label = (utm_medium + utm_campaign).toLowerCase();

    sendAnalyticsEvent('Registration', analytics.utm_source, label);
    clearAnalytics();

    // switch (analytics.utm_source) {
    //   case enums.analyticsUtmSource.facebook:
    //   case enums.analyticsUtmSource.linkedin:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    //   case enums.analyticsUtmSource.twitter:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    //   case enums.analyticsUtmSource.youtube:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    //   case enums.analyticsUtmSource.email:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    //   case enums.analyticsUtmSource.referral:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    //   default:
    //     sendAnalyticsEvent('Registration', analytics.utm_source, label);
    //     clearAnalytics();
    //     break;
    // }
  }
};

/**
 * Generate pagination.
 * @param {number} current Current page.
 * @param {number} last Last page.
 * @param {number} width width.
 * @returns {Array} Returns array of pages.
 */
export const paginationGenerator = (current, last, width = 2) => {
  const left = current - width;
  const right = current + width + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i <= right)) {
      range.push(i);
    } else if (i < left) {
      i = left - 1;
    } else if (i > right) {
      range.push(last);
      break;
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
};

/**
 * Check if number is divisible by three
 * @param {number} number Number to check.
 * @returns {boolean} return true false
 */
export const isDivisibleByThree = (number) => {
  if (number % 3 === 0) {
    return true;
  }

  return false;
};

/**
 * Check if number is divisible by three
 * @param {boolean} isFreeSessionOpted isFreeSessionOpted.
 * @param {boolean} isFreeSessionConsumed isFreeSessionConsumed.
 * @param {boolean} isLoggedIn isLoggedIn.
 * @returns {boolean} return true false
 */
export const isFreeSession = (isFreeSessionOpted, isFreeSessionConsumed, isLoggedIn) => {
  if (isFreeSessionConsumed) {
    return false;
  }

  if (isFreeSessionOpted && isLoggedIn && !isFreeSessionConsumed) {
    return true;
  }

  if (isFreeSessionOpted && !isLoggedIn) {
    return true;
  }

  return false;
};

/**
 * Count number of words in a string
 * @param {string} str String.
 * @returns {number} return number of words
 */

export const wordCount = (str) => {
  // eslint-disable-next-line no-param-reassign
  str = str.replace(/\s+/g, ' ').trim();
  return str.split(' ').length;
};

/**
 * Scrolls to top of the window
 */
export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

function isTrackWithinHour() {
    const lastTrackTime = localStorage.getItem('lastTrackTime');

    if (lastTrackTime) {
        const currentTime = Date.now();
        const oneHour = 60 * 60 * 1000; // in milliseconds

        return (currentTime - lastTrackTime) < oneHour;
    }
    
    // No tracking has been recorded yet
    return false;
}

function track(eventName, userData, recordTracking) {
    if (!recordTracking) {
        return Track(eventName, userData);
    }

    // Check if tracking was done within the last hour
    if (!isTrackWithinHour()) {
        Track(eventName, userData);
        
        localStorage.setItem('lastTrackTime', Date.now());
    }
}

/**
 * App activity tracker
 * @param {string} eventName
 * @param {object} userData
 * @param {number} timeout
 * @param {boolean} trackImmediately
 * @param {boolean} recordTracking
 */
export const appActivityTracker = (eventName, userData, timeout, trackImmediately = false, recordTracking = false) => {
      // Track immediately
    if (trackImmediately) {
        track(eventName, userData);
    }

    // Set interval to track activity
    setInterval(() => track(eventName, userData, recordTracking), timeout);
};
