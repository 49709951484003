import React from 'react';
import styles from './HappinessResources.module.scss';

type HappinessResourceArticleProps = {
	img: string;
	link: string;
	title: string;
	content: string;
};

export default function HappinessResourceArticle(
	props: HappinessResourceArticleProps
): JSX.Element {
	return (
		<a href={props.link} className={styles.blogArticleCard} target="_open">
			<img
				data-testid="quiz-happiness-resource-article-img"
				src={props.img}
				alt={props.title}
				className={styles.articleImg}
			/>
			<div className={styles.articleContentDetails}>
				<p
					data-testid="quiz-happiness-resource-article-title"
					className={styles.articleTitle}
				>
					{props.title}
				</p>
				<div
					data-testid="quiz-happiness-resource-article-content"
					className={styles.articleContent}
				>
					{props.content}
				</div>
			</div>
			<div className={styles.readMoreArticles}>
				<p>Read more</p>
			</div>
		</a>
	);
}
