import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { alert } from '../../../utils';
import Headers from '../../Headers/Headers';
import globalPropTypes from '../../../config/proptype';
import { secureBooking, validatePayIn, validatePreAuth } from '../../../services/userService';
import { getAppPaymentReturnUrl } from '@conrati/utils';
import { Track } from '@conrati/tracking';
import { DateTime } from 'luxon';
class PaymentConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  async componentDidMount() {
    const { location, isLoggedIn } = this.props;

    const searchparams = new URLSearchParams(location.search);

    if (searchparams.has('transactionId')) {
      const payInId = searchparams.get('transactionId');
      const validation = await validatePayIn(payInId);

      if (validation.status === 'FAILED') {
        Track('payment/experiment/payin-failed', { payInId });

        alert(validation.message);

        // Returt to return url
        const appReturnUrl = getAppPaymentReturnUrl();

        if (appReturnUrl) {
          return (window.location.href = appReturnUrl);
        }
      }
    }

    if (searchparams.has('preAuthorizationId')) {
      const preauthId = searchparams.get('preAuthorizationId');
      const validation = await validatePreAuth(preauthId);

      if (validation.status === 'FAILED') {
        Track('payment/experiment/preauth-failed', { preauthId });

        alert(validation.message);

        // Returt to return url
        const appReturnUrl = getAppPaymentReturnUrl();

        if (appReturnUrl) {
          return (window.location.href = appReturnUrl);
        }
      }
    }

    if (searchparams.has('redirectFrom') && searchparams.get('redirectFrom') === 'experiment') {
      const returnUrl = window.localStorage.getItem('experimentReturnUrl');
      const experimentVariant = window.localStorage.getItem('experimentVariant');

      Track('payment/experiment/redirected-to-experiment-url', { returnUrl });

      if (experimentVariant === 'x') {
        return (window.location.href = 'https://pages.conrati.com/thank-you-for-booking-ec007/');
      }

      return (window.location.href = returnUrl);
    }

    if (searchparams.has('preAuthorizationId') && isLoggedIn) {
      const preauthId = searchparams.get('preAuthorizationId');

      const { ongoingBooking, user, history } = this.props;
      const { start, end, topicTitle, topicDescription, apUserData } = ongoingBooking;

      const reqData = {
        preauth_id: preauthId,
        ar_id: user.userId,
        ap_id: apUserData.userId,
        hourly_rate: apUserData.hourlyRate,
        date_of_booking: moment(start).format('YYYY-MM-DD'),
        title: topicTitle,
        topic_description: topicDescription,
        from: moment(start).unix(),
        to: moment(end).unix(),
        time_zone: DateTime.local().zoneName,
      };

      secureBooking(reqData)
        .then((result) => {
          return history.replace(`/dashboard?booking_type=new&booking_id=${result.bookingId}`);
        })
        .catch((err) => {
          alert(err.message || 'Something went wrong. Please contact support');
          this.setState({ error: true });

          setTimeout(() => {
            return history.replace('/');
          }, 10000);
        });
    }
  }

  render() {
    const { error } = this.state;
    return (
      <div>
        <Headers />
        {!error ? (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <p>
              Your payment is being submitted. Please do not close this window, refresh your browser
              or click the Back button on your browser....
            </p>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <p>Your payment could not be processed. Redirecting to home page....</p>
          </div>
        )}
      </div>
    );
  }
}

PaymentConfirm.propTypes = {
  location: globalPropTypes.location.isRequired,
  history: globalPropTypes.history.isRequired,
  user: PropTypes.shape({
    userId: PropTypes.string,
  }).isRequired,
  ongoingBooking: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    topicTitle: PropTypes.string,
    topicDescription: PropTypes.string,
    apUserData: PropTypes.object,
    quotation: PropTypes.object,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    ongoingBooking: state.userState.ongoingBooking,
    isLoggedIn: state.userState.isLoggedIn,
  };
};

export default connect(mapStateToProps, null)(PaymentConfirm);
