import React from 'react';
import style from './style.module.scss';

function DisconnectNoShowAP() {
  return (
    <div className={style.disconnectSession}>
      <div className={style.card}>
        <div className={style.message}>
          <h6>No show by Consultant!</h6>
          <p className={style.msgtxt}>
            Unfortunately your consultant has failed to show up within the first ten minutes. You
            will not be billed for this session and your booking fee will be refunded.
            <span>
              <a className={style.link} href="https://www.conrati.com/legal/terms-and-condtions">
                Terms and Conditions
              </a>
            </span>
          </p>
        </div>
        <div className={style.btns}>
          <a href="/bookinghistory">Booking History</a>
        </div>
      </div>
    </div>
  );
}

export default DisconnectNoShowAP;
