import { ApiService } from '@conrati/utils';

const trackingEndpoint = '/v2/identify';

// Tracking service
function TrackingApiService(eventProperties: { [key: string]: unknown }): void {
	ApiService.ApiRequest('POST', trackingEndpoint, eventProperties);
}

export { TrackingApiService };
