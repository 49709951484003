import * as React from 'react';
import styles from './Testimonials.module.scss';
import arrow from '../../assets/svg/arrowWhite.svg';
import testimonialCircleRight from '../../assets/svg/testimonialCircleR.svg';
import testimonialCircleLeft from '../../assets/svg/testimonialCircleL.svg';
import { testimonials as testimonialData } from '../../common/constants/data';
import TestimonialItem from './testimonial-item/TestimonialItem';
import { handleSliderController } from './testimonialUtil';

export default function Testimonials(): JSX.Element {
	const transformSize = -20;

	const handleBtnClick = (type: string): void => {
		handleSliderController(type, testimonialData.length);
	};

	return (
		<div className={styles.testimonialsSection}>
			{/* Desktop version */}
			<div className={styles.testimonialDesktop}>
				<div className={styles.testimonialContainer}>
					<p className={styles.testimonialTitle}>Testimonials</p>
					<div className={styles.wrapperTestimonial}>
						<img
							src={testimonialCircleLeft}
							alt="Left Circle"
							className={styles.leftCircle}
						/>
						<img
							src={testimonialCircleRight}
							alt="Right Circle"
							className={styles.rightCircle}
						/>
						<div
							className={styles.testimonial}
							data-size={transformSize}
							id="desktop-testimonials"
							data-testid="quiz-testimonials-list"
							style={{ transform: `translateX(${transformSize}px)` }}
						>
							{testimonialData.map(testimonial => (
								<TestimonialItem
									key={testimonial.id}
									name={testimonial.name}
									comment={testimonial.comment}
									star={testimonial.star}
								/>
							))}
						</div>
					</div>

					<div className={styles.marker}>
						<div className={styles.sliderButtons}>
							<button
								type="button"
								className={styles.prevSliderBtn}
								data-testid="quiz-testimonials-prev-btn"
								onClick={(): void => handleBtnClick('prev')}
							>
								<img src={arrow} alt="button" />
							</button>
							<button
								type="button"
								className={styles.nextSliderBtn}
								data-testid="quiz-testimonials-next-btn"
								onClick={(): void => handleBtnClick('next')}
							>
								<img src={arrow} alt="button" />
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Mobile version */}
			<div className={styles.testimonialMobile}>
				<div className={styles.testimonialContainer}>
					<p className={styles.title}>Testimonials</p>
					<div className={styles.wrapperTestimonial}>
						<img
							src={testimonialCircleLeft}
							alt="Left Circle"
							className={styles.leftCircle}
						/>
						<img
							src={testimonialCircleRight}
							alt="Right Circle"
							className={styles.rightCircle}
						/>
						<div
							className={styles.testimonial}
							id="mobile-testimonials"
							style={{ transform: `translateX(${transformSize}px)` }}
						>
							{testimonialData.map(testimonial => (
								<TestimonialItem
									key={testimonial.id}
									name={testimonial.name}
									comment={testimonial.comment}
									star={testimonial.star}
								/>
							))}
						</div>
					</div>

					<div className={styles.marker}>
						<div className={styles.sliderButtons}>
							<button
								type="button"
								className={styles.prevSliderBtn}
								onClick={(): void => handleBtnClick('prev')}
							>
								<img src={arrow} alt="button" />
							</button>
							<button
								type="button"
								className={styles.nextSliderBtn}
								onClick={(): void => handleBtnClick('next')}
							>
								<img src={arrow} alt="button" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
