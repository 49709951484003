import { AxiosConfig } from './axios-config';
import { Method, AxiosRequestHeaders, AxiosResponse } from 'axios';

interface ApiResponseData<Result> {
	data?: Result;
	status: boolean;

	/* 
		Delete this property after verifying that all the APIs are returning the same response
	*/
	success?: boolean;

	statusCode: number;
	message?: string;
	result: Result;
}

interface SearchApiResponseData<Result> {
	status: boolean;
	code: number;
	message?: string;
	data: Result;
}

export class ApiService {
	public static async ApiRequest<Response>(
		method: Method,
		url: string,
		payload = {}
	): Promise<Response> {
		try {
			// AxiosInstance
			const axios = AxiosConfig.getInstance().getApiAxiosInstance();

			const headersConfig: AxiosRequestHeaders = {
				'Content-Type': 'application/json',
			};

			const response: AxiosResponse<ApiResponseData<Response>> =
				await axios({
					method,
					url,
					headers: headersConfig,
					data: payload,
				});

			if (response.data.status || response.data.success) {
				if (response.data.result) {
					return Promise.resolve(response.data.result);
				}

				if (response.data.data) {
					return Promise.resolve(response.data.data);
				}
			}

			throw new Error(response.data.message);
		} catch (err) {
			this.handleError(err);
			return Promise.reject(err);
		}
	}

	private static handleError(err: Error | unknown): void {
		console.log(err);
	}
}

export class SearchApiService {
	public static async ApiService<Response>(
		method: Method,
		url: string,
		params?: Record<string, string>
	): Promise<Response> {
		try {
			// AxiosSearchInstance
			const searchAxiosInstance =
				AxiosConfig.getInstance().getSearchAxiosInstance();

			const headersConfig: AxiosRequestHeaders = {
				'Content-Type': 'application/json',
			};

			const response: AxiosResponse<SearchApiResponseData<Response>> =
				await searchAxiosInstance({
					method,
					url,
					headers: headersConfig,
					params,
				});

			if (response.data.status) {
				return Promise.resolve(response.data.data);
			}

			throw new Error(response.data.message);
		} catch (err) {
			this.handleError(err);
			return Promise.reject(err);
		}
	}

	private static handleError(err: Error | unknown): void {
		console.log(err);
	}
}

// Export setEnvironment function
const setEnvironment = AxiosConfig.getInstance().setEnvironment;

export { setEnvironment };
