/* eslint-disable */
import React, { Component } from 'react';
import styles from '../Static.module.css';

class Termsandcondtion extends Component {
  render() {
    return (
      <div className={styles.brpage}>
        <div className={styles.brheader}>
          <h1>Terms and Conditions</h1>
        </div>
        <div className={styles.brcontainer}>
          <div
            style={{
              marginBottom: '50px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              color: '#555',
            }}
          >
            <div>
              <p
                style={{
                  marginLeft: '42.5pt',
                  textIndent: '-42.5pt',
                  textAlign: 'right',
                  lineHeight: '112%',
                  widows: 0,
                  orphans: 0,
                  fontSize: '11pt',
                }}
              >
                <span style={{ fontFamily: 'Calibri' }}>&nbsp;</span>
              </p>
            </div>
            <p style={{ textAlign: 'justify', fontSize: '14pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <ol type="I" style={{ margin: '0pt', paddingLeft: '0pt' }}>
              <li
                style={{
                  marginLeft: '11.4pt',
                  textAlign: 'justify',
                  paddingLeft: '12.85pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                  fontWeight: 'bold',
                }}
              >
                Terms and Conditions
              </li>
            </ol>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>
                  By accessing or using the Conrati Platform, you agree to comply with, and be bound
                  by, these Terms of Service (“Terms”). Please read them carefully.{' '}
                </span>
              </em>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </em>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Effective Date: </span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>04</span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}> </span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>December</span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}> 202</span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>0</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                Welcome to Conrati, the go-to marketplace for on demand online consulting.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                These Terms constitute a legally binding agreement (the “Agreement”) between you and
                us governing your access to and use of the Conrati website located at{' '}
              </span>
              <a href="http://www.conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#0563c1' }}>www.conrati.com</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                (the "Site"), our mobile, tablet and other smart device applications (collectively,
                "Application") and all associated services (collectively, the "Conrati Services" or
                “Services”).
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                In these terms, “you”, “your”, “user” or “users” refer to the individual or entity
                that uses the Conrati Site, Application or Services. "Conrati", "us," "we" or "our"
                refers to Conrati GmbH &amp; Co. KG, a company incorporated under the laws of
                Germany, and having its registered address at Siemensstraße 2, 78532 Tuttlingen,
                Germany.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati offers, through its Site, Application and Services, an online platform (the
                “Conrati Platform” or “Platform”) which provides opportunities for users providing
                skills, information and advice (the “Advice Providers”, “APs” or “Consultants”) to
                connect with users seeking skills, information and advice (the “Advice Receivers”,
                “ARs" or “Clients”).
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                You must read, agree to and accept all the terms and conditions contained in this
                Agreement to use our Site, Application and Services. Please also{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>carefully read</span>
              <span style={{ fontFamily: 'Roboto' }}> our </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Code of Conduct</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>, </span>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Privacy Policy</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>, </span>
              <a
                href="https://www.conrati.com/help/trust-and-safety"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    Trust and Safety Policy
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}> and </span>
              <a
                href="https://www.conrati.com/help/faq?section=general"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>FAQ Documents</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>, </span>
              <span style={{ fontFamily: 'Roboto' }}>
                which is incorporated by reference into these Terms.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto', color: '#ff0000' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                You acknowledge and agree that by accessing or using the Site, Application or
                Services, including registering for a Conrati account on our Site or Application (an
                “Account”), scheduling an Online Consultation with an AP or clicking to accept these
                Terms when prompted on our Site or Application, you are indicating that you have
                read and understand these Terms, agree to be bound by these Terms and are deemed to
                have executed this Agreement electronically.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                If you are accepting and executing these Terms on behalf of a registered business,
                company, partnership (whether incorporated or not) or any other legal entity or
                agency, you expressly represent and warrant that you have the authority to bind the
                company, legal entity or agency to these Terms in which event the terms “You” or
                “Your” will refer and apply to that company, legal entity or agency.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                If you are accepting and executing these Terms in your individual capacity,
                including as a self-employed individual, sole proprietor or individual consumer, you
                must be and hereby represent and warrant that you are 18 years and older and can
                conclude legally binding agreements.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                If you do not accept these Terms in its entirety, including any other agreements or
                policies which are incorporated into these Terms by reference, you have no right to
                use the Site, Application or Services.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                We reserve the right to modify these Terms, including the terms of our{' '}
              </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Code of Conduct</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>, </span>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Privacy Policy</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}> and </span>
              <a
                href="https://www.conrati.com/help/trust-and-safety"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    Trust and Safety Policy
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                , at any time and will provide you with notice of any material modifications or
                changes made to these Terms via the email address specified in your Account at least
                thirty (30) days prior to the date the modified Terms become effective.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                Your continued use of the Conrati Services after changes or modifications become
                effective shall mean that You accept those changes or modifications. The “Effective
                Date” date at the top of these Terms will be updated when any changes or
                modifications are made to these Terms. You should review these Terms regularly to
                ensure You are aware of the latest version of the Terms. Any revised Terms shall
                supersede all previous Terms.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Table of Contents</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '12pt',
                marginLeft: '36pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '11pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', color: '#1f3864' }}>&nbsp;</span>
              </em>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#1">
                  The Conrati Services
                </a>
              </span>
              <span style={{ width: '405.24pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#2">
                  Register
                </a>
              </span>
              <span style={{ width: '405.24pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#3">
                  Display and Search
                </a>
              </span>
              <span style={{ width: '352.34pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>4.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#4">
                  Book
                </a>
              </span>
              <span style={{ width: '420.53pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#5">
                  Advise
                </a>
              </span>
              <span style={{ width: '412.62pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#6">
                  Pay
                </a>
              </span>
              <span style={{ width: '427.37pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: '47pt',
                marginBottom: '5pt',
                textIndent: '-11pt',
                lineHeight: '108%',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>A.</span>
              <span style={{ width: '1.26pt', textIndent: '0pt', display: 'inline-block' }}>
                &nbsp;
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#6.1">
                  The AP Fee
                </a>
              </span>
              <span style={{ width: '343.4pt', textIndent: '0pt', display: 'inline-block' }}>
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginLeft: '47pt',
                marginBottom: '5pt',
                textIndent: '-11pt',
                lineHeight: '108%',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>B.</span>
              <span style={{ width: '1.26pt', textIndent: '0pt', display: 'inline-block' }}>
                &nbsp;
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#6.2">
                  The Conrati Fee
                </a>
              </span>
              <span style={{ width: '343.4pt', textIndent: '0pt', display: 'inline-block' }}>
                &nbsp;
              </span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>7.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#7">
                  Non-Disclosure of Information
                </a>
              </span>
              <span style={{ width: '361.96pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>8.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#8">
                  Communications
                </a>
              </span>
              <span style={{ width: '361.96pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.</span>
              <span style={{ width: '12.93pt', display: 'inline-block' }}>&nbsp;</span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#9">
                  User Content and Licenses
                </a>
              </span>
              <span style={{ width: '314.72pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#10">
                  Disclaimer
                </a>
              </span>
              <span style={{ width: '394.98pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>11.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#11">
                  Limitation of Liability
                </a>
              </span>
              <span style={{ width: '344.64pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>12.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#12">
                  Indemnification
                </a>
              </span>
              <span style={{ width: '370.5pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>13.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#13">
                  Termination
                </a>
              </span>
              <span style={{ width: '387.72pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>14.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#14">
                  Legal Jurisdiction and Dispute Resolution
                </a>
              </span>
              <span style={{ width: '244.56pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '5pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                <a className={styles.ta} href="#15">
                  General
                </a>
              </span>
              <span style={{ width: '409.21pt', display: 'inline-block' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}></p>
            <p style={{ marginBottom: '8pt', lineHeight: '108%', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <h1
              id="1"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>1.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  The Conrati Services
                </span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>The </span>
              <a href="http://www.conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#0563c1' }}>www.conrati.com</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                Site and Application is an online platform which provides opportunities for Advice
                Providers seeking to provide skills, information and advice to connect with Advice
                Receivers seeking skills, information and advice. The Conrati Services provided
                through the Site or Application include:
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Display</span>
                </em>
              </strong>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                – The Platform enables Advice Providers (e.g. business consultants) to create
                profiles which display their skills, areas of expertise, hourly rates, hourly
                availability and language proficiencies;{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Search</span>
                </em>
              </strong>
              <em>
                <span style={{ fontFamily: 'Roboto' }}> – </span>
              </em>
              <span style={{ fontFamily: 'Roboto' }}>
                Advice Receivers can use the Platform’s search functionality to find Advice
                Providers who have the requisite skills, areas of expertise, hourly rates, hourly
                availability and language proficiency they are looking for;
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Book</span>
                </em>
              </strong>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                – Through the Platform’s scheduling functionality, Advice Receivers can book{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>30 (thirty) minute</span>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                sessions (or longer sessions in 30 (thirty) minute increments) with Advice Providers
                who have the requisite skills and available hours (calendar invitations and meetings
                links are sent when bookings are made);{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Advise</span>
              </strong>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                – The Platform provides multi-functional infrastructure through which the online
                advice sessions between the Advice Provider and Advice Receiver takes place which
                includes video, online chat, VoIP, file upload and screen sharing functionality (the
                “Online Consultations”); and
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Pay</span>
              </strong>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                – Payments for the Advice Providers’ services are collected though the Platform’s
                payment and billing functionality.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati makes the Site and Application available to Advice Providers to display
                their profiles and Advice Receivers to search for suitable Advice Providers. We do
                not actively introduce Advice Providers and Advice Receivers to each other, nor do
                we act as a broker between the Advice Provider and Advice Receiver.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users do not have the authority to enter into any agreements (whether written, oral
                or otherwise), expressly or implicitly, on behalf of Conrati. For the sake of
                clarity, no partnership, joint venture or agency is created between users by
                agreeing to these Terms.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati does not provide any guarantee that by using the Platform an Advice Provider
                will be engaged by Advice Receivers.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Although we use reasonable skill and care in performing our Services we will not
                verify if, and cannot guarantee that, all information supplied on the Conrati
                Platform is accurate, complete or correct, nor can we be held responsible for any
                errors (including manifest and typographical errors), any interruptions (whether due
                to any (temporary and/or partial) breakdown, repair, upgrade or maintenance of our
                Site, Application or Platform or otherwise), inaccurate, misleading or untrue
                information or non-delivery of information.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>1.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati reserves the right, in its sole discretion, to refuse, suspend or revoke
                access to our Site, Application or Services where false information is provided or
                information is provided which otherwise violates these Terms. Conrati may, in this
                regard, perform background checks on any information provided by users, including
                but not limited to verification of value-added tax (“VAT”) numbers, personal
                information, professional credentials or any areas of expertise or skills listed by
                Advice Providers in their profiles.
              </span>
            </p>
            <h1
              id="2"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>2.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Register</span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users must register an Account to use the Site, Application or Services.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The Conrati Services are intended to be used by Advice Providers for business
                purposes only and not for personal or domestic purposes.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users registering an Account in their individual capacity, including as
                self-employed individuals or sole proprietors, must be 18 years and older to
                register an account.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users registering an Account on behalf of a registered business, company,
                partnership (whether incorporated or not) or any other legal entity or agency must
                comply with all licensing or registration requirements relevant to their business
                entity.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                When registering and creating an Account, users provide us with user data and
                information, including contact information (company name, personal name and surname,
                physical and email addresses, etc.) and information on skills, level or fields of
                expertise, qualifications, hourly rates and availability. By providing this
                information, users expressly provide us permission to publish this user data and
                information for purposes of making Advice Provider listings available to Advice
                Receivers.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                User agrees to provide true, accurate, and complete information when registering
                their profiles.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.7</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users may not register for more than one account, unless prior written permission
                has been obtained from Conrati.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>2.8</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users are responsible for safeguarding their usernames and passwords and keeping
                this information confidential always.{' '}
              </span>
            </p>
            <h1
              id="3"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>3.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Display and Search
                </span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati makes no representations about, nor do we guarantee, the truthfulness,
                accuracy, credibility or integrity of any information provided or maintained by
                Advice Providers on the Platform, including but not limited to information about
                Advice Providers’ commercial background, skills, level or fields of expertise,
                proficiency, qualifications, hourly rates and availability or any other information
                that is listed or displayed on our Site or Application by Advice Providers.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                We furthermore make no representations about, nor do we guarantee, the ability,
                reliability or capability of the Advice Providers to provide advice on the areas of
                expertise or skills listed in their profiles.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                We are not required to, nor do we, verify information provided by the users of the
                Platform. No background checks are required to be performed by us on any users
                registered on the Site or Application. We recommend that Advice Receivers only book
                Online Consultations with Advice Providers after carefully and thoroughly reviewing
                their profiles and whose skills and experience have been verified through the Advice
                Receiver’s own research or investigations.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Advice Providers are provided access to an Advice Provider Account with a calendar
                and profile section on the Platform where they take responsibility for updating all
                skills, rates, hourly availability, and other information that is displayed on the
                Platform.{' '}
              </span>
            </p>
            &nbsp;&nbsp;
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati retains the right to use all Advice Provider’s published profiles for
                Conrati marketing and promotion purposes.
              </span>
            </p>
            <h1
              id="4"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>4.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Book </span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>4.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                By scheduling an Online Consultation with an Advice Provider through the Platform,
                the Advice Receiver enters into a direct, legally binding, contractual relationship
                with the Advice Provider with whom an Online Consultation has been scheduled.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>4.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati acts solely as an intermediary between the Advice Receiver and the Advice
                Provider, transmitting the details of the Advice Receiver’s booking to the relevant
                Advice Provider and sending a calendar invitation e-mail on behalf of the Advice
                Provider. This calendar invitation e-mail contains the Online Consultation meeting
                details, including the electronic link which the Advice Receiver should click on to
                attend the meeting.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>4.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Each Advice Receiver qualifies for one free 30-minute consultation with an Advice
                Provider who chooses to offer this free service. Bookings over 30-minutes will be
                charged at the AP's full hourly rate.
              </span>
            </p>
            <h1
              id="5"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>5.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Advise</span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati is not a party to the transaction between the Advice Provider and the Advice
                Receiver. Users agree to be bound by the provisions contained in these Terms that
                relate directly, or indirectly, to the Online Consultations, including but not
                limited to our{' '}
              </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Code of Conduct</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>, </span>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Privacy Policy</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}> and </span>
              <a
                href="https://www.conrati.com/help/trust-and-safety"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    Trust and Safety Policy
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                . Users can agree that additional or different terms should apply to their
                contractual relationship, provided that these additional or different terms are not
                in conflict with the Terms or affect Conrati’s rights and obligations in any way.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Advice Providers act in their own, independent, capacity during Online Consultations
                and remain solely responsible for any services rendered during the Online
                Consultations.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The approaches, methods and processes followed by Advice Providers during Online
                Consultations are determined and controlled solely by the Advice Providers. Advice
                Providers are independent service providers using the Site or Application to market
                their skills, information and services to Advice Receivers. Advice Providers are not
                employees or agents of Conrati. We do not in any way supervise, direct or control
                the Advice Providers’ service and make no representations about, and do not
                guarantee, the standard, quality, accuracy or legality of the advice provided by the
                Advice Provider during Online Consultations.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Advice Receivers, in their sole discretion, should consider whether advice provided
                by Advice Providers through the Platform is correct, accurate, suitable and usable.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                It remains the sole responsibility of the Advice Receiver to ensure that he is
                available on the date and time of the scheduled Online Consultation, have sufficient
                Internet access and speed to allow for the successful execution of the Online
                Consultation, and to ensure that his surroundings and infrastructure (including
                computer hardware, video and VoIP functionality, software etc.) allow for the
                successful execution of the Online Consultation.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users are solely responsible, and assume all liability, for determining whether
                Advice Providers provide their services as independent contractors or employees of
                the Advice Receivers. The Conrati Services may not be used to circumvent any payroll
                tax, employees tax or employment law obligations.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>5.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>Please also refer to our </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    User Code of Conduct
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                {' '}
                for more detail on the Terms which apply specifically to the services provided
                during Online Consultations through the Platform.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <h1
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>6.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Pay</span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati provides payment services to users in respect of the Online Consultations,
                including payment collection via credit card and payout services via direct deposit
                (the “Payment Services”).
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users agree to provide true, accurate, current and complete information relating to
                the Payment Services and to keep this information up-to-date at all times. Users are
                solely responsible for the accuracy and completeness of information provided in
                respect of the Payment Services, including:{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ marginLeft: '54pt', textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.2.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Payout service information provided by Advice Providers, including address, name of
                account holder, bank name, bank account number or IBAN, SWIFT code; and
              </span>
            </p>
            <p style={{ marginLeft: '54pt', textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.2.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Payment collection service information provided by Advice Receivers, including card
                holder name, card number, expiry date and CVV.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ marginLeft: '38.25pt', textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users authorise Conrati to collect Payment Service information for purposes of
                collecting payments and making payouts. Conrati do not store any payment related
                data and entrust third-party payment service providers to store your data securely.
                We will not be responsible for any loss suffered by any user as a result of
                inaccurate or incomplete information provided in respect of the Payment Services.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The Payment Services involves the use of third-party payment service providers which
                are subject to different terms and conditions (the Platform may contain links to
                these services providers). We are not responsible or liable for the services
                provided by any third-party payment service providers and do not endorse or
                guarantee any third-party payment services. The third-party service providers may in
                certain instances charge additional fees when processing payments and payouts in
                connection with the Payment Services. Conrati is not responsible or liable for such
                fees and disclaims all liability in this regard. Users are encouraged to review the
                terms and conditions applicable to third-party payment services before using the
                Payment Services.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                We reserve the right to modify the Terms relating to our Payment Services and will
                provide notice of any material modifications or changes made via the email address
                specified in your Account at least thirty (30) days prior to the date the modified
                Terms become effective. Any user which disagrees with the modified Terms relating to
                our Payment Services may terminate this Agreement with immediate effect. Continued
                use of the Conrati Services, including the Payment Services, after the date the
                modified Terms become effective will constitute acceptance of the revised Terms.{' '}
              </span>
            </p>
            <h2
              style={{
                marginTop: '2pt',
                marginLeft: '22.5pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '13pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal', color: '#1f3864' }}>
                &nbsp;
              </span>
            </h2>
            <h2
              id="6.1"
              style={{
                marginTop: '2pt',
                marginLeft: '22.5pt',
                textIndent: '-22.5pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '13pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>A.</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>The AP Fee</span>
            </h2>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </em>
            </p>
            <p style={{ textIndent: '22.5pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>General</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </em>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                A fee will be due and payable by the Advice Receiver to any Advice Provider in
                respect of any online consultation booked and successfully completed via the
                Platform (“the AP Fee”) with the exception of a 30-minute booking made by Advice
                Receiver who qualifies for a free 30-minute consultation with an Advice Provider who
                chooses to offer this free service.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.7</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The AP Fee due and payable in respect of an online consultation will be calculated
                by multiplying the Advice Provider’s rate per hour, as displayed in the Advice
                Provider’s profile at the time of booking, by the number of consecutive hours booked
                for the online consultation. Conrati does not set the hourly rates as displayed in
                the Advice Providers’ profiles. These hourly rates are highly competitive and are
                set and maintained by the Advice Providers.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.8</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The total amount to be paid by the Advice Receiver, for the online consultation,
                inclusive of the AP Fee and any VAT or other taxes that may apply, will be displayed
                during the booking process (the “Total Amount”). Advice Receivers are shown a
                quotation of a consultation based on their selected currency with which they
                registered. Whilst Conrati updates it’s conversion rates hourly through the use of a
                third-party conversion service, the total amount accepted on the quotation could
                differ slightly from the actual amount charged due to fluctuations and discrepancies
                in real-time exchange rates. .Once the quotation has been accepted, the Advice
                Receiver will be prompted to make a pre-authorised payment via our third-party
                providers, [
              </span>
              <a
                href="https://www.mangopay.com/terms/MANGOPAY_Terms-EN.pdf"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    MangoPay Terms in English
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}> , </span>
              <a
                href="https://www.mangopay.com/terms/MANGOPAY_Terms-DE.pdf"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    MangoPay Terms in German
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                ], or such other payment methods as may be listed from time to time in these Terms.{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Successful pre-authorisation secures Advice Provider’s funds as the
                pre-authorisation renders the balance as unavailable on a Advice Receiver’s card
                until the outcome of the consultation is known.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.9</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>Upon successful completion of </span>
              <span style={{ fontFamily: 'Roboto' }}>a pre-authorisation</span>
              <span style={{ fontFamily: 'Roboto' }}> </span>
              <span style={{ fontFamily: 'Roboto' }}>on the relevant credit card</span>
              <span style={{ fontFamily: 'Roboto' }}>
                , the booking will reflect on the Advice Provider’s dashboard and Conrati calendar.
                The booking will also reflect on the Advice Receiver’s dashboard. A booking
                confirmation email will be sent to the Advice Receiver and the A
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                dvice Provider with a meeting invite for the online consultation. The online
                consultation can be accessed by users via their dashboards or via the meeting invite
                contained in the booking confirmation email.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textIndent: '27pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Collection of AP Fee</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.10</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati will collect the Total Amount on behalf of the Advice Provider from the
                Advice Receiver on the successful completion of the Online Consultation. For the
                avoidance of doubt, an Online Consultation will be considered to have been
                successfully completed where 1) the Advice Provider and Advice Receiver have
                successfully joined the Online Consultation within 10 minutes from the start of the
                session, and 2) no fatal technical failure was detected by our system during the
                session.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.11</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Advice Receivers authorise Conrati to charge their credit cards for the Total Amount
                due, as displayed during the booking process, either directly or via third-party
                service providers.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.12</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                All collections and payouts will be made in EUR (
              </span>
              <span style={{ fontFamily: 'Roboto', backgroundColor: '#ffffff' }}>€).</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textIndent: '27pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Cancellations and No-Shows </span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto', color: '#0000ff' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.13</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No AP Fee will be collected from the Advice Receiver if the Online Consultation is
                cancelled by either the Advice Receiver or Advice Provider via the Site or
                Application prior to the commencement of the Online Consultation which, for the
                avoidance of doubt, will be the start time of the Online Consultation as displayed
                in the booking.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.14</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No AP Fee will be collected in the event of a no-show by the Advice Provider. A
                no-show by the Advice Provider occurs where the Advice Provider fails to log into
                the Online Consultation session within 10 minutes from the Online Consultation’s
                starting time.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.15</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The Total Amount will be collected in the event of a no-show by the Advice Receiver.
                A no-show by the Advice Receiver occurs where the Advice Provider has successfully
                logged into the Online Consultation session but the Advice Receiver fails to log
                into the Online Consultation session within 10 minutes from the Online
                Consultation’s starting time.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.16</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No AP Fee will be collected in the event where both the Advice Provider and the
                Advice Receiver fail to successfully log into the Online Consultation session within
                10 minutes from the start of the Online Consultation.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.17</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No AP Fee will be collected in the event that the Online Consultation cannot
                commence or is critically interrupted due to a technical error attributable to the
                Platform. Conrati will not be held liable for any technical or infrastructure
                failures on the part of either the Advice Provider or Advice Receiver. We monitor
                our systems and do our utmost to ensure their technical and functional usability. No
                penalty will be payable by Conrati to either the Advice Provider or Advice Receiver
                in the event of any technical of infrastructure failures attributable to Conrati. In
                this instance the Advice Receiver will be refunded the full amount
              </span>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
            </p>
            <p style={{ marginLeft: '38.25pt', textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.18</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No refunds will generally be made once the Total Amount has been collected from the
                Advice Receiver. Requests for refunds can be made by contacting the Conrati support
                team at{' '}
              </span>
              <a href="mailto:support@conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    support@conrati.com
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                . Where a refund application has been approved, the Advice Receiver will be refunded
                the full amount charged less any applicable transaction costs.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textIndent: '27pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Taxes and Invoicing</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.19</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                An invoice for the AP Fee is generated by the Platform as part of the payment
                process upon successful completion of the Online Consultation. To uphold best
                accounting business practice, Conrati’s generation of invoices and deduction of
                funds on behalf of Advice Providers are always in the relevant Advice Provider’s
                currency. .Invoices and confirmation of payments made can be accessed via user
                accounts.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.20</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The Advice Provider will be solely responsible for 1) determining the necessity of
                or confirming that formal invoices have been issued correctly, 2) determining
                whether the Advice Provider, or Conrati, are required by law to remit any VAT or
                other similar taxes applicable to the Online Consultation to the relevant
                authorities and remitting any such taxes to the appropriate authorities on behalf of
                itself or Conrati, as appropriate and 3) determining whether Conrati is required by
                any law to withhold any amount of the AP Fee and for notifying Conrati of any such
                requirement and indemnifying Conrati for any requirement to pay any withholding
                amount to the appropriate authorities (including penalties and interest), either by
                Conrati, at our sole discretion, offsetting the relevant amount against a future
                payment of AP Fees to the Advice Provider or the Advice Provider reimbursing Conrati
                for the applicable amount.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.21</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The VAT or other similar taxes, as calculated in respect of the AP Fee, included in
                the booking quotation and displayed on the invoices generated by the Platform, will
                be calculated in accordance with{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                rules and assumptions on international tax regulations
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                . These rules and assumptions do not constitute tax advice and Advice Providers
                remain solely responsible for determining any applicable tax or tax reporting
                requirements relating to the AP Fee in consultation with their tax advisors.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.22</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati will have no responsibility whatsoever for determining the necessity of or
                for issuing any formal invoices, or for determining, remitting, or withholding any
                taxes applicable to the AP Fee to the necessary authorities.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.23</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                It remains the responsibility of the Advice Provider to ensure that any invoices
                required in respect of the AP Fee are correct and that the correct amount of VAT or
                other similar taxes have been charged on the AP Fee and are remitted to the
                appropriate authorities. The Advice Providers must inform Conrati within 2 working
                days from receipt of the invoice if any invoice generated by the platform is
                incorrect, or has been incorrectly issued, or the VAT or other similar taxes have
                been incorrectly included or excluded by sending an email to{' '}
              </span>
              <a href="mailto:support@conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    support@conrati.com
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                . Transactions will not be reversed or corrected, nor will any credit notes or new
                or additional invoices be issued by the Platform where an Advice Provider fails to
                notify Conrati within 2 days from the receipt of an invoice that such invoice is
                incorrect, or has been incorrectly issued, or the VAT or other similar taxes have
                been incorrectly included or excluded. For the avoidance of doubt, an invoice will
                be deemed to have been received from the moment it is accessible via the Advice
                Providers’{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>booking history</span>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp;&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.24</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                In the event of an audit of Conrati, Advice Providers agree to promptly cooperate
                with Conrati and provide copies of their tax returns and other documents as may be
                reasonably requested for purposes of such audit.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </em>
            </p>
            <h2
              id="6.2"
              style={{
                marginTop: '2pt',
                marginLeft: '22.5pt',
                textIndent: '-22.5pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '13pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>B.</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>The Conrati Fee</span>
            </h2>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <em>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </em>
            </p>
            <p style={{ textIndent: '22.5pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>General</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.25</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                In consideration for the Conrati Services, we charge Advice Providers a fee (the
                “Conrati Fee”), calculated as a fixed percentage of the AP Fee collected on behalf
                of the Advice Providers. The percentage currently used for calculating the Conrati
                fee is ten percent (10%). The Conrati Fee is deducted from the AP Fee payable to the
                Advice Provider in respect of the Online Consultation.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.26</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                After deducting the applicable Conrati Fee, we will remit the balance of the AP Fee,
                and any VAT or other taxes collected on the Advice Provider’s behalf, to Advice
                Providers via our third-party providers,{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>[</span>
              <a
                href="https://www.mangopay.com/terms/MANGOPAY_Terms-EN.pdf"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    MangoPay Terms in English
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}> , </span>
              <a
                href="https://www.mangopay.com/terms/MANGOPAY_Terms-DE.pdf"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    MangoPay Terms in German
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>]</span>
              <span style={{ fontFamily: 'Roboto' }}>
                , or such other payment methods as may be listed from time to time in these Terms.
                The balance of the AP fee will be remitted to Advice Providers{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                once their KYC documents have been successfully verified by MangoPay to qualify for
                a payout
              </span>
              <span style={{ fontFamily: 'Roboto' }}>. </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textIndent: '27pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Cancellations and No-Shows</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.27</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The Conrati Fee will only be charged where the AP Fee is collected from the Advice
                Receiver as per paragraph 8A above.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textIndent: '27pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Taxes and Invoicing</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.28</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                An invoice for the Conrati Fee is generated by the Platform as part of the payment
                process upon successful completion of the Online Consultation and remittance of the
                AP Fee. To uphold best accounting business practice, Conrati’s generation of the
                invoice and deduction of funds from the Advice Provider will be in the relevant
                Advice Provider’s currency. The invoice will include Euros as a secondary currency
                in the case that Advice Providers are from a non Euro trading country. Invoices and
                confirmation of payments made can be accessed via the Advice Providers’{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>booking history</span>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>6.29</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The AP Fee may be subject to VAT or other similar taxes depending on the laws of the
                Advice Provider’s jurisdiction.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p
              style={{
                marginLeft: '36pt',
                marginBottom: '8pt',
                textIndent: '-36pt',
                lineHeight: '108%',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <h1
              id="7"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>7.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Non-Disclosure of Information
                </span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>7.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati provides multi-functional infrastructure through which the Online
                Consultations between users takes place. We are not required to, nor do we, take any
                responsibility whatsoever for the safeguarding of information or data, including
                sensitive information (such as non-public, confidential or other sensitive
                information), disclosed by any user during Online Consultation facilitated by the
                Platform. As such we are not required to, nor do we, take any responsibility
                whatsoever for ensuring that information and data, including sensitive information,
                disclosed during an Online Consultation is not disclosed by users to anyone else
                after the conclusion of the Online Consultation.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>7.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                By accepting these Terms, users agree to be bound by the provisions of our User{' '}
              </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Code of Conduct</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>
                , and as such, agree to act in a professional manner, agrees to ensure that
                non-public information and data shared during an Online Consultation remains
                non-public and agrees to ensure that sensitive information is protected at all times
                before, during and after Online Consultations. Users who wish to enter into
                formalised non-disclosure agreements prior to the disclosure of information and data
                during an Online Consultation, may do so by uploading, completing and electronically
                signing their non-disclosure agreements at the commencement of the Online
                Consultation.{' '}
              </span>
            </p>
            <h1
              id="8"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>8.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Communications</span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: '38.25pt',
                textIndent: '-20.25pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>8.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                All notices to Conrati intended to have a legal effect must be in writing and
                delivered either (a) in person, (b) by a means evidenced by a delivery receipt, to
                the following address: Siemensstraße 2, 78532 Tuttlingen, Germany, or (c) in writing
                via email to{' '}
              </span>
              <a href="mailto:support@conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    support@conrati.com
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                All such notices are deemed effective upon receipt by Conrati.{' '}
              </span>
            </p>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <h1
              id="9"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>9.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  User Content and Licenses{' '}
                </span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
              </strong>
            </p>
            <p style={{ textIndent: '22.5pt', textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>User Content</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati enables, in its sole discretion, users to create, upload or post content,
                including but not limited to text, photos, video or any other information (“User
                Content”), for purposes of creating and maintaining their User profiles.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users retain all ownership rights in any User Content created, uploaded, posted on,
                or provided to us via, the Conrati Site, Application or Platform, including any
                copyright rights attached to any copyrightable User Content.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                By creating, uploading, posting or providing User Content, users grant us, to the
                extent permitted by applicable law, a non-exclusive, royalty-free, sub-licensable,
                worldwide license to use, copy, reproduce, process, adapt, modify, publish,
                translate, distribute, publicly display of all such User Content for use in
                connection with the Conrati Site, Application or Platform, subject to our{' '}
              </span>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>P</span>
                </u>
              </a>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>rivacy Policy</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>. </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You represent and warrant that you own the User Content created, uploaded or posted
                by you on the Conrati Site, Application or Platform, including any copyright rights
                attached to any copyrightable User Content, or otherwise have the right, power and
                authority to create, upload or post the User Content on the Conrati Site,
                Application or Platform.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You further represent and warrant that you have the right, power and authority to
                grant the licenses specified above and that by creating, uploading, posting or
                providing such User Content you will not violate third-party rights of any kind,
                including but not limited to, Intellectual Property and Privacy Rights.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You also hereby grant each user a non-exclusive license to access your User Content
                through the Conrati Site, Application or Platform and to use such User Content to
                the extent permitted through the normal functionality of the Conrati Site,
                Application or Platform and subject to Our policies, including our{' '}
              </span>
              <a
                href="https://www.conrati.com/legal/code-of-conduct"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Code of Conduct </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>and </span>
              <a
                href="https://www.conrati.com/legal/privacy-policy"
                style={{ textDecoration: 'none' }}
              >
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>Privacy Policy</span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.7</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Notwithstanding the foregoing paragraphs, we will only use or disclose User Content
                you post to any non-public area of the Site to the extent necessary to provide the
                Conrati Services.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.8</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The licenses to User Content granted by you in this Agreement will terminate within
                a commercially reasonable time after you remove or delete your User Content from the
                Site or Application, except that you grant us the irrevocable and perpetual license
                to retain and use, but not publicly display or distribute, server or archival copies
                of all User Content that you have removed or deleted to the extent permitted by
                applicable law.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.9</span>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You may submit comments or ideas about the Site and Site Services, including without
                limitation about how to improve the Site or Site Services (collectively, "Ideas").
                Any Ideas you submit to us will be considered non-confidential and non-proprietary
                to you. By submitting any Ideas, you agree that your disclosure is voluntary,
                gratuitous, unsolicited, and without restriction and will not place us under any
                obligation. You further agree that we are free to use the Ideas without any
                additional compensation to you.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <em>
                  <span style={{ fontFamily: 'Roboto' }}>Software License</span>
                </em>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.10</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Unless stated otherwise, the software required for, or available on or used by, the
                Conrati Site, Application, Platform and Services, is owned by Conrati. The Conrati
                Site, Application, Platform and Services and any user or Conrati content may be
                protected by copyright and trademark laws in the European Union and other countries.
                Conrati, and any licensor or authorizing third-party, are the owners of the Conrati
                Site, Application, Platform and Services and any Conrati content, including the
                intellectual property rights associated with it. The Conrati logos and names are
                trademarks of Conrati and may be registered in certain jurisdictions.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                All other product names, company names, marks, logos, and symbols on the Site or
                Site Services may be the trademarks of their respective owners.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.11</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Subject to your compliance with these Terms, Conrati grants you, a limited,
                non-exclusive, non-sublicensable, revocable, non-transferrable license to access and
                use the Conrati Site, Application and Platform solely in connection with your use of
                the Conrati Services.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.12</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You must not access (or attempt to access) the Conrati Site by any means other than
                the interface provided, and you will not use information from the Conrati Site for
                any purposes other than the purposes for which it was made available.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.13</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You agree not to use the Conrati Site for offering any goods or services other than
                Advice Provider Services as permitted by this Agreement.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp; </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>9.14</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You must not attempt to reverse engineer, modify, adapt, translate, prepare
                derivative works from, decompile, attempt to interfere with the operation of, or
                otherwise attempt to derive source code from any part of the Site, Application or
                Services unless expressly permitted by applicable law.
              </span>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;&nbsp;&nbsp; </span>
            </p>
            <h1
              id="10"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>10.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Disclaimer </span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users use the Site, Application, Platform and Conrati Services at their own risk.
                The Conrati Site, Application, Platform and Services is provided “as is”, without
                any warranty of any kind, either express or implied.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users understand and acknowledge that Conrati will not be held liable for any loss
                or damage caused by any reliance on the skills, information or advice accessed or
                provided during Online Consultations or otherwise, through the Conrati Site,
                Application, Platform and Services. Conrati is not responsible for, and expressly
                disclaims, any and all liability related to any skills, information or advice
                provided or accessed during Online Consultations or otherwise, through the Conrati
                Site, Application, Platform and Services.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati cannot be held liable for any advice provided by any Advice Provider during
                Online Consultations or otherwise, and you agree to free Conrati of any liability
                whatsoever as far as the advice provided and any consequences resulting from it.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users understand and acknowledge that Conrati does not have an obligation to conduct
                background checks on any Users, but may, in its sole discretion, conduct such
                background checks, to the extent permitted by applicable law.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>10.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>Conrati makes no warranty: </span>
            </p>
            <p style={{ marginLeft: '63pt', textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginLeft: '63pt',
                textIndent: '-36pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>10.5.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                that the Conrati Site, Application, Platform and Services will be available on an
                uninterrupted, secure, or error-free basis;{' '}
              </span>
            </p>
            <p
              style={{
                marginLeft: '63pt',
                textIndent: '-36pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>10.5.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                that any advice or services received via the Conrati Site, Application, Platform and
                Services is correct, accurate, complete, reliable, suitable, usable and meets Users’
                requirements; and
              </span>
            </p>
            <p
              style={{
                marginLeft: '63pt',
                textIndent: '-36pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto' }}>10.5.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                regarding the quality of any advice or services received via the Conrati Site,
                Application, Platform and Services.
              </span>
            </p>
            <h1
              id="11"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>11.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Limitation of Liability
                </span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>11.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users acknowledge and agree that, to the maximum extent permitted by law, the entire
                risk arising out of your access to and use of the Conrati Site, Application,
                Platform, and Services, including Online Consultations or any other contact with
                other Users, remains with You.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>11.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Except for any liability in relation to the breach of any implied condition,
                warranty or guarantee in this agreement, the exclusion of which would contravene the
                law or cause any part of this agreement to be void (“the Excluded Conditions”), and
                to the extent permitted by law, Conrati disclaims all liability for any loss or
                damage, whether actual, special, direct, indirect or consequential, of any kind,
                including but not limited to loss of income, revenue or profits, loss of data, loss
                of goodwill or reputation, loss of production or interruption of services, arising
                out of or in connection with any Online Consultations or other transactions between
                Advice Receivers and Advice Providers.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>11.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Except for any liability in relation to the breach of any Excluded Condition, and to
                the extent permitted by law, Conrati disclaims all liability for any loss or damage,
                whether actual, special, direct, indirect or consequential, of any kind, including
                but not limited to loss of income, revenue or profits, loss of data, loss of
                goodwill or reputation, loss of production or interruption of services, arising out
                of or in connection with any transactions between Users and any third-party service
                providers used as part of forming part of the Conrati Site, Application, Platform,
                and Services.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>11.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Except for instances of gross negligence, willful misconduct or any liability in
                relation to the breach of Excluded Conditions, Conrati’s liability arising out of or
                in connection with these terms or from the use, or inability to use, by a User of
                the Conrati Site, Application, Platform, and Services shall not exceed the amount
                the User has paid in the twelve (12) month period preceding the event or incident
                giving rise to the liability. Certain jurisdictions do not allow the exclusion or
                limitation of liability for consequential or incidental damages; the limitations of
                damages set forth above may therefore not apply to all Users.{' '}
              </span>
            </p>
            <h1
              id="12"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>12.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Indemnification</span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>12.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Users agree to release, indemnify, and hold Conrati and its affiliates and
                subsidiaries, and their officers, directors, employees and agents, harmless from and
                against any claims, liabilities, damages, losses, and expenses, including, without
                limitation, reasonable legal and accounting fees, arising out of or in any way
                connected with 1) access to or use of the Conrati Site, Application, Platform, and
                Services, 2) breach of these Terms, 3) the improper use of the Conrati Site,
                Application, Platform, and Services, 4) any interaction between Users and 5) Users’
                reliance on any information exchanged via the Conrati Site, Application, Platform,
                and Services.{' '}
              </span>
            </p>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <h1
              id="13"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>13.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>Termination</span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>13.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Either Users or Conrati may terminate Users’ accounts or this Agreement at any time
                for any reason.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>13.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Third Party Services are subject to Third Party Service provider terms and
                conditions.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>13.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Sections 8 (Pay), 13 (Limitation of Liability), 14 (Indemnification) and 16 (Legal
                Jurisdiction and Dispute Resolution) and any other terms which by their nature
                should continue to apply, will survive any termination or expiration of this
                Agreement.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>13.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                If a User account or this Agreement is terminated for any reason, the user may not
                without Conrati’s consent (in its absolute discretion) create any further Conrati
                accounts.
              </span>
            </p>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <h1
              id="14"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>14.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>
                  Legal Jurisdiction and Dispute Resolution{' '}
                </span>
              </em>
            </h1>
            <p style={{ fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>14.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                To the extent permitted by law, this Agreement and the provision of our Services
                shall be governed by and construed in accordance with German law and any dispute
                arising out of this Agreement and our Services shall exclusively be submitted to the
                competent courts in Berlin, Germany.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>14.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                This choice of law does not impact users’ rights as consumers according to the
                consumer protection regulations of their countries of residence. If a user is acting
                as a consumer, the user agrees to submit to the non-exclusive jurisdiction of the
                courts in Berlin, Germany. Judicial proceedings that you are able to bring against
                us arising from or in connection with this Agreement may only be brought in a court
                located in Germany or a court with jurisdiction in your place of residence. If we
                wish to enforce any of our rights against you as a consumer, we may do so only in
                the courts of the jurisdiction in which you are a resident.
              </span>
            </p>
            <h1
              id="15"
              style={{
                marginTop: '12pt',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'avoid',
                lineHeight: '108%',
                fontSize: '14pt',
              }}
            >
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>15.</span>
              </em>
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </span>
              <em>
                <span style={{ fontFamily: 'Roboto', fontWeight: 'normal' }}>General</span>
              </em>
            </h1>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.1</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Except as they may be supplemented by additional terms and conditions, policies,
                guidelines or standards, these Terms constitute the entire Agreement between Conrati
                and users pertaining to the subject matter hereof, and supersede any and all prior
                oral or written understandings or agreements between Conrati and users in relation
                to the access to and use of the Conrati Platform, Site, Application and Services.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.2</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                No joint venture, partnership, employment, or agency relationship exists between
                users and Conrati as a result of this Agreement or the use of the Conrati Site,
                Application, Platform, and Services.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.3</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                This Agreement does not and is not intended to confer any rights or remedies upon
                any person other than the parties to this Agreement.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.4</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The provisions of this Agreement are severable. If any provision of this Agreement
                is held to be invalid or unenforceable, such provision may be removed and will not
                affect the validity and enforceability of the remaining provisions.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.5</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                Conrati’s failure to enforce any right or provision in this Agreement will not
                constitute a waiver of a future enforcement of such right or provision. The waiver
                of any such right or provision will be effective only if in writing and signed by a
                duly authorized representative of Conrati. Except as expressly set forth in this
                Agreement, the exercise by either party of any of its remedies under this Agreement
                will be without prejudice to its other remedies under this Agreement or otherwise
                permitted under law.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.6</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                You may not assign, transfer or delegate this Agreement and your rights and
                obligations hereunder without Conrati’s prior written consent. We may without
                restriction assign, transfer or delegate this Agreement and any rights and
                obligations hereunder, at our sole discretion, with 30 days prior notice. Your right
                to terminate this Agreement at any time remains unaffected.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.7</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                If you reside in the EU you can access the European Commission’s online dispute
                resolution platform here: http://ec.europa.eu/consumers/odr. Please note that
                Conrati is not committed nor obliged to use an alternative dispute resolution entity
                to resolve disputes with consumers.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>15.8</span>
              <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
              <span style={{ fontFamily: 'Roboto' }}>
                The English language version of the Terms of Service will be controlling in all
                respects and will prevail in case of any inconsistencies with translated versions,
                if any.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>II. Customer Information</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <ol type={1} style={{ margin: '0pt', paddingLeft: '0pt' }}>
              <li
                style={{
                  marginLeft: '14.51pt',
                  textAlign: 'justify',
                  paddingLeft: '3.34pt',
                  fontFamily: 'Roboto',
                  fontSize: '11pt',
                  fontWeight: 'bold',
                }}
              >
                Identity of the Provider
              </li>
            </ol>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>Conrati GmbH &amp; Co KG</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>Siemensstraße 2,</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>78532 Tuttlingen,</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>Germany</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>E-Mail: </span>
              <a href="mailto:support@conrati.com" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#1155cc' }}>
                    support@conrati.com
                  </span>
                </u>
              </a>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>Alternative dispute resolution:</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                The European Commission provides a platform for the out-of-court resolution of
                disputes (ODR platform), which can be viewed under
              </span>
              <a href="https://ec.europa.eu/odr" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#0000ff' }}>&nbsp;</span>
                </u>
              </a>
              <a href="https://ec.europa.eu/odr" style={{ textDecoration: 'none' }}>
                <u>
                  <span style={{ fontFamily: 'Roboto', color: '#0000ff' }}>
                    https://ec.europa.eu/odr
                  </span>
                </u>
              </a>
              <span style={{ fontFamily: 'Roboto' }}>.</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ marginBottom: '8pt', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                We are not willing to enter into dispute resolution proceedings before the consumer
                arbitration board.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>
                  2. Information regarding the conclusion of the contract
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                By placing our services in question on our website, we provide you with a binding
                offer to conclude a agreement subject to the conditions specified in the
                aforementioned terms and conditions.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                The conclusion of the service agreement takes place via the website as follows:
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                The services agreement can be entered by registering for our services. You can
                select “REGISTER” using the appropriate buttons on the navigation bar.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                After calling up the “REGISTER” page and entering the required personal data you can
                still make changes there at any time. By selecting the button “NEXT” you register
                for our services.{' '}
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                The conclusion of a contract with an Advice Provider takes place via the website as
                follows:
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                (5) You are not bound by your enquiries regarding the creation of an offer that have
                been conveyed to us. We supply you with a textual and binding offer (e.g. via
                e-mail), which you can accept within a period of 5 days.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                (6) The execution of the contract and the sending of all the details necessitated by
                the conclusion of the contract take place via e-mail, in a partially-automated
                manner. Consequently, you have to ensure that the e-mail address that you have
                deposited with us is the correct one, and that the receipt of the respective e-mails
                is guaranteed. In particular, you have to ensure that the respective e-mails are not
                blocked by a SPAM filter.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>
                  3. Contractual language, saving the text of the contract
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>3.1 Contract language shall be English.</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                3.2 The complete text of the contract is not saved with us. Before the agreement is
                concluded, the contract data can be printed out or electronically saved using the
                browser’s print function. After the registration is received by us, the registration
                data and the standard business terms are re-sent to you via e-mail.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                3.3 You will be sent all contractual information within the framework of a binding
                offer in written form, via{' '}
              </span>
              <span style={{ fontFamily: 'Roboto' }}>e</span>
              <span style={{ fontFamily: 'Roboto' }}>
                -mail for example, for quotation requests outside of the online shopping basket
                system, which can be printed out or saved electronically in a secure manner.
              </span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>4. </span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Requirements for</span>
              </strong>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}> product or service use</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify', fontSize: '11pt' }}>
              <span style={{ fontFamily: 'Roboto' }}>
                To use our services, you need a browser with HTML5 support and a stable internet
                connection. Without these conditions the use of the service is excluded.
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Termsandcondtion;
