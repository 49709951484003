import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './style.module.scss';
import switch_ar from '../../Common/assets/switch.svg';

const AP = (props) => {
  const history = useHistory();

  const onAccountSwitch = (event, path) => {
    event.preventDefault();

    const { pathname, search } = window.location;
    const encodeURL = encodeURIComponent(`${pathname}${search}`);

    history.push({
      pathname: path,
      search: `?redirect=${encodeURL}`,
    });
  };

  return (
    <>
      <div className={styles.nmitemgs}>
        <p className={styles.menuheader}>Advice Provider Menu</p>
      </div>
      <div className={styles.nitemgn}>
        <div className={styles.nmitem}>
          <Link to="/myprofile">My public profile</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/availability">Availability</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/mycalendar">My calendar</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/dashboard">Upcoming Sessions</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/bookinghistory">Booking History</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/myaccount">My account</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/payout">Payout</Link>
        </div>
        <div className={styles.nmitem}>
          <Link to="/help/faq">FAQs</Link>
        </div>
        <div className={styles.nmitem}>
          <a href="https://blog.conrati.com" target="_blank" rel="noreferrer noopener">
            Blog
          </a>
        </div>
      </div>
      <div className={styles.nmitemg}>
        <button type="button" onClick={(e) => onAccountSwitch(e, '/myaccount/switch')}>
          <div className={styles.switch}>
            <img src={switch_ar} alt="Switch Account" width="22px" />
            <p>Switch Account AR</p>
          </div>
        </button>
      </div>
      <div className={styles.signout}>
        <button className={styles.signoutbtn} onClick={props.handleOnLogout}>
          Sign out
        </button>
      </div>
    </>
  );
};

export default AP;
