import React from 'react';
import style from './style.module.scss';

function InfoFileHeader() {
  return (
    <div className={style.file}>
      <p>File</p>
    </div>
  );
}

export default InfoFileHeader;
