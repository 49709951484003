import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import styles from './KycDetails.module.css';
import { apiurl } from '../../../utils/index';
import EditInput from '../../Common/EditInput/EditInput';
import EditDate from '../../Common/EditDate/EditDate';
import EditSelect from '../../Common/EditSelect/EditSelect';

class Uboupdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      typeOfBusiness: 0,
      address: '',
      city: '',
      postalCode: '',
      birthplaceCity: '',
      birthplaceCountry: '',
      nationality: '',
      selectactive: false,
      region: '',
      country: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelActive = () => {
    this.setState({ selectactive: true });
  };

  componentDidMount() {
    const { firstName, lastName, birthday, nationality, address, birthplace } =
      this.props.updateData;

    this.setState({
      firstName,
      lastName,
      dateOfBirth: moment.unix(birthday).format('YYYY/MM/DD'),
      address: address.addressLine1,
      city: address.city,
      postalCode: address.postalCode,
      birthplaceCity: birthplace.city,
      birthplaceCountry: birthplace.country,
      nationality,
      selectactive: false,
      region: '',
      country: address.country,
    });
  }

  onSelectChange = (e, name) => {
    this.setState({ [name]: e.value });
  };

  onSubmitUboDetails = (e, type) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      address,
      city,
      region,
      postalCode,
      country,
      nationality,
      dateOfBirth,
      birthplaceCity,
      birthplaceCountry,
    } = this.state;

    if (
      firstName &&
      lastName &&
      address &&
      city &&
      postalCode &&
      country &&
      nationality &&
      dateOfBirth &&
      birthplaceCity &&
      birthplaceCountry
    ) {
      this.setState({ uploading: true });

      const data = {
        first_name: firstName,
        last_name: lastName,
        address,
        city,
        region,
        postal_code: postalCode,
        country,
        nationality,
        date_of_birth: dateOfBirth,
        birthplace_city: birthplaceCity,
        birthplace_country: birthplaceCountry,
        submit: false,
        ubodeclartion_id: this.props.updateData.declaration_id,
        ubouser_id: this.props.updateData.id,
      };

      if (type === 'submit') {
        data.submit = true;
      }

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Beare ${this.props.user.userToken}`,
      };

      axios
        .post(`${apiurl}/v1/payment/kyc/ubo/update`, data, { headers })
        .then((result) => {
          if (result.data.success) {
            if (type === 'add') {
              this.setState({
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                typeOfBusiness: 0,
                address: '',
                city: '',
                postalCode: '',
                birthplaceCity: '',
                birthplaceCountry: '',
                nationality: '',
                selectactive: false,
                region: '',
                country: '',
              });
            }

            this.setState({ uploading: false });

            this.props.closeModal();

            this.props.onFetchAllUboDocs({ userToken: this.props.user.userToken });

            return toast.warn(result.data.message, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          throw new Error(result.data.message);
        })
        .catch((err) => {
          this.setState({ uploading: false });
          return toast.warn(err.message || 'Failed in adding ubo data', {
            position: 'top-center',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } else {
      alert('Please fill all required fields!');
    }
  };

  render() {
    const countries = this.props.allcountries.map((country) => ({
      value: country.countryCode,
      label: country.countryName,
    }));

    const selectedNationality =
      this.state.nationality &&
      this.props.allcountries.find((country) => {
        return country.countryCode === this.state.nationality;
      });

    const selectedCountry =
      this.state.country &&
      this.props.allcountries.find((country) => {
        return country.countryCode === this.state.country;
      });

    const selectedBirthPlaceCountry =
      this.state.birthplaceCountry &&
      this.props.allcountries.find((country) => {
        return country.countryCode === this.state.birthplaceCountry;
      });

    return (
      <div className={styles.selectpop}>
        <p className={styles.alisubikbm}>
          Please upload the details of an individual possessing 25% or more of the shares or voting
          rights of the company. If no individual owns at least 25% shares or voting rights, then
          provide the details of a legal representative of the company instead.
        </p>

        {this.state.firstName && (
          <div className={styles.form}>
            <div className={styles.left}>
              <EditInput
                htmlFor="First Name"
                label="First Name"
                value={this.state.firstName}
                placeholder="First Name"
                onChange={this.onChange}
                name="firstName"
                required
              />
              <EditInput
                htmlFor="Last Name"
                label="Last Name"
                value={this.state.lastName}
                placeholder="Last Name"
                onChange={this.onChange}
                name="lastName"
                required
              />

              <EditDate
                label="Date of Birth"
                date={this.state.dateOfBirth}
                onChange={this.onChange}
                name="dateOfBirth"
                required
              />

              <EditInput
                htmlFor="Birthplace City"
                label="Birthplace City"
                value={this.state.birthplaceCity}
                placeholder="Birthplace City"
                onChange={this.onChange}
                name="birthplaceCity"
                required
              />
              <EditSelect
                label="Birthplace Country"
                options={countries}
                selected={
                  selectedBirthPlaceCountry
                    ? {
                        value: selectedBirthPlaceCountry.countryCode,
                        label: selectedBirthPlaceCountry.countryName,
                      }
                    : null
                }
                placeholder="Select Birthplace Country"
                onChange={(e) => this.onSelectChange(e, 'birthplaceCountry')}
                onActive={this.onSelActive}
                required
              />
            </div>
            <div className={styles.left}>
              <EditSelect
                label="Nationality"
                options={countries}
                placeholder="Select Nationality"
                selected={
                  selectedNationality
                    ? {
                        value: selectedNationality.countryCode,
                        label: selectedNationality.countryName,
                      }
                    : null
                }
                onChange={(e) => this.onSelectChange(e, 'nationality')}
                onActive={this.onSelActive}
                required
              />
              <EditSelect
                label="Country of residence"
                options={countries}
                placeholder="Select Country"
                selected={
                  selectedCountry
                    ? { value: selectedCountry.countryCode, label: selectedCountry.countryName }
                    : null
                }
                onChange={(e) => this.onSelectChange(e, 'country')}
                onActive={this.onSelActive}
                required
              />
              <EditInput
                htmlFor="Street - Address"
                label="Address"
                value={this.state.address}
                placeholder="Address"
                onChange={this.onChange}
                name="address"
                required
              />
              <EditInput
                htmlFor="City - Address"
                label=" City - Address"
                value={this.state.city}
                placeholder="City"
                onChange={this.onChange}
                name="city"
                required
              />
              {this.state.country === 'US' ||
              this.state.country === 'CA' ||
              this.state.country === 'MX' ? (
                <EditInput
                  htmlFor="Region"
                  label="Region"
                  value={this.state.region}
                  placeholder="Region"
                  onChange={this.onChange}
                  name="region"
                  required
                />
              ) : null}
              <EditInput
                htmlFor="Postal Code - Address"
                label="Postal Code - Address"
                value={this.state.postalCode}
                placeholder="Postal Code"
                onChange={this.onChange}
                name="postalCode"
                required
              />
            </div>
          </div>
        )}

        <div className={styles.buttons}>
          <button className={styles.btnc} onClick={this.props.closeModal}>
            CANCEL
          </button>
          <div className={styles.mubtn}>
            <button
              className={styles.btn}
              disabled={!!this.state.uploading}
              onClick={(e) => this.onSubmitUboDetails(e, 'add')}
            >
              {this.state.uploading ? 'UPDATING SHARE HOLDER' : 'UPDATE SHARE HOLDER'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Uboupdate;
