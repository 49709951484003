/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.css';
import globalProptypes from '../../../config/proptype';
import { handleArOnChange } from '../../../actions/userAction';
import EditSelect from '../../Common/EditSelect/EditSelect';
import businessIcon from '../../Common/assets/businsess.svg';
import BusinessForm from './BusinessForm';
import SoleTraderForm from './SoleTraderForm';
import enums from '../../../config/enum';
import info from '../../Common/assets/info.svg';
import ConsumerForm from './ConsumerForm';

function BusinessDetails(props) {
  const { onChange, businessDetails, user } = props;
  const { typeOfBusiness } = businessDetails;
  const { isEmailVerified } = user;

  const typeOfUser = [
    {
      value: 3,
      label: 'Individual / Consumer',
    },
    {
      value: 0,
      label: 'Freelancer / Soletrader',
    },
    {
      value: 1,
      label: 'Business',
    },
  ];

  const selectedTypeOfUser =
    typeOfBusiness >= 0
      ? typeOfUser.filter((arr) => {
          return arr.value === typeOfBusiness;
        })
      : null;

  return (
    <div className={styles.group}>
      <ReactTooltip
        id="actip"
        place="top"
        type="warning"
        effect="solid"
        className={styles.toolttip}
      />
      <div className={styles.ahead}>
        <img className={styles.aheadimg} src={businessIcon} alt="Account Type" />
        <div>
          <p className={styles.atitle}>Account Type</p>
          <div style={{ display: 'flex' }}>
            <p className={styles.asub}>
              Select whether registering as a business, sole trader or individual (consumer).
            </p>
            <img
              data-for="actip"
              data-tip="This information is required to automatically generate invoices containing accurate VAT rates."
              src={info}
              width="15px"
              height="15px"
              alt="Info"
              style={{ marginRight: '10px' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.formb}>
        <div className={styles.left}>
          <EditSelect
            label="Type of User"
            selected={selectedTypeOfUser}
            options={typeOfUser}
            placeholder="Type of User"
            onActive={() => false}
            onChange={(e) => {
              onChange({
                name: 'business',
                value: {
                  typeOfBusiness: e.value,
                },
              });
            }}
            isDisabled={!isEmailVerified}
          />
        </div>
      </div>

      {typeOfBusiness === enums.typeOfBusinessText['Freelancer / Soletrader'] && (
        <>
          <div className={styles.line} />
          <SoleTraderForm {...props} />
        </>
      )}
      {typeOfBusiness === enums.typeOfBusinessText.Business && (
        <>
          <div className={styles.line} />
          <BusinessForm {...props} />
        </>
      )}
      {typeOfBusiness === enums.typeOfBusinessText['Individual / Consumer'] && (
        <>
          <div className={styles.line} />
          <ConsumerForm {...props} />
        </>
      )}
    </div>
  );
}

BusinessDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  businessDetails: globalProptypes.businessDetailsState,
  user: globalProptypes.user.isRequired,
};

BusinessDetails.defaultProps = {
  businessDetails: {
    businessId: 0,
    businessName: '',
    businessRegistrationNo: '',
    typeOfBusiness: null,
    vatNo: '',
    userId: null,
    address: '',
    city: '',
    region: '',
    countryId: 0,
    postalCode: '',
    countryCode: '',
    isSmallEnterprise: false,
  },
};

const mapStateToProps = (state) => {
  const countriesMasterdata = state.masterdata.masterData.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
    code: country.countryCode,
    currencyCode: country.currencyCode,
  }));

  const country =
    state.userState.user.countryId > 0 ? state.userState.user.country : state.userState.country;

  const selectedCountry = {
    value: country.countryId,
    label: country.countryName,
  };

  return {
    user: state.userState.user,
    countriesMasterdata,
    selectedCountry,
    businessDetails: state.userState.user.business ? state.userState.user.business : {},
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onChange: handleArOnChange,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(BusinessDetails);
