import EventEmiiter from 'eventemitter2';

const emitter = new EventEmiiter();

function sendEvent(eventName, data) {
  emitter.emit(eventName, data);
}

function closeAllEvents() {
  emitter.removeAllListeners();
}

export { sendEvent, emitter, closeAllEvents };
