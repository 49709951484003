import PropTypes from 'prop-types';

export default {
  userState: PropTypes.shape({
    user: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    oauthData: PropTypes.object,
    upcomingSessions: PropTypes.array,
    buisnessDetails: PropTypes.object,
    accountCompleted: PropTypes.number,
    stepsCompleted: PropTypes.bool,
    onboardingStepsCompleted: PropTypes.number,
    kycConfirm: PropTypes.bool,
    analytics: PropTypes.object,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    country: PropTypes.shape({
      countryName: PropTypes.string,
      countryCodeIso: PropTypes.string,
      countryId: PropTypes.number,
    }),
    mobileCode: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
      currencyName: PropTypes.string,
      currencySymbol: PropTypes.string,
      currencyId: PropTypes.number,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
  businessDetailsState: PropTypes.shape({
    businessName: PropTypes.string,
    businessRegistrationNo: PropTypes.string,
    typeOfBusiness: PropTypes.number,
    vatNo: PropTypes.string,
    userId: PropTypes.number,
    address: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    countryId: PropTypes.number,
    postalCode: PropTypes.string,
    countryCode: PropTypes.string,
  }),
  booking: PropTypes.shape({
    bookingId: PropTypes.string,
    arId: PropTypes.number,
    apId: PropTypes.number,
    invoiceData: PropTypes.shape({}),
    from: PropTypes.string,
    to: PropTypes.string,
    title: PropTypes.string,
    topicDescription: PropTypes.string,
    dateOfBooking: PropTypes.string,
    apUserData: PropTypes.shape({}),
    arUserData: PropTypes.shape({}),
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    timeZone: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.shape({
      countryName: PropTypes.string,
      countryCodeIso: PropTypes.string,
      countryId: PropTypes.number,
    }),
    mobileCode: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
      currencyName: PropTypes.string,
      currencySymbol: PropTypes.string,
      currencyId: PropTypes.number,
    }),
    dateOfBirth: PropTypes.string,
    personalTaxNo: PropTypes.string,
    mobileNumber: PropTypes.string,
    personalDetailsCompleted: PropTypes.bool,
    primaryImg: PropTypes.string,
    countryId: PropTypes.number,
    currencyId: PropTypes.number,
  }),
  ongoingBooking: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    apUserData: PropTypes.object,
    topicTitle: PropTypes.string,
    topicDescription: PropTypes.string,
  }),
};
