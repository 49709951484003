/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoChatHeader from '../Chat';
import InfoFileHeader from '../File';
import InfoSessionHeader from '../Info';
import SessionHeader from '../SessionHeader';
import VideoCall from '../VideoCall';
import style from './style.module.scss';
import globalProptypes from '../../../config/proptype/index';
import { sendEvent } from '../../utils/event';
import { Track } from '@conrati/tracking';

const activeActionBtns = {
  chat: 1,
  file: 2,
  info: 3,
};

class SessionCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAction: activeActionBtns.info,
    };
  }

  componentDidMount() {
    const { sessionId, bookingData, userData } = this.props;

    Track('session/both-joined-session', {
        sessionId,
        bookingId: bookingData.bookingId,
        userId: userData.userId,
        apUserData: {
            userId: bookingData.apId,
            email: bookingData.apUserData.email,
        },
        arUserData: {
            userId: bookingData.arId,
            email: bookingData.arUserData.email,
        },
    });
  };

  handleOnActionBtnChange = (actionBtn) => {
    this.setState({
      activeAction: actionBtn,
    });

    sendEvent('action-btn-change', actionBtn);
  };

  render() {
    const { activeAction } = this.state;
    const { bookingData, userData, msgStack } = this.props;

    return (
      <div className={style.sessionCall}>
        <SessionHeader onActionBtnChange={this.handleOnActionBtnChange} />
        <div className={style.mainLayout}>
          <div className={style.video}>
            <VideoCall bookingData={bookingData} userData={userData} />
          </div>
          <div className={style.actionLayout}>
            {activeAction === activeActionBtns.info && (
              <InfoSessionHeader bookingData={bookingData} />
            )}
            {activeAction === activeActionBtns.chat && (
              <InfoChatHeader bookingData={bookingData} userData={userData} msgStack={msgStack} />
            )}
            {activeAction === activeActionBtns.file && <InfoFileHeader bookingData={bookingData} />}
          </div>
        </div>
      </div>
    );
  }
}

SessionCall.propTypes = {
  bookingData: PropTypes.shape({}).isRequired,
  userData: globalProptypes.user.isRequired,
  msgStack: PropTypes.any.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default SessionCall;
