import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import LargeWarning from '../../../Common/LargeWarning/LargeWarning';
import notificationIcon from '../../../Common/assets/notificationsuccess.svg';
import Topics from './Topics';
import Education from './Education';
import Experience from './Experience';
import ShareProfile from '../ShareProfile/ShareProfile';
import globalProptype from '../../../../config/proptype';

const enumsActiveStep = {
  topics: 1,
  education: 2,
  experience: 3,
  share: 4,
};

class ImproveProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      education: [{ field: '', university: '', from: '', to: '' }],
      topics: [{ title: '', topicDescription: '' }],
      experience: [{ company: '', title: '', from: '', to: '' }],
    };
  }

  onClickBtn = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  onClickIndicator = (activeStep) => {
    this.setState((prevState) => {
      if (activeStep !== prevState.activeStep) {
        return {
          activeStep,
        };
      }

      return true;
    });
  };

  onProfileUpdate = (data) => {
    const [property, dataArray] = Object.entries(data)[0];

    this.setState((prevState) => {
      return { ...prevState, [property]: dataArray };
    });

    this.props.onUpdateProfile(data);
  };

  render() {
    const { activeStep, education, experience, topics } = this.state;
    const { user, history, onUpdateProfile } = this.props;
    return (
      <div className={styles.shareprofile}>
        <LargeWarning
          hideclose
          icon={notificationIcon}
          title="You are bookable! Now strengthen your profile!"
          body="We strongly recommend that you add the following details to your profile to increase your chance of bookings."
        />

        <div className={styles.editSection}>
          {activeStep === enumsActiveStep.topics && (
            <Topics topics={topics} onUpdateProfile={this.onProfileUpdate} />
          )}
          {activeStep === enumsActiveStep.education && (
            <Education education={education} onUpdateProfile={this.onProfileUpdate} />
          )}
          {activeStep === enumsActiveStep.experience && (
            <Experience experience={experience} onUpdateProfile={this.onProfileUpdate} />
          )}
          {activeStep === enumsActiveStep.share && <ShareProfile user={user} history={history} />}
        </div>

        {activeStep !== enumsActiveStep.share && (
          <>
            <div className={styles.indicator}>
              <div
                className={activeStep === enumsActiveStep.topics ? styles.dotBorder : styles.dot}
                onClick={() => this.onClickIndicator(enumsActiveStep.topics)}
                onKeyPress={() => true}
                role="button"
                tabIndex="0"
                aria-label="slider"
              />
              <div
                className={activeStep === enumsActiveStep.education ? styles.dotBorder : styles.dot}
                onClick={() => this.onClickIndicator(enumsActiveStep.education)}
                onKeyPress={() => true}
                role="button"
                tabIndex="0"
                aria-label="slider"
              />
              <div
                className={
                  activeStep === enumsActiveStep.experience ? styles.dotBorder : styles.dot
                }
                onClick={() => this.onClickIndicator(enumsActiveStep.experience)}
                onKeyPress={() => true}
                role="button"
                tabIndex="0"
                aria-label="slider"
              />
              <div
                className={activeStep === enumsActiveStep.share ? styles.dotBorder : styles.dot}
                onClick={() => this.onClickIndicator(enumsActiveStep.share)}
                onKeyPress={() => true}
                role="button"
                tabIndex="0"
                aria-label="slider"
              />
            </div>

            <div className={styles.button}>
              <button type="button" onClick={() => this.onClickBtn()} className={styles.acbtn}>
                NEXT
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

ImproveProfile.propTypes = {
  user: PropTypes.shape({
    primaryImg: PropTypes.string,
    userToken: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    headline: PropTypes.string,
    seoImage: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  history: globalProptype.history.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
};

export default ImproveProfile;
