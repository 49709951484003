import * as React from 'react';
import searchSymbol from '../../assets/img/search-symbol.png';

import styles from './Search.module.scss';

export default function Search(): JSX.Element {
	return (
		<div className={styles.searchField}>
			<div className={styles.icon}>
				<img src={searchSymbol} alt="Search" />
			</div>
			<input
				type="search"
				placeholder="Try 'ADHD'"
				data-testid="quiz-search-input"
			/>
			<button className={styles.button}>Search</button>
		</div>
	);
}
