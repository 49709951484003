/* eslint-disable import/prefer-default-export */
/**
 * Verify whether browser is mobile or not
 * @param {string} useragent useragent.
 * @returns {boolean} Returns true or false
 */
const checkIsMobileBrowser = (useragent) => {
  const regex = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/;
  if (regex.test(useragent)) {
    return true;
  }
  return false;
};

export { checkIsMobileBrowser };
