import React from 'react';
import styles from './WorldOfTherapists.module.scss';

type WorldOfTherapistItemProps = {
	img: string;
	title: string;
	subTitle: string;
};

export default function WorldOfTherapistItem(props: WorldOfTherapistItemProps): JSX.Element {
	return (
		<div className={styles.worldOfTherapistItem}>
			<div className={styles.worldOfTherapistItemImg}>
				<img
					data-testid="quiz-world-therapist-item-img"
					src={props.img}
					alt="Therapist item"
				/>
			</div>
			<div
				data-testid="quiz-world-therapist-item-title"
				className={styles.worldOfTherapistItemText}
			>
				{props.title}
			</div>
			<div
				data-testid="quiz-world-therapist-item-sub-title"
				className={styles.worldOfTherapistItemDesc}
			>
				{props.subTitle}
			</div>
		</div>
	);
}
