import freeImg from './images/benfefits/free.svg';
import therapyImg from './images/benfefits/therapy.svg';
import findImg from './images/benfefits/find.svg';
import scheduldeImg from './images/benfefits/schedulde.svg';
import payImg from './images/benfefits/pay.svg';
import sliderUserImage1 from './images/slider/user1.png';
import sliderUserImage12x from './images/slider/user1@2x.png';

const sliderUserList = [
  {
    id: 1,
    img: sliderUserImage1,
    img2x: sliderUserImage12x,
    link: '/davidarnoux2',
  },
];

const themeTitle = 'Growth Hacking';
const buttonText = 'Find a growth hacker';
const initalTransformSize = -20;
const benifitTitle = 'A whole world of growth hackers at your fingertips';
const benefitsList = [
  {
    id: 1,
    img: freeImg,
    title: 'First-time free sessions',
    subTitle: 'Many consultants offer 30 minute bookings for free to familiarise yourself',
  },
  {
    id: 2,
    img: therapyImg,
    title: 'Get growth hacking advice from home',
    subTitle: 'Video conference technology allows for calls from the comfort/safety of your home',
  },
  {
    id: 3,
    img: findImg,
    title: 'Find and compare growth hackers',
    subTitle: 'Growth hackers across different areas of specialisation and price',
  },
  {
    id: 4,
    img: scheduldeImg,
    title: 'Schedule an online booking instantly',
    subTitle: 'View growth hackers availability and schedule your booking',
  },
  {
    id: 5,
    img: payImg,
    title: 'Pay only after a successful consultation',
    subTitle: 'Only pay on the basis that your consultation was successful',
  },
];
const testominals = [
  {
    id: 1,
    name: 'Kirsten Hough',
    comment:
      'Chatting to David opened a whole new perspective on how I should be focusing on growth for my startup. Having now adopted a growth hacking methodology, I have weekly bookings to keep my process of growth hacking in check.',
    star: 5,
  },
  {
    id: 2,
    name: 'Henry Navarro',
    comment:
      'I was able to obtain step by step guidance on how to reverse engineer my competitors and their successful campaigns. Since then I have been able to capatilise on their strengths.',
    star: 5,
  },
  {
    id: 3,
    name: 'Fletcher Moore',
    comment:
      'As a person who is starting to learn about growth hacking and launching my first online start up: I have discovered more than one unicorn on Conrati! Professional, knowledgeable advisors who helped me plan how to start, what gaps to cover and in what direction to move.',
    star: 5,
  },
  {
    id: 4,
    name: 'Jessica Wiggins',
    comment:
      'Amazing platform - it certainly beats the user interface and design of your competitors so well done on that front.',
    star: 5,
  },
];

const userCardPlaceholder = 'Growth Hackers in Action';
const blogTitle = 'Recent Growth Hacker Blogs';

const blogsList = [
  {
    id: 1,
    img: 'https://blog.conrati.com/wp-content/uploads/2020/12/Growth-Hacking-III-360x203.png',
    link: 'https://blog.conrati.com/hacking-the-science-of-startup-growth/',
    title: 'Hacking” the science of Startup Growth',
    content:
      'The term “hacking” is often used to describe nefarious activity online. It brings to mind hooded figures hunched over computers…',
  },
];

const popularItemsList = [
  'GROWTH HACKING',
  'ACCELERATOR',
  'GROWTH MARKETING',
  'GROWTH ENGINES',
  'RAPID PROTOTYPING',
  'LEAN STARTUP',
  'DIGITAL MARKETING',
  'GROWTH STRATEGIES',
];

const sliderTitle = 'Book world-class growth hackers';
const sliderSubTitle = 'Leverage from the experience of experts';
const blogCategoryId = 92;

export {
  initalTransformSize,
  benifitTitle,
  benefitsList,
  testominals,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderTitle,
  sliderSubTitle,
  themeTitle,
  sliderUserList,
  blogCategoryId,
};
