import freeImg from './images/benfefits/free.svg';
import therapyImg from './images/benfefits/therapy.svg';
import findImg from './images/benfefits/find.svg';
import scheduldeImg from './images/benfefits/schedulde.svg';
import payImg from './images/benfefits/pay.svg';
import sliderUserImage1 from './images/slider/user1.png';
import sliderUserImage112 from './images/slider/slider1.png';
import sliderUserImage2 from './images/slider/user2.png';
import sliderUserImage12x from './images/slider/user1@2x.png';
import sliderUserImage22x from './images/slider/user2@2x.png';

const sliderUserList = [
  {
    id: 1,
    img: sliderUserImage112,
    img2x: sliderUserImage112,
    link: '/depression-test',
  },
  {
    id: 2,
    img: sliderUserImage1,
    img2x: sliderUserImage12x,
    link: '/antoinettemullan1',
  },
  {
    id: 3,
    img: sliderUserImage2,
    img2x: sliderUserImage22x,
    link: '/dominiquerosaribeiro1',
  },
];

const themeTitle = 'Psychologist';
const profileUrl =
  '?&rate=&category=&skills=psychologist,industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor,depression,depression%20and%20anxiety,anxiety%20counselling,mental%20health%20support,psychotherapy,addiction,counselling%20for%20trauma,family%20therapy&languages=&free=false';
const buttonText = 'Find a therapist';
const initalTransformSize = -20;
const benifitTitle = 'A whole world of psychologists and therapists...';
const benefitsList = [
  {
    id: 1,
    img: freeImg,
    title: 'First-time free sessions',
    subTitle: 'Many therapists offer 30 minute bookings for free to familiarise yourself',
  },
  {
    id: 2,
    img: therapyImg,
    title: 'Therapy from the comfort of your home',
    subTitle: 'Video conference technology allows for calls from the comfort/safety of your home',
  },
  {
    id: 3,
    img: findImg,
    title: 'Find and compare therapists',
    subTitle: 'Therapists across different areas of specialisation and price',
  },
  {
    id: 4,
    img: scheduldeImg,
    title: 'Schedule an online booking instantly',
    subTitle: 'View therapists availability and schedule your booking',
  },
  {
    id: 5,
    img: payImg,
    title: 'Pay only after a successful consultation',
    subTitle: 'Only pay on the basis that your consultation was successful',
  },
];
const testominals = [
  {
    id: 1,
    name: 'Robin Berry',
    comment:
      'Thanks for a new marketplace. Great to have a platform to be in contact with therapists!.',
    star: 5,
  },
  {
    id: 2,
    name: 'Clive Simpson',
    comment:
      'I was suffering from anxiety and depression during Covid times. Desperate for help, I found a great psychologist who at a distance was extremely helpful. She really helped give me the tools I needed to deal with my problems.',
    star: 5,
  },
  {
    id: 3,
    name: 'Olivia Wood',
    comment:
      'I have such wonderful support now. I look forward to my sessions and have reframed my thoughts, goals and my potential. I have a kind, and caring therapist who listens intently. Our sessions are a safe and welcoming space that I have truly treasured.',
    star: 5,
  },
  {
    id: 4,
    name: 'Jessica Wiggins',
    comment:
      'Amazing platform - it certainly beats the user interface and design of your competitors so well done on that front.',
    star: 5,
  },
];

const userCardPlaceholder = 'Psychologists & Therapists in Action';
const blogTitle = 'Recent Psychology Blogs';

const blogsList = [
  {
    id: 1,
    img: 'https://blog.conrati.com/wp-content/uploads/2020/09/Can-a-psychologist-help-990x558.png',
    link: 'https://blog.conrati.com/can-an-online-psychologist-help/',
    title: 'Can an online psychologist help?',
    content:
      'There is growing evidence of the global impact of mental health. The burden of mental health disorders on health...',
  },
  {
    id: 2,
    img: 'https://blog.conrati.com/wp-content/uploads/2021/01/pexels-cottonbro-4101137-4-scaled-990x558.jpg',
    link: 'https://blog.conrati.com/psychology-and-its-facets/',
    title: 'Psychology and its facets',
    content:
      'Psychology is defined as “the scientific study of the human mind and its functions, especially those affecting...',
  },
];

const popularItemsList = [
  'PSYCHOLOGIST',
  'INDUSTRIAL PSYCHOLOGIST',
  'CHILD PSYCHOLOGIST',
  'CLINICAL PSYCHOLOGIST',
  'PSYCHOTHERAPIST',
  'COUNSELLOR',
  'MARRIAGE COUNSELLOR',
];

const sliderTitle = 'Book world-class therapists';
const sliderSubTitle = 'From industrial psychologists to counsellors';
const blogCategoryId = 54;

export {
  initalTransformSize,
  benifitTitle,
  benefitsList,
  testominals,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderTitle,
  sliderSubTitle,
  themeTitle,
  sliderUserList,
  profileUrl,
  blogCategoryId,
};
