import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ImageUploadModal.module.css';
import usernull from '../assets/usernull.svg';

class ImageUploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imgFile: '',
      uploading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { user, onClose } = this.props;
    if (prevProps.user.primaryImg !== user.primaryImg) {
      onClose('success');
    }
  }

  onFileChange = (e) => {
    const file = e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file || !file.type.match(pattern)) {
      return;
    }

    reader.onload = (event) => {
      this.setState({
        file: reader.result,
        imgFile: file,
      });

      if (file.size > 90000) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const width = 300;
          const scaleFactor = width / img.width;

          const imageElem = document.createElement('canvas');
          imageElem.width = width;
          imageElem.height = img.height * scaleFactor;

          imageElem.style.objectFit = 'center';
          imageElem.style.objectPosition = 'cover';

          const ctx = imageElem.getContext('2d');
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          ctx.canvas.toBlob(
            (blob) => {
              const compressedImageFile = new File([blob], 'profileImageUser', {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });

              this.setState({
                file: ctx.canvas.toDataURL(),
                imgFile: compressedImageFile,
              });
            },
            'image/jpeg',
            1
          );
        };
      }
    };

    reader.readAsDataURL(file);
  };

  onClickImg = () => {
    this.fileUpload.click();
  };

  onUploadImage = () => {
    const { imgFile } = this.state;
    const { onProfileImageUpload, user } = this.props;

    this.setState({ uploading: true });
    onProfileImageUpload({
      image1: imgFile,
      ...user,
    });
  };

  render() {
    const { file, uploading } = this.state;
    const { onClose } = this.props;

    return (
      <div className={styles.modal}>
        <div className={styles.modalbg} />
        <div className={styles.modalbody}>
          <div className={styles.head}>
            <p className={styles.headt}>Click to add a new profile image</p>
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={this.onFileChange}
              className={styles.imgupl}
              ref={(ref) => {
                this.fileUpload = ref;
              }}
            />
            <button type="button" onClick={this.onClickImg}>
              <img className={styles.pimg} src={file || usernull} alt="User" />
            </button>
          </div>
          <div className={styles.footer}>
            <button
              className={styles.btn}
              onClick={this.onUploadImage}
              disabled={!(file || uploading)}
              type="button"
            >
              {uploading ? 'UPLOADING' : 'SAVE'}
            </button>
            <button
              disabled={uploading}
              className={styles.btncancel}
              onClick={() => onClose('cancel')}
              type="button"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ImageUploadModal.propTypes = {
  user: PropTypes.shape({
    primaryImg: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onProfileImageUpload: PropTypes.func.isRequired,
};

export default ImageUploadModal;
