/* eslint-disable */
import React, { Component } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import styles from '../Myaccount.module.css';
import EditTooltip from '../../Common/EditTooltip/EditTooltip';
import EditSelect from '../../Common/EditSelect/EditSelect';
import enums from '../../../config/enum';
import payment_receive from '../../Common/assets/payment receive.svg';
import business from '../../Common/assets/businsess.svg';
import add_card from '../../Common/assets/add_OVER.svg';
import { alert } from '../../../utils';
import BusinessForm from './BusinessForm';
import ConsumerForm from './ConsumerForm';
import { updateBusinessDetails, updateBusinessDetailsNatural } from '../../../services/userService';

class BusinessDetails extends Component {
  constructor(props) {
    super(props);

    this.boxRef = React.createRef(); // this will give us a handle to the component's topmost element

    this.state = {
      edit: false,
      businessName: '',
      businessRegistrationNo: '',
      typeOfBusiness: '',
      vatNo: '',
      address: '',
      city: '',
      region: '',
      countryId: '',
      postalCode: '',
      selectactive: false,
      vatRegistered: false,
      countryCode: null,
      required: false,
      istypeOfBusinessSelected: false,
      isSmallEnterprise: false,
      isSmallExemptDisabled: false,
    };

    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onSelActive = this.onSelActive.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmitData = this.onSubmitData.bind(this);
    this.toastId = null;
  }

  componentDidMount() {
    const { businessDetails } = this.props;
    if (this.props.user.countryId) {
      const userCountry = this.props.user.countryId;

      this.setState({
        countryId: userCountry,
      });
    }

    if (businessDetails && businessDetails.businessId) {
      const {
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        isSmallEnterprise,
      } = businessDetails;
      const userCountry = this.props.user.countryId;

      this.setState({
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        countryCode: userCountry,
        vatRegistered: !!(vatNo && vatNo.length > 0),
        countryId: userCountry,
        istypeOfBusinessSelected: !!(
          typeOfBusiness === 0 ||
          typeOfBusiness === 1 ||
          typeOfBusiness === 3
        ),
        isSmallEnterprise,
      });
    } else {
      this.setState({
        isSmallEnterprise: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user.country) {
      const userCountry = newProps.user.countryId;

      this.setState({
        countryId: userCountry,
        countryCode: userCountry,
      });
    }

    if (newProps.businessDetails && newProps.businessDetails.businessId >= 0) {
      const {
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        isSmallEnterprise,
      } = newProps.businessDetails;

      this.setState({
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        vatRegistered: !!(vatNo && vatNo.length > 0),
        istypeOfBusinessSelected: !!(
          typeOfBusiness === 0 ||
          typeOfBusiness === 1 ||
          typeOfBusiness === 3
        ),
        isSmallEnterprise,
      });

      if (!isSmallEnterprise) {
        if (!vatNo) {
          this.setState({
            isSmallEnterprise: true,
          });
        }
      }
    } else {
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onTooltipClick(event) {
    event.preventDefault();
    event.stopPropagation(); // this stops the event to be captured by the click-outside handler immediately

    if (!this.props.user.personalDetailsCompleted) {
      return toast.warn('Please add your personal details first', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    this.setState({ edit: true });
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick(e) {
    if (this.state.selectactive) {
      return;
    }

    if (this.boxRef.current && this.boxRef.current.contains(e.target)) {
      return;
    }

    this.onSubmitData();

    if (this.state.required) {
      alert('Please fill all the required details');
      return;
    }

    this.setState({ edit: false });
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSelActive(type) {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  }

  onCheck(type) {
    if (type === false) {
      this.setState({
        vatRegistered: false,
        vatNo: '',
        isSmallExemptDisabled: true,
        isSmallEnterprise: true,
      });
    }

    if (type === true) {
      this.setState({
        vatRegistered: true,
        isSmallExemptDisabled: false,
        isSmallEnterprise: false,
      });
    }
  }

  onCheckSmallExempt = (type) => {
    const { user } = this.props;
    const { countryCode } = user.country;

    if (
      countryCode === 'MT' ||
      countryCode === 'GR' ||
      countryCode === 'FR' ||
      countryCode === 'BE'
    ) {
      if (type === false) {
        this.setState({ isSmallEnterprise: type });
      }

      if (type === true) {
        this.setState({ isSmallEnterprise: type });
      }
    } else {
      this.setState({ isSmallExemptDisabled: true });
    }
  };

  onSelectChange(selectedOption, name) {
    if (name === 'country') {
      this.setState({ countryCode: selectedOption.value });
    }

    if (name === 'typeOfBusiness') {
      this.setState({ typeOfBusiness: selectedOption.value });
    }
  }

  onSubmitData() {
    const {
      typeOfBusiness,
      businessName,
      businessRegistrationNo,
      vatNo,
      address,
      city,
      postalCode,
      region,
      countryCode,
      isSmallEnterprise,
    } = this.state;

    const businessStateServer = {
      type_of_business: typeOfBusiness,
      business_name: businessName,
      business_registration_no: businessRegistrationNo,
      vat_no: vatNo,
      address,
      city,
      postal_code: postalCode,
      region,
      country_id: countryCode,
      userToken: this.props.user.userToken,
      first_name: this.props.user.firstName,
      last_name: this.props.user.lastName,
      country_code: this.props.user.country.countryCode,
      is_small_enterprise: isSmallEnterprise,
    };

    if (typeOfBusiness >= 0) {
      if (
        typeOfBusiness !== enums.typeOfBusinessText['Individual / Consumer'] &&
        businessName &&
        address &&
        city &&
        postalCode &&
        countryCode
      ) {
        if (
          typeOfBusiness === enums.typeOfBusinessText.Business &&
          businessRegistrationNo.length < 1
        ) {
          this.props.onRequired(true, enums.onboardingStep.BusinessDetails);
          this.setState({ required: true });
          return alert('Please fill all the required details');
        }

        this.setState({ required: false });
        let toastId = null;

        toastId = toast.warn('Updating account details.', {
          position: 'top-center',
          hideProgressBar: true,
          autoClose: false,
        });

        const { user, onUpdateUserBuisnessDetails } = this.props;

        return updateBusinessDetails(businessStateServer)
          .then((data) => {
            if (user.userType === enums.userType.ar && !user.isBusinessRegistered) {
              ReactGA.pageview('/myaccount/ar/business_details');
            }

            onUpdateUserBuisnessDetails(data.result, {
              userToken: user.userToken,
            });

            return toast.update(toastId, {
              render: 'Account details updated.',
              autoClose: 1000,
            });
          })
          .catch(() => {
            return toast.update(toastId, {
              render: 'Business details update failed',
              autoClose: 1000,
            });
          });
      } else {
        this.setState({ required: true });
      }

      if (
        typeOfBusiness === enums.typeOfBusinessText['Individual / Consumer'] &&
        address &&
        city &&
        postalCode &&
        countryCode
      ) {
        this.setState({ required: false });

        let toastId = null;

        toastId = toast.warn('Updating business  details', {
          position: 'top-center',
          hideProgressBar: true,
          autoClose: false,
        });

        const { user, onUpdateUserBuisnessDetails } = this.props;

        const businessStateNaturalServer = {
          type_of_business: typeOfBusiness,
          address,
          city,
          postal_code: postalCode,
          region: '',
          country_id: user.countryId,
          first_name: user.firstName,
          last_name: user.lastName,
          country_code: user.country.countryCode,
        };

        updateBusinessDetailsNatural(businessStateNaturalServer)
          .then((data) => {
            if (user.userType === enums.userType.ar && !user.isBusinessRegistered) {
              ReactGA.pageview('/myaccount/ar/business_details');
            }

            onUpdateUserBuisnessDetails(data.result, {
              userToken: user.userToken,
            });

            return toast.update(toastId, {
              render: 'Account details updated.',
              autoClose: 1000,
            });
          })
          .catch(() => {
            return toast.update(toastId, {
              render: 'Business details update failed',
              autoClose: 1000,
            });
          });
      } else {
        this.setState({ required: true });
      }
    } else {
      this.setState({ required: true });
    }
  }

  render() {
    const {
      typeOfBusiness,
      businessName,
      businessRegistrationNo,
      vatNo,
      address,
      city,
      postalCode,
      istypeOfBusinessSelected,
    } = this.state;
    const userCountry = this.props.user.countryId;
    const { isBusinessRegistered, userType } = this.props.user;

    let typeofbuisness = [
      {
        value: 3,
        label: 'Individual / Consumer',
      },
      {
        value: 0,
        label: 'Freelancer / Soletrader',
      },
      {
        value: 1,
        label: 'Business',
      },
    ];

    if (userType === enums.userType.ar) {
      typeofbuisness = [
        {
          value: 3,
          label: 'Individual / Consumer',
        },
        {
          value: 0,
          label: 'Freelancer / Soletrader',
        },
        {
          value: 1,
          label: 'Business',
        },
      ];
    }

    const countries = this.props.countries.map((country) => ({
      value: country.countryId,
      label: country.countryName,
    }));

    const selectedCountry = userCountry
      ? countries.filter((arr) => {
          return arr.value === userCountry;
        })
      : null;

    const selectedTypeOfBusiness =
      typeOfBusiness >= 0
        ? typeofbuisness.filter((arr) => {
            return arr.value === typeOfBusiness;
          })
        : null;

    return (
      <div
        className={this.state.edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={this.boxRef}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={business} alt="business" />
          <p className={styles.atitle}>Account Details</p>
          <p className={styles.rerror}>* Required</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={this.state.edit} />
          </div>
        </div>
        {!isBusinessRegistered && !this.state.edit && (
          <div className={styles.nnotfoundcc}>
            <div className={styles.nnotfound}>
              <img src={payment_receive} alt="Business details" className={styles.bdetails} />
              <p className={styles.ntitle}>No Account Details !</p>
              <p className={styles.nsub}>
                {userType === enums.userType.ar
                  ? 'We require your account details to complete your registration. You can register as an individual (consumer) or alternatively as a business or sole trader.'
                  : `We require your business details to complete your registration. If you do not have a
                  business you can always register as a sole trader.`}
              </p>
            </div>
            <div className={styles.adbusiness} onClick={this.onTooltipClick}>
              <p className={styles.addp}>ADD YOUR ACCOUNT DETAILS</p>
              <img src={add_card} alt="Add" className={styles.add} />
            </div>
          </div>
        )}

        {isBusinessRegistered && !this.state.edit && (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Type of User </p>
              <p className={styles.alisub}>{enums.typeOfBusiness[typeOfBusiness]}</p>
            </div>
            {typeOfBusiness !== enums.typeOfBusinessText['Individual / Consumer'] && (
              <>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Business Name</p>
                  <p className={styles.alisub}>{businessName}</p>
                </div>
                {businessRegistrationNo && (
                  <div className={styles.alitem}>
                    <p className={styles.alitit}>Business registration number</p>
                    <p className={styles.alisub}>{businessRegistrationNo}</p>
                  </div>
                )}
                {vatNo && (
                  <div className={styles.alitem}>
                    <p className={styles.alitit}>VAT Number</p>
                    <p className={styles.alisub}>{vatNo}</p>
                  </div>
                )}
              </>
            )}
            <div className={styles.alitem}>
              <p className={styles.alitit}>Address</p>
              <p className={styles.alisub}>{`${address} ${city} ${postalCode}`}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Country</p>
              <p className={styles.alisub}>{selectedCountry && selectedCountry[0] ? selectedCountry[0].label : ''}</p>
            </div>
          </div>
        )}

        {this.state.edit && (
          <div className={styles.acedit}>
            <EditSelect
              label="Type of User"
              selected={selectedTypeOfBusiness}
              options={typeofbuisness}
              placeholder="Select your business type"
              onActive={this.onSelActive}
              onChange={(e) => this.onSelectChange(e, 'typeOfBusiness')}
              isDisabled={!!istypeOfBusinessSelected}
            />
            <p
              className={styles.alisub}
              style={{ marginTop: '-10px', marginBottom: '15px', color: '#FF9494' }}
            >
              Once selected, this field is no longer editable.
            </p>
            {typeOfBusiness === enums.typeOfBusinessText['Individual / Consumer'] && (
              <ConsumerForm
                onSelActive={this.onSelActive}
                onChange={this.onChange}
                onCheck={this.onCheck}
                onCheckSmallExempt={this.onCheckSmallExempt}
                onSelectChange={this.onSelectChange}
                {...this.props}
                parentState={this.state}
              />
            )}
            {typeOfBusiness === enums.typeOfBusinessText.Business && (
              <BusinessForm
                onSelActive={this.onSelActive}
                onChange={this.onChange}
                onCheck={this.onCheck}
                onCheckSmallExempt={this.onCheckSmallExempt}
                onSelectChange={this.onSelectChange}
                {...this.props}
                parentState={this.state}
              />
            )}
            {typeOfBusiness === enums.typeOfBusinessText['Freelancer / Soletrader'] && (
              <BusinessForm
                onSelActive={this.onSelActive}
                onChange={this.onChange}
                onCheck={this.onCheck}
                onCheckSmallExempt={this.onCheckSmallExempt}
                onSelectChange={this.onSelectChange}
                {...this.props}
                parentState={this.state}
              />
            )}

            <div className={styles.binfo}>
              <p>
                This information is used to generate invoices between advice providers and advice
                receivers on your business’s behalf. This includes the VAT determination both
                locally and internationally depending on the scenario
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BusinessDetails;
