import React from 'react';
import styles from './style.module.scss';

export interface NativeInputValidation {
	value: string;
	isValid: boolean;
	errorMessage: string;
}

interface NativeInputProps {
	name: string;
	className?: string;
	label?: string;
	type: 'email' | 'text' | 'password';
	id?: string;
	placeholder?: string;
	onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	validate?: NativeInputValidation;
	hideLabel?: boolean;
	defaultValue?: string;
	value?: string;
}

export const NativeInput = (props: NativeInputProps): JSX.Element => {
	const {
		name,
		label,
		id,
		type,
		placeholder,
		validate,
		hideLabel,
		onInputChange,
		className,
		defaultValue,
		value,
	} = props;

	const showError = validate && !validate.isValid && validate.errorMessage;

	return (
		<div
			className={`${styles.inputContainer} ${className || ''} ${
				showError ? styles.inputError : ''
			}`}
		>
			<label
				className={`${styles.inputLabel} ${
					hideLabel ? styles.hideLabel : ''
				}`}
				data-testid="ui-native-input-label"
				htmlFor={id}
			>
				{label}
			</label>
			<input
				className={styles.inputInput}
				type={type}
				name={name}
				defaultValue={defaultValue}
				value={value}
				id={id}
				data-testid="ui-native-input"
				onChange={(event): void => {
					if (onInputChange) {
						onInputChange(event);
					}
				}}
				placeholder={placeholder}
				autoComplete={type === 'password' ? 'current-password' : 'off'}
			/>

			{showError && (
				<div
					className={styles.inputErrorField}
					data-testid="ui-native-input-error-text"
				>
					{validate.errorMessage}
				</div>
			)}
		</div>
	);
};
