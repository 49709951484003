import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './Dashboard.module.css';
import enums from '../../config/enum';
import usernull from '../Common/assets/usernull.svg';
import videobtn from '../Common/assets/btn_icon_vidcall.svg';
import arrowMore from '../Common/assets/arrow_more.svg';

function BookingDetailItem(props) {
  const { session, user, onJoinSession, onMore } = props;
  const { bookingStatus } = session;

  return (
    <div className={styles.upseslistb}>
      <div className={styles.upseslist}>
        {user.userType === enums.userType.ar ? (
          <Link to={`/${session.apUserData.username}`} className={styles.userProfile}>
            <div className={styles.usrib}>
              <img
                className={styles.pimg}
                src={session.apUserData.primaryImg ? session.apUserData.primaryImg : usernull}
                alt="user"
              />
              <p className={styles.uname}>
                {`${session.apUserData.firstName} ${session.apUserData.lastName}`}
              </p>
            </div>
          </Link>
        ) : (
          <div className={styles.usriba}>
            <img
              className={styles.pimg}
              src={session.arUserData.primaryImg ? session.arUserData.primaryImg : usernull}
              alt="user"
            />
            <p className={styles.uname}>
              {`${session.arUserData.firstName} ${session.arUserData.lastName}`}
            </p>
          </div>
        )}
        <div className={styles.time}>
          <p>{moment(session.dateOfBooking).format('DD MMMM YYYY')}</p>
          <p>
            {`${moment.unix(session.from).format('hh:mm a')}-${moment
              .unix(session.to)
              .format('hh:mm a')}`}
          </p>
        </div>
        <p className={styles.stit}>{session.title}</p>
        {bookingStatus === enums.bookingStatus.PENDING_CONFIRMATION &&
          user.userType === enums.userType.ar && (
            <div className={styles.ico}>
              <p className={styles.pbtn}>Pending Confirmation</p>
              <button type="button" onClick={() => onMore(session.bookingId)}>
                <img src={arrowMore} alt="more" width="19px" height="19px" />
              </button>
            </div>
          )}
        {bookingStatus === enums.bookingStatus.PENDING_CONFIRMATION &&
          user.userType === enums.userType.ap && (
            <div className={styles.ico}>
              <Link
                to={`/session/details?booking_id=${session.bookingId}&type=cancel`}
                className={styles.canBtn}
              >
                Decline
              </Link>
              <Link
                to={`/session/details?booking_id=${session.bookingId}&type=confirm`}
                className={styles.suBtn}
              >
                Confirm
              </Link>
            </div>
          )}
        {bookingStatus !== enums.bookingStatus.PENDING_CONFIRMATION && (
          <div className={styles.ico}>
            <button type="button" onClick={() => onJoinSession(session.bookingId)}>
              <img src={videobtn} alt="joinvideo" width="27px" height="16px" />
            </button>
            <button type="button" onClick={() => onMore(session.bookingId)}>
              <img src={arrowMore} alt="more" width="19px" height="19px" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

BookingDetailItem.propTypes = {
  session: PropTypes.shape({
    bookingStatus: PropTypes.number,
    dateOfBooking: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    title: PropTypes.string,
    bookingId: PropTypes.string,
    apUserData: PropTypes.shape({
      username: PropTypes.string,
      primaryImg: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    arUserData: PropTypes.shape({
      username: PropTypes.string,
      primaryImg: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({ userType: PropTypes.number }).isRequired,
  onJoinSession: PropTypes.func.isRequired,
  onMore: PropTypes.func.isRequired,
};

export default BookingDetailItem;
