const transformSize = -20;

function getSize(): number {
	const desktopTestimonialElem = document.getElementById(
		'desktop-testimonials'
	) as HTMLDivElement | null;

	if (!desktopTestimonialElem) {
		return transformSize;
	}

	const currentSizeElem = desktopTestimonialElem.getAttribute('data-size');

	if (!currentSizeElem) {
		return transformSize;
	}

	return parseInt(currentSizeElem);
}

const setTransformSize = (size: number): void => {
	const mobileTestimonialElem = document.getElementById(
		'mobile-testimonials'
	) as HTMLDivElement | null;
	const desktopTestimonialElem = document.getElementById(
		'desktop-testimonials'
	) as HTMLDivElement | null;

	if (!desktopTestimonialElem || !mobileTestimonialElem) {
		return;
	}

	desktopTestimonialElem.setAttribute('data-size', size.toString());

	desktopTestimonialElem.style.transform = `translateX(${size}px)`;
	mobileTestimonialElem.style.transform = `translateX(${size}px)`;
};

export const handleSliderController = (
	type: string,
	dataSize: number
): true | void => {
	const initialTransformSize = -20;
	const divWidth = 537;

	const maxTransformSize = -((dataSize * divWidth) / 2 + -initialTransformSize);
	const transformSize = getSize();

	if (type === 'next') {
		const nextTransformSize = transformSize - divWidth;

		if (nextTransformSize >= maxTransformSize) {
			return setTransformSize(nextTransformSize);
		}
	}

	if (type === 'prev') {
		const nextTransformSize = transformSize + divWidth;

		if (nextTransformSize <= initialTransformSize) {
			return setTransformSize(nextTransformSize);
		}
	}

	return true;
};
