import { OptionType } from './select';

export function isOptionMatching(
	option: OptionType,
	inputOption: OptionType
): boolean {
	return (
		inputOption.value.toLowerCase() === option.value.toLowerCase() ||
		inputOption.label.toLowerCase() === option.label.toLowerCase()
	);
}

export function matchingOption(
	option: OptionType,
	inputOption: OptionType | OptionType[]
): boolean {
	if (Array.isArray(inputOption)) {
		const foundIndex = inputOption.findIndex((input) =>
			isOptionMatching(input, option)
		);

		return foundIndex > -1;
	}

	return isOptionMatching(inputOption, option);
}
