import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.css';
import cx from 'classnames';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      focused: false,
    };
    this.showLabel = this.showLabel.bind(this);
  }

  componentDidMount() {}

  showLabel(e, label) {
    this.setState({
      label: this.props.label,
      focused: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(true);
    }
  }

  hideLabel(e, label) {
    this.setState({
      label: this.props.label ? '' : label,
      focused: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);

      if (this.props.onFocus) {
        this.props.onFocus(false);
      }
    }
  }

  render() {
    const {
      classes,
      style,
      htmlFor,
      label,
      value,
      placeholder,
      error,
      refInput,
      readOnly,
      name,
      type,
      tooltip,
      tooltipmsg,
      ...rest
    } = this.props;

    let stylescss = cx(styles.input);
    let stylescss_focused = cx(styles.input, styles.inputf);
    return (
      <div className={styles.inputel}>
        <label htmlFor={htmlFor} className={styles.label}>
          {this.state.label}
        </label>
        <div className={styles.inputflex}>
          <input
            {...rest}
            ref={refInput}
            type={type ? type : 'text'}
            className={this.state.focused ? stylescss_focused : stylescss}
            placeholder={placeholder}
            value={value}
            name={name}
            onFocus={(e) => this.showLabel(e, label)}
            onBlur={(e) => this.hideLabel(e, label)}
            onChange={(e) => this.props.onChange(e)}
          />

          {tooltip && this.state.focused && (
            <div className={cx(styles.tooltipb)}>
              <p>{tooltipmsg}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.any,
  refInput: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.bool,
};

export default Input;
