/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SessionFooter from '../SessionFooter';
import style from './style.module.scss';
import globalProptypes from '../../../config/proptype/index';

class VideoCall extends Component {
  render() {
    const { bookingData, userData } = this.props;
    return (
      <div className={style.VideoCall}>
        <div className={style.mainView} id="subscriber-stream" />
        <div className={style.tile}>
          <div className={style.callCard} id="publisher-stream" />
        </div>
        <SessionFooter bookingData={bookingData} userData={userData} />
      </div>
    );
  }
}

VideoCall.propTypes = {
  bookingData: PropTypes.shape({}).isRequired,
  userData: globalProptypes.user.isRequired,
};

export default VideoCall;
