import React, { Component } from 'react';
import styles from '../Static.module.css';

class Codeofconduct extends Component {
  render() {
    return (
      <div className={styles.brpage}>
        <div className={styles.brheader}>
          <h1>Code of Conduct</h1>
        </div>
        <div className={styles.brcontainer}>
          <div
            style={{
              marginBottom: '50px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              color: '#555',
            }}
          >
            <div>
              <p
                style={{
                  marginTop: '0pt',
                  marginLeft: '42.5pt',
                  marginBottom: '0pt',
                  textIndent: '-42.5pt',
                  textAlign: 'right',
                  lineHeight: '112%',
                  widows: 0,
                  orphans: 0,
                  fontSize: '10pt',
                }}
              >
                <span style={{ fontFamily: 'Arial' }}>&nbsp;</span>
              </p>
            </div>
            <p style={{ marginTop: '6pt', marginBottom: '14pt', fontSize: '14pt' }}>
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Code of Conduct</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <strong>
                <span style={{ fontFamily: 'Roboto' }}>Last Updated: 1 July 2019</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', color: '#555555' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', color: '#555555' }}>
                Advice Receivers to whom you will provide advice want to be assured that non-public
                information shared with you during an online consultation remain non-public and
                protected.{' '}
              </span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', color: '#555555' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', color: '#555555' }}>
                By using the Conrati platform for providing advice, you agree to the following Code
                of Conduct:
              </span>
            </p>
            <p
              style={{
                marginTop: '0pt',
                marginBottom: '0pt',
                textAlign: 'justify',
                fontSize: '11pt',
              }}
            >
              <span style={{ fontFamily: 'Roboto', color: '#555555' }}>&nbsp;</span>
            </p>
            <ul
              style={{ margin: '0pt', paddingLeft: '18pt', color: '#555555', fontSize: '11pt' }}
              type="bullet"
            >
              <li>Act in a timely, cost-effective and professional manner</li>
              <li>Listen carefully and attentively to the needs of the Advice Receiver</li>
              <li>Provide advice according to the best of your knowledge</li>
              <li>
                Recommend the signing of a non-disclosure-agreement in case the Advice Receiver
                wishes to share confidential information with you and restrict access thereof to
                third parties. In such cases the following procedure may be used during an online
                consultation (session):
              </li>
            </ul>

            <ul style={{ margin: '0pt', paddingLeft: '0pt', marginTop: '10px' }} type="circle">
              <li
                style={{
                  marginLeft: '65.6pt',
                  textAlign: 'justify',
                  paddingLeft: '6.4pt',
                  fontSize: '11pt',
                  color: '#555555',
                }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  The Advice Receiver uploads a signed non-disclosure agreement into the session
                </span>
              </li>
              <li
                style={{
                  marginLeft: '65.6pt',
                  textAlign: 'justify',
                  paddingLeft: '6.4pt',
                  fontSize: '11pt',
                  color: '#555555',
                }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  The Advice Provider downloads and signs the non-disclosure agreement
                </span>
              </li>
              <li
                style={{
                  marginLeft: '65.6pt',
                  textAlign: 'justify',
                  paddingLeft: '6.4pt',
                  fontSize: '11pt',
                  color: '#555555',
                }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  The Advice Provider uploads a scanned copy into the session.{' '}
                </span>
              </li>
              <li
                style={{
                  marginLeft: '65.6pt',
                  textAlign: 'justify',
                  paddingLeft: '6.4pt',
                  fontSize: '11pt',
                  color: '#555555',
                }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  Both parties keep a record of the agreed NDA on their own systems
                </span>
              </li>
              <li
                style={{
                  marginLeft: '65.6pt',
                  textAlign: 'justify',
                  paddingLeft: '6.4pt',
                  fontSize: '11pt',
                  color: '#555555',
                }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  Proceed according your individual agreement
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Codeofconduct;
