import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'react-modal';
import styles from './Home.module.css';
import Headers from '../Headers/Headers';
import searchico from '../Common/assets/search.1.svg';
import arrowico from '../Common/assets/arrow_gray_white.svg';
import availabiltyhome from '../Common/assets/availability_home.svg';
import searchhome from '../Common/assets/search_home.svg';
import sessionhome from '../Common/assets/session_home.svg';
import paymenthome from '../Common/assets/payment_home.svg';
import rpayment from '../Common/assets/recieve_payment_home.svg';
import plana from '../Common/assets/plana.svg';
import planb from '../Common/assets/planb.svg';
import { fetchRandomProfilesHomePage } from '../../actions/profileAction';
import enums from '../../config/enum';
import ProfileCard from './ProfileCard/ProfileCard';
import globalPropTypes from '../../config/proptype';
import Slider from './Slider';
import Category from './Category';
import ExploreCategory from './ExploreCategory';
import { checkIsMobileBrowser } from '../../utils';

const modalStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '358px',
    backgroundColor: ' #f2f2f2',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      exploreCategory: false,
      isMobileDevice: checkIsMobileBrowser(navigator.userAgent),
    };
  }

  componentDidMount() {
    const { userState, history, onFetchRandomProfiles, currency } = this.props;
    if (userState.isLoggedIn) {
      const { userType } = userState.user;
      // Redirect to dashboard if user is logged in as AP
      if (userType === enums.userType.ap) {
        return history.replace('/dashboard');
      }

      // Redirect to search page if user is logged in as AR
      if (userType === enums.userType.ar) {
        const reqData = {};

        if (currency.currencyCode) {
          reqData.currency = currency;
        }
        // return history.replace('/search');
        onFetchRandomProfiles(reqData, 'mentors');
        onFetchRandomProfiles(reqData, 'psychology');
        return onFetchRandomProfiles(reqData, 'business consulting');
      }
    } else {
      const reqData = {};

      if (currency.currencyCode) {
        reqData.currency = currency;
      }

      // return history.replace('/search');
      onFetchRandomProfiles(reqData, 'mentors');
      onFetchRandomProfiles(reqData, 'psychology');
      return onFetchRandomProfiles(reqData, 'business consulting');
    }

    return true;
  }

  onSearchChange = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value });
  };

  handleKeyPress = (e) => {
    const { search } = this.state;
    const { history } = this.props;
    if (e.key === 'Enter' && search) {
      return history.push(`/search?query=${search.toLowerCase()}`);
    }

    return true;
  };

  onSearchClick = (e) => {
    e.preventDefault();
    const { search } = this.state;
    const { history } = this.props;
    if (search) {
      return history.push(`/search?query=${search.toLowerCase()}`);
    }

    return history.push('/search');
  };

  render() {
    const { mentors, bconsultants, psychologists } = this.props;
    const { exploreCategory, isMobileDevice } = this.state;
    return (
      <>
        <div className={styles.home}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Headers {...this.props} />
          <Slider />
          <div className={styles.searchbar}>
            <input
              aria-label="Seacrh"
              type="text"
              placeholder="What skill or qualification are you looking for?"
              className={styles.inputctl}
              onChange={this.onSearchChange}
              onKeyPress={this.handleKeyPress}
            />
            <button type="submit" className={styles.bico} onClick={this.onSearchClick}>
              <img src={searchico} alt="search" />
            </button>
          </div>

          <div className={styles.ciactionCat}>
            <div className={styles.ciahead}>
              <p className={styles.pign} />
              <p className={styles.ctit}>What do you need help with?</p>
              <button
                type="button"
                className={styles.cba}
                onClick={() => {
                  this.setState({ exploreCategory: true });
                }}
              >
                Browse All <img className={styles.aic} src={arrowico} alt="" />
              </button>
            </div>
            <div className={styles.cards}>
              <Category />
            </div>
          </div>
          <div className={styles.bline} />

          <div className={styles.ciaction}>
            <div className={styles.ciahead}>
              <p className={styles.pign} />
              <p className={styles.ctit}>Mentors / Coaches available</p>
              <Link to="/categories/mentors" className={styles.cba}>
                See More <img className={styles.aic} src={arrowico} alt="" />
              </Link>
            </div>
            <div className={cx(styles.cards, isMobileDevice ? styles.mobileCards : '')}>
              <ProfileCard profiles={mentors} />
            </div>
          </div>

          <div className={styles.bline} />

          <div className={styles.ciaction}>
            <div className={styles.ciahead}>
              <p className={styles.pign} />
              <p className={styles.ctit}>Therapists Available</p>
              <Link to="/categories/psychologist" className={styles.cba}>
                See More <img className={styles.aic} src={arrowico} alt="" />
              </Link>
            </div>
            <div className={cx(styles.cards, isMobileDevice ? styles.mobileCards : '')}>
              <ProfileCard profiles={psychologists} />
            </div>
          </div>

          <div className={styles.bline} />

          <div className={styles.ciaction}>
            <div className={styles.ciahead}>
              <p className={styles.pign} />
              <p className={styles.ctit}>Business consultants available</p>
              <Link to="/categories/businessconsultant" className={styles.cba}>
                See More <img className={styles.aic} src={arrowico} alt="" />
              </Link>
            </div>
            <div className={cx(styles.cards, isMobileDevice ? styles.mobileCards : '')}>
              <ProfileCard profiles={bconsultants} />
            </div>
          </div>

          {/* Testimonial */}
          {/* <div className={styles.testomonialDesktop}>
            <div className={styles.benefitsa}>
              <p className={styles.bftitle}>Testimonials</p>
              <div className={styles.wrapperTestimonial}>
                <img src={testimonialCircleL} alt="leftCircle" className={styles.leftCircle} />
                <img src={testimonialCircleR} alt="leftCircle" className={styles.rightCircle} />
                <div
                  className={styles.testimonial}
                  style={{ transform: `translateX(${transformSize}px)` }}
                >
                  {testominals.map(testimon => (
                    <div key={testimon.id} className={styles.testContainer}>
                      <div className={styles.leftTest}>
                        <img src={star5} alt="star" />
                        <p className={styles.tname}>{testimon.name}</p>
                      </div>
                      <div className={styles.rightTest}>
                        <p className={styles.tsub}>{testimon.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.marker}>
                <div className={styles.clbuttons}>
                  <button
                    type="button"
                    className={styles.clbtn}
                    onClick={() => this.handleBtnClick('prev')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                  <button
                    type="button"
                    className={styles.clbtnr}
                    onClick={() => this.handleBtnClick('next')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.testomonialMobile}>
            <div className={styles.benefitsa}>
              <p className={styles.bftitle}>Testimonials</p>
              <div className={styles.wrapperTestimonial}>
                <img src={testimonialCircleL} alt="leftCircle" className={styles.leftCircle} />
                <img src={testimonialCircleR} alt="leftCircle" className={styles.rightCircle} />
                <div
                  className={styles.testimonial}
                  style={{ transform: `translateX(${transformSize}px)` }}
                >
                  {testominals.map(testimon => (
                    <div key={testimon.id} className={styles.testContainer}>
                      <div className={styles.leftTest}>
                        <img src={star5} alt="star" />
                        <p className={styles.tname}>{testimon.name}</p>
                      </div>
                      <div className={styles.rightTest}>
                        <p className={styles.tsub}>{testimon.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.marker}>
                <div className={styles.clbuttons}>
                  <button
                    type="button"
                    className={styles.clbtn}
                    onClick={() => this.handleBtnClick('prev')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                  <button
                    type="button"
                    className={styles.clbtnr}
                    onClick={() => this.handleBtnClick('next')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                </div>
              </div>
            </div>
          </div>       */}

          <div className={styles.binfo}>
            <div className={styles.binfotext}>
              <p>On-demand expert advice, anytime, anywhere ...</p>
              <p>
                Join other members in impacting knowledge, sharing advice and building solutions
                while saving time and money.
              </p>
              <a href="/register" className={styles.abtn}>
                Register
              </a>
            </div>
          </div>
          <div className={styles.hiw}>
            <div className={styles.howitext}>
              <p className={styles.hitt}>How it works</p>
              <p className={styles.hist}>
                Conrati is an on-demand advice based B2B and B2C marketplace
                <br /> Advice Receivers book paid video consulation with Advice Providers <br />{' '}
                Easily search, book, consult, invoice and pay across borders
              </p>
              <div className={styles.adfeature}>
                <div className={styles.fecard}>
                  <p className={styles.fecardtit}>
                    Advice Providers <br />
                    set availability
                  </p>
                  <img src={availabiltyhome} alt="Availabilty" />
                  <p className={styles.fecardsub}>
                    No back and forth to secure <br /> arrangements{' '}
                  </p>
                </div>
                <div className={styles.fecard}>
                  <p className={styles.fecardtit}>
                    Advice Receivers search <br /> and schedule bookings
                  </p>
                  <img src={searchhome} alt="Search" />
                  <p className={styles.fecardsub}>
                    The perfect candidate <br /> found within seconds
                  </p>
                </div>
                <div className={styles.fecard}>
                  <p className={styles.fecardtit}>
                    Secure video <br /> consultations
                  </p>
                  <img src={sessionhome} alt="Session" />
                  <p className={styles.fecardsub}>
                    Easy and reliable <br /> video conferencing
                  </p>
                </div>
                <div className={styles.fecard}>
                  <p className={styles.fecardtit}>
                    Payment follows <br /> a consultation{' '}
                  </p>
                  <img src={paymenthome} alt="Payment" />
                  <p className={styles.fecardsub}>
                    Fast and secure <br /> payment
                  </p>
                </div>
                <div className={styles.fecard}>
                  <p className={styles.fecardtit}>
                    Invoices are <br /> automated
                  </p>
                  <img src={rpayment} alt="Availabilty" />
                  <p className={styles.fecardsub}>
                    No tax <br /> guesswork
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sliderquotes}>
            <div className={styles.quotescontainer}>
              <p className={styles.quotesq}>
                “Conrati is the bridge between Advisees and Advisors, between Companies and
                Consultants.”
              </p>
              <p className={styles.quotesaut}>Rory de Groot, Growth and Marketing Manager</p>
            </div>
          </div>
          <div className={styles.plans}>
            <div className={styles.planscontainer}>
              <div className={styles.plana}>
                <p className={styles.planti}>Need paid advice?</p>
                <p className={styles.planssub}>
                  Find an expert business consultant <br /> and book an online consultation.
                </p>
                <a href="/register?user_type=ar" className={styles.pbtn}>
                  BECOME AN ADVICE RECEIVER
                </a>
                <img src={plana} alt="plana" />
              </div>
              <div className={styles.planb}>
                <p className={styles.planti}>Want to provide advice?</p>
                <p className={styles.planssub}>
                  Have extra time available <br /> and want to give paid advice?
                </p>
                <a href="/register?user_type=ap" className={styles.pbtn}>
                  BECOME AN ADVICE PROVIDER
                </a>
                <img src={planb} alt="plana" />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={exploreCategory}
          onRequestClose={() => this.setState({ exploreCategory: false })}
          style={modalStyles}
          contentLabel="Explore Category"
        >
          <ExploreCategory onClose={() => this.setState({ exploreCategory: false })} />
        </Modal>
      </>
    );
  }
}

Home.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  userState: globalPropTypes.userState.isRequired,
  history: globalPropTypes.history.isRequired,
  onFetchRandomProfiles: PropTypes.func.isRequired,
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
  }).isRequired,
  psychologists: PropTypes.arrayOf(PropTypes.object).isRequired,
  mentors: PropTypes.arrayOf(PropTypes.object).isRequired,
  bconsultants: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : {};
  return {
    profiles: state.profiles.profiles,
    userState: state.userState,
    currency,
    mentors: state.profiles.mentors,
    psychologists: state.profiles.psychologists,
    bconsultants: state.profiles.bconsultants,
    userData: state.userState.isLoggedIn ? state.userState.user : {},
    isLoggedIn: state.userState.isLoggedIn,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchRandomProfiles: fetchRandomProfilesHomePage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Home);
