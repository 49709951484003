import React, { Component } from 'react';
import styles from './KycDetails.module.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { apiurl } from '../../../utils/index';
import EditInput from '../../Common/EditInput/EditInput';
import EditDate from '../../Common/EditDate/EditDate';
import EditSelect from '../../Common/EditSelect/EditSelect';

class Uboadd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      typeOfBusiness: 0,
      address: '',
      city: '',
      postalCode: '',
      birthplaceCity: '',
      birthplaceCountry: '',
      nationality: '',
      selectactive: false,
      region: '',
      country: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelActive = () => {
    this.setState({ selectactive: true });
  };

  componentDidMount() {}

  onSelectChange = (e, name) => {
    this.setState({ [name]: e.value });
  };

  onSubmitUboDetails = (e, type) => {
    e.preventDefault();

    let {
      firstName,
      lastName,
      address,
      city,
      region,
      postalCode,
      country,
      nationality,
      dateOfBirth,
      birthplaceCity,
      birthplaceCountry,
    } = this.state;

    if (
      firstName &&
      lastName &&
      address &&
      city &&
      postalCode &&
      country &&
      nationality &&
      dateOfBirth &&
      birthplaceCity &&
      birthplaceCountry
    ) {
      this.setState({ uploading: true });

      let data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        city: city,
        region: region,
        postal_code: postalCode,
        country: country,
        nationality: nationality,
        date_of_birth: dateOfBirth,
        birthplace_city: birthplaceCity,
        birthplace_country: birthplaceCountry,
        submit: false,
      };

      if (type === 'submit') {
        data.submit = true;
      }

      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Beare ${this.props.user.userToken}`,
      };

      axios
        .post(`${apiurl}/v1/payment/kyc/ubo`, data, { headers: headers })
        .then((result) => {
          if (result.data.success) {
            if (type === 'add') {
              this.setState({
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                typeOfBusiness: 0,
                address: '',
                city: '',
                postalCode: '',
                birthplaceCity: '',
                birthplaceCountry: '',
                nationality: '',
                selectactive: false,
                region: '',
                country: '',
              });
            }

            this.setState({ uploading: false });

            this.props.onFetchAllUboDocs({ userToken: this.props.user.userToken });

            this.props.closeModal();

            return toast.warn(result.data.message, {
              position: 'top-center',
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            throw new Error(result.data.message);
          }
        })
        .catch((err) => {
          this.setState({ uploading: false });
          return toast.warn(err.message || 'Failed in adding ubo data', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } else {
      alert('Please fill all required fields!');
    }
  };

  render() {
    let countries = this.props.allcountries.map((country) => ({
      value: country.countryCode,
      label: country.countryName,
    }));

    return (
      <div className={styles.selectpop}>
        <p className={styles.alisubikbm}>
          Please upload the details of an individual possessing 25% or more of the shares or voting
          rights of the company. If no individual owns at least 25% shares or voting rights, then
          provide the details of a legal representative of the company instead.
        </p>

        <div className={styles.form}>
          <div className={styles.left}>
            <EditInput
              htmlFor="First Name"
              label="First Name"
              value={this.state.firstName}
              placeholder="First Name"
              onChange={this.onChange}
              name="firstName"
              required={true}
            />
            <EditInput
              htmlFor="Last Name"
              label="Last Name"
              value={this.state.lastName}
              placeholder="Last Name"
              onChange={this.onChange}
              name="lastName"
              required={true}
            />

            <EditDate
              label="Date of Birth"
              date={this.state.dateOfBirth}
              onChange={this.onChange}
              name="dateOfBirth"
              required={true}
            />

            <EditInput
              htmlFor="Birthplace City"
              label="Birthplace City"
              value={this.state.birthplaceCity}
              placeholder="Birthplace City"
              onChange={this.onChange}
              name="birthplaceCity"
              required={true}
            />
            <EditSelect
              label="Birthplace Country"
              options={countries}
              placeholder="Select Birthplace Country"
              onChange={(e) => this.onSelectChange(e, 'birthplaceCountry')}
              onActive={this.onSelActive}
              required={true}
            />
          </div>
          <div className={styles.left}>
            <EditSelect
              label="Nationality"
              options={countries}
              placeholder="Select Nationality"
              onChange={(e) => this.onSelectChange(e, 'nationality')}
              onActive={this.onSelActive}
              required={true}
            />
            <EditSelect
              label="Country of residence"
              options={countries}
              placeholder="Select Country"
              onChange={(e) => this.onSelectChange(e, 'country')}
              onActive={this.onSelActive}
              required={true}
            />
            <EditInput
              htmlFor="Street - Address"
              label="Street - Address"
              value={this.state.address}
              placeholder="Address"
              onChange={this.onChange}
              name="address"
              required={true}
            />
            <EditInput
              htmlFor="City - Address"
              label="City - Address"
              value={this.state.city}
              placeholder="City"
              onChange={this.onChange}
              name="city"
              required={true}
            />
            {this.state.country === 'US' ||
            this.state.country === 'CA' ||
            this.state.country === 'MX' ? (
              <EditInput
                htmlFor="Region - Address"
                label="Region - Address"
                value={this.state.region}
                placeholder="Region"
                onChange={this.onChange}
                name="region"
                required={true}
              />
            ) : null}
            <EditInput
              htmlFor="Postal Code - Address"
              label="Postal Code - Address"
              value={this.state.postalCode}
              placeholder="Postal Code"
              onChange={this.onChange}
              name="postalCode"
              required={true}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.btnc} onClick={this.props.closeModal}>
            CANCEL
          </button>
          <div className={styles.mubtn}>
            <button
              className={styles.btn}
              disabled={this.state.uploading ? true : false}
              onClick={(e) => this.onSubmitUboDetails(e, 'add')}
            >
              {this.state.uploading ? 'ADDING SHARE HOLDER' : 'ADD SHARE HOLDER'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Uboadd;
