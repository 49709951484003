import freeImg from './images/benfefits/free.svg';
import therapyImg from './images/benfefits/therapy.svg';
import findImg from './images/benfefits/find.svg';
import scheduldeImg from './images/benfefits/schedulde.svg';
import payImg from './images/benfefits/pay.svg';
import sliderUserImage1 from './images/slider/user1.png';
import sliderUserImage12x from './images/slider/user1@2x.png';

const sliderUserList = [
  {
    id: 1,
    img: sliderUserImage1,
    img2x: sliderUserImage12x,
    link: '/wilfriedrupieper1',
  },
];

const themeTitle = 'Business Consulting';
const buttonText = 'Find a consultant';
const initalTransformSize = -20;
const benifitTitle = 'A whole world of business consultants at your fingertips';
const benefitsList = [
  {
    id: 1,
    img: freeImg,
    title: 'First-time free sessions',
    subTitle:
      'Some consultants offer 30 minute bookings for free to become familiar before you spend money',
  },
  {
    id: 2,
    img: therapyImg,
    title: 'Consulting from the comfort of your home or office',
    subTitle:
      'Virtual consulting sessions from the comfort and safety of your home or office via video conference technology',
  },
  {
    id: 3,
    img: findImg,
    title: 'Find and compare consultants',
    subTitle:
      'Search and compare various consultants across different areas of specialisation and price',
  },
  {
    id: 4,
    img: scheduldeImg,
    title: 'Schedule an online booking instantly',
    subTitle: 'View consultants availability & schedule your booking',
  },
  {
    id: 5,
    img: payImg,
    title: 'Pay only after a successful consultation',
    subTitle: 'Only pay on the basis that your consultation was successful',
  },
];
const testominals = [
  {
    id: 1,
    name: 'Sebastiaan Weber',
    comment:
      'Managed to speak to a business consultant who gave me great insight, straight to the point replies, actionable and practical business advice. Thanks Conrati!',
    star: 5,
  },
  {
    id: 2,
    name: 'Max Peters',
    comment:
      'Growing a business is tough! No doubt, Conrati is an amazing way of getting fast, specialised, business advice when I need it most!',
    star: 5,
  },
  {
    id: 3,
    name: 'Johan Mathews',
    comment:
      'Nothing out there quite like Conrati when it comes to niche consulting! The array of business consultants listed is remarkable.',
    star: 5,
  },
  {
    id: 4,
    name: 'Jessica Wiggins',
    comment:
      'Amazing platform - it certainly beats the user interface and design of your competitors so well done on that front.',
    star: 5,
  },
];

const userCardPlaceholder = 'Consultants in Action';
const blogTitle = 'Recent business consultant blogs';

const blogsList = [];

const popularItemsList = [
  'ERP',
  'BUSINESS CONSULTING',
  'BUSINESS STRATEGY',
  'ORGANISATIONAL DEVELOPMENT',
  'FINANCIAL MODELING',
  'ATTORNEY',
  'LAWYER',
  'ACCOUNTING',
  'FUNDRAISING',
  'STARTUPS',
  'DIGITAL MARKETING STRATEGIST',
  'AI',
  'GROWTH HACKING',
];

const sliderTitle = 'Book world-class business consultants';
const sliderSubTitle = 'Leverage from the experience of micro niche experts';

export {
  initalTransformSize,
  benifitTitle,
  benefitsList,
  testominals,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderTitle,
  sliderSubTitle,
  themeTitle,
  sliderUserList,
};
