import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './AccountSettings.module.css';
import Input from '../../Common/Input/Input';
import { apiurl, isValidPassword } from '../../../utils';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      currentpassword: '',
      password: '',
      confirmpassword: '',
      passwordvalid: false,
      changepas: false,
      changepaserror: false,
      submit: false,
    };

    this.onChangePassword = this.onChangePassword.bind(this);
  }

  handleInput = (e) => {
    const { password } = this.state;
    if (e.target.name === 'currentpassword') {
      this.setState({ currentpassword: e.target.value });
    }

    if (e.target.name === 'newpassword') {
      if (isValidPassword(e.target.value)) {
        this.setState({ passwordvalid: true, password: e.target.value });
      } else {
        this.setState({ passwordvalid: false, password: e.target.value });
      }
    }

    if (e.target.name === 'confirmpassword' && e.target.value.length > 0) {
      if (e.target.value !== password) {
        this.setState({ error: true, passwordvalid: false });
      } else {
        const isPasswordValid = isValidPassword(e.target.value);
        this.setState({
          confirmpassword: e.target.value,
          error: false,
          passwordvalid: isPasswordValid,
        });
      }
    }
  };

  onChangePassword = () => {
    if (this.state.passwordvalid) {
      this.setState({ submit: true });

      const current = this.state.currentpassword;
      const newpass = this.state.confirmpassword;

      const { userToken } = this.props.user;

      const headers = {
        'Content-Type': 'application/json',
      };

      const data = {
        current_password: current,
        new_password: newpass,
      };

      headers.Authorization = `Bearer ${userToken}`;

      axios
        .post(`${apiurl}/v1/user/changepassword`, data, { headers })
        .then((result) => {
          if (result.data.success) {
            this.setState({ changepas: true, submit: false });
            this.props.closeModal();
            return toast.warn(result.data.message, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          this.setState({ changepaserror: true, submit: false });
          return toast.warn(result.data.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((err) => {
          this.setState({ changepaserror: true, submit: false });
        });
    }
  };

  handleDisablePassword = () => {
    const { passwordvalid, error, confirmpassword, password } = this.state;

    if (
      passwordvalid &&
      !error &&
      password.length > 0 &&
      confirmpassword.length > 0 &&
      password === confirmpassword
    ) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <div>
        <div className={styles.ebox}>
          <div className={styles.emsg}>
            <p className={styles.chngp}>Change Password</p>
            <Input
              type="password"
              name="currentpassword"
              placeholder="Current Password"
              htmlFor="Current Password"
              label="Current Password"
              onChange={this.handleInput}
              tooltip={!this.state.passwordvalid}
            />
            <Input
              type="password"
              name="newpassword"
              placeholder="New Password"
              htmlFor="New Password"
              label="New Password"
              onChange={this.handleInput}
              tooltip={!this.state.passwordvalid}
              tooltipmsg="Password must contain minimum 6 characters, including at least 1 uppercase letter, 1 lowercase letter and 1 number or symbol."
            />
            <Input
              type="password"
              name="confirmpassword"
              placeholder="Confirm Password"
              htmlFor="Confirm Password"
              label="Confirm Password"
              onChange={this.handleInput}
            />
            {this.state.error ? (
              <p style={{ fontSize: '11px', color: 'red', fontWeight: '300', marginLeft: '5px' }}>
                Password and Confirm Password do not match.
              </p>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          <button className={styles.btnc} onClick={this.props.closeModal}>
            CANCEL
          </button>
          <button
            disabled={this.handleDisablePassword()}
            className={styles.btn}
            onClick={this.onChangePassword}
          >
            {this.state.submit ? 'UPDATING' : 'CHANGE'}
          </button>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
