import React from 'react';
import styles from './HowItWork.module.scss';
import startTherapySVG from '../../assets/svg/start-therapy.svg';
import bookTherapistSVG from '../../assets/svg/book-therapist.svg';
import searchTherapistSVG from '../../assets/svg/search-therapist.svg';
import arrowSVG from '../../assets/svg/arrow.svg';

export default function HowItWork(): JSX.Element {
	return (
		<div className={styles.howItWork}>
			<h1 data-testid="quiz-how-works-title">How it Works</h1>
			<div className={styles.subTitle}>
				<p data-testid="quiz-how-works-subtitle">
					Stop the negative cycle and prioritise your mental health today
				</p>
			</div>

			<div className={styles.processes}>
				<div className={styles.process}>
					<div className={styles.processImg}>
						<img src={searchTherapistSVG} alt="Search therapist" />
					</div>
					<div className={styles.processText}>Search for your therapist</div>
				</div>
				<div className={styles.processArrow}>
					<img src={arrowSVG} alt="Arrow" />
				</div>
				<div className={styles.process}>
					<div className={styles.processImg}>
						<img src={bookTherapistSVG} alt="Book therapist" />
					</div>
					<div className={styles.processText}>Book your therapist</div>
				</div>
				<div className={styles.processArrow}>
					<img src={arrowSVG} alt="Arrow" />
				</div>
				<div className={styles.process}>
					<div className={styles.processImg}>
						<img src={startTherapySVG} alt="Start therapy" />
					</div>
					<div className={styles.processText}>Start therapy</div>
				</div>
			</div>
		</div>
	);
}
