import React from 'react';
import './safety.css';
import styles from '../Static.module.css';

const safety = () => {
  return (
    <div className={styles.brpage}>
      <div className={styles.brheader}>
        <h1>Trust And Safety</h1>
      </div>
      <div className={styles.brcontainer}>
        <div className="safetybody">
          <p className="c3">
            <span className="c2">
              Conrati&rsquo;s mission is to make advice accessible on demand. Providing a safe,
              secure, and trusted marketplace is an essential part of achieving that mission. We
              want to provide our users with the information that they need in order to ensure a
              safe and secure user experience.
            </span>
          </p>
          <h1 className="c5">
            <span className="c4">We have safeguards in place</span>
          </h1>
          <p className="c3">
            <span className="c2">
              Your trust in Conrati is important. We do our best to ensure you have a positive and
              safe experience with Conrati. Our experienced team is as passionate about innovation
              as our community is, and we&rsquo;re dedicated to making sure that we do everything we
              can to ensure a safe and secure experience on our platform.{' '}
            </span>
          </p>
          <p className="c3">
            <span className="c2">
              We use Amazon Web Services as hosting provider and protect your information using
              technical and administrative security measures to reduce the risks of loss, misuse,
              unauthorized access, disclosure and alteration of information. Some of the safeguards
              we use are firewalls and data encryption, physical access controls, and information
              access authorization controls.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Our team also investigates real-time feedback and concerns from our users. Any safety
              or security issues can be reported to{' '}
            </span>
            <span className="c8">
              <a className="c7" href="mailto:support@conrati.com">
                support@conrati.com
              </a>
            </span>
            <span className="c2">&nbsp;</span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We hold our users accountable for verifying the identity information they provide to
              making sure how and what they communicate when interacting with each other. Conrati
              does not tolerate abuse of our platform and takes appropriate action when necessary to
              maintain its integrity.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Providing a trusted platform is a two-way street. We expect everyone to comply with
              our&nbsp;Terms and Conditions and keep the lines of communication open and honest. Not
              doing so may jeopardize the confidence and trust of the community who is excited to
              use our offering of on demand consulting.
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <h1 className="c5">
            <span className="c4">Safeguards for Advice Receivers</span>
          </h1>
          <p className="c1">
            <span className="c2">
              Conrati is a marketplace for on demand consulting. When you need advice, it is
              important to only book a consultation with an Advice Provider capable of providing you
              with the advice that you need. We recommend that you study the experience,
              certifications, skills, service offerings and other information available on the
              public profile of the Advice Provider carefully before making a booking to assure
              yourself of his / her capabilities. No refunds are possible based on the quality of
              advice provided or received.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              When you intend to discuss non-public and / or sensitive information with an Advice
              Provider, you may want to consider uploading a non-disclosure-agreement into the
              online consultation and ask the Advice Provider to sign and upload a scanned copy of
              the agreement before you share any sensitive or non-public information during a
              consultation.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Note that Conrati cannot and does not take responsibility for any information that you
              may share with an Advice Provider during a consultation, nor for the advice provided
              by the Advice Provider. Conrati takes no responsibility concerning the disclosure of
              any information that you may have shared during a consultation, therefore our
              recommendation not to share any private, non-public or sensitive information with an
              Advice Provider without signing a non-disclosure-agreement first.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Conrati is a marketplace for on demand consulting, focusing on business consulting
              only. When we are made aware of fraudulent activities we may suspense the account of
              any Advice Receiver or Advice Provider.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Conrati carries out no background checks on any Advice Provider, nor do we take
              responsibility for or ensure the quality of advice provided, or any consequences as a
              result of advice provided.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              By using the platform, the Advice Receiver takes full responsibility for carrying out
              background checks and quality checks on Advice Providers if needed before booking a
              consultation.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <h1 className="c5">
            <span className="c4">Safeguards for Advice Providers</span>
          </h1>
          <p className="c1">
            <span className="c2">
              Conrati is a marketplace for on demand consulting. When you provide advice, it is
              important to note that you are doing so in your own and / or company name, and that
              Conrati takes no responsibility for any advice which you provide, nor for the
              consequences of advice provided. We recommend that you do not accept the disclosure of
              any non-public, personal or sensitive information by an Advice Receiver without first
              signing a non-disclosure-agreement with the Advice Receiver. You may also indicate to
              an Advice Receiver that the advice provided by you should be judged and applied only
              on the risk taken by the Advice Receiver.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              A marketplace is about trust and quality, and as an Advice Provider you need to take
              responsibility for maintaining an accurate and up to date profile and hourly
              availability. Without hourly availability maintained in your conrati calendar, no
              bookings can be made and your profile may be excluded from search results as no
              matching could be performed.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              By maintaining accurate and up to date information in your profile, account and
              calendar, and by acting and delivering professional advice on the platform, you can
              build a name of trust and expect more bookings from Advice Receivers who learned to
              trust you and the advice which you provide.
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1" id="h.gjdgxs">
            <span className="c2">
              There is no rating system of Advice Providers or consultations on the platform at the
              moment yet, but we plan to introduce a rating and feedback system soon.{' '}
            </span>
          </p>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              As Advice Provider, you are free to market your Profile and Availability outside of
              the Conrati platform by sharing a link to your profile on other media channels &ndash;
              for example Social Media channels that allow for inserting links on their platforms.
              By so doing, you can enhance your chances of being booked and paid on the Conrati
              platform.{' '}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default safety;
