import React from 'react';
import styles from './Chip.module.scss';

type ChipProps = {
	name: string;
};

export default function Chip(props: ChipProps): JSX.Element {
	const { name } = props;

	return (
		<button type="button" data-testid="quiz-chip" className={styles.chip}>
			{name}
		</button>
	);
}
