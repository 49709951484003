/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { UserCard } from '@conrati/ui';
import style from './style.module.scss';
import PageLoader from '../../../components/Common/PageLoader/PageLoader';

function SearchProfileSection(props) {
  const { searchData, isSearchFetching } = props;

  return (
    <div className={style.SearchProfileSection}>
      <h4 className={style.sectionTitle}>
        Find the <b>Right Advisor</b> for you
      </h4>

      <section className={style.profileSection}>
        <div className={style.title}>
          <h6 className={style.profileSectionTitle}>Browse More Profiles</h6>
          <div className={style.underline} />
        </div>

        <div className={style.userCardList}>
          {searchData.length === 0 && isSearchFetching ? (
            <PageLoader />
          ) : (
            searchData.map((user) => <UserCard key={`user=${user.userId}`} user={user} />)
          )}

          {searchData.length === 0 && !isSearchFetching && (
            <p className={style.nouser}>
              No results for your search. Please try searching once again using different criteria.
            </p>
          )}
        </div>
      </section>
    </div>
  );
}

export default SearchProfileSection;
