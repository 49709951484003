/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */

import React, { Component } from 'react';
import './calendarcss.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import { Map } from 'immutable';
import { toast } from 'react-toastify';
import Toolbar from './Toolbar/Toolbar';
import CalendarEvents from './Events/CalendarEvents';
import { isWeekend, apiurl } from '../../../../utils';
import PopUp from './PopUp/PopUp';

class UserCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      activeView: 'month',
      month: moment().month(),
      events: [],
      dateOfMonth: moment(),
      eventpopUp: false,
      selectedEvent: {},
      availbiltyAll: {},
      count: 0,
    };

    this.onNavigate = this.onNavigate.bind(this);
    this.changeView = this.changeView.bind(this);
    this.getNextMonth = this.getNextMonth.bind(this);
    this.getPrevMonth = this.getPrevMonth.bind(this);
    this.onSelectSlotCalendar = this.onSelectSlotCalendar.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.fetchDayEvents = this.fetchDayEvents.bind(this);
    this.fetchMonthEvents = this.fetchMonthEvents.bind(this);
    this.syncDayEvents = this.syncDayEvents.bind(this);
    this.onPopUpCancel = this.onPopUpCancel.bind(this);
    this.onBook = this.onBook.bind(this);
    this.fetchAvailabiltyServer = this.fetchAvailabiltyServer.bind(this);
    this.eventPropGetter = this.eventPropGetter.bind(this);
  }

  componentDidMount() {
    this.fetchMonthEvents();
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.availbiltyDay.length > 0) {
      await this.syncDayEvents(newProps);
    }
  }

  componentDidUpdate(prevProps) {
    const prevprops = Map(prevProps.availbiltyAll.availability);
    const currentprops = Map(this.props.availbiltyAll.availability);

    if (!prevprops.equals(currentprops)) {
      this.fetchMonthEvents();
    }
  }

  async fetchAvailabiltyServer() {
    const headers = {
      'Content-Type': 'application/json',
    };

    const availabiltyurl = `${apiurl}/v1/user/availability`;
    const { user } = this.props;

    headers.Authorization = `Bearer ${user.userToken}`;

    await axios.get(`${availabiltyurl}/all`, { headers }).then((result) => {
      if (result.data.success) {
        this.setState({
          availbiltyAll: result.data.result[0],
        });

        this.props.onSyncAvailabilty(result.data.result[0]);
      } else {
        throw new Error('Error');
      }
    });
  }

  onNavigate(date) {
    const currentdate = moment().startOf('month');
    const navdate = date;

    if (currentdate.isSameOrBefore(navdate)) {
      this.setState({ date });

      if (this.state.activeView === 'day') {
        this.fetchDayEvents(date);
      }

      if (this.state.activeView === 'month') {
        this.fetchMonthEvents();
      }
    }
  }

  changeView(view) {
    if (view === 'day') {
      this.setState({
        date: moment(),
        dateOfMonth: moment(),
        activeView: view,
        month: moment().month(),
      });
      this.fetchDayEvents(this.state.date);
    }

    if (view === 'month') {
      this.setState({ activeView: view });
      this.fetchMonthEvents();
    }
  }

  // Open a specific date in view
  openDateEvents(date) {
    this.setState({ date, activeView: 'day' });
    this.fetchDayEvents(date);
  }

  async fetchMonthEvents() {
    this.syncCalendar();
  }

  syncCalendar = async () => {
    await this.fetchAvailabiltyServer();

    if (this.state.availbiltyAll && this.state.activeView === 'month') {
      const customAvailability = this.props.availbiltyAll.customAvailability || [];
      const { availability } = this.props.availbiltyAll;
      const monthlyEvents = [];
      const daysInMonth = moment(this.state.dateOfMonth).daysInMonth();
      let todaydate;

      if (moment().format('M') === this.state.dateOfMonth.format('M')) {
        todaydate = Number(moment().format('D'));
      } else {
        todaydate = Number(moment(this.state.dateOfMonth).format('D'));
      }

      if (availability) {
        if (availability.isWeekdayOn && availability.isWeekendOn) {
          for (let i = todaydate - 1; i < daysInMonth; i += 1) {
            const date = i + 1;
            const newdate = new Date(this.state.dateOfMonth.format(`YYYY/MM/${date}`));

            const event = {
              id: i + 1,
              title: 'Available For Booking',
              start: newdate,
              end: newdate,
              type: 'recurring',
            };

            monthlyEvents.push(event);
          }

          this.setState({ events: monthlyEvents });
        } else if (availability.isWeekdayOn) {
          for (let i = todaydate - 1; i < daysInMonth; i += 1) {
            const date = i + 1;
            const newdate = new Date(this.state.dateOfMonth.format(`YYYY/MM/${date}`));
            const dayofdate = moment(newdate).day();
            if (dayofdate !== 0 && dayofdate !== 6) {
              const event = {
                id: i + 1,
                title: 'Available For Booking',
                start: newdate,
                end: newdate,
                type: 'recurring',
              };

              monthlyEvents.push(event);
            }
          }

          if (customAvailability.length > 0) {
            for (const i in customAvailability) {
              const date = moment.unix(customAvailability[i].from);
              if (Number(date.format('D')) >= todaydate) {
                const newdate = new Date(date.format('YYYY/MM/DD'));

                const event = {
                  id: i + 1,
                  title: 'Available For Booking',
                  start: newdate,
                  end: newdate,
                  type: 'recurring',
                };

                monthlyEvents.push(event);
              }
            }

            this.setState({ events: monthlyEvents });
          }
          this.setState({ events: monthlyEvents });
        } else if (availability.isWeekendOn) {
          for (let i = todaydate - 1; i < daysInMonth; i += 1) {
            const date = i + 1;
            const newdate = new Date(this.state.dateOfMonth.format(`YYYY/MM/${date}`));

            const dayofdate = moment(newdate).day();
            if (dayofdate === 0 || dayofdate === 6) {
              const event = {
                id: i + 1,
                title: 'Available For Booking',
                start: newdate,
                end: newdate,
                type: 'custom',
              };

              monthlyEvents.push(event);
            }
          }

          if (customAvailability.length > 0) {
            for (const i in customAvailability) {
              const date = moment.unix(customAvailability[i].from);
              if (Number(date.format('D')) >= todaydate) {
                const newdate = new Date(date.format('YYYY/MM/DD'));

                const event = {
                  id: i + 1,
                  title: 'Available For Booking',
                  start: newdate,
                  end: newdate,
                  type: 'custom',
                };

                monthlyEvents.push(event);
              }
            }

            this.setState({ events: monthlyEvents });
          }

          this.setState({ events: monthlyEvents });
        }
      } else if (!availability) {
        if (customAvailability.length > 0) {
          for (const i in customAvailability) {
            const date = moment.unix(customAvailability[i].from);
            if (Number(date.format('D')) >= todaydate) {
              const newdate = new Date(date.format('YYYY/MM/DD'));

              const event = {
                id: i + 1,
                title: 'Available For Booking',
                start: newdate,
                end: newdate,
                type: 'custom',
              };

              monthlyEvents.push(event);
            }
          }

          this.setState({ events: monthlyEvents });
        }
      }
    }
  };

  // Fetch day events from server
  fetchDayEvents(date) {
    const readDate = date.format('YYYY-MM-DD');
    const { userId } = this.props.profile;
    const { userToken } = this.props.user;

    const data = {
      user_id: userId,
      date: readDate,
      is_weekend: isWeekend(date.toDate()),
      userToken,
    };

    this.props.onFetchAvailabilty(data);
  }

  // Sync Day events from server
  syncDayEvents(newProps) {
    const events = newProps.availbiltyDay;
    const dayEvents = [];

    if (this.state.activeView === 'day') {
      for (const eventdata in events) {
        if (events[eventdata].isRecurring) {
          const starttime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].from).hour(),
              minute: moment.unix(events[eventdata].from).minute(),
              second: 0,
            })
            .format();
          const endtime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].to).hour(),
              minute: moment.unix(events[eventdata].to).minute(),
              second: 0,
            })
            .format();

          const data = {
            id: eventdata,
            title: events[eventdata].summary,
            start: new Date(starttime),
            end: new Date(endtime),
            type: 'recurring',
          };

          dayEvents.push(data);
        } else if (events[eventdata].isExternalEvent) {
          const starttime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].from).hour(),
              minute: moment.unix(events[eventdata].from).minute(),
              second: 0,
            })
            .format();
          const endtime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].to).hour(),
              minute: moment.unix(events[eventdata].to).minute(),
              second: 0,
            })
            .format();

          const data = {
            id: eventdata,
            title: events[eventdata].summary,
            start: new Date(starttime),
            end: new Date(endtime),
            type: 'external',
          };

          dayEvents.push(data);
        } else if (events[eventdata].isBooking) {
          const starttime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].from).hour(),
              minute: moment.unix(events[eventdata].from).minute(),
              second: 0,
            })
            .format();
          const endtime = moment()
            .set({
              year: this.state.date.year(),
              month: this.state.date.month(),
              date: this.state.date.date(),
              hour: moment.unix(events[eventdata].to).hour(),
              minute: moment.unix(events[eventdata].to).minute(),
              second: 0,
            })
            .format();

          const data = {
            id: eventdata,
            title: events[eventdata].summary,
            start: new Date(starttime),
            end: new Date(endtime),
            type: 'booking',
          };

          dayEvents.push(data);
        } else {
          const starttime = moment.unix(events[eventdata].from).format();
          const endtime = moment.unix(events[eventdata].to).format();

          const data = {
            id: events[eventdata].id,
            title: events[eventdata].summary,
            start: new Date(starttime),
            end: new Date(endtime),
            type: 'custom',
          };

          dayEvents.push(data);
        }
      }

      return this.setState({ events: dayEvents });
    }
  }

  async getNextMonth() {
    await this.setState({
      dateOfMonth: moment(this.state.dateOfMonth).add(1, 'months').startOf('month'),
      month: this.state.month + 1,
    });
    this.fetchMonthEvents();
  }

  async getPrevMonth() {
    if (this.state.month >= moment().format('M')) {
      await this.setState({
        dateOfMonth: moment(this.state.dateOfMonth).subtract(1, 'months').startOf('month'),
        month: this.state.month - 1,
      });
      this.fetchMonthEvents();
    }
  }

  renderToolbar() {
    const { date, activeView, month } = this.state;
    const toolbarWithProps = (props) => (
      <div>
        <Toolbar
          date={date}
          activeView={activeView}
          month={month}
          onNavigate={(navigateDate) => this.onNavigate(navigateDate)}
          changeView={(view) => this.changeView(view)}
          getNextMonth={this.getNextMonth}
          getPrevMonth={this.getPrevMonth}
          label={props.label}
        />
      </div>
    );

    return toolbarWithProps;
  }

  renderDayView = () => {
    const dayViewWithProps = () => {
      return (
        <div>
          <p>Event</p>
        </div>
      );
    };

    return dayViewWithProps;
  };

  renderEvent = () => {
    const EventWithProps = (props) => <CalendarEvents {...props} />;
    return EventWithProps;
  };

  onSelectSlotCalendar(select) {
    const selectdate = moment(select.start).add(1, 'day');

    if (this.state.activeView === 'month') {
      if (moment().isBefore(selectdate)) {
        return this.openDateEvents(moment(select.start));
      }
      return toast.warn('You have selected a past date', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (this.state.activeView === 'day') {
      this.setState({ selectedEvent: select, eventpopUp: true });
    }
  }

  onSelectEvent(event) {
    if (this.state.activeView === 'month') {
      this.setState({ date: moment(event.start), activeView: 'day' });
      this.fetchDayEvents(moment(event.start));
    }

    if (this.state.activeView === 'day') {
      if (event.type === 'custom') {
        this.setState({ selectedEvent: event, eventpopUp: true });
      }
    }
  }

  onPopUpCancel() {
    this.setState({ eventpopUp: false });
    this.fetchDayEvents(this.state.date);
  }

  onBook(e, event) {
    e.preventDefault();
    this.props.history.push({
      pathname: '/book',
      state: {
        start: event.start,
        end: event.end,
        user: this.props.profile,
      },
    });
  }

  eventPropGetter = (event) => {
    if (this.state.activeView === 'day') {
      let eventColor = '';

      switch (event.type) {
        case 'custom':
          eventColor = '#fbb613';
          break;
        case 'recurring':
          eventColor = '#fbb613';
          break;
        case 'external':
          eventColor = '#9A9A9A';
          break;
        case 'booking':
          eventColor = '#1054A5';
          break;
        default:
          break;
      }

      return {
        style: {
          boxSizing: 'border-box',
          border: 'none',
          display: 'block',
          backgroundColor: 'eventColor',
          backgroundImage: `linear-gradient(${eventColor} 24px, #fff 1px)`,
          backgroundSize: '1px 1px',
          color: '#ffffff',
          borderRadius: '3px',
        },
      };
    }
    return {};
  };

  render() {
    const formats = {
      weekdayFormat: 'dddd',
      timeGutterFormat: 'hh a',
      dayFormat: (date) => {
        const momentDate = moment(date);
        const dayOfMonth = momentDate.format('DD');
        const month = momentDate.format('MM');
        const dayOfWeek = momentDate.format('ddd');
        return `${dayOfMonth}/${month} ${dayOfWeek}`;
      },
      eventTimeRangeFormat: ({ start, end }) =>
        `${moment(start).format('h:mma')} – ${moment(end).format('h:mma')}`,
    };

    const localizer = momentLocalizer(moment);
    return (
      <div style={{ height: '720px', width: '100%' }}>
        {this.state.eventpopUp && (
          <PopUp
            fetchDayEvents={this.fetchDayEvents}
            user={this.props.user}
            {...this.state.selectedEvent}
            onCancel={this.onPopUpCancel}
            onBook={this.onBook}
          />
        )}
        <Calendar
          localizer={localizer}
          eventPropGetter={this.eventPropGetter}
          events={this.state.events}
          views={['month', 'day']}
          date={this.state.date.toDate()}
          style={{
            height: this.state.activeView === 'month' ? this.state.heightOfMonthlyCalendar : '100%',
          }}
          formats={formats}
          onNavigate={this.onNavigate}
          culture="en"
          onSelectEvent={this.onSelectEvent}
          showMultiDayTimes={false}
          components={{
            toolbar: this.renderToolbar(),
          }}
          onView={this.changeView}
          view={this.state.activeView}
          onSelectSlot={this.onSelectSlotCalendar}
        />
      </div>
    );
  }
}

export default UserCalendar;
