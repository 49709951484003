import moment from 'moment';

function onCloseCameraFeedback(streamData) {
  return new Promise((resolve, reject) => {
    try {
      const streamTracks = streamData.getTracks();
      streamTracks.forEach((track, ind) => {
        if (ind === streamTracks.length - 1) {
          track.stop();
          return resolve(true);
        }

        return track.stop();
      });
    } catch (err) {
      reject(err);
    }
  });
}

function countAB() {
  return true;
}

function enumerateDaysBetweenDatesSearch(startDate, endDate) {
  const dates = [];
  let days = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  dates.push(currDate.clone());

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }

  dates.push(lastDate);

  if (dates.length <= 7) {
    dates.forEach((dt) => days.push(dt.format('dd').toLowerCase()));
  } else {
    days = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
  }

  return days.join(',');
}

function getQueryParams(searchString) {
  const searchParams = new URLSearchParams(searchString);
  return searchParams.get('query') || '';
}

export { onCloseCameraFeedback, countAB, enumerateDaysBetweenDatesSearch, getQueryParams };
