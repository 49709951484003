import React from 'react';
import styles from './style.module.scss';
import searchIcon from '../../../assets/search-icon-sec-800.svg';

interface SelectSearchInputProps {
	onSearchInput(value: string): void;
	placeholder: string;
}

export const SelectSearchInput = ({
	onSearchInput,
	placeholder,
}: SelectSearchInputProps): JSX.Element => {
	return (
		<div
			className={styles.selectSearchInputContainer}
			data-testid="ui-native-select-search-input-container"
		>
			<div
				className={styles.selectSearchInputWrapper}
				data-testid="ui-native-select-search-input-wrapper"
			>
				<input
					className={styles.selectSearchInput}
					type="text"
					id="select-search-input"
					onChange={(event): void => {
						onSearchInput(event.target.value);
					}}
					placeholder={placeholder}
					data-testid="ui-native-select-search-input-element"
				/>
				<label
					htmlFor="select-search-input"
					className={styles.selectSearchIcon}
					data-testid="ui-native-select-search-input-icon"
				>
					<img
						src={searchIcon}
						alt="Search icon"
						data-testid="ui-native-select-search-icon-img"
					/>
				</label>
			</div>
		</div>
	);
};
