import React, { Component } from 'react';
import styles from './SessionComplete.module.css';
import { connect } from 'react-redux';
import enums from '../../../config/enum';
import { bindActionCreators } from 'redux';
import Headers from '../../Headers/Headers';

class SessionComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }

  componentDidMount() {
    let searchparams = new URLSearchParams(this.props.location.search);

    if (searchparams.has('username')) {
      this.setState({ username: searchparams.get('username') });
    }
  }
  handleSwitchAccount = (e) => {
    e.preventDefault();
    let userType;

    if (this.props.user.userType === enums.userType.ap) {
      userType = enums.userType.ar;
    }

    if (this.props.user.userType === enums.userType.ar) {
      userType = enums.userType.ap;
    }

    let user = {
      userType: userType,
      userToken: this.props.user.userToken,
    };

    this.props.onSwitchAccount(user);
  };

  render() {
    return (
      <div>
        <Headers />
        {this.props.user.userType === enums.userType.ar && (
          <div className={styles.marketing}>
            <div className={styles.marketingcard}>
              <div className={styles.micard}>
                <p className={styles.mtitle}>Session complete!</p>
                <p className={styles.msub}>
                  Thank you for using Conrati for your consulting needs. We would love to hear your
                  feedback
                  <br /> <a href="mailto:support@conrati.com">support@conrati.com </a>
                </p>
              </div>
              <div className={styles.buttonss}>
                <button
                  className={styles.btn}
                  onClick={(e) => {
                    this.props.history.replace(`/search`);
                  }}
                >
                  SEARCH
                </button>
                <button
                  className={styles.btn}
                  onClick={(e) => {
                    this.props.history.replace(`/${this.state.username}/calendar`);
                  }}
                >
                  BOOK AGAIN
                </button>
              </div>
            </div>
          </div>
        )}
        {this.props.user.userType === enums.userType.ap && (
          <div className={styles.marketing}>
            <div className={styles.marketingcard}>
              <div className={styles.micard}>
                <p className={styles.mtitle}>Session complete!</p>
                <p className={styles.msub}>
                  Thank you for using Conrati for your consulting needs. We would love to hear your
                  feedback
                  <br /> <a href="mailto:support@conrati.com">support@conrati.com </a>
                </p>
              </div>
              <div className={styles.buttonss}>
                <button
                  className={styles.btn}
                  onClick={(e) => {
                    this.props.history.replace(`/bookinghistory`);
                  }}
                >
                  BOOKING HISTORY
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapActionToPrps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionToPrps)(SessionComplete);
