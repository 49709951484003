import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styles from './Myaccount.module.css';
import Headers from '../Headers/Headers';
import { fetchSingleProfile } from '../../actions/profileAction';
import {
  fetchUserCardData,
  fetchUserBankAccount,
  addUserBankAccount,
  fetchKycDocuments,
} from '../../actions/tempAction';
import { fetchMasterData, fetchCountryCurrencyDetails } from '../../actions/masterAction';
import {
  editProfile,
  updateProfile,
  userBuisnessDetails,
  updateBuisnessDetails,
  uploadUserProfileImage,
  addNewCard,
  deleteUserState,
  userOauthData,
} from '../../actions/userAction';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import CalendarDetails from './CalendarDetails/CalendarDetails';
import BusinessDetails from './BusinessDetails';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import UserHeadEdit from './UserHeadEdit/UserHeadEdit';
import ImageUploadModal from '../Common/ImageUploadModal/ImageUploadModal';
import AccountSettings from './AccountSettings/AccountSettings';
import enums from '../../config/enum';
import messages from '../../config/message';
import { alert } from '../../utils';
import globalProptypes from '../../config/proptype';
import FreeSessionSettings from './FreeSessionSettings';
import { editProfile as editProfileService } from '../../services/userService';

class Myaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalopen: false,
    };

    this.onClickImg = this.onClickImg.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { stepsCompleted, user, onFetchCountryCurrencyDetails, location, onEditProfile } =
      this.props;

    // Fetch country currency details from ip
    onFetchCountryCurrencyDetails();

    if (!stepsCompleted && user.userType === enums.userType.ap) {
      alert(messages.apIncompleteAccunt);
      return this.props.history.replace('/myprofile/edit/step');
    }

    const searchParams = new URLSearchParams(location.search || '');
    const setting = searchParams.get('setting') || '';

    if (setting === 'free_consultaion' && user.userType === enums.userType.ar) {
      editProfileService({ user_type: enums.userType.ap }).then((res) => {
        if (res.success) {
          const resData = res.result;
          onEditProfile(resData);
        }
      });
    }

    this.props.onFetchMasterData();

    this.props.onUpdateProfile({ userToken: this.props.user.userToken });
    if (this.props.user.isBusinessRegistered) {
      this.props.onFetchUserBuisnessDetails(this.props.user.userToken);
    }

    if (this.props.user.isBankDetailsRegistered) {
      this.props.onFetchBankAccount(this.props.user);
    }

    return true;
  }

  onClickImg = (e) => {
    e.preventDefault();

    if (!this.props.user.isEmailVerified) {
      return toast.warn('Please verify your email address to continue.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return this.setState({ modalopen: true });
  };

  onClose() {
    this.setState({ modalopen: false });
  }

  render() {
    const { cardData, user, onProfileImageUpload, onFetchUserCardData, onAddNewCard } = this.props;
    const { modalopen } = this.state;
    return (
      <>
        <Headers />
        <div>
          {modalopen ? (
            <ImageUploadModal
              user={user}
              onProfileImageUpload={onProfileImageUpload}
              onClose={this.onClose}
            />
          ) : null}
          <div className={styles.userhead}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <UserHeadEdit {...user} onClickImg={this.onClickImg} />
          </div>
          <div className={styles.dtit}>
            <p className={styles.db}>My Account</p>
          </div>

          <div className={styles.container}>
            <div className={styles.editaccount}>
              <div className={styles.leftrow}>
                <div className={styles.details}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <PersonalDetails {...this.props} />
                  {user.userType === enums.userType.ar && (
                    <PaymentDetails
                      cardData={cardData}
                      onFetchUserCardData={onFetchUserCardData}
                      onAddNewCard={onAddNewCard}
                      user={user}
                    />
                  )}
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  {user.userType === enums.userType.ap && <FreeSessionSettings {...this.props} />}
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <AccountSettings {...this.props} />
                </div>
              </div>

              <div className={styles.rightrow}>
                <div className={styles.details}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <BusinessDetails {...this.props} />
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <CalendarDetails {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Myaccount.propTypes = {
  user: PropTypes.shape({}).isRequired,
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProfileImageUpload: PropTypes.func.isRequired,
  businessDetails: globalProptypes.businessDetailsState,
};

Myaccount.defaultProps = {
  businessDetails: {
    businessId: 0,
    businessName: '',
    businessRegistrationNo: '',
    typeOfBusiness: null,
    vatNo: '',
    userId: null,
    address: '',
    city: '',
    region: '',
    countryId: 0,
    postalCode: '',
    countryCode: '',
    isSmallEnterprise: false,
  },
};

const mapStateToProps = (state) => {
  const countriesMasterdata = state.masterdata.masterData.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
    code: country.countryCode,
    currencyCode: country.currencyCode,
  }));

  const currenciesMasterdata = state.masterdata.masterData.currencies.map((currency) => ({
    value: currency.currencyId,
    label: `${currency.currencyCode} (${currency.currencyName})`,
    code: currency.currencyCode,
    symbol: currency.currencySymbol,
  }));

  return {
    user: state.userState.user,
    cardData: state.temp.userCards,
    countries: state.masterdata.masterData.countries,
    currencies: state.masterdata.masterData.currencies,
    languages: state.masterdata.masterData.languages,
    categories: state.masterdata.masterData.categories,
    businessDetails: state.userState.user.business,
    request: state.request,
    bank_account: state.temp.bankAccounts,
    kycDocuments: state.temp.kycDocuments,
    accountCompleted: state.userState.accountCompleted,
    stepsCompleted: state.userState.stepsCompleted,
    ipCurrency: state.userState.currency,
    ipCountry: state.userState.country,
    currenciesMasterdata,
    countriesMasterdata,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchProfile: fetchSingleProfile,
      onFetchUserCardData: fetchUserCardData,
      onFetchMasterData: fetchMasterData,
      onEditProfile: editProfile,
      onUpdateProfile: updateProfile,
      onFetchUserBuisnessDetails: userBuisnessDetails,
      onUpdateUserBuisnessDetails: updateBuisnessDetails,
      onProfileImageUpload: uploadUserProfileImage,
      onAddNewCard: addNewCard,
      onFetchBankAccount: fetchUserBankAccount,
      onAddBankAccount: addUserBankAccount,
      onFetchKycDocuments: fetchKycDocuments,
      onDeleteAccount: deleteUserState,
      onSignInclickOauth: userOauthData,
      onFetchCountryCurrencyDetails: fetchCountryCurrencyDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Myaccount);
