import React from 'react';
import styles from './UserCard.module.scss';

interface SkillItemProps {
	skill: string;
}

const SkillItem = (props: SkillItemProps): JSX.Element => {
	return (
		<div className={styles.skill}>
			<p data-testid="ui-native-skill-item-text">{props.skill}</p>
		</div>
	);
};

export default SkillItem;
