import React, { Component } from 'react';
import styles from './Toolbar.module.css';
import arrowico from '../../../../Common/assets/arrow-down.svg';
import cx from 'classnames';
import moment from 'moment';

class Toolbar extends Component {
  render() {
    let { label, getNextMonth, month, date, onNavigate, activeView, getPrevMonth, changeView } =
      this.props;
    let activer = cx(styles.active, styles.cviewbtnr);
    let activel = cx(styles.active, styles.cviewbtnl);
    return (
      <div className={styles.toolbar}>
        <div className={styles.monthnav}>
          <button
            className={styles.nback}
            onClick={() => {
              if (activeView === 'month') {
                getPrevMonth();
                onNavigate(moment().month(month - 1));
              } else {
                onNavigate(moment(date).subtract(1, 'days'));
              }
            }}
          >
            <img src={arrowico} alt="back" width="9px" />
          </button>
          <button className={styles.nmonth}>{label}</button>
          <button
            className={styles.nforward}
            onClick={() => {
              if (activeView === 'month') {
                getNextMonth();
                onNavigate(moment().month(month + 1));
              } else {
                onNavigate(moment(date).add(1, 'days'));
              }
            }}
          >
            <img src={arrowico} alt="back" width="9px" />
          </button>
        </div>

        <div className={styles.cview}>
          <button
            className={activeView === 'day' ? activel : styles.cviewbtnl}
            onClick={() => changeView('day')}
          >
            Today
          </button>
          <button
            className={activeView === 'month' ? activer : styles.cviewbtnr}
            onClick={() => changeView('month')}
          >
            Month
          </button>
        </div>
      </div>
    );
  }
}

export default Toolbar;
