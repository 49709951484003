import React from 'react';
import PropTypes from 'prop-types';
import styles from './Placeholder.module.css';

const Card = (props) => {
  const {
    firstName,
    lastName,
    headline,
    currency,
    hourlyRate,
    country,
    languages,
    skills,
    primaryImg,
  } = props;

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardheadbg}>
          <div className={styles.cardhead}>
            <p>{`${currency.currencySymbol}${hourlyRate}`}</p>
            <img src={primaryImg} className={styles.cpimg} alt="" />
            <p>{country.countryName}</p>
          </div>
          <div className={styles.cardsh}>
            <p className={styles.name}>{`${firstName} ${lastName}`}</p>
            <p className={styles.pos}>{headline}</p>
          </div>
        </div>
        <div className={styles.lang}>
          <ul className={styles.langlist}>
            {languages.map((language, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className={styles.langitem}>
                {language.code.toUpperCase()}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.skills}>
          <ul className={styles.skillslist}>
            {skills &&
              skills.map((skill, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} className={styles.skill}>
                  {skill.toLowerCase()}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

Card.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    currencySymbol: PropTypes.string,
  }).isRequired,
  hourlyRate: PropTypes.string.isRequired,
  country: PropTypes.shape({
    countryName: PropTypes.string,
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  primaryImg: PropTypes.string.isRequired,
};

export default Card;
