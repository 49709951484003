import React from 'react';
import styles from './TherapyHealthSection.module.scss';
import checkMarkSVG from '../../assets/svg/check-mark.svg';

type TherapyCategoryItemProps = {
	category: string;
};

export default function TherapyCategoryItem(props: TherapyCategoryItemProps): JSX.Element {
	return (
		<div className={styles.therapyCategoryItem}>
			<div className={styles.therapyCategoryIcon}>
				<img src={checkMarkSVG} alt="Check mark" />
			</div>
			<span data-testid="quiz-therapy-category-title">{props.category}</span>
		</div>
	);
}
