import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ResetPassword.module.css';
import resetsucess from '../../Common/assets/password.svg';
import Input from '../../Common/Input/Input';
import LoaderButton from '../../Common/LoaderButton/LoaderButton';
import { isValidPassword } from '../../../utils';
import globalProptype from '../../../config/proptype';

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmpassword: '',
      error: false,
      resettoken: '',
      identifier: '',
      passwordvalid: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlparams = new URLSearchParams(location.search);
    const resettoken = urlparams.get('key');
    const identifier = urlparams.get('identifier');
    this.setState({ resettoken, identifier });
  }

  handleInput = (e) => {
    const { password } = this.state;
    if (e.target.name === 'password') {
      if (isValidPassword(e.target.value)) {
        this.setState({ passwordvalid: true, password: e.target.value });
      } else {
        this.setState({ passwordvalid: false, password: e.target.value });
      }
    }

    if (e.target.name === 'confirmpassword' && e.target.value.length > 0) {
      if (e.target.value !== password) {
        this.setState({ error: true });
      } else {
        this.setState({ confirmpassword: e.target.value });
        this.setState({ error: false });
      }
    }
  };

  handleSubmit = () => {
    const { password, resettoken, identifier } = this.state;
    const { onResetPassword } = this.props;
    if (isValidPassword(password) && password && resettoken && identifier) {
      const data = {
        password,
        reset_token: resettoken,
        identifier,
      };

      onResetPassword(data);
    }
  };

  render() {
    const { request } = this.props;
    const { password, passwordvalid, confirmpassword, error } = this.state;
    return (
      <div>
        <div className={styles.title}>
          <p>Create a new password</p>
        </div>
        {request.error && (
          <p
            style={{
              fontSize: '13px',
              color: 'red',
              fontWeight: '300',
              margin: '10px 0',
              textAlign: 'center',
            }}
          >
            Password Reset Failed
          </p>
        )}
        <div className={styles.ebox}>
          <div className={styles.emsg}>
            <Input
              type="password"
              name="password"
              placeholder="New Password"
              htmlFor="New Password"
              label="New Password"
              onChange={this.handleInput}
              tooltip={!passwordvalid}
              tooltipmsg="Password must contain minimum 6 characters, including at least 1 uppercase letter, 1 lowercase letter and 1 number or symbol."
            />
            <Input
              type="password"
              name="confirmpassword"
              placeholder="Confirm Password"
              htmlFor="Confirm Password"
              label="Confirm Password"
              onChange={this.handleInput}
            />
            {error ? (
              <p
                style={{
                  fontSize: '11px',
                  color: 'red',
                  fontWeight: '300',
                  marginLeft: '5px',
                }}
              >
                Password and Confirm Password do not match.
              </p>
            ) : (
              ''
            )}
          </div>
          <div className={styles.eimg}>
            <img src={resetsucess} alt="Email sent" width="100%" height="100%" />
          </div>
        </div>
        <div className={styles.snext}>
          <div className={styles.rbtns}>
            <LoaderButton
              color="success"
              retry={!request.submit && request.error}
              disabled={
                !(password === confirmpassword && password.length > 0 && isValidPassword(password))
              }
              active="SET PASSWORD"
              success="SENT"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

SetPassword.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  request: PropTypes.shape({
    error: PropTypes.bool,
    submit: PropTypes.bool,
  }).isRequired,
  location: globalProptype.location.isRequired,
};

export default SetPassword;
