import { LOADING, SUCCESS, ERROR_R, CLEAR_ER } from './types';

export const loading = (requesttype) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: requesttype || false,
  });
};

export const success = () => (dispatch) => {
  dispatch({
    type: SUCCESS,
  });
};
export const error = (err) => (dispatch) => {
  dispatch({
    type: ERROR_R,
    payload: err || {},
  });
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ER,
    payload: {},
  });
};
