import React from 'react';
import styles from './styles.module.scss';
import { OptionType } from '../select';
import checkMarkIcon from '../../../assets/check-mark.svg';

interface SelectOptionProps {
	option: OptionType;
	selectOption(option: OptionType): void;
}

export const SelectOption = ({
	option,
	selectOption,
}: SelectOptionProps): JSX.Element => {
	return (
		<button
			type="button"
			className={`${styles.selectOptionContainer} ${
				option.isSelected ? styles.selectOptionActive : ''
			}`}
			onClick={(): void => selectOption(option)}
			data-testid="ui-native-select-option-container"
		>
			<div
				className={styles.selectOptionText}
				data-testid="ui-native-select-option-text"
			>
				{option.label}
			</div>

			{!option.isSelected && (
				<div
					className={styles.selectOptionNotActive}
					data-testid="ui-native-select-option-not-active"
				/>
			)}

			{option.isSelected && (
				<div
					className={styles.selectOptionCheckedMark}
					data-testid="ui-native-select-option-checked-mark"
				>
					<img
						src={checkMarkIcon}
						data-testid="ui-native-select-option-checked-mark-icon"
						alt="Check mark icon"
					/>
				</div>
			)}
		</button>
	);
};
