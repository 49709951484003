import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Card.module.css';
import nextbtn from '../assets/forward.svg';
import freeIcon from '../assets/free.png';
import ButtonAuth from '../ButtonAuth/ButtonAuth';
import { weburl } from '../../../utils';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showskill: true,
      showservice: false,
    };
  }

  btnHandler = (type) => {
    if (type === 1) {
      this.setState({ showskill: true, showservice: false });
    } else if (type === 2) {
      this.setState({ showskill: false, showservice: true });
    }
  };

  // cardOnHover = type => {
  //   // if (type === 1) {
  //   //   this.setState({ mouseOver: true });
  //   // } else if (type === 0) {
  //   //   this.setState({ mouseOver: false });
  //   // } else {
  //   //   this.setState({ mouseOver: false });
  //   // }
  // };

  cardUserProfileClick = (username) => {
    window.open(`${weburl}/${username}`);
  };

  render() {
    const {
      firstName,
      lastName,
      headline,
      hourlyRateWithSymbol,
      country,
      languages,
      skills,
      serviceDescription,
      primaryImg,
      username,
      isFreeSessionOpted,
      isFreeSessionConsumed,
    } = this.props;
    const { showservice, showskill } = this.state;
    const dispnone = cx(styles.dispnone);
    const nnextdisabled = cx(styles.nnext, styles.disabled);
    const nprevdisabled = cx(styles.nprev, styles.disabled);

    return (
      <div className={styles.card} onFocus={() => {}}>
        <Link to={`/${username}`} target="_blank">
          <div className={styles.main}>
            <div className={styles.cardheadbg}>
              <div className={styles.cardhead}>
                <p>{`${hourlyRateWithSymbol}`}</p>
                <div className={styles.fico}>
                  {isFreeSessionOpted && !isFreeSessionConsumed && (
                    <img src={freeIcon} alt="free" className={styles.fimg} />
                  )}

                  <img src={primaryImg} className={styles.cpimg} alt="" />
                </div>
                <p>{country.countryName}</p>
              </div>
              <div className={showskill ? styles.cardsh : dispnone}>
                <p className={styles.name}>{`${firstName} ${lastName}`}</p>
                <p className={styles.pos}>{headline}</p>
              </div>
            </div>
            <div className={showskill ? styles.lang : dispnone}>
              <ul className={styles.langlist}>
                {languages.map((language, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className={styles.langitem}>
                    {language.code.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
            <div className={showskill ? styles.skills : dispnone}>
              <ul className={styles.skillslist}>
                {skills &&
                  skills.map((skill, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className={styles.skill}>
                      {skill.toLowerCase()}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Link>
        <div className={showservice ? styles.sdesc : dispnone}>
          <p className={styles.sdt}>Service Description</p>
          <p className={styles.sds}>{serviceDescription}</p>
        </div>

        <div className={styles.nbtn}>
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
            className={showskill ? nprevdisabled : styles.nprev}
            onClick={() => this.btnHandler(1)}
          >
            <img src={nextbtn} alt="Previous" width="100%" />
          </div>
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
            className={showservice ? nnextdisabled : styles.nnext}
            onClick={() => this.btnHandler(2)}
          >
            <img src={nextbtn} alt="Previous" width="100%" />
          </div>
        </div>

        {/* {!mouseOver ? null : (
          <div className={styles.clickbtn}>
            <div className={styles.clickbtnc}>
              <ButtonAuth isFreeSessionOpted={isFreeSessionOpted} username={username} />
            </div>
          </div>
        )} */}

        <div className={styles.spacer} />

        <div className={styles.clickbtn}>
          <div className={styles.clickbtnc}>
            <ButtonAuth isFreeSessionOpted={isFreeSessionOpted} username={username} />
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    currencySymbol: PropTypes.string,
  }).isRequired,
  hourlyRateWithSymbol: PropTypes.string.isRequired,
  country: PropTypes.shape({
    countryName: PropTypes.string,
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceDescription: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  primaryImg: PropTypes.string.isRequired,
  isFreeSessionOpted: PropTypes.bool.isRequired,
  isFreeSessionConsumed: PropTypes.bool,
};

Card.defaultProps = {
  isFreeSessionConsumed: false,
};

export default Card;
