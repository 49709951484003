import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import styles from './style.module.css';
import warningIco from '../../Common/assets/warning.png';
import PopUp from './PopUp';
import { onReviewProfile } from '../../../services/userService';

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

class AlertFrozenProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popUp: false,
    };
  }

  handleOnSubmitReview = () => {
    let toastId = null;

    this.setState({ popUp: false });

    toastId = toast.warn('Submitting profile for review....', {
      position: 'top-center',
      hideProgressBar: true,
      autoClose: false,
    });

    onReviewProfile()
      .then((result) => {
        return toast.update(toastId, {
          render: result.message,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        return toast.update(toastId, {
          render: err.message,
          autoClose: 3000,
        });
      });
  };

  render() {
    const { popUp } = this.state;
    return (
      <div className={styles.alertFp}>
        <div className={styles.alertFpBody}>
          <div className={styles.alertGroup}>
            <img src={warningIco} alt="Warning" className={styles.warningIco} />
            <p className={styles.alertText}>
              Your profile has been frozen due to lack of profile information.
            </p>
            <Link to="/help/faq?section=profile">Find out more here</Link>
          </div>

          <button
            type="submit"
            className={styles.sbtn}
            onClick={() => this.setState({ popUp: true })}
          >
            SUBMIT FOR REVIEW
          </button>

          <Modal
            isOpen={popUp}
            onRequestClose={() => this.setState({ popUp: false })}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <PopUp
              onCancel={() => this.setState({ popUp: false })}
              onSubmit={this.handleOnSubmitReview}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default AlertFrozenProfile;
