/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import style from './style.module.scss';
import user1img from '../../assets/user-search/user-placeholder.png';
import searchSymbol from '../../assets/search-symbol.png';
import { getQueryParams } from '../../utils';

const popularSkillList = [
  'Depression',
  'Anxiety',
  'Burnout',
  'Trauma',
  'Couples Counsellor',
  'Psychologist',
  'Counsellor',
];

function SearchBanner({ onSearchQuery }) {
  const location = useLocation();
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(getQueryParams(location.search));
  }, [location.search]);

  return (
    <div className={style.SearchBanner}>
      <div className={style.container}>
        <div className={style.placeholderImg}>
          <img src={user1img} alt="User" />
        </div>
        <div className={style.searchContainer}>
          <h4 className={style.mainText}>
            <b>Find the Advisor</b> <br /> you need to get you on track
          </h4>
          <div className={style.searchBar}>
            <img src={searchSymbol} alt="Search" className={style.searchSymbol} />
            <input
              type="text"
              placeholder="Try “Psychologist”"
              value={query}
              onChange={(ev) => setQuery(ev.target.value)}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  return onSearchQuery(query);
                }

                return false;
              }}
            />
            <button type="submit" onClick={() => onSearchQuery(query)}>
              SEARCH
            </button>
          </div>
          <div className={style.popularContainer}>
            <p className={style.poptext}>Popular:</p>
            <div className={style.skillList}>
              {popularSkillList.map((skill, ind) => (
                <button
                  key={`button-${ind}`}
                  className={style.skill}
                  type="button"
                  onClick={() => {
                    onSearchQuery(skill);
                  }}
                >
                  {skill}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBanner;
