import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TimePicker from 'rc-time-picker';
import cx from 'classnames';
import axios from 'axios';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import styles from './AvailabilityOnboard.module.css';
import 'rc-time-picker/assets/index.css';

import {
  syncAvailabiltyAll,
  fetchUserAvailablity,
  fetchUserAvailablityAll,
} from '../../../../actions/profileAction';
import { showCongratsMsg, updateProfile } from '../../../../actions/userAction';

import LargeWarning from '../../../Common/LargeWarning/LargeWarning';
import calendarico from '../../../Common/assets/calendarico.svg';
import sessionnew from '../../../Common/assets/mycalendar.svg';
import EditRadio from '../../../Common/EditRadio/EditRadio';

import { apiurl, alert, timeToNumber } from '../../../../utils';
import enums from '../../../../config/enum';
import messages from '../../../../config/message';

const format = 'h:mm a';

class AvailabilityOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekdayFrom: '',
      weekdayTo: '',
      weekendFrom: '',
      weekendTo: '',
      isWeekdayOn: false,
      isWeekendOn: false,
      edit: false,
      selectactive: false,
      required: false,
      modalIsOpen: false,
    };

    this.onSelActive = this.onSelActive.bind(this);
  }

  componentDidMount() {
    this.onLoad();
    this.onPreloadSave();
  }

  onUnload = () => {
    this.onPreloadSave();
  };

  onLoad = () => {
    this.setState({
      edit: true,
      weekdayFrom: moment().set({ hour: 9, minute: 0, second: 0 }),
      weekdayTo: moment().set({ hour: 17, minute: 0, second: 0 }),
      isWeekdayOn: true,
    });
  };

  onPreloadSave = () => {
    const headers = {
      'Content-Type': 'application/json',
    };

    const { user } = this.props;

    const data = {
      weekday_from: moment().set({ hour: 9, minute: 0, second: 0 }).unix(),
      weekday_to: moment().set({ hour: 17, minute: 0, second: 0 }).unix(),
      weekend_from: '',
      weekend_to: '',
      time_zone: user.timeZone,
    };

    const availabiltyurl = `${apiurl}/v1/user/availability`;

    headers.Authorization = `Bearer ${user.userToken}`;

    axios.put(`${availabiltyurl}`, data, { headers }).then(() => {
      ReactGA.pageview('/myprofile/ap/profile_public');

      const userStateServer = {
        userToken: this.props.user.userToken,
        registration_steps_completed: enums.profileCompleted.complete,
        registrationComplete2: 1,
      };

      this.props.onUpdateProfile(userStateServer);
    });
  };

  onChange = (value, type) => {
    const { weekdayTo, weekendTo } = this.state;

    if (type === 'weekdayFrom') {
      const weekdayFromTime = timeToNumber(value);
      const weekdayToTime = timeToNumber(weekdayTo);

      if (weekdayToTime > 23.5 || weekdayToTime < weekdayFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekdayFrom: value, required: false });
    }
    if (type === 'weekdayTo') {
      const { weekdayFrom } = this.state;
      const weekdayToTime = timeToNumber(value);
      const weekdayFromTime = timeToNumber(weekdayFrom);

      if (weekdayToTime === 0) {
        alert(messages.availabilityTimeInvalid);
        return this.setState({ weekdayTo: value.subtract(30, 'm'), required: false });
      }

      if (weekdayToTime > 23.5 || weekdayToTime < weekdayFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekdayTo: value, required: false });
    }
    if (type === 'weekendFrom') {
      const weekendFromTime = timeToNumber(value);
      const weekendToTime = timeToNumber(weekendTo);

      if (weekendToTime > 23.5 || weekendToTime < weekendFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekendFrom: value, required: false });
    }
    if (type === 'weekendTo') {
      const { weekdayFrom } = this.state;
      const weekendToTime = timeToNumber(value);
      const weekendFromTime = timeToNumber(weekdayFrom);

      if (weekendToTime === 0) {
        alert(messages.availabilityTimeInvalid);
        return this.setState({ weekendTo: value.subtract(30, 'm'), required: false });
      }

      if (weekendToTime > 23.5 || weekendToTime < weekendFromTime) {
        return alert(messages.availabilityTimeInvalid);
      }

      return this.setState({ weekendTo: value, required: false });
    }

    return true;
  };

  onCheck = (value, type) => {
    if (type === 1) {
      this.setState({ isWeekdayOn: value, weekdayFrom: null, weekdayTo: null });
    }

    if (type === 2) {
      this.setState({ isWeekendOn: value, weekendFrom: null, weekendTo: null });
    }
  };

  onSelActive(status, type) {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  }

  onSubmitRecuring = () => {
    const { weekdayFrom, weekdayTo, weekendFrom, weekendTo, isWeekdayOn, isWeekendOn } = this.state;
    const { user } = this.props;

    const headers = {
      'Content-Type': 'application/json',
    };

    let data = {};

    if (isWeekdayOn && isWeekendOn) {
      if (weekdayFrom && weekdayTo && weekendFrom && weekendTo) {
        const momweekdayFrom = moment(this.state.weekdayFrom).unix();
        const momweekdayTo = moment(this.state.weekdayTo).unix();
        const momweekendFrom = moment(this.state.weekendFrom).unix();
        const momweekendTo = moment(this.state.weekendTo).unix();

        data = {
          weekday_from: momweekdayFrom,
          weekday_to: momweekdayTo,
          weekend_from: momweekendFrom,
          weekend_to: momweekendTo,
          time_zone: user.timeZone,
        };
      }
    }

    if (isWeekdayOn && !isWeekendOn) {
      if (weekdayFrom && weekdayTo) {
        const momweekdayFrom = moment(this.state.weekdayFrom).unix();
        const momweekdayTo = moment(this.state.weekdayTo).unix();

        data = {
          weekday_from: momweekdayFrom,
          weekday_to: momweekdayTo,
          time_zone: user.timeZone,
        };
      } else {
        return alert('You are required to maintain atleast one availabilty!');
      }
    }

    if (isWeekendOn && !isWeekdayOn) {
      if (weekendFrom && weekendTo) {
        const momentweekendFrom = moment(this.state.weekendFrom).unix();
        const momentweekendTo = moment(this.state.weekendTo).unix();
        data = {
          weekend_from: momentweekendFrom,
          weekend_to: momentweekendTo,
          time_zone: user.timeZone,
        };
      } else {
        return this.setState({
          required: true,
        });
      }
    }

    if (!isWeekendOn && !isWeekdayOn) {
      return alert('You are required to maintain atleast one availabilty!');
    }

    let toastId = null;

    toastId = toast.warn('Configuring Availability..', {
      position: 'top-center',
      hideProgressBar: true,
    });

    const availabiltyurl = `${apiurl}/v1/user/availability`;
    headers.Authorization = `Bearer ${user.userToken}`;

    return axios
      .put(`${availabiltyurl}`, data, { headers })
      .then((result) => {
        if (result.data.success) {
          const userStateServer = {
            userToken: this.props.user.userToken,
            registration_steps_completed: enums.profileCompleted.complete,
            registrationComplete2: 1,
          };

          this.props.onUpdateProfile(userStateServer);

          this.props.onSyncAvailabilty({
            availability: result.data.result[0],
          });

          toast.update(toastId, {
            render: 'Availabilty set successfully!',
            autoClose: 1000,
          });

          this.props.onShowFinal('Final');
        } else {
          throw new Error('Error');
        }
      })
      .catch(() => {
        return alert('There was an error in setting your availabilty. Please try again!');
      });
  };

  disabledHours = (from) => {
    if (from) {
      const currentTime = from.format('H');
      const arr = [];

      for (let i = 0; i < currentTime; i += 1) {
        arr.push(i);
      }

      return arr;
    }

    return [];
  };

  disabledMinutes = (h, from) => {
    if (from) {
      const currentTime = Number(from.format('H'));

      if (h === currentTime) {
        const arr = [];

        for (let i = 0; i < 30; i += 1) {
          arr.push(i);
        }

        return arr;
      }
    }

    return [];
  };

  render() {
    const { isWeekdayOn, isWeekendOn, weekdayFrom, weekdayTo, weekendFrom, weekendTo } = this.state;

    return (
      <div className={styles.availabiltybody}>
        <LargeWarning
          hideclose
          icon={sessionnew}
          title="Configure your availability for bookings!"
          body="Please set your general availability below (the default setting is weekdays between 9:00am-5:00pm). Conrati automatically removes availability when you have events in your synchronised calendar. The more availability you set, the greater is the chance of bookings."
          link="/help/faq?section=calendar"
          linktext="For more information, read our FAQ’s on availability"
        />

        <div className={styles.calendarWeekend}>
          <div
            className={this.state.edit ? cx(styles.weekend, styles.cardborder) : styles.weekend}
            ref={(editelem) => {
              this.editelem = editelem;
            }}
          >
            <div className={styles.weekflex}>
              <div className={styles.calinfo}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={calendarico} alt="My calendar" />
                  <p className={styles.wphead}>General Availability</p>
                </div>

                <p className={styles.wpheadr}>
                  YOUR TIME ZONE - {this.props.user.timeZone}
                </p>
              </div>
              <div className={styles.weekflexe}>
                <div className={styles.lbox}>
                  <div className={styles.scheck}>
                    <p className={styles.wtit}>Weekdays (Mon - Fri) </p>
                    <div className={styles.tbtn}>
                      <EditRadio
                        tooltipmsg={isWeekdayOn ? 'Disable Weekdays' : 'Enable Weekdays'}
                        label=""
                        onCheck={(e) => this.onCheck(e, 1)}
                        checked={!!isWeekdayOn}
                      />
                      <p className={styles.etext}>{isWeekdayOn ? 'Enabled' : 'Disabled'}</p>
                    </div>
                  </div>
                  <p className={styles.wsub}>Available From</p>
                  <div className={styles.from}>
                    <TimePicker
                      showSecond={false}
                      disabled={!isWeekdayOn}
                      defaultValue={weekdayFrom || moment().startOf('date')}
                      value={weekdayFrom || null}
                      className="xxx"
                      onChange={(e) => this.onChange(e, 'weekdayFrom')}
                      format={format}
                      use12Hours
                      inputReadOnly
                      onOpen={(e) => this.onSelActive(e, 1)}
                      onClose={(e) => this.onSelActive(e, 2)}
                      minuteStep={30}
                    />

                    <p className={styles.to}>TO</p>

                    <TimePicker
                      showSecond={false}
                      disabled={!(this.state.isWeekdayOn || this.state.weekdayFrom)}
                      defaultValue={weekdayTo || moment().startOf('date')}
                      value={weekdayTo || null}
                      className="xxx"
                      onChange={(e) => this.onChange(e, 'weekdayTo')}
                      format={format}
                      use12Hours
                      inputReadOnly
                      onOpen={(e) => this.onSelActive(e, 1)}
                      onClose={(e) => this.onSelActive(e, 2)}
                      disabledHours={() => this.disabledHours(weekdayFrom)}
                      disabledMinutes={(e) => this.disabledMinutes(e, weekdayFrom)}
                      minuteStep={30}
                    />
                  </div>
                </div>
                <div className={styles.rbox}>
                  <div className={styles.scheck}>
                    <p className={styles.wtit}>Weekends (Sat - Sun) </p>
                    <div className={styles.tbtn}>
                      <EditRadio
                        tooltipmsg={isWeekendOn ? 'Disable Weekend' : 'Enable Weekend'}
                        label=""
                        onCheck={(e) => this.onCheck(e, 2)}
                        checked={!!isWeekendOn}
                      />
                      <p className={styles.etext}>{isWeekendOn ? 'Enabled' : 'Disabled'}</p>
                    </div>
                  </div>
                  <p className={styles.wsub}>Available From</p>
                  <div className={styles.from}>
                    <TimePicker
                      showSecond={false}
                      disabled={!this.state.isWeekendOn}
                      defaultValue={weekendFrom || moment().startOf('date')}
                      value={weekendFrom || null}
                      className="xxx"
                      onChange={(e) => this.onChange(e, 'weekendFrom')}
                      format={format}
                      use12Hours
                      inputReadOnly
                      onOpen={(e) => this.onSelActive(e, 1)}
                      onClose={(e) => this.onSelActive(e, 2)}
                      minuteStep={30}
                    />

                    <p className={styles.to}>TO</p>

                    <TimePicker
                      showSecond={false}
                      disabled={!(this.state.isWeekendOn || this.state.weekendFrom)}
                      defaultValue={weekendTo || moment().startOf('date')}
                      value={weekendTo || null}
                      className="xxx"
                      onChange={(e) => this.onChange(e, 'weekendTo')}
                      format={format}
                      use12Hours
                      inputReadOnly
                      onOpen={(e) => this.onSelActive(e, 1)}
                      onClose={(e) => this.onSelActive(e, 2)}
                      disabledHours={() => this.disabledHours(weekendFrom)}
                      disabledMinutes={(e) => this.disabledMinutes(e, weekendFrom)}
                      minuteStep={30}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.sbtn}>
            <button onClick={this.onSubmitRecuring} className={styles.vcalbtnpop}>
              SET AVAILABILTY
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profiles.profile,
    availbiltyDay: state.profiles.availbiltyDay,
    availbiltyAll: state.profiles.availabilityAll,
    request: state.request,
    user: state.userState.user,
    accountCompleted: state.userState.accountCompleted,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchAvailabilty: fetchUserAvailablity,
      onFetchAvailabiltyAll: fetchUserAvailablityAll,
      onSyncAvailabilty: syncAvailabiltyAll,
      onShowCongratsMsg: showCongratsMsg,
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(AvailabilityOnboard);
