import React from 'react';
import cm from 'classnames';
import styles from '../styles.module.scss';
import globalProptypes from '../../../../config/proptype/index';
import enums from '../../../../config/enum';

function ReJoin({ userData }) {
  return (
    <p className={cm(styles.ititle, styles.blinkMe)}>
      Waiting for Advice {userData.userType === enums.userType.ap ? 'Provider' : 'Reciever'} to
      rejoin the session again
    </p>
  );
}

ReJoin.propTypes = {
  userData: globalProptypes.user.isRequired,
};

export default ReJoin;
