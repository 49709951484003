import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import styles from './KycDetails.module.css';
import { apiurl } from '../../../utils/index';
import EditInput from '../../Common/EditInput/EditInput';
import EditDate from '../../Common/EditDate/EditDate';
import EditSelect from '../../Common/EditSelect/EditSelect';

class KycPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      businessName: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      typeOfBusiness: 0,
    };
  }

  componentDidMount() {
    if (this.props.buisnessDetails) {
      this.setState({
        businessName: this.props.buisnessDetails.businessName,
        typeOfBusiness: this.props.buisnessDetails.typeOfBusiness,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        dateOfBirth: this.props.user.dateOfBirth,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.buisnessDetails) {
      this.setState({
        businessName: newprops.buisnessDetails.businessName,
        typeOfBusiness: this.props.buisnessDetails.typeOfBusiness,
        firstName: newprops.user.firstName,
        lastName: newprops.user.lastName,
        dateOfBirth: newprops.user.dateOfBirth,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onUpdateDetails = (e) => {
    e.preventDefault();

    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.dateOfBirth &&
      this.state.businessName
    ) {
      this.setState({
        uploading: true,
      });

      const headers = {
        'Content-Type': 'application/json',
      };

      headers.Authorization = `Bearer ${this.props.user.userToken}`;

      const data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        business_name: this.state.businessName,
        date_of_birth: this.state.dateOfBirth,
        mangopay_user_id: this.props.user.mangopayUserId,
      };

      axios
        .put(`${apiurl}/v1/payment/user`, data, { headers })
        .then((result) => {
          if (result.data.success) {
            this.setState({
              uploading: false,
            });

            this.props.onConfirm();
          } else {
            toast.warn(result.data.message, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            throw new Error('Error');
          }
        })
        .catch((err) => {
          this.setState({
            uploading: false,
          });
        });
    } else {
      toast.warn('Please fill the mandatory details', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  render() {
    const typeofbuisness = [
      {
        value: 0,
        label: 'Freelancer / Soletrader',
      },
      {
        value: 1,
        label: 'Business',
      },
    ];

    const userCountry = this.props.user.countryId;

    const countries = this.props.countries.map((country) => ({
      value: country.countryId,
      label: country.countryName,
    }));

    const selectedCountry = userCountry
      ? countries.filter((arr) => {
          return arr.value === userCountry;
        })
      : null;

    const selectedTypeOfBusiness =
      this.state.typeOfBusiness >= 0
        ? typeofbuisness.filter((arr) => {
            return arr.value === this.state.typeOfBusiness;
          })
        : null;

    return (
      <div className={styles.selectpop}>
        <p className={styles.alisubikbm}>
          Before uploading your KYC documents for your soletrader or business, you must confirm that
          your data records will match the KYC documents that will be uploaded.
        </p>
        <EditInput
          htmlFor="Business Name"
          label="Business Name"
          value={this.state.businessName}
          placeholder="Business Name"
          onChange={this.onChange}
          name="businessName"
        />
        <EditInput
          htmlFor="First Name"
          label="First Name"
          value={this.state.firstName}
          placeholder="First Name"
          onChange={this.onChange}
          name="firstName"
        />
        <EditInput
          htmlFor="Last Name"
          label="Last Name"
          value={this.state.lastName}
          placeholder="Last Name"
          onChange={this.onChange}
          name="lastName"
        />
        {this.state.dateOfBirth && (
          <EditDate
            label="Date of Birth"
            date={this.state.dateOfBirth}
            onChange={this.onChange}
            name="dateOfBirth"
          />
        )}
        <EditSelect
          label="Country"
          options={[]}
          selected={selectedCountry}
          placeholder="Select Country"
          onActive={this.onSelActive}
          isDisabled
        />
        <EditSelect
          label="Type of Business"
          selected={selectedTypeOfBusiness}
          options={[]}
          placeholder="Select your business type"
          onActive={this.onSelActive}
          isDisabled
        />

        <div className={styles.buttons}>
          <button className={styles.btnc} onClick={this.props.closeModal}>
            CANCEL
          </button>
          <button
            className={styles.btn}
            disabled={!!this.state.uploading}
            onClick={this.onUpdateDetails}
          >
            {this.state.uploading ? 'CONFIRMING' : 'CONFIRM'}
          </button>
        </div>
      </div>
    );
  }
}

export default KycPopUp;
