import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import {
  FETCH_USER_CARD,
  FETCH_ALL_UBODOC,
  FETCH_ALL_COUNTRIES,
  FETCH_BANK_ACCOUNT,
  FETCH_KYC_DOCUMENT,
  UPDATE_PROFILE,
  CALCULATE_VAT,
  FETCH_BALANCE,
  FETCH_KYCHELPTEXT,
} from './types';
import { alert, apiRequest as API } from '../utils';
// eslint-disable-next-line import/no-cycle
import { updateProfile } from './userAction';

export const fetchUserCardData = (user) => (dispatch) => {
  const data = {
    mangopay_user_id: user.mangoPayUserId,
  };

  API('POST', '/v1/payment/cards', data)
    .then((res) => {
      dispatch({
        type: FETCH_USER_CARD,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchUserBankAccount = () => (dispatch) => {
  API('GET', '/v1/payment/bank')
    .then((res) => {
      dispatch({
        type: FETCH_BANK_ACCOUNT,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const addUserBankAccount = (user, data) => (dispatch) => {
  let toastId = null;
  toastId = toast.warn('Updating bank details', {
    position: 'top-center',
    hideProgressBar: true,
  });

  API('POST', '/v1.1/bank/iban', data, {}, { modifyBody: false })
    .then((res) => {
      ReactGA.pageview('/ap/bank_account');
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          isBankDetailsRegistered: true,
        },
      });

      dispatch(updateProfile({ is_bank_details_registered: true }));

      dispatch({
        type: FETCH_BANK_ACCOUNT,
        payload: res.result[0],
      });
      return toast.update(toastId, {
        render: 'Bank details updated',
        autoClose: 1000,
      });
    })
    .catch(() => {
      return toast.update(toastId, {
        render: 'Your bank account could not be added as have provided invaild bank details',
        autoClose: 1000,
      });
    });
};

export const syncBankAccount = (data) => (dispatch) => {
  dispatch(updateProfile({ is_bank_details_registered: true }));
  dispatch({
    type: FETCH_BANK_ACCOUNT,
    payload: data,
  });
};

export const addUserBankAccountOther = (user, data) => (dispatch) => {
  let toastId = null;
  toastId = toast.warn('Updating bank details', {
    position: 'top-center',
    hideProgressBar: true,
  });

  API('POST', '/v1.1/bank/other', data, {}, { modifyBody: false })
    .then((res) => {
      ReactGA.pageview('/ap/bank_account');
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          isBankDetailsRegistered: true,
        },
      });

      dispatch(updateProfile({ is_bank_details_registered: true }));

      dispatch({
        type: FETCH_BANK_ACCOUNT,
        payload: res.result[0],
      });
      return toast.update(toastId, {
        render: 'Bank details updated',
        autoClose: 1000,
      });
    })
    .catch(() => {
      return toast.update(toastId, {
        render: 'Your bank account could not be added as have provided invaild bank details',
        autoClose: 1000,
      });
    });
};

export const addUserBankAccountGb = (user, data) => (dispatch) => {
  let toastId = null;
  toastId = toast.warn('Updating bank details', {
    position: 'top-center',
    hideProgressBar: true,
  });

  API('POST', '/v1.1/bank/gb', data, {}, { modifyBody: false })
    .then((res) => {
      ReactGA.pageview('/ap/bank_account');
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          isBankDetailsRegistered: true,
        },
      });

      dispatch(updateProfile({ is_bank_details_registered: true }));

      dispatch({
        type: FETCH_BANK_ACCOUNT,
        payload: res.result[0],
      });
      return toast.update(toastId, {
        render: 'Bank details updated',
        autoClose: 1000,
      });
    })
    .catch(() => {
      return toast.update(toastId, {
        render: 'Your bank account could not be added as have provided invaild bank details',
        autoClose: 1000,
      });
    });
};

export const fetchKycDocuments = () => (dispatch) => {
  API('GET', '/v1/payment/kyc')
    .then((res) => {
      dispatch({
        type: FETCH_KYC_DOCUMENT,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchAllCountries = () => (dispatch) => {
  API('GET', '/v1/common/masterdata/countries')
    .then((res) => {
      dispatch({
        type: FETCH_ALL_COUNTRIES,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchAllUboDocs = () => (dispatch) => {
  API('GET', '/v1/payment/kyc/ubo')
    .then((res) => {
      dispatch({
        type: FETCH_ALL_UBODOC,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchWalletBalance = () => (dispatch) => {
  API('GET', '/v1.1/bank/balance')
    .then((res) => {
      dispatch({
        type: FETCH_BALANCE,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const fetchKycHelpText = (user) => (dispatch) => {
  API('GET', `/v1/payment/kychelptext/${user.countryId}`)
    .then((res) => {
      dispatch({
        type: FETCH_KYCHELPTEXT,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const resetUserPassword = (post) => (dispatch) => {
  const data = {
    password: post.password,
    reset_token: post.reset_token,
    identifier: post.identifier,
  };

  API('POST', '/v1/user/resetpassword', data)
    .then(() => {
      alert('Password reset successfully.');
      dispatch(replace('/signin'));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const calculateVat = (data) => (dispatch) => {
  API('POST', '/v1/payment/vat', data)
    .then((res) => {
      dispatch({
        type: CALCULATE_VAT,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};
