/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import React from 'react';
import moment from 'moment';
import styles from './style.module.scss';
import dateImg from '../../../components/Common/assets/date.svg';
import clockImg from '../../../components/Common/assets/clock.svg';

function InfoSessionHeader({ bookingData }) {
  const { topicDescription, title, dateOfBooking, from, to, apUserData, arUserData } = bookingData;

  return (
    <div className={styles.infoTab}>
      <div className={styles.details}>
        <p className={styles.title}>Session Overview</p>
        <p className={styles.stitle}>{title}</p>
        <p className={styles.sdes}>{topicDescription}</p>
      </div>

      <div className={styles.datetime}>
        <div className={styles.date}>
          <img src={dateImg} alt="date" />
          <p>{moment(dateOfBooking).format('LL')}</p>
        </div>
        <div className={styles.date}>
          <img src={clockImg} alt="date" />
          <p>
            {moment.unix(from).format('hh:mm A')} - {moment.unix(to).format('hh:mm A')}
          </p>
        </div>
      </div>

      <div className={styles.userinfo}>
        <div className={styles.users}>
          {apUserData.primaryImg ? (
            <img src={apUserData.primaryImg} alt="ar" />
          ) : (
            <div className={styles.usl}>
              {apUserData.firstName.charAt(0) + apUserData.lastName.charAt(0)}
            </div>
          )}
          <div className={styles.userd}>
            <p className={styles.utit}>Consultant</p>
            <p className={styles.udes}>{`${apUserData.firstName} ${apUserData.lastName}`}</p>
          </div>
        </div>

        <div className={styles.users}>
          {arUserData.primaryImg ? (
            <img src={arUserData.primaryImg} alt="ar" />
          ) : (
            <div className={styles.usl}>
              {arUserData.firstName.charAt(0) + arUserData.lastName.charAt(0)}
            </div>
          )}
          <div className={styles.userd}>
            <p className={styles.utit}>Advisee</p>
            <p className={styles.udes}>{`${arUserData.firstName} ${arUserData.lastName}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoSessionHeader;
