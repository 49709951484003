import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './UnauthHeader.module.scss';
import logo_yellow from '../../Common/assets/logo-yellow.png';

// Can see header buttons on pages
function canSeeHeaderButtons(experiment = false) {
  if (experiment) {
    return false;
  }

  return true;
}

function UnauthHeader(props) {
  const inHomePage = props?.router?.location?.pathname === '/';

  return (
    <div>
      <header className={inHomePage ? styles.homepageHeader : styles.header}>
        <div className={styles.container}>
          <Link to="/">
            <img src={logo_yellow} alt="conrati-logo" width="110px" className={styles.logo} />
          </Link>
          {canSeeHeaderButtons(props.experiment) && (
            <nav className={styles.links}>
              <a
                href="https://blog.conrati.com"
                className={styles.blogButton}
                target="_blank"
                rel="noreferrer noopener"
              >
                BLOG
              </a>
              <div className={styles.separator} />
              <a href="/signin" className={styles.secondaryBtn}>
                SIGN IN
              </a>
              <a href="/register" className={styles.primaryBtn}>
                REGISTER
              </a>
            </nav>
          )}
        </div>
      </header>
    </div>
  );
}

UnauthHeader.propsTypes = {
  router: PropTypes.object.isRequired,
};

export default UnauthHeader;
