/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../components/Home/Home';
import Auth from '../components/Auth/Auth';
import Dashboard from '../components/Dashboard/Dashboard';
import Oauth from '../components/Auth/Oauth/Oauth';
import ProtectedRoute from './protectedroute';
import Notfound from '../components/Notfound/Notfound';
import ResetPassword from '../components/Auth/ResetPassword/ResetPassword';
import Static from '../components/Static/Static';
import ViewProfile from '../components/ViewProfile/ViewProfile';
import ProfileCaledar from '../components/ViewProfile/ProfileCalendar/ProfileCaledar';
import Book from '../components/Book/Book';
import BookConfirm from '../components/Book/BookConfirm/BookConfirm';
import SessionCall from '../components/VideoSession/SessionCall/SessionCall';
import Myaccount from '../components/Myaccount/Myaccount';
import EmailActivate from '../components/EmailActivate/EmailActivate';
import BookingHistory from '../components/BookingHistory/BookingHistory';
import Noshow from '../components/VideoSession/VideoSessionCases/Noshow';
import MarketingPrompt from '../components/MarketingPrompt/MarketingPrompt';
import EditMyProfile from '../components/Myprofile/EditMyProfile/EditMyProfile';
import MyaccountStep from '../components/Myaccount/MyaccountStep/MyaccountStep';
import Mycalendar from '../components/Mycalendar/Mycalendar';
import BookingDetails from '../components/BookingDetails/BookingDetails';
import SwitchAccount from '../components/SwitchAccount/SwitchAccount';
import SessionComplete from '../components/VideoSession/SessionComplete/SessionComplete';
import PaymentConfirm from '../components/Book/PaymentConfirm/PaymentConfirm';
import Payment from '../components/Payment/Payment';
import Availability from '../components/Availability/Availability';
import OauthShare from '../components/Auth/OauthShare/OauthShare';
import QuickRegistration from '../components/AR/QuickRegistration';
import {
  PsychologistPage,
  BusinessConsultant,
  Mentors,
  GrowthHacker,
} from '../components/LandingPages';
import Session from '../v2/pages/SessionCall';
import SessionEnd from '../v2/pages/SessionEnd';
import ClientRoutes from '../client/route';
import SearchPage from '../v2/pages/Search';
import DepressionTest from '../components/DepressionTest';
import QuizComposite from '@conrati/quiz';
import PaymentPage from '../components/PaymentPage/PaymentPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/categories/psychologist" component={PsychologistPage} />
    <Route exact path="/categories/businessconsultant" component={BusinessConsultant} />
    <Route exact path="/categories/mentors" component={Mentors} />
    <Route exact path="/categories/growthhacker" component={GrowthHacker} />
    <Route exact path="/register/confirm-auth" component={MarketingPrompt} />
    <Route exact path="/book" component={Book} />
    <Route exact path="/register" component={Auth} />
    <Route exact path="/signin" component={Auth} />
    <Route exact path="/depression-test" component={DepressionTest} />
    <Route exact path="/experience/payment" component={PaymentPage} />
    {/* v2 routes */}
    <ProtectedRoute exact path="/session" component={Session} />
    <ProtectedRoute exact path="/session/disconnect" component={SessionEnd} />
    <ProtectedRoute exact path="/session/details" component={BookingDetails} />
    <ProtectedRoute exact path="/session/call" component={SessionCall} />
    <Route exact path="/signin/oauth/:type" component={Oauth} />
    <Route exact path="/share/oauth/:type" component={OauthShare} />
    <Route exact path="/search" component={SearchPage} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <ProtectedRoute path="/dashboard" component={Dashboard} />
    <ProtectedRoute path="/payout" component={Payment} />
    <ProtectedRoute path="/availability" component={Availability} />
    <ProtectedRoute path="/mycalendar" component={Mycalendar} />
    <ProtectedRoute path="/quick-registration-ar" component={QuickRegistration} />

    <ProtectedRoute exact path="/myprofile" component={EditMyProfile} roles="AP" />
    <ProtectedRoute exact path="/myprofile/edit" component={EditMyProfile} roles="AP" />
    <ProtectedRoute exact path="/myaccount" component={Myaccount} />
    <ProtectedRoute exact path="/myaccount/switch" component={SwitchAccount} />
    <ProtectedRoute exact path="/myprofile/edit/step" component={MyaccountStep} roles="AP" />

    <ProtectedRoute exact path="/session/call/noshow" component={Noshow} />
    <ProtectedRoute exact path="/session/call/complete" component={SessionComplete} />
    <Route exact path="/email/activate" component={EmailActivate} />

    <ProtectedRoute exact path="/bookinghistory" component={BookingHistory} />
    <ProtectedRoute exact path="/book/confirm" component={BookConfirm} />
    <Route exact path="/book/confirm/payment" component={PaymentConfirm} />
    <Route exact path="/:username/calendar" component={ProfileCaledar} />
    <Route path="/quiz" component={QuizComposite} />

    {/* All new client routes */}
    {ClientRoutes.map((route, ind) => (
      <Route key={`${ind}-route`} exact path={route.path} component={route.component} />
    ))}
    {/* End new client routes */}

    <Route exact path="/:username" component={ViewProfile} />
    <Route path="/" component={Static} />
    <Route path="*" component={Notfound} />
  </Switch>
);

export default Routes;
