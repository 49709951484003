import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import EditInput from '../../Common/EditInput/EditInput';
import globalProptypes from '../../../config/proptype';
import { handleArOnChange } from '../../../actions/userAction';
import EditSelect from '../../Common/EditSelect/EditSelect';
import EditDob from '../../Common/EditDob';
import userIcon from '../../Common/assets/userBlue.png';

function PersonalDetails(props) {
  const { user, onChange, countriesMasterdata, selectedCountry, currenciesMasterdata } = props;
  const { firstName, lastName, dateOfBirth, isEmailVerified } = user;

  return (
    <div className={styles.group}>
      <div className={styles.ahead}>
        <img className={styles.aheadimg} src={userIcon} alt="User" />
        <div>
          <p className={styles.atitle}>Personal Details</p>
          <p className={styles.asub}>Please fill the personal details for booking confirmation.</p>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.left}>
          <EditInput
            htmlFor="First Name"
            label="First Name"
            value={firstName}
            placeholder="First Name"
            name="firstName"
            required
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            disabled={!isEmailVerified}
          />

          <EditDob
            label="Date of Birth"
            value={dateOfBirth}
            name="dateOfBirth"
            required
            onChange={(e) => {
              onChange({
                name: 'dateOfBirth',
                value: `${e.yyyy}/${e.mm}/${e.dd}`,
              });
            }}
            disabled={!isEmailVerified}
          />
        </div>
        <div className={styles.right}>
          <EditInput
            htmlFor="Last Name"
            label="Last Name"
            value={lastName}
            placeholder="Last Name"
            name="lastName"
            required
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            disabled={!isEmailVerified}
          />

          <EditSelect
            label="Country"
            selected={selectedCountry}
            options={countriesMasterdata}
            placeholder="Select Country"
            onChange={(e) => {
              onChange({ name: 'countryId', value: e.value });
              onChange({
                name: 'country',
                value: {
                  countryId: e.value,
                  countryCode: e.code,
                  countryName: e.label,
                  currencyCode: e.currencyCode,
                },
              });

              const currency = currenciesMasterdata.find((curr) => curr.code === e.currencyCode);

              onChange({
                name: 'currencyId',
                value: currency.value,
              });

              onChange({
                name: 'currency',
                value: currency,
              });
            }}
            onActive={() => false}
            required
            isDisabled={!isEmailVerified}
          />
        </div>
      </div>
    </div>
  );
}

PersonalDetails.propTypes = {
  user: globalProptypes.user.isRequired,
  onChange: PropTypes.func.isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountry: PropTypes.shape({}).isRequired,
  currenciesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const countriesMasterdata = state.masterdata.masterData.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
    code: country.countryCode,
    currencyCode: country.currencyCode,
  }));

  const currenciesMasterdata = state.masterdata.masterData.currencies.map((currency) => ({
    value: currency.currencyId,
    label: `${currency.currencyCode} (${currency.currencyName})`,
    code: currency.currencyCode,
    symbol: currency.currencySymbol,
  }));

  const country =
    state.userState.user.countryId > 0 ? state.userState.user.country : state.userState.country;

  const selectedCountry = {
    value: country.countryId,
    label: country.countryName,
  };

  return {
    user: state.userState.user,
    countriesMasterdata,
    selectedCountry,
    currenciesMasterdata,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onChange: handleArOnChange,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(PersonalDetails);
