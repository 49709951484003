import React, { Component } from 'react';
import styles from './EditDate.module.css';
//import DayPicker from 'react-day-picker';
//import 'react-day-picker/lib/style.css';
import moment from 'moment';
//import cx from 'classnames';

class EditDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      focused: false,
      dateInput: this.props.date,
      required: false,
      invalid: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    if (this.props.date) {
      this.setState({ date: moment(this.props.date, 'YYYY/MM/DD').toDate() });
    }

    if (this.props.required && !this.props.date) {
      this.setState({ required: true });
    }
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.date) {
      this.setState({ date: moment(newprops.date, 'YYYY/MM/DD').toDate() });
    }
  }

  onChange(e) {
    e.preventDefault();

    if (this.props.required && e.target.value.length === 0) {
      this.setState({ required: true });
    }

    if (e.target.value.length === 4 && this.state.dateInput.length === 3) {
      e.target.value = e.target.value += '/';
    }

    if (e.target.value.length === 7 && this.state.dateInput.length === 6) {
      e.target.value = e.target.value += '/';
    }

    if (e.target.value.length <= 10) {
      this.setState({ dateInput: e.target.value, required: true });
    }

    if (e.target.value.length === 10) {
      this.setState({ date: moment(e.target.value, 'YYYY/MM/DD').toDate() });

      let date = e.target.value.split('/');

      if (
        date.length === 3 &&
        Number(date[0]) < moment().year() &&
        Number(date[1]) <= 12 &&
        Number(date[1]) >= 1 &&
        Number(date[2]) >= 1 &&
        Number(date[2]) <= 31
      ) {
        this.setState({ invalid: false, required: false });
        this.props.onChange(e);
      } else {
        this.setState({ invalid: true, required: false });
      }
    }
  }

  handleDayClick(day) {
    this.setState({ date: day, dateInput: moment(day).format('YYYY/MM/DD'), focused: false });
  }

  handleFocus(e, type) {
    e.preventDefault();

    if (type === 1) {
      this.setState({ focused: true });
    }
  }

  render() {
    const hasError = this.state.required || this.state.invalid ? styles.inputField : '';

    return (
      <div className={styles.edate}>
        <label htmlFor={this.props.label} className={styles.label}>
          {this.props.label}
        </label>
        <input
          className={`${styles.sdate} ${hasError}`}
          ref={(e) => {
            this.maskedInput = e;
          }}
          name={this.props.name}
          type="text"
          value={this.state.dateInput}
          placeholder="YYYY/MM/DD"
          onChange={this.onChange}
          onFocus={(e) => this.handleFocus(e, 1)}
          onBlur={(e) => this.handleFocus(e, 2)}
        />
        {this.state.required && <p className={styles.error}>{this.props.label} Required</p>}
        {this.state.invalid && (
          <p className={styles.error}>Invalid Date. Date should be YYYY/MM/DD format</p>
        )}
      </div>
    );
  }
}

export default EditDate;
