/* eslint-disable import/prefer-default-export */
import { apiRequest } from '../../../utils';

export const uploadMentalHealthTestResult = ({ testName, testResult, email, utmCampaign }) => {
  return apiRequest('POST', `/v1.1/screener-test/store-data`, {
    test_name: testName,
    test_result: testResult,
    email,
    utm_campaign: utmCampaign,
  });
};
