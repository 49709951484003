import React from 'react';
import starSVG from '../../assets/svg/star.svg';
import style from './Star.module.scss';

export default function Star(): JSX.Element {
	return (
		<div className={style.star}>
			<img src={starSVG} alt="Five stars" />
		</div>
	);
}
