/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DisconnectSuccessful from '../../components/DisconnectSuccesful';
import style from './style.module.scss';
import globalProptypes from '../../../config/proptype/index';
import DisconnectNoShowAP from '../../components/DisconnectNoShowAP';
import Headers from '../../../components/Headers/Headers';
import enums from '../../../config/enum';
import DisconnectNoShowAR from '../../components/DisconnectNoShowAR';

class SessionEnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStatus: 0,
      apLink: '',
    };
  }

  componentDidMount() {
    this.fetchActiveBookingStatus();
  }

  fetchActiveBookingStatus = () => {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);

    const bookingStatus = Number(urlParams.get('sessionStatus')) || 0;
    const apLink = urlParams.get('apUserName');

    if (bookingStatus === 0) {
      window.location.href = '/404';
      return '404';
    }

    return this.setState({
      activeStatus: bookingStatus,
      apLink,
    });
  };

  render() {
    const { userData } = this.props;
    const { activeStatus, apLink } = this.state;
    return (
      <div className={style.sessionEnd}>
        <Headers />
        {activeStatus === enums.sessionDisconnectType.Successful && (
          <DisconnectSuccessful userData={userData} apLink={apLink} />
        )}
        {activeStatus === enums.sessionDisconnectType.NoShowAP && (
          <DisconnectNoShowAR userData={userData} />
        )}
        {activeStatus === enums.sessionDisconnectType.NoShowAR && (
          <DisconnectNoShowAP userData={userData} />
        )}
      </div>
    );
  }
}

SessionEnd.propTypes = {
  userData: globalProptypes.user.isRequired,
  location: globalProptypes.location.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userData: state.userState.user,
    activeSession: state.session.activeSession,
  };
};

export default connect(mapStateToProps, null)(SessionEnd);
