import React, { Component } from 'react';
import cx from 'classnames';
import './daterange.css';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './Filter.module.css';

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      clickcount: 0,
    };
  }

  handleSelect = async (ranges) => {
    await this.setState((prevState) => ({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      clickcount: prevState.clickcount + 1,
    }));

    const { clickcount } = this.state;
    const { onAddDateFilter } = this.props;
    if (clickcount === 2) {
      onAddDateFilter(ranges.selection);
    }
  };

  render() {
    const { dateShow } = this.props;
    const { startDate, endDate } = this.state;
    const rangelisif = dateShow
      ? cx(styles.rangelisdate, styles.rangeop, styles.rangelisif)
      : cx(styles.rangelisdate, styles.rangeop);

    const selectionRange = {
      startDate,
      endDate,
      key: 'selection',
    };

    return (
      <div
        className={dateShow ? rangelisif : styles.rangelisdate}
        ref={(node) => {
          this.categoryfilter = node;
        }}
      >
        <div className={styles.rtit}>
          <p>Session Availabilty</p>
        </div>
        <div className={styles.rbodyc}>
          <p className={styles.rbodytc}>Select date range</p>
          <div className={styles.filinputlis}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
              className="PreviewArea"
              maxDate={moment().add(3, 'months').toDate()}
              minDate={moment().toDate()}
            />
          </div>
        </div>
      </div>
    );
  }
}

DateFilter.propTypes = {
  dateShow: PropTypes.bool.isRequired,
  onAddDateFilter: PropTypes.func.isRequired,
};

export default DateFilter;
