import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './common.module.css';
import SessionHeaderNs from '../SessionHeader/SessionHeaderNs';
import enums from '../../../config/enum';

class Noshow extends Component {
  render() {
    const { activeBooking } = this.props;

    return (
      <div className={styles.videosession}>
        <SessionHeaderNs />
        {this.props.user.userType === enums.userType.ar ? (
          <div className={styles.noshow}>
            <img
              className={styles.profile}
              src={this.props.user.primaryImg}
              alt="user"
              height="70px"
              width="70px"
            />
            <p className={styles.wel}>NO SHOW BY CONSULTANT</p>
            <p className={styles.sub}>
              {activeBooking.isSessionFree
                ? 'Unfortunately your consultant has failed to show up within the first ten minutes. You still have your free session left and can book the consultant for FREE, again.'
                : `Unfortunately your consultant has failed to show up within first ten minutes. You will
                    not be billed for this session and your booking fee will be refunded.`}
            </p>
            <Link to="/bookinghistory" className={styles.btn}>
              Booking History
            </Link>
          </div>
        ) : (
          <div className={styles.noshow}>
            <img
              className={styles.profile}
              src={this.props.user.primaryImg}
              alt="user"
              height="70px"
              width="70px"
            />
            <p className={styles.wel}>NO SHOW BY CLIENT</p>
            <p className={styles.sub}>
              {activeBooking.isSessionFree
                ? 'Unfortunately your client has failed to show up within the first ten minutes.'
                : `Unfortunately your client has failed to show up within the first ten minutes. You will
                    still receive the payment.`}
            </p>
            <Link to="/bookinghistory" className={styles.btn}>
              Booking History
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    activeBooking: state.session.activeBooking,
  };
};

export default connect(mapStateToProps, null)(Noshow);
