import React from 'react';
import PropTypes from 'prop-types';
import styles from './Payment.module.css';
import closeIcon from '../Common/assets/closex.svg';

function PayOutModal(props) {
  const { onClose, onPayment } = props;
  return (
    <>
      <div className={styles.header}>
        <div />
        <p className={styles.ptitle}>Trigger Payout</p>
        <button type="button" style={{ cursor: 'pointer' }} onClick={onClose}>
          <img src={closeIcon} alt="close" height="12px" width="12px" />
        </button>
      </div>
      <div className={styles.body}>
        <p className={styles.bodyText}>
          You are about to trigger a payout from your Conrati wallet to your bank account.
        </p>
        <p className={styles.bodyTextBold}>
          Please note: Banks charge different fees for receiving payments. It is MUCH MORE COST
          EFFECTIVE to transfer a higher balance into your account than to payout smaller amounts.
        </p>
        <p className={styles.bodyText}>
          Conrati can neither control these costs nor be held accountable for your bank charges.
          Please contact your bank to find out more information on the costs involved to receive the
          balance of your funds transferred to your bank account.
        </p>
      </div>
      <div className={styles.footer}>
        <button type="button" className={styles.tbtn} onClick={onPayment}>
          CONTINUE
        </button>
      </div>
    </>
  );
}

PayOutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPayment: PropTypes.func.isRequired,
};

export default PayOutModal;
