import React from 'react';
import PropTypes from 'prop-types';
import { UserCard } from '@conrati/ui';
import { LegacyNativeUserCard as NativeUserCard } from '@conrati-ui/native';
import Card from '../../Common/Card/Card';
import { Card as Placeholder } from '../../Common/Placeholders';
import { checkIsMobileBrowser } from '../../../utils';

const ProfileCard = (props) => {
  const { profiles } = props;

  return (
    <>
      {profiles.length > 0 ? (
        profiles.map((profile, index) => (
          <React.Fragment key={`profile-${index.toString()}`}>
            {checkIsMobileBrowser(navigator.userAgent) ? (
              <NativeUserCard user={profile} />
            ) : (
              <UserCard user={profile} />
            )}
          </React.Fragment>
        ))
      ) : (
        <>
          <Placeholder />
          <Placeholder />
          <Placeholder />
        </>
      )}
    </>
  );
};

ProfileCard.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfileCard;
