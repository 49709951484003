import React from 'react';
import styles from './UserCard.module.scss';

interface LanguageItemProps {
	language: string;
}

const LanguageItem = (props: LanguageItemProps): JSX.Element => {
	return (
		<p
			data-testid="ui-native-language-item-text"
			className={styles.languageName}
		>
			{props.language}
		</p>
	);
};

export default LanguageItem;
