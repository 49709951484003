function storeDataLocal(keyName, data) {
  window.localStorage.setItem(keyName, JSON.stringify(data));
}

function fetchDataLocal(keyName) {
  const data = window.localStorage.getItem(keyName);

  if (data) {
    return JSON.parse(data);
  }

  return null;
}

export { storeDataLocal, fetchDataLocal };
