import React, { Component } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import styles from './Newonboarding.module.css';
import EditTooltip from '../../../Common/EditTooltip/EditTooltip';
import EditInput from '../../../Common/EditInput/EditInput';
import EditSelect from '../../../Common/EditSelect/EditSelect';
import EditRadio from '../../../Common/EditRadio/EditRadio';
import enums from '../../../../config/enum';
import businsess from '../../../Common/assets/businsess.svg';
import { alert } from '../../../../utils';
import globalProptype from '../../../../config/proptype';
import message from '../../../../config/message';
import { updateBusinessDetails } from '../../../../services/userService';

class BusinessDetailsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessName: '',
      businessRegistrationNo: '',
      typeOfBusiness: '',
      vatNo: '',
      address: '',
      city: '',
      region: '',
      postalCode: '',
      selectactive: false,
      vatRegistered: false,
      istypeOfBusinessSelected: false,
      showExtend: true,
      isSmallEnterprise: false,
      isSmallExemptDisabled: false,
    };

    this.toastId = null;
  }

  componentDidMount() {
    const { buisnessDetails } = this.props;
    if (buisnessDetails && buisnessDetails.businessId) {
      const {
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        isSmallEnterprise,
      } = buisnessDetails;

      this.setState({
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        vatRegistered: vatNo && vatNo.length > 0,
        istypeOfBusinessSelected: !!(typeOfBusiness === 0 || typeOfBusiness === 1),
        isSmallEnterprise,
      });

      if (!isSmallEnterprise) {
        if (!vatNo) {
          this.setState({
            isSmallEnterprise: true,
            isSmallExemptDisabled: true,
          });
        }
      }
    } else {
      this.setState({
        isSmallEnterprise: true,
        isSmallExemptDisabled: true,
      });
    }

    this.onOpenEditScreen();
  }

  componentDidUpdate(prevprops) {
    const { requiredStep, buisnessDetails } = this.props;
    if (prevprops.requiredStep !== requiredStep) {
      if (requiredStep !== enums.onboardingStep.BusinessDetails) {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }

    if (!prevprops.buisnessDetails.businessId && buisnessDetails && buisnessDetails.businessId) {
      const {
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        isSmallEnterprise,
      } = buisnessDetails;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        typeOfBusiness,
        businessName,
        businessRegistrationNo,
        vatNo,
        address,
        city,
        postalCode,
        vatRegistered: !!(vatNo && vatNo.length > 0),
        istypeOfBusinessSelected: !!(typeOfBusiness === 0 || typeOfBusiness === 1),
      });

      if (!isSmallEnterprise) {
        if (!vatNo) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            isSmallEnterprise: true,
            isSmallExemptDisabled: true,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onOpenEditScreen = () => {
    const { open } = this.props;
    if (open) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  onTooltipClick = () => {
    const { completedStep, open, required, onTooltipClick, onAlert } = this.props;
    if (completedStep > 1) {
      if (!open && !required) {
        onTooltipClick(enums.onboardingStep.BusinessDetails);
        document.addEventListener('click', this.handleOutsideClick, false);
      }
    } else {
      onAlert();
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelActive = (type) => {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  };

  onCheck = (type) => {
    if (type === false) {
      this.setState({
        vatRegistered: false,
        vatNo: '',
        isSmallExemptDisabled: true,
        isSmallEnterprise: true,
      });
    }

    if (type === true) {
      this.setState({
        vatRegistered: true,
        isSmallExemptDisabled: false,
        isSmallEnterprise: false,
      });
    }
  };

  handleOutsideClick = (e) => {
    const { selectactive } = this.state;
    if (selectactive) {
      return;
    }

    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onSubmitData();
  };

  onCheckSmallExempt = (type) => {
    const { user } = this.props;
    const { countryCode } = user.country;

    if (
      countryCode === 'MT' ||
      countryCode === 'GR' ||
      countryCode === 'FR' ||
      countryCode === 'BE'
    ) {
      if (type === false) {
        this.setState({ isSmallEnterprise: type });
      }

      if (type === true) {
        this.setState({ isSmallEnterprise: type });
      }
    } else {
      this.setState({ isSmallExemptDisabled: true });
    }
  };

  onSelectChange = (selectedOption, name) => {
    if (name === 'typeOfBusiness') {
      this.setState({ typeOfBusiness: selectedOption.value });
    }
  };

  onSubmitData = () => {
    const {
      typeOfBusiness,
      businessName,
      businessRegistrationNo,
      vatNo,
      address,
      city,
      postalCode,
      region,
      vatRegistered,
      isSmallEnterprise,
    } = this.state;

    const { onRequired, user, onUpdateUserBuisnessDetails, onCompleteEditing } = this.props;

    if (vatRegistered && vatNo.length < 1) {
      onRequired(true, enums.onboardingStep.BusinessDetails);
      return alert(message.requiredFieldsMissing);
    }

    if (typeOfBusiness === enums.typeOfBusinessText.Business && businessRegistrationNo.length < 1) {
      onRequired(true, enums.onboardingStep.BusinessDetails);
      return alert(message.requiredFieldsMissing);
    }

    if (typeOfBusiness >= 0 && businessName && address && city && postalCode) {
      const businessStateServer = {
        type_of_business: typeOfBusiness,
        business_name: businessName,
        business_registration_no: businessRegistrationNo,
        vat_no: vatNo,
        address,
        city,
        postal_code: postalCode,
        region,
        country_id: user.country.countryId,
        first_name: user.firstName,
        last_name: user.lastName,
        country_code: user.country.countryCode,
        isSmallEnterprise,
      };

      let toastId = null;

      toastId = toast.warn('Updating account details', {
        position: 'top-center',
        hideProgressBar: true,
      });

      updateBusinessDetails(businessStateServer)
        .then(async (res) => {
          await onUpdateUserBuisnessDetails(res.result, {
            registration_steps_completed: enums.profileCompleted.business,
          });

          toast.update(toastId, {
            render: 'Account details updated.',
            autoClose: 1000,
          });
          ReactGA.pageview('/myaccount/ap/business_details');

          onRequired(false);
          onCompleteEditing(enums.onboardingStep.PersonalDetails);
        })
        .catch(() => {
          toast.update(toastId, {
            render: 'Business details update failed!',
            autoClose: 1000,
          });
        });

      return document.removeEventListener('click', this.handleOutsideClick, false);
    }

    onRequired(true, enums.onboardingStep.BusinessDetails);
    return alert(message.requiredFieldsMissing);
  };

  render() {
    const {
      typeOfBusiness,
      businessName,
      businessRegistrationNo,
      vatNo,
      address,
      city,
      postalCode,
      istypeOfBusinessSelected,
      vatRegistered,
      showExtend,
      isSmallEnterprise,
      isSmallExemptDisabled,
    } = this.state;

    const { user, countries, open, countriesMasterdata, alertuser } = this.props;
    const { isBusinessRegistered } = user;

    const typeofbuisness = [
      {
        value: 0,
        label: 'Freelancer / Soletrader',
      },
      {
        value: 1,
        label: 'Business',
      },
    ];

    const selectedCountry = countriesMasterdata.filter(
      (country) => country.value === user.countryId
    );
    const selectedTypeOfBusiness =
      typeOfBusiness >= 0
        ? typeofbuisness.filter((arr) => {
            return arr.value === typeOfBusiness;
          })
        : null;

    return (
      <div
        className={
          open
            ? cx(styles.personal, styles.cardborder)
            : cx(styles.personal, { [styles.alertclick]: alertuser })
        }
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={businsess} alt="business" />
          <p className={styles.atitle}>Account Details</p>
          <p className={styles.rerror}>* Required</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={open} />
          </div>
        </div>
        {isBusinessRegistered && !open && (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Business Name</p>
              <p className={styles.alisub}>{businessName}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Type Of Business </p>
              <p className={styles.alisub}>{enums.typeOfBusiness[typeOfBusiness]}</p>
            </div>

            {businessRegistrationNo && (
              <div className={styles.alitem}>
                <p className={styles.alitit}>Business Registration Number</p>
                <p className={styles.alisub}>{businessRegistrationNo}</p>
              </div>
            )}
            <div className={styles.alitem}>
              <p className={styles.alitit}>Business Address</p>
              <p className={styles.alisub}>{`${address} ${city} ${postalCode}`}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Country</p>
              <p className={styles.alisub}>{selectedCountry ? selectedCountry[0].label : ''}</p>
            </div>
            {vatNo && (
              <div className={styles.alitem}>
                <p className={styles.alitit}>VAT Number</p>
                <p className={styles.alisub}>{vatNo}</p>
              </div>
            )}
          </div>
        )}

        {open && (
          <div className={styles.acedit}>
            <EditSelect
              label="Type of Business"
              selected={selectedTypeOfBusiness}
              options={typeofbuisness}
              placeholder="Select your business type"
              onActive={this.onSelActive}
              onChange={(e) => this.onSelectChange(e, 'typeOfBusiness')}
              isDisabled={!!istypeOfBusinessSelected}
            />
            <p
              className={styles.alisub}
              style={{
                marginTop: '-10px',
                marginBottom: '15px',
                color: '#FF9494',
              }}
            >
              Once selected, this field is no longer editable.
            </p>
            <div className={styles.ccheck}>
              <EditRadio
                label="Is your business registered for VAT?"
                onCheck={this.onCheck}
                checked={vatRegistered}
                right
              />
            </div>
            {vatRegistered ? (
              <EditInput
                htmlFor="VAT number"
                label="VAT number"
                value={vatNo}
                placeholder="VAT number"
                onChange={this.onChange}
                required
                name="vatNo"
              />
            ) : null}
            <div className={styles.ccheck}>
              <EditRadio
                label="Are you an SME exempt from VAT?"
                onCheck={this.onCheckSmallExempt}
                checked={isSmallEnterprise}
                right
                tooltip
                disabled={isSmallExemptDisabled}
              />
            </div>
            {showExtend && (
              <>
                <EditInput
                  htmlFor="Business Name"
                  label="Business Name"
                  value={businessName}
                  placeholder="Business Name"
                  onChange={this.onChange}
                  name="businessName"
                  required
                />

                {typeOfBusiness === enums.typeOfBusinessText.Business ? (
                  <EditInput
                    htmlFor="Business Registration Number"
                    label="Business Registration Number"
                    value={businessRegistrationNo}
                    placeholder="Business Registration Number"
                    required
                    onChange={this.onChange}
                    name="businessRegistrationNo"
                  />
                ) : (
                  <EditInput
                    htmlFor="Business Registration Number"
                    label="Business Registration Number"
                    value={businessRegistrationNo}
                    placeholder="Business Registration Number"
                    onChange={this.onChange}
                    name="businessRegistrationNo"
                  />
                )}

                <EditInput
                  htmlFor="Address"
                  label="Address"
                  value={address}
                  placeholder="Address"
                  onChange={this.onChange}
                  name="address"
                  required
                />
                <EditInput
                  htmlFor="City"
                  label="City"
                  value={city}
                  placeholder="City"
                  onChange={this.onChange}
                  name="city"
                  required
                />
                <EditInput
                  htmlFor="Postal Code"
                  label="Postal Code"
                  value={postalCode}
                  placeholder="Postal Code"
                  onChange={this.onChange}
                  name="postalCode"
                  required
                />
                <EditSelect
                  label="Country"
                  options={countries}
                  selected={selectedCountry}
                  placeholder="Select Country"
                  onChange={(e) => this.onSelectChange(e, 'country')}
                  onActive={this.onSelActive}
                  isDisabled={!!selectedCountry}
                />

                <div className={styles.binfo}>
                  <p>
                    This information is used to generate invoices between advice providers and
                    advice receivers on your business’s behalf. This includes the VAT determination
                    both locally and internationally depending on the scenario
                  </p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

BusinessDetailsNew.propTypes = {
  buisnessDetails: globalProptype.businessDetailsState.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    countryId: PropTypes.number,
    country: PropTypes.shape({
      countryCode: PropTypes.string,
      countryName: PropTypes.string,
      countryId: PropTypes.number,
    }),
    isBusinessRegistered: PropTypes.bool,
  }).isRequired,
  requiredStep: PropTypes.number,
  completedStep: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  alertuser: PropTypes.bool.isRequired,
  onTooltipClick: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  onRequired: PropTypes.func.isRequired,
  onCompleteEditing: PropTypes.func.isRequired,
  onUpdateUserBuisnessDetails: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BusinessDetailsNew.defaultProps = {
  requiredStep: 0,
};

export default BusinessDetailsNew;
