import React from 'react';
import style from './style.module.scss';

function DisconnectNoShowAR() {
  return (
    <div className={style.disconnectSession}>
      <div className={style.card}>
        <div className={style.message}>
          <h6>No show by Advisee!</h6>
          <p className={style.msgtxt}>
            Unfortunately the advisee has failed to show up within the first ten minutes. You will
            still receive your full consultation fee.
          </p>
        </div>
        <div className={style.btns}>
          <a href="/bookinghistory">Booking History</a>
        </div>
      </div>
    </div>
  );
}

export default DisconnectNoShowAR;
