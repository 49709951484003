/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Dialog } from '@headlessui/react';
import Headers from '../../components/Headers/Headers';
import style from './style.module.scss';
import positiveMessageImage from './assets/mental-health-test-positive-message-image.png';
import { ReactComponent as ShortRightArrow } from './assets/right-arrow.svg';
import { ReactComponent as LongRightArrow } from './assets/right-arrow-long.svg';
import { ReactComponent as HowItWorksStep1 } from './assets/mental-health-test-how-it-works-1.svg';
import { ReactComponent as HowItWorksStep2 } from './assets/mental-health-test-how-it-works-2.svg';
import { ReactComponent as HowItWorksStep3 } from './assets/mental-health-test-how-it-works-3.svg';
import freeImg from './assets/free.svg';
import therapyImg from './assets/therapy.svg';
import findImg from './assets/find.svg';
import scheduldeImg from './assets/schedulde.svg';
import paySvg from './assets/pay.svg';
import { ReactComponent as Tick } from './assets/tick.svg';
import rudselMduroImage from './assets/mental-health-test-network-of-professionals-rudsel-mduro.png';
import RecommendedTherapists from './RecommendedTherapists';
import adhdTest from './tests/adhd-test.json';
import anxietyTest from './tests/anxiety-test.json';
import bipolarTest from './tests/bipolar-test.json';
import burnoutTest from './tests/burnout-test.json';
import depressionTest from './tests/depression-test.json';
import insomniaTest from './tests/insomnia-test.json';
import ocdTest from './tests/ocd-test.json';
import panicDisorderTest from './tests/panic-disorder-test.json';
import postpartumDepressionTest from './tests/postpartum-depression-test.json';
import psychoSomaticTest from './tests/psycho-somatic-test.json';
import ptsdTest from './tests/ptsd-test.json';
import socialAnxietyTest from './tests/social-anxiety-test.json';
import { scrollToTop } from '../../utils';

import testimonialCircleR from '../../components/Common/assets/testimonialCircleR.svg';
import testimonialCircleL from '../../components/Common/assets/testimonialCircleL.svg';
import star5 from '../../components/Common/assets/5star.svg';
import arrow from '../../components/Common/assets/arrowWhite.svg';
import PsychTestResult from './PsychTestResult';
import { uploadMentalHealthTestResult } from './services/uploadMentalHealthTestResult';

const testBackgroundImages = require.context('./assets', true);

const questionFiles = {
  'adhd-test': adhdTest,
  'anxiety-test': anxietyTest,
  'bipolar-test': bipolarTest,
  'burnout-test': burnoutTest,
  'depression-test': depressionTest,
  'insomnia-test': insomniaTest,
  'ocd-test': ocdTest,
  'panic-disorder-test': panicDisorderTest,
  'postpartum-depression-test': postpartumDepressionTest,
  'psycho-somatic-test': psychoSomaticTest,
  'ptsd-test': ptsdTest,
  'social-anxiety-test': socialAnxietyTest,
};

const redirectingPages = [
    'adhd-test', 
    'ocd-test', 
    'ptsd-test',
    'anxiety-test',
    'burnout-test'
]

// Extrack utm params from url
const getUtmParams = (location) => {
  const searchParams = location.search;

  const searchParamsWithAmpersand = searchParams.replace('?', '');
  return searchParamsWithAmpersand;
};

export default function PsychTestPage() {
  const location = useLocation();
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [checkedError, setCheckedError] = useState({
        animate: false,
        error: false,
    });
  const testName = location.pathname.replace(/\//g, '');
  const data = questionFiles[testName];
  const testBgImg = testBackgroundImages(`./${testName}.png`);

  // We can use this ref to scroll to the test block when the user presses the start test button
  const testBlockRef = useRef(null);
  const blockRerender = useRef(false);

  const getResultBasedOnScoreNonCallback = (score) => {
    const result = data.results.find((record) => {
      const { resultText, resultDescription, ...rest } = record;
      const name = Object.keys(rest)[0];
      const range = record[name];

      return score >= range[0] && score <= range[1];
    });

    return {
      resultText: result.resultText,
      resultDescription: result.resultDescription,
    };
  };

  const calculateQuizScoreNonCallback = (state) => {
    return [...state.answers.entries()]
      .map(([key, value]) => {
        return data.questions[key].options[value].score;
      })
      .reduce((sum, current) => sum + current, 0);
  };

  const checkAndSendEmail = async (result) => {
    if (email) {
      const searchURL = new URLSearchParams(location.search);
      const utmCampaign = searchURL.get("utm_campaign") || ''

      await uploadMentalHealthTestResult({
        testName,
        testResult: result,
        email,
        utmCampaign
      });
    }
  };

  const testSubmitHandler = (state) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let emailError = "";

    if (!email) {
        emailError = 'Email address required';
    }

    if (email && !emailRegex.test(email)) {
        emailError = 'Enter a valid email address';
    }

    if (!isChecked) {
        setCheckedError({
            error: true,
            animate: true
        });
        setTimeout(() => {
            setCheckedError({
                error: true,
                animate: false
            });
        }, 2000);
    }

    setEmailError(emailError);
    setCheckedError((prev) => ({ ...prev, error: !isChecked }));

    if (emailError || !isChecked){
        return "HAS_ERROR";
    }

    if (!redirectingPages.includes(testName)) {
        scrollToTop();

        return 'SHOW_RESULT';
    }

    // Redirect to the result page
    return 'REDIRECT_TO_RESULT';
  };

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'START_TEST':
          return {
            ...currentState,
            answers: new Map(),
            errors: new Map(),
            showDialog: false,
            showResult: false,
          };
        case 'ANSWER': {
          const newMap = new Map(currentState.answers);
          newMap.set(action.question, action.choice);

          return {
            ...currentState,
            answers: newMap,
          };
        }
        case 'CHECK_FOR_ERRORS': {
          const newErrors = new Map();

          for (let i = 0; i < data.questions.length; i += 1) {
            if (!currentState.answers.has(i)) {
              newErrors.set(i, 'CANNOT_BE_EMPTY');
            }
          }

          if (newErrors.size === 0) {
            const result = testSubmitHandler(currentState);

            if (result === 'SHOW_RESULT') {
                return {
                    ...currentState,
                    showDialog: false,
                    showResult: true,
                };
            }

            if (result === 'REDIRECT_TO_RESULT') {
                return {
                    ...currentState,
                    redirect: true,
                }
            }
          }

          return {
            ...currentState,
            errors: newErrors,
            showDialog: newErrors.size === 0,
          };
        }
        case 'OPEN_DIALOG':
          return {
            ...currentState,
            showDialog: true,
            showResult: false,
          };
        case 'CHANGE_EMAIL':
          return {
            ...currentState,
            email: action.value,
          };
        case 'CLOSE_DIALOG':
          return {
            ...currentState,
            showDialog: false,
            showResult: false,
          };
        case 'SHOW_RESULT':
          return {
            ...currentState,
            showDialog: false,
            showResult: true,
          };
        case 'PREVIOUS_TESTIMONIAL': {
          const divWidth = 537;
          const nextTransformSize = currentState.testimonialScroll + divWidth;

          return {
            ...currentState,
            testimonialScroll:
              nextTransformSize <= 20 ? nextTransformSize : currentState.testimonialScroll,
          };
        }
        case 'NEXT_TESTIMONIAL': {
          const divWidth = 537;
          const maxTransformSize = -((data.testimonials.length * divWidth) / 2 - 20);
          const nextTransformSize = currentState.testimonialScroll - divWidth;

          return {
            ...currentState,
            testimonialScroll:
              nextTransformSize >= maxTransformSize
                ? nextTransformSize
                : currentState.testimonialScroll,
          };
        }
        default:
          throw new Error(`Invalid Action "${action}"!`);
      }
    },
    undefined,
    () => {
      return {
        answers: new Map(),
        errors: new Map(),
        showDialog: false,
        showResult: false,
        email: '',
        testimonialScroll: -20,
      };
    }
  );

  useEffect(() => {
    if (!state.errors || (state.errors && state.errors.size === 0)) return;

    const firstErrorKey = [...state.errors.keys()].sort((a, b) => a - b)[0];

    testBlockRef.current
      .querySelector(`#question-${firstErrorKey + 1}`)
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [state.errors]);

  useEffect(() => {
    const redirectToResults = async () => {
        const score = calculateQuizScoreNonCallback(state);
        const results = getResultBasedOnScoreNonCallback(score);
        
        await checkAndSendEmail(results.resultText);

        return (window.location.href = `https://pages.conrati.com/${testName}-results/?result=${
            results.resultText
          }&email=${state.email}&${getUtmParams(location)}`)
    }

    if (state.redirect && !blockRerender.current) {
        blockRerender.current = true;
        redirectToResults();
    }
  }, [state])

  /**
   * Calculates the score based on answers.
   * Gets used for the result page and uploading results.
   *
   * @returns number
   */
  const calculateScore = useCallback(() => {
    return [...state.answers.entries()]
      .map(([key, value]) => {
        return data.questions[key].options[value].score;
      })
      .reduce((sum, current) => sum + current, 0);
  }, [data.questions, state.answers]);

  const getResultBasedOnScore = useCallback(
    (score) => {
      const result = data.results.find((record) => {
        const { resultText, resultDescription, ...rest } = record;
        const name = Object.keys(rest)[0];
        const range = record[name];

        return score >= range[0] && score <= range[1];
      });

      return {
        resultText: result.resultText,
        resultDescription: result.resultDescription,
      };
    },
    [data.results]
  );

  const getResultBasedOnConditions = useCallback(() => {
    const answers = [...state.answers.entries()].map(([key, value]) => {
      return data.questions[key].options[value].value;
    });

    const firstThirteenQuestions = answers.slice(0, 13).filter((answer) => {
      return answer.toLowerCase() === 'yes';
    }); // “Yes” to seven or more of the first 13 questions

    const conditionTwo = answers[13]; // “Yes” to question number 14
    const conditionThree = answers[14]; // Answered "Moderate Problem" OR "Serious Problem" to question 15

    if (
      firstThirteenQuestions.length >= 7 &&
      conditionTwo.toLowerCase() === 'yes' &&
      ['moderate problem', 'serious problem'].includes(conditionThree.toLowerCase())
    ) {
      return data.results[1];
    }

    return data.results[0];
  }, [data.questions, state.answers, data.results]);

  const testStartHandler = useCallback(() => {
    flushSync(() => {
      dispatch({ type: 'START_TEST' });
    });
    testBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const answerHandler = useCallback(
    (question, choice) => {
      dispatch({ type: 'ANSWER', question, choice });
    },
    [dispatch]
  );

  const errorCheckHandler = useCallback(() => {
    dispatch({ type: 'CHECK_FOR_ERRORS' });
  }, []);

  const dialogCloseHandler = useCallback(() => {
    dispatch({ type: 'CLOSE_DIALOG' });
  }, []);

  const dialogInputHandler = useCallback((event) => {
    dispatch({ type: 'CHANGE_EMAIL', value: event.target.value });
  }, []);

  const getResults = useCallback(() => {
    if (testName === 'bipolar-test') {
      const results = getResultBasedOnConditions();

      return results;
    }

    const score = calculateScore();
    const results = getResultBasedOnScore(score);

    return results;
  }, [calculateScore, testName, getResultBasedOnScore, getResultBasedOnConditions]);

  const dialogSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();

      const result = getResults();

      uploadMentalHealthTestResult({
        testName,
        testResult: result.resultText,
        email: state.email,
      });

      dispatch({ type: 'SHOW_RESULT' });

      scrollToTop();

      // Redirect to the result page
      window.location.href = `https://pages.conrati.com/${testName}-results/?result=${result.resultText}&email=${state.email}`;
    },
    [testName, state, getResults]
  );

  const testimonialPreviousHandler = useCallback(() => {
    dispatch({ type: 'PREVIOUS_TESTIMONIAL' });
  }, []);

  const testimonialNextHandler = useCallback(() => {
    dispatch({ type: 'NEXT_TESTIMONIAL' });
  }, []);

  let output;

  if (state.showResult) {
    const { resultText, resultDescription } = getResults();

    if (!blockRerender.current) {
        blockRerender.current = true;
        checkAndSendEmail(resultText)
    }

    output = <PsychTestResult result={resultText} resultLong={resultDescription} />;
  } else {
    output = (
      <div className={style.container}>
        <div className={style.test} ref={testBlockRef}>
          <h1 className={style.testName}>{data.testTitle}</h1>
          <p
            className={style.testDescription}
            dangerouslySetInnerHTML={{ __html: data.testDescription }}
          />
          <ol className={style.questions}>
            {data.questions.map((question, qIndex) => (
              <li
                className={style.questionRow}
                key={question.question}
                id={`question-${qIndex + 1}`}
              >
                <p
                  className={style.question}
                  dangerouslySetInnerHTML={{ __html: question.question }}
                />

                <ol>
                  {question.options.map((option, oIndex) => (
                    <li className={style.optionRow} key={option.value}>
                      <label
                        className={style.option}
                        htmlFor={`question-${qIndex}-option-${oIndex}`}
                      >
                        <input
                          type="radio"
                          name={`question-${qIndex}`}
                          id={`question-${qIndex}-option-${oIndex}`}
                          value={option.score}
                          defaultChecked={false}
                          onChange={() => answerHandler(qIndex, oIndex)}
                        />
                        {option.value}
                      </label>
                    </li>
                  ))}
                </ol>

                {state.errors.has(qIndex) && (
                  <p className={style.questionError}>* All questions are mandatory!</p>
                )}
              </li>
            ))}
          </ol>
          <div className={style.emailContainer}>
              <p className={style.note}>
              Provide your email address to view your test results:
            </p>
            <div className={style.emailInput} id="email-input">
                <input
                    type="email"
                    name="email"
                    className={emailError ? style.emailErrorInput : ''}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    placeholder="Your Email Address"
                />
                {emailError && (
                    <div className={style.emailError}>
                        {emailError}
                    </div>
                )}
            </div>
            <div className={style.checkBox}>
                <input
                    type="checkbox"
                    name="check"
                    defaultChecked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                />
                <p
                    className={
                    checkedError.error && checkedError.animate
                        ? cx(style.error, style.errorAnimate)
                        : checkedError.error
                        ? style.error
                        : null
                    }
                >
                    Conrati will use your contact information you provide in this form to provide
                    marketing notifications. By providing your email address, you agree to
                    Conrati’s privacy policy
                </p>
            </div>
          </div>
          <div>
            <button type="button" className={style.submitButton} onClick={errorCheckHandler}>
              <span>GET YOUR RESULTS</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialog className={style.dialog} open={false} onClose={dialogCloseHandler}>
        {/* Dialog Backdrop */}
        <div className={style.dialogBackdrop} aria-hidden="true" />

        <div className={style.dialogContainer}>
          <Dialog.Panel className={style.dialogContent}>
            <Dialog.Title className={style.dialogTitle}>
              Enter your email to see your results
            </Dialog.Title>

            <form onSubmit={dialogSubmitHandler}>
              <div className={style.dialogInput}>
                <label style={{ display: 'none' }} htmlFor="email">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  required
                  onChange={dialogInputHandler}
                />
              </div>

              <button type="submit" className={style.dialogButton}>
                SHOW RESULTS
              </button>
            </form>

            <p className={style.dialogFooter}>
              You agree to get your results and further content and promotional offerings related to
              the subject of this testing.You can opt out anytime through email preferences.
            </p>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Site-wide Page Header */}
      <Headers />

      <div className={style.testsWrapper}>
        {/* Banner */}
        {!state.showResult && (
            <div className={style.banner}>
                <div className={style.bannerContent}>
                    <img className={style.bannerBgImage} src={testBgImg} alt={testName} />
                    <div className={style.bannerData}>
                    <p
                        className={style.bannerHeading}
                        dangerouslySetInnerHTML={{ __html: data.slider.heading }}
                    />
                    <p
                        className={style.bannerSubHeading}
                        dangerouslySetInnerHTML={{ __html: data.slider.subHeadingOne }}
                    />
                    <p
                        className={style.bannerSubHeading}
                        dangerouslySetInnerHTML={{ __html: data.slider.subHeadingTwo }}
                    />
                    <div>
                        <button type="button" className={style.bannerButton} onClick={testStartHandler}>
                        <span>{data.slider.btnText}</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )}

        {/* Output */}
        {output}

        {state.showResult && (
          <>
            {/* Separator */}
            <hr className={style.separator} />

            {data.profiles.length > 0 && (
                <RecommendedTherapists usernames={data.profiles} />
            )}

            {/* Positive Message */}
            <div className={style.positiveMessage}>
              <img
                className={style.positiveMessageImage}
                alt="Positive Message Image"
                src={positiveMessageImage}
                aria-hidden
              />
              <div className={style.circle}>
                <p style={{ fontSize: '40px', fontWeight: 900 }}>You deserve to feel better</p>
                <p style={{ fontSize: '24px', fontWeight: 400 }}>
                  Things may feel like they'll never change. But you don't have to live with the
                  severe symptoms.
                </p>
                <p style={{ fontSize: '25px', fontWeight: 900 }}>
                  Change starts with a single step
                </p>
              </div>
            </div>

            {/* How it Works */}
            <div className={style.howItWorks}>
              <h1 className={style.howItWorksHeading}>How It Works</h1>
              <div className={style.howItWorksSteps}>
                <div className={style.howItWorksStep}>
                  <HowItWorksStep1 />
                  <p>Search for your therapist</p>
                </div>
                <LongRightArrow className={style.longArrow} />
                <div className={style.howItWorksStep}>
                  <HowItWorksStep2 />
                  <p>Book your therapist </p>
                </div>
                <LongRightArrow className={style.longArrow} />
                <div className={style.howItWorksStep}>
                  <HowItWorksStep3 />
                  <p>Start therapy</p>
                </div>
              </div>
            </div>

            {/* Separator */}
            <hr className={style.separator} />

            {/* A Whole World of Therapists */}
            <div className={style.aWholeWorldOfTherapists}>
              <h1 className={style.aWholeWorldOfTherapistsHeading}>A Whole World of Therapists</h1>
              <div className={style.aWholeWorldOfTherapistsContent}>
                <div className={style.aWholeWorldOfTherapistsAdvantages}>
                  <div className={style.aWholeWorldOfTherapistsAdvantage}>
                    <img src={freeImg} alt="Free Session" />
                    <p className={style.aWholeWorldOfTherapistsAdvantageTitle}>
                      First-time free sessions
                    </p>
                    <p className={style.aWholeWorldOfTherapistsAdvantageDescription}>
                      Many therapists offer 30 minute bookings for free to familiarise yourself
                    </p>
                  </div>
                  <div className={style.aWholeWorldOfTherapistsAdvantage}>
                    <img src={therapyImg} alt="Therapy" />
                    <p className={style.aWholeWorldOfTherapistsAdvantageTitle}>
                      Therapy from the comfort of your home
                    </p>
                    <p className={style.aWholeWorldOfTherapistsAdvantageDescription}>
                      Video conference technology allows for calls from the comfort/safety of your
                      home
                    </p>
                  </div>
                  <div className={style.aWholeWorldOfTherapistsAdvantage}>
                    <img src={findImg} alt="Therapy" />
                    <p className={style.aWholeWorldOfTherapistsAdvantageTitle}>
                      Find and compare therapists
                    </p>
                    <p className={style.aWholeWorldOfTherapistsAdvantageDescription}>
                      Therapists across different areas of specialisation and price
                    </p>
                  </div>
                  <div className={style.aWholeWorldOfTherapistsAdvantage}>
                    <img src={scheduldeImg} alt="Schedulde" />
                    <p className={style.aWholeWorldOfTherapistsAdvantageTitle}>
                      Schedule an online booking instantly
                    </p>
                    <p className={style.aWholeWorldOfTherapistsAdvantageDescription}>
                      View therapists availability and schedule your booking
                    </p>
                  </div>
                  <div className={style.aWholeWorldOfTherapistsAdvantage}>
                    <img src={paySvg} alt="Pay" />
                    <p className={style.aWholeWorldOfTherapistsAdvantageTitle}>
                      Pay only after a successful consultation
                    </p>
                    <p className={style.aWholeWorldOfTherapistsAdvantageDescription}>
                      Only pay on the basis that your consultation was successful
                    </p>
                  </div>
                </div>

                <div className={style.aWholeWorldOfTherapistsFooter}>
                  <Link
                    to="/search?&rate=&category=&skills=industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor&languages="
                    className={style.seeMore}
                  >
                    FIND A THERAPIST
                    <div className={style.seeMoreIcon}>
                      <ShortRightArrow />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            {/* A Network of Professionals */}
            <div className={style.aNetworkOfProfessionals}>
              <h1 className={style.aNetworkOfProfessionalsHeading}>
                Meet our Network of Mental Health Professionals
              </h1>
              <h2 className={style.aNetworkOfProfessionalsSubheading}>
                Our network of providers covers a range of specialities to meet your specific needs.
                Get matched today!
              </h2>
              <div className={style.aNetworkOfProfessionalsContent}>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Depression
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Parenting
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Learning Disabilities
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Anxiety & Stress
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Child Psychology
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Eating Disorders
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Grief
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Anger Management
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Family Conflict
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Trauma
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Burnout
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Abuse
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Relationship
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Mood Disorders
                </div>
                <div className={style.aNetworkOfProfessionalsMentalHealthIssue}>
                  <Tick /> Panic Attacks
                </div>
                <div className={style.getMatched}>
                  <Link className={style.getMatchedButton} to="/search">
                    GET MATCHED
                  </Link>
                </div>
                <div className={style.aNetworkOfProfessionalsProfessionalImage}>
                  <Tick className={style.aNetworkOfProfessionalsProfessionalBackground} />
                  <img src={rudselMduroImage} alt="Rudsel Mduro" aria-hidden />
                </div>
              </div>
            </div>

            {/* Testimonial */}
            {data.testimonials.length > 0 && (
                <>
                    <div className={style.testomonialDesktop}>
                        <div className={style.benefitsa}>
                            <p className={style.bftitle}>Testimonials</p>
                            <div className={style.wrapperTestimonial}>
                            <img src={testimonialCircleL} alt="leftCircle" className={style.leftCircle} />
                            <img src={testimonialCircleR} alt="leftCircle" className={style.rightCircle} />
                            <div
                                className={style.testimonial}
                                style={{ transform: `translateX(${state.testimonialScroll}px)` }}
                            >
                                {data.testimonials.map((testimon) => (
                                <div key={testimon.id} className={style.testContainer}>
                                    <div className={style.leftTest}>
                                    <img src={star5} alt="star" />
                                    <p className={style.tname}>{testimon.name}</p>
                                    </div>
                                    <div className={style.rightTest}>
                                    <p className={style.tsub}>{testimon.comment}</p>
                                    </div>
                                </div>
                                ))}
                            </div>
                            </div>

                            <div className={style.marker}>
                            <div className={style.clbuttons}>
                                <button
                                type="button"
                                className={style.clbtn}
                                onClick={testimonialPreviousHandler}
                                >
                                <img src={arrow} alt="button" />
                                </button>
                                <button type="button" className={style.clbtnr} onClick={testimonialNextHandler}>
                                <img src={arrow} alt="button" />
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.testomonialMobile}>
                        <div className={style.benefitsa}>
                            <p className={style.bftitle}>Testimonials</p>
                            <div className={style.wrapperTestimonial}>
                            <img src={testimonialCircleL} alt="leftCircle" className={style.leftCircle} />
                            <img src={testimonialCircleR} alt="leftCircle" className={style.rightCircle} />
                            <div
                                className={style.testimonial}
                                style={{ transform: `translateX(${state.testimonialScroll}px)` }}
                            >
                                {data.testimonials.map((testimon) => (
                                <div key={testimon.id} className={style.testContainer}>
                                    <div className={style.leftTest}>
                                    <img src={star5} alt="star" />
                                    <p className={style.tname}>{testimon.name}</p>
                                    </div>
                                    <div className={style.rightTest}>
                                    <p className={style.tsub}>{testimon.comment}</p>
                                    </div>
                                </div>
                                ))}
                            </div>
                            </div>

                            <div className={style.marker}>
                            <div className={style.clbuttons}>
                                <button
                                type="button"
                                className={style.clbtn}
                                onClick={testimonialPreviousHandler}
                                >
                                <img src={arrow} alt="button" />
                                </button>
                                <button type="button" className={style.clbtnr} onClick={testimonialNextHandler}>
                                <img src={arrow} alt="button" />
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
          </>
        )}
      </div>
    </>
  );
}
