/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// Remove this in future
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Remove this in future
import Header from '../../components/Header';
import SearchBanner from '../../components/SearchBanner';
import SearchFilter from '../../components/SearchFilter';
import SearchProfileSection from '../../components/SearchProfileSection';
import { fetchUsers, fetchUsersWithFilter, fetchUsersWithQuery } from '../../services/search';
import styles from './style.module.scss';
import { updateProfile } from '../../../actions/userAction';
import { fetchCountryCurrencyDetails } from '../../../actions/masterAction';
import SearchOld from '../../../components/Search/Search';
import { checkIsMobileBrowser } from '../../../utils';

const searchAction = {
  search: 1,
  query: 2,
  filter: 3,
};

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: [],
      queryValue: '',
      pageNumber: 1,
      totalPage: 1,
      scrollContinue: true,
      currentSearchAction: searchAction.search,
      filterSearch: '',
      recentSkills: [],
      isSearchFetching: true,
    };
  }

  async componentDidMount() {
    const { history, onFetchCountryCurrencyDetails } = this.props;

    await onFetchCountryCurrencyDetails();

    history.listen((location) => this.handleUrlUpdateListener(location));

    setTimeout(() => {
      this.loadSearchQueryOnMount();
    }, 1000);
  }

  handleScroll = async () => {
    const { pageNumber, totalPage, currentSearchAction, filterSearch } = this.state;

    if (pageNumber <= totalPage) {
      if (currentSearchAction === searchAction.search) {
        await this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
        this.fetchUsersData();
      }

      if (currentSearchAction === searchAction.filter) {
        await this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
        this.fetchUsersDataWithFilter(filterSearch);
      }

      if (currentSearchAction === searchAction.query) {
        await this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
        this.fetchUsersDataWithQuery();
      }
    } else {
      this.setState({ scrollContinue: false });
    }
  };

  fetchUsersData = async () => {
    this.setState({ isSearchFetching: true });

    const { currency } = this.props;
    const { pageNumber } = this.state;
    const responseData = await fetchUsers(pageNumber, currency);
    const totalPage = Math.ceil(responseData.totalRecords / 18);

    this.setState((prevState) => ({
      searchData: [...prevState.searchData, ...responseData.data],
      totalPage,
      currentSearchAction: searchAction.search,
      isSearchFetching: false,
    }));
  };

  fetchUsersDataWithFilter = async (query) => {
    this.setState({ isSearchFetching: true });
    const { currency } = this.props;
    const { pageNumber } = this.state;
    const responseData = await fetchUsersWithFilter(query, pageNumber, currency);

    const totalPage = Math.ceil(responseData.totalRecords / 18);

    if (pageNumber === 1) {
      this.setState(() => ({
        searchData: [...responseData.data],
        totalPage,
        currentSearchAction: searchAction.filter,
        isSearchFetching: false,
      }));
    } else {
      this.setState((prevState) => ({
        searchData: [...prevState.searchData, ...responseData.data],
        totalPage,
        currentSearchAction: searchAction.filter,
        isSearchFetching: false,
      }));
    }
  };

  fetchUsersDataWithQuery = async () => {
    this.setState({ isSearchFetching: true });

    const { currency } = this.props;
    const { pageNumber, queryValue } = this.state;
    const responseData = await fetchUsersWithQuery(queryValue, pageNumber, currency);
    const totalPage = Math.ceil(responseData.totalRecords / 18);

    if (pageNumber === 1) {
      this.setState(() => ({
        searchData: [...responseData.data],
        totalPage,
        currentSearchAction: searchAction.query,
        isSearchFetching: false,
      }));
    } else {
      this.setState((prevState) => ({
        searchData: [...prevState.searchData, ...responseData.data],
        totalPage,
        currentSearchAction: searchAction.query,
        isSearchFetching: false,
      }));
    }
  };

  loadSearchQueryOnMount = () => {
    const { history } = this.props;
    const { location } = history;

    if (!location.search) {
      return this.fetchUsersData();
    }

    this.setState({ filterSearch: location.search });
    return this.fetchUsersDataWithFilter(location.search);
  };

  handleUrlUpdateListener = async (nav) => {
    const paramsUrl = new URLSearchParams(nav.search);
    const { pageNumber } = this.state;

    if (paramsUrl.has('query')) {
      this.setState({ queryValue: paramsUrl.get('query') || '' });
    }

    this.setState({ filterSearch: nav.search });
    this.fetchUsersDataWithFilter(nav.search, pageNumber);

    return true;
  };

  handleOnSearchQuery = async (query) => {
    await this.setState({
      pageNumber: 1,
      totalPage: 1,
      scrollContinue: true,
      currentSearchAction: searchAction.search,
      filterSearch: '',
    });

    const { history } = this.props;
    const pathName = window.location.pathname;

    const params = new URLSearchParams({
      query: query.toLowerCase(),
    });

    history.replace(`${pathName}?${params.toString()}`);
  };

  urlBuilder = (param, val) => {
    // const pathName = window.location.pathname;
    const currentSearchVal = window.location.search;
    const urlStringBuild = new URLSearchParams(currentSearchVal);

    urlStringBuild.set(param, val);

    return `?${urlStringBuild.toString()}`;
  };

  handleRecentSkills = (skill) => {
    this.setState((prevState) => {
      const { recentSkills } = prevState;

      if (recentSkills.length >= 5) {
        recentSkills.shift();
      }

      recentSkills.push(skill);

      return {
        ...prevState,
        recentSkills,
      };
    });
  };

  handleOnFilterSelect = async (filterName, value, clear = false) => {
    await this.setState({
      pageNumber: 1,
      totalPage: 1,
      scrollContinue: true,
      currentSearchAction: searchAction.search,
      filterSearch: '',
    });

    const { history } = this.props;
    const filterType = filterName.toLowerCase();

    if (filterType === 'skills' || filterType === 'languages' || filterType === 'countries') {
      const filterValueArray = value.map((val) => val.searchKey);
      const filterValue = filterValueArray.toString();

      if (filterType === 'skills' && !clear) {
        this.handleRecentSkills(value[value.length - 1].searchKey);
      }

      const urlToSearch = this.urlBuilder(filterType, filterValue);

      return history.replace(urlToSearch);
    }

    if (filterType === 'rate') {
      const urlToSearch = this.urlBuilder(filterType, value.searchKey);
      return history.replace(urlToSearch);
    }

    if (filterName === 'ratefilter') {
      const urlToSearch = this.urlBuilder(filterType, value);
      return history.replace(urlToSearch);
    }

    if (filterName === 'date') {
      const urlToSearch = this.urlBuilder(filterType, value);
      return history.replace(urlToSearch);
    }

    return true;
  };

  render() {
    const { queryValue, scrollContinue, recentSkills, isSearchFetching } = this.state;
    let { searchData } = this.state;
    const { activeUserId, isLoggedIn, currency, history } = this.props;

    if (isLoggedIn) {
      searchData = searchData.filter((user) => user.userId !== activeUserId);
    }

    return (
      <div className={styles.searchPage} ref={this.myRef}>
        {checkIsMobileBrowser(navigator.userAgent) ? (
          <SearchOld {...this.props} />
        ) : (
          <>
            <div className={styles.searchHeader}>
              <Header />
            </div>
            <SearchBanner onSearchQuery={this.handleOnSearchQuery} queryValue={queryValue} />
            <div className={styles.searchProfiles}>
              <div className={styles.container}>
                <div className={styles.leftSide}>
                  <SearchFilter
                    onHandleOnFilterSelect={this.handleOnFilterSelect}
                    recentSkills={recentSkills}
                    currency={currency}
                    history={history}
                  />
                </div>
                <div className={styles.rightSide}>
                  <InfiniteScroll
                    dataLength={searchData.length}
                    next={this.handleScroll}
                    hasMore={scrollContinue}
                  >
                    <SearchProfileSection
                      searchData={searchData}
                      {...this.props}
                      isSearchFetching={isSearchFetching}
                    />
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userLocalCurrency =
    !state.userState.isLoggedIn && state.userState.currency.currencyCode
      ? state.userState.currency
      : { currencyCode: 'EUR', currencyName: 'Euro', currencySymbol: '€' };

  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : userLocalCurrency;

  const isFreeSessionConsumed = !!(
    state.userState.isLoggedIn && state.userState.user.isFreeSessionConsumed
  );

  return {
    isLoggedIn: state.userState.isLoggedIn,
    currency,
    isFreeSessionConsumed,
    activeUserId: state.userState.user.userId,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
      onFetchCountryCurrencyDetails: fetchCountryCurrencyDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(SearchPage);
