import React, { MouseEvent, ReactNode, forwardRef } from 'react';
import { Track } from '@conrati/tracking';
import { toCamelCase } from '../../utils/naming-convention';
import styles from './styles.module.scss';
import { Spinner } from '../spinner';

export type ButtonVariants =
	| 'plain'
	| 'primary'
	| 'secondary'
	| 'outlined-plain'
	| 'green'
	| 'outlined-secondary';

interface ButtonProps {
	children: ReactNode;

	/**
	 * Button variants
	 *
	 * buttons may be one of a variety of visual variants such as:
	 *
	 * '`plain', 'primary', 'secondary', 'outlined-plain'`
	 */
	variant?: ButtonVariants;
	type?: 'button' | 'submit' | 'reset';
	className?: string;

	rounded?: boolean;

	/**
	 * Disables the Button, preventing mouse events
	 */
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

	/** Providing a `href` will render an `<a>` element even if provided button type, _styled_ as a button. */
	href?: string;

	/** loading */
	loading?: boolean;

	/**
	 * Track a new event
	 * @property `eventName` - The name of the event
	 * @property `eventProperties` - The properties of the event
	 **/
	tracking?: {
		eventName: string;
		eventProperties: { [key: string]: unknown };
	};
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const { loading, tracking, onClick } = props;

		const styleVariant = props.variant;

		const inputProps = { ...props, variant: undefined };

		let className = `${
			styleVariant ? styles[toCamelCase(styleVariant)] : styles.default
		} ${props.className || ''}`;

		if (props.rounded) {
			className += ` ${styles.rounded}`;
		}

		// Delete rounded prop to avoid React warning
		delete inputProps.rounded;
		delete inputProps.loading;
		delete inputProps.onClick;

		const onClickEvent = (ev: MouseEvent<HTMLButtonElement>): void => {
			if (onClick) onClick(ev);

			if (tracking) {
				Track(tracking.eventName, tracking.eventProperties);
			}
		};

		if (loading) {
			return (
				<button
					{...inputProps}
					ref={ref}
					disabled
					className={className}
				>
					<Spinner />
				</button>
			);
		}

		return (
			<button
				{...inputProps}
				onClick={onClickEvent}
				ref={ref}
				className={className}
			>
				{props.children}
			</button>
		);
	}
);

Button.displayName = 'Button';

Button.defaultProps = {
	type: 'button',
	className: undefined,
	disabled: false,
	variant: 'primary',
};

export default Button;
