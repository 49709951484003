import React, { Component } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import styles from '../Myaccount.module.css';
import EditTooltip from '../../Common/EditTooltip/EditTooltip';
import KycPopUp from './KycPopUp';
// import enums from '../../../config/enum';
import kyc_payment from '../../Common/assets/kyc_payment.svg';
import Soletrader from './Soletrader';
import Buisness from './Buisness';
import enums from '../../../config/enum';

const customStyles = {
  content: {
    top: '43%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '600px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};

Modal.setAppElement('#root');

class KycDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      required: false,
      modalIsOpen: false,
      kycConfirm: false,
      isModalOpen: false,
    };

    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  openModal() {
    if (!this.props.user.isBusinessRegistered) {
      return toast.warn('Please add your business details first', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    this.setState({ modalIsOpen: true });
  }

  componentDidMount() {
    this.setState({ kycConfirm: this.props.kycConfirm });
    this.props.onFetchAllCountries();
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.onFetchKycDocuments(this.props.user);
  }

  onConfirm() {
    this.setState({
      modalIsOpen: false,
      edit: !this.state.edit,
      kycConfirm: true,
    });
    this.props.onFetchKycDocuments(this.props.user);
    this.props.onKycPopUpConfirm(true);
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onTooltipClick() {
    if (!this.props.user.isBusinessRegistered) {
      return toast.warn('Please add your business details first', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (this.state.kycConfirm) {
      if (!this.state.edit) {
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }

      const isBankOpen = window.localStorage.getItem('bpayOpen');

      if (isBankOpen === 'true') {
        return false;
      }

      return this.setState({ edit: !this.state.edit });
    }

    this.openModal();
  }

  handleOutsideClick(e) {
    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    if (this.state.required) {
      alert('Please fill all the required details');
      return;
    }

    if (this.state.isModalOpen) {
      return;
    }

    this.onTooltipClick();
  }

  isModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  render() {
    return (
      <div
        className={this.state.edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        {this.props.user.isKycVerified ? (
          <div className={styles.kycver}>
            <p className={styles.kycstatus}>KYC VERIFIED</p>
          </div>
        ) : (
          <div className={styles.kycver}>
            <p className={cx(styles.kycstatus, styles.refused)}>NOT KYC VERIFIED</p>
          </div>
        )}
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={kyc_payment} alt="Kyc" />
          <p className={styles.atitle}>KYC Requirements</p>

          {!this.props.user.isKycVerified && (
            <div className={styles.tooltip}>
              <EditTooltip onClick={this.onTooltipClick} active={this.state.edit} />
            </div>
          )}
        </div>
        <div className={styles.acbody}>
          <div className={styles.alitemk}>
            <p className={styles.alisubik}>
              Know Your Customer (KYC) is a process of verification of identity - either yourself or
              your business - whereby you submit documents. This is to comply with EU anti-money
              laundering laws.
            </p>

            {this.props.buisnessDetails &&
            this.props.buisnessDetails.typeOfBusiness ===
              enums.typeOfBusinessText['Freelancer / Soletrader'] ? (
              <Soletrader {...this.props} edit={this.state.edit} />
            ) : (
              <Buisness modalOpen={this.isModalOpen} {...this.props} edit={this.state.edit} />
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="KYC CONFIRM"
        >
          <KycPopUp closeModal={this.closeModal} onConfirm={this.onConfirm} {...this.props} />
        </Modal>
      </div>
    );
  }
}

export default KycDetails;
