const enums = {
  loginType: {
    guest: 1,
    email: 2,
    social: 3,
  },

  profileCompleted: {
    notcomplete: 0,
    personaldetails: 1,
    business: 2,
    calendar: 3,
    availabilty: 4,
    complete: 5,
  },

  userType: {
    not_defined: 0,
    ap: 1,
    ar: 2,
    both: 3,
  },

  userTypeText: {
    1: 'AP',
    2: 'AR',
    3: 'BOTH',
  },

  bankAccountType: {
    0: 'iban',
    1: 'other',
  },

  sessionStatus: {
    created: 1,
    active: 2,
    expired: 3,
  },

  supportType: {
    0: 'account',
    1: 'appsoftware',
    2: 'billing',
    3: 'suggestion',
    4: 'performance',
    5: 'functionalitynotworking',
    6: 'other',
  },

  kycDocumentType: {
    0: 'IDENTITY_PROOF',
    1: 'REGISTRATION_PROOF',
    2: 'ARTICLES_OF_ASSOCIATION',
    3: 'SHAREHOLDER_DECLARATION',
    4: 'ADDRESS_PROOF',
  },

  kycDocumentTypeText: {
    IDENTITY_PROOF: 0,
    REGISTRATION_PROOF: 1,
    ARTICLES_OF_ASSOCIATION: 2,
    SHAREHOLDER_DECLARATION: 3,
    ADDRESS_PROOF: 4,
  },

  kycDocumentStatus: {
    CREATED: 0,
    VALIDATION_ASKED: 1,
    VALIDATED: 2,
    REFUSED: 3,
  },

  kycDocumentStatusText: {
    0: 'CREATED',
    1: 'VALIDATION ASKED',
    2: 'VALIDATED',
    3: 'REFUSED',
  },

  kycDocumentStatusMangopay: {
    CREATED: 'CREATED',
    VALIDATION_ASKED: 'VALIDATION_ASKED',
    VALIDATED: 'VALIDATED',
    REFUSED: 'REFUSED',
    OUT_OF_DATE: 'OUT_OF_DATE',
  },

  kycDocumentTypeMangopay: {
    IDENTITY_PROOF: 'IDENTITY_PROOF',
    REGISTRATION_PROOF: 'REGISTRATION_PROOF',
    ARTICLES_OF_ASSOCIATION: 'ARTICLES_OF_ASSOCIATION',
    SHAREHOLDER_DECLARATION: 'SHAREHOLDER_DECLARATION',
    ADDRESS_PROOF: 'ADDRESS_PROOF',
  },

  typeOfBusiness: {
    0: 'Freelancer / Soletrader',
    1: 'Business',
    2: 'Organisation',
    3: 'Individual / Consumer',
  },

  typeOfBusinessText: {
    'Freelancer / Soletrader': 0,
    Business: 1,
    Organisation: 2,
    'Individual / Consumer': 3,
  },

  bookingStatus: {
    NEW_BOOKING: 0,
    SUCCESSFUL: 1,
    AP_NOSHOW: 2,
    AR_NOSHOW: 3,
    AR_CANCELLED: 4,
    AP_CANCELLED: 5,
    TECHNICAL_FAILURE: 6,
    AP_AR_NO_SHOW: 7,
    PENDING_CONFIRMATION: 8,
  },

  bookingStatusText: {
    0: 'NEW BOOKING',
    1: 'SUCCESSFUL',
    2: 'AP NO SHOW',
    3: 'AR NO SHOW',
    4: 'AR CANCELLED',
    5: 'AP CANCELLED',
    6: 'TECHNICAL FAILURE',
    7: 'AP AR NO SHOW',
    8: 'PENDING_CONFIRMATION',
  },

  oauthType: {
    Google: 0,
    Microsoft: 1,
    linkedin: 2,
  },

  tokboxSessionStatus: {
    CREATED: 1,
    STARTED: 2,
    EXPIRED: 3,
  },
  oauthTokenStatus: {
    VALID: 1,
    INVALID: 2,
  },
  onboardingStep: {
    ProfileImage: 1,
    PersonalDetails: 2,
    BusinessDetails: 3,
    CalendarSettings: 4,
    Availabilty: 5,
    CONGRATS: 6,
  },
  analyticsUtmSource: {
    referral: 'referral',
    email: 'email',
    facebook: 'facebook',
    linkedin: 'linkedin',
    youtube: 'youtube',
    twitter: 'twitter',
  },
  accountStatus: {
    active: 1,
    frozen: 2,
    review: 3,
  },
  tokboxSignalType: {
    Joined: 'Joined',
    Message: 'Message',
    DisconnectSession: 'DisconnectSession',
    SessionEndedByAdviceReceiver: 'session-ended-by-advice-receiver',
  },
  sessionDisconnectType: {
    Successful: 1,
    NoShowAP: 2,
    NoShowAR: 3,
  },
  accountCategory: {
    full: 1,
    light: 2,
  },
};

export default enums;
