import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styles from './ResetPassword.module.css';
import whitelogo from '../../Common/assets/whiteyellow-logo.png';
import resetpassword from '../../Common/assets/reset-password.png';
import bluelarge from '../../Common/assets/blue-large.svg';
import Input from '../../Common/Input/Input';
import { isValidEmail } from '../../../utils';
import ResetSucess from './ResetSucess';
import { forgetPassword, logOutUserState } from '../../../actions/userAction';
import { resetUserPassword } from '../../../actions/tempAction';
import SetPassword from './SetPassword';
import globalProptype from '../../../config/proptype';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
      sent: false,
      setpassword: false,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { userState, onLogOut } = this.props;
    if (userState.isLoggedIn) {
      onLogOut(userState.user);
    }
  }

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const setpassword = params.get('setpassword');
    if (setpassword === 'true') {
      this.setState({ setpassword: true });
    }
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (isValidEmail(e.target.value)) {
      this.setState({ emailValid: true });
    }
  };

  handleSubmit = () => {
    const { email } = this.state;
    const { onForgetPassword } = this.props;
    onForgetPassword(email);
    this.setState({ sent: true });
  };

  handleBack = () => {
    this.setState({ sent: false, emailValid: false, email: '' });
  };

  render() {
    const { history, onResetPassword, location, request } = this.props;
    const { email, emailValid, setpassword, sent } = this.state;
    let resetpasswordContainer = (
      <div>
        <div className={styles.title}>
          <p>Lost password? Let&apos;s reset it</p>
        </div>
        <div className={styles.ebox}>
          <div className={styles.emsg}>
            <p className={styles.etext}>
              Fill in your email address below, and we will email you a link to create a new
              password.
            </p>
            <Input
              name="email"
              placeholder="Email address"
              htmlFor="Email"
              label="Email address"
              onChange={this.handleInput}
            />
          </div>
          <div className={styles.eimg}>
            <img src={resetpassword} alt="Email sent" width="100%" height="100%" />
          </div>
        </div>
        <div className={styles.snext}>
          <div className={styles.rbtn}>
            <button
              type="button"
              className={styles.snextbtnb}
              onClick={() => history.push('/signin')}
            >
              Back
            </button>
            <button
              type="button"
              disabled={!(email.length > 0 && emailValid)}
              className={styles.snextbtn}
              onClick={this.handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );

    if (setpassword === true) {
      resetpasswordContainer = (
        <SetPassword onResetPassword={onResetPassword} request={request} location={location} />
      );
    }

    if (sent) {
      resetpasswordContainer = (
        <ResetSucess onBack={this.handleBack} onResend={this.handleSubmit} />
      );
    }

    return (
      <div>
        <header className={styles.aheader} style={{ backgroundImage: `url(${bluelarge})` }}>
          <Link to="/" className={styles.alink}>
            <img src={whitelogo} width="158px" alt="" />
          </Link>
        </header>
        <div className={styles.atabb}>
          <div className={styles.atab}>{resetpasswordContainer}</div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  userState: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
  }).isRequired,
  onForgetPassword: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  location: globalProptype.location.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  request: PropTypes.shape({
    error: PropTypes.bool,
    submit: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    request: state.request,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onForgetPassword: forgetPassword,
      onResetPassword: resetUserPassword,
      onLogOut: logOutUserState,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(ResetPassword);
