import React, { Component } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './Newonboarding.module.css';
import EditTooltip from '../../../Common/EditTooltip/EditTooltip';
import EditRadio from '../../../Common/EditRadio/EditRadio';
import calendarico from '../../../Common/assets/calendar.svg';
import calendargoogle from '../../../Common/assets/calendar_google.png';
import calendarexchange from '../../../Common/assets/calendar_exchange.png';
import info from '../../../Common/assets/info.svg';
import selectedico from '../../../Common/assets/circle_selected.svg';
import { apiurl, alert } from '../../../../utils';
import enums from '../../../../config/enum';
import OauthPopUp from '../../../Common/OauthPopUp/OauthPopUp';
import { connectCalendar, editProfile } from '../../../../services/userService';

class CalendarDetailsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: false,
      oauthScreen: false,
      redirectUrl: '',
      isFreeSessionOpted: true,
    };
  }

  componentDidMount() {
    this.onOpenEditScreen();
  }

  componentDidUpdate(prevprops) {
    const { requiredStep } = this.props;
    if (prevprops.requiredStep !== requiredStep) {
      if (requiredStep !== enums.onboardingStep.CalendarSettings) {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    const { isFreeSessionOpted } = this.state;
    editProfile({
      is_free_session_opted: isFreeSessionOpted,
      opt_notification_email: isFreeSessionOpted ? 1 : 2,
    });
  }

  onOpenEditScreen = () => {
    const { open } = this.props;
    if (open) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  onTooltipClick = () => {
    const { completedStep, required, onTooltipClick, open, onAlert } = this.props;
    if (completedStep > 2) {
      if (!open && !required) {
        onTooltipClick(enums.onboardingStep.CalendarSettings);
        document.addEventListener('click', this.handleOutsideClick, false);
      }
    } else {
      onAlert();
    }
  };

  handleOutsideClick = (e) => {
    const { required } = this.state;
    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onSubmitData();

    if (required) {
      alert('Please fill all the required details');
    }
  };

  onSubmitData = () => {
    const { onCompleteEditing } = this.props;
    document.removeEventListener('click', this.handleOutsideClick, false);
    return onCompleteEditing(enums.onboardingStep.CalendarSettings);
  };

  OnGoogleConnect = () => {
    const { user, onSignInclickOauth } = this.props;
    if (!user.social.isGoogleConnected && !user.social.isMicrosoftConnected) {
      onSignInclickOauth('/myprofile/edit/step');
      this.setState({
        redirectUrl: `${apiurl}/v1/oauth/auth/google`,
        oauthScreen: true,
      });
    }

    if (
      user.social.oauthTokenStatus === enums.oauthTokenStatus.INVALID &&
      user.social.isGoogleConnected
    ) {
      onSignInclickOauth('/myprofile/edit/step');
      this.setState({
        redirectUrl: `${apiurl}/v1/oauth/auth/google`,
        oauthScreen: true,
      });
    }
  };

  onMicrosoftConnect = () => {
    const { user, onSignInclickOauth } = this.props;
    if (!user.social.isGoogleConnected && !user.social.isMicrosoftConnected) {
      onSignInclickOauth('/myprofile/edit/step');
      this.setState({
        redirectUrl: `${apiurl}/v1/oauth/auth/microsoft`,
        oauthScreen: true,
      });
    }

    if (
      user.social.oauthTokenStatus === enums.oauthTokenStatus.INVALID &&
      user.social.isMicrosoftConnected
    ) {
      onSignInclickOauth('/myprofile/edit/step');
      this.setState({
        redirectUrl: `${apiurl}/v1/oauth/auth/microsoft`,
        oauthScreen: true,
      });
    }
  };

  onCloseSharePopUp = (data) => {
    if (data) {
      const { onUpdateProfile, onCompleteEditing, onShowFinal } = this.props;
      this.setState({
        oauthScreen: false,
      });

      let toastId = null;

      toastId = toast.warn('Connecting Calendar!', {
        position: 'top-center',
        hideProgressBar: true,
      });

      connectCalendar(data)
        .then((response) => {
          if (response.success) {
            onUpdateProfile({
              registration_steps_completed: enums.profileCompleted.calendar,
            });

            ReactGA.pageview('/myprofile/ap/calendar');

            onCompleteEditing(enums.onboardingStep.CalendarSettings);

            toast.update(toastId, {
              render: 'Calender Connected!',
              autoClose: 3000,
            });

            onShowFinal('Availabilty');
          } else {
            toast.update(toastId, {
              render: response.message,
              autoClose: 7000,
            });
          }
        })
        .catch(() => {
          toast.update(toastId, {
            render: 'Error in connecting calendar. Please try again!',
            autoClose: 3000,
          });
        });
    }
  };

  render() {
    const { user, open, alertuser } = this.props;
    const { oauthScreen, redirectUrl } = this.state;
    const { social } = user;
    const { isFreeSessionOpted } = this.state;

    return (
      <div
        className={
          open
            ? cx(styles.personal, styles.cardborder)
            : cx(styles.personal, { [styles.alertclick]: alertuser })
        }
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={calendarico} alt="calendar" />
          <p className={styles.atitle}>Calendar & Consultation Settings</p>

          {user.userType === enums.userType.ap && <p className={styles.rerror}>* Required</p>}

          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={open} />
          </div>
        </div>
        {open && (
          <div className={styles.acbody}>
            <div className={styles.consultSetting}>
              <ReactTooltip
                id="actip"
                place="top"
                type="warning"
                effect="solid"
                className={styles.toolttip}
              />
              <div>
                <p className={styles.emailct}>Consultation Settings</p>
                <div style={{ display: 'flex' }}>
                  <p className={styles.alisub}>Allow 30 min free consultation on first booking</p>
                  <img
                    data-for="actip"
                    data-tip="Conrati recommends this configuration. Consultants who offer free 30-minute bookings get scheduled for paid consultations more often. It is a fantastic tool to market yourself to new prospects."
                    src={info}
                    width="15px"
                    height="15px"
                    alt="Info"
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
              <EditRadio
                checked={isFreeSessionOpted}
                onCheck={() => {
                  this.setState({
                    isFreeSessionOpted: !isFreeSessionOpted,
                  });
                }}
              />
            </div>
            <div className={styles.alitem}>
              <p className={styles.emailct}>Calendar Settings</p>
              <p className={styles.alisub}>
                Choose a preferred calendar that you already use for all your scheduled
                appointments. This will allow a two-way synchronisation between your newly assigned
                Conrati calendar and your connected personal calendar.
              </p>
              <div className={styles.calendarli}>
                <button type="button" className={styles.calndarbtn} onClick={this.OnGoogleConnect}>
                  <img src={calendargoogle} alt="Google Calndar" />
                  {social &&
                    social.isGoogleConnected &&
                    social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                      <div className={styles.cselected}>
                        <img src={selectedico} alt="Selected" width="17px" height="17px" />
                      </div>
                    )}
                </button>
                <button
                  type="button"
                  className={styles.calndarbtn}
                  onClick={this.onMicrosoftConnect}
                >
                  <img src={calendarexchange} alt="Exchange Calndar" />
                  {social &&
                    social.isMicrosoftConnected &&
                    social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                      <div className={styles.cselected}>
                        <img src={selectedico} alt="Selected" width="17px" height="17px" />
                      </div>
                    )}
                </button>
              </div>
              <div className={styles.emailc}>
                <div>
                  <p className={styles.emailct}>Email address associated with your calendar</p>
                  {social && social.oauthTokenStatus === enums.oauthTokenStatus.INVALID ? (
                    <p className={styles.alisub}>
                      {social && social.email
                        ? `${social.email} (EXPIRED)`
                        : 'Calendar Not Connected'}
                    </p>
                  ) : (
                    <p className={styles.alisub}>
                      {social && social.email ? social.email : 'Calendar Not Connected'}
                    </p>
                  )}
                </div>
              </div>
              <p className={styles.alisub}>
                Personal calendar information will remain private and will not be seen by anyone
                else but you.
              </p>
            </div>
          </div>
        )}
        {!open &&
          social &&
          (social.isGoogleConnected || social.isMicrosoftConnected) &&
          social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
            <div className={styles.acbody}>
              <div className={styles.alitem}>
                <p className={styles.alisub}>
                  Choose a preferred calendar that you already use for all your scheduled
                  appointments. This will allow a two-way synchronisation between your newly
                  assigned Conrati calendar and your connected personal calendar.
                </p>
                <div className={styles.calendarli}>
                  <button
                    type="button"
                    className={styles.calndarbtn}
                    onClick={this.OnGoogleConnect}
                  >
                    <img src={calendargoogle} alt="Google Calndar" />
                    {social &&
                      social.isGoogleConnected &&
                      social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                        <div className={styles.cselected}>
                          <img src={selectedico} alt="Selected" width="17px" height="17px" />
                        </div>
                      )}
                  </button>
                  <button
                    type="button"
                    className={styles.calndarbtn}
                    onClick={this.onMicrosoftConnect}
                  >
                    <img src={calendarexchange} alt="Exchange Calndar" />
                    {social &&
                      social.isMicrosoftConnected &&
                      social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                        <div className={styles.cselected}>
                          <img src={selectedico} alt="Selected" width="17px" height="17px" />
                        </div>
                      )}
                  </button>
                </div>
                <div className={styles.emailc}>
                  <div>
                    <p className={styles.emailct}>Email address associated with your calendar</p>
                    {social && social.oauthTokenStatus === enums.oauthTokenStatus.INVALID ? (
                      <p className={styles.alisub}>
                        {social && social.email
                          ? `${social.email} (EXPIRED)`
                          : 'Calendar Not Connected'}
                      </p>
                    ) : (
                      <p className={styles.alisub}>
                        {social && social.email ? social.email : 'Calendar Not Connected'}
                      </p>
                    )}
                  </div>
                </div>
                <p className={styles.alisub}>
                  Personal calendar information will remain private and will not be seen by anyone
                  else but you.
                </p>
              </div>
            </div>
          )}
        {oauthScreen && (
          <OauthPopUp redirectUrl={redirectUrl} onCloseModal={this.onCloseSharePopUp} />
        )}
      </div>
    );
  }
}

CalendarDetailsNew.propTypes = {
  requiredStep: PropTypes.number,
  open: PropTypes.bool.isRequired,
  completedStep: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
  onTooltipClick: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onCompleteEditing: PropTypes.func.isRequired,
  onSignInclickOauth: PropTypes.func.isRequired,
  user: PropTypes.shape({
    social: PropTypes.object,
    userType: PropTypes.number,
  }).isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onShowFinal: PropTypes.func.isRequired,
  alertuser: PropTypes.bool.isRequired,
};

CalendarDetailsNew.defaultProps = {
  requiredStep: 0,
};

export default CalendarDetailsNew;
