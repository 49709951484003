import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Timer from './Timer';
import style from './style.module.scss';
import cameraOnIcon from '../../assets/camera_on.svg';
import cameraOffIcon from '../../assets/camera_off.svg';
import micOnIcon from '../../assets/mic_on.svg';
import micOffIcon from '../../assets/mic_off.svg';
import { sendEvent } from '../../utils/event';
import globalProptypes from '../../../config/proptype/index';

function MicController({ isMicOn, onSetMicOn }) {
  return (
    <button
      type="button"
      className={style.mic}
      onClick={() => {
        onSetMicOn(!isMicOn);
        sendEvent('mic-on', !isMicOn);
      }}
    >
      <img src={isMicOn ? micOnIcon : micOffIcon} alt="Mic" />
    </button>
  );
}

function CameraController({ isCameraOn, onSetCameraOn }) {
  return (
    <button
      type="button"
      className={style.mic}
      onClick={() => {
        onSetCameraOn(!isCameraOn);
        sendEvent('video-on', !isCameraOn);
      }}
    >
      <img src={isCameraOn ? cameraOnIcon : cameraOffIcon} alt="Camera" />
    </button>
  );
}

function SessionFooter({ bookingData, userData }) {
  const [isCameraOn, setCameraOn] = useState(true);
  const [isMicOn, setMicOn] = useState(true);

  return (
    <div className={style.sessionFooter}>
      <div className={style.leftAlign}>
        <Timer bookingData={bookingData} userData={userData} />
      </div>
      <div className={style.rightAlign}>
        <CameraController isCameraOn={isCameraOn} onSetCameraOn={setCameraOn} />
        <MicController isMicOn={isMicOn} onSetMicOn={setMicOn} />
      </div>
    </div>
  );
}

MicController.propTypes = {
  isMicOn: PropTypes.bool.isRequired,
  onSetMicOn: PropTypes.func.isRequired,
};

CameraController.propTypes = {
  isCameraOn: PropTypes.bool.isRequired,
  onSetCameraOn: PropTypes.func.isRequired,
};

SessionFooter.propTypes = {
  bookingData: PropTypes.shape({}).isRequired,
  userData: globalProptypes.user.isRequired,
};
export default SessionFooter;
