import React from 'react';
import styles from './Chat.module.css';
import moment from 'moment';
import btn_download from '../../../Common/assets/btn_download.svg';

const sentfile = (props) => {
  let initails = props.from.split(' ');
  return (
    <div className={styles.sent}>
      <div className={styles.minfo}>
        <div className={styles.usl}>{initails[0].charAt(0) + initails[1].charAt(0)}</div>
        <p className={styles.mtime}>{moment.unix(props.time).format('HH:mm')}</p>
        <p className={styles.mname}>{initails[0]}</p>
      </div>
      <div className={styles.messages}>
        <p>{props.filename}</p>
        <a href={props.link} target="_blank" className={styles.downloads} rel="noopener noreferrer">
          <img src={btn_download} alt="Download" height="20px" width="20px" />
        </a>
      </div>
    </div>
  );
};

export default sentfile;
