import React, { Component } from 'react';

class Share extends Component {
  render() {
    return (
      <div>
        <p>Share</p>
      </div>
    );
  }
}

export default Share;
