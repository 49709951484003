import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const Dashboard = () => {
  return (
    <div>
      <p className={styles.ftitle}>Dashboard</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What information is displayed on the dashboard of the Advice Provider?"
          content={`
                    • Upcoming sessions are displayed on the dashboard of the Advice Provider. If you have entries there, it means you have been booked and need to participate in an online consultation. 
                    
                    •  Against each new entry, you will see two buttons - Accept and Decline. By clicking on the “Accept” button, you will be able to confirm your availability for a session. If you wish to cancel a booking you may do so before the meeting starts by clicking on the “Decline” button.
                    Please note that if you do not confirm your availability by clicking on the “Accept” button within 23 hours you have been booked, the booking will be cancelled automatically.                    
                    
                    • If you have no entries here, it means you are currently not booked and need to ensure that your profile and hourly availability is updated. 
                    
                    • Upcoming sessions which an Advice Receiver booked will be shown on the dashboard of the Advice Receiver.
                    Until the booking has been confirmed by the Advice Provider, they will be marked as “Pending for confirmation”. Once they are confirmed by the Advice Provider, you will see a meeting icon in front of the session. 
                    
                    • If you have no entries here, it means you are currently not booked and need to ensure that your profile and hourly availability is updated. 
                    
                `}
          expand
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What information is displayed on the dashboard of the Advice Receiver?"
          content={`
                    • Upcoming sessions which an Advice Receiver booked will be shown on the dashboard of the Advice Receiver. Until the booking has been confirmed by the Advice Provider, they will be marked as “Pending for confirmation”. Once they are confirmed by the Advice Provider, you will see a meeting icon in front of the session. 
                    
                    • In case this is empty, it means no new bookings have been made                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Can I jump to online consultations from the dashboard?"
          content={`
                    Yes - you can jump to online consultations from the dashboard which contains the link to the online consultation, or you can open the session from your Calendar.                
                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default Dashboard;
