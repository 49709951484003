import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiurl } from '../../../../../utils';
import LoaderButton from '../../../../Common/LoaderButton/LoaderButton';
import styles from './PopUp.module.css';

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      focusl: false,
      focusr: false,
      start: '' || this.props.start,
      end: '' || this.props.end,
      selectedStart: '',
      selectedEnd: '',
      timeDiff: '',
      timeRange: [],
      timeRangeRight: [],
      uploading: false,
      error: false,
    };

    this.handleOnCLickControl = this.handleOnCLickControl.bind(this);
    this.getTimeSlots = this.getTimeSlots.bind(this);
    this.selectTimeSlot = this.selectTimeSlot.bind(this);
    this.editTimeSlots = this.editTimeSlots.bind(this);
    this.onSetAvailablity = this.onSetAvailablity.bind(this);
    this.onDeleteAvailablity = this.onDeleteAvailablity.bind(this);
  }

  componentDidMount() {
    this.getTimeSlots(this.props.start, this.props.end);
  }

  handleOnCLickControl(e, type) {
    e.preventDefault();
    if (type === 1) {
      this.setState({ focusr: false, focusl: !this.state.focusl });
    }

    if (type === 2) {
      this.setState({ focusr: !this.state.focusr, focusl: false });
    }
  }

  getTimeSlots(start, end) {
    const currentMoment = moment(start);
    const endMoment = moment(end);

    return this.setState({
      selectedStart: currentMoment,
      selectedEnd: endMoment,
      timeDiff: moment.duration(moment(endMoment).diff(currentMoment)).asHours(),
    });
  }

  editTimeSlots(start, end) {
    const currentMoment = moment(start);
    const endMoment = moment(end);
    const timezone = [];

    while (currentMoment.isSameOrBefore(endMoment, 'minutes')) {
      timezone.push(currentMoment.format());
      currentMoment.add(30, 'minutes');
    }

    const timeRangeRight = [...timezone];
    timeRangeRight.shift();

    return this.setState({ timeRangeRight });
  }

  selectTimeSlot(time, type) {
    if (type === 1) {
      const selectedEnd = moment(time).add(30, 'minutes').format();
      this.editTimeSlots(selectedEnd, this.props.end);
      this.setState({ selectedStart: time, selectedEnd, focusl: false });
      this.setState({ timeDiff: moment.duration(moment(selectedEnd).diff(time)).asHours() });
    }

    if (type === 2) {
      this.setState({ selectedEnd: time, focusr: false });
      this.setState({
        timeDiff: moment.duration(moment(time).diff(this.state.selectedStart)).asHours(),
      });
    }
  }

  handleButton(e, type) {
    e.preventDefault();
    if (type === 'cancel') {
      this.props.onCancel(e);
    }
  }

  onSetAvailablity() {
    this.setState({
      uploading: true,
    });

    const start = moment(this.state.selectedStart).unix();
    const end = moment(this.state.selectedEnd).unix();
    const { user } = this.props;

    const headers = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${user.userToken}`;

    axios
      .post(
        `${apiurl}/v1/user/availability/custom`,
        {
          from: start,
          to: end,
        },
        { headers }
      )
      .then((result) => {
        if (result.data.success) {
          this.props.onCancel();

          return toast.warn('Custom Availabilty Set', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        throw new Error('Error');
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
  }

  onDeleteAvailablity() {
    this.setState({
      uploading: true,
    });

    const { user } = this.props;

    const headers = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${user.userToken}`;

    const data = {
      id: this.props.id,
    };

    axios
      .delete(`${apiurl}/v1/user/availability/custom`, { headers, data })
      .then((result) => {
        if (result.data.success) {
          this.props.onCancel();

          return toast.warn('Custom Availabilty Deleted', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        throw new Error('Error');
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
  }

  render() {
    const sborder = cx(styles.selectcontrol, styles.scontrolborder);
    return (
      <div className={styles.popup}>
        <div className={styles.lightbg} />
        <div className={styles.pbody}>
          <div className={styles.pcard}>
            <div className={styles.pcdate}>
              <p>{moment(this.props.start).format('DD MMMM YYYY')}</p>
            </div>
            <div className={styles.pselect}>
              <div className={styles.pico}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+dJREFUWAnNWEFPE0EUbgvSkBJM9KJiTGhMTDhoNBDxYICDlxItUtoDHPwPJl6UaCRw5eZPKIfSIiGRi0QhHkQl4WBCgjFwEeTihdCDSMHvm7632XZ3S0tbdJLp7LyZ933fvn07M1uf7z8p/kp0pFKppkAg0Hd0dPQAfh1oL6FlZdn2+/3baNfQzh0eHr5PJBL7ZqSMn7KEQMAFYD1HHUFtLQOXU3ZRk6hjELRDQ6lSUsj8/Hwwm80+w50/BkiIQLjbVfRnGxoaPuB66+DgYDsYDPpzudxF2NvQ3oV9ANc3hTiL/mQoFJqIRCK/xeZoPIVIFF7Do1u80mhHcXfrDhQXA/yvwTyOOiTDy2gfekXHVUg6nb6OO3qDehnOm7j74VgsRqCKSyaT6UaUpuDYjsj8AGYEYr4WAzmEMBJw+CIilhobG2ODg4O/ih0r6c/MzJzHI8zAp0fEdBVHpkAIc2Jvb28RDnwcS+Fw+F5nZ+cfXFddVlZWzmxsbLwFUA/qcktLS689ZwJ2BiYm+hSxyUjUSgQ5iNXc3Mx82UTtFi4OmWJFRJLzO6wh5MSdk+aE4Ho2kjMfMSGLelUfkT0iXCf4iqbrJQLYPsHmG0gucppihCAaTehxsWIZzTfV/QLzHOo7Vhck5RgRbp8RwmUbk1uR0asIVVnrhAu4ZaIIYC3AQNyz1oBckINc6LYKd14IXlXuHT60szLXtcH6MjA9PR13HRSjigAWV9ZvWHXvu81XLuXWHOngZC7bbk5qw0b2Co7gSvHtchQXEX3RaJQboaPYuAy3EQJws4MiXFsOj0LDE3RzqOPFYioRQUjlUm6NiBHCDYyTvAqe7RQAHmG8QEylIoiPiPwUHsOtQsR2fBOPx5N2MciZCfQXcGeaE56PoxS6CjGRwGpq1JVy4JhdDAQ8PYkIHhuEx3AbIbgj0wFg23EidNwuBrZ1vB0VRUK5lFsjskYCHmqUqJyWYiDgCjawG15vhxeOjctwa0TmxCHq5ehlpwD7Luo1r9iOSAzQhtZwGyE86MK2i3oLbwBPVnUt5JC82hXu/MqK13IfyqaEnce7ehflSJKbZJojXN5fos+teYhbNQfrUQSb5xJyjSmHJQTKdhCVSQ4gXEke73RSrVpiyvmVuTFJTsW2hNDAIz8mfEJ0wnBI83inE6ttiUVM4LSjLpPLjum3d3iNRLIOzxC1iKV4qBaHZ4rADfYCkyd5x+G5ICIUIo+oXxx6AfC5mpyhL/YwfhUYEcDttz8ScrI4IpI3W5GZBcBtsZ3+B5aKKfeTk/O5T0F07T85VQxbyZsXIBpG9/Q/wu1iRFDd/pYo5vpn/b+UAxeKzwSaBwAAAABJRU5ErkJggg=="
                  alt="select"
                  width="26px"
                  height="26px"
                />
              </div>
              <div className={styles.select}>
                <div className={this.state.focusl ? sborder : styles.selectcontrol}>
                  <p className={styles.default}>
                    {moment(this.state.selectedStart).format('hh:mm a')}
                  </p>
                </div>
                {this.state.focusl && (
                  <div className={styles.selectdata}>
                    {this.state.timeRange.map((time, index) => (
                      <p key={index} className={styles.sitem}>
                        {moment(time).format('hh:mm a')}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.pto}>
                <p>to</p>
              </div>
              <div className={styles.pico}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+dJREFUWAnNWEFPE0EUbgvSkBJM9KJiTGhMTDhoNBDxYICDlxItUtoDHPwPJl6UaCRw5eZPKIfSIiGRi0QhHkQl4WBCgjFwEeTihdCDSMHvm7632XZ3S0tbdJLp7LyZ933fvn07M1uf7z8p/kp0pFKppkAg0Hd0dPQAfh1oL6FlZdn2+/3baNfQzh0eHr5PJBL7ZqSMn7KEQMAFYD1HHUFtLQOXU3ZRk6hjELRDQ6lSUsj8/Hwwm80+w50/BkiIQLjbVfRnGxoaPuB66+DgYDsYDPpzudxF2NvQ3oV9ANc3hTiL/mQoFJqIRCK/xeZoPIVIFF7Do1u80mhHcXfrDhQXA/yvwTyOOiTDy2gfekXHVUg6nb6OO3qDehnOm7j74VgsRqCKSyaT6UaUpuDYjsj8AGYEYr4WAzmEMBJw+CIilhobG2ODg4O/ih0r6c/MzJzHI8zAp0fEdBVHpkAIc2Jvb28RDnwcS+Fw+F5nZ+cfXFddVlZWzmxsbLwFUA/qcktLS689ZwJ2BiYm+hSxyUjUSgQ5iNXc3Mx82UTtFi4OmWJFRJLzO6wh5MSdk+aE4Ho2kjMfMSGLelUfkT0iXCf4iqbrJQLYPsHmG0gucppihCAaTehxsWIZzTfV/QLzHOo7Vhck5RgRbp8RwmUbk1uR0asIVVnrhAu4ZaIIYC3AQNyz1oBckINc6LYKd14IXlXuHT60szLXtcH6MjA9PR13HRSjigAWV9ZvWHXvu81XLuXWHOngZC7bbk5qw0b2Co7gSvHtchQXEX3RaJQboaPYuAy3EQJws4MiXFsOj0LDE3RzqOPFYioRQUjlUm6NiBHCDYyTvAqe7RQAHmG8QEylIoiPiPwUHsOtQsR2fBOPx5N2MciZCfQXcGeaE56PoxS6CjGRwGpq1JVy4JhdDAQ8PYkIHhuEx3AbIbgj0wFg23EidNwuBrZ1vB0VRUK5lFsjskYCHmqUqJyWYiDgCjawG15vhxeOjctwa0TmxCHq5ehlpwD7Luo1r9iOSAzQhtZwGyE86MK2i3oLbwBPVnUt5JC82hXu/MqK13IfyqaEnce7ehflSJKbZJojXN5fos+teYhbNQfrUQSb5xJyjSmHJQTKdhCVSQ4gXEke73RSrVpiyvmVuTFJTsW2hNDAIz8mfEJ0wnBI83inE6ttiUVM4LSjLpPLjum3d3iNRLIOzxC1iKV4qBaHZ4rADfYCkyd5x+G5ICIUIo+oXxx6AfC5mpyhL/YwfhUYEcDttz8ScrI4IpI3W5GZBcBtsZ3+B5aKKfeTk/O5T0F07T85VQxbyZsXIBpG9/Q/wu1iRFDd/pYo5vpn/b+UAxeKzwSaBwAAAABJRU5ErkJggg=="
                  alt="select"
                  width="26px"
                  height="26px"
                />
              </div>
              <div className={styles.select}>
                <div className={this.state.focusr ? sborder : styles.selectcontrol}>
                  <p className={styles.default}>
                    {moment(this.state.selectedEnd).format('hh:mm a')}
                  </p>
                </div>
                {this.state.focusr && (
                  <div className={styles.selectdata}>
                    {this.state.timeRangeRight.map((time, index) => (
                      <p key={index} className={styles.sitem}>
                        {moment(time).format('hh:mm a')}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.sslot}>
              <p>
                Selected slot{' '}
                {`${moment(this.state.selectedStart).format('hh:mm a')} ${moment(
                  this.state.selectedEnd
                ).format('hh:mm a')} (${this.state.timeDiff} hours)`}
              </p>
            </div>
            <div className={styles.pbtn}>
              <div className={styles.cancel}>
                <button className={styles.button} onClick={(e) => this.handleButton(e, 'cancel')}>
                  Cancel
                </button>
              </div>
              <div className={styles.book}>
                {this.props.type !== 'custom' && (
                  <LoaderButton
                    onClick={this.onSetAvailablity}
                    color="success"
                    active="SET AVAILABILTY"
                    success="AVAILABILTY SET"
                    disabled={!!this.state.uploading}
                    retry={!!this.state.err}
                  />
                )}
                {this.props.type === 'custom' && (
                  <LoaderButton
                    onClick={this.onDeleteAvailablity}
                    color="success"
                    active="DELETE AVAILABILTY"
                    success="DELETED"
                    disabled={!!this.state.uploading}
                    retry={!!this.state.err}
                  />
                )}
                {/* <button className={styles.button} onClick={this.onSetAvailablity}><Loader /></button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopUp;
