import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const Profile = () => {
  return (
    <div>
      <p className={styles.ftitle}>Profile</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="My profile has been frozen. Why?"
          content={
            <>
              <span>
                Your profile has been frozen due to your inability to uphold your set availability
                and bookings or due to a lack of profile information. Conrati wishes to present a
                quality of profiles to maintain a credible consulting marketplace. You are not able
                to be found or booked presently. You can contact our support staff{' '}
                <a href="mailto:support@conrati.com">support@conrati.com</a> in order to query and
                clarify this status. In the case of a lacking profile, please ensure that you have
                improved your profile information related to your skills, consulting topics,
                education, service description, experience and certifications before submitting your
                profile to be reviewed for unfreezing. Your profile should be detailed and impactful
                so that in your physical absence, your digital Conrati public profile acts on your
                behalf to entice users to book you. A good starting point is to read our blog 3 easy
                steps to improve your profile:
                <a href="https://blog.conrati.com/3-easy-steps-to-strengthen-your-conrati-profile/">
                  3 easy steps to improve your profile
                </a>
              </span>
            </>
          }
          expand
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are the benefits registering with a linkedin username and password?"
          content="It makes for a swifter process of registration, by pre-populating your basic information like name, surname, and country from LinkedIn into your Conrati profile."
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Are reviews or feedback integrated on the services of advice providers?"
          content="Reviews and feedback are currently not a part of the application but may become part of the application sometime soon"
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How do I improve my profile to maximise bookings within my area of expertise?"
          content={`
                    • Ensure that your profile information is correct and up to date
                    
                    • Include all of your skills and education
                    
                    • Ensure that you complete the ‘Topics’ session with consultation topics you specialise in so that Advice Receivers can quickly see whether you would be able to assist them
                    
                    • Be honest about what you can and cannot do
                    
                    • Maintain your hourly availability so that you can indeed be booked                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Are profiles publicly available?"
          content={`
                    • Profiles of Advice Providers are made available for public viewing on the internet as they wish to be booked by Advice Receivers for online consultations. 
                    
                    • If you do not want your profile, including hourly rate and hourly availability to be available for public viewing you should not make use of this application.
                                        
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How do I share my profile with someone that is not on the Conrati Platform?"
          content={`
                    You can share a link to your profile and or availability calendar by copying the link and pasting it into an email or social media platform for public viewing. 
                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default Profile;
