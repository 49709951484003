import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styles from './ViewProfile.module.css';
import Headers from '../Headers/Headers';
import { fetchSingleProfile } from '../../actions/profileAction';

import ProfileDetails from './ProfileDetails/ProfileDetails';
import ProfileHead from './ProfileHead/ProfileHead';
import PageLoader from '../Common/PageLoader/PageLoader';
import Notfound from '../Notfound/Notfound';
import enums from '../../config/enum';

class ViewProfile extends Component {
  componentDidMount() {
    this.getUserId();
  }

  getUserId() {
    if (this.props.match) {
      const { currency } = this.props;
      const { username } = this.props.match.params;

      const reqData = {};

      if (currency.currencyCode) {
        reqData.currency = currency;
      }

      this.props.onFetchProfile(username, reqData);
    }
  }

  onBookNow(e, userid) {
    e.preventDefault();
    if (this.props.user.isLoggedIn && this.props.user.user.userType === enums.userType.ap) {
      this.props.history.push('/myaccount/switch');
    } else {
      this.props.history.push(`/user/${userid}/calendar`);
    }
  }

  render() {
    const { loading, error, success } = this.props.request;

    return (
      <div>
        <div className={styles.viewprofile}>
          <Headers />

          {loading && <PageLoader />}

          {!loading &&
            (success && !error ? (
              <div>
                <ProfileHead {...this.props.profile} userData={this.props.userData} />
                <ProfileDetails
                  {...this.props.profile}
                  onClick={(e, userid) => this.onBookNow(e, userid)}
                />
              </div>
            ) : (
              <Notfound />
            ))}
        </div>
      </div>
    );
  }
}

ViewProfile.propTypes = {
  match: PropTypes.object.isRequired,
  onFetchProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : {};

  return {
    profile: state.profiles.profile,
    request: state.request,
    user: state.userState,
    userData: state.userState.user,
    currency,
    isLoggedIn: state.userState.isLoggedIn,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchProfile: fetchSingleProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(ViewProfile);
