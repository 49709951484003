/* eslint-disable import/prefer-default-export */
import axios from 'axios';

/**
 * @todo It is very ugly to hardcode Prod, but I guess it's the only way if we don't have the
 *    recommended therapists in json files on Dev and Test Environments
 */
export const fetchTherapistDataFromProd = (username) => {
  return axios
    .post(`https://api.conrati.com/api/v1.1/profile/${username}`)
    .then((response) => response.data)
    .catch((err) => {
      console.error(err);
    });
};
