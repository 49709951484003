import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './HowItWorks.module.css';

import hiwleft1 from '../../Common/assets/hiwleft1.svg';
import hiwleft2 from '../../Common/assets/hiwleft2.png';
import hiwleft3 from '../../Common/assets/hiwleft3.svg';
import hiwleft4 from '../../Common/assets/hiwleft4.png';
import hiwleft5 from '../../Common/assets/hiwleft5.svg';
import hiwleft6 from '../../Common/assets/hiwleft6.png';
import hiwright1 from '../../Common/assets/hiwright1.png';
import hiwright2 from '../../Common/assets/hiwright2.svg';
import hiwright3 from '../../Common/assets/hiwright3.png';
import hiwright4 from '../../Common/assets/hiwright4.svg';
import hiwright5 from '../../Common/assets/hiwright5.png';
import hiwright6 from '../../Common/assets/hiwright6.svg';
import onboard from '../../Common/assets/onboard.svg';
import hiwline from '../../Common/assets/hiwline.svg';

import ahiwleft1 from '../../Common/assets/ahiwleft1.svg';
import ahiwleft2 from '../../Common/assets/ahiwleft2.png';
import ahiwleft3 from '../../Common/assets/ahiwleft3.svg';
import ahiwleft4 from '../../Common/assets/ahiwleft4.png';
import ahiwleft5 from '../../Common/assets/ahiwleft5.svg';
import ahiwleft6 from '../../Common/assets/ahiwleft6.png';
import ahiwright1 from '../../Common/assets/ahiwright1.png';
import ahiwright2 from '../../Common/assets/ahiwright2.svg';
import ahiwright3 from '../../Common/assets/ahiwright3.png';
import ahiwright4 from '../../Common/assets/ahiwright4.svg';
import ahiwright5 from '../../Common/assets/ahiwright5.png';
import ahiwright6 from '../../Common/assets/ahiwright6.svg';
import arrow_gray_white from '../../Common/assets/arrow_gray_white.svg';

class HowItWorks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 1,
    };
  }

  render() {
    return (
      <div className={styles.brpage}>
        <div className={styles.brheader}>
          {this.state.type === 1 ? (
            <h1>How to receive advice?</h1>
          ) : (
            <h1>How to provide advice? </h1>
          )}
        </div>
        <div className={styles.switchbtn}>
          <button
            className={this.state.type === 1 ? cx(styles.sbtnl, styles.active) : styles.sbtnl}
            onClick={(e) => {
              this.setState({ type: 1 });
            }}
          >
            RECEIVE ADVICE
          </button>
          <button
            className={this.state.type === 2 ? cx(styles.sbtnr, styles.active) : styles.sbtnr}
            onClick={(e) => {
              this.setState({ type: 2 });
            }}
          >
            PROVIDE ADVICE
          </button>
        </div>

        {this.state.type === 1 && (
          <div className={styles.brcontainer}>
            <Link to="/help/faq">
              <div className={styles.faq}>
                <p>Frequently asked questions</p>
                <img src={arrow_gray_white} alt="More" />
              </div>
            </Link>
            <div className={styles.ar}>
              <div className={styles.left}>
                <div className={styles.hiwimg}>
                  <img src={hiwleft1} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwleft2} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={hiwleft3} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwleft4} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={hiwleft5} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwleft6} alt="Hiw" />
                </div>
              </div>
              <div className={styles.center}>
                <img src={hiwline} alt="Hiwline" />
              </div>
              <div className={styles.right}>
                <div className={styles.hiwimg}>
                  <img src={hiwright1} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwright2} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={hiwright3} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwright4} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={hiwright5} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={hiwright6} alt="Hiw" />
                </div>
              </div>
            </div>

            {!this.props.isLoggedIn ? (
              <div className={styles.bottomreg}>
                <img src={onboard} alt="Onboard" />
                <a href="/register" className={styles.btn}>
                  Register to book a consultation
                </a>
              </div>
            ) : (
              <div className={styles.bottomreg}>
                <img src={onboard} alt="Onboard" />
              </div>
            )}
          </div>
        )}

        {this.state.type === 2 && (
          <div className={styles.brcontainer}>
            <Link to="/help/faq">
              <div className={styles.faq}>
                <p>Frequently Asked questions</p>
                <img src={arrow_gray_white} alt="More" />
              </div>
            </Link>
            <div className={styles.ar}>
              <div className={styles.left}>
                <div className={styles.hiwimg}>
                  <img src={ahiwleft1} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwleft2} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={ahiwleft3} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwleft4} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={ahiwleft5} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwleft6} alt="Hiw" />
                </div>
              </div>
              <div className={styles.center}>
                <img src={hiwline} alt="Hiwline" />
              </div>
              <div className={styles.right}>
                <div className={styles.hiwimg}>
                  <img src={ahiwright1} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwright2} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={ahiwright3} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwright4} alt="Hiw" />
                </div>

                <div className={styles.hiwimg}>
                  <img src={ahiwright5} alt="Hiw" />
                </div>
                <div className={styles.hiwcard}>
                  <img src={ahiwright6} alt="Hiw" />
                </div>
              </div>
            </div>
            {!this.props.isLoggedIn ? (
              <div className={styles.bottomreg}>
                <img src={onboard} alt="Onboard" />
                <a href="/register" className={styles.btn}>
                  Register to start consulting
                </a>
              </div>
            ) : (
              <div className={styles.bottomreg}>
                <img src={onboard} alt="Onboard" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.userState.isLoggedIn,
  };
};

export default connect(mapStateToProps)(HowItWorks);
