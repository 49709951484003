import React, { Component } from 'react';
import styles from './SessionGrace.module.css';
import SessionHeaderNs from '../../SessionHeader/SessionHeaderNs';
import Grace from '../../VideoSessionCases/Grace';
import enums from '../../../../config/enum';
import usernull from '../../../Common/assets/usernull.svg';
import Waiting from '../../VideoSessionCases/Waiting';

class SessionGrace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ap_ar_no_show: false,
    };
  }

  onNoShow = (type) => {
    if (type) {
      this.setState({ ap_ar_no_show: type });
      let data;

      if (this.props.user.userType === enums.userType.ar) {
        data = {
          userToken: this.props.user.userToken,
          booking_id: this.props.activeBooking.bookingId,
          status: enums.bookingStatus.AP_NOSHOW,
        };
      }

      if (this.props.user.userType === enums.userType.ap) {
        data = {
          userToken: this.props.user.userToken,
          booking_id: this.props.activeBooking.bookingId,
          status: enums.bookingStatus.AR_NOSHOW,
        };
      }

      this.props.onDisconnectSession(data);
      this.props.history.replace('/session/call/noshow');
    }
  };

  render() {
    return (
      <div className={styles.videosession}>
        <SessionHeaderNs onEndCall={this.onEndCall} />
        <div className={styles.card}>
          <img
            className={styles.profile}
            src={this.props.user.primaryImg ? this.props.user.primaryImg : usernull}
            alt="user"
            height="70px"
            width="70px"
          />
          <p className={styles.wel}>Welcome</p>
          {this.props.activeSession.isSessionActive === enums.tokboxSessionStatus.CREATED && (
            <Grace {...this.props} onNoShow={this.onNoShow} />
          )}
          {this.props.activeSession.isSessionActive === enums.tokboxSessionStatus.STARTED && (
            <Waiting {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

export default SessionGrace;
