import React from 'react';
import styles from './WorldOfTherapists.module.scss';
import Arrow from '../arrow';
import { therapyBenefits, therapistProfileUrl } from '../../common/constants/data';
import WorldOfTherapistItem from './WorldOfTherapistItem';

export default function WorldOfTherapists(): JSX.Element {
	return (
		<div className={styles.worldTherapists}>
			<h1 data-testid="quiz-world-therapist-title">
				A Whole World of Therapists
			</h1>

			<div className={styles.processes}>
				{therapyBenefits.map(benefit => (
					<WorldOfTherapistItem
						key={`benefit-item-${benefit.id}`}
						img={benefit.img}
						title={benefit.title}
						subTitle={benefit.subTitle}
					/>
				))}
			</div>

			<div className={styles.findTherapist}>
				<a
					data-testid="quiz-world-therapist-link"
					href={`/search${therapistProfileUrl}`}
				>
					FIND A THERAPIST <Arrow hasBackground />
				</a>
			</div>
		</div>
	);
}
