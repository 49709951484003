/* eslint-disable */
import React, { Component } from 'react';
import styles from '../Myaccount.module.css';
import EditInput from '../../Common/EditInput/EditInput';
import EditSelect from '../../Common/EditSelect/EditSelect';
import EditRadio from '../../Common/EditRadio/EditRadio';
import enums from '../../../config/enum';

function BusinessForm(props) {
  const { parentState, user, onChange, onCheck, onCheckSmallExempt, onSelActive, onSelectChange } =
    props;

  const {
    typeOfBusiness,
    businessName,
    businessRegistrationNo,
    vatNo,
    address,
    city,
    postalCode,
    isSmallEnterprise,
    vatRegistered,
    isSmallExemptDisabled,
  } = parentState;

  const userCountry = user.countryId;

  const countries = props.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
  }));

  const selectedCountry = userCountry
    ? countries.filter((arr) => {
        return arr.value === userCountry;
      })
    : null;

  return (
    <>
      <div className={styles.acedit}>
        <EditRadio
          label="Is your business registered for VAT?"
          onCheck={onCheck}
          checked={vatRegistered || false}
          right
        />
        {vatRegistered ? (
          <EditInput
            htmlFor="VAT number"
            label="VAT number"
            value={vatNo}
            placeholder="VAT number"
            onChange={onChange}
            required
            name="vatNo"
          />
        ) : null}

        <div className={styles.cchecka}>
          <EditRadio
            label="Are you an SME exempt from VAT?"
            onCheck={onCheckSmallExempt}
            checked={isSmallEnterprise}
            right
            tooltip
            disabled={isSmallExemptDisabled}
          />
        </div>
        <EditInput
          htmlFor="Business Name"
          label="Business Name"
          value={businessName}
          placeholder="Business Name"
          onChange={onChange}
          name="businessName"
          required
        />

        {typeOfBusiness === enums.typeOfBusinessText.Business ? (
          <EditInput
            htmlFor="Business Registration Number"
            label="Business Registration Number"
            value={businessRegistrationNo}
            placeholder="Business Registration Number"
            required
            onChange={onChange}
            name="businessRegistrationNo"
          />
        ) : (
          <EditInput
            htmlFor="Business Registration Number"
            label="Business Registration Number"
            value={businessRegistrationNo}
            placeholder="Business Registration Number"
            onChange={onChange}
            name="businessRegistrationNo"
          />
        )}

        <EditInput
          htmlFor="Address"
          label="Address"
          value={address}
          placeholder="Address"
          onChange={onChange}
          name="address"
          required
        />
        <EditInput
          htmlFor="City"
          label="City"
          value={city}
          placeholder="City"
          onChange={onChange}
          name="city"
          required
        />
        <EditInput
          htmlFor="Postal Code"
          label="Postal Code"
          value={postalCode}
          placeholder="Postal Code"
          onChange={onChange}
          name="postalCode"
          required
        />
        <EditSelect
          label="Country"
          options={countries}
          selected={selectedCountry}
          placeholder="Select Country"
          onChange={(e) => onSelectChange(e, 'country')}
          onActive={onSelActive}
          isDisabled={!!selectedCountry}
        />
      </div>
    </>
  );
}

export default BusinessForm;
