import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import styles from './SessionHeader.module.css';
import logo from '../../Common/assets/logo-yellow.png';
// import linkico from '../../Common/assets/sharelink.svg';
import sharescreen from '../../Common/assets/sharescreen.svg';
import fullscreen from '../../Common/assets/fullscreen.svg';
import endcall from '../../Common/assets/endcall.svg';
import chat_light from '../../Common/assets/chat_light.svg';
// import share_light from '../../Common/assets/share_light.svg';
import info_light from '../../Common/assets/info_light.svg';
import chat_dark from '../../Common/assets/chat_dark.svg';
// import share_dark from '../../Common/assets/share_dark.svg';
import info_dark from '../../Common/assets/info_dark.svg';

class SessionHeader extends Component {
  render() {
    const activecbtn = cx(styles.cbtn, styles.cbtnactive);
    return (
      <header className={styles.sheaders}>
        <div className={styles.container}>
          <nav className={styles.links}>
            <div className={styles.left}>
              <div className={styles.logo}>
                <Link to="/">
                  <img src={logo} alt="conrati-logo" width="110px" />
                </Link>
              </div>
              <div className={styles.callbtns}>
                {/* <button>
                                        <img src={linkico} alt="sharelink" width="30px" height="30px"/>
                                    </button> */}
                <button
                  onClick={this.props.onScreenShare}
                  data-tip="Share Screen"
                  data-for="navbar"
                >
                  <img src={sharescreen} alt="sharescreen" width="30px" height="30px" />
                </button>
                <button onClick={this.props.openFullscreen} data-tip="Fullscreen" data-for="navbar">
                  <img src={fullscreen} alt="fullscreen" width="30px" height="30px" />
                </button>
                <button onClick={this.props.onEndCall} data-tip="End Call" data-for="navbar">
                  <img src={endcall} alt="endcall" width="30px" height="30px" />
                </button>
                <ReactTooltip id="navbar" place="left" type="warning" effect="solid" />
              </div>
            </div>
            <div className={styles.chatbtns}>
              <button
                className={this.props.activeTab === 'chat' ? activecbtn : styles.cbtn}
                onClick={(e) => this.props.tabClick(e, 'chat')}
              >
                <img
                  src={this.props.activeTab === 'chat' ? chat_dark : chat_light}
                  alt="sharelink"
                  width="27px"
                  height="27px"
                />
                <p>Chat</p>
              </button>
              {/* <button className={this.props.activeTab === 'share' ?activecbtn :styles.cbtn} onClick={e => this.props.tabClick(e,'share')}>
                                    <img src={this.props.activeTab === 'share' ? share_dark : share_light} alt="sharescreen" width="27px" height="27px"/>
                                    <p>Files</p>
                                </button> */}
              <button
                className={this.props.activeTab === 'info' ? activecbtn : styles.cbtn}
                onClick={(e) => this.props.tabClick(e, 'info')}
              >
                <img
                  src={this.props.activeTab === 'info' ? info_dark : info_light}
                  alt="fullscreen"
                  width="27px"
                  height="27px"
                />
                <p>Info</p>
              </button>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default SessionHeader;
