import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import styles from './Payment.module.css';
import Headers from '../Headers/Headers';
import headbg from '../Common/assets/small.svg';
import KycDetails from '../Myaccount/KycDetails/KycDetails';
import {
  fetchUserBankAccount,
  fetchAllUboDocs,
  addUserBankAccount,
  addUserBankAccountOther,
  addUserBankAccountGb,
  fetchKycDocuments,
  fetchWalletBalance,
  fetchKycHelpText,
  fetchAllCountries,
  syncBankAccount,
} from '../../actions/tempAction';
import { userBuisnessDetails, useronKycConfirm } from '../../actions/userAction';
import BankDetails from '../Myaccount/BankDetails/BankDetails';
import walletwarning from '../Common/assets/wallet_warning.svg';
import wallet from '../Common/assets/wallet.svg';
import strength from '../Common/assets/strength.svg';
import LargeWarning from '../Common/LargeWarning/LargeWarning';
import globalPropTypes from '../../config/proptype';
import { alert } from '../../utils';
import messages from '../../config/message';
import PayOutModal from './PayOutModal';
import { onTriggerPayout } from '../../services/userService';
import enums from '../../config/enum';

const modayStyle = {
  content: {
    top: '43%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '650px',
    maxHeight: '600px',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 4px 47px 0 rgba(85,85,85,0.2)',
    border: 'none',
    padding: '0px',
  },
};

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      user,
      onFetchBankAccount,
      onFetchKycDocuments,
      onFetchBalance,
      onFetchUserBuisnessDetails,
      onFetchKycHelpText,
      history,
    } = this.props;

    if (user.userType === enums.userType.ar) {
      alert(messages.apIncompleteAccunt);
      return history.replace('/myprofile');
    }

    if (user.isBusinessRegistered) {
      if (user.isBankDetailsRegistered) {
        onFetchBankAccount(user);
      }

      onFetchKycDocuments(user);
      onFetchBalance(user);
      onFetchUserBuisnessDetails(user.userToken);
      onFetchKycHelpText(user);
    }
  }

  onTriggerPayout = () => {
    const { user, balance } = this.props;
    const { isKycVerified } = user;
    if (!isKycVerified) {
      return alert(messages.kycNotVerified);
    }

    if (balance.amount <= 0) {
      return alert(messages.payoutAmountInvalid);
    }

    this.setState({ isModalOpen: true });
    return false;
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  onPayment = () => {
    this.setState({ isModalOpen: false });

    let toastId = null;

    toastId = toast.warn('Processing payout trigger...', {
      position: 'top-center',
      hideProgressBar: true,
      autoClose: false,
    });

    onTriggerPayout()
      .then((response) => {
        const { user, onFetchBalance } = this.props;

        onFetchBalance(user);

        return toast.update(toastId, {
          render: response.message,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        return toast.update(toastId, {
          render: err.message,
          autoClose: 3000,
        });
      });
  };

  render() {
    const { isModalOpen } = this.state;
    const { user, balance } = this.props;
    return (
      <div className={styles.payment}>
        <Headers />
        <div style={{ backgroundImage: `url(${headbg})` }} className={styles.head}>
          <div className={styles.name}>
            <p className={styles.title}>{`${user.firstName} ${user.lastName}`}</p>
            <p className={styles.sub}>{user.headline}</p>
          </div>
        </div>
        <div className={styles.dtit}>
          <p className={styles.db}>Payout</p>
        </div>
        <div className={styles.pcontainer}>
          {user.isBusinessRegistered ? (
            <LargeWarning
              icon={walletwarning}
              title="Details and documents required for payout!"
              body="Please ensure that you have entered your business’s relevant bank account details as well as uploaded the applicable Know Your Customer (KYC) documents. MANGOPAY’s KYC obligation requirements come from regulators and are intended to prevent abuse of the financial system."
            />
          ) : (
            <LargeWarning
              icon={strength}
              title="Incomplete business details!"
              body="Please complete your business details in my account section to access this page."
            />
          )}
          {user.isBusinessRegistered && (
            <>
              <div className={styles.vsdes}>
                <div className={styles.vrrow}>
                  <div className={styles.vrcolumn12}>
                    <div className={styles.ctitle}>
                      <div className={styles.wsection}>
                        <img src={wallet} alt="" />
                        <p>Wallet Balance</p>
                      </div>
                      <div className={styles.wsection}>
                        <ReactTooltip
                          id="smetip"
                          place="top"
                          type="warning"
                          effect="solid"
                          className={styles.toolttip}
                        />
                        <button
                          data-for="smetip"
                          data-tip="Transfer amount to your bank account."
                          type="button"
                          className={styles.tbtn}
                          onClick={this.onTriggerPayout}
                        >
                          TRIGGER PAYOUT
                        </button>
                      </div>
                    </div>
                    <div className={styles.cbody}>
                      <div className={styles.wbalance}>
                        <p className={styles.cbalance}>Current Balance</p>

                        {balance && balance.amount >= 0 ? (
                          <p className={styles.cbalance}>{balance.amountWithCurrency}</p>
                        ) : (
                          <div
                            style={{
                              width: '70px',
                              height: '19px',
                              textAlign: 'right',
                            }}
                          >
                            <ContentLoader height={19} width={40} speed={2} />
                          </div>
                        )}
                      </div>
                      <div className={styles.winfo}>
                        <p>
                          Your money remains safely stored with MANGOPAY until you have been
                          successfully KYC verified and have uploaded your bank details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.pimpdoc}>
                <div className={styles.leftrow}>
                  <div className={styles.details}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <BankDetails {...this.props} />
                  </div>
                </div>
                <div className={styles.rightrow}>
                  <div className={styles.details}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <KycDetails {...this.props} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal isOpen={isModalOpen} style={modayStyle} contentLabel="KYC CONFIRM">
          <PayOutModal onClose={this.onCloseModal} onPayment={this.onPayment} />
        </Modal>
      </div>
    );
  }
}

Payment.propTypes = {
  user: globalPropTypes.user.isRequired,
  onFetchBankAccount: PropTypes.func.isRequired,
  onFetchKycDocuments: PropTypes.func.isRequired,
  onFetchBalance: PropTypes.func.isRequired,
  onFetchUserBuisnessDetails: PropTypes.func.isRequired,
  onFetchKycHelpText: PropTypes.func.isRequired,
  balance: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
    amountWithCurrency: PropTypes.string,
  }),
  history: globalPropTypes.history.isRequired,
};

Payment.defaultProps = {
  balance: {
    currency: '',
    amount: 0,
    amountWithCurrency: '',
  },
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles.profile,
    request: state.request,
    user: state.userState.user,
    bank_account: state.temp.bankAccounts,
    kycDocuments: state.temp.kycDocuments,
    buisnessDetails: state.userState.buisnessDetails,
    countries: state.masterdata.masterData.countries,
    balance: state.temp.balance,
    kycConfirm: state.userState.kycConfirm,
    kychelptext: state.temp.kychelptext,
    allcountries: state.temp.allcountries,
    allubodocs: state.temp.allubodocs,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchBankAccount: fetchUserBankAccount,
      onAddBankAccount: addUserBankAccount,
      onAddBankAccountOther: addUserBankAccountOther,
      onAddBankAccountGb: addUserBankAccountGb,
      onFetchKycDocuments: fetchKycDocuments,
      onFetchBalance: fetchWalletBalance,
      onFetchUserBuisnessDetails: userBuisnessDetails,
      onKycPopUpConfirm: useronKycConfirm,
      onFetchKycHelpText: fetchKycHelpText,
      onFetchAllCountries: fetchAllCountries,
      onFetchAllUboDocs: fetchAllUboDocs,
      onSyncBankAccount: syncBankAccount,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Payment);
