import React from 'react';
import styles from './styles.module.scss';
import masterCardIcon from '../../../assets/mastercard.svg';
import { Checkbox } from '../../checkbox';
import { PaymentCardInterface } from '../payment-cards';

interface PaymentCardProps {
	selectedCardId?: string;
	card: PaymentCardInterface;
	onMarkActiveCard(card: PaymentCardInterface): void;
}

export const PaymentCard = (props: PaymentCardProps): JSX.Element => {
	const { card, onMarkActiveCard, selectedCardId } = props;

	return (
		<button
			type="button"
			className={`${styles.paymentCardContainer} ${
				card.active ? styles.selectedPaymentCard : ''
			}`}
			onClick={(): void => onMarkActiveCard(card)}
			data-testid="ui-native-payment-card-container"
		>
			<div
				className={styles.paymentCardIcon}
				data-testid="ui-native-payment-card-icon-container"
			>
				<img
					src={masterCardIcon}
					alt="Payment Card Icon"
					data-testid="ui-native-payment-card-icon"
				/>
			</div>
			<div
				className={styles.paymentCardDetails}
				data-testid="ui-native-payment-card-details"
			>
				<div
					className={styles.paymentCardName}
					data-testid="ui-native-payment-card-name"
				>
					{card.nameOnCard}
				</div>
				<div
					className={styles.paymentCardNumber}
					data-testid="ui-native-payment-card-number"
				>
					•••••••{card.alias.slice(-4)}
				</div>
			</div>
			<div
				className={styles.paymentCardCheckMark}
				data-testid="ui-native-payment-card-check-mark"
			>
				<Checkbox
					checked={
						selectedCardId ? selectedCardId === card.id : false
					}
					onChange={(): void => {
						// Required function since this is treated as a controlled component
					}}
					round
					id={card.id}
				/>
			</div>
		</button>
	);
};
