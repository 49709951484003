import React from 'react';
import styles from './HappinessResources.module.scss';
import Arrow from '../arrow';
import { blogArticles } from '../../common/constants/data';
import HappinessResourceItem from './HappinessResourceItem';

export default function HappinessResources(): JSX.Element {
	return (
		<div className={styles.happinessResources}>
			<h1 data-testid="quiz-happiness-resources-title">
				More Happiness Resources
			</h1>

			<div className={styles.blogArticles}>
				{blogArticles.map(article => (
					<HappinessResourceItem
						key={article.id}
						content={article.content}
						title={article.title}
						img={article.img}
						link={article.link}
					/>
				))}
			</div>

			<div className={styles.moreArticles}>
				<a
					data-testid="quiz-happiness-resources-link"
					target="_blank"
					href="https://blog.conrati.com"
					rel="noreferrer"
				>
					MORE ARTICLES <Arrow hasBackground />
				</a>
			</div>
		</div>
	);
}
