import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.css';
import icoMentoring from './images/conrati-icon-mentorship1.svg';
import icoPsy from './images/conrati-icon_mentorship2.svg';
import icoBuc from './images/conrati-icon-technology1.svg';
import icoGh from './images/conrati-icon_Strategy2.svg';
import icoLaw from './images/conrati-icon_Legal1.svg';

function Category() {
  return (
    <div className={styles.category}>
      <Link to="/categories/mentors" className={styles.catGroup}>
        <div className={styles.imgGroup}>
          <img src={icoMentoring} alt="category" />
        </div>
        <p>Mentoring / Coaching</p>
      </Link>
      <Link to="/categories/psychologist" className={styles.catGroup}>
        <div className={styles.imgGroup}>
          <img src={icoPsy} alt="category" />
        </div>
        <p>Psychology</p>
      </Link>
      <Link to="/categories/businessconsultant" className={styles.catGroup}>
        <div className={styles.imgGroup}>
          <img src={icoBuc} alt="category" />
        </div>
        <p>Business consulting</p>
      </Link>
      <Link to="/categories/growthhacker" className={styles.catGroup}>
        <div className={styles.imgGroup}>
          <img src={icoGh} alt="category" />
        </div>
        <p>Growth hacking</p>
      </Link>
      <Link to="/search?skills=legal" className={styles.catGroup}>
        <div className={styles.imgGroup}>
          <img src={icoLaw} alt="category" />
        </div>
        <p>Lawyer</p>
      </Link>
    </div>
  );
}

export default Category;
