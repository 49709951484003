import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styles from './EditMyProfile.module.css';
import ProfileHead from '../../ViewProfile/ProfileHead/ProfileHead';
import Headers from '../../Headers/Headers';
import ServiceDescription from './ServiceDescription/ServiceDescription';
import Topics from './Topics/Topics';
import VideoBio from './VideoBio/VideoBio';
import Certification from './Certifications/Certification';
import Education from './Education/Education';
import Skills from './Skills/Skills';
import Experience from './Experience/Experience';
import YourDetails from './YourDetails/YourDetails';
import { updateProfile, editProfile, uploadUserProfileImage } from '../../../actions/userAction';
import ImageUploadModal from '../../Common/ImageUploadModal/ImageUploadModal';
import Oauthpop from '../../Common/Oauthpop/Oauthpop';
import { weburl, apiurl, alert } from '../../../utils';
import Messages from '../../../config/message';
import LargeWarning from '../../Common/LargeWarning/LargeWarning';
import notificationsuccess from '../../Common/assets/notificationsuccess.svg';
import globalProptype from '../../../config/proptype';
import enums from '../../../config/enum';

class EditMyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalopen: false,
      required: false,
      requiredin: '',
      oauthScreen: false,
      userlink: '',
      warning: false,
    };
  }

  componentDidMount() {
    const { onUpdateProfile, location, history, user, stepsCompleted } = this.props;

    onUpdateProfile();

    if (!stepsCompleted && user.userType === enums.userType.ap) {
      alert(Messages.apIncompleteAccunt);
      return history.replace('/myprofile/edit/step');
    }

    const searchparam = new URLSearchParams(location.search);
    if (searchparam.get('share') && history.action === 'REPLACE') {
      this.onShareLinkedin();
    }

    return this.showWarning(user);
  }

  componentDidUpdate(prevProps) {
    const prevpropsUser = prevProps.user;
    const { user } = this.props;

    if (!isEqual(user, prevpropsUser)) {
      this.showWarning(user);
    }
  }

  onClickImg = () => {
    this.setState({ modalopen: true });
  };

  onClose = () => {
    this.setState({ modalopen: false });
  };

  onRequired = (required, reqin) => {
    this.setState({ required, requiredin: reqin });
  };

  onShareStatus = () => {
    this.setState({ oauthScreen: false });
  };

  onCloseOauthScreen = () => {
    this.setState({ oauthScreen: false });
  };

  onShareLinkedin = () => {
    // e.preventDefault();

    const { user } = this.props;
    const { registrationStepsCompleted, username } = user;

    if (registrationStepsCompleted === 5 || registrationStepsCompleted === 4) {
      const link = `${weburl}/${username}`;
      this.setState({ oauthScreen: true, userlink: link });
    } else {
      alert(Messages.sharingPostWarning);
    }
  };

  showWarning = (user) => {
    const { warning } = this.state;
    if (
      user.certification.length === 0 ||
      user.categories.length === 0 ||
      user.education.length === 0 ||
      user.topics.length === 0 ||
      user.experience.length === 0
    ) {
      if (!warning) {
        this.setState({ warning: true });
      }
    } else {
      this.setState({ warning: false });
    }
  };

  copyProfileLink = () => {
    const { user } = this.props;
    const { registrationStepsCompleted, username } = user;
    if (registrationStepsCompleted === 5 || registrationStepsCompleted === 4) {
      const link = `${weburl}/${username}`;
      const inputcopy = document.createElement('input');
      document.body.appendChild(inputcopy);
      inputcopy.setAttribute('value', link);
      inputcopy.select();
      document.execCommand('copy');
      document.body.removeChild(inputcopy);

      return alert(Messages.copyProfileLink);
    }
    return alert(Messages.copyProfileLinkCompleteWarning);
  };

  render() {
    const { modalopen, warning, required, requiredin, oauthScreen, userlink } = this.state;

    const {
      user,
      onProfileImageUpload,
      onUpdateProfile,
      onEditProfile,
      languagesMasterData,
      categoriesMasterData,
      skillsMasterData,
    } = this.props;
    return (
      <div>
        {modalopen ? (
          <ImageUploadModal
            user={user}
            onProfileImageUpload={onProfileImageUpload}
            onClose={this.onClose}
          />
        ) : null}
        <Headers />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ProfileHead {...user} onUploadImage={this.onClickImg} />
        <div className={styles.clickbtn}>
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
            className={styles.clickbtnc}
            onClick={this.copyProfileLink}
          >
            <button type="button" className={styles.btns}>
              COPY PROFILE LINK
            </button>
          </div>
          <div
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
            className={styles.clickbtnc}
            onClick={this.onShareLinkedin}
          >
            <button type="button" className={styles.btnsl}>
              <svg
                className={styles.linkedin}
                fill="#000000"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19,19H16V13.7A1.5,1.5 0 0,0 14.5,12.2A1.5,1.5 0 0,0 13,13.7V19H10V10H13V11.2C13.5,10.36 14.59,9.8 15.5,9.8A3.5,3.5 0 0,1 19,13.3M6.5,8.31C5.5,8.31 4.69,7.5 4.69,6.5A1.81,1.81 0 0,1 6.5,4.69C7.5,4.69 8.31,5.5 8.31,6.5A1.81,1.81 0 0,1 6.5,8.31M8,19H5V10H8M20,2H4C2.89,2 2,2.89 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z" />
              </svg>
              SHARE ON LINKEDIN
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.editaccount}>
            {warning && (
              <LargeWarning
                icon={notificationsuccess}
                title="Strengthen your public profile!"
                body="Increase your credibility by adding more information to your public profile below."
              />
            )}
            <div className={styles.editaccounta}>
              <div className={styles.leftrow}>
                <div className={styles.details}>
                  <YourDetails
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    categoriesMasterData={categoriesMasterData}
                    languagesMasterData={languagesMasterData}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                    user={{
                      skills: user.skills,
                      social: user.social,
                      hourlyRate: user.hourlyRate,
                      categories: user.categories,
                      languages: user.languages,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      headline: user.headline,
                      currency: user.currency,
                    }}
                  />
                  <Skills
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    skillsMasterData={skillsMasterData}
                    user={{
                      skills: user.skills,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                  <Topics
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    skillsMasterData={skillsMasterData}
                    user={{
                      topics: user.topics,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                  <Experience
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    user={{
                      experience: user.experience,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                </div>
              </div>

              <div className={styles.rightrow}>
                <div className={styles.details}>
                  <ServiceDescription
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    user={{
                      serviceDescription: user.serviceDescription,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                  <VideoBio
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    user={{
                      videoBio: user.videoBio,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                  <Certification
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    user={{
                      certification: user.certification,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                  <Education
                    onRequired={this.onRequired}
                    required={required}
                    requiredIn={requiredin}
                    user={{
                      education: user.education,
                      social: user.social,
                    }}
                    onUpdateProfile={onUpdateProfile}
                    onEditProfile={onEditProfile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {oauthScreen && (
          <Oauthpop
            onClose={this.onCloseOauthScreen}
            shareStatus={this.onShareStatus}
            url={`${apiurl}/v1/oauth/share/linkedin`}
            link={userlink}
          />
        )}
      </div>
    );
  }
}

EditMyProfile.propTypes = {
  onUpdateProfile: PropTypes.func.isRequired,
  history: globalProptype.history.isRequired,
  location: globalProptype.location.isRequired,
  user: PropTypes.shape({
    registrationStepsCompleted: PropTypes.number,
    username: PropTypes.string,
    certification: PropTypes.arrayOf(PropTypes.object),
    social: PropTypes.shape({}),
    skills: PropTypes.arrayOf(PropTypes.string),
    serviceDescription: PropTypes.string,
    hourlyRate: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    headline: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
      currencyName: PropTypes.string,
      currencySymbol: PropTypes.string,
    }),
    topics: PropTypes.arrayOf(PropTypes.object),
    experience: PropTypes.arrayOf(PropTypes.object),
    videoBio: PropTypes.string,
    education: PropTypes.arrayOf(PropTypes.object),
    userType: PropTypes.number,
  }).isRequired,
  onProfileImageUpload: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  skillsMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoriesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  languagesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsCompleted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    categoriesMasterData: state.masterdata.masterData.categories || [],
    currencies: state.masterdata.masterData.currencies,
    skillsMasterData: state.masterdata.masterData.skills,
    languagesMasterData: state.masterdata.masterData.languages,
    accountCompleted: state.userState.accountCompleted,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
      onEditProfile: editProfile,
      onProfileImageUpload: uploadUserProfileImage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(EditMyProfile);
