import React from 'react';
import styles from './AccountSettings.module.css';
import delete_ico from '../../Common/assets/delete_ico.svg';

const DeleteAccount = (props) => {
  return (
    <div>
      <img src={delete_ico} alt="Delete Account" className={styles.delaccount} />
      <p className={styles.ptext}>
        We are sorry to see you go. <br />
        Are you sure you want to delete your account? <br /> Once you delete your account, all your
        data will be deleted within 90 days from the date of request except for data which Conrati
        is legally obliged to store for specific authorities.
      </p>
      <div className={styles.buttons}>
        <button className={styles.btnc} onClick={props.closeModal}>
          CANCEL
        </button>
        <button className={styles.btn} onClick={props.submitModal}>
          {props.deleting ? 'DELETING' : 'DELETE'}
        </button>
      </div>
    </div>
  );
};

export default DeleteAccount;
