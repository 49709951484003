import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ShareProfile.module.css';
import linkedcolor from '../../../Common/assets/linked-color.svg';
import linkedmenu from '../../../Common/assets/linkedin_menu.png';
import linkedpobg from '../../../Common/assets/linkedin_pobg.svg';
import { weburl, alert } from '../../../../utils';
import { shareLinkedinEmail } from '../../../../services/userService';
import globalProptype from '../../../../config/proptype';

class ShareProfile extends Component {
  copyProfileLink = (username) => {
    const link = `${weburl}/${username}`;
    const inputcopy = document.createElement('input');
    document.body.appendChild(inputcopy);
    inputcopy.setAttribute('value', link);
    inputcopy.select();
    document.execCommand('copy');
    document.body.removeChild(inputcopy);
    return alert('Profile Link Copied!');
  };

  onClickBtn = (type, data) => {
    const { user, history } = this.props;
    if (type === 'edit') {
      shareLinkedinEmail(user.userToken);
      return history.replace('/myprofile');
    }

    if (type === 'copy') {
      shareLinkedinEmail(user.userToken);
      this.copyProfileLink(data);
      return history.replace('/myprofile');
    }

    if (type === 'share') {
      return history.replace('/myprofile?share=true');
    }

    return true;
  };

  render() {
    const { user } = this.props;
    const { primaryImg, firstName, lastName, headline, seoImage, username } = user;

    return (
      <div className={styles.shareprofile}>
        <div className={styles.linkedincard}>
          <div className={styles.liheaderb} />
          <div className={styles.liheader}>
            <div className={styles.icon}>
              <img src={linkedcolor} alt="linkedin" height="27px" width="27px" />
            </div>
            <div className={styles.iconmenu}>
              <img src={linkedmenu} alt="limenu" className={styles.iconmenuimg} />
            </div>
          </div>

          <div className={styles.libody}>
            <div className={styles.libodyprofile}>
              <img
                className={styles.linkedpobg}
                src={linkedpobg}
                alt="linkedpobg"
                height="52px"
                width="100%"
              />
              <div className={styles.liprofileimg}>
                <img
                  src={primaryImg}
                  alt="primaryImg"
                  height="70px"
                  width="70px"
                  className={styles.primaryImg}
                />
              </div>
              <div className={styles.liname}>
                <p className={styles.linamep}>{`${firstName} ${lastName}`}</p>
                <p className={styles.linameh}>{headline}</p>
              </div>
            </div>
            <div className={styles.seocard}>
              <div className={styles.tname}>
                <img src={primaryImg} height="50px" width="50px" alt="primaryImgName" />
                <div className={styles.tnamep}>
                  <p className={styles.linamep}>{`${firstName} ${lastName}`}</p>
                  <p className={styles.linameh}>{headline}</p>
                </div>
              </div>
              <p className={styles.seotit}>
                Get my advice by the hour! Schedule a booking with me for an online one-on-one video
                consultation. I consult using Conrati - a marketplace for consulting on demand.
              </p>
              <div className={styles.seocardimg}>
                <img src={seoImage} alt="seoimage" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.button}>
          <button type="button" onClick={() => this.onClickBtn('share')} className={styles.acbtn}>
            SHARE OVER LINKEDIN
          </button>
          <button
            type="button"
            onClick={() => this.onClickBtn('copy', username)}
            className={styles.vcalbtn}
          >
            COPY PROFILE LINK
          </button>
        </div>
      </div>
    );
  }
}

ShareProfile.propTypes = {
  user: PropTypes.shape({
    primaryImg: PropTypes.string,
    userToken: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    headline: PropTypes.string,
    seoImage: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  history: globalProptype.history.isRequired,
};

export default ShareProfile;
