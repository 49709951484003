import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Signin.module.css';
import Input from '../../Common/Input/Input';
import enums from '../../../config/enum';
import { apiurl, isValidEmail } from '../../../utils';
import linkedIco from '../../Common/assets/linked-color.svg';
import googleIco from '../../Common/assets/google.svg';
import arrowIco from '../../Common/assets/arrow_gray_white.svg';
import globalProptype from '../../../config/proptype';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      action: '',
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.action) {
      this.setState({ action: history.action });
    }
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const { action, email, password } = this.state;
    const { location, onSigninUser } = this.props;

    if (action === 'PUSH' || action === 'REPLACE') {
      if (location.state) {
        onSigninUser(
          {
            email,
            login_type: enums.loginType.email,
            password,
          },
          {
            redirect: true,
            url: `${location.state.from.pathname + location.state.from.search}`,
          }
        );
      } else {
        onSigninUser(
          {
            email,
            login_type: enums.loginType.email,
            password,
          },
          {
            redirect: false,
          }
        );
      }
    } else {
      onSigninUser(
        {
          email,
          login_type: enums.loginType.email,
          password,
        },
        {
          redirect: false,
        }
      );
    }
  };

  handleSocial = (type) => {
    // Open oauth signin flow
    const { onUserOauthData } = this.props;
    if (type === enums.oauthType.linkedin) {
      onUserOauthData('/signin');
      return window.location.replace(`${apiurl}/v1/oauth/auth/linkedin`);
    }

    if (type === enums.oauthType.Google) {
      onUserOauthData('/signin');
      return window.location.replace(`${apiurl}/v1/oauth/auth/google`);
    }

    return 'No matched redirection type';
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <div className={styles.signin}>
          <div className={styles.sbtnbox}>
            <div className={styles.sbtn}>
              <div className={styles.sibutton}>
                <button
                  type="button"
                  className={styles.sbtnc}
                  onClick={() => this.handleSocial(enums.oauthType.linkedin)}
                >
                  <img src={linkedIco} alt="" width="22px" />
                  <p className={styles.sbtntext}>Sign in with LinkedIn</p>
                </button>
                <button
                  type="button"
                  className={styles.sbtnc}
                  onClick={() => this.handleSocial(enums.oauthType.Google)}
                >
                  <img src={googleIco} alt="" width="22px" />
                  <p className={styles.sbtntext}>Sign in with Google</p>
                </button>
                <p className={styles.or}>or</p>
              </div>
            </div>
            <div className={styles.sbtnemail}>
              <p className={styles.sbtntitle}>Or use your email address</p>
              <div className={styles.rform}>
                <Input
                  type="email"
                  name="email"
                  htmlFor="Email"
                  label="Email address"
                  placeholder="Email address"
                  onChange={this.handleInput}
                />
                <Input
                  type="password"
                  name="password"
                  htmlFor="Password"
                  label="Password"
                  placeholder="Password"
                  onChange={this.handleInput}
                />
              </div>
              <p className={styles.mor}>or</p>
            </div>
          </div>
          <div className={styles.sforget}>
            <Link to="/reset-password">
              <p>Forgot password?</p>
              <div className={styles.aimg}>
                <img src={arrowIco} alt="Forget Password." />
              </div>
            </Link>
          </div>
          <div className={styles.snext}>
            <button
              type="button"
              disabled={!(isValidEmail(email) && password)}
              className={styles.snextbtn}
              onClick={this.handleSubmit}
            >
              Sign In
            </button>
          </div>
        </div>
      </>
    );
  }
}

Signin.propTypes = {
  location: globalProptype.location.isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    push: PropTypes.func.isRequired,
  }).isRequired,
  onSigninUser: PropTypes.func.isRequired,
  onUserOauthData: PropTypes.func.isRequired,
};

export default Signin;
