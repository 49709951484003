import React from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

type NavItem = {
	name: string;
	img: string;
	path?: string;
	/** Open navigation menu */
	onClick?: () => void;
	className?: string;
};

export const NavItem = ({
	name,
	img,
	onClick,
	className,
	path,
}: NavItem): JSX.Element => {
	const navigate = useNavigate();

	const onClickNavItem = (): void => {
		if (onClick) {
			return onClick();
		}

		return navigate(path || '/');
	};

	return (
		<button className={styles.navItem} onClick={onClickNavItem} data-testid={`nav-item-${name.toLowerCase()}`}>
			<img
				src={img}
				alt={name}
				className={className ? styles[className] : ''}
			/>
			{name}
		</button>
	);
};

NavItem.defaultProps = {
	path: '/',
	onMenu: undefined,
	className: undefined,
};

export default NavItem;
