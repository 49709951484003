/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import style from './style.module.scss';
import attachIcon from '../../assets/attach.svg';
import sendIcon from '../../assets/send.svg';
import Sent from './sent';
import Received from './received';
import { emitter, sendEvent } from '../../utils/event';
import globalProptypes from '../../../config/proptype/index';
import Welcome from './Welcome';
import { apiurl } from '../../../utils';

const messageType = {
  sent: 1,
  recieve: 2,
};

const messageGroupEnum = {
  message: 1,
  file: 2,
};

class InfoChatHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMessage: '',
    };
    this.inputElem = React.createRef();
    this.chatBody = React.createRef();
    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    emitter.on('recieve-message', (msg) => this.onReceiveMsg(msg));
  }

  onInputChange = (e) => {
    this.setState({
      inputMessage: e.target.value,
    });
  };

  onSendMessage = () => {
    const { inputMessage } = this.state;
    const { userData } = this.props;
    const { firstName, lastName, email } = userData;
    this.inputElem.current.value = '';

    const msg = {
      message: inputMessage,
      name: `${firstName} ${lastName}`,
      timestamp: moment().unix(),
      type: 0,
      email,
      id: uuidv4(),
      messageGroup: messageGroupEnum.message,
      link: '',
    };

    const mobileMessage = {
      type: 'session-chat',
			data: inputMessage,
    }

    sendEvent('send-message', msg);
  };

  // eslint-disable-next-line class-methods-use-this
  onReceiveMsg = () => {
    this.chatBody.current.scrollTop = this.chatBody.current.scrollHeight;
  };

  // eslint-disable-next-line class-methods-use-this
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.uploadFile(file);
  };

  // eslint-disable-next-line class-methods-use-this
  uploadFile = (file) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
      headers,
    };

    const formData = new FormData();

    formData.append('file', file);

    axios.post(`${apiurl}/v1/booking/session/upload`, formData, config);
  };

  render() {
    const { msgStack } = this.props;

    return (
      <div className={style.chat}>
        <div className={style.chatBody} ref={this.chatBody}>
          {msgStack.length === 0 && <Welcome />}
          {msgStack.map((msg) => {
            if (msg.type === messageType.sent) {
              return <Sent key={msg.id} msg={msg} />;
            }

            return <Received key={msg.id} msg={msg} />;
          })}
        </div>
        <div className={style.chatSendBox}>
          <input
            type="text"
            placeholder="Type here to chat ..."
            onChange={this.onInputChange}
            ref={this.inputElem}
          />
          <div className={style.btns}>
            <button className={style.btcirc} type="button" onClick={this.onSendMessage}>
              <img src={sendIcon} alt="Send" />
            </button>
            <button
              className={style.btcirc}
              type="button"
              onClick={() => {
                this.fileUpload.current.click();
              }}
            >
              <input
                type="file"
                accept="*"
                onChange={this.onFileChange}
                style={{ display: 'none' }}
                ref={this.fileUpload}
              />
              <img src={attachIcon} alt="Attach" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

InfoChatHeader.propTypes = {
  userData: globalProptypes.user.isRequired,
  msgStack: PropTypes.any.isRequired,
};

export default InfoChatHeader;
