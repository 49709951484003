import React, { Component } from 'react';
import moment from 'moment';
import styles from './common.module.css';
import { isObjectEmpty } from '../../../utils';

class Grace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown_to: {},
      duration: null,
      interval_id: null,
    };

    this.timeCounter = this.timeCounter.bind(this);
  }

  componentDidMount() {
    if (this.props.From) {
      const from = moment.unix(this.props.From);
      const to = moment(from).add(10, 'minutes');
      const interval_id = setInterval(this.timeCounter, 1000);

      this.setState({ countdown_to: to, interval_id });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval_id);
  }

  timeCounter = () => {
    if (!isObjectEmpty(this.state.countdown_to)) {
      const duration = moment.duration(this.state.countdown_to.diff(moment()));
      this.setState({ duration });
    }

    if (this.state.duration.minutes() <= 0 && this.state.duration.seconds() < 1) {
      clearInterval(this.state.interval_id);
      this.props.onNoShow(true);
    }
  };

  render() {
    const title = this.props.Title;
    return (
      <div className={styles.grace}>
        <p className={styles.title}>
          Please stay connected both parties have 10 minutes to join the meeting
        </p>
        {this.state.duration && (
          <p className={styles.atime}>
            {this.state.duration &&
              `${this.state.duration.minutes()}:${this.state.duration.seconds()}`}
          </p>
        )}
        <p className={styles.eartitle}>{title}</p>
      </div>
    );
  }
}

export default Grace;
