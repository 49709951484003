import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Mycalendar.module.css';
import Headers from '../Headers/Headers';
import ProfileCalendarEdit from '../ViewProfile/ProfileCalendarEdit/ProfileCalendarEdit';
import arrowgraywhite from '../Common/assets/arrow_gray_white.svg';
import { alert } from '../../utils';
import messages from '../../config/message';
import globalProptype from '../../config/proptype';
import enums from '../../config/enum';

class Mycalendar extends Component {
  componentDidMount() {
    const {
      stepsCompleted,
      history,
      user,
    } = this.props;
    if (!stepsCompleted) {
      alert(messages.apIncompleteAccunt);

      let targetPage = '';

      if (user.userType === enums.userType.ap) {
        return '/edit/step';
      }

      return history.replace('/myprofile' + targetPage);
    }

    return true;
  }

  render() {
    const { user, stepsCompleted } = this.props;
    const { hourlyRateWithSymbol } = user;
    return (
      <div>
        <Headers />
        <div className={styles.head}>
          <p className={styles.ptime}>{moment().format('hh:mm dddd')}</p>
          <p className={styles.pdate}>{moment().format('DD MMMM YYYY')}</p>
        </div>
        <div className={styles.dtit}>
          <p className={styles.db}>
            {hourlyRateWithSymbol ? `CALENDAR - ${hourlyRateWithSymbol} P/H` : 'CALENDAR'}
          </p>
        </div>
        <div className={styles.calendar}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {stepsCompleted && <ProfileCalendarEdit {...this.props} />}
        </div>
        <div className={styles.editcontainer}>
          <Link to="/availability" className={styles.edita}>
            <span style={{ marginRight: 4 }}>Edit Availability</span>{' '}
            <img src={arrowgraywhite} alt="Edit" />
          </Link>
        </div>
      </div>
    );
  }
}

Mycalendar.propTypes = {
  history: globalProptype.history.isRequired,
  stepsCompleted: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    hourlyRate: PropTypes.string,
    hourlyRateWithSymbol: PropTypes.string,
    userType: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles.profile,
    user: state.userState.user,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionToProps)(Mycalendar);
