import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './ButtonAuth.module.css';
import { isFreeSession } from '../../../utils';

const ButtonAuth = (props) => {
  const { isFreeSessionOpted, isLoggedIn, userData, username, fromPage } = props;
  return (
    <>
      <Link
        className={styles.button}
        to={fromPage !== 'view' ? `/${username}` : `/${username}/calendar`}
        target="_blank"
      >
        {isFreeSession(isFreeSessionOpted, userData.isFreeSessionConsumed, isLoggedIn) ? (
          <button type="button" className={styles.unauth}>
            BOOK FOR FREE
          </button>
        ) : (
          <button type="button" className={styles.btn}>
            BOOK
          </button>
        )}
      </Link>
    </>
  );
};

ButtonAuth.propTypes = {
  userData: PropTypes.shape({
    isFreeSessionConsumed: PropTypes.bool,
  }).isRequired,
  isFreeSessionOpted: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  username: PropTypes.string,
  fromPage: PropTypes.string,
};

ButtonAuth.defaultProps = {
  isFreeSessionOpted: false,
  username: '',
  fromPage: '',
};

const mapStateToProps = (state) => {
  return {
    user: state.userState,
    isLoggedIn: state.userState.isLoggedIn,
    userData: state.userState.user,
  };
};

export default connect(mapStateToProps)(ButtonAuth);
