import moment from 'moment';
import { FETCH_MASTER_DATA } from '../actions/types';

const initialState = {
  masterData: {
    categories: [],
    skills: [],
    languages: [],
    currencies: [],
    countries: [],
    lastUpdated: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASTER_DATA:
      return {
        ...state,
        masterData: { ...state.masterData, ...action.payload },
        lastUpdated: moment().format(),
      };

    default:
      return state;
  }
};
