import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './ListCard.module.css';
import visa_card from '../../Common/assets/card_visa_select.svg';
import master_card from '../../Common/assets/card_mastercard_select.svg';
import other_card from '../../Common/assets/other_card.svg';
import close_ico from '../../Common/assets/close_ico.svg';
import { apiurl } from '../../../utils';

class ListCard extends Component {
  componentDidMount() {
    const { user, onFetchUserCardData } = this.props;

    const data = {
      mangoPayUserId: user.mangopayUserId,
    };

    if (user.mangopayUserId) {
      onFetchUserCardData(data);
    }
  }

  onDeactivateCard = (e, cardid) => {
    e.preventDefault();
    const confirm = window.confirm('Please confirm delete card');
    if (confirm === true) {
      axios
        .post(
          `${apiurl}/v1/payment/card/deactivate`,
          { card_id: cardid },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.props.user.userToken}`,
            },
          }
        )
        .then(() => {
          const user = {
            userToken: this.props.user.userToken,
            mangoPayUserId: this.props.user.mangopayUserId,
          };

          this.props.onFetchUserCardData(user);

          return toast.warn('Your card has been deleted', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch(() => {
          return toast.warn('Card delete failed', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  };

  formatExpiryDate = (date) => {
    return `${date.substr(0, 2)}/${date.substr(2, 2)}`;
  };

  render() {
    return (
      <div className={styles.listcard}>
        {this.props.cardData &&
          this.props.cardData.map((card, index) => {
            if (card.active) {
              return (
                <div className={styles.lcard} key={index}>
                  <div className={styles.lcardic}>
                    <div className={styles.cardico}>
                      <img
                        src={
                          card.cardProvider === 'VISA'
                            ? visa_card
                            : card.cardProvider === 'MASTERCARD'
                            ? master_card
                            : other_card
                        }
                        alt="card"
                        width="50px"
                        height="33px"
                      />
                    </div>
                    <div className={styles.carddata}>
                      <p className={styles.cardtype}>{card.cardProvider}</p>
                      <p className={styles.cardno}>{card.alias}</p>
                    </div>
                  </div>
                  <div className={styles.endcard}>
                    <div className={styles.currency}>
                      <p className={styles.cardtype}>Currency</p>
                      <p className={styles.cardno}>{card.currency}</p>
                    </div>
                    <div>
                      <p className={styles.cardtype}>Expiry</p>
                      <p className={styles.cardno}>{this.formatExpiryDate(card.expirationDate)}</p>
                    </div>
                    <div
                      className={styles.closeico}
                      onClick={(e) => this.onDeactivateCard(e, card.id)}
                    >
                      <img src={close_ico} alt="Close" />
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>
    );
  }
}

export default ListCard;
