import React from 'react';
import styles from './Notfound.module.css';

const Notfound = () => {
  return (
    <div>
      <div className={styles.notfound}>
        <div className={styles.nsec}>
          <p className={styles.ntitle}>404</p>
          <p className={styles.nsub}>Page Not Found</p>
        </div>
        <div className={styles.nsuba}>
          <p>We are sorry but the page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
