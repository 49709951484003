import freeImg from './images/benfefits/free.svg';
import therapyImg from './images/benfefits/therapy.svg';
import findImg from './images/benfefits/find.svg';
import scheduldeImg from './images/benfefits/schedulde.svg';
import payImg from './images/benfefits/pay.svg';
import sliderUserImage1 from './images/slider/user1.png';
import sliderUserImage2 from './images/slider/user2.png';
import sliderUserImage12x from './images/slider/user1@2x.png';
import sliderUserImage22x from './images/slider/user2@2x.png';

const sliderUserList = [
  {
    id: 1,
    img: sliderUserImage1,
    img2x: sliderUserImage12x,
    link: '/-rudsel.g.maduro-',
  },
  {
    id: 2,
    img: sliderUserImage2,
    img2x: sliderUserImage22x,
    link: '/tamarathorpe',
  },
];

const themeTitle = 'Mentors';
const buttonText = 'Find a coach or mentor';
const initalTransformSize = -20;
const benifitTitle = 'A whole world of business coaches and mentors at your fingertips';
const benefitsList = [
  {
    id: 1,
    img: freeImg,
    title: 'First-time free sessions',
    subTitle: 'Many coaches and mentors offer 30 minute bookings for free to familiarise yourself',
  },
  {
    id: 2,
    img: therapyImg,
    title: 'Coaching and mentoring from home',
    subTitle: 'Video conference technology allows for calls from the comfort/safety of your home',
  },
  {
    id: 3,
    img: findImg,
    title: 'Find and compare coaches and mentors',
    subTitle: 'Mentors across different areas of specialisation and price',
  },
  {
    id: 4,
    img: scheduldeImg,
    title: 'Schedule an online booking instantly',
    subTitle: 'View coaches and mentors availability and schedule your booking',
  },
  {
    id: 5,
    img: payImg,
    title: 'Pay only after a successful consultation',
    subTitle: 'Only pay on the basis that your consultation was successful',
  },
];
const testominals = [
  {
    id: 1,
    name: 'Michael Clarke',
    comment:
      'Rudsel has a demeanor of confidence, backed up by a wealth of knowledge and experience. I found that within the first few minutes of chatting with him that I was speaking with an individual who was "been there and done that". I think many will find his advice helpful and beneficial to their overarching business goals. I will definitely be giving him more calls in the future.',
    star: 5,
  },
  {
    id: 2,
    name: 'Emma Whittaker',
    comment:
      'I get instant answers to my unique business questions. It’s great knowing that this resource is here when I need it.',
    star: 5,
  },
  {
    id: 3,
    name: 'Felix Valentine',
    comment:
      'I have regular sessions with my mentor. He has helped unleash my full potential by exposing me to new ideas and ways of thinking. I have really advanced both personally and professionally as a result of our calls.',
    star: 5,
  },
  {
    id: 4,
    name: 'Jessica Wiggins',
    comment:
      'Amazing platform - it certainly beats the user interface and design of your competitors so well done on that front.',
    star: 5,
  },
];

const userCardPlaceholder = 'Mentors & Business Coaches in Action';
const blogTitle = 'Recent Mentors & Business Coaches Blogs';

const blogsList = [
  {
    id: 1,
    img: 'https://blog.conrati.com/wp-content/uploads/2020/11/Simple-4-360x203.png',
    link: 'https://blog.conrati.com/progress-through-a-pandemic-podcast/',
    title: 'Progress post pandemic – Podcast',
    content:
      'We have all slowly gotten used to the new norm. Our days now routinely include masks, hand sanitisers at every...',
  },
  {
    id: 2,
    img: 'https://blog.conrati.com/wp-content/uploads/2020/10/nadir-syzygy-den6gTowZKs-unsplash-scaled-360x203.jpg',
    link: 'https://blog.conrati.com/mentorship-for-the-mentee/',
    title: 'Mentorship for the Mentee',
    content:
      'Everything you need to know about acquiring mentorship to accelerate your professional and personal growth....',
  },
  {
    id: 3,
    img: 'https://blog.conrati.com/wp-content/uploads/2020/10/PleaseWork-360x203.png',
    link: 'https://blog.conrati.com/a-walk-through-mentoring/',
    title: 'A walk through mentoring.',
    content:
      'We have all benefited from mentoring in one way or another. It may have been a sibling at home, a....',
  },
];

const popularItemsList = [
  'MENTORSHIP',
  'BUSINESS COACHING',
  'BUSINESS MENTOR',
  'EXECUTIVE COACHING',
];

const sliderTitle = 'Book world-class business mentors';
const sliderSubTitle = 'Leverage from the experience of experts';
const blogCategoryId = 53;

export {
  initalTransformSize,
  benifitTitle,
  benefitsList,
  testominals,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderTitle,
  sliderSubTitle,
  themeTitle,
  sliderUserList,
  blogCategoryId,
};
