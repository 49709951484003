import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './styles.module.css';

const Card = () => (
  <div className={styles.cardplaceholder}>
    <ContentLoader
      height={275}
      width={270}
      speed={2}
      primarycolor="#dfdcdc"
      secondarycolor="#ecebeb"
    />
  </div>
);

export default Card;
