import React, { Component } from 'react';
import styles from './common.module.css';

class Waiting extends Component {
  render() {
    let title = this.props.Title;
    return (
      <div className={styles.grace}>
        <p className={styles.title}>Please stay connected while your partner reconnects.</p>
        <p className={styles.eartitle}>{title}</p>
      </div>
    );
  }
}

export default Waiting;
