import React from 'react';
import styles from './styles.module.scss';
import backIcon from '../../assets/arrow-back-sec-800.svg';

interface NativeHeaderProps {
	title?: string;
	hideBackBtn?: boolean;
	rightContent?: React.ReactNode; // Content to be displayed on the right side of the header
	onBackClick?: () => void;
}

const NativeHeader = ({
	title,
	rightContent,
	onBackClick,
	hideBackBtn,
}: NativeHeaderProps): JSX.Element => {
	/**
	 * Handles the click event on the back button.
	 * If onBackClick prop is provided, calls the callback function.
	 * Otherwise, navigates back using the browser's history.
	 */
	const handleGoBack = (): void => {
		if (onBackClick) {
			onBackClick();
		} else {
			if (history.length > 1) {
				history.back();
			}
		}
	};

	return (
		<header
			className={styles.headerRoot}
			data-testid="native-ui-native-header"
		>
			<div className={styles.headerTopLeft}>
				{!hideBackBtn && (
					<button
						type="button"
						onClick={handleGoBack}
						className={styles.backButton}
						aria-label="Go back"
					>
						<img src={backIcon} alt="Back arrow" />
					</button>
				)}
				{title && <div>{title}</div>}
			</div>
			{rightContent && (
				<div className={styles.headerTopRight}>{rightContent}</div>
			)}
		</header>
	);
};

export { NativeHeader };
