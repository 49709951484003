import { User } from './user';

export const MOCK_USER_DAVID: User = {
	mangopayUserId: '207301429',
	userId: '3739',
	firstName: 'David',
	lastName: 'Arnoux',
	email: 'liveg27740@unbiex.com',
	dateOfBirth: '1972/09/15',
	mobileNumber: '27781361456',
	mobileCode: '27',
	personalTaxNo: '',
	countryId: 1157,
	userType: 1,
	timeZone: '+01:00',
	primaryImg: 'https://cdn.conrati.com/conrati_1583840357515',
	currencyId: 3,
	username: 'davidarnoux2',
	seoImage: 'https://cdn.conrati.com/seo/conrati_user_seo_1585720527715.png',
	isFreeSessionConsumed: false,
	accountStatus: 1,
	utmData: null,
	accountCategory: 1,
	availabiltyData: ['mo', 'tu', 'we', 'th', 'fr'],
	isBusinessRegistered: true,
	languages: [
		{
			code: 'fr',
			language: 'French',
			createdAt: '2019-05-07T06:40:36.793Z',
			searchKey: 'french',
			updatedAt: '2019-05-07T06:40:36.793Z',
			languageId: 234,
		},
		{
			code: 'en',
			language: 'English',
			createdAt: '2019-05-07T06:40:35.069Z',
			searchKey: 'english',
			updatedAt: '2019-05-07T06:40:35.069Z',
			languageId: 224,
		},
	],
	config: {
		switchToAp: true,
		receiveBookingInvoice: true,
	},
	business: {
		businessId: 1594,
		businessName: 'TEST',
		businessRegistrationNo: '77878123',
		typeOfBusiness: 1,
		vatNo: '',
		userId: 3083,
		address: 'TEST',
		city: 'TEST',
		region: '',
		countryId: 1083,
		postalCode: '65656',
		countryCode: 'DE',
		isSmallEnterprise: true,
	},
	hourlyRateEuro: 155,
	serviceDescription: 'I help companies grow',
	videoBio: 'https://www.youtube.com/watch?v=XBqM17MKz6U',
	certification: [
		{
			to: '',
			from: '',
			name: 'Machine Learning',
			about: '',
		},
		{
			to: '',
			from: '',
			name: 'AI for business',
			about: '',
		},
		{
			to: '',
			from: '',
			name: 'Growth Marketing',
			about: '',
		},
		{
			to: '',
			from: '',
			name: 'Growth Hacking',
			about: '',
		},
	],
	education: [
		{
			to: 'January 2006',
			from: 'January 2001',
			field: 'BBA',
			university: 'ESSEC Business School',
		},
		{
			to: 'September 2005',
			from: 'September 2004',
			field: 'BSc Dual Diploma',
			university: 'University Salford',
		},
	],
	experience: [
		{
			to: 'December 2020',
			from: 'May 2015',
			title: 'Head of Growth',
			company: 'Growth Tribe',
		},
		{
			to: 'December 2020',
			from: 'April 2014',
			title: 'Advisor',
			company: '20+ startups and scaleups',
		},
		{
			to: 'November 2016',
			from: 'September 2012',
			title: 'Head of Growth',
			company: 'Twoodo',
		},
		{
			to: 'April 2013',
			from: 'January 2006',
			title: 'Head of Growth',
			company: 'Lu Lemon',
		},
	],
	skills: [
		'digital marketing',
		'growth hacking',
		'growth engines',
		'rapid experimentation',
		'change management',
		'organizational change',
		'startups',
		'scale ups',
		'agile',
		'email marketing',
		'customer experience',
		'customer journey',
		'marketing',
		'management',
	],
	topics: [
		{
			title: 'Customer Acqusition',
			topicDescription: 'How to acquire more customers',
		},
		{
			title: 'Customer Retention',
			topicDescription: 'How to retain more customers',
		},
		{
			title: 'Building a growth engine',
			topicDescription: 'How to build a growth engine',
		},
		{
			title: 'Machine learning for marketing',
			topicDescription: '',
		},
	],
	headline: 'Co-founder | Head of Growth',
	categories: [
		{
			createdAt: '2019-05-07T06:34:20.486Z',
			searchKey: 'marketing',
			updatedAt: '2019-05-07T06:34:20.486Z',
			categoryId: 52,
			categoryName: 'Marketing',
		},
		{
			createdAt: '2019-05-07T06:34:20.486Z',
			searchKey: 'management',
			updatedAt: '2019-05-07T06:34:20.486Z',
			categoryId: 51,
			categoryName: 'Management',
		},
		{
			createdAt: '2019-05-07T06:34:20.488Z',
			searchKey: 'other',
			updatedAt: '2019-05-07T06:34:20.488Z',
			categoryId: 64,
			categoryName: 'Other',
		},
	],
	isFreeSessionOpted: false,
	suggestedSkill: [
		'digital marketing',
		'growth hacking',
		'growth engines',
		'rapid experimentation',
		'change management',
		'organizational change',
		'startups',
		'scale ups',
		'agile',
		'email marketing',
		'customer experience',
		'customer journey',
		'marketing',
		'management',
		'digital marketing',
		'growth hacking',
		'growth engines',
		'rapid experimentation',
		'change management',
		'organizational change',
		'startups',
		'scale ups',
		'agile',
		'email marketing',
		'customer experience',
		'customer journey',
		'marketing',
		'management',
	],
	hourlyRate: '155',
	hourlyRateWithSymbol: '€ 155.00',
	currency: {
		currencyId: 3,
		currencyCode: 'EUR',
		currencyName: 'Euro',
		currencySymbol: '€',
	},
	country: {
		countryId: 1157,
		countryCode: 'NL',
		countryName: 'Netherlands',
	},
	userToken:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImxpdmVnMjc3NDBAdW5iaWV4LmNvbSIsImlhdCI6MTY5OTYwMDgxMn0.NJUsTYAH4FlzAwoqE0sa0PwK419ZneiCRaiaqSD5QZA',
};
