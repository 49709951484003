import React, { useEffect } from 'react';
import Glider from 'glider-js';

import 'glider-js/glider.min.css';

import './styles.css';

interface SliderProps {
	children: React.ReactElement[];
	/** Number of items to show per slide */
	slidesToShow?: number;
	slidesToScroll?: number;
	className?: string;
}

export const Slider = (props: SliderProps): JSX.Element => {
	const { children, slidesToShow, slidesToScroll } = props;

	useEffect(() => {
		new Glider(document.querySelector('.glider'), {
			slidesToShow: slidesToShow || 1,
			slidesToScroll: slidesToScroll || 1,
			draggable: true,
			dots: '#dots',
		});
	}, []);

	return (
		<div className="glider-container">
			<div className="glider">
				{children.map((child, index) => (
					<div key={`glider-slide-${index}`}>{child}</div>
				))}
			</div>
			<div
				role="tablist"
				id="dots"
				className="glider-dots"
				data-testid="home-native-home-page-categories-slider-dots"
			/>
		</div>
	);
};

Slider.defaultProps = {
	slidesToShow: 1,
	slidesToScroll: 1,
};
