/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import closeIcon from '../../Common/assets/delete_ACTIVE.svg';
import economicsIcon from './images/economics.svg';
import engineeringIcon from './images/engineering.svg';
import erpIcon from './images/erp.svg';
import financialIcon from './images/financial.svg';
import growthhackingIcon from './images/growth hacking.svg';
import hrIcon from './images/hr.svg';
import itIcon from './images/it.svg';
import legalIcon from './images/legal.svg';
import logisticsIcon from './images/logistics.svg';
import managementIcon from './images/management.svg';
import marketingIcon from './images/marketing.svg';
import mentorshipcoachingIcon from './images/mentorship.svg';
import operationsIcon from './images/operations.svg';
import procurementIcon from './images/procurement.svg';
import productionIcon from './images/production.svg';
import projectmanagementIcon from './images/project management.svg';
import psychologyIcon from './images/psychology.svg';
import riskIcon from './images/risk.svg';
import salesIcon from './images/sales.svg';
import serviceIcon from './images/service.svg';
import strategyIcon from './images/strategy.svg';
import sustainabilityIcon from './images/sustainability.svg';
import technologyIcon from './images/technology.svg';
import tutoringIcon from './images/tutoring.svg';

function ExploreCategory(props) {
  const { onClose } = props;

  const categoriesItem = [
    {
      id: 1,
      name: 'Economics',
      slug: 'economics',
      img: economicsIcon,
      link: '/search?skills=economics',
    },
    {
      id: 2,
      name: 'Engineering',
      slug: 'engineering',
      img: engineeringIcon,
      link: '/search?skills=engineering',
    },
    {
      id: 3,
      name: 'ERP',
      slug: 'erp',
      img: erpIcon,
      link: '/search?skills=erp',
    },
    {
      id: 4,
      name: 'Financial',
      slug: 'financial',
      img: financialIcon,
      link: '/search?skills=financial',
    },
    {
      id: 5,
      name: 'Growth Hacking',
      slug: 'growth hacking',
      img: growthhackingIcon,
      link: '/categories/growthhacker',
    },
    {
      id: 6,
      name: 'HR',
      slug: 'hr',
      img: hrIcon,
      link: '/search?skills=hr',
    },
    {
      id: 7,
      name: 'IT',
      slug: 'it',
      img: itIcon,
      link: '/search?skills=it',
    },
    {
      id: 8,
      name: 'Legal',
      slug: 'legal',
      img: legalIcon,
      link: '/search?skills=legal',
    },
    {
      id: 9,
      name: 'Logistics',
      slug: 'logistics',
      img: logisticsIcon,
      link: '/search?skills=logistics',
    },
    {
      id: 10,
      name: 'Management',
      slug: 'management',
      img: managementIcon,
      link: '/search?skills=management',
    },
    {
      id: 11,
      name: 'Marketing',
      slug: 'marketing',
      img: marketingIcon,
      link: '/search?skills=marketing',
    },
    {
      id: 12,
      name: 'Mentorship / Coaching',
      slug: 'mentorship / coaching',
      img: mentorshipcoachingIcon,
      link: '/categories/mentors',
    },
    {
      id: 13,
      name: 'Operations',
      slug: 'operations',
      img: operationsIcon,
      link: '/search?skills=operations',
    },
    {
      id: 14,
      name: 'Procurement',
      slug: 'procurement',
      img: procurementIcon,
      link: '/search?skills=procurement',
    },
    {
      id: 15,
      name: 'Production',
      slug: 'production',
      img: productionIcon,
      link: '/search?skills=production',
    },
    {
      id: 16,
      name: 'Project management',
      slug: 'project management',
      img: projectmanagementIcon,
      link: '/search?skills=project management',
    },
    {
      id: 17,
      name: 'Psychology',
      slug: 'psychology',
      img: psychologyIcon,
      link: '/categories/psychologist',
    },
    {
      id: 18,
      name: 'Risk',
      slug: 'risk',
      img: riskIcon,
      link: '/search?skills=risk',
    },
    {
      id: 19,
      name: 'Sales',
      slug: 'sales',
      img: salesIcon,
      link: '/search?skills=sales',
    },
    {
      id: 20,
      name: 'Service',
      slug: 'service',
      img: serviceIcon,
      link: '/search?skills=service',
    },
    {
      id: 21,
      name: 'Strategy',
      slug: 'strategy',
      img: strategyIcon,
      link: '/search?skills=strategy',
    },
    {
      id: 22,
      name: 'Sustainability',
      slug: 'sustainability',
      img: sustainabilityIcon,
      link: '/search?skills=sustainability',
    },
    {
      id: 23,
      name: 'Technology',
      slug: 'technology',
      img: technologyIcon,
      link: '/search?skills=technology',
    },
    {
      id: 24,
      name: 'Tutoring',
      slug: 'tutoring',
      img: tutoringIcon,
      link: '/search?skills=tutoring,tutor',
    },
  ];

  return (
    <div className={styles.exploreCategory}>
      <div className={styles.head}>
        <p className={styles.title}>Explore the market place.</p>
        <button type="button" className={styles.closeBtn} onClick={onClose}>
          <img src={closeIcon} alt="close" height="20px" width="20px" />
        </button>
      </div>
      <div className={styles.categories}>
        {categoriesItem.map((catItem) => (
          <Link key={catItem.id} to={catItem.link} className={styles.catItem}>
            <img src={catItem.img} alt={catItem.name} />
            <p className={styles.linkText}>{catItem.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

ExploreCategory.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ExploreCategory;
