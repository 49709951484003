// Env of app
let appEnv = 'production';

function setManogpayEnv(env: string): void {
	appEnv = env;
}

// Get client id mangopay
function getMangopayClientId(): string {
	console.log('appEnv', appEnv);
	if (appEnv === 'production') {
		return 'conratigmbhcokgprod';
	}

	return 'conrati123test';
}

// Get base url mangopay
function getMangopayBaseUrl(): string {
	if (appEnv === 'production') {
		return 'https://api.mangopay.com';
	}

	return 'https://api.sandbox.mangopay.com';
}

export { getMangopayClientId, getMangopayBaseUrl, setManogpayEnv };
