import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import momenttz from 'moment-timezone';
import Headers from '../../Headers/Headers';
import styles from './styles.module.css';
import PersonalDetails from './PersonalDetails';
import BusinessDetails from './BusinessDetails';
import userNull from '../../Common/assets/addimage.svg';
import globalProptypes from '../../../config/proptype';
import ImageUploadModal from '../../Common/ImageUploadModal/ImageUploadModal';
import {
  uploadArUserProfileImage,
  editProfile as onProfileSync,
} from '../../../actions/userAction';
import enums from '../../../config/enum';
import messages from '../../../config/message';
import { alert } from '../../../utils';
import {
  editProfile,
  updateBusinessDetails,
  updateBusinessDetailsNatural,
} from '../../../services/userService';
import { bookSession } from '../../../actions/sessionAction';
import { DateTime } from 'luxon';

function onFreeBooking(bookingDetailsProps, userId, onBookSession) {
  const { start, end, topicTitle, topicDescription, apUserData } = bookingDetailsProps;

  const reqBookingData = {
    ar_id: userId,
    ap_id: apUserData.userId,
    hourly_rate: apUserData.hourlyRate,
    date_of_booking: moment(start).format('YYYY-MM-DD'),
    title: topicTitle,
    topic_description: topicDescription,
    from: moment(start).unix(),
    to: moment(end).unix(),
    time_zone: DateTime.local().zoneName,
    is_session_free: true,
  };

  return onBookSession(reqBookingData);
}

function validateForm(
  user,
  countryId,
  countryCode,
  currencyId,
  onUpdateProfile,
  history,
  isFreeBooking = false,
  bookingDetails = {},
  onBookSession
) {
  const { business, firstName, lastName, dateOfBirth, isEmailVerified } = user;

  if (business) {
    const {
      typeOfBusiness,
      businessName,
      businessRegistrationNo,
      address,
      city,
      postalCode,
      vatNo,
      isSmallEnterprise,
      isVatRegistered,
    } = business;

    const userStateServer = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dateOfBirth,
      currency_id: currencyId,
      country_id: countryId,
      personal_details_completed: true,
      time_zone: DateTime.local().zoneName,
    };

    const businessStateServer = {
      type_of_business: typeOfBusiness,
      business_name: businessName,
      business_registration_no: businessRegistrationNo,
      vat_no: vatNo,
      address,
      city,
      postal_code: postalCode,
      region: '',
      country_id: countryId,
      first_name: user.firstName,
      last_name: user.lastName,
      country_code: countryCode,
      isSmallEnterprise,
    };

    if (typeOfBusiness === enums.typeOfBusinessText['Freelancer / Soletrader']) {
      if (
        !firstName ||
        !lastName ||
        !dateOfBirth ||
        !businessName ||
        !address ||
        !city ||
        !postalCode
      ) {
        return alert(messages.requiredFieldsMissing);
      }

      if (isVatRegistered && !vatNo) {
        return alert(messages.requiredFieldsMissing);
      }

      if (!isEmailVerified) {
        return alert(messages.verifyEmail);
      }

      let toastId = null;

      toastId = toast.warn('Updating user details..', {
        position: 'top-center',
        hideProgressBar: true,
        autoClose: false,
      });

      return editProfile(userStateServer)
        .then(() => updateBusinessDetails(businessStateServer))
        .then(() => editProfile({}))
        .then(async (response) => {
          await onUpdateProfile(response.result);

          toast.update(toastId, {
            render: 'User details updated.',
            autoClose: 1000,
          });

          if (isFreeBooking) {
            return onFreeBooking(bookingDetails, user.userId, onBookSession);
          }

          return history.push({
            pathname: '/book/confirm',
          });
        })
        .catch((err) => {
          const msg = err.message || 'Update Failed';

          return toast.update(toastId, {
            render: msg,
            autoClose: 1000,
          });
        });
    }

    if (typeOfBusiness === enums.typeOfBusinessText.Business) {
      if (isVatRegistered && !vatNo) {
        return alert(messages.requiredFieldsMissing);
      }

      if (!isEmailVerified) {
        return alert(messages.verifyEmail);
      }

      if (
        !firstName ||
        !lastName ||
        !dateOfBirth ||
        !businessName ||
        !businessRegistrationNo ||
        !address ||
        !city ||
        !postalCode
      ) {
        return alert(messages.requiredFieldsMissing);
      }

      let toastId = null;

      toastId = toast.warn('Updating user details..', {
        position: 'top-center',
        hideProgressBar: true,
        autoClose: false,
      });

      return editProfile(userStateServer)
        .then(() => updateBusinessDetails(businessStateServer))
        .then(() => editProfile({}))
        .then(async (response) => {
          await onUpdateProfile(response.result);

          toast.update(toastId, {
            render: 'User details updated.',
            autoClose: 1000,
          });

          if (isFreeBooking) {
            return onFreeBooking(bookingDetails, user.userId, onBookSession);
          }

          return history.push({
            pathname: '/book/confirm',
          });
        })
        .catch((err) => {
          const msg = err.message || 'Update Failed';
          toast.update(toastId, {
            render: msg,
            autoClose: 1000,
          });
        });
    }

    if (typeOfBusiness === enums.typeOfBusinessText['Individual / Consumer']) {
      if (!isEmailVerified) {
        return alert(messages.verifyEmail);
      }

      // if (!primaryImg) {
      //   return alert(messages.imageUploadWarning);
      // }

      if (!firstName || !lastName || !dateOfBirth || !address || !city || !postalCode) {
        return alert(messages.requiredFieldsMissing);
      }

      const businessStateNaturalServer = {
        type_of_business: typeOfBusiness,
        address,
        city,
        postal_code: postalCode,
        region: '',
        country_id: countryId,
        first_name: user.firstName,
        last_name: user.lastName,
        country_code: countryCode,
      };

      let toastId = null;

      toastId = toast.warn('Updating user details..', {
        position: 'top-center',
        hideProgressBar: true,
        autoClose: false,
      });

      return editProfile(userStateServer)
        .then(() => updateBusinessDetailsNatural(businessStateNaturalServer))
        .then(() => editProfile({}))
        .then(async (response) => {
          await onUpdateProfile(response.result);

          toast.update(toastId, {
            render: 'User details updated.',
            autoClose: 1000,
          });

          if (isFreeBooking) {
            return onFreeBooking(bookingDetails, user.userId, onBookSession);
          }

          return history.push({
            pathname: '/book/confirm',
          });
        })
        .catch((err) => {
          const msg = err.message || 'Update Failed';
          toast.update(toastId, {
            render: msg,
            autoClose: 1000,
          });
        });
    }
  }

  return alert(messages.requiredFieldsMissing);
}

function QuickRegistration(props) {
  const {
    user,
    onProfileImageUpload,
    countryId,
    currencyId,
    onUpdateProfile,
    history,
    countryCode,
    bookingDetails,
    onBookSession,
  } = props;
  const { primaryImg, isEmailVerified } = user;
  const { isFreeSession } = bookingDetails;

  const [imageModalOpen, setImageModalOpen] = useState(false);

  return (
    <>
      <Headers />
      {imageModalOpen && (
        <ImageUploadModal
          user={{ ...user, countryId, currencyId }}
          onProfileImageUpload={onProfileImageUpload}
          onClose={() => setImageModalOpen(false)}
        />
      )}

      <div className={styles.layout}>
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.title}>
              <button
                type="button"
                className={primaryImg ? styles.image : styles.imageun}
                onClick={() => {
                  if (!isEmailVerified) {
                    return alert(messages.verifyEmail);
                  }
                  return setImageModalOpen(true);
                }}
              >
                <img src={primaryImg || userNull} alt="ProfileImage" />
              </button>
              <p className={styles.titleText}>
                To complete the booking, Please fill out the following details:
              </p>
            </div>
          </div>
          <div className={styles.section}>
            <PersonalDetails />
            <div className={styles.line} />
            <BusinessDetails />
          </div>
          <div className={styles.line} />
          <div className={styles.submitBtn}>
            {isFreeSession ? (
              <button
                type="submit"
                className={styles.buttonFree}
                onClick={() => {
                  validateForm(
                    user,
                    countryId,
                    countryCode,
                    currencyId,
                    onUpdateProfile,
                    history,
                    isFreeSession,
                    bookingDetails,
                    onBookSession
                  );
                }}
              >
                BOOK FREE SESSION
              </button>
            ) : (
              <button
                type="submit"
                className={styles.button}
                onClick={() => {
                  validateForm(
                    user,
                    countryId,
                    countryCode,
                    currencyId,
                    onUpdateProfile,
                    history,
                    false
                  );
                }}
              >
                NEXT
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

QuickRegistration.propTypes = {
  user: globalProptypes.user.isRequired,
  onProfileImageUpload: PropTypes.func.isRequired,
  countryId: PropTypes.number.isRequired,
  currencyId: PropTypes.number.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  history: globalProptypes.history.isRequired,
  countryCode: PropTypes.string.isRequired,
  bookingDetails: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    apUserData: globalProptypes.user,
    topicTitle: PropTypes.string,
    topicDescription: PropTypes.string,
    isFreeSession: PropTypes.bool,
  }).isRequired,
  onBookSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const countryId =
    state.userState.user.countryId > 0
      ? state.userState.user.countryId
      : state.userState.country.countryId;

  const countryCode =
    state.userState.user.countryId > 0
      ? state.userState.user.country.countryCode
      : state.userState.country.countryCode;

  const currencyId =
    state.userState.user.currencyId > 0
      ? state.userState.user.currencyId
      : state.userState.currency.currencyId;

  return {
    user: state.userState.user,
    countryId,
    currencyId,
    countryCode,
    bookingDetails: state.userState.ongoingBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onProfileImageUpload: uploadArUserProfileImage,
      onUpdateProfile: onProfileSync,
      onBookSession: bookSession,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(QuickRegistration);
