import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './BookingHistory.module.css';
import Headers from '../Headers/Headers';
import UserHead from '../Myaccount/UserHead/UserHead';
import calendarico from '../Common/assets/calendar.svg';
import btndownload from '../Common/assets/btn_download.svg';
import dispute from '../Common/assets/dispute.svg';
import nobooking from '../Common/assets/no_booking.svg';
import { fetchBookingHistory } from '../../actions/sessionAction';
import enums from '../../config/enum';
import globalProptype from '../../config/proptype';
import messages from '../../config/message';
import { apiurl, alert } from '../../utils';
import usernull from '../Common/assets/usernull.svg';

class BookingHistory extends Component {
  componentDidMount() {
    const { stepsCompleted, user, history, onFetchBookingHistory } = this.props;
    if (!stepsCompleted && user.userType === enums.userType.ap) {
      alert(messages.apIncompleteAccunt);
      return history.replace('/myprofile/edit/step');
    }

    return onFetchBookingHistory();
  }

  onDownloadInvoice = (type, bookingId) => {
    window.open(`${apiurl}/v1/invoice/download?type=${type}&booking_id=${bookingId}`, '_blank');
  };

  onDisputeBooking = (bookingId, user) => {
    const { history } = this.props;
    const url = `/company/contact?type=dispute&booking_id=${bookingId}&name=${`${user.firstName} ${user.lastName}`}&email=${
      user.email
    }&user_id=${user.user_id}`;

    history.push(url);
  };

  render() {
    const { user, bookingHistory } = this.props;
    return (
      <div>
        <Headers />
        <div className={styles.userhead}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <UserHead {...user} />
        </div>
        <div className={styles.dtit}>
          <p className={styles.db}>Booking History</p>
        </div>
        <div className={styles.bhistory}>
          <div className={styles.utitle}>
            <img src={calendarico} alt="upcomingSession" width="38px" height="38px" />
            <p>Booking History</p>
          </div>
          <div className={styles.abooking}>
            {bookingHistory &&
              bookingHistory.map((booking) => (
                <div key={booking.bookingId} className={styles.upseslist}>
                  {user.userType === enums.userType.ar ? (
                    <Link to={`/${booking.apUserData.username}`}>
                      <div className={styles.usrib}>
                        <img
                          className={styles.pimg}
                          src={
                            booking.apUserData.primaryImg ? booking.apUserData.primaryImg : usernull
                          }
                          alt="user"
                        />
                        <p className={styles.uname}>
                          {`${booking.apUserData.firstName} ${booking.apUserData.lastName}`}
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <div className={styles.usrib}>
                      <img
                        className={styles.pimg}
                        src={
                          booking.arUserData.primaryImg ? booking.arUserData.primaryImg : usernull
                        }
                        alt="user"
                      />
                      <p className={styles.uname}>
                        {`${booking.arUserData.firstName} ${booking.arUserData.lastName}`}
                      </p>
                    </div>
                  )}

                  <div className={cx(styles.bitem)}>
                    <p className={styles.bititle}>Date</p>
                    <p className={styles.bisub}>
                      {moment(booking.dateOfBooking, 'YYYY-MM-DD').format('DD MMM YYYY')}
                    </p>
                  </div>
                  <div className={cx(styles.bitem, styles.bhidden)}>
                    <p className={styles.bititle}>Hourly Rate</p>
                    <p className={styles.bisub}>
                      {booking.invoiceData.booking.hourlyRateWithCurrency || 'N/A'}
                    </p>
                  </div>
                  <div className={cx(styles.bitem, styles.bhidden)}>
                    <p className={styles.bititle}>Hours</p>
                    <p className={styles.bisub}>{booking.invoiceData.booking.hours || 'N/A'} hrs</p>
                  </div>
                  <div className={cx(styles.bitem, styles.bhidden)}>
                    <p className={styles.bititle}>Total</p>
                    <p className={styles.bisub}>
                      {booking.invoiceData.transaction
                        ? booking.invoiceData.transaction.grandTotalWithCurrency
                        : 'N/A'}
                    </p>
                  </div>
                  <div className={styles.bitem}>
                    <p className={styles.bititle}>Invoices</p>
                    {user.config.receiveBookingInvoice &&
                      booking.bookingStatus === enums.bookingStatus.SUCCESSFUL && (
                        <div className={styles.bdown}>
                          <div
                            className={styles.bditem}
                            onClick={() =>
                              this.onDownloadInvoice(
                                `${
                                  booking.arUserData.business.typeOfBusiness ===
                                  enums.typeOfBusinessText['Individual / Consumer']
                                    ? 'APARB2C'
                                    : 'APAR'
                                }`,
                                booking.bookingId
                              )
                            }
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex="0"
                          >
                            <img src={btndownload} alt="Download" height="18px" width="18px" />
                            <span className={styles.download}>
                              {booking.invoiceData.invoiceNumber}
                            </span>
                          </div>
                          {user.userType === enums.userType.ap && (
                            <div
                              className={styles.bditem}
                              onClick={() => this.onDownloadInvoice('CONAP', booking.bookingId)}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex="0"
                            >
                              <img src={btndownload} alt="Download" height="18px" width="18px" />
                              <span className={styles.download}>
                                {booking.invoiceData.conratiInvoiceNumber}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    {user.config.receiveBookingInvoice &&
                      booking.bookingStatus === enums.bookingStatus.AR_NOSHOW && (
                        <div className={styles.bdown}>
                          <div
                            className={styles.bditem}
                            onClick={() => this.onDownloadInvoice('APAR', booking.bookingId)}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex="0"
                          >
                            <img src={btndownload} alt="Download" height="18px" width="18px" />
                            <span className={styles.download}>
                              {booking.invoiceData.invoiceNumber}
                            </span>
                          </div>
                          {user.userType === enums.userType.ap && (
                            <div
                              className={styles.bditem}
                              onClick={() => this.onDownloadInvoice('CONAP', booking.bookingId)}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex="0"
                            >
                              <img src={btndownload} alt="Download" height="18px" width="18px" />
                              <span className={styles.download}>
                                {booking.invoiceData.conratiInvoiceNumber}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    {!user.config.receiveBookingInvoice ||
                    (booking.bookingStatus !== enums.bookingStatus.AR_NOSHOW &&
                      booking.bookingStatus !== enums.bookingStatus.SUCCESSFUL) ? (
                      <div className={styles.bdown}>
                        <p className={styles.bisub}>N/A</p>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={styles.bitem}
                    onClick={() => this.onDisputeBooking(booking.bookingId, user)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <p className={styles.bititle}>Dispute</p>
                    <div className={styles.bditem}>
                      <img src={dispute} alt="Download" height="18px" width="18px" />
                      <span className={styles.download}>Dispute</span>
                    </div>
                  </div>
                  <div className={styles.bitem}>
                    <p className={booking.bookingStatus === 1 ? styles.bstatuss : styles.bstatus}>
                      {enums.bookingStatusText[booking.bookingStatus]}
                    </p>
                  </div>
                </div>
              ))}

            {bookingHistory.length === 0 && (
              <div className={styles.nobooking}>
                <img src={nobooking} alt="No Booking" className={styles.bimg} />
                <p className={styles.ntitle}>You have no bookings</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BookingHistory.propTypes = {
  user: PropTypes.shape({
    userType: PropTypes.number,
  }).isRequired,
  onFetchBookingHistory: PropTypes.func.isRequired,
  bookingHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: globalProptype.history.isRequired,
  stepsCompleted: PropTypes.bool.isRequired,
  location: globalProptype.location.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    bookingHistory: state.session.bookingHistory,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchBookingHistory: fetchBookingHistory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(BookingHistory);
