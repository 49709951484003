import PsychTestPage from './PsychTestPage';

const tests = [
  'adhd',
  'anxiety',
  'bipolar',
  'burnout',
  'new-depression',
  'insomnia',
  'ocd',
  'panic-disorder',
  'postpartum-depression',
  'psycho-somatic',
  'ptsd',
  'social-anxiety',
];

export default [
  {
    path: `/(${tests.join('|')})-test`,
    component: PsychTestPage,
  },
];
