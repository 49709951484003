import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styles from './MarketingPrompt.module.css';
import homeSearch from '../Common/assets/search_home.svg';
import EditRadio from '../Common/EditRadio/EditRadio';
import { updateProfile, handleOptin } from '../../actions/userAction';
import payment from '../Common/assets/payment receive.svg';
import availabilityHome from '../Common/assets/calendar_auth.svg';
import userCard from '../Common/assets/user_card.svg';
import enums from '../../config/enum';
import globalPropTypes from '../../config/proptype';
import Cookies from 'js-cookie';

class MarketingPrompt extends Component {
  componentDidMount() {
    const { user, optinClicked, history, ongoingBooking } = this.props;

    if (user.isMarketting || optinClicked) {
      if (user.userType === enums.userType.ap) {
        history.replace('/myprofile/edit/step');
      } else {
        if (ongoingBooking.start) {
          return history.replace('/quick-registration-ar');
        }
        return history.replace('/search');
      }
    }

    return true;
  }

  onCheck = (check) => {
    const { ongoingBooking, onUpdateProfile, user, history, onHandleOptin } = this.props;
    if (check) {
      onUpdateProfile({
        userToken: user.userToken,
        is_marketting: check,
      });
    }
    onHandleOptin(true);

    if (user.userType === enums.userType.ap) {
      return history.replace('/myprofile/edit/step');
    }

    if (ongoingBooking.start) {
      return history.replace('/quick-registration-ar');
    }

    // Redirect based on UTM
    if (Cookies.get('seeking_advice_from_psychologist')) {
      return history.replace('/categories/psychologist');
    }

    return history.replace('/search');
  };

  render() {
    const { user } = this.props;
    return (
      <div className={styles.marketing}>
        {user.userType === enums.userType.ar ? (
          <div className={styles.marketingcard}>
            <p className={styles.title}>You’ve been successfully registered!</p>
            <p className={styles.sub}>
              You will only be able to book a consultant once you have a method of payment set{' '}
              <br />
              up, but if you’re not quite ready for that, feel free to begin your Conrati experience{' '}
              <br />
              by searching for the perfect consultant.
            </p>

            <img className={styles.simg} src={homeSearch} alt="Search" />
            <div className={styles.checkbox}>
              <div className={styles.check}>
                <EditRadio onCheck={() => this.onCheck(false)} />
                <p className={styles.checkp}>No, I would not like to receive any notifications</p>
              </div>
              <div className={styles.check}>
                <EditRadio onCheck={() => this.onCheck(true)} />
                <p className={styles.checkp}>
                  Yes, I would like to receive all notifications, tips and announcements
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.marketingcard}>
            <p className={styles.mtitle}>
              You’re registered! To enable bookings, do the following:
            </p>
            <div className={styles.micard}>
              <img src={userCard} alt="Payment" />
              <div>
                <p className={styles.head}>Complete your personal information</p>
                <p className={styles.msub}>
                  Add personal information to your public profile so that consultation requests are
                  better paired to your area of expertise.
                </p>
              </div>
            </div>
            <div className={styles.micard}>
              <img src={payment} alt="Payment" />
              <div>
                <p className={styles.head}>Complete your business information</p>
                <p className={styles.msub}>
                  Add your business details to allow Conrati to generate invoices on your behalf.
                </p>
              </div>
            </div>
            <div className={styles.micardlast}>
              <img src={availabilityHome} alt="Payment" />
              <div>
                <p className={styles.head}>Integrate your calendar</p>
                <p className={styles.msub}>
                  Conrati integrates with your calendar to insert all Conrati related bookings into
                  your external calendar.
                </p>
              </div>
            </div>
            <div className={styles.checkbox}>
              <div className={styles.check}>
                <EditRadio onCheck={() => this.onCheck(false)} />
                <p className={styles.checkp}>No, I would not like to receive any notifications</p>
              </div>
              <div className={styles.check}>
                <EditRadio onCheck={() => this.onCheck(true)} />
                <p className={styles.checkp}>
                  Yes, I would like to receive all notifications, tips and announcements
                </p>
              </div>
            </div>
            {/* <div className={styles.buttonss}>
                        <Link to="/myprofile/edit/step" className={styles.btn}>MY PROFILE</Link>
                    </div> */}
          </div>
        )}
      </div>
    );
  }
}

MarketingPrompt.propTypes = {
  user: globalPropTypes.user.isRequired,
  history: globalPropTypes.history.isRequired,
  optinClicked: PropTypes.bool.isRequired,
  ongoingBooking: globalPropTypes.ongoingBooking.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onHandleOptin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    optinClicked: state.userState.optinClicked,
    ongoingBooking: state.userState.ongoingBooking,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateProfile: updateProfile,
      onHandleOptin: handleOptin,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(MarketingPrompt);
