/* eslint-disable import/prefer-default-export */
import { FETCH_MASTER_DATA, FETCH_IPINFO_DETAILS } from './types';
import { alert, apiRequest as API } from '../utils';

export const fetchMasterData = () => (dispatch) => {
  API('GET', '/v1/common/masterdata')
    .then((res) => {
      dispatch({
        type: FETCH_MASTER_DATA,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.messgae);
    });
};

export const fetchCountryCurrencyDetails = () => (dispatch) => {
  API('GET', '/v1.1/common/ipinfo')
    .then((res) => {
      dispatch({
        type: FETCH_IPINFO_DETAILS,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.messgae);
    });
};
