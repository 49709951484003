import React from 'react';
import styles from '../../Static.module.css';
import Collapse from '../../../Common/collapse/collapse';

const General = () => {
  return (
    <div>
      <p className={styles.ftitle}>General</p>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What is Conrati?"
          content="Conrati is an online B2B and B2C marketplace for consulting on demand, by the hour. We are the fast-lane for consulting! From calendar integration to bookings, video consultations, automated invoicing, payments and more. "
          expand
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are Conrati’s main features?"
          content={`You can register on Conrati to either provide or receive advice. Conrati will arm you with a toolset to facilitate the procurement of consulting. The platform offers:  \u000A
                    • Integrated calendars allowing easy access to Advice Providers availability and removing the hassle of back and forth booking arrangements.

                    • Pinpoint search accuracy of Advice Providers based on availability, skills, language, certifications and price allow you to find the ideal consultant within seconds.

                    • Booking functionality at an hourly rate that has been set by the Advice Providers.

                    • Automated invoicing with automatic cross-border vat determination. Our app automatically raises an invoice on your business’s behalf and calculates the cross-border vat determination.

                    • Built-in video consultation technology during the scheduled booking allows users to fulfil their consultation remotely. 
                    
                    • Automated payments after a successful consultation means Advice Providers do not have to worry about chasing payments.
                    `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What is the mission and vision of Conrati?"
          content="Conrati envisages becoming the fast-lane for consulting by making advice accessible and on-demand throughout the globe.  "
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How can businesses or individuals use the application?"
          content="The Conrati application caters for both individual consumers and business users whether your business is a sole trader or fully registered company. Individuals and businesses make use of Conrati by visiting our website to register as an Advice Provider or Advice Receiver. 

          Your individual consumer or business information is filled in during the onboarding process to ensure all transactions generated by the system relates to your personal or business details.
          "
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are the benefits for Advice Providers using the application?"
          content={`
                    From calendar integration to bookings, video consultations, automated invoicing, payments and more. Conarti offers Advice Providers a full toolset to boost their consultations and simplify the procurement of consulting. 

                    Advice Provider’s profiles and availability are published on Conrati and made available to all registered Advice Receivers on our website via a precise search tool. Conrati further optimises public profiles to allow profiles to be discoverable on other popular search engines in order to increase bookings. 

                    Advice Providers can earn additional money beyond their current work by making their free hours between assignments available on Conrati for online consulting.

                    Advice Providers waste no time on administrative tasks such as booking arrangments, selecting video conference technology, invoicing and VAT determination not to mention chasing payments. These processes are automated when utilising Conarti. 

                    Advice Providers have the opportunity to consult remotely from the comfort of their own environment.

                    Advice Providers are paid swiftly after each successful consultation
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="What are the benefits for Advice Receivers using the application?"
          content={`
                    Advice Receivers use our application to book online consultations with Advice Providers of their choice within a matter of minutes. This is the only application which provides the skills, rates and hourly availability of consultants with the ability to book online consultations, handle payments and tax invoices. 

                    Feedback from Advice Receivers using our application is that they find the answers they are looking for much faster, overcome hurdles on projects quicker, and pay far less than they used to.
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="How do I query an instance related to my interaction with the system (i.e. vat determination on an invoice)?"
          content={`
                    You have the ability to create a query related to a specific transaction by selecting the dispute link in your booking history before submitting more information related to your query.

                    You are also able to send queries to support@conrati.com. Please quote your booking reference number if your query relates to a specific transaction.
                    
                `}
          expand={false}
        />
      </div>
      <div className={styles.fquestionitem}>
        <Collapse
          title="Which currencies and countries are supported by the Conrati application?"
          content={`
          We support the United Kingdom (£), South African (R) as well as countries in Europe that trade using the Euro (€) including Austria, Belgium, Cyprus, Estonia, Finland, France, Germany, Greece, Italy, Ireland, Latvia, Lithuania, Luxembourg, Malta, the Netherlands, Portugal, Slovakia, Slovenia and Spain. Soon the platform will be rolled out into more countries. 


          To uphold best accounting business practice, Conrati's generation of invoices and deduction of funds on behalf of Advice Providers are always in the relevant Advice Provider’s currency. This ensures that consultants receive faster payouts, do not incur unnecessary conversion costs on payouts and are able to account for every cent of income earned through Conrati as the numerical amounts on invoices match the exact flow of funds.
          
          Advice Receivers are shown a converted estimate or quotation of a consultation based on their selected currency with which they registered.
          
                    
                `}
          expand={false}
        />
      </div>
    </div>
  );
};

export default General;
