import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './EditRadio.module.css';
import checkbox_checked from '../assets/checkbox_checked.svg';
import checkbox_empty from '../assets/checkbox_empty.svg';
import information from '../assets/info.svg';

class EditRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    if (this.props.checked && this.props.checked === true) {
      this.setState({ checked: true });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.checked && newProps.checked === true) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  }

  onCheckClick(e) {
    e.preventDefault();
    const { disabled, onCheck } = this.props;
    const { checked } = this.state;
    if (disabled) {
      return true;
    }

    this.setState({ checked: !checked });
    onCheck(!checked);
  }

  render() {
    const { label, tooltip } = this.props;
    const right = this.props.right || false;
    return (
      <div
        className={styles.radio}
        data-for="checkbox"
        data-tip={this.props.tooltipmsg ? this.props.tooltipmsg : ''}
      >
        {!right && (
          <img
            src={this.state.checked ? checkbox_checked : checkbox_empty}
            alt="unchecked"
            width="34px"
            onClick={this.onCheckClick.bind(this)}
          />
        )}
        <div className={styles.textp}>
          <p>{label}</p>
          {tooltip && (
            <p
              data-for="checkbox"
              data-tip="Are you a small or medium-sized enterprise that is exempt from VAT obligations in your country of establishment. Selection of this field may be disabled due to applicable country-specific VAT rules applied to your provided business details"
              width="30px"
              height="30px"
            >
              <img
                src={information}
                height="22px"
                width="22px"
                alt="info"
                className={styles.textpi}
              />
            </p>
          )}
        </div>
        {right && (
          <div>
            <img
              src={this.state.checked ? checkbox_checked : checkbox_empty}
              alt="unchecked"
              width="34px"
              onClick={this.onCheckClick.bind(this)}
            />
            <p>Yes</p>
          </div>
        )}

        <ReactTooltip id="checkbox" place="top" type="warning" effect="solid" />
      </div>
    );
  }
}

EditRadio.propTypes = {
  onCheck: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditRadio.defaultProps = {
  tooltip: false,
  disabled: false,
};

export default EditRadio;
