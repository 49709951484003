import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { NativeHeader } from '../header';
import { SelectSearchInput } from './select-search-input';
import { SelectOption } from './select-option';
import { OptionType } from './select';
import { matchingOption } from './select-util';
import { SelectBottomButton } from './select-bottom-button';

export interface NativeSelectProps {
	title: string;
	/** Placeholder for the input search */
	placeholder: string;
	/** Array of options that populate the select options */
	options: OptionType[];
	selectedOption?: OptionType | OptionType[];
	selectOption(option: OptionType | OptionType[]): void;
	/** Support multiple selected options */
	isMulti?: boolean;
	/** Text to display when there are no options */
	noOptionMessage?: string;
	selectedOptionPlaceholder?: string;
	disabled?: boolean;
	className?: string;
	/**
	 * Class name for styling the container of all options
	 */
	optionsContainerClass?: string;
}

export const NativeSelect = (props: NativeSelectProps): JSX.Element => {
	const {
		title,
		placeholder,
		options,
		isMulti,
		selectOption,
		noOptionMessage,
		selectedOption,
		selectedOptionPlaceholder,
		disabled,
		className,
		optionsContainerClass,
	} = props;

	// Internal state for options to enable filtering when searching
	const [internalOptions, setInternalOptions] = useState<OptionType[]>([]);
	const optionsRef = useRef<OptionType[]>([]);
	const [hasSelectedOption, setHasSelectedOption] = useState<boolean>(false);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);

	// Searching state
	const [searchResults, setSearchResults] = useState<OptionType[]>([]);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	useEffect(() => {
		let inputOptions = options;

		if (selectedOption) {
			inputOptions = [];
			let hasSelectedItem = false;

			options.forEach((option) => {
				option.isSelected = matchingOption(option, selectedOption);

				if (option.isSelected) {
					hasSelectedItem = true;
				}

				inputOptions.push(option);
			});

			setHasSelectedOption(hasSelectedItem);
		}

		optionsRef.current = inputOptions;
		setInternalOptions(inputOptions);
	}, [options, selectedOption]);

	const matchingSearch = (
		option: OptionType,
		searchValue: string
	): boolean => {
		const trimValue = searchValue.toLowerCase();

		return (
			option.value.toLowerCase().includes(trimValue) ||
			option.label.toLowerCase().includes(trimValue)
		);
	};

	const onSearchInput = (value: string): void => {
		const trimValue = value.trim();

		if (!trimValue) {
			setInternalOptions(optionsRef.current);
			setSearchResults([]);
			setIsSearching(false);

			return;
		}

		const filteredOptions = internalOptions.filter((option) =>
			matchingSearch(option, trimValue)
		);

		setIsSearching(true);
		setSearchResults(filteredOptions);
	};

	const onShowOverlay = (): void => {
		if (!disabled) setShowOverlay(true);
	};

	const onHideOverlay = (): void => {
		setShowOverlay(false);
		setHasSelectedOption(false);
		setInternalOptions(options);
	};

	const onSelectOption = (option: OptionType): void => {
		const modifyOptions: OptionType[] = [];
		let hasSelectedItem = false;

		internalOptions.forEach((item) => {
			// Creating an immutable copy on the item: OptionType object
			const obj = { ...item };

			if (!isMulti) {
				obj.isSelected = item.value === option.value;
			}

			if (isMulti && item.value === option.value) {
				obj.isSelected = !obj.isSelected;
			}

			if (obj.isSelected) {
				hasSelectedItem = true;
			}

			modifyOptions.push(obj);
		});

		// If there are search results update them also
		if (searchResults.length > 0) {
			const updateSearchResults = searchResults.map((result) => {
				if (!isMulti) {
					result.isSelected = result.value === option.value;
				}

				if (isMulti && result.value === option.value) {
					result.isSelected = !result.isSelected;
				}

				return result;
			});

			setSearchResults(updateSearchResults);
		}

		setHasSelectedOption(hasSelectedItem);
		setInternalOptions(modifyOptions);

		optionsRef.current = modifyOptions;
	};

	const renderSelectedOption = (): string => {
		let value = '';

		if (Array.isArray(selectedOption)) {
			value = selectedOption.map((option) => option.label).join(', ');
		}

		if (!Array.isArray(selectedOption) && selectedOption) {
			value = selectedOption.label;
		}

		if (!value) {
			value = selectedOptionPlaceholder || 'Select ...';
		}

		return value;
	};

	const onSelect = (): void => {
		const getSelectedOptions = internalOptions.filter(
			(option) => option.isSelected
		);

		setIsSearching(false);
		setShowOverlay(false);
		selectOption(getSelectedOptions);
	};

	const noQueryMatch = (): boolean => {
		return options.length > 0 && isSearching && searchResults.length === 0;
	};

	return (
		<div
			className={`${styles.nativeSelectContainer} ${className || ''}`}
			data-testid="ui-native-select-container"
		>
			<button
				className={styles.nativeSelectButton}
				onClick={(): void => onShowOverlay()}
				type="button"
				disabled={disabled}
				data-testid="ui-native-select-button"
			>
				{renderSelectedOption()}
			</button>

			{showOverlay && (
				<div
					className={styles.nativeSelectFixedContainer}
					data-testid="ui-native-select-fixed-container"
				>
					<NativeHeader
						title={title}
						onBackClick={(): void => onHideOverlay()}
					/>
					<div
						className={styles.nativeSelectSections}
						data-testid="ui-native-select-sections"
					>
						<SelectSearchInput
							onSearchInput={onSearchInput}
							placeholder={placeholder}
						/>
						<div
							className={`${styles.nativeSelectOptions} ${
								optionsContainerClass || ''
							}`}
							data-testid="ui-native-select-options"
						>
							{(
								(isSearching && searchResults) ||
								internalOptions
							).map((option, index) => (
								<SelectOption
									option={option}
									selectOption={(): void =>
										onSelectOption(option)
									}
									key={`native-select-option-${index}`}
								/>
							))}

							{options.length === 0 && (
								<div
									className={styles.noSelectOptions}
									data-testid="ui-native-select-no-available-options"
								>
									{noOptionMessage || 'No available options'}
								</div>
							)}

							{noQueryMatch() && (
								<div
									className={styles.noSelectOptions}
									data-testid="ui-native-select-no-query-match"
								>
									No matching query
								</div>
							)}
						</div>
					</div>

					<SelectBottomButton
						isDisabled={!hasSelectedOption}
						onSelect={onSelect}
					/>
				</div>
			)}
		</div>
	);
};

NativeSelect.displayName = 'Native Select';
