import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SessionHeader.module.css';
import logo from '../../Common/assets/logo-yellow.png';
import linkico from '../../Common/assets/sharelink.svg';
import endcall from '../../Common/assets/endcall.svg';

export default function SessionHeaderNs() {
  return (
    <header className={styles.sheaders}>
      <div className={styles.container}>
        <nav className={styles.links}>
          <div className={styles.leftns}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="conrati-logo" width="110px" />
              </Link>
            </div>
            <div className={styles.callbtnssns}>
              <button type="button">
                <img src={linkico} alt="sharelink" width="30px" height="30px" />
              </button>
              <button type="button">
                <img src={endcall} alt="endcall" width="30px" height="30px" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
