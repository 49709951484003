import { User } from '@conrati/ui';
import { ApiService } from '@conrati/utils';

const endpoint = '/v1.1/profile/';

const cache: Map<string, User> = new Map();

async function getUser(username: string): Promise<User> {
	const cacheKey = username;

	const cacheResponse = cache.get(cacheKey);

	if (cacheResponse) {
		return cacheResponse;
	}

	const fetchUserDetailsFromServer = await ApiService.ApiRequest<User>(
		'POST',
		endpoint + username
	);

	cache.set(cacheKey, fetchUserDetailsFromServer);

	return fetchUserDetailsFromServer;
}

export { getUser };
