import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.css';
import nextbtn from '../../Common/assets/forward.svg';
import { paginationGenerator } from '../../../utils';

class Pagination extends Component {
  onClickButton = (type) => {
    const { totalPages, page, onChangePage } = this.props;

    if (type === 'prev') {
      if (page > 1) {
        onChangePage(page - 1);
      }
    }

    if (type === 'next') {
      if (page < totalPages) {
        onChangePage(page + 1);
      }
    }
  };

  onClickOnPage = (pageNumber, index, displayPages) => {
    const { onChangePage } = this.props;
    const lengthOfPages = displayPages.length;

    if (pageNumber === '...') {
      if (index + 1 === lengthOfPages - 1) {
        const page = displayPages[index - 1] + 2;
        return onChangePage(page);
      }

      if (index === 1) {
        const page = displayPages[index - 1] + 2;
        return onChangePage(page);
      }
    }

    return onChangePage(pageNumber);
  };

  render() {
    const { totalPages, page } = this.props;

    const displayPages = paginationGenerator(page, totalPages);

    return (
      <div className={styles.paginationContainer}>
        <button
          type="button"
          className={styles.paginationButtonPrev}
          onClick={() => this.onClickButton('prev')}
        >
          <img src={nextbtn} alt="Next" />
        </button>
        {displayPages.map((item, index) => (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="button"
            disabled={page === item}
            className={page === item ? styles.paginationButtonActive : styles.paginationButton}
            onClick={() => this.onClickOnPage(item, index, displayPages)}
          >
            {item}
          </button>
        ))}
        <button
          type="button"
          className={styles.paginationButtonNext}
          onClick={() => this.onClickButton('next')}
        >
          <img src={nextbtn} alt="Next" />
        </button>
      </div>
    );
  }
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default Pagination;
