import freeImg from '../../assets/benefit-images/free.svg';
import therapyImg from '../../assets/benefit-images/therapy.svg';
import findImg from '../../assets/benefit-images/find.svg';
import scheduleImg from '../../assets/benefit-images/schedule.svg';
import payImg from '../../assets/benefit-images/pay.svg';

export const blogArticles = [
	{
		id: 1,
		img:
      'https://blog.conrati.com/wp-content/uploads/2020/09/Can-a-psychologist-help-990x558.png',
		link: 'https://blog.conrati.com/can-an-online-psychologist-help/',
		title: 'Can an online psychologist help?',
		content:
      'There is growing evidence of the global impact of mental health. The burden of mental health disorders on health...',
	},
	{
		id: 2,
		img:
      'https://blog.conrati.com/wp-content/uploads/2021/01/pexels-cottonbro-4101137-4-scaled-990x558.jpg',
		link: 'https://blog.conrati.com/psychology-and-its-facets/',
		title: 'Psychology and its facets',
		content:
      'Psychology is defined as “the scientific study of the human mind and its functions, especially those affecting...',
	},
	{
		id: 3,
		img:
      'https://blog.conrati.com/wp-content/uploads/2021/01/pexels-cottonbro-4101137-4-scaled-990x558.jpg',
		link: 'https://blog.conrati.com/psychology-and-its-facets/',
		title: 'Psychology and its facets',
		content:
      'Psychology is defined as “the scientific study of the human mind and its functions, especially those affecting...',
	},
];

export const testimonials = [
	{
		id: 1,
		name: 'Robin Berry',
		comment:
      'Thanks for a new marketplace. Great to have a platform to be in contact with therapists!.',
		star: 5,
	},
	{
		id: 2,
		name: 'Clive Simpson',
		comment:
      'I was suffering from anxiety and depression during Covid times. Desperate for help, I found a great psychologist who at a distance was extremely helpful. She really helped give me the tools I needed to deal with my problems.',
		star: 5,
	},
	{
		id: 3,
		name: 'Olivia Wood',
		comment:
      'I have such wonderful support now. I look forward to my sessions and have reframed my thoughts, goals and my potential. I have a kind, and caring therapist who listens intently. Our sessions are a safe and welcoming space that I have truly treasured.',
		star: 5,
	},
	{
		id: 4,
		name: 'Jessica Wiggins',
		comment:
      'Amazing platform - it certainly beats the user interface and design of your competitors so well done on that front.',
		star: 5,
	},
];

export const consultingTherapyCategories = [
	'Depression',
	'Parenting',
	'Learning Disabilities',
	'Anxiety & Stress',
	'Child Psychology',
	'Eating Disorders',
	'Grief',
	'Anger Management',
	'Family Conflict',
	'Trauma',
	'Burnout',
	'Abuse',
	'Relationships',
	'Mood Disorders',
	'Panic Attacks',
];

export const therapyBenefits = [
	{
		id: 1,
		img: freeImg,
		title: 'First-time free sessions',
		subTitle:
			'Many therapists offer 30 minute bookings for free to familiarise yourself',
	},
	{
		id: 2,
		img: therapyImg,
		title: 'Therapy from the comfort of your home',
		subTitle:
			'Video conference technology allows for calls from the comfort/safety of your home',
	},
	{
		id: 3,
		img: findImg,
		title: 'Find and compare therapists',
		subTitle:
			'Therapists across different areas of specialisation and price',
	},
	{
		id: 4,
		img: scheduleImg,
		title: 'Schedule an online booking instantly',
		subTitle: 'View therapists availability and schedule your booking',
	},
	{
		id: 5,
		img: payImg,
		title: 'Pay only after a successful consultation',
		subTitle: 'Only pay on the basis that your consultation was successful',
	},
];

export const therapistProfileUrl =
	'?&rate=&category=&skills=psychologist,industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor,depression,depression%20and%20anxiety,anxiety%20counselling,mental%20health%20support,psychotherapy,addiction,counselling%20for%20trauma,family%20therapy&languages=&free=false';

