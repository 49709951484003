import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.css';

class EditDob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dd: '',
      mm: '',
      yyyy: '',
    };

    this.date = null;
    this.month = null;
    this.year = null;
  }

  componentDidMount() {
    const { value } = this.props;
    const dob = value.split('/');

    if (dob.length === 3) {
      this.setState({
        dd: dob[2],
        mm: dob[1],
        yyyy: dob[0],
      });
    }
  }

  onChangeInput = async (e) => {
    const { onChange } = this.props;
    if (e.target.name === 'dd') {
      if (e.target.value > 31) {
        return false;
      }

      if (e.target.value.length === 2) {
        this.month.focus();
      }
    }

    if (e.target.name === 'mm') {
      if (e.target.value > 12) {
        return false;
      }

      if (e.target.value.length === 2) {
        this.year.focus();
      }
    }

    if (e.target.name === 'yyyy' && e.target.value > moment().year()) {
      return false;
    }

    await this.setState({
      [e.target.name]: e.target.value,
    });

    const { dd, mm, yyyy } = this.state;

    if (dd.length === 2 && mm.length === 2 && yyyy.length === 4) {
      onChange({
        dd,
        mm,
        yyyy,
      });
    }

    return true;
  };

  render() {
    const { label, required, disabled } = this.props;
    const { dd, mm, yyyy } = this.state;

    return (
      <div className={styles.edate}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        <div className={styles.dgroup}>
          <input
            className={styles.sdate}
            ref={(e) => {
              this.date = e;
            }}
            name="dd"
            type="text"
            value={dd}
            placeholder="DD"
            onChange={this.onChangeInput}
            maxLength={2}
            disabled={disabled}
          />
          <input
            className={styles.sdate}
            ref={(e) => {
              this.month = e;
            }}
            name="mm"
            type="text"
            value={mm}
            placeholder="MM"
            onChange={this.onChangeInput}
            maxLength={2}
            disabled={disabled}
          />
          <input
            className={styles.sdate}
            ref={(e) => {
              this.year = e;
            }}
            name="yyyy"
            type="text"
            value={yyyy}
            placeholder="YYYY"
            onChange={this.onChangeInput}
            maxLength={4}
            disabled={disabled}
          />
        </div>
        {required && (!mm || !dd || yyyy.length < 4) && (
          <p className={styles.error}>{label} Required</p>
        )}
      </div>
    );
  }
}

EditDob.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

EditDob.defaultProps = {
  value: '',
  disabled: false,
};

export default EditDob;
