import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import style from './style.module.scss';
import secondaryLogo from '../../assets/conrati-logo-secondary.png';
import Headers from '../../../components/Headers/Headers';

function Header() {
  const userState = useSelector((state) => state.userState);
  const { isLoggedIn } = userState;

  return (
    <header className={style.header}>
      <div className={style.content}>
        {isLoggedIn ? (
          <Headers />
        ) : (
          <>
            <div className={style.logo}>
              <Link to="/">
                <img src={secondaryLogo} alt="Conrati Logo" />
              </Link>
            </div>
            <div className={style.links}>
              <a href="/signin" className={cx(style.button, style.sigin)}>
                Sign In
              </a>
              <a href="/register" className={cx(style.button, style.register)}>
                Register
              </a>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
