import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PaymentFAQs.module.css';
import { paymentFAQData } from './paymentFAQData';
import Collapse from '../../Common/collapse/collapse';

const PaymentFAQs = props => {
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		const variant = props.variant?.toLowerCase();

		if (variant === 'o' || variant === 't' || variant === 's' || variant === 'x') {
			setQuestions(paymentFAQData.variantFirstOption);
		}

		if (variant === 'm' || variant === 'l') {
			setQuestions(paymentFAQData.variantSecondOption);
		}

		if (variant === 'n' || variant === 'u') {
			setQuestions(paymentFAQData.variantThirdOption);
		}
	}, [props.variant]);

	return (
		<>
			{questions.length > 0 && (
				<div className={styles.faqContainer}>
					<div className={styles.faqTitle}>FREQUENTLY ASKED QUESTIONS</div>
					<div>
						{questions.map((question, index) => {
							return (
								<div key={`question-${index}`} className={styles.questionContainer}>
									<Collapse
										title={question.title}
										titleClassName={styles.questionTitle}
										contentClassName={styles.questionContent}
										content={question.details}
										expand={index === 0}
									/>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

PaymentFAQs.propTypes = {
	variant: PropTypes.string.isRequired,
};

export default PaymentFAQs;
