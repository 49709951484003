import React from 'react';
import styles from './UserHead.module.css';
import usernull from '../../Common/assets/usernull.svg';
import background from '../../Common/assets/blue-large.svg';

const UserHead = (props) => {
  let { firstName, lastName, primaryImg, email } = props;
  return (
    <div style={{ backgroundImage: `url(${background})` }} className={styles.userhead}>
      <p className={styles.name}>{firstName + ' ' + lastName}</p>
      <p className={styles.compname}>{email}</p>
      <img
        className={styles.pimg}
        src={primaryImg ? primaryImg : usernull}
        alt="User"
        height="112px"
        width="112px"
      />
    </div>
  );
};

export default UserHead;
