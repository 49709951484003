import { ApiService } from '../api-service';
import MangopayKit from 'mangopay-cardregistration-js-kit';
import { getMangopayBaseUrl, getMangopayClientId } from './config';

interface PregistrationCard {
	mango_pay_id: string;
	currency: string;
}

interface CardRegistrationData {
	id: string;
	tag?: null;
	creationDate: number;
	userId: string;
	accessKey: string;
	preregistrationData: string;
	registrationData?: null;
	cardId?: null;
	cardType: string;
	cardRegistrationUrl: string;
	resultCode?: null;
	resultMessage?: null;
	currency: string;
	status: string;
}

interface RegisterCard {
	cardNumber: string;
	cardExpirationDate: string;
	cardCvx: string;
	cardType?: string;
}

export interface Card {
	Id: string;
	Tag?: null;
	CreationDate: number;
	UserId: string;
	AccessKey: string;
	PreregistrationData: string;
	RegistrationData: string;
	CardId: string;
	CardType: string;
	CardRegistrationURL: string;
	ResultCode: string;
	ResultMessage: string;
	Currency: string;
	Status: string;
}

export interface UserCard {
	nameOnCard: string;
	active: boolean;
	alias: string;
	bankCode: string;
	cardProvider: string;
	cardType: string;
	country: string;
	creationDate: number;
	currency: string;
	expirationDate: string;
	fingerprint: string;
	id: string;
	product: string;
	tag: null;
	userId: string;
	validity: string;
}

interface MangoPayCardAddError {
	ResultCode?: string;
	ResultMessage?: string;
}

async function createPregistrationCard(
	registrationCard: PregistrationCard
): Promise<CardRegistrationData> {
	const endpoint = '/v1/payment/preauthorize';

	const pregistrationCardData = await ApiService.ApiRequest<
		[CardRegistrationData]
	>('POST', endpoint, registrationCard);

	return pregistrationCardData[0];
}

// Register card mangopay
function registerCardMangopay(registerCard: RegisterCard): Promise<Card> {
	const { cardNumber, cardExpirationDate, cardCvx, cardType } = registerCard;

	return new Promise((resolve, reject) => {
		MangopayKit.cardRegistration.registerCard(
			{
				cardNumber,
				cardExpirationDate,
				cardCvx,
				cardType: cardType || 'CB_VISA_MASTERCARD',
			},
			(res: Card | PromiseLike<Card>) => {
				return resolve(res);
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(err: any) => {
				return reject(err);
			}
		);
	});
}

/*
	Add new card
*/
async function addNewCard(
	registrationCard: PregistrationCard,
	registerCard: RegisterCard
): Promise<Card> {
	const preRegisterCard = await createPregistrationCard(registrationCard);

	MangopayKit.cardRegistration.clientId = getMangopayClientId();
	MangopayKit.cardRegistration.baseURL = getMangopayBaseUrl();

	await MangopayKit.cardRegistration.init({
		cardRegistrationURL: preRegisterCard.cardRegistrationUrl,
		preregistrationData: preRegisterCard.preregistrationData,
		accessKey: preRegisterCard.accessKey,
		Id: preRegisterCard.id,
	});

	registerCard.cardType = preRegisterCard.cardType;

	const card = await registerCardMangopay(registerCard);

	console.log('card', card);

	if (MangopayKit.cardRegistration.reset) {
		MangopayKit.cardRegistration.reset();
	}

	return card;
}

function formatMangopayErrorMessage(error: MangoPayCardAddError): string {
	if (error.ResultCode && error.ResultMessage) {
		const words = error.ResultMessage.split('_');
		const readableFormat = words
			.map(
				(word) =>
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
			)
			.join(' ');
		return readableFormat;
	}

	return 'Something went wrong in adding card';
}

export {
	createPregistrationCard,
	addNewCard,
	registerCardMangopay,
	formatMangopayErrorMessage,
};

export type { PregistrationCard, RegisterCard, MangoPayCardAddError };
