import React from 'react';
import PropTypes from 'prop-types';
import {
  initalTransformSize,
  testominals,
  benifitTitle,
  benefitsList,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderSubTitle,
  sliderTitle,
  sliderUserList,
  themeTitle,
} from './data';
import LandingPageTheme from '../Theme';

function Consultant({ history }) {
  const data = {
    initalTransformSize,
    testominals,
    benifitTitle,
    benefitsList,
    buttonText,
    userCardPlaceholder,
    blogTitle,
    blogsList,
    popularItemsList,
  };

  const sliderData = {
    sliderSubTitle,
    sliderTitle,
    sliderUserList,
    popularItemsList,
    themeTitle,
  };

  return <LandingPageTheme data={data} sliderData={sliderData} history={history} />;
}

Consultant.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
export default Consultant;
