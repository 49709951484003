import React, { Component } from 'react';
import styles from '../SessionCall/SessionCall.module.css';
import timeicon from '../../Common/assets/time.svg';
import video_open from '../../Common/assets/video_open.svg';
import mic_open from '../../Common/assets/mic_open.svg';
//import volume_open from '../../Common/assets/volume_open.svg'
import mic_closed from '../../Common/assets/video_mic_off_over.svg';
import video_closed from '../../Common/assets/video_disabled.svg';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

let count = 0;
let warningcount = 0;

class toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mic: true,
      video: true,
      from: '',
      interval_id: '',
      duration: '',
      to: '',
      intervelset: false,
      durationdiff: '',
    };

    this.handleToolbarBtn = this.handleToolbarBtn.bind(this);
    this.timeCounter = this.timeCounter.bind(this);
    this.notify = this.notify.bind(this);
    this.warning = this.warning.bind(this);
  }

  toastId = null;

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.activeBooking) {
      if (!this.state.intervelset) {
        let interval_id = setInterval(this.timeCounter, 1000);
        this.setState({ interval_id: interval_id, intervelset: true });
      }

      this.setState({
        from: moment.unix(newProps.activeBooking.from),
        to: moment.unix(newProps.activeBooking.to),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval_id);
  }

  handleToolbarBtn(e, type) {
    e.preventDefault();
    if (type === 'mic') {
      this.setState({ mic: !this.state.mic });
    }

    if (type === 'video') {
      this.setState({ video: !this.state.video });
    }

    this.props.handleToolBarBtn(type);
  }

  timeCounter() {
    if (this.state.from) {
      let durationdiff = this.state.to.diff(moment());

      let duration = moment.duration(this.state.from.diff(moment()));
      this.setState({ duration: duration, durationdiff: durationdiff });

      let remaining_duration = Number(
        moment.duration(this.state.to.diff(moment())).asMinutes()
      ).toFixed(1);
      let remaining_duration_seconds;

      if (remaining_duration < 5) {
        if (count === 0) {
          this.notify();
        }
      }

      if (remaining_duration < 1) {
        remaining_duration_seconds = moment.duration(this.state.to.diff(moment())).seconds();
        this.warning('start', remaining_duration_seconds);
      }

      if (remaining_duration < 0.1) {
        this.warning('stop');
      }
    }
  }

  notify() {
    if (count < 1) {
      toast.warn(`You have 5 minutes remaining`, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

    return count++;
  }

  warning(status, seconds) {
    if (status === 'start') {
      if (warningcount === 0) {
        this.toastId = toast.warn('You have 1 minute remaining... ', {
          position: 'bottom-center',
          hideProgressBar: true,
          autoClose: false,
        });
      }

      toast.update(this.toastId, {
        render: `You have ${seconds} seconds remaining.`,
        autoClose: false,
      });

      warningcount++;
    }

    if (status === 'stop') {
      toast.update(this.toastId, {
        render: 'Times up....',
        autoClose: 2000,
      });

      this.props.onTimeUp();

      return clearInterval(this.state.interval_id);
    }
  }

  render() {
    return (
      <div className={styles.toolbar}>
        <div className={styles.pfirst}>
          {this.state.duration && (
            <div className={styles.timestamp}>
              <img src={timeicon} alt="time" width="100%" height="100%" />
              <p className={styles.time}>
                {moment.utc(this.state.durationdiff).format('HH:mm:ss')}
              </p>
            </div>
          )}
          {/* <div className={styles.pname}>
                        <img src={talking} alt="talking" width="16px" height="16px"/>
                        <p>{name ? name : ''}</p>
                    </div> */}
        </div>
        <div className={styles.pbtn}>
          <div className={styles.pico} onClick={(e) => this.handleToolbarBtn(e, 'video')}>
            <img
              data-tip={this.state.video ? 'Disable Video' : 'Enable Video'}
              src={this.state.video ? video_open : video_closed}
              alt="camera"
            />
          </div>
          <div className={styles.pico} onClick={(e) => this.handleToolbarBtn(e, 'mic')}>
            <img
              data-tip={this.state.mic ? 'Mute' : 'Unmute'}
              src={this.state.mic ? mic_open : mic_closed}
              alt="mic"
            />
          </div>
        </div>

        <ReactTooltip place="top" className={styles.customeTheme} effect="solid" />
      </div>
    );
  }
}

export default toolbar;
