/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './Newonboarding.module.css';
import yourdetails from '../../../Common/assets/your_details.svg';
import EditTooltip from '../../../Common/EditTooltip/EditTooltip';
import EditInput from '../../../Common/EditInput/EditInput';
import EditSelectMulti from '../../../Common/EditSelectMulti/EditSelectMulti';
import EditDate from '../../../Common/EditDate/EditDate';
import EditSelect from '../../../Common/EditSelect/EditSelect';
import EditTextArea from '../../../Common/EditTextArea/EditTextArea';
import enums from '../../../../config/enum';
import { editProfile } from '../../../../services/userService';
import messages from '../../../../config/message';
import { alert } from '../../../../utils';
import EditNumber from '../../../Common/EditNumber';
import EditInputMobile from '../../../Common/EditInputMobile/EditInputMobile';
import { DateTime } from 'luxon';

class PersonalDetailsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hourlyRate: 0,
      languages: [],
      skills: [],
      selectactive: false,
      selectedLanguages: [],
      selectedSkills: [],
      firstName: '',
      lastName: '',
      headline: '',
      serviceDescription: '',
      country: {},
      currency: {},
      currencyCode: '',
      countryCode: '',
      dateOfBirth: '',
      mobileNumber: '49',
      mobileCode: '49',
      countryCodeIso: 'DE',
    };
  }

  componentDidMount() {
    const { user, ipCountry, ipCurrency } = this.props;
    const {
      hourlyRate,
      languages,
      firstName,
      lastName,
      headline,
      skills,
      dateOfBirth,
      serviceDescription,
      countryId,
      country,
      currency,
      currencyId,
      mobileNumber,
      mobileCode,
    } = user;

    const selectedLanguages = languages
      ? languages.map((language) => ({
          value: language.languageId,
          label: language.language,
        }))
      : [];

    const selectedSkills = skills
      ? skills.map((skill, index) => ({ value: index, label: skill }))
      : [];

    this.setState({
      hourlyRate: hourlyRate || 0,
      selectedLanguages,
      selectedSkills,
      skills: skills || [],
      languages: languages || {},
      firstName: firstName || '',
      lastName: lastName || '',
      headline: headline || '',
      currencyCode: currencyId,
      countryCode: countryId,
      dateOfBirth: dateOfBirth || '',
      serviceDescription: serviceDescription || '',
      country: countryId > 0 ? country : ipCountry,
      currency: currencyId > 0 ? currency : ipCurrency,
      countryCodeIso: country && country.countryCode ? country.countryCode : ipCountry.countryCode,
      mobileNumber,
      mobileCode,
    });

    this.onOpenEditScreen();
  }

  componentDidUpdate(prevprops) {
    const { requiredStep } = this.props;
    if (prevprops.requiredStep !== requiredStep) {
      if (requiredStep !== enums.onboardingStep.PersonalDetails) {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onOpenEditScreen = () => {
    const { open } = this.props;
    if (open) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  onTooltipClick = () => {
    const { completedStep, onTooltipClick, onAlert, open, required } = this.props;
    if (completedStep > 0) {
      if (!open && !required) {
        onTooltipClick(enums.onboardingStep.PersonalDetails);
        document.addEventListener('click', this.handleOutsideClick, false);
      }
    } else {
      onAlert();
    }
  };

  handleOutsideClick = (e) => {
    const { selectactive } = this.state;
    if (selectactive) {
      return;
    }

    if (
      (this.editelem && this.editelem.contains(e.target)) ||
      e.target.dataset.countryCode ||
      (e.target.parentElement?.dataset?.countryCode)
    ) {
      return;
    }

    this.onSubmitData();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectChange = (selectedOption, name) => {
    const { languages, categories } = this.props;
    if (name === 'languages') {
      const lang = [];

      if (selectedOption) {
        selectedOption.forEach((option) => {
          lang.push(languages.find((language) => language.languageId === option.value));
        });
      }

      this.setState({ languages: lang, selectedLanguages: selectedOption });
    }

    if (name === 'categories') {
      const cat = [];

      if (selectedOption) {
        selectedOption.forEach((option) => {
          cat.push(categories.find((category) => category.categoryId === option.value));
        });
      }
    }

    if (name === 'skills') {
      let skills = [];

      if (selectedOption) {
        if (selectedOption !== null && selectedOption.length > 10) {
          return true;
        }

        skills = selectedOption
          .filter((option) => option.label && option.label.length > 0)
          .map((option) => {
            option.label = option.label.replace(/[,.]/gi, '');
            option.label = option.label.trim();
            return option.label.toLowerCase();
          });
      }

      const selectedSkills = skills.map((skill, index) => ({
        value: index,
        label: skill,
      }));

      this.setState({
        skills,
        selectedSkills,
      });
    }

    if (name === 'country') {
      this.setState({
        countryCode: selectedOption.value,
        country: {
          countryId: selectedOption.value,
          countryCode: selectedOption.code,
          countryName: selectedOption.label,
        },
        countryCodeIso: selectedOption.code,
      });

      this.selectCurrency(selectedOption.currencyCode);
    }

    if (name === 'currency') {
      this.setState({
        currencyCode: selectedOption.value,
        currency: {
          currencyId: selectedOption.value,
          currencyName: selectedOption.label,
          currencyCode: selectedOption.code,
          currencySymbol: selectedOption.symbol,
        },
      });
    }

    return true;
  };

  selectCurrency = (code) => {
    const { currenciesMasterdata } = this.props;
    const selectedOption = currenciesMasterdata.find((currency) => currency.code === code);
    this.setState({
      currencyCode: selectedOption.value,
      currency: {
        currencyId: selectedOption.value,
        currencyName: selectedOption.label,
        currencyCode: selectedOption.code,
        currencySymbol: selectedOption.symbol,
      },
    });
  };

  onSelActive = (type) => {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  };

  onChangeMobile = (e, value) => {
    this.setState({
      mobileNumber: e,
      mobileCode: value.dialCode,
    });
  };

  onSubmitData = () => {
    const {
      hourlyRate,
      languages,
      firstName,
      lastName,
      headline,
      dateOfBirth,
      serviceDescription,
      countryCode,
      currencyCode,
      skills,
      currency,
      country,
      mobileCode,
      mobileNumber,
    } = this.state;

    const cleanServiceDescription = serviceDescription.trim().replace(/\s+/g, ' ');

    const userStateServer = {
      first_name: firstName,
      last_name: lastName,
      headline,
      date_of_birth: dateOfBirth,
      service_description: cleanServiceDescription,
      currency_id: currencyCode === 0 ? currency.currencyId : currencyCode,
      hourly_rate: Math.trunc(hourlyRate),
      skills,
      languages,
      country_id: countryCode === 0 ? country.countryId : countryCode,
      personal_details_completed: true,
      video_bio: 'https://www.youtube.com/watch?v=o0X_xJU7LUE',
      registration_steps_completed: enums.profileCompleted.personaldetails,
      time_zone: DateTime.local().zoneName,
      registrationComplete1: true,
      currency_code: currency.currencyCode,
      mobile_code: mobileCode,
      mobile_number: mobileNumber,
    };

    if (
      languages.length > 0 &&
      headline &&
      firstName &&
      lastName &&
      hourlyRate > 0 &&
      dateOfBirth &&
      cleanServiceDescription &&
      cleanServiceDescription.length >= 300 &&
      skills.length >= 4 &&
      skills.length <= 10 &&
      userStateServer.country_id > 0 &&
      mobileNumber
    ) {
      let toastId = null;

      toastId = toast.warn('Updating personal details..', {
        position: 'top-center',
        hideProgressBar: true,
      });
      const { onRequired, onCompleteEditing, onEditProfile, user } = this.props;

      editProfile(userStateServer)
        .then((response) => {
          if (!user.personalDetailsCompleted) {
            ReactGA.pageview('/myprofile/ap/personal_details');
          }

          onRequired(false);
          onCompleteEditing(enums.onboardingStep.PersonalDetails);

          toast.update(toastId, {
            render: 'Personal details updated',
            autoClose: 1000,
          });

          onEditProfile(response.result);

          document.removeEventListener('click', this.handleOutsideClick, false);
        })
        .catch(() => {
          toast.update(toastId, {
            render: 'You are missing something. Please recheck your details and submit.',
            autoClose: 1000,
          });
        });
    } else {
      const { onRequired } = this.props;
      onRequired(true, enums.onboardingStep.PersonalDetails);
      alert(messages.requiredFieldsMissing);
    }
  };

  render() {
    const {
      firstName,
      lastName,
      hourlyRate,
      currency,
      languages,
      headline,
      dateOfBirth,
      serviceDescription,
      country,
      skills,
      selectedLanguages,
      selectedSkills,
      mobileCode,
      mobileNumber,
      countryCodeIso,
    } = this.state;

    const {
      languagesMasterdata,
      currenciesMasterdata,
      skillsMasterdata,
      countriesMasterdata,
      open,
      alertuser,
      user,
    } = this.props;

    const selectedCountry = country
      ? { value: country.countryId, label: country.countryName }
      : null;

    const selectedCurrency = currency
      ? {
          value: currency.currencyId,
          label: currency.currencyName,
          code: currency.currencyCode,
        }
      : null;

    return (
      <div
        className={
          open
            ? cx(styles.personal, styles.cardborder)
            : cx(styles.personal, { [styles.alertclick]: alertuser })
        }
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={yourdetails} alt="Details" />
          <p className={styles.atitle}>Personal Details</p>
          <p className={styles.rerror}>* Required</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={open} />
          </div>
        </div>
        {!open && (
          <div className={styles.acbody}>
            {firstName && lastName && hourlyRate > 0 && (
              <div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>First Name</p>
                  <p className={styles.alisub}>{firstName}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Last Name</p>
                  <p className={styles.alisub}>{lastName}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Headline</p>
                  <p className={styles.alisub}>{headline}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Date of Birth</p>
                  <p className={styles.alisub}>{dateOfBirth}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Service Description</p>
                  <p className={styles.alisub}>{serviceDescription}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Currency</p>
                  <p className={styles.alisub}>
                    {currency ? `${currency.currencyName} (${currency.currencySymbol})` : ''}
                  </p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Hourly Rate</p>
                  <p className={styles.alisub}>
                    {hourlyRate && currency
                      ? `${currency.currencySymbol} ${Math.trunc(hourlyRate)}`
                      : Math.trunc(hourlyRate)}
                  </p>
                </div>
                {/* <div className={styles.alitem}>
                  <p className={styles.alitit}>Category</p>
                  <ul className={styles.ucategory}>
                    {categories
                      ? categories.map((category, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index} className={styles.lilistitem}>
                            {category.categoryName}
                          </li>
                        ))
                      : ''}
                  </ul>
                </div> */}
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Skills</p>
                  <ul className={styles.ucategory}>
                    {skills
                      ? skills.map((skill, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index} className={styles.lilistitem}>
                            {skill}
                          </li>
                        ))
                      : ''}
                  </ul>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Languages</p>
                  <ul className={styles.ucategory}>
                    {languages &&
                      languages.map((language, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className={styles.lilistitem}>
                          {language.language}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>Country of Residence</p>
                  <p className={styles.alisub}>{country ? country.countryName : ''}</p>
                </div>
                <div className={styles.alitem}>
                  <p className={styles.alitit}>
                    Mobile Number (We only call when booking is unconfirmed. Clients can't see this)
                  </p>
                  <p className={styles.alisub}>+{mobileCode + ' ' + mobileNumber}</p>
                </div>
              </div>
            )}
          </div>
        )}
        {open && (
          <div className={styles.acedit}>
            <div className={styles.row}>
              <div className={styles.column}>
                <EditInput
                  htmlFor="First Name"
                  label="First Name"
                  value={firstName}
                  placeholder="First Name"
                  onChange={this.onChange}
                  name="firstName"
                  required
                />
              </div>
              <div className={styles.column}>
                <EditInput
                  htmlFor="Last Name"
                  label="Last Name"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={this.onChange}
                  name="lastName"
                  required
                />
              </div>
            </div>
            <EditInput
              htmlFor="Headline"
              label="Headline or Job title"
              value={headline}
              placeholder="Headline"
              onChange={this.onChange}
              name="headline"
              required
              maxLength={120}
            />
            <EditDate
              label="Date of Birth"
              date={dateOfBirth}
              onChange={this.onChange}
              name="dateOfBirth"
              required
            />
            <EditTextArea
              htmlFor="Service Description"
              label="Service Description"
              value={serviceDescription}
              placeholder="SERVICE DESCRIPTION"
              onChange={this.onChange}
              name="serviceDescription"
              required
              notice="Service description should be a minimum of 300 characters to max 1000 characters."
              maxLength={1000}
              minLength={300}
            />
            <EditSelect
              label="Country"
              selected={selectedCountry}
              options={countriesMasterdata}
              placeholder="Select Country"
              onChange={(e) => this.onSelectChange(e, 'country')}
              onActive={this.onSelActive}
              required
              isDisabled={user.countryId > 0}
            />
            <div className={styles.row}>
              <div className={styles.column}>
                <EditSelect
                  label="Currency"
                  selected={selectedCurrency}
                  options={currenciesMasterdata}
                  placeholder="Select Currency"
                  onChange={(e) => this.onSelectChange(e, 'currency')}
                  onActive={this.onSelActive}
                  required
                  isDisabled={user.currencyId > 0 || currency.currencyId > 0}
                />
              </div>
              <div className={styles.column}>
                <EditNumber
                  htmlFor="Hourly Rate"
                  label={`Hourly Rate (${currency.currencyCode})`}
                  value={hourlyRate}
                  placeholder="Hourly Rate"
                  onChange={this.onChange}
                  name="hourlyRate"
                  type="number"
                  required
                />
              </div>
            </div>
            {/* <EditSelectMulti
              label="Categories"
              selected={selectedCategories}
              selecttype="fixed"
              options={categoriesMasterdata}
              placeholder="Select Category"
              onChange={e => this.onSelectChange(e, 'categories')}
              onActive={this.onSelActive}
              required
            /> */}
            <EditSelectMulti
              label="Skills"
              options={skillsMasterdata}
              selected={selectedSkills}
              placeholder="Type a skill then hit enter (e.g: business mentor)"
              onChange={(e) => this.onSelectChange(e, 'skills')}
              onActive={this.onSelActive}
              required
              maxWordLimit
              note="Min 4 Skills are required and you can add Max 10 Skills."
            />
            <EditSelectMulti
              label="Languages"
              selected={selectedLanguages}
              selecttype="fixed"
              options={languagesMasterdata}
              placeholder="Add Language"
              onChange={(e) => this.onSelectChange(e, 'languages')}
              onActive={this.onSelActive}
              required
            />
            <EditInputMobile
              type="tel"
              countryCode={countryCodeIso}
              htmlFor="Mobile Number "
              label="Mobile Number (We only call when booking is unconfirmed. Clients can't see this)"
              value={mobileNumber}
              placeholder="Mobile Number"
              onChange={this.onChangeMobile}
              name="mobileNumber"
              required
            />
          </div>
        )}
      </div>
    );
  }
}

PersonalDetailsNew.propTypes = {
  user: PropTypes.shape({
    hourlyRate: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    headline: PropTypes.string,
    currency: PropTypes.shape({
      currencyCode: PropTypes.string,
      currencyName: PropTypes.string,
      currencySymbol: PropTypes.string,
    }),
    dateOfBirth: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf(PropTypes.string),
    serviceDescription: PropTypes.string,
    countryId: PropTypes.number,
    country: PropTypes.shape({}),
    personalDetailsCompleted: PropTypes.bool.isRequired,
    currencyId: PropTypes.number.isRequired,
  }).isRequired,
  requiredStep: PropTypes.number,
  completedStep: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  alertuser: PropTypes.bool.isRequired,
  onTooltipClick: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRequired: PropTypes.func.isRequired,
  onCompleteEditing: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  currenciesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  countriesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  languagesMasterdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  ipCountry: PropTypes.shape({}).isRequired,
  ipCurrency: PropTypes.shape({}).isRequired,
};

PersonalDetailsNew.defaultProps = {
  requiredStep: 0,
};

export default PersonalDetailsNew;
