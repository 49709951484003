import React, { useState } from 'react';
import styles from './styles.module.scss';
import clockIcon from '../../assets/clock.svg';
import addCardIcon from '../../assets/add-card.svg';
import { PaymentCard } from './payment-card';
import { RegisterCard, UserCard } from '@conrati/utils';
import { NativeModal } from '../modal';
import { AddCard } from './add-card';
import { useTranslation } from 'react-i18next';

interface PaymentCardsProps {
	paymentCards: UserCard[];
	addCard: (cardDetails: RegisterCard) => Promise<void>;
	onSelectCard?: (cardId: string) => void;
	currency?: string;
}

export const PaymentCards = (props: PaymentCardsProps): JSX.Element => {
	const { paymentCards, addCard, onSelectCard, currency } = props;

	const { t } = useTranslation();
	const [selectedCardId, setSelectedCardId] = useState<string>('');
	const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);

	const selectCard = (card: UserCard): void => {
		setSelectedCardId(card.id);

		if (onSelectCard) {
			onSelectCard(card.id);
		}
	};

	return (
		<div
			className={styles.paymentCardsContainer}
			data-testid="ui-native-payment-cards-container"
		>
			<div
				className={styles.paymentCardsTitleContainer}
				data-testid="ui-native-payment-cards-title-container"
			>
				<div
					className={styles.paymentCardsTitle}
					data-testid="ui-native-payment-cards-title"
				>
					<div
						className={styles.paymentCardsTitleClock}
						data-testid="ui-native-payment-cards-title-clock"
					>
						<img
							src={clockIcon}
							data-testid="ui-native-payment-cards-clock-icon"
							alt="Clock Icon"
						/>
					</div>
					<span
						className={styles.paymentCardsTitleText}
						data-testid="ui-native-payment-cards-title-text"
					>
						{t('nativeUI.paymentCards.title')}
					</span>
				</div>
			</div>

			{paymentCards.length > 0 && (
				<div
					className={styles.paymentCardsList}
					data-testid="ui-native-payment-cards-list"
				>
					
					{currency && paymentCards.filter((card) => card.currency === currency).map((paymentCard, index) => (
						<PaymentCard
							selectedCardId={selectedCardId}
							card={paymentCard}
							key={`payment-method-${index}`}
							onMarkActiveCard={(card): void => selectCard(card)}
						/>
					))}
				</div>
			)}

			<div
				className={`${styles.paymentCardsFooterContainer} ${
					paymentCards.length === 0 ? styles.emptyPaymentCards : ''
				}`}
				data-testid="ui-native-payment-cards-footer-container"
			>
				<button
					type="button"
					className={styles.paymentCardsFooter}
					data-testid="ui-native-payment-cards-add-btn"
					onClick={(): void => setShowAddCardModal(true)}
				>
					<div
						className={styles.paymentCardsAddIcon}
						data-testid="ui-native-payment-cards-add-icon-container"
					>
						<img
							data-testid="ui-native-payment-cards-add-btn-icon"
							src={addCardIcon}
							alt="Add Icon"
						/>
					</div>
					<span
						className={styles.paymentCardsFooterText}
						data-testid="ui-native-payment-cards-add-btn-text"
					>
						{t('nativeUI.paymentCards.addNewCard')}
					</span>
				</button>
			</div>

			<NativeModal isOpen={showAddCardModal}>
				<AddCard
					onClose={(): void => setShowAddCardModal(false)}
					addCard={addCard}
				/>
			</NativeModal>
		</div>
	);
};

PaymentCards.displayName = 'Payment Cards';
