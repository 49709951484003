/* eslint-disable no-plusplus */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import searchico from '../../Common/assets/search.1.svg';
import ProfileCard from '../../Home/ProfileCard/ProfileCard';
import arrowico from '../../Common/assets/arrow_gray_white.svg';
import { fetchRandomProfiles } from '../../../actions/profileAction';
import logoYellow from '../../Common/assets/logo-yellow.png';
import star5 from '../../Common/assets/5star.svg';
import arrow from '../../Common/assets/arrowWhite.svg';
import testimonialCircleR from '../../Common/assets/testimonialCircleR.svg';
import testimonialCircleL from '../../Common/assets/testimonialCircleL.svg';
import Slider from './slider';
import { checkIsMobileBrowser, isDivisibleByThree } from '../../../utils';
import { fetchBlogPosts } from '../../../services/blogService';
import Headers from '../../Headers/Headers';

class LandingPageTheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transformSize: -20,
      search: '',
      blogData: [],
      isMobileDevice: checkIsMobileBrowser(navigator.userAgent),
    };
  }

  componentDidMount() {
    // Scroll page at top
    window.scroll(0, 0);

    const { onFetchRandomProfiles, currency, sliderData, data } = this.props;
    const { themeTitle } = sliderData;

    const { blogCategoryId } = data;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    if (blogCategoryId) {
      fetchBlogPosts(blogCategoryId).then((res) => this.setState({ blogData: res }));
    }

    onFetchRandomProfiles(reqData, themeTitle.toLowerCase(), 6);
  }

  handleBtnClick = (type) => {
    const { data } = this.props;
    const { testominals, initalTransformSize } = data;
    const { transformSize } = this.state;
    const divWidth = 537;
    const maxTransformSize = -((testominals.length * divWidth) / 2 + -initalTransformSize);

    if (type === 'next') {
      const nextTransformSize = transformSize - divWidth;
      if (nextTransformSize >= maxTransformSize) {
        return this.setState({ transformSize: nextTransformSize });
      }
    }

    if (type === 'prev') {
      const nextTransformSize = transformSize + divWidth;
      if (nextTransformSize <= initalTransformSize) {
        return this.setState({ transformSize: nextTransformSize });
      }
    }

    return true;
  };

  handleKeyPress = (e) => {
    const { search } = this.state;
    const { history } = this.props;
    if (e.key === 'Enter' && search) {
      return history.push(`/search?query=${search.toLowerCase()}`);
    }

    return true;
  };

  onSearchClick = () => {
    const { search } = this.state;
    const { history } = this.props;
    if (search) {
      return history.push(`/search?query=${search.toLowerCase()}`);
    }

    return history.push('/search');
  };

  render() {
    const { themeProfiles, data, sliderData } = this.props;
    const { transformSize } = this.state;
    const {
      testominals,
      benifitTitle,
      benefitsList,
      buttonText,
      userCardPlaceholder,
      blogTitle,
      blogsList,
      popularItemsList,
      profileUrl,
      themeTitle,
    } = data;

    const { blogData, isMobileDevice } = this.state;

    return (
      <>
        <div className={styles.home}>
          {/* Navigation */}
          <div className={styles.headerContainer}>
            <Headers />
          </div>

          {/* Image Slider */}
          <Slider sliderData={sliderData} />

          {/* Search Bar */}
          <div className={styles.searchbar}>
            <input
              aria-label="Seacrh"
              type="text"
              placeholder="What skill or qualification are you looking for?"
              className={styles.inputctl}
              onChange={(e) => this.setState({ search: e.target.value })}
              onKeyPress={this.handleKeyPress}
            />
            <button type="submit" className={styles.bico} onClick={this.onSearchClick}>
              <img src={searchico} alt="search" />
            </button>
          </div>

          {/* User card Placeholder */}
          <div className={styles.benefits}>
            <div className={styles.ciaction}>
              <div className={styles.ciahead}>
                <p className={styles.pign} />
                <p className={styles.ctit}>{userCardPlaceholder}</p>
                {themeTitle === 'Psychologist' ? (
                  <Link to={`/search${profileUrl}`} className={styles.cba}>
                    Browse all <img className={styles.aic} src={arrowico} alt="" />
                  </Link>
                ) : (
                  <Link
                    to={`/search?&rate=&category=&skills=${popularItemsList
                      .toString()
                      .toLowerCase()}&languages=`}
                    className={styles.cba}
                  >
                    Browse all <img className={styles.aic} src={arrowico} alt="" />
                  </Link>
                )}
              </div>
              <div className={cx(styles.cards, isMobileDevice ? styles.mobileCards : '')}>
                <ProfileCard profiles={themeProfiles} />
              </div>
            </div>
          </div>

          {/* Benefits */}
          <div>
            <div className={styles.benefits}>
              <div className={styles.bfcontainer}>
                <p className={styles.bftitle}>{benifitTitle}</p>
                <div className={styles.bfitemlist}>
                  {benefitsList.map((benefit) => (
                    <div className={styles.bfitem} key={benefit.id}>
                      <div className={styles.bfimgcontainer}>
                        <img src={benefit.img} alt="Benefits" />
                      </div>
                      <p className={styles.bfittit}>{benefit.title}</p>
                      <p className={styles.bfitsub}>{benefit.subTitle}</p>
                    </div>
                  ))}
                </div>

                <div className={styles.findButton}>
                  {themeTitle === 'Psychologist' ? (
                    <Link to={`/search${profileUrl}`} target="_open" className={styles.abtnf}>
                      {buttonText}
                    </Link>
                  ) : (
                    <Link
                      to={`/search?&rate=&category=&skills=${popularItemsList
                        .toString()
                        .toLowerCase()}&languages=`}
                      target="_open"
                      className={styles.abtnf}
                    >
                      {buttonText}
                    </Link>
                  )}
                </div>
              </div>
            </div>

            {/* Blogs */}
            {blogData.length > 0 && (
              <div className={styles.benefits}>
                <div className={styles.bfcontainer}>
                  <p className={styles.bftitle}>{blogTitle}</p>
                  <div
                    className={
                      isDivisibleByThree(blogData.length)
                        ? styles.bfitemlist
                        : styles.bfitemlisteven
                    }
                  >
                    {blogData.map((blog) => (
                      <a href={blog.link} className={styles.blogCard} key={blog.id} target="_open">
                        <img
                          src={blog._embedded['wp:featuredmedia'][0].source_url}
                          alt={styles.blog}
                          className={styles.blogImg}
                        />
                        <div className={styles.blogCont}>
                          <p
                            className={styles.blogtitle}
                            dangerouslySetInnerHTML={{
                              __html: blog.title.rendered,
                            }}
                          />
                          <div
                            className={styles.blogsub}
                            dangerouslySetInnerHTML={{
                              __html: blog.excerpt.rendered,
                            }}
                          />
                        </div>
                        <div className={styles.rmorec}>
                          <p className={styles.rmore}>Read more</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Testimonial */}
          <div className={styles.testomonialDesktop}>
            <div className={styles.benefitsa}>
              <p className={styles.bftitle}>Testimonials</p>
              <div className={styles.wrapperTestimonial}>
                <img src={testimonialCircleL} alt="leftCircle" className={styles.leftCircle} />
                <img src={testimonialCircleR} alt="leftCircle" className={styles.rightCircle} />
                <div
                  className={styles.testimonial}
                  style={{ transform: `translateX(${transformSize}px)` }}
                >
                  {testominals.map((testimon) => (
                    <div key={testimon.id} className={styles.testContainer}>
                      <div className={styles.leftTest}>
                        <img src={star5} alt="star" />
                        <p className={styles.tname}>{testimon.name}</p>
                      </div>
                      <div className={styles.rightTest}>
                        <p className={styles.tsub}>{testimon.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.marker}>
                <div className={styles.clbuttons}>
                  <button
                    type="button"
                    className={styles.clbtn}
                    onClick={() => this.handleBtnClick('prev')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                  <button
                    type="button"
                    className={styles.clbtnr}
                    onClick={() => this.handleBtnClick('next')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.testomonialMobile}>
            <div className={styles.benefitsa}>
              <p className={styles.bftitle}>Testimonials</p>
              <div className={styles.wrapperTestimonial}>
                <img src={testimonialCircleL} alt="leftCircle" className={styles.leftCircle} />
                <img src={testimonialCircleR} alt="leftCircle" className={styles.rightCircle} />
                <div
                  className={styles.testimonial}
                  style={{ transform: `translateX(${transformSize}px)` }}
                >
                  {testominals.map((testimon) => (
                    <div key={testimon.id} className={styles.testContainer}>
                      <div className={styles.leftTest}>
                        <img src={star5} alt="star" />
                        <p className={styles.tname}>{testimon.name}</p>
                      </div>
                      <div className={styles.rightTest}>
                        <p className={styles.tsub}>{testimon.comment}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.marker}>
                <div className={styles.clbuttons}>
                  <button
                    type="button"
                    className={styles.clbtn}
                    onClick={() => this.handleBtnClick('prev')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                  <button
                    type="button"
                    className={styles.clbtnr}
                    onClick={() => this.handleBtnClick('next')}
                  >
                    <img src={arrow} alt="button" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LandingPageTheme.propTypes = {
  onFetchRandomProfiles: PropTypes.func.isRequired,
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
  }).isRequired,
  themeProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.shape({
    initalTransformSize: PropTypes.number,
    testominals: PropTypes.array,
    benifitTitle: PropTypes.string,
    benefitsList: PropTypes.array,
    buttonText: PropTypes.string,
    userCardPlaceholder: PropTypes.string,
    blogTitle: PropTypes.string,
    blogsList: PropTypes.array,
    popularItemsList: PropTypes.array,
    profileUrl: PropTypes.string,
    themeTitle: PropTypes.string.isRequired,
  }).isRequired,
  sliderData: PropTypes.shape({
    sliderSubTitle: PropTypes.string,
    sliderTitle: PropTypes.string,
    sliderUserList: PropTypes.array,
    themeTitle: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : {};
  return {
    profiles: state.profiles.profiles,
    userState: state.userState,
    currency,
    mentors: state.profiles.mentors,
    psychologists: state.profiles.psychologists,
    bconsultants: state.profiles.bconsultants,
    themeProfiles: state.profiles.themeProfiles,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchRandomProfiles: fetchRandomProfiles,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(LandingPageTheme);
