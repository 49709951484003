import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './EditTooltip.module.css';
import pencilLight from '../assets/pencil-light.svg';
import pencilDark from '../assets/pencil-dark.svg';

class EditTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseover: false,
    };
  }

  onMouseOver = (type) => {
    if (type) {
      this.setState({ mouseover: true });
    }

    if (!type) {
      this.setState({ mouseover: false });
    }
  };

  render() {
    const { active, onClick, css } = this.props;
    const { mouseover } = this.state;
    const editStyles = cx(styles.edittooltip, css);
    return (
      <div className={editStyles}>
        <div className={active ? cx(styles.tmessagea, styles.tmessage) : styles.tmessage}>
          {active
            ? 'You are in edit mode. To exit click outside of the editable area'
            : 'Edit this content'}
        </div>
        <div
          className={active ? cx(styles.toolpoitner, styles.tpactive) : styles.toolpoitner}
          onMouseEnter={() => this.onMouseOver(true)}
          onMouseLeave={() => this.onMouseOver(false)}
          onClick={(event) => onClick(event)}
          role="button"
          tabIndex="0"
          onKeyPress={() => {}}
        >
          <img
            src={mouseover || active ? pencilDark : pencilLight}
            alt="tooltip"
            height="14.8px"
            width="14.8px"
          />
        </div>
      </div>
    );
  }
}

EditTooltip.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  css: PropTypes.string,
};

EditTooltip.defaultProps = {
  active: false,
  css: '',
};

export default EditTooltip;
