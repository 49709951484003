/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import slider1img from './images/slider_1.png';
import slider2img from './images/slider_2.jpg';
import slider3img from './images/slider_3.jpg';
import slider4img from './images/slider_4.jpg';

const sliderProperties = [
  {
    id: 1,
    img: slider1img,
    link: '/search?ratefilter=free',
    text: 'Free Session',
  },
  {
    id: 2,
    img: slider4img,
    link: '/search?ratefilter=free',
    text: 'Free Session',
  },
  {
    id: 3,
    img: slider3img,
    link: '/search',
    text: 'Free Session',
  },
  {
    id: 4,
    img: slider2img,
    link: '/search',
    text: 'Free Session',
  },
];

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlider: 1,
      activeImg: slider1img,
      activeLink: '/search?ratefilter=free',
    };

    this.sliderInterval = null;
  }

  componentDidMount() {
    this.sliderInterval = setInterval(this.onChangeSliderImg, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }

  onChangeSliderImg = () => {
    const { activeSlider } = this.state;

    if (activeSlider === 3) {
      this.setState({
        activeSlider: 1,
        activeImg: slider1img,
        activeLink: '/search?ratefilter=free',
      });
    } else {
      this.setState({
        activeSlider: activeSlider + 1,
        activeImg: sliderProperties[activeSlider].img,
        activeLink: sliderProperties[activeSlider].link,
      });
    }
  };

  onCLickSliderDot = (sliderId) => {
    // Clear interval
    clearInterval(this.sliderInterval);

    const clickedSlide = sliderProperties.find((slide) => slide.id === sliderId);
    this.setState({
      activeSlider: clickedSlide.id,
      activeImg: clickedSlide.img,
      activeLink: clickedSlide.link,
    });

    // Restart interval
    this.sliderInterval = setInterval(this.onChangeSliderImg, 10000);
  };

  render() {
    const { activeSlider, activeImg, activeLink } = this.state;

    return (
      <div className={styles.slider}>
        <Link className={styles.sliderLink} to={activeLink}>
          <img src={activeImg} alt="slider" className={styles.sliderImg} />
        </Link>

        <div className={styles.sliderDots}>
          {sliderProperties.map((slide) => {
            return (
              <div
                key={slide.id}
                className={activeSlider === slide.id ? styles.dotsActive : styles.dots}
                onClick={() => this.onCLickSliderDot(slide.id)}
                role="button"
                tabIndex={0}
                onKeyPress={() => true}
                aria-label="Slide"
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default Slider;
