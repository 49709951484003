import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import StaticHeader from './StaticHeader/StaticHeader';
import aboutus from './aboutus/aboutus';
import Notfound from '../Notfound/Notfound';
import contact from './contact/contact';
import billing from './billing/billing';
import support from './support/support';
import Termsandcondtion from './termsandcondtion/Termsandcondtion';
import Privacypolicy from './privacypolicy/Privacypolicy';
import Codeofconduct from './codeofconduct/Codeofconduct';
import safety from './safety/safety';
import HowItWorks from './HowItWorks/HowItWorks';

class Static extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <>
        <StaticHeader />
        <Switch>
          <Route exact path="/company/aboutus" component={aboutus} />
          <Route exact path="/company/contact" component={contact} />
          <Route exact path="/company/costs-billing" component={billing} />
          <Route exact path="/help/how-it-works" component={HowItWorks} />
          <Route exact path="/help/faq" component={support} />
          <Route exact path="/help/trust-and-safety" component={safety} />
          <Route exact path="/legal/privacy-policy" component={Privacypolicy} />
          <Route exact path="/legal/terms-and-condtions" component={Termsandcondtion} />
          <Route exact path="/legal/code-of-conduct" component={Codeofconduct} />
          <Route exact path="/404" component={Notfound} />

          <Route path="*" component={Notfound} />
        </Switch>
      </>
    );
  }
}

export default Static;
