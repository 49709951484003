import { LOADING, SUCCESS, ERROR_R, CLEAR_ER } from '../actions/types';

const initialState = {
  loading: false,
  success: false,
  error: false,
  submit: false,
  errorObject: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        submit: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        submit: false,
      };
    case ERROR_R:
      return {
        ...state,
        loading: false,
        error: true,
        errorObject: action.payload,
      };
    case CLEAR_ER:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        errorObject: {},
      };
    default:
      return state;
  }
};
