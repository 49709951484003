/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const apiUrl = 'https://blog.conrati.com/wp-json/';
const endpoint = 'wp/v2/posts';

export const fetchBlogPosts = async (categoryId) => {
  try {
    const reqUrl = `${apiUrl + endpoint}?per_page=3&categories=${categoryId}&_embed`;
    const response = await axios.get(reqUrl);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error('Failed fetching data');
  } catch (err) {
    return Promise.reject(err);
  }
};
