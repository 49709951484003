import React from 'react';
import styles from './ResetPassword.module.css';
import reset_sucess from '../../Common/assets/reset-success.png';
import PropTypes from 'prop-types';

const ResetSucess = (props) => {
  return (
    <div>
      <div className={styles.title}>
        <p>Lost password? Let's reset it</p>
      </div>
      <div className={styles.ebox}>
        <div className={styles.emsg}>
          <p className={styles.etextrsb}>
            Please check your inbox (including your spam folder) for the email containing your
            password-reset link.
          </p>
          <p className={styles.etextrsm}>
            If you registered using LinkedIn or Google, you will not receive an email. Please sign
            in using the same LinkedIn or Google account
          </p>
        </div>
        <div className={styles.eimg}>
          <img src={reset_sucess} alt="Email sent" width="100%" height="100%" />
        </div>
      </div>
      <div className={styles.snext}>
        <div className={styles.rbtn}>
          <button className={styles.snextbtnb} onClick={props.onBack}>
            Back
          </button>
          <button className={styles.snextbtn} onClick={props.onResend}>
            Resend
          </button>
        </div>
      </div>
    </div>
  );
};

ResetSucess.propType = {
  onBack: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
};

export default ResetSucess;
