import React, { Component } from 'react';
import { toast } from 'react-toastify';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from '../Myaccount.module.css';
import bankkyc from '../../Common/assets/bank_kyc.svg';
import EditTooltip from '../../Common/EditTooltip/EditTooltip';
import EditInput from '../../Common/EditInput/EditInput';
import EditSelect from '../../Common/EditSelect/EditSelect';
import maangopayicon from '../../Common/assets/mangopay.png';
import padlock from '../../Common/assets/padlock.svg';
import paymentreceive from '../../Common/assets/payment receive.svg';
import addcard from '../../Common/assets/add_OVER.svg';
import globalProptypes from '../../../config/proptype';
import { alert } from '../../../utils';
import { onAddBankAccountGb } from '../../../services/userService';

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      selectactive: false,
      ownerName: '',
      accountNumber: '',
      address: '',
      city: '',
      postalCode: '',
      countryCode: '',
      sortCode: '',
      required: false,
    };

    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onSelActive = this.onSelActive.bind(this);
    // this.onCheck = this.onCheck.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.onSubmitData = this.onSubmitData.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const { country } = user;

    this.setState({
      countryCode: country ? country.countryCode : null,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user.country) {
      const { country } = newProps.user;
      this.setState({
        countryCode: country.countryCode ? country.countryCode : null,
      });
    }

    if (newProps.bank_account && newProps.bank_account.ownerName) {
      const { country } = newProps.user;
      const { ownerName, ownerAddress, accountNumber, sortCode } = newProps.bank_account;
      this.setState({
        ownerName,
        accountNumber,
        address: ownerAddress ? ownerAddress.addressLine1 : '',
        city: ownerAddress ? ownerAddress.city : '',
        postalCode: ownerAddress.postalCode,
        countryCode: country.countryCode ? country.countryCode : null,
        sortCode,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.handleIsBankEditOpen(false);
  }

  onTooltipClick() {
    const { user } = this.props;
    if (!user.isBusinessRegistered) {
      return toast.warn('Please add your business details first', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    const { edit, required } = this.state;

    if (required) {
      return alert('Please fill all the required details');
    }

    if (!edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }

    this.handleIsBankEditOpen(!edit);
    return this.setState({ edit: !edit });
  }

  onSelectChange(selectedOption, name) {
    if (name === 'country') {
      this.setState({ countryCode: selectedOption.value });
    }
  }

  onSelActive(type) {
    if (type === 1) {
      this.setState({ selectactive: true });
    } else {
      this.setState({ selectactive: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitData() {
    const { address, city, postalCode, accountNumber, ownerName, countryCode, sortCode } =
      this.state;

    const { user, onSyncBankAccount } = this.props;

    const bankStateServer = {
      address: {
        AddressLine1: address,
        AddressLine2: '',
        City: city,
        postalCode,
        country: countryCode,
      },
      name: ownerName,
      account_number: accountNumber,
      mangopay_id: user.mangopayUserId,
      sortcode: sortCode,
      country_code: countryCode,
    };

    user.currency_code = user.currency.currencyCode;

    let toastId = null;

    if (accountNumber && ownerName && address && city && postalCode && countryCode && sortCode) {
      toastId = toast.warn('Adding bank details..', {
        position: 'top-center',
        hideProgressBar: true,
      });

      this.setState({ required: false });
      onAddBankAccountGb(bankStateServer)
        .then((data) => {
          onSyncBankAccount(data[0]);
          document.removeEventListener('click', this.handleOutsideClick, false);
          return toast.update(toastId, {
            render: 'Bank account added successfully...',
            autoClose: 1000,
          });
        })
        .catch((err) => {
          this.setState({ required: true, edit: true });
          toast.update(toastId, {
            render: err.message || 'Adding bank details failed!',
            autoClose: 1000,
          });
        });
    } else {
      this.setState({ required: true });
    }
  }

  handleIsBankEditOpen = (open) => {
    window.localStorage.setItem('bpayOpen', open);
  };

  handleOutsideClick(e) {
    const { selectactive } = this.state;

    if (selectactive) {
      return;
    }

    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onSubmitData();
    this.onTooltipClick();
  }

  render() {
    const { user, countries } = this.props;
    const { address, city, postalCode, accountNumber, ownerName, edit, sortCode } = this.state;
    const userCountry = user.countryId;
    const { isBankDetailsRegistered } = user;

    const countriesData = countries.map((country) => ({
      value: country.countryId,
      label: country.countryName,
    }));

    const selectedCountry = userCountry
      ? countriesData.filter((arr) => {
          return arr.value === userCountry;
        })
      : null;

    return (
      <div
        className={edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        {!user.isBankDetailsRegistered && (
          <div className={styles.kycver}>
            <p className={cx(styles.kycstatus, styles.refused)}>ACTION REQUIRED</p>
          </div>
        )}
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={bankkyc} alt="Bank details" />
          <p className={styles.atitle}>Bank Account Details</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={edit} />
          </div>
        </div>

        {!isBankDetailsRegistered && !edit && (
          <div className={styles.nnotfoundcc}>
            <div className={styles.nnotfound}>
              <img src={paymentreceive} alt="Bank details" className={styles.bdetails} />
              <p className={styles.ntitle}>No Bank Details !</p>
              <p className={styles.nsub}>Add a bank account to receive payments.</p>
            </div>
            <div
              className={styles.adbusiness}
              onClick={this.onTooltipClick}
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
            >
              <p className={styles.addp}>Add your bank details</p>
              <img src={addcard} alt="Add" className={styles.add} />
            </div>
          </div>
        )}

        {isBankDetailsRegistered && !edit && (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Account Number</p>
              <p className={styles.alisub}>{accountNumber}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Name of Account Holder</p>
              <p className={styles.alisub}>{ownerName}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>SORT code</p>
              <p className={styles.alisub}>{sortCode}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Owner Address</p>
              <p className={styles.alisub}>{`${address} ${city} ${postalCode}`}</p>
            </div>
            <div className={styles.alitem}>
              <p className={styles.alitit}>Country</p>
              <p className={styles.alisub}>
                {selectedCountry.length > 0 ? selectedCountry[0].label : ''}
              </p>
            </div>
          </div>
        )}

        {edit && (
          <div className={styles.acedit}>
            <div className={styles.binfot}>
              <p>
                Please enter a bank account where you would like to receive your income directly
                after a consultantion. The bank account holder must be related to your personal or
                business details set up on Conrati
              </p>
            </div>
            <EditInput
              htmlFor="Full name of account holder"
              label="Full name of account holder"
              value={ownerName}
              placeholder="Full name of account holder"
              onChange={this.onChange}
              name="ownerName"
              required
            />
            <EditInput
              htmlFor="Account Number"
              label="Account Number"
              value={accountNumber}
              placeholder="Account Number"
              onChange={this.onChange}
              name="accountNumber"
              required
            />
            <EditInput
              htmlFor="SORT Code"
              label="SORT Code"
              value={sortCode}
              placeholder="SORT Code"
              onChange={this.onChange}
              name="sortCode"
            />

            <p className={styles.binfoa}>Bank owners Address Details</p>

            <EditInput
              htmlFor="Address"
              label="Address"
              value={address}
              placeholder="Address"
              onChange={this.onChange}
              name="address"
              required
            />
            <EditInput
              htmlFor="City"
              label="City"
              value={city}
              placeholder="City"
              onChange={this.onChange}
              name="city"
              required
            />
            <EditInput
              htmlFor="Postal Code"
              label="Postal Code"
              value={postalCode}
              placeholder="Postal Code"
              onChange={this.onChange}
              name="postalCode"
              required
            />
            <EditSelect
              label="Country"
              options={countriesData}
              selected={selectedCountry}
              placeholder="Select Country"
              onChange={(e) => this.onSelectChange(e, 'country')}
              onActive={this.onSelActive}
              isDisabled={!!selectedCountry}
            />

            <div className={styles.attr}>
              <img src={maangopayicon} alt="mangopay" height="32px" width="151px" />
              <img src={padlock} alt="secure" height="27px" width="24px" />
            </div>
            <p className={styles.sub}>
              Conrati entrusts Mangopay (a secured third party payment provider) to store and handle
              all payment related data.
            </p>
          </div>
        )}
      </div>
    );
  }
}

BankDetails.propTypes = {
  user: globalProptypes.user.isRequired,
  onSyncBankAccount: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BankDetails;
