import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ListCardsSelect.module.css';
import visacard from '../../Common/assets/card_visa_select.svg';
import mastercard from '../../Common/assets/card_mastercard_select.svg';
import othercard from '../../Common/assets/other_card.svg';

class ListCardsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: '',
    };
  }

  onClickItem = (card, index) => {
    const { onClick } = this.props;
    onClick(card);
    this.setState({
      selectedIndex: index,
    });
  };

  render() {
    const { cardData, currencyCode } = this.props;
    const { selectedIndex } = this.state;

    const cardDataFilter = cardData.filter((card) => card.currency === currencyCode);

    return (
      <div>
        <div className={styles.listcard}>
          {cardDataFilter &&
            cardDataFilter.map((card, index) => {
              if (card.active) {
                return (
                  <div
                    className={styles.lcard}
                    key={card.id}
                    onClick={() => this.onClickItem(card, index)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <div className={styles.lcardic}>
                      <div className={styles.cardico}>
                        <img
                          src={
                            // eslint-disable-next-line no-nested-ternary
                            card.cardProvider === 'VISA'
                              ? visacard
                              : card.cardProvider === 'MASTERCARD'
                              ? mastercard
                              : othercard
                          }
                          alt="card"
                          width="50px"
                          height="33px"
                        />
                      </div>
                      <div className={styles.carddata}>
                        <p
                          className={selectedIndex === index ? styles.selectedcard : styles.cardno}
                        >
                          {card.cardProvider}
                        </p>
                        <p
                          className={selectedIndex === index ? styles.selectedcard : styles.cardno}
                        >
                          {card.alias}
                        </p>
                      </div>
                    </div>
                    <div className={styles.endcard}>
                      <div>
                        <p
                          className={selectedIndex === index ? styles.selectedcard : styles.cardno}
                        >
                          Expiry
                        </p>
                        <p
                          className={selectedIndex === index ? styles.selectedcard : styles.cardno}
                        >
                          {card.expirationDate}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          <div className={styles.choosec}>
            <p>Choose a card</p>
          </div>
        </div>
      </div>
    );
  }
}

ListCardsSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ListCardsSelect;
