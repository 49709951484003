import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import cx from 'classnames';
import styles from './KycDetails.module.css';
import idcardicon from '../../Common/assets/id-card.svg';
import regprooficon from '../../Common/assets/reg-prof.svg';
import upbtn from '../../Common/assets/upload_light.svg';
import { apiurl } from '../../../utils/index';
import enums from '../../../config/enum';

class Soletrader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfDocument: '',
      file: '',
      image1: '',
      imgFile: '',
      uploading: false,
      kycDocuments: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.kycDocuments && newprops.kycDocuments.length > 0) {
      this.setState({
        kycDocuments: newprops.kycDocuments,
      });
    }
  }

  onClickImg = (e, typeOfDocument) => {
    this.setState({ typeOfDocument });
    this.fileUpload.click();
  };

  onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    const { typeOfDocument } = this.state;

    reader.onload = () => {
      this.setState({
        file: reader.result,
        imgFile: file,
      });

      if (typeOfDocument === enums.kycDocumentTypeText.IDENTITY_PROOF && file.size <= 33000) {
        return toast.warn('File should be greater then 32kb for IDENTITY PROOF', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      if (file.size <= 7000000) {
        this.onUploadImage();
      } else {
        toast.warn('File should be less then 7mb', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };

    reader.readAsDataURL(file);
  };

  onUploadImage() {
    let toastid = null;

    this.setState({
      uploading: true,
    });

    toastid = toast.warn('Uploading', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (this.state.imgFile) {
      const headers = {
        'Content-Type': 'application/json',
      };

      headers['Content-Type'] = 'application/x-www-form-urlencoded';
      headers.Authorization = `Bearer ${this.props.user.userToken}`;

      const data = {
        type: this.state.typeOfDocument,
        file: this.state.file,
      };

      const formData = new FormData();
      formData.append('image1', this.state.imgFile);
      formData.append('document_type', data.type);

      axios
        .post(`${apiurl}/v1/payment/kyc/web`, formData, { headers })
        .then((result) => {
          if (result.data.success) {
            this.props.onFetchKycDocuments(this.props.user);

            toast.update(toastid, {
              render: result.data.message,
              autoClose: 3000,
            });

            this.setState({
              uploading: false,
              file: '',
              imgFile: '',
            });

            this.fileUpload.value = null;
          } else {
            this.fileUpload.value = null;

            toast.update(toastid, {
              render: result.data.message,
              autoClose: 3000,
            });

            throw new Error('Error');
          }
        })
        .catch(() => {
          this.fileUpload.value = null;
          toast.update(toastid, {
            render: 'Upload Failed',
            autoClose: 3000,
          });

          this.setState({
            uploading: false,
            file: '',
            imgFile: '',
          });
        });
    }
  }

  render() {
    return (
      <div className={styles.Soletrader}>
        {this.props.edit ? (
          <div className={styles.edit}>
            <p className={styles.alisubikb}>
              Please ensure that you merge singular documents into one file for each of the sections
              below.
            </p>
            <input
              type="file"
              accept=".pdf,.jpeg,.jpg,.gif,.png"
              onChange={this.onFileChange}
              className={styles.imgupl}
              ref={(ref) => {
                this.fileUpload = ref;
              }}
            />
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={idcardicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>ID Proof</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.IDENTITY_PROOF;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>{kyc.type}</p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
              </div>

              <button
                disabled={this.state.uploading}
                className={styles.upbtn}
                onClick={(e) => this.onClickImg(e, enums.kycDocumentTypeText.IDENTITY_PROOF)}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Click to upload a file</p>
              </button>

              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  {this.props.kychelptext.soleTrader &&
                    this.props.kychelptext.soleTrader.find(
                      (doc) => doc.kycDocumentType === enums.kycDocumentTypeText.IDENTITY_PROOF
                    ).text}
                </p>
              </div>
            </div>
            <div className={styles.kitem}>
              <div className={styles.idproof}>
                <img src={regprooficon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Registration Proof</p>
              </div>
              <div className={styles.filelist}>
                {this.state.kycDocuments
                  .filter((document) => {
                    return document.type === enums.kycDocumentTypeMangopay.REGISTRATION_PROOF;
                  })
                  .map((kyc, index) => (
                    <div key={index} className={styles.kycfile}>
                      <div>
                        <p className={styles.statustext}>{kyc.type}</p>
                        <p className={styles.statustextp}>
                          ID: {moment.unix(kyc.creationDate).format('DDMMYY')}-{kyc.id}
                        </p>
                        {kyc.refusedReasonType && (
                          <p className={styles.statustextp}>
                            Reason:{' '}
                            <span style={{ color: 'red' }}>
                              {kyc.refusedReasonType.replace(/[^a-zA-Z ]/g, ' ')}
                            </span>
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          kyc.status === enums.kycDocumentStatusMangopay.REFUSED ||
                          kyc.status === enums.kycDocumentStatusMangopay.OUT_OF_DATE
                            ? cx(styles.statustexticon, styles.refused)
                            : styles.statustexticon
                        }
                      >
                        {kyc.status.replace(/[^a-zA-Z ]/g, ' ')}
                      </p>
                    </div>
                  ))}
              </div>
              <button
                disabled={this.state.uploading}
                onClick={(e) => this.onClickImg(e, enums.kycDocumentTypeText.REGISTRATION_PROOF)}
                className={styles.upbtn}
              >
                <img src={upbtn} alt="" />
                <p className={styles.upbtntext}>Click to upload a file</p>
              </button>
              <div className={styles.doctypes}>
                <p className={styles.doctypesp}>
                  {this.props.kychelptext.soleTrader &&
                    this.props.kychelptext.soleTrader.find(
                      (doc) => doc.kycDocumentType === enums.kycDocumentTypeText.REGISTRATION_PROOF
                    ).text}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.view}>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={idcardicon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>ID Proof</p>
              </div>
            </div>
            <div className={styles.idproof}>
              <div className={styles.cinfo}>
                <img src={regprooficon} alt="Id card" width="25px" height="20px" />
                <p className={styles.ititle}>Registration Proof</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Soletrader;
