/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import * as createHistory from 'history';
import thunk from 'redux-thunk';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import ReactGA from 'react-ga';
import moment from 'moment';
import { reactAppEnv, analyticsTrackingCode } from '../utils';
import createRootReducer from '../reducers';

/* ....................Google analytics middleware..................... */

if (reactAppEnv === 'production') {
  ReactGA.initialize(analyticsTrackingCode);
}

const trackPage = (page) => {
  ReactGA.pageview(page);
};

const gaTrackingMiddleware = () => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    window.Intercom('update', { last_request_at: moment().unix() });

    if (reactAppEnv === 'production') {
      trackPage(nextPage);
    }
  }
  return next(action);
};

/* ....................End Google analytics middleware..................... */

export const history = createHistory.createBrowserHistory();

const initialState = {};

const migrations = {
  0: (state) => {
    return {
      userState: {
        ...state.userState,
        kycConfirm: false,
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  1: (state) => {
    return {
      userState: {
        ...state.userState,
        analytics: {
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
        },
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  2: (state) => {
    return {
      userState: {
        ...state.userState,
        isLoading: false,
        isError: false,
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  3: (state) => {
    return {
      userState: {
        ...state.userState,
        currency: {
          currencyCode: 'EUR',
          currencyName: 'Euro',
          currencySymbol: '€',
        },
        country: {
          countryCode: 'DE',
          countryName: 'Germany',
        },
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  4: (state) => {
    return {
      userState: {
        ...state.userState,
        lastUpdatedCurrency: '',
        ongoingBooking: {},
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  5: (state) => {
    return {
      userState: {
        ...state.userState,
        optinClicked: false,
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  6: (state) => {
    return {
      userState: {
        ...state.userState,
        user: {
          ...state.user,
          business: {
            isSmallEnterprise: false,
            isVatRegistered: false,
            vatNo: '',
          },
        },
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
  7: (state) => {
    return {
      userState: {
        ...state.userState,
        user: {
          ...state.user,
          business: {
            isSmallEnterprise: false,
            isVatRegistered: false,
            vatNo: '',
          },
        },
      },
      masterdata: {
        ...state.masterdata,
      },
    };
  },
};

const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['userState', 'masterdata'],
  version: 7,
  migrate: createMigrate(migrations, { debug: false }),
};

const middleware = [thunk, routerMiddleware(history), gaTrackingMiddleware];

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const composeEnhancers =
  reactAppEnv === 'dev' || reactAppEnv === 'test' || reactAppEnv === 'dev-server'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default store;
