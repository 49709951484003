import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './LargeWarning.module.css';
import close_icon from '../assets/close.png';
import arrow_white_yellow from '../assets/arrow_white_yellow.svg';

class LargeWarning extends Component {
  static propTypes = {
    hideclose: PropTypes.bool,
    icon: PropTypes.any,
    title: PropTypes.string,
    body: PropTypes.string,
    link: PropTypes.string,
    linktext: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      active: true,
      close: true,
    };
  }

  componentDidMount() {
    if (this.props.hideclose) {
      this.setState({ close: false });
    }
  }

  onClose = (e) => {
    e.preventDefault();
    this.setState({ active: false });
  };

  render() {
    return (
      <>
        {this.state.active && (
          <div className={styles.newbooking}>
            <div className={styles.newbookingnot}>
              {this.state.close && (
                <img onClick={this.onClose} className={styles.close} src={close_icon} alt="close" />
              )}
              <img src={this.props.icon} alt="Warning" />
              <div className={styles.info}>
                <p className={styles.infot}>{this.props.title}</p>
                <p className={styles.infost}>
                  {this.props.body} <br />{' '}
                  {this.props.link && (
                    <span className={styles.infolink}>
                      <Link to={this.props.link} target="_open">
                        {this.props.linktext}
                        <img src={arrow_white_yellow} alt="Visit" />
                      </Link>
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default LargeWarning;
