/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Headers from '../Headers/Headers';
import styles from './Search.module.css';
import { fetchAllProfiles, clearProfiles } from '../../actions/profileAction';
import { fetchCountryCurrencyDetails } from '../../actions/masterAction';
import titlebg from '../Common/assets/small.svg';
import searchnf from '../Common/assets/search_nf.svg';
import PageLoader from '../Common/PageLoader/PageLoader';
import Filter from './Filter/Filter';
import globalProptypes from '../../config/proptype';
import CardProfiles from './CardProfiles/CardProfiles';
import Pagination from './Pagination/Pagination';
import PlaceHolder from '../Common/PlaceHolder';
import { isDivisibleByThree } from '../../utils';
import { onSearchSuggestion } from '../../services/userService';
import { updateProfile } from '../../actions/userAction';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      searchKey: '',
      suggestion: [],
      showSuggestion: false,
      recentSearches: [],
    };

    this.inputCtl = React.createRef();
  }

  componentDidMount() {
    const {
      onFetchAllProfiles,
      location,
      currency,
      onFetchCountryCurrencyDetails,
      onUpdateProfile,
      isLoggedIn,
    } = this.props;

    // Update profile
    if (isLoggedIn) {
      onUpdateProfile();
    }

    // Fetch currency
    onFetchCountryCurrencyDetails();

    // Scroll the window on active
    window.scrollTo(0, 0);

    this.axiosCancelToken = axios.CancelToken.source();

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    onFetchAllProfiles(location.search, this.axiosCancelToken.token, reqData);

    const search = new URLSearchParams(location.search);
    if (search.has('query')) {
      this.setState({
        query: search.get('query'),
      });
    }

    // Initiate recent searches
    const isRecentSearches = window.localStorage.getItem('recentSearches');

    if (!isRecentSearches) {
      window.localStorage.setItem('recentSearches', JSON.stringify([]));
    } else {
      const recentSearches = JSON.parse(window.localStorage.getItem('recentSearches'));
      this.setState({
        recentSearches,
      });
    }

    this.inputCtl.current.focus();
  }

  componentWillUnmount() {
    const { onClearProfiles } = this.props;
    this.axiosCancelToken.cancel('Search request canceled');
    onClearProfiles();
  }

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      query: e.target.value,
      searchKey: e.target.value.toLowerCase(),
    });

    this.fetchSearchSuggestion();
  };

  onSearch = () => {
    const { location, history, onFetchAllProfiles, currency } = this.props;
    const { searchKey } = this.state;

    const newquery = new URLSearchParams(location.search);
    newquery.set('query', searchKey || '');
    const query = `/search?${newquery.toString()}`;
    const apiquery = `?${newquery.toString()}`;

    history.push(query);

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    this.handleRecentSearches(searchKey);
    onFetchAllProfiles(apiquery, this.axiosCancelToken.token, reqData);
    this.setState({ showSuggestion: false });
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  };

  onChangePage = (pageNumber) => {
    const { location, onFetchAllProfiles, currency } = this.props;
    let { searchKey } = this.state;
    const newquery = new URLSearchParams(location.search);

    if (!searchKey) {
      searchKey = newquery.has('query') ? newquery.get('query') : '';
    }

    newquery.set('query', searchKey || '');
    newquery.set('page', pageNumber);
    const apiquery = `?${newquery.toString()}`;

    const reqData = {};

    if (currency.currencyCode) {
      reqData.currency = currency;
    }

    onFetchAllProfiles(apiquery, this.axiosCancelToken.token, reqData);
    window.scrollTo(0, 0);
  };

  renderProfiles = () => {
    const { profiles, totalPages, page, isFreeSessionConsumed } = this.props;
    let template = '';
    if (profiles.length > 0) {
      template = (
        <div
          className={!isDivisibleByThree(profiles.length) ? styles.usercardeven : styles.usercard}
        >
          <CardProfiles profiles={profiles} isFreeSessionConsumed={isFreeSessionConsumed} />
          <Pagination totalPages={totalPages} page={page} onChangePage={this.onChangePage} />
        </div>
      );
    } else {
      template = (
        <div className={styles.nf}>
          <img src={searchnf} alt="Not found" />
          <p>
            No users found <br /> Matching your search result
          </p>
        </div>
      );
    }

    return template;
  };

  fetchSearchSuggestion = () => {
    const { query, showSuggestion } = this.state;

    if (query.length === 0) {
      return this.setState({ showSuggestion: false });
    }

    if (query.length >= 1) {
      onSearchSuggestion(query).then((data) => {
        if (!showSuggestion) {
          this.setState({ suggestion: data, showSuggestion: true });
        }

        this.setState({ suggestion: data });
      });
    }

    return true;
  };

  onClickSuggestion = async (link) => {
    await this.setState({ query: link, searchKey: link });

    this.onSearch();
  };

  handleRecentSearches = (searchWord) => {
    const recentSearches = JSON.parse(window.localStorage.getItem('recentSearches'));

    const isValueAlreadyPrsnt = recentSearches.findIndex((item) => item === searchWord);

    if (isValueAlreadyPrsnt >= 0) {
      return true;
    }

    if (recentSearches.length === 5) {
      recentSearches.pop();
    }

    recentSearches.unshift(searchWord);

    this.setState({
      recentSearches,
    });

    return window.localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  };

  render() {
    const { loading } = this.props;
    const { query, suggestion, showSuggestion, recentSearches } = this.state;

    return (
      <div>
        <Headers />
        <div>
          <div style={{ backgroundImage: `url(${titlebg})` }} className={styles.stitle}>
            <div className={styles.searchbar}>
              <input
                value={query}
                onChange={this.onChange}
                onKeyPress={this.onKeyDown}
                type="text"
                placeholder="What skill or qualification are you looking for?"
                className={styles.inputctl}
                ref={this.inputCtl}
              />
              <button type="button" className={styles.bico} onClick={this.onSearch}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAAA+pJREFUWAnNlttLVFEUxp3xloVGZFam9QcUBWEh3WCE8Em0UZQIgiKIrCDopcJHHyJ8Ebr9A0GNozYU0QUSjZ56sjKkJ4MwaUzxwUrTmX7f6Rw5k+c2E1kb1tmXtda3v7Mva+28vP+khPx4DAwMFCSTyUOhUKgxnU7XUldSV+A3TXuc+g2SKCsre1JfXz/rh+emdyXCZKHe3t5j1J3INjcA2/gM7atId2tr6zfbeKCmI5FEIrFxbm6uF4T9QuHPR6niEHqSn58/tri4+Lm4uHjdwsLCllQqFUEXRbdPtpSxgoKCpmg0OvyrG+y7jEhfX98OJniEezUEPiKXm5ub71CnvSBZvQgEu7DZjcyGw+GjLS0tD7x87LoMIuZKvMKgGhkqKSlpbmhomLQ7eLVjsVgRhG+xOiex+87qHeInhOdblojgHOrp6XmBh7ZjCDnMXs/7IjgYgHMdvLOoxtmmnWzTFwezjKGw1dPBpL1f26GVyJWE8MrLyy9QDSKVbFeHxvyKQURXlD/olLHORDbb4TRBJBJZYCXOoUuB286WbXWys48ZRBQncNgGiVEdTLtBrm224y14MfyLkDY/HIMIDo2mYZy25+3wA7Trwbpn9i18uzqjbRBhNWo1ypV7nKH9w05paekz88f2MsfSxXCCtVak0lR+cDLKdcwM+Un8C/v7+zd44VgrotyRp4jpZZyLjpWwMI053DAMIiinZaCw7Wb4B+MGJtHamMMNxyLySQbKHW6GuYwrLOC3CUkhE14Y1hl5LSMzgXnZZ6Wbmpo6iEM+8o4AuejlbK1IwjSKehlnq+PMGXjcnPt+vgYRPWownOFg7VMW9XMKogdnM3YnZAuRHj8fg4h5zfSo0c3pUhb1c/TTs83CWwOJGM8BY+u9fKytkU03MobsxvkWdc6F7Hue1T0OwHfqS0GAMqIdj6Jd3JyXOOpPbiiLKoEFAbJsIHGayW/SJ1CHz7Aaty2dV51BRIbxeLyBZVWyWoUMKosqgUnnVfSomp+fv2auhGHKz4zwOKrD3wpqrhDLiMiSg7aHs6KTrtCfAvAeEiPgPeWJ8FU2KooTk5OTB2geQU5BYjX1LLYXaesZsCMoGUciAOSxTesh0wFgO13j8AKqzJxkTH+4FlGwKkR0MxhO97GCV1iB9xx45ZbnSCAyrkQErmI+atqYqImusqiipVHMyUfoPITAXQgMmyqjyoaMLxE7MCRCZhat4CDOVFVVTdTU1Pyw2/zeDkomKyK/TxK0H4TMihARYT8yK0bEj8yKEnEiw7mLkJmT9hAvu79eNCmT1HHjRiCxnXa/Jl1xIppUZBRxaSqdLAVI6f55+QmuoaH9cpjZkwAAAABJRU5ErkJggg=="
                  alt="search"
                />
              </button>
            </div>

            {showSuggestion && (
              <div className={styles.suggestionBar}>
                <div className={styles.line} />
                <div className={styles.box}>
                  {recentSearches.length > 0 && (
                    <div className={styles.recenta}>
                      <p className={styles.btitle}>Recent Searches</p>
                      {recentSearches.map((link, index) => (
                        <p key={index} className={styles.searchTextp}>
                          <Link
                            key={index}
                            to={`/search?query=${link}`}
                            className={styles.searchText}
                            onClick={() => this.onClickSuggestion(link)}
                          >
                            {link}
                          </Link>
                        </p>
                      ))}
                    </div>
                  )}
                  <div className={styles.recent}>
                    <p className={styles.btitle}>Quick Suggestion</p>
                    {suggestion.map((link, index) => (
                      <p key={index} className={styles.searchTextp}>
                        <Link
                          key={index}
                          to={`/search?query=${link}`}
                          className={styles.searchText}
                          onClick={() => this.onClickSuggestion(link)}
                        >
                          {link}
                        </Link>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.scontainer}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Filter {...this.props} query={query} />
            <>
              {loading ? (
                <div className={styles.usercardLoader}>
                  <PlaceHolder numberOfPlaceholder={12} />
                  <div className={styles.pcardLoader}>
                    <div className={styles.cardLoader}>
                      <PageLoader />
                      <p className={styles.loaderTitle}>Fetching Users</p>
                    </div>
                  </div>
                </div>
              ) : (
                this.renderProfiles()
              )}
            </>
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  location: globalProptypes.location.isRequired,
  onFetchAllProfiles: PropTypes.func.isRequired,
  onClearProfiles: PropTypes.func.isRequired,
  history: globalProptypes.history.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  totalRecords: PropTypes.number,
  totalPages: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  categoriesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  languagesMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
  }).isRequired,
  onFetchCountryCurrencyDetails: PropTypes.func.isRequired,
  isFreeSessionConsumed: PropTypes.bool.isRequired,
};

Search.defaultProps = {
  totalRecords: 0,
  totalPages: 0,
  limit: 0,
  page: 0,
};

const mapStateToProps = (state) => {
  const currency =
    state.userState.isLoggedIn && state.userState.user.currencyId > 0
      ? state.userState.user.currency
      : state.userState.currency;

  const isFreeSessionConsumed = !!(
    state.userState.isLoggedIn && state.userState.user.isFreeSessionConsumed
  );

  return {
    isLoggedIn: state.userState.isLoggedIn,
    loading: state.profiles.loading,
    profiles: state.profiles.proflieData.data ? state.profiles.proflieData.data : [],
    categoriesMasterData: state.masterdata.masterData.categories || [],
    skillsMasterData: state.masterdata.masterData.skills,
    languagesMasterData: state.masterdata.masterData.languages,
    totalRecords: state.profiles.proflieData.totalRecords,
    totalPages: state.profiles.proflieData.totalPages,
    limit: state.profiles.proflieData.limit,
    page: state.profiles.proflieData.page,
    currency,
    isFreeSessionConsumed,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchAllProfiles: fetchAllProfiles,
      onClearProfiles: clearProfiles,
      onFetchCountryCurrencyDetails: fetchCountryCurrencyDetails,
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Search);
