/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import mangopay from 'mangopay-cardregistration-js-kit';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import styles from './AddCard.module.css';
import maangopayIcon from '../../Common/assets/mangopay.png';
import padlock from '../../Common/assets/padlock.svg';
import LoaderButton from '../../Common/LoaderButton/LoaderButton';
import { fetchUserCardData } from '../../../actions/tempAction';
import { apiurl, addNewCard, MangoPayBaseURL, MangoPayClientId, alert } from '../../../utils/index';
import { addCardPreAuth } from '../../../services/userService';

class AddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardno: '',
      expiry: '',
      cvc: '',
      sent: false,
    };
  }

  componentDidMount = () => {
    mangopay.cardRegistration.baseURL = MangoPayBaseURL;
    mangopay.cardRegistration.clientId = MangoPayClientId;
  };

  expiryCleanUp = (expiryDate) => {
    return expiryDate.replace(/\//g, '');
  };

  onExpiryDateChange = (inputDate) => {
    if (inputDate.length < 3) {
      return inputDate;
    }

    const cleanDate = this.expiryCleanUp(inputDate);

    if (cleanDate.length < 2) {
      return cleanDate;
    }

    const month = cleanDate.substr(0, 2) || '';

    if (parseInt(month) > 12) {
      return '';
    }

    const year = cleanDate.substr(2, 2) || '';
    const format = `${month}/${year}`;

    if (format.length !== 5) {
      return format;
    }

    const formattedDate = DateTime.fromFormat(format, 'MM/yy');

    if (!formattedDate.isValid) {
      return '';
    }

    return formattedDate.toFormat('MM/yy');
  };

  handleOnChange = (e) => {
    if (e.target.name === 'expiry') {
      return this.setState({ [e.target.name]: this.onExpiryDateChange(e.target.value) });
    }

    if (e.target.name === 'cvc') {
      if (e.target.value.length <= 3) {
        return this.setState({ [e.target.name]: e.target.value });
      }
    }

    if (e.target.name === 'cardno') {
      if (e.target.value.length <= 16) {
        return this.setState({ [e.target.name]: e.target.value });
      }
    }

    if (e.target.name !== 'expiry' && e.target.name !== 'cvc' && e.target.name !== 'cardno') {
      return this.setState({ [e.target.name]: e.target.value });
    }

    return true;
  };

  addZarCard = async () => {
    try {
      const { mangopayUserId } = this.props;

      const reqData = {
        mango_pay_id: mangopayUserId,
        currency: 'ZAR',
      };

      const preauthDataResponse = await addCardPreAuth(reqData);
      const preAuthData = preauthDataResponse[0];

      await mangopay.cardRegistration.init({
        cardRegistrationURL: preAuthData.cardRegistrationUrl,
        preregistrationData: preAuthData.preregistrationData,
        accessKey: preAuthData.accessKey,
        Id: preAuthData.id,
      });

      // Add card to server
      const { cardno, expiry, cvc } = this.state;
      const carddata = {
        cardNumber: cardno,
        cardExpirationDate: this.expiryCleanUp(expiry),
        cardCvx: cvc,
        cardType: preAuthData.cardType,
      };
      await addNewCard(carddata);

      // After card add
      ReactGA.pageview('/myaccount/ar/credit_card');

      const { onFetchUserCardData, onAddNewCard } = this.props;

      const user = {
        mangoPayUserId: mangopayUserId,
      };

      await onFetchUserCardData(user);

      onAddNewCard();
      return Promise.resolve(true);
    } catch (err) {
      this.setState({ sent: true });
      return Promise.reject(err);
    }
  };

  addEurCard = async () => {
    try {
      const { mangopayUserId } = this.props;

      const reqData = {
        mango_pay_id: mangopayUserId,
        currency: 'EUR',
      };

      const preauthDataResponse = await addCardPreAuth(reqData);
      const preAuthData = preauthDataResponse[0];

      await mangopay.cardRegistration.init({
        cardRegistrationURL: preAuthData.cardRegistrationUrl,
        preregistrationData: preAuthData.preregistrationData,
        accessKey: preAuthData.accessKey,
        Id: preAuthData.id,
      });

      // Add card to server
      const { cardno, expiry, cvc } = this.state;
      const carddata = {
        cardNumber: cardno,
        cardExpirationDate: this.expiryCleanUp(expiry),
        cardCvx: cvc,
        cardType: preAuthData.cardType,
      };
      await addNewCard(carddata);

      // After card add
      ReactGA.pageview('/myaccount/ar/credit_card');

      const { onFetchUserCardData, onAddNewCard } = this.props;

      const user = {
        mangoPayUserId: mangopayUserId,
      };

      await onFetchUserCardData(user);

      onAddNewCard();

      return Promise.resolve(true);
    } catch (err) {
      this.setState({ sent: true });
      return Promise.reject(err);
    }
  };

  addGbpCard = async () => {
    try {
      const { mangopayUserId } = this.props;

      const reqData = {
        mango_pay_id: mangopayUserId,
        currency: 'GBP',
      };

      const preauthDataResponse = await addCardPreAuth(reqData);
      const preAuthData = preauthDataResponse[0];

      await mangopay.cardRegistration.init({
        cardRegistrationURL: preAuthData.cardRegistrationUrl,
        preregistrationData: preAuthData.preregistrationData,
        accessKey: preAuthData.accessKey,
        Id: preAuthData.id,
      });

      // Add card to server
      const { cardno, expiry, cvc } = this.state;
      const carddata = {
        cardNumber: cardno,
        cardExpirationDate: this.expiryCleanUp(expiry),
        cardCvx: cvc,
        cardType: preAuthData.cardType,
      };
      await addNewCard(carddata);

      // After card add
      ReactGA.pageview('/myaccount/ar/credit_card');

      const { onFetchUserCardData, onAddNewCard } = this.props;

      const user = {
        mangoPayUserId: mangopayUserId,
      };

      await onFetchUserCardData(user);

      onAddNewCard();

      return Promise.resolve(true);
    } catch (err) {
      this.setState({ sent: true });
      return Promise.reject(err);
    }
  };

  cardErrorHandling = (error) => {
    let errorMessage = '';

    if (error.ResultMessage) {
      switch (error.ResultMessage) {
        case 'CARD_NUMBER_FORMAT_ERROR':
          errorMessage = 'The card number is not a valid format';
          break;
        case 'PAST_EXPIRY_DATE_ERROR':
        case 'EXPIRY_DATE_FORMAT_ERROR':
          errorMessage = 'The expiry date is not in the future';
          break;
        case 'CVV_FORMAT_ERROR':
          errorMessage = 'The CVV is missing or not the required the length';
          break;
        default:
          errorMessage = 'Failed adding Card';
          break;
      }
    }

    alert(errorMessage);
  };

  handleOnSubmit = async () => {
    try {
      await this.addEurCard();

      setTimeout(async () => {
        await this.addZarCard();
      }, 1500);

      setTimeout(async () => {
        await this.addGbpCard();
        this.setState({ sent: true });
        alert('Card added successfully');

        const { onAddCardSuccess, handleSuccess } = this.props;
        if (handleSuccess) {
          onAddCardSuccess(true);
        }
      }, 3000);
    } catch (err) {
      this.cardErrorHandling(err);
    }
  };

  render() {
    const { cardno, expiry, cvc, sent } = this.state;
    return (
      <div className={styles.addcard}>
        <div className={styles.registercard}>
          <form className={styles.cardform} onSubmit={this.handleOnSubmit}>
            <p className={styles.title}>Fill in the card details</p>
            <div className={styles.formgroup}>
              <label className={styles.label} htmlFor="Name">
                Name on card
              </label>
              <input
                autoComplete="off"
                type="text"
                className={styles.formcontrol}
                placeholder="Name on card"
                name="name"
                onChange={this.handleOnChange}
              />
            </div>
            <div className={styles.formgroup}>
              <label className={styles.label} htmlFor="cardno">
                Card number
              </label>
              <input
                type="text"
                value={cardno}
                className={styles.formcontrol}
                placeholder="Card number"
                name="cardno"
                onChange={this.handleOnChange}
              />
            </div>
            <div className={styles.formgrouph}>
              <div className={styles.exp}>
                <label className={styles.label} htmlFor="cardno">
                  Expiry
                </label>
                <input
                  type="text"
                  value={expiry}
                  autoComplete="off"
                  className={styles.formcontrol}
                  placeholder="MM/YY"
                  name="expiry"
                  onChange={this.handleOnChange}
                />
              </div>
              <div className={styles.cvc}>
                <label className={styles.label} htmlFor="cardno">
                  CVV
                </label>
                <input
                  type="password"
                  value={cvc}
                  autoComplete="off"
                  className={styles.formcontrol}
                  placeholder="***"
                  name="cvc"
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className={styles.btngroup}>
              <LoaderButton
                onClick={this.handleOnSubmit}
                color="success"
                active="ADD"
                success="ADDED"
                retry={sent}
                disabled={!(cardno.length > 0 && cvc.length > 0 && expiry.length > 0)}
                response={sent}
              />
            </div>
          </form>
          <div className={styles.attr}>
            <button
              type="button"
              onClick={() => {
                window.open('https://www.mangopay.com/privacy/');
              }}
            >
              <img src={maangopayIcon} alt="mangopay" height="32px" width="151px" />
            </button>
            <img src={padlock} alt="secure" height="27px" width="24px" />
          </div>
          <p className={styles.sub}>
            Conrati entrust Mangopay (a secure third payment provider) to store and handle all
            payment related data
          </p>
        </div>
      </div>
    );
  }
}

AddCard.propTypes = {
  mangopayUserId: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    currencyCode: PropTypes.string,
  }).isRequired,
  onFetchUserCardData: PropTypes.func.isRequired,
  onAddNewCard: PropTypes.func.isRequired,
  onAddCardSuccess: PropTypes.func.isRequired,
  handleSuccess: PropTypes.bool.isRequired,
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchUserCardData: fetchUserCardData,
    },
    dispatch
  );
};

export default connect(null, mapActionToProps)(AddCard);
