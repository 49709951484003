import React, { useEffect, useState } from 'react';
import cm from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from '../styles.module.scss';
import clockWhite from '../../../assets/clock_white.svg';
import globalProptypes from '../../../../config/proptype/index';
import enums from '../../../../config/enum';
import { disconnectVideoSession } from '../../../../services/userService';

function Waiting(props) {
  const { sessionData, userData } = props;

  const { from } = sessionData.bookingData;
  const currentTime = moment();
  const fromBooking = moment.unix(from);
  const graceTime = fromBooking.clone().add(10, 'minutes');
  const [duration, setDuration] = useState(moment.duration(graceTime.diff(currentTime)));
  const [disconnectRequestSent, setDisconnectRequestSent] = useState(false);

  useEffect(() => {
    const interVal = setInterval(() => {
      const hours = duration.hours();
      const min = duration.minutes();
      const seconds = duration.seconds();

      if (hours === 0 && min === 0 && seconds === 0) {
        const { bookingId } = sessionData.bookingData;

        const reqData = {
          booking_id: bookingId,
          status:
            userData.userType === enums.userType.ap
              ? enums.sessionDisconnectType.NoShowAR
              : enums.sessionDisconnectType.NoShowAP,
        };

        if(!disconnectRequestSent) {
          setDisconnectRequestSent(true);

          disconnectVideoSession(reqData).then((response) => {
            setDisconnectRequestSent(false);
            window.location.href = `/session/disconnect?sessionStatus=${
              userData.userType === enums.userType.ap
                ? enums.sessionDisconnectType.NoShowAP
                : enums.sessionDisconnectType.NoShowAR
            }`;
          });
        }

        return 'redirected';
      }

      return setDuration(moment.duration(graceTime.diff(currentTime)));
    }, 1000);
    return () => {
      clearInterval(interVal);
    };
  }, [currentTime, graceTime, userData.userType, duration, sessionData]);

  return (
    <div className={styles.waiting}>
      <p className={cm(styles.ititle, styles.blinkMe)}>
        The {userData.userType === enums.userType.ap ? 'Advice Receiver' : 'Consultant'} has 10 mins
        to arrive
      </p>
      <div className={styles.timer}>
        <img src={clockWhite} alt="Clock" />
        <p>
          {duration.hours()} : {duration.minutes()} : {duration.seconds()}
        </p>
      </div>
    </div>
  );
}

Waiting.propTypes = {
  sessionData: PropTypes.shape({
    bookingData: PropTypes.shape({
      title: PropTypes.string,
      dateOfBooking: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      bookingId: PropTypes.string.isRequired,
    }),
  }),
  userData: globalProptypes.user.isRequired,
};

Waiting.defaultProps = {
  sessionData: {
    bookingData: {
      title: '',
      dateOfBooking: '',
      from: '',
      to: '',
    },
  },
};

export default Waiting;
