/*
Get Browser Info
*/

interface BrowserInfo {
	AcceptHeader: string;
	JavaEnabled: boolean;
	Language: string;
	ColorDepth: number;
	ScreenHeight: number;
	ScreenWidth: number;
	TimeZoneOffset: number;
	UserAgent: string;
	JavascriptEnabled: boolean;
}

function browserInfo(): BrowserInfo {
	const date = new Date();

	const browserInfo = {
		AcceptHeader:
			'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
		JavaEnabled: true,
		Language: window.navigator.language,
		ColorDepth: window.screen.colorDepth,
		ScreenHeight: window.screen.height,
		ScreenWidth: window.screen.width,
		TimeZoneOffset: date.getTimezoneOffset(),
		UserAgent: window.navigator.userAgent,
		JavascriptEnabled: true,
	};

	return browserInfo;
}

export { browserInfo };
