/* eslint-disable import/no-cycle */
import { replace } from 'connected-react-router';
import {
  JOIN_SESSION,
  ACTIVE_CHAT,
  ACTIVE_BOOKING,
  DISCONNECT_SESSION,
  BOOKING_HISTORY,
  NEW_ONGOING_BOOKING,
  CLEAR_ONGOING_BOOKING,
} from './types';
import enums from '../config/enum';
import { weburl, alert, apiRequest as API } from '../utils';
import { updateProfile } from './userAction';

export const bookingDetails = (bookingId) => (dispatch) => {
  API('GET', `/v1/booking/${bookingId}`)
    .then((res) => {
      dispatch({
        type: ACTIVE_BOOKING,
        payload: res.result[0],
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const joinSession = (data) => (dispatch) => {
  const datasend = {
    booking_id: data.booking_id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
  };

  API('POST', '/v1.1/session/join', datasend)
    .then((res) => {
      dispatch({
        type: JOIN_SESSION,
        payload: res.result,
      });

      dispatch(bookingDetails(data.booking_id));
    })
    .catch((err) => {
      alert(err.message);
    });
};

export const bookSession = (data) => (dispatch) => {
  const date = new Date();

  const browserInfo = {
    AcceptHeader: 'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
    JavaEnabled: true,
    Language: window.navigator.language,
    ColorDepth: window.screen.colorDepth,
    ScreenHeight: window.screen.height,
    ScreenWidth: window.screen.width,
    TimeZoneOffset: date.getTimezoneOffset(),
    UserAgent: window.navigator.userAgent,
    JavascriptEnabled: true,
  };

  API('POST', '/v1.1/booking', { ...data, browser_info: browserInfo })
    .then((res) => {
      if (res.success) {
        if (!res.result.secureModeNeeded) {
          dispatch(replace(`/dashboard?booking_type=new&booking_id=${res.result.bookingId}`));

          return dispatch({
            type: CLEAR_ONGOING_BOOKING,
          });
        }
        return window.location.replace(res.result.secureModeRedirectUrl);
      }
      throw new Error(res.message);
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const disconnectSession = (data, user) => (dispatch) => {
  const postData = {
    booking_id: data.booking_id,
    status: data.status,
  };

  API('POST', '/v1.1/session/disconnect', postData)
    .then(() => {
      if (data.status === enums.bookingStatus.SUCCESSFUL) {
        window.location.replace(`${weburl}/session/call/complete?username=${user}`);
      }

      dispatch(updateProfile());

      dispatch({
        type: DISCONNECT_SESSION,
      });
    })
    .catch(() => {
      return window.location.replace(`${weburl}/session/call/complete?username=${user}`);
    });
};

export const fetchBookingHistory = () => (dispatch) => {
  API('GET', '/v1.1/booking/history')
    .then((res) => {
      if (res.success) {
        dispatch({
          type: BOOKING_HISTORY,
          payload: res.result,
        });
      } else {
        throw new Error(res.message);
      }
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const newMessage = (data) => (dispatch) => {
  dispatch({
    type: ACTIVE_CHAT,
    payload: data,
  });
};

export const newOngoingBooking = (data) => (dispatch) => {
  dispatch({
    type: NEW_ONGOING_BOOKING,
    payload: data,
  });
};

export const clearOngoingBooking = () => (dispatch) => {
  dispatch({
    type: CLEAR_ONGOING_BOOKING,
  });
};
