/* eslint-disable import/no-cycle */
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import {
  kycConfirm,
  ACCOUNT_COMPLETE,
  SWITCH_ACCOUNT,
  REGISTER_USER,
  ONBOARDING_STEPS,
  LOG_OUT,
  SIGN_IN,
  USERTYPE_REGISTER,
  oauthData,
  FORGET_PASSWORD,
  UPCOMING_SESSION,
  EDIT_PROFILE,
  UPDATE_PROFILE,
  FETCH_BUISNESS_DETAILS_USER,
  CLEAR_ER,
  UPDATE_ADVERT,
  NEW_REQUEST,
  USER_FORM_HANDLING,
  HANDLE_OPT_IN,
  HANDLE_AR_ONCHANGE,
  ERROR_R,
} from './types';

import { error } from './requestAction';
import { calculateVat } from './tempAction';
import enums from '../config/enum';
import { sendAnalyticsEventRegistration, alert, apiRequest as API } from '../utils';
import Messages from '../config/message';
import { addUtmData } from '../services/userService';

export const checkUserSteps = (user) => (dispatch) => {
  dispatch({
    type: ACCOUNT_COMPLETE,
    payload: user.registrationStepsCompleted,
  });

  if (user.availability && user.availability.id) {
    return dispatch({
      type: ONBOARDING_STEPS,
      payload: enums.onboardingStep.Availabilty,
    });
  }

  if (
    user.personalDetailsCompleted &&
    user.isBusinessRegistered &&
    (user.social.isGoogleConnected || user.social.isMicrosoftConnected)
  ) {
    return dispatch({
      type: ONBOARDING_STEPS,
      payload: enums.onboardingStep.CalendarSettings,
    });
  }

  if (user.isBusinessRegistered) {
    return dispatch({
      type: ONBOARDING_STEPS,
      payload: enums.onboardingStep.BusinessDetails,
    });
  }

  if (user.personalDetailsCompleted) {
    return dispatch({
      type: ONBOARDING_STEPS,
      payload: enums.onboardingStep.PersonalDetails,
    });
  }

  if (user.primaryImg) {
    return dispatch({
      type: ONBOARDING_STEPS,
      payload: enums.onboardingStep.ProfileImage,
    });
  }

  return true;
};

export const updateProfile = (user) => (dispatch) => {
  API('PUT', '/v1.1/profile/edit', user, {}, { modifyBody: false })
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.result,
      });
      dispatch(checkUserSteps(res.result));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const swicthAccount = (user) => (dispatch) => {
  let toastId = null;

  toastId = toast.warn('Switching Account... ', {
    position: 'top-center',
    hideProgressBar: true,
  });

  const data = {
    user_type: user.userType,
  };

  API('PUT', '/v1/user/switch', data)
    .then(async (res) => {
      await dispatch({
        type: SWITCH_ACCOUNT,
        payload: {
          user: res.result[0],
        },
      });

      dispatch(replace(user.redirectTo || '/'));
      dispatch(checkUserSteps(res.result[0]));
      return toast.update(toastId, {
        render: 'Account Switched',
        autoClose: 1000,
      });
    })
    .catch(() => {
      return toast.update(toastId, {
        render: 'Account Switched Failed',
        autoClose: 1000,
      });
    });
};

function handleAuthError(message, dispatch) {
  alert(message);

  if (dispatch) {
    dispatch({
      type: NEW_REQUEST,
      payload: {
        isLoading: false,
        isError: false,
      },
    });

    dispatch({
      type: ERROR_R,
      payload: {
        message,
      },
    });
  }
}

export const registerUser = (user) => (dispatch, getState) => {
  dispatch({
    type: NEW_REQUEST,
    payload: {
      isLoading: true,
      isError: false,
    },
  });

  API('POST', '/v1.1/user/register', user)
    .then((res) => {
      if (!res.success) {
        handleAuthError(res.message, dispatch);

        return;
      }

      if (user.user_type === enums.userType.ap) {
        ReactGA.pageview('/register/success/ap');
      } else {
        ReactGA.pageview('/register/success/ar');
      }

      // Sen analytics data redux store
      sendAnalyticsEventRegistration(getState().userState.analytics);

      // Updating redux store data
      dispatch({
        type: REGISTER_USER,
        payload: {
          user: res.result,
        },
      });

      const utmData = JSON.parse(window.localStorage.getItem('utmData'));

      // Storing utm data in server
      addUtmData({
        utm_data: utmData,
      });
    })
    .catch((err) => {
      dispatch({
        type: NEW_REQUEST,
        payload: {
          isLoading: false,
          isError: true,
        },
      });

      return alert(err.message);
    });
};

export const signinUser = (user, redirect) => (dispatch, getState) => {
  dispatch({
    type: NEW_REQUEST,
    payload: {
      isLoading: true,
      isError: false,
    },
  });

  return API('POST', '/v1.1/user/signin', user)
    .then((res) => {
      if (res.success) {
        dispatch({
          type: SIGN_IN,
          payload: {
            user: res.result,
          },
        });

        dispatch(checkUserSteps(res.result));

        if (redirect.redirect) {
            return dispatch(replace(redirect.url));
        }

        if (res.result.accountCategory === enums.accountCategory.light) {
          return dispatch(replace('/dashboard'));
        }

        if (res.result.userType === enums.userType.ap) {
          dispatch(replace('/dashboard'));
        } else if (res.result.userType === enums.userType.ar) {
          const { ongoingBooking } = getState().userState;

          if (ongoingBooking.start) {
            if (res.result.personalDetailsCompleted && res.result.isBusinessRegistered) {
              return dispatch(replace('/book/confirm'));
            }

            return dispatch(replace('/quick-registration-ar'));
          }

          return dispatch(replace('/search'));
        }
      } else {
        return handleAuthError(res.message, dispatch);
      }

      return true;
    })
    .catch((err) => {
      dispatch({
        type: NEW_REQUEST,
        payload: {
          isLoading: false,
          isError: true,
        },
      });
      return alert(err.message);
    });
};

export const logOutUser = () => (dispatch) => {
  API('PUT', '/v1/user/logout');
  dispatch({
    type: LOG_OUT,
    payload: {
      user: {},
    },
  });
  dispatch(replace('/'));
};

export const logOutUserState = () => (dispatch) => {
  API('PUT', '/v1/user/logout');
  dispatch({
    type: LOG_OUT,
    payload: {
      user: {},
    },
  });
};

export const linkedinSignIn = (code) => async (dispatch, getState) => {
  const linkedindetails = await API('POST', '/v1/oauth/token/linkedin', { code });
  const linkedinData = linkedindetails.result[0];

  if (linkedindetails.success) {
    const data = {
      login_type: enums.loginType.social,
      social_id: linkedinData.id,
      email: linkedinData.emailAddress,
      oauth_type: enums.oauthType.linkedin,
    };

    return API('POST', '/v1.1/user/signin', data)
      .then((res) => {
        if (!res.success) {
          handleAuthError(res.message, dispatch);

          return;
        }

        dispatch({
          type: REGISTER_USER,
          payload: {
            user: res.result,
          },
        });

        dispatch(checkUserSteps(res.result));

        if (res.result.userType === enums.userType.ap) {
          dispatch(replace('/dashboard'));
        } else if (res.result.userType === enums.userType.ar) {
          const { ongoingBooking } = getState().userState;

          if (ongoingBooking.start) {
            if (res.result.personalDetailsCompleted && res.result.isBusinessRegistered) {
              return dispatch(replace('/book/confirm'));
            }

            return dispatch(replace('/quick-registration-ar'));
          }

          return dispatch(replace('/search'));
        }
        return true;
      })
      .catch((err) => {
        return alert(err.message);
      });
  }
  return alert(Messages.error);
};

export const linkedinRegister = (code, userType) => async (dispatch, getState) => {
  // const linkedindetails = await API('POST', '/oauth/token/linkedin', { code });
  // const linkedinData = linkedindetails.result[0];

  // if (linkedindetails.success) {
  // const data = {
  //   user_type: userType,
  //   login_type: 3,
  //   email: linkedinData.emailAddress,
  //   social_id: linkedinData.id,
  //   last_name: linkedinData.lastName,
  //   first_name: linkedinData.firstName,
  //   oauth_type: enums.oauthType.linkedin,
  // };

  //   return API('POST', '/v1/user/register', data)
  //     .then(res => {
  //       if (res.result[0].userType === enums.userType.ap) {
  //         ReactGA.pageview('/register/success/ap');
  //       } else {
  //         ReactGA.pageview('/register/success/ar');
  //       }

  //       sendAnalyticsEventRegistration(getState().userState.analytics);

  //       dispatch({
  //         type: REGISTER_USER,
  //         payload: {
  //           user: res.result[0],
  //         },
  //       });

  //       if (res.result[0].userType === enums.userType.ap) {
  //         return dispatch(replace('/register/confirm-auth'));
  //       }

  //       return true;
  //     })
  //     .catch(err => {
  //       return alert(err.message);
  //     });
  // }
  // return alert(Messages.error);

  const data = {
    user_type: userType,
    login_type: enums.loginType.social,
    oauth_type: enums.oauthType.linkedin,
    code,
  };

  return API('POST', '/v1.1/user/register/oauth', data)
    .then((res) => {
      if (!res.success) {
        handleAuthError(res.message, dispatch);

        return;
      }

      if (res.result.userType === enums.userType.ap) {
        ReactGA.pageview('/register/success/ap');
      } else {
        ReactGA.pageview('/register/success/ar');
      }

      sendAnalyticsEventRegistration(getState().userState.analytics);

      dispatch({
        type: REGISTER_USER,
        payload: {
          user: res.result,
        },
      });

      return dispatch(replace('/register/confirm-auth'));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const socialSignIn = (socialId) => async (dispatch, getState) => {
  const data = {
    login_type: enums.loginType.social,
    social_id: socialId,
  };

  API('POST', '/v1.1/user/signin', data)
    .then((res) => {
      if (!res.success) {
        handleAuthError(res.message, dispatch);

        return;
      }

      dispatch({
        type: REGISTER_USER,
        payload: {
          user: res.result,
        },
      });
      dispatch(checkUserSteps(res.result));
      if (res.result.userType === enums.userType.ap) {
        dispatch(replace('/dashboard'));
      } else if (res.result.userType === enums.userType.ar) {
        const { ongoingBooking } = getState().userState;

        if (ongoingBooking.start) {
          if (res.result.personalDetailsCompleted && res.result.isBusinessRegistered) {
            return dispatch(replace('/book/confirm'));
          }

          return dispatch(replace('/quick-registration-ar'));
        }

        return dispatch(replace('/search'));
      }

      return true;
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const connectCalendar = (code, userid, type) => (dispatch) => {
  const data = {
    code,
    user_id: userid,
    oauth_type: type,
  };

  API('POST', '/v1/user/connect/calender', data)
    .then(() => {
      dispatch(replace('/myaccount'));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const googleRegister = (code, userType) => async (dispatch, getState) => {
  const data = {
    user_type: userType,
    login_type: enums.loginType.social,
    oauth_type: enums.oauthType.Google,
    code,
  };

  return API('POST', '/v1.1/user/register/oauth', data)
    .then((res) => {
      if (!res.success) {
        handleAuthError(res.message, dispatch);

        return;
      }

      if (res.result.userType === enums.userType.ap) {
        ReactGA.pageview('/register/success/ap');
      } else {
        ReactGA.pageview('/register/success/ar');
      }

      sendAnalyticsEventRegistration(getState().userState.analytics);

      dispatch({
        type: REGISTER_USER,
        payload: {
          user: res.result,
        },
      });

      return dispatch(replace('/register/confirm-auth'));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const upcomingSession = () => (dispatch) => {
  API('GET', '/v1.1/booking/upcoming')
    .then((res) => {
      dispatch({
        type: UPCOMING_SESSION,
        payload: res.result,
      });
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const useronKycConfirm = (status) => (dispatch) => {
  dispatch({
    type: kycConfirm,
    payload: status,
  });
};

export const userBuisnessDetails = () => (dispatch) => {
  API('GET', '/v1/business')
    .then((res) => {
      dispatch({
        type: FETCH_BUISNESS_DETAILS_USER,
        payload: res.result[0],
      });
      dispatch(updateProfile());
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const bookConfirmAction = (apvatno, apcountryid) => (dispatch) => {
  API('GET', '/v1/business')
    .then((res) => {
      dispatch({
        type: FETCH_BUISNESS_DETAILS_USER,
        payload: res.result[0],
      });

      dispatch(
        calculateVat({
          ap_country_code: apcountryid,
          ar_country_code: res.result[0].countryCode,
          ar_vat_no: res.result[0].vatNo,
          ap_vat_no: apvatno,
        })
      );
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const updateBuisnessDetails = (data, user) => (dispatch) => {
  dispatch({
    type: FETCH_BUISNESS_DETAILS_USER,
    payload: data,
  });

  if (data.mangopayUserId) {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        isBusinessRegistered: true,
        mangopayUserId: data.mangopayUserId,
      },
    });
  } else {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        isBusinessRegistered: true,
      },
    });
  }

  dispatch(updateProfile(user));
};

export const editProfile = (user) => (dispatch) => {
  dispatch({
    type: EDIT_PROFILE,
    payload: user,
  });

  dispatch(checkUserSteps(user));
};

export const showCongratsMsg = () => (dispatch) => {
  return dispatch({
    type: ACCOUNT_COMPLETE,
    payload: 5,
  });
};

export const uploadUserProfileImage = (user) => (dispatch) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const NewForm = new FormData();
  const image = user.image1;
  NewForm.append('image1', image);

  API('PUT', '/v1.1/profile/edit', NewForm, headers, { modifyBody: false })
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.result,
      });

      dispatch(checkUserSteps(res.result));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const uploadArUserProfileImage = (user) => (dispatch) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const NewForm = new FormData();
  const image = user.image1;
  NewForm.append('image1', image);

  const userData = {
    first_name: user.firstName,
    last_name: user.lastName,
    date_of_birth: user.dateOfBirth,
    currency_id: user.currencyId,
    country_id: user.countryId,
  };

  dispatch(updateProfile(userData));

  API('PUT', '/v1.1/profile/edit', NewForm, headers, { modifyBody: false })
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.result,
      });

      dispatch(checkUserSteps(res.result));
    })
    .catch((err) => {
      return alert(err.message);
    });
};

export const newError = () => (dispatch) => {
  dispatch(error());
};

export const addNewCard = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      isCardRegistered: true,
    },
  });

  dispatch(
    updateProfile({
      is_card_registered: true,
    })
  );
};

export const accountCompleted = (step) => (dispatch) => {
  dispatch({
    type: ACCOUNT_COMPLETE,
    payload: step,
  });
};

export const trackAdvert = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ADVERT,
    payload,
  });
};

export const deleteUserState = () => (dispatch) => {
  dispatch({
    type: LOG_OUT,
    payload: {
      user: {},
    },
  });
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ER,
    payload: {},
  });
};

export const userOauthData = (urlfrom) => (dispatch) => {
  dispatch({
    type: oauthData,
    payload: {
      oauthData: {
        urlfrom,
      },
    },
  });
};

export const socialRegisterUserType = (usertype) => (dispatch) => {
  dispatch({
    type: USERTYPE_REGISTER,
    payload: {
      user: {
        userType: usertype,
      },
    },
  });
};

export const forgetPassword = (email) => (dispatch) => {
  API('POST', '/v1/user/forget', { email });
  dispatch({
    type: FORGET_PASSWORD,
    payload: {},
  });
};

export const clearNewRequest = () => (dispatch) => {
  dispatch({
    type: NEW_REQUEST,
    payload: {
      isLoading: false,
      isError: false,
    },
  });
};

export const formHandling = (data) => (dispatch) => {
  dispatch({
    type: USER_FORM_HANDLING,
    payload: data,
  });
};

export const handleOptin = (data) => (dispatch) => {
  dispatch({
    type: HANDLE_OPT_IN,
    payload: data,
  });
};

export const handleArOnChange = (data) => (dispatch) => {
  dispatch({
    type: HANDLE_AR_ONCHANGE,
    payload: data,
  });
};
