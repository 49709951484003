import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';
import styles from './EditInput.module.css';
import 'react-phone-input-2/lib/style.css';

class EditInputMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: false,
    };
  }

  componentDidMount() {
    const { required, value } = this.props;
    if (required && value.length === 0) {
      this.setState({ required: true });
    }
  }

  hideLabel = (e) => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(e);
    }
  };

  onChange = (e, value) => {
    const { required, onChange } = this.props;
    const dialCodelength = value.dialCode.length + 1;
    if (required && dialCodelength >= e.length) {
      this.setState({ required: true });
    } else {
      this.setState({ required: false });
    }

    onChange(e, value);
  };

  render() {
    const { htmlFor, label, value, placeholder, name, countryCode } = this.props;
    const { required } = this.state;

    return (
      <div className={styles.inputel} data-input-type="mobile">
        <label htmlFor={htmlFor} className={styles.label}>
          {label}
        </label>
        <ReactPhoneInput
          country={countryCode ? countryCode.toLowerCase() : 'de'}
          value={value}
          onChange={this.onChange}
          name={name}
          placeholder={placeholder}
          inputClass={`${styles.inputTel} ${required ? styles.inputField : ''}`}
          buttonClass={required ? styles.inputField : ''}
        />
        {required && <p className={styles.error}>{label} Required</p>}
      </div>
    );
  }
}

EditInputMobile.propTypes = {
  placeholder: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  countryCode: PropTypes.string,
};

EditInputMobile.defaultProps = {
  value: '',
  countryCode: '',
  required: false,
  onBlur: () => {},
};

export default EditInputMobile;
