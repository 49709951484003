import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  fetchSingleProfile,
  fetchUserAvailablity,
  fetchUserAvailablityAll,
  syncAvailabiltyAll,
} from '../../../actions/profileAction';
import UserCalendar from './UserCalendar/UserCalendar';
import styles from './ProfileCalendar.module.css';
import { isObjectEmpty } from '../../../utils';
import PageLoader from '../../Common/PageLoader/PageLoader';

class ProfileCalendarEdit extends Component {
  render() {
    return (
      <div className={styles.profilemain}>
        {this.props.loading && <PageLoader />}
        {!this.props.loading ? (
          <div>
            <div
              className={styles.usercalendar}
              style={{ height: this.props.stepsCompleted ? '790px' : '940px' }}
            >
              <div className={styles.calendarshow}>
                {!isObjectEmpty(this.props.profile) && <UserCalendar {...this.props} />}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

ProfileCalendarEdit.propTypes = {
  match: PropTypes.object.isRequired,
  onFetchProfile: PropTypes.func.isRequired,
  onFetchAvailabilty: PropTypes.func.isRequired,
  onFetchAvailabiltyAll: PropTypes.func.isRequired,
  onSyncAvailabilty: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.profiles.loading,
    profile: state.userState.user,
    availbiltyDay: state.profiles.availbiltyDay,
    user: state.userState.user,
    availbiltyAll: state.profiles.availabilityAll,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchProfile: fetchSingleProfile,
      onFetchAvailabilty: fetchUserAvailablity,
      onFetchAvailabiltyAll: fetchUserAvailablityAll,
      onSyncAvailabilty: syncAvailabiltyAll,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(ProfileCalendarEdit);
