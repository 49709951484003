import React from 'react';
import styles from './styles.module.scss';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { User } from '../../../user-card/user';
import { MoreNavMenu } from './more-nav';

interface NavMenuProps {
	isLoggedIn?: boolean;
	user?: User;
	onClose(): void;
}

export const NavMenu = ({
	onClose,
}: NavMenuProps): JSX.Element => {
	const ref = React.useRef<HTMLDivElement>(null);

	// Call onClose callback to close the menu
	useOnClickOutside(ref, (event) => {
		event.stopPropagation();

		onClose();
	});

	return (
		<>
			<div className={styles.navMenuContainer}>
				<div className={styles.navMenuBg}>
					<div ref={ref} className={styles.navMenuBgLayout}>
						<MoreNavMenu />
					</div>
				</div>
			</div>
		</>
	);
};
