import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from '../EditMyProfile.module.css';
import EditTooltip from '../../../Common/EditTooltip/EditTooltip';
import EditTextArea from '../../../Common/EditTextArea/EditTextArea';
import servicedescription from '../../../Common/assets/service_description.svg';
import { alert } from '../../../../utils';
import messages from '../../../../config/message';

class ServiceDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      required: false,
      serviceDescription: '',
    };

    this.onTooltipClick = this.onTooltipClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmitData = this.onSubmitData.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const { serviceDescription } = user;

    this.setState({
      serviceDescription,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { required, requiredIn } = this.props;
    if (required && requiredIn !== 'sd') {
      document.removeEventListener('click', this.handleOutsideClick, false);
      if (prevState.edit) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ edit: false });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onTooltipClick = () => {
    const { edit } = this.state;
    if (!edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    return this.setState({ edit: !edit });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitData = () => {
    const { user, onEditProfile, onUpdateProfile } = this.props;
    const { serviceDescription } = this.state;

    const userState = {
      serviceDescription: serviceDescription.trim().replace(/\s+/g, ' '),
    };

    const userStateServer = {
      service_description: userState.serviceDescription,
    };

    const userSync = { ...user, ...userState };

    if (userState.serviceDescription.length >= 300) {
      this.setState({ required: false });
      onEditProfile(userSync);
      onUpdateProfile(userStateServer);
    } else {
      this.setState({ required: true });
    }
  };

  handleOutsideClick = (e) => {
    if (this.editelem && this.editelem.contains(e.target)) {
      return true;
    }

    this.onSubmitData();

    const { required } = this.state;
    const { onRequired } = this.props;
    if (required) {
      alert(messages.requiredFieldsMissing);
      return onRequired(true, 'sd');
    }
    onRequired(false, '');
    return this.onTooltipClick();
  };

  render() {
    const { serviceDescription, edit } = this.state;

    return (
      <div
        className={edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={servicedescription} alt="Service Description" />
          <p className={styles.atitle}>Service Description</p>
          <p className={styles.rerror}>* Required</p>
          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={edit} />
          </div>
        </div>
        {!edit && serviceDescription && serviceDescription.length > 0 && (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <div className={styles.alisub}>{serviceDescription}</div>
            </div>
          </div>
        )}
        {edit && (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              {/* <p className={styles.rserror}>{serviceDescription.length}</p> */}
              <EditTextArea
                htmlFor="Service Description"
                label="Service Description"
                value={serviceDescription}
                placeholder="SERVICE DESCRIPTION"
                onChange={this.onChange}
                name="serviceDescription"
                required
                notice="Service description should be a minimum of 300 characters to max 1000 characters."
                maxLength={1000}
                minLength={300}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ServiceDescription.propTypes = {
  user: PropTypes.shape({
    serviceDescription: PropTypes.string,
  }).isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onRequired: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  requiredIn: PropTypes.string.isRequired,
};

export default ServiceDescription;
