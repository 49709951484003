import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducers from './userReducers';
import requestReducers from './requestReducers';
import profileReducers from './profileReducers';
import sessionReducers from './sessionReducers';
import tempReducers from './tempReducers';
import masterReducers from './masterReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    userState: userReducers,
    request: requestReducers,
    profiles: profileReducers,
    session: sessionReducers,
    temp: tempReducers,
    masterdata: masterReducers,
  });
