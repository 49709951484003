/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Headers from '../Headers/Headers';
import style from './style.module.css';
import placholderImg1x from '../Common/assets/depression-placeholder.png';
import placholderImg2x from '../Common/assets/depression-placeholder@2x.png';
import placholderImg3x from '../Common/assets/depression-placeholder@3x.png';
import Result from './Result';
import { fetchProfileData } from '../../services/userService';
import arrowico from '../Common/assets/arrow_gray_white.svg';
import ProfileCard from '../Home/ProfileCard/ProfileCard';
import { sendEmail } from '../../services/sendgrid';
import { reactAppEnv } from '../../utils';
import { uploadMentalHealthTestResult } from '../../client/PsychTest/services/uploadMentalHealthTestResult';

const answers = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

class DepressionTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isChecked: false,
      checkedError: false,
      emailError: '',
      animate: false,
      showResult: false,
      profiles: [],
      result: '',
      resultLong: '',
      questions: [
        {
          id: 1,
          question: 'Little interest or pleasure in doing things?',
          answer: '',
          questionId: 'con100',
        },
        {
          id: 2,
          question: 'Feeling down, depressed, or hopeless?',
          answer: '',
          questionId: 'con101',
        },
        {
          id: 3,
          question: 'Trouble falling or staying asleep, or sleeping too much?',
          answer: '',
          questionId: 'con102',
        },
        {
          id: 4,
          question: 'Feeling tired or having little energy?',
          answer: '',
          questionId: 'con103',
        },
        {
          id: 5,
          question: 'Poor appetite or overeating?',
          answer: '',
          questionId: 'con104',
        },
        {
          id: 6,
          question:
            'Feeling bad about yourself - or that you are a failure or have let yourself or your family down?',
          answer: '',
          questionId: 'con105',
        },
        {
          id: 7,
          question:
            'Trouble concentrating on things, such as reading the newspaper or watching television?',
          answer: '',
          questionId: 'con106',
        },
        {
          id: 8,
          question:
            'Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?',
          answer: '',
          questionId: 'con107',
        },
        {
          id: 9,
          question:
            'Thoughts that you would be better off dead, or of hurting yourself in some way?',
          answer: '',
          questionId: 'con108',
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchSuggestedProfiles();
  }

  fetchSuggestedProfiles = () => {
    let userNames = ['joemcgregor', 'tedbundy', 'markjacobs'];

    if (reactAppEnv === 'production') {
      userNames = ['markeaton1', 'byronathene1', 'sotiriosshort1'];
    }

    userNames.forEach(async (user) => {
      const userData = await fetchProfileData(user);
      if (userData) {
        this.setState((prevState) => ({
          profiles: [...prevState.profiles, userData],
        }));
      }

      return true;
    });
  };

  onAnswerSelected = (question, answer) => {
    const { questions } = this.state;
    const selectedQuestion = questions.find((item) => item.id === question);
    if (selectedQuestion) {
      selectedQuestion.answer = answer;
      this.setState({ questions });
    }
  };

  getResult = () => {
    this.removeErrorAnswerMsg();

    const { isChecked, email } = this.state;

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let emailError = "";

    const emailTrim = email.trim()

    if (emailTrim && !emailRegex.test(emailTrim)) {
        emailError = 'Enter a valid email address';
    }

    if (emailTrim && !isChecked) {
      this.setState({ checkedError: true, animate: true });
      setTimeout(() => {
        this.setState({ animate: false });
      }, 2000);
    } 

    if (emailError || (emailTrim && !isChecked)){
      this.setState({ emailError, checkedError: !isChecked });
      return false;
    }

    this.calculateResult();

    return true;
  };

  handleRequiredError = (ques) => {
    const quesElem = document.getElementById(ques.questionId);
    const errorElem = document.createElement('p');
    errorElem.innerHTML = '* All questions are mandatory';
    errorElem.className = cx(style.error, style.errorAnimate, style.mb);
    errorElem.id = 'error-answer';

    quesElem.appendChild(errorElem);

    quesElem.scrollIntoView({ behavior: 'smooth' });
  };

  removeErrorAnswerMsg = () => {
    const errorElem = document.getElementById('error-answer');
    if (errorElem) {
      errorElem.remove();
    }
  };

  redirectToPages = () => {
    const urlLocation = this.props.location;
    const newUrl = new URLSearchParams(urlLocation.search);
    const redirectUrl = 'https://pages.conrati.com/depression-test-results';

    const redirectPagesUrl = new URL(redirectUrl);

    const { result, email } = this.state;

    redirectPagesUrl.searchParams.append('result', result);
    redirectPagesUrl.searchParams.append('email', email);
    newUrl.forEach((val, key) => redirectPagesUrl.searchParams.append(key, val));

    window.open(redirectPagesUrl.href, '_blank');
  };

  calculateResult = async () => {
    const { questions } = this.state;

    let totalMarks = 0;

    for (const ques of questions) {
      if (ques.answer === '') {
        return this.handleRequiredError(ques);
      }

      totalMarks += ques.answer;
    }

    window.scrollTo(0, 0);

    if (totalMarks >= 0 && totalMarks <= 4) {
      await this.setState({
        result: 'NO DEPRESSION',
        resultLong:
          'Your answers suggest you may not be suffering from depression. Still if you feel something isn’t quite right we recommend you schedule an appointment with a mental health professional.',
      });
    }

    if (totalMarks >= 5 && totalMarks <= 9) {
      await this.setState({
        result: 'MILD DEPRESSION',
        resultLong:
          'You appear to be suffering from mild depression. Consider watchful waiting, and testing again normally within two weeks. Additionally, we suggest it would be prudent to schedule an appointment with a mental health professional.',
      });
    }

    if (totalMarks >= 10 && totalMarks <= 14) {
      await this.setState({
        result: 'MODERATE DEPRESSION',
        resultLong:
          'Your answers suggest you are suffering from moderate depression. Consider watchful waiting, and testing again normally within two weeks. Additionally, we suggest it would be prudent to schedule an appointment with a mental health professional.',
      });
    }

    if (totalMarks >= 15 && totalMarks <= 19) {
      await this.setState({
        result: 'MODERATE SEVERE DEPRESSION',
        resultLong:
          'Your answers suggest you are suffering from moderate to severe depression. It doesn’t necessarily mean you have depression but is important that you schedule an appointment with a mental health professional.',
      });

      
    }

    if (totalMarks >= 20 && totalMarks <= 27) {
      await this.setState({
        result: 'SEVERE DEPRESSION',
        resultLong:
          'Your answers suggest you are suffering from severe depression. It is important that you schedule an appointment with a mental health professional.',
      });
    }

    await this.checkAndSendEmail();
    this.redirectToPages();

    return true;
  };

  checkAndSendEmail = async () => {
    const { email, result, resultLong } = this.state;
    if (email) {
      const searchURL = new URLSearchParams(this.props.location.search);
      const utmCampaign = searchURL.get("utm_campaign") || ''

      await uploadMentalHealthTestResult({
        testName: 'depression-test',
        testResult: result,
        email,
        utmCampaign
      });
    }
  };

  canSeeRecomendedProfiles = () => {
    return false;
  };

  render() {
    const {
      questions,
      email,
      isChecked,
      checkedError,
      emailError,
      animate,
      showResult,
      result,
      resultLong,
      profiles,
    } = this.state;

    return (
      <div>
        <Headers />
        <div className={style.headerPlaceholder}>
          <img
            src={placholderImg1x}
            srcSet={`${placholderImg1x} 1x,${placholderImg2x} 2x,${placholderImg3x} 3x`}
            alt="Placeholder"
            className={style.placeHolder}
          />
          <div className={style.placeHolderText}>
            <p className={style.title}>
              {showResult ? `YOUR RESULT: ${result}` : 'Find out if you are depressed'}
            </p>
            {showResult && <p className={style.subTitle}>It is okay not to be okay</p>}
            {!showResult && (
              <p className={style.subTitle}>
                Take this quick depression test and <br /> you’ll get your anonymous results
                instantly
              </p>
            )}
            {!showResult && (
              <div className={style.startTest}>
                <button
                  type="button"
                  className={style.submit}
                  onClick={() => window.scrollTo({ top: 500, behavior: 'smooth' })}
                >
                  START YOUR TEST
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={style.mainContainerViewport}>
          <div className={style.mainContainer}>
            {showResult ? (
              <Result result={result} resultLong={resultLong} profiles={profiles} />
            ) : (
              <div className={style.quiz}>
                <p className={style.introContentTitle}>Depression Test (Official PHQ-9)</p>
                <div className={style.introContent}>
                  <p>
                    Below are 9 questions that relate to life experiences common among people who
                    have depression. The PHQ-9 has been validated for use in primary care.
                  </p>

                  <p>
                    Conrati believes assessments can be a valuable first step toward getting
                    treatment. All too often people stop short of seeking help out of fear their
                    concerns aren&apos;t legitimate or severe enough to warrant professional
                    intervention.
                  </p>

                  <p className={style.fontItalic}>
                    <strong>Please note:</strong> This quiz is NOT a diagnostic tool. Mental health
                    disorders can only be diagnosed by a licensed mental health professional or
                    doctor. Your privacy is important to us. All results are completely anonymous.
                  </p>
                  <p>
                    Please read each question carefully, and indicate how often you have experienced
                    the same or similar challenges in the past few weeks.
                  </p>
                </div>

                <div className={style.questions}>
                  {questions.map((question) => (
                    <div key={question.id} className={style.questionGroup} id={question.questionId}>
                      <p className={style.question}>{question.question}</p>
                      <div className={style.answers}>
                        {answers.map((item, index) => (
                          <label key={index} htmlFor={`q-${question.id}-answer-${index}`} className={style.answerItem}>
                            <input
                              type="radio"
                              name={question.question}
                              value={item}
                              id={`q-${question.id}-answer-${index}`}
                              checked={index === question.answer}
                              onChange={() => this.onAnswerSelected(question.id, index)}
                            />
                            <p>{item}</p>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={style.end}>
                  <p className={style.note}>
                    OPTIONAL - if you wish to receive your test results directly to your inbox and notification of our latest metal health blogs and podcasts (We try hard to make it great and will not bombard your inbox)
                  </p>
                  <div className={style.emailInput} id="email-input">
                    <input
                      type="email"
                      name="email"
                      className={emailError ? style.emailErrorInput : ''}
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      placeholder="Your Email Address"
                    />
                    {emailError && (

                    <div className={style.emailError}>
                        {emailError}
                    </div>
                    )}
                  </div>
                  <label htmlFor='checkbox' className={style.checkBox}>
                    <input
                      type="checkbox"
                      name="check"
                      id='checkbox'
                      defaultChecked={isChecked}
                      onChange={() => this.setState({ isChecked: !isChecked })}
                    />
                    <p
                      className={
                        checkedError && animate
                          ? cx(style.error, style.errorAnimate)
                          : checkedError
                          ? style.error
                          : null
                      }
                    >
                      Conrati will use your contact information you provide in this form to provide
                      marketing notifications. By providing your email address, you agree to
                      Conrati’s privacy policy
                    </p>
                  </label>
                  <div className={style.resultButton}>
                    <button type="button" className={style.submit} onClick={this.getResult}>
                      GET YOUR RESULTS
                    </button>
                  </div>

                  {this.canSeeRecomendedProfiles() && (
                    <div className={style.benefits}>
                      <div className={style.ciaction}>
                        <div className={style.ciahead}>
                          <p className={style.ctit}>Recommended Psychologists & Therapists</p>
                          <Link
                            to="/search?&rate=&category=&skills=psychologist,industrial%20psychologist,child%20psychologist,clinical%20psychologist,psychotherapist,counsellor,marriage%20counsellor&languages="
                            className={style.cba}
                          >
                            Find More <img className={style.aic} src={arrowico} alt="" />
                          </Link>
                        </div>
                        <div className={style.cards}>
                          <ProfileCard profiles={profiles} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DepressionTest;
