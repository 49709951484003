import React, { Component } from 'react';
import Select from 'react-select';
import styles from './EditSelect.module.css';

class EditSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      active: false,
      required: false,
    };

    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.selected) {
      this.setState({
        selectedOption: this.props.selected,
      });
    }

    if (this.props.required && !this.props.selected) {
      this.setState({
        required: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selected) {
      this.setState({
        selectedOption: newProps.selected,
      });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);

    if (this.props.required && !selectedOption) {
      this.setState({
        required: true,
      });
    } else {
      this.setState({
        required: false,
      });
    }
  };

  handleBlur(e, type) {
    e.preventDefault();
    if (type === 1) {
      this.setState({ active: true });
      this.props.onActive(1);
    }

    if (type === 2) {
      this.setState({ active: false });
      this.props.onActive(2);
    }
  }

  render() {
    const { selectedOption } = this.state;
    const { label } = this.props;

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '9px',
        boxShadow: 'none',
        borderColor: state.isFocused ? '#254f89' : '#d4d4d4',
        '&:hover': {
          borderColor: state.isFocused ? '#254f89' : '#d4d4d4',
        },
      }),
      singleValue: (provided) => {
        const fontSize = '0.8125em';
        const color = '#555';

        return { ...provided, fontSize, color };
      },
      placeholder: (provided) => {
        const fontSize = '0.8125em';
        const color = 'rgb(211, 198, 198);';

        return { ...provided, fontSize, color };
      },
    };

    return (
      <div className={styles.select}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          styles={customStyles}
          isDisabled={this.props.isDisabled || false}
          onFocus={(e) => this.handleBlur(e, 1)}
          onBlur={(e) => this.handleBlur(e, 2)}
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'off',
          }}
        />
        {this.state.required && <p className={styles.error}>{label} Required</p>}
      </div>
    );
  }
}

export default EditSelect;
