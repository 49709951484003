import React, { useRef } from 'react';
import styles from './styles.module.scss';
import { Transition } from 'react-transition-group';

/*
 * Conrati native modal
 **/

interface NativeModalProps {
	children?: React.ReactNode;
	isOpen: boolean;
	className?: string;
	backdrop?: boolean;
}

// Get classname from transition state
function getTransitionClass(state: string): unknown {
	switch (state) {
	case 'entering':
		return styles.entering;
	case 'entered':
		return styles.entered;
	case 'exiting':
		return styles.exiting;
	case 'exited':
		return styles.exited;
	default:
		return '';
	}
}

export const NativeModal = ({
	isOpen,
	children,
	backdrop,
	className,
}: NativeModalProps): JSX.Element => {
	// Set ref for modal
	const modalRef = useRef<HTMLDivElement>(null);

	return (
		<Transition nodeRef={modalRef} in={isOpen} timeout={200}>
			{(state): JSX.Element => (
				<div
					ref={modalRef}
					className={`${styles.nativeUiModalRoot} ${
						state === 'exited' ? styles.nativeUiModalExited : ''
					}`}
					data-testid="native-ui-modal"
				>
					{backdrop && state !== 'exited' && (
						<div className={styles.backDrop} />
					)}
					<div
						className={`${styles.modal} ${className || ''} ${getTransitionClass(
							state
						)}`}
						data-testid="native-ui-modal-container"
					>
						{children}
					</div>
				</div>
			)}
		</Transition>
	);
};
