import React, { forwardRef } from 'react';
import styles from './style.module.scss';
import checkMarkIcon from '../../assets/check-mark.svg';

interface CheckboxProps {
	/** The id of the `input` element. */
	id: string;
	name?: string;
	/** The default checked state. Use when the component is not controlled. */
	defaultChecked?: boolean;
	/** If `true`, the component is checked. Control the checkbox with the checked and onChange props */
	checked?: boolean;
	/** Callback fired when the state is changed. */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string;
	/** If `true`, the component is disabled. */
	disabled?: boolean;
	/** Make the checkbox have a round border */
	round?: boolean;
	labelStyle?: string;
}

// TODO: Add unit test for the native checkbox component
export const Checkbox = forwardRef(
	(
		props: CheckboxProps,
		ref: React.ForwardedRef<HTMLInputElement>
	): JSX.Element => {
		const {
			id,
			name,
			defaultChecked,
			label,
			disabled,
			checked,
			onChange,
			round,
			labelStyle,
		} = props;

		return (
			<label className={styles.checkbox} htmlFor={id}>
				<input
					data-testid="ui-native-checkbox-input"
					className={styles.checkboxInput}
					ref={ref}
					disabled={disabled}
					onChange={onChange}
					checked={checked}
					defaultChecked={defaultChecked}
					type="checkbox"
					name={name}
					id={id}
				/>
				<div className={styles.checkboxContent}>
					<div className={styles.checkboxIcon}>
						<div
							className={`${styles.checkMark} ${
								round ? styles.roundMark : ''
							}`}
						/>
						<div
							className={`${styles.checkedMarkContainer} ${
								round ? styles.roundMark : ''
							}`}
						>
							<div className={styles.checkedMark}>
								<img src={checkMarkIcon} alt="Checkbox Icon" />
							</div>
						</div>
					</div>

					{label && (
						<span
							className={`${styles.checkboxLabel} ${
								labelStyle || ''
							}`}
							data-testid="ui-native-checkbox-label"
						>
							{label}
						</span>
					)}
				</div>
			</label>
		);
	}
);

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
	disabled: false,
};
