/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }

  componentDidMount() {
    const { isChecked } = this.props;

    this.setState({
      toggle: isChecked,
    });
  }

  componentDidUpdate(prevProps) {
    this.onUpdate(prevProps);
  }

  onUpdate = (prevProps) => {
    const { isChecked } = this.props;

    if (prevProps.isChecked === isChecked) {
      return false;
    }

    return this.setState({
      toggle: isChecked,
    });
  };

  handleOnChange = async () => {
    const { onChange, isDisabled } = this.props;

    if (isDisabled) {
      return false;
    }

    await this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));

    const { toggle } = this.state;

    return onChange(toggle);
  };

  render() {
    const { toggle } = this.state;
    const { switchStyle, checkboxStyle, name } = this.props;

    return (
      <div className={styles.tswitch}>
        <input
          type="checkbox"
          checked={toggle}
          onChange={this.handleOnChange}
          className={checkboxStyle || styles.checkbox}
          id={name}
        />
        <label htmlFor={name} className={switchStyle || styles.switch} />
        <p className={styles.ttext}>{toggle ? 'Yes' : 'No'}</p>
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  switchStyle: PropTypes.string,
  checkboxStyle: PropTypes.string,
  isChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  switchStyle: '',
  checkboxStyle: '',
  isChecked: false,
  isDisabled: false,
};

export default ToggleSwitch;
