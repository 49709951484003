import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  initalTransformSize,
  testominals,
  benifitTitle,
  benefitsList,
  buttonText,
  userCardPlaceholder,
  blogTitle,
  blogsList,
  popularItemsList,
  sliderSubTitle,
  sliderTitle,
  sliderUserList,
  themeTitle,
  profileUrl,
  blogCategoryId,
} from './data';
import LandingPageTheme from '../Theme';

function Psychologist({ history }) {
  const data = {
    initalTransformSize,
    testominals,
    benifitTitle,
    benefitsList,
    buttonText,
    userCardPlaceholder,
    blogTitle,
    blogsList,
    popularItemsList,
    themeTitle,
    profileUrl,
    blogCategoryId,
  };

  useEffect(() => {
    // todo: where should I get the value for isLoggedIn?
    const isLoggedIn = false;

    if (!isLoggedIn) {
      // expires config is in days
      Cookies.set('seeking_advice_from_psychologist', '1', { expires: 1 });
    }
  }, []);

  const sliderData = {
    sliderSubTitle,
    sliderTitle,
    sliderUserList,
    popularItemsList,
    themeTitle,
  };

  return <LandingPageTheme data={data} sliderData={sliderData} history={history} />;
}

Psychologist.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default Psychologist;
