import * as React from 'react';
import Header from './header';
import HeroSection from './hero-section';
import WorldOfTherapists from './world-of-therapists';
import HappinessResources from './happiness-resources';
import Testimonials from './testimonials';
import HowItSection from './how-it-section';
import TherapyHealthSection from './therapy-health-section';
import SelfAssessment from './self-assessment';
import TherapistInAction from './therapist-in-action';

export default function QuizPage(): JSX.Element {
	return (
		<>
			<Header />
			<HeroSection />
			<TherapistInAction />
			<SelfAssessment />
			<HowItSection />
			<HappinessResources />
			<WorldOfTherapists />
			<TherapyHealthSection />
			<Testimonials />
		</>
	);
}
