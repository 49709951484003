import React from 'react';
import styles from '../Testimonials.module.scss';
import ratingStarFive from '../../../assets/svg/5star.svg';

type TestimonialProps = {
	name: string;
	comment: string;
	star: number;
};

export default function TestimonialItem(props: TestimonialProps): JSX.Element {
	return (
		<div className={styles.testimonialItem}>
			<div className={styles.leftTestimonialDetail}>
				<img
					data-testid="quiz-testimonial-item-star"
					src={ratingStarFive}
					alt="star"
				/>
				<p
					data-testid="quiz-testimonial-item-user-name"
					className={styles.testimonialUserName}
				>
					{props.name}
				</p>
			</div>
			<div className={styles.rightTestimonialDetail}>
				<p
					data-testid="quiz-testimonial-item-comment"
					className={styles.testimonialComment}
				>
					{props.comment}
				</p>
			</div>
		</div>
	);
}
