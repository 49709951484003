/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Headers from '../Headers/Headers';
import styles from './Dashboard.module.css';
import upcomingIco from '../Common/assets/upcoming.svg';
import { upcomingSession, updateProfile } from '../../actions/userAction';
import { weburl, alert as alertUtil, checkIsMobileBrowser, connectAppUrl } from '../../utils';
import messages from '../../config/message';
import globalProptype from '../../config/proptype';
import nobooking from '../Common/assets/no_booking.svg';
import smallbg from '../Common/assets/small.svg';
import sessionnew from '../Common/assets/session_new.svg';
import enums from '../../config/enum';
import Cancel from './Notification/Cancel';
import BookingDetailItem from './BookingDetailItem';
import { Track } from '@conrati/tracking';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newbookingid: '',
      cancel: false,
      apForwardUsername: '',
    };
  }

  componentDidMount() {
    const { stepsCompleted, user, history, onUpdateProfile, onFetchUpcomingSession, location } =
      this.props;
    if (!stepsCompleted && user.userType === enums.userType.ap) {
      alertUtil(messages.apIncompleteAccunt);
      return history.replace('/myprofile/edit/step');
    }

    onUpdateProfile();
    const searchparams = new URLSearchParams(location.search);

    if (searchparams.has('booking_id')) {
      this.setState({
        newbookingid: searchparams.get('booking_id'),
      });
    }

    if (searchparams.has('cancel') && searchparams.has('ap_forward_username')) {
      if (history.action === 'REPLACE') {
        this.setState({
          cancel: true,
          apForwardUsername: searchparams.get('ap_forward_username'),
        });
      }
    }

    return onFetchUpcomingSession();
  }

  onJoinSession = (bookingid) => {
    const { user } = this.props;
    const isMobile = checkIsMobileBrowser(navigator.userAgent);

    Track("button-click-upcoming-session-page-join-session", {
        email: user.email,
        userId: user.userId,
        userType: user.userType,
        bookingId: bookingid,
        isMobile,
    });

    return window.open(`${connectAppUrl}?sessionId=${bookingid}&appToken=${this.props.user.userToken}&action=join`);
  };

  onMore = (bookingid) => {
    const { history } = this.props;
    history.push(`/session/details?booking_id=${bookingid}`);
  };

  render() {
    const { upcomingSessions, user } = this.props;
    const { apForwardUsername, newbookingid, cancel } = this.state;

    return (
      <div>
        <Headers />
        <div className={styles.head} style={{ backgroundImage: `url(${smallbg})` }}>
          <p className={styles.ptime}>{moment().format('hh:mm dddd')}</p>
          <p className={styles.pdate}>{moment().format('DD MMMM YYYY')}</p>
        </div>
        <div className={styles.dtit}>
          <p className={styles.db}>DASHBOARD</p>
        </div>

        {newbookingid && (
          <div className={styles.newbooking}>
            <div className={styles.newbookingnot}>
              <img src={sessionnew} alt="New Booking" />
              <div className={styles.info}>
                <p className={styles.infot}>Session Booked!</p>
                <p className={styles.infost}>
                  You have successfully booked a session. It will appear at the top of your
                  “Upcoming sessions” on your dashboard. From there you can get the session link and
                  view the booking statement.
                </p>
              </div>
            </div>
          </div>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {cancel && <Cancel {...this.props} username={apForwardUsername} />}
        <div className={styles.upsession}>
          {' '}
          <div className={styles.upsessioncon}>
            <div className={styles.utitle}>
              <img src={upcomingIco} alt="upcoming_session" width="38px" height="38px" />
              <p>Upcoming sessions</p>
            </div>

            <div className={styles.ubody}>
              {upcomingSessions &&
                upcomingSessions.map((session, index) => (
                  <BookingDetailItem
                    key={index}
                    session={session}
                    user={user}
                    onJoinSession={this.onJoinSession}
                    onMore={this.onMore}
                  />
                ))}
              {upcomingSessions.length === 0 && (
                <div className={styles.nobooking}>
                  <img src={nobooking} alt="No Booking" className={styles.dimg} />
                  <p className={styles.ntitle}>
                    {user.accountCategory === enums.accountCategory.full
                      ? 'You have no upcoming sessions'
                      : 'Your booking will show here once confirmed'}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.shape({
    userType: PropTypes.number,
  }).isRequired,
  upcomingSessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFetchUpcomingSession: PropTypes.func.isRequired,
  history: globalProptype.history.isRequired,
  stepsCompleted: PropTypes.bool.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  location: globalProptype.location.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    upcomingSessions: state.temp.upcomingSessions,
    accountCompleted: state.userState.accountCompleted,
    stepsCompleted: state.userState.stepsCompleted,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchUpcomingSession: upcomingSession,
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Dashboard);
