import React from 'react';
import cx from 'classnames';
import styles from '../Static.module.css';
import aboutimage from '../../Common/assets/about_image.png';

const billing = () => {
  return (
    <div className={styles.brpage}>
      <div className={styles.brheader}>
        <h1>So how do we make a profit?</h1>
      </div>
      <div className={styles.brcontainer}>
        <div className={cx(styles.brrow, styles.mb3, styles.mt3)}>
          <div className={styles.brcolumn}>
            <h6 className={styles.brh6}>10% charge to Advice Providers</h6>
            <p className={styles.brp}>
              Conrati charge Advice Providers a platform fee of ten percent (10%) per booking in
              which the outcome of the booking was either a successful video consultation or a no
              show by the Advice Receiver (and the Advice Provider was present in the video session)
            </p>
            <p className={styles.brp}>
              The 10% fee charged by Conrati may be subject to VAT or other similar taxes depending
              on the laws of the Advice Provider’s jurisdiction. Please refer to our Terms and
              Conditions for more information or download scenarios to assist you with examples of
              how Conrati’s charges may apply.
            </p>
            <p className={styles.brp}>
              Conrati’s fee is deducted from the Advice Provider’s funds that are payable to the
              Advice Provider in respect of the online consultation. All Conrati related invoices
              can be downloaded on the application
            </p>
          </div>
          <div className={styles.brcolumn}>
            <h6 className={styles.brh6}>0% charge to Advice Receivers</h6>
            <p className={styles.brp}>
              Advice Receivers utilise the Conrati platform free of charge by Conrati.
            </p>
            <p className={styles.brp}>
              An Advice Receiver is however still liable to pay the Advice Provider in respect of
              the online consultation. The total monetary amount during the quotation of a
              consultation will be charged once the outcome of the booking was either a successful
              video consultation or a no show by the Advice Receiver (and the Advice Provider was
              present in the video session).
            </p>
          </div>
        </div>
        <div className={cx(styles.brhr, styles.mt3, styles.mb3)} />
        <div className={styles.abtitle}>
          <p className={cx(styles.abt, styles.tc)}>What do Advice Providers get in return?</p>
          <p className={styles.abtsub}>
            Conrati makes use of a third party payment provider (
            <a target="_open" href="https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_EN.pdf">
              MangoPay
            </a>
            ) to pre authorise the relevant credit / debit card used to schedule a booking. This
            secures Advice Provider’s funds as the pre authorisation renders the balance as
            unavailable on a Advice Receiver’s card until the outcome of the consultation is known.
          </p>
          <p className={cx(styles.mt2, styles.mb3, styles.abtsub)}>
            The Conrati platform affords Advice Providers the opportunity to build a Conrati based
            profile which acts as a personal online resume which is matched to our network of Advice
            Receiver’s requirements. The platform also allows Advice Providers to manage
            availability through a personal Conrati calendar, fulfill a booking through a live video
            session and be assisted with the necessary invoicing to Advice Receivers.
          </p>
        </div>
        <div className={styles.abimg}>
          <img src={aboutimage} alt="About Us" height="100%" />
        </div>
        <div className={styles.abtitle}>
          <p className={cx(styles.abt, styles.tc)}>
            Invoicing, currencies and other transaction fees
          </p>
          <p className={cx(styles.abtsub, styles.tca)}>
            To uphold best accounting business practice, Conrati's generation of invoices and
            deduction of funds on behalf of Advice Providers are always in the relevant Advice
            Provider’s currency. This ensures that consultants receive faster payouts, do not incur
            unnecessary conversion costs on payouts and are able to account for every cent of income
            earned through Conrati as the numerical amounts on invoices match the exact flow of
            funds.
          </p>
          <p className={cx(styles.abtsub, styles.tca)}>
            Advice Receivers are shown an estimate or quotation of a consultation based on their
            selected currency with which they registered. Whilst Conrati updates it’s conversion
            rates hourly through the use of a third-party conversion service, the estimated
            converted cost could differ slightly from the actual amount charged due to fluctuations
            and discrepancies in real-time exchange rates.
          </p>
          <p className={cx(styles.abtsub, styles.tca)}>
            Both Advice Provider and Advice Reciever bear their own bank or payment provider cost.
          </p>
        </div>
      </div>
    </div>
  );
};

export default billing;
