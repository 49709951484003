import {
  FETCH_ALL_PROFILE,
  FETCH_PROFILE,
  AVAILABLITY_DAY,
  AVAILABLITY_ALL,
  PROFILE_REQUEST,
  RANDOM_PROFILE,
  RANDOM_PROFILE_HOMEPAGE,
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  errorData: {},
  profiles: [],
  proflieData: {
    totalRecords: 0,
    totalPages: 0,
    limit: 0,
    page: 0,
    data: [],
  },
  profile: {},
  availbiltyDay: [],
  availabilityAll: {
    availability: {},
    customAvailability: [],
  },
  mentors: [],
  psychologists: [],
  bconsultants: [],
  growth: [],
  themeProfiles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ALL_PROFILE:
      return {
        ...state,
        proflieData: action.payload,
        loading: false,
      };
    case RANDOM_PROFILE:
      return {
        ...state,
        themeProfiles: action.payload,
        loading: false,
      };
    case RANDOM_PROFILE_HOMEPAGE:
      if (action.query === 'mentors') {
        return {
          ...state,
          mentors: action.payload,
          loading: false,
        };
      }

      if (action.query === 'psychology') {
        return {
          ...state,
          psychologists: action.payload,
          loading: false,
        };
      }

      if (action.query === 'business consulting') {
        return {
          ...state,
          bconsultants: action.payload,
          loading: false,
        };
      }

      if (action.query === 'growth') {
        return {
          ...state,
          growth: action.payload,
          loading: false,
        };
      }

      return {
        ...state,
        loading: false,
      };
    case FETCH_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case AVAILABLITY_DAY:
      return {
        ...state,
        availbiltyDay: action.payload,
        loading: false,
      };
    case AVAILABLITY_ALL:
      return {
        ...state,
        availabilityAll: { ...state.availabilityAll, ...action.payload },
        loading: false,
      };
    default:
      return state;
  }
};
