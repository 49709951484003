import * as React from 'react';
import { toCamelCase } from '../utils/naming-convention';
import styles from './Button.module.scss';

interface ButtonProps {
	children: React.ReactNode;

	/**
	 * Button variants
	 *
	 * buttons may be one of a variety of visual variants such as:
	 *
	 * '`plain', 'primary', 'secondary', 'outlined-plain'`
	 */
	variant?: 'plain' | 'primary' | 'secondary' | 'outlined-plain' | 'green';
	type?: 'button' | 'submit' | 'reset';
	className?: string;

	/**
	 * Disables the Button, preventing mouse events
	 */
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

	/** Providing a `href` will render an `<a>` element even if provided button type, _styled_ as a button. */
	href?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const Component = props.href ? 'a' : ('button' as React.ElementType);

		const styleVariant = props.variant;

		const inputProps = { ...props, variant: undefined };

		return (
			<Component
				{...inputProps}
				ref={ref}
				className={
					styleVariant
						? styles[toCamelCase(styleVariant)]
						: styles.default
				}
			>
				{props.children}
			</Component>
		);
	}
);

Button.displayName = 'Button';

Button.defaultProps = {
	type: 'button',
	className: undefined,
	disabled: false,
	variant: 'primary',
};

export default Button;
