import React from 'react';
import arrowImg from '../../assets/img/arrow.png';
import styles from './Arrow.module.scss';

type ArrowProps = {
	hasBackground?: boolean;
};

export default function Arrow(props: ArrowProps): JSX.Element {
	const { hasBackground } = props;

	return (
		<div className={hasBackground ? styles.arrowBackground : ''}>
			<img src={arrowImg} alt="Arrow" />
		</div>
	);
}

Arrow.defaultProps = {
	hasBackground: false,
};
