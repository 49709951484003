/* eslint-disable import/no-cycle */
import { alert, apiRequest } from '../utils';
import store from '../store';
import { newOngoingBooking } from '../actions/sessionAction';

export const connectCalendar = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1/user/connect/calender', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const shareLinkedinEmail = () => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1/user/email/sharelinkedin')
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editProfile = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('PUT', '/v1.1/profile/edit', data, {}, { modifyBody: false })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addUtmData = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/user/add-utm-data', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBusinessDetails = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/profile/business', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBusinessDetailsNatural = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/profile/business/natural', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAvailabilty = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('PUT', '/v1/user/availability', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Api deprecated. Do not use
 */
export const fetchDailyAvailabilty = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1/user/availability/date', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * New api for fetching availabilty
 */
export const fetchUserAvailablity = (userId, reqData) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', `/v2/availabilty/${userId}`, reqData, '', {
      modifyBody: false,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const monitorSession = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1/booking/session/monitor', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const joinVideoSession = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/session/join', data)
      .then((response) => {
        if (!response.success) alert(response.message);
        resolve(response.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const disconnectVideoSession = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/session/disconnect', data)
      .then((response) => {
        if (!response.success) alert(response.message);

        if (response.result.paymentUrl) {
          return window.location.href = response.result.paymentUrl;
        }

        resolve(response.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchQuotation = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/booking/quotation', data)
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error('Quotation generation failed');
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserProfileData = (data, username) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', `/v1.1/profile/${username}/booking`, data)
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const secureBooking = (data) => {
  return new Promise((resolve, reject) => {
    const date = new Date();

    const browserInfo = {
      AcceptHeader: 'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
      JavaEnabled: true,
      Language: window.navigator.language,
      ColorDepth: window.screen.colorDepth,
      ScreenHeight: window.screen.height,
      ScreenWidth: window.screen.width,
      TimeZoneOffset: date.getTimezoneOffset(),
      UserAgent: window.navigator.userAgent,
      JavascriptEnabled: true,
    };

    apiRequest('POST', '/v1.1/booking/secure', { ...data, browser_info: browserInfo })
      .then((response) => {
        if (response.success) {
          resolve(response.result);
          store.dispatch(newOngoingBooking({}));
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onAddBankAccount = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/bank/iban', data, {}, { modifyBody: false })
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onAddBankAccountOther = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/bank/other', data, {}, { modifyBody: false })
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onAddBankAccountGb = (data) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/bank/gb', data, {}, { modifyBody: false })
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onTriggerPayout = () => {
  return new Promise((resolve, reject) => {
    apiRequest('GET', '/v1.1/bank/payout')
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onReviewProfile = () => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', '/v1.1/profile/review')
      .then((response) => {
        if (response.success) {
          resolve(response);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onSearchSuggestion = (query) => {
  return new Promise((resolve, reject) => {
    apiRequest('GET', `/v1.1/profile/suggestion?query=${query}`)
      .then((response) => {
        if (response.success) {
          resolve(response.result.suggestion);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchRandomProfiles = (query, limit = 3) => {
  return new Promise((resolve, reject) => {
    apiRequest('POST', `/v1.1/profile/random?limit=${limit}&query=${query}`)
      .then((response) => {
        if (response.success) {
          resolve(response.result);
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchProfileData = (username) => {
  return apiRequest('POST', `/v1.1/profile/${username}`)
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const confirmBooking = (bookingId) => {
  return apiRequest('POST', '/v1.1/booking/confirm', {
    booking_id: bookingId,
  })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getBookingDetails = (bookingId) => {
  return apiRequest('GET', `/v1/booking/${bookingId}`)
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const addCardPreAuth = (data) => {
  return apiRequest('POST', '/v1/payment/preauthorize', data)
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const chargeCard = (data) => {
  return apiRequest('POST', '/v1.1/payment/charge-payment', data, {}, { modifyBody: false })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const validatePreAuth = (preAuthId) => {
  return apiRequest('POST', '/v1.1/payment/validate-preauth', {
    pre_authorization_id: preAuthId,
  })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const validatePayIn = (payInId) => {
  return apiRequest('POST', '/v1.1/payment/validate-payin', {
    pay_in_id: payInId,
  })
    .then((response) => {
      if (response.success) {
        return Promise.resolve(response.result);
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
