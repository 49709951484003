import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './style.module.scss';

function Sent(props) {
  const { msg } = props;
  const { message, name, timestamp } = msg;
  const nameBreak = name.split(' ');

  return (
    <div className={styles.sent}>
      <div className={styles.minfo}>
        <div className={styles.usl}>{`${nameBreak[0].charAt(0)}${nameBreak[1].charAt(0)}`}</div>
        <p className={styles.mtime}>{moment.unix(timestamp).format('hh:mm A')}</p>
        <p className={styles.mname}>{name}</p>
      </div>
      <div className={styles.message}>
        <p>{message}</p>
      </div>
    </div>
  );
}

Sent.propTypes = {
  msg: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
    timestamp: PropTypes.number,
  }).isRequired,
};

export default Sent;
