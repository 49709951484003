import React from 'react';
import Loader from '../Loader/Loader';
import styles from './PageLoader.module.css';

const PageLoader = () => {
  return (
    <div className={styles.pageloader}>
      <div className={styles.loader}>
        <Loader />
      </div>
    </div>
  );
};

export default PageLoader;
