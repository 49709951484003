import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './SwitchAccount.module.css';
import { swicthAccount } from '../../actions/userAction';
import padvice from '../Common/assets/padvice.svg';
import radvice from '../Common/assets/radvice.svg';
import enums from '../../config/enum';

const EXCEPTION_PAGES = ['/book/confirm', '/book'];

class SwitchAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: '',
    };
  }

  componentDidMount() {
    const searchParam = new URLSearchParams(window.location.search);

    this.setState({
      redirectTo: searchParam.get('redirect'),
    });
  }

  handleSwitchAccount = (e) => {
    e.preventDefault();

    let userType;
    let redirectTo = this.state.redirectTo.toLowerCase();

    if (this.props.user.userType === enums.userType.ap) {
      userType = enums.userType.ar;
    }

    if (this.props.user.userType === enums.userType.ar) {
      userType = enums.userType.ap;

      if (
        EXCEPTION_PAGES.includes(redirectTo) || 
		redirectTo.includes('/calendar')
      ) {
        redirectTo = '';
      }
    }

    const user = {
      userType,
      userToken: this.props.user.userToken,
      redirectTo,
    };

    this.props.onSwitchAccount(user);
  };

  onCancelButton = () => {
    const { history } = this.props;

    if (history.length < 2) {
      return window.close();
    }
    return history.goBack();
  };

  render() {
    return (
      <div className={styles.marketing}>
        {this.props.user.userType === enums.userType.ar ? (
          <div className={styles.marketingcard}>
            <div className={styles.micard}>
              <p className={styles.mtitle}>You are now switching to an Advice Provider account!</p>
              <p className={styles.msub}>
                Your advice provider account allows you to provide advice. <br /> You can Switch
                between your advice provider and advice receiver account <br />
                through your navigation bar.
              </p>
              <img src={padvice} alt="Provide advice" />
            </div>
            <div className={styles.buttonss}>
              <button className={styles.cbtn} onClick={() => this.onCancelButton()}>
                CANCEL
              </button>
              <button className={styles.btn} onClick={this.handleSwitchAccount}>
                CONTINUE
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.marketingcard}>
            <div className={styles.micard}>
              <p className={styles.mtitle}>You are now switching to an Advice Receiver account!</p>
              <p className={styles.msub}>
                Your advice receiver account allows you to book a consultant. <br /> You can Switch
                between your advice provider and advice receiver account <br />
                through your navigation bar.
              </p>
              <img src={radvice} alt="Recieve advice" />
            </div>
            <div className={styles.buttonss}>
              <button className={styles.cbtn} onClick={() => this.onCancelButton()}>
                CANCEL
              </button>
              <button className={styles.btn} onClick={this.handleSwitchAccount}>
                CONTINUE
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapActionToPrps = (dispatch) => {
  return bindActionCreators(
    {
      onSwitchAccount: swicthAccount,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToPrps)(SwitchAccount);
