import { Component } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import { apiRequest } from '../../../utils';
import Messages from '../../../config/message';

class Oauthpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receivedMsg: false,
    };

    // STEP 1: create a container <div>
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
    this.oauthInterval = null;
    this.sharingToast = null;
    this.timestamp = 0;
  }

  componentDidMount() {
    const { url } = this.props;

    this.sharingToast = toast.warn('Sharing Post...', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open(url, 'Oauth', 'width=800,height=400');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);

    this.externalWindow.addEventListener('unload', this.onWindowUnload);
    this.externalWindow.focus();

    window.addEventListener('message', this.receiveMessage, false);

    this.oauthInterval = setInterval(() => {
      const { receiveMessage } = this.state;
      if (this.externalWindow.closed && !receiveMessage) {
        const { shareStatus } = this.props;
        shareStatus(false);
        clearInterval(this.oauthInterval);
      }
    }, 1000);
  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    const { onClose } = this.props;
    onClose();
    this.externalWindow.close();
    toast.update(this.sharingToast, {
      render: 'Something Went wrong',
      autoClose: 3000,
    });

    clearInterval(this.oauthInterval);
  }

  onWindowUnload = () => {};

  receiveMessage = (event) => {
    const { data, timestamp } = event;

    if (data.source === 'oauth-share') {
      this.setState({
        receiveMessage: true,
      });

      if (timestamp > 0 && timestamp - this.timestamp < 1000) {
        return false;
      }

      this.timestamp = timestamp;

      const { link, shareStatus } = this.props;

      const params = new URLSearchParams(data.payload);
      const code = params.get('code');

      apiRequest('POST', '/v1/oauth/post/linkedin', {
        code,
        link,
      })
        .then(() => {
          shareStatus(true);
          toast.update(this.sharingToast, {
            render: Messages.postShared,
            autoClose: 1000,
          });
          ReactGA.pageview('/ap/linkedinshare');
        })
        .catch((err) => {
          shareStatus(false);
          toast.update(this.sharingToast, {
            render: err.message || 'Something Went wrong',
            autoClose: 1000,
          });
        });
    }
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.containerEl);
  }
}

export default Oauthpop;
