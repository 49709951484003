import React from 'react';
import styles from './UserCard.module.scss';
import { User } from '../../user-card/user';
import countryIcon from '../../assets/location.svg';
import worldIcon from '../../assets/world.svg';
import LanguageItem from './LanguageItem';
import SkillItem from './SkillItem';
import { Button } from '../button';

interface NativeUserCardProps {
	user: User;
}

export function LegacyNativeUserCard(props: NativeUserCardProps): JSX.Element {
	const { user } = props;

	return (
		<a href={`/${user.username}`} className={styles.userCardContainer}>
			<div className={styles.userCardTop}>
				<div className={styles.userInfo}>
					<div className={styles.image}>
						<img
							src={user.primaryImg}
							data-testid="ui-native-user-card-profile-img"
							alt="User image"
						/>
					</div>
					<div className={styles.userDetails}>
						<div
							className={styles.userName}
							data-testid="ui-native-user-card-user-name"
						>
							{user.firstName} {user.lastName}
						</div>
						<div
							className={styles.headline}
							title={user.headline}
							data-testid="ui-native-user-card-headline"
						>
							{user.headline}
						</div>
					</div>
				</div>
				<div className={styles.locationInfo}>
					<div className={styles.country}>
						<img src={countryIcon} alt="Country" />
						<p className={styles.countryName}>
							{user.country.countryName}
						</p>
					</div>
					<div className={styles.language}>
						{user.languages && (
							<>
								<img src={worldIcon} alt="Language" />
								<div className={styles.languages}>
									{user.languages.map(
										(lang, index, languages) => (
											<LanguageItem
												key={`lang-li-${lang.code}`}
												language={`${lang.language}${
													index !==
													languages.length - 1
														? ','
														: ''
												}`}
											/>
										)
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className={styles.skills}>
				{user.skills &&
					user.skills.map((skill, index) => (
						<SkillItem key={`skill-li-${index}`} skill={skill} />
					))}
			</div>
			<div className={styles.userCardBottom}>
				<div
					className={styles.hourlyRate}
					data-testid="ui-native-user-card-hourly-rate"
				>
					{user.hourlyRateWithSymbol}/hour
				</div>
				<div>
					<Button
						data-testid="ui-native-user-card-booking-btn"
						className={
							user.isFreeSessionOpted &&
							!user.isFreeSessionConsumed
								? `${styles.actionBtn} ${styles.bookingBtn}`
								: styles.actionBtn
						}
					>
						{user.isFreeSessionOpted && !user.isFreeSessionConsumed
							? 'FREE BOOKING'
							: 'BOOK'}
					</Button>
				</div>
			</div>
		</a>
	);
}

LegacyNativeUserCard.displayName = 'User Card';
