import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import footerbg from '../Common/assets/footer.png';
import linkedinico from '../Common/assets/linkedin.svg';
import twitterico from '../Common/assets/twitter.svg';
import blogIco from '../Common/assets/blog_icon.svg';
import socialyoutubedefault from '../Common/assets/social_youtube_default.svg';
import fbico from '../Common/assets/fb.svg';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: true,
    };
  }

  componentDidMount() {
    const { router } = this.props;
    if (router.location.pathname) {
      this.hideFooter(router.location.pathname);
    }
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (router.location.pathname !== prevProps.router.location.pathname) {
      this.hideFooter(router.location.pathname);
    }
  }

  hideFooter = (pathname) => {
    const whitelistUrl = ['/session', '/session/call', '/internal/session'];
    const { footer } = this.state;

    const isWhitelistUrl = whitelistUrl.some((url) => url === pathname && footer === true);

    if (isWhitelistUrl) {
      this.setState({ footer: false });
    }
  };

  render() {
    const { footer } = this.state;
    return (
      <>
        {footer && (
          <footer
            className={styles.footer}
            ref={(el) => {
              if (el) {
                el.style.setProperty('background-image', `url(${footerbg})`, 'important');
              }
            }}
          >
            <div className={styles.fmenu}>
              <div className={styles.fmlinks}>
                <p>Company</p>
                <Link aria-label="About Us" to="/company/aboutus">
                  About Us
                </Link>
                <Link aria-label="Contact" to="/company/contact">
                  Contact
                </Link>
                <Link aria-label="Costs and Billing" to="/company/costs-billing">
                  Costs and Billing
                </Link>
                <a aria-label="Costs and Billing" href="https://blog.conrati.com/" target="_open">
                  Blog
                </a>
              </div>
              <div className={styles.fmlinks}>
                <p>Help</p>
                <Link aria-label="How it works" to="/help/how-it-works">
                  How It Works
                </Link>
                <Link aria-label="FAQ" to="/help/faq">
                  FAQ
                </Link>
                <Link aria-label="Trust and Safety" to="/help/trust-and-safety">
                  Trust and Safety
                </Link>
              </div>
              <div className={styles.fmlinks}>
                <p>LEGALITIES</p>
                <Link aria-label="Privacy Policy" to="/legal/privacy-policy">
                  Privacy Policy
                </Link>
                <Link aria-label="Terms and Condtion" to="/legal/terms-and-condtions">
                  Terms and Conditions
                </Link>
                <Link aria-label="Code of conduct" to="/legal/code-of-conduct">
                  Code of Conduct
                </Link>
              </div>
              <div className={styles.fmsocial}>
                <p>Connect With Us</p>
                <div className={styles.fmico}>
                  <a
                    aria-label="blog"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://blog.conrati.com"
                    className={styles.fmicol}
                  >
                    <div className={styles.fmimg}>
                      <img src={blogIco} alt="" width="85%" height="85%" />
                    </div>
                  </a>
                  <a
                    aria-label="Linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/conrati"
                    className={styles.fmicol}
                  >
                    <div className={styles.fmimg}>
                      <img src={linkedinico} alt="" width="45%" height="45%" />
                    </div>
                  </a>
                  <a
                    aria-label="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/conrati"
                    className={styles.fmicol}
                  >
                    <div className={styles.fmimg}>
                      <img src={fbico} alt="" width="45%" height="45%" />
                    </div>
                  </a>
                  <a
                    aria-label="Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/ondemandconsult"
                    className={styles.fmicol}
                  >
                    <div className={styles.fmimg}>
                      <img src={twitterico} alt="" width="45%" height="45%" />
                    </div>
                  </a>
                  <a
                    aria-label="Youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCEt8PGpymBFZXFPIufyn6xg"
                    className={styles.fmicol}
                  >
                    <div className={styles.fmimg}>
                      <img src={socialyoutubedefault} alt="" width="100%" height="100%" />
                    </div>
                  </a>
                </div>
                <p className={styles.fcopy}>&copy; Copyright Conrati {moment().format('YYYY')}</p>
              </div>
            </div>
          </footer>
        )}
      </>
    );
  }
}

Footer.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    router: state.router,
  };
};

export default connect(mapStateToProps, null)(Footer);
