import React from 'react';
import styles from '../Static.module.css';
import aboutimage from '../../Common/assets/about_image.png';
import cx from 'classnames';
import YouTube from 'react-youtube';

const aboutus = () => {
  return (
    <div className={styles.brpage}>
      <div className={styles.brheader}>
        <h1>Let's introduce ourselves</h1>
      </div>
      <div className={styles.brcontainer}>
        <div className={styles.abtitles}>
          <p className={styles.abt}>Who and what is Conrati?</p>
          <p className={styles.abtsub}>
            We are an established, young and vibrant software company made up of a cross-functional
            team, spread across South Africa, Germany, India and France.{' '}
          </p>
          <p className={styles.abtsub} style={{ marginTop: '10px' }}>
            Conrati is a marketplace for consulting by the hour. Join the fast-lane of modern
            consulting!  From calendar integration to bookings, video consultations, automated
            invoicing, payments and more.{' '}
          </p>
          <YouTube
            videoId="o0X_xJU7LUE"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                autoplay: 0,
                rel: 0,
                modestbranding: 1,
              },
            }}
            containerClassName={styles.ytboxpl}
          />
        </div>
        <div className={styles.abtitlem}>
          <p className={styles.abt}>The name?</p>
          <p className={styles.abtsub}>
            The name ‘Conrad’ has a German origin - and carries the meaning of ‘the good Advisor’
            and ‘the Advisor with courage’. Providing ‘good advice’ is the service rendered by every
            professional business consultant. As a firm we act with courage - enabling consulting on
            demand across borders!{' '}
          </p>
        </div>
        <div className={styles.abimg}>
          <img src={aboutimage} alt="About Us" />
        </div>
        <div className={cx(styles.brrow, styles.mb3)}>
          <div className={styles.abtitles}>
            <p className={styles.abt}>Who can I find on Conrati?</p>
            <p className={styles.abtsub}>
              On Conrati you find a range of industry leaders, covering categories such as business
              coaches and mentors to business strategist, and many more in between. Our search
              functionality uses data science to highlight consultants based on their skills and
              working experience, helping you find the perfect match.
            </p>
            <p className={styles.abtsub} style={{ marginTop: '10px' }}>
              Use the search and filtering system to find the right skills, industries, rates and
              availability for your project needs. Browse through the resulting profiles to discover
              the niche details that each consultant has to offer. Use Conrati’s video and chat
              session to communicate. Send and receive files. Deliver digital assets in a secure
              environment.
            </p>
          </div>
        </div>
        <div className={cx(styles.brhr, styles.mt3)}></div>
        <div className={cx(styles.mt3, styles.mb3)}>
          <div className={styles.abtitles}>
            <p className={styles.abt}>How can Conrati help me?</p>
            <p className={styles.abtsub}>
              Procuring a consultant is usually a hassle. Trying to, first of all, find the exact
              consultant for your needs with the correct skillset, trying to secure a booking time
              without the back and forth, invoicing and payment headaches. Not to mention any agency
              costs and then still identifying a mutual way of meeting in person or via video
              conference.
            </p>
            <p className={styles.abtsub} style={{ marginTop: '10px' }}>
              Conrati solves all these difficulties and provides a bridge between the advice
              provider and advice receiver.
            </p>
            <p className={styles.abtsub} style={{ marginTop: '20px', marginBottom: '20px' }}>
              The platform includes:{' '}
            </p>
            <p className={styles.abtsub}>INTEGRATED CALENDARS</p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Which allow for easy access to advice providers availability and the benefit of
              inserting bookings in advice providers and advice receivers calendars.
            </p>
            <p className={styles.abtsub}>PINPOINT SEARCH ACCURACY</p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Advice receivers search for an advice providers based on availability, skills,
              language, certifications, price and so forth.
            </p>
            <p className={styles.abtsub}>BOOKING FUNCTIONALITY </p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Advice receivers book advice providers at an hourly rate.
            </p>
            <p className={styles.abtsub}>
              AUTOMATED INVOICING (with automatic cross-border vat determination)
            </p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Our app automatically raises an invoice on the advice provider's behalf and calculates
              the cross border vat determination that may exist between the advice provider and
              advice receiver. Don’t worry yourself on the complex international tax laws of tax,
              Conrati has you covered.
            </p>
            <p className={styles.abtsub}>BUILT-IN VIDEO CONSULTATION</p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Video consultation technology during the scheduled booking allows users to fulfil
              their consultation remotely with text chat, file and screen sharing functionality.
            </p>
            <p className={styles.abtsub}>AUTOMATED PAYMENTS</p>
            <p className={styles.abtsub} style={{ marginBottom: '16px' }}>
              Payments follow a successful consultation which means consultants do not have to worry
              about chasing payments. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default aboutus;
