import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@conrati/ui';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  linkedinRegister,
  linkedinSignIn,
  googleRegister,
  socialSignIn,
  connectCalendar,
} from '../../../actions/userAction';

import styles from './Oauth.module.css';
import whitelogo from '../../Common/assets/whiteyellow-logo.png';
import Loader from '../../Common/Loader/Loader';
import { apiurl, weburl } from '../../../utils';
import enums from '../../../config/enum';
import messages from '../../../config/message';

class Oauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      loading: false,
    };
  }

  async componentDidMount() {
    const {
      location,
      user,
      onLinkedInRegister,
      onGoogleRegister,
      onCaledarConnect,
      onSocialSignIn,
      onLinkedInSignIn,
      history,
    } = this.props;
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const errorCancel = params.get('error_description') || params.get('error');
    const error = params.has('error');

    if (errorCancel) {
      if (user.oauthData.urlfrom === '/signin') {
        return history.replace('/signin');
      }

      if (user.oauthData.urlfrom === '/register') {
        return history.replace('/register');
      }
    }

    if (code) {
      try {
        if (user.oauthData.urlfrom === '/register') {
          if (location.pathname === '/signin/oauth/linkedin') {
            onLinkedInRegister(code, user.user.userType);
          }

          if (location.pathname === '/signin/oauth/google') {
            onGoogleRegister(code, user.user.userType);
          }
        }

        if (user.oauthData.urlfrom === '/myaccount') {
          if (location.pathname === '/signin/oauth/google') {
            this.setState({ loading: true });
            onCaledarConnect(code, user.user.userId, enums.oauthType.Google, user.user.userToken);
          }

          if (location.pathname === '/signin/oauth/microsoft') {
            this.setState({ loading: true });
            onCaledarConnect(
              code,
              user.user.userId,
              enums.oauthType.Microsoft,
              user.user.userToken
            );
          }
        }

        if (user.oauthData.urlfrom === '/myprofile/edit/step') {
          if (location.pathname === '/signin/oauth/google') {
            this.setState({ loading: true });

            if (window.opener) {
              const message = {
                source: 'oauth-code',
                payload: {
                  code,
                  user_id: user.user.userId,
                  oauth_type: enums.oauthType.Google,
                },
              };

              window.opener.postMessage(message, weburl);
            }

            window.close();
          }

          if (location.pathname === '/signin/oauth/microsoft') {
            this.setState({ loading: true });

            if (window.opener) {
              const message = {
                source: 'oauth-code',
                payload: {
                  code,
                  user_id: user.user.userId,
                  oauth_type: enums.oauthType.Microsoft,
                },
              };
              window.opener.postMessage(message, weburl);
            }

            window.close();
          }
        }

        if (user.oauthData.urlfrom === '/signin') {
          if (location.pathname === '/signin/oauth/linkedin') {
            onLinkedInSignIn(code);
          }

          if (location.pathname === '/signin/oauth/google') {
            try {
              const apiresult = await axios.post(
                `${apiurl}/v1/oauth/token/google/code`,
                { code },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
              if (apiresult.data.success) {
                this.setState({ loading: false });
                onSocialSignIn(apiresult.data.result[0].socialId);
              } else {
                this.setState({
                  error: true,
                  errorMessage: 'Your social login has failed',
                  loading: false,
                });
              }
            } catch (err) {
              this.setState({
                error: true,
                errorMessage: 'Your social login has failed',
                loading: false,
              });
            }
          }
        }
      } catch (err) {}
    }

    if (error) {
      this.setState({
        error: true,
        errorMessage: errorCancel === 'access_denied' ? messages.accessDenied : messages.error,
      });
    }
  }

  actionBtn(message) {
    const actionObj = {
      message: 'Please try again',
      src: '/signin',
    };

    if (message.toLowerCase().includes('please register')) {
      actionObj.src = '/register';
      actionObj.message = 'Please register';
    } else if (message.toLowerCase().includes('please sign in')) {
      actionObj.message = 'Please sign in';
    }

    return <Button href={actionObj.src}>{actionObj.message}</Button>;
  }

  render() {
    const { request } = this.props;
    const { loading, error, errorMessage } = this.state;
    return (
      <div>
        <header className={styles.aheader}>
          <Link to="/" className={styles.alink}>
            <img src={whitelogo} width="158px" alt="" />
          </Link>
        </header>
        <div className={styles.atab}>
          {request.loading || loading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            ''
          )}

          {error && !request.loading ? (
            <div className={styles.oerror}>
              <p>{errorMessage}</p>
              <div className={styles.tryAgainBtn}>{this.actionBtn(errorMessage)}</div>
            </div>
          ) : (
            ''
          )}
          {!request.loading && request.error ? (
            <div className={styles.oerror}>
              <p>{request.errorObject.message ? request.errorObject.message : ''}</p>
              <div className={styles.tryAgainBtn}>
                {this.actionBtn(request.errorObject.message ? request.errorObject.message : '')}
              </div>
            </div>
          ) : (
            ''
          )}
          {request.loading ? (
            <div className={styles.oloading}>
              <p>{request.loading ? 'Setting Up Your Social Account' : ''}</p>
            </div>
          ) : (
            ''
          )}
          {request.success && !request.error && !error ? (
            <div className={styles.oloading}>
              <p>{request.success ? 'Your social registration was successful' : ''}</p>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

Oauth.propTypes = {
  location: PropTypes.shape({
    replace: PropTypes.func,
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    user: PropTypes.object,
    oauthData: PropTypes.object,
  }).isRequired,
  request: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorObject: PropTypes.object,
    success: PropTypes.bool,
  }).isRequired,
  onLinkedInRegister: PropTypes.func.isRequired,
  onGoogleRegister: PropTypes.func.isRequired,
  onCaledarConnect: PropTypes.func.isRequired,
  onSocialSignIn: PropTypes.func.isRequired,
  onLinkedInSignIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.userState,
    request: state.request,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLinkedInRegister: linkedinRegister,
      onLinkedInSignIn: linkedinSignIn,
      onGoogleRegister: googleRegister,
      onSocialSignIn: socialSignIn,
      onCaledarConnect: connectCalendar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionToProps)(Oauth);
