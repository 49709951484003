import React, { Component } from 'react';
import { toast } from 'react-toastify';
import cx from 'classnames';
import styles from '../Myaccount.module.css';
import calendar_ico from '../../Common/assets/calendar.svg';
import calendar_google from '../../Common/assets/calendar_google.png';
import calendar_exchange from '../../Common/assets/calendar_exchange.png';
import selected_ico from '../../Common/assets/circle_selected.svg';
import { apiurl } from '../../../utils';
import enums from '../../../config/enum';
import EditTooltip from '../../Common/EditTooltip/EditTooltip';

class CalendarDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  OnGoogleConnect = () => {
    const { user } = this.props;

    if (!user.primaryImg && user.userType !== 2) {
      return toast.warn('Please upload a profile picture to continue.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (!user.isEmailVerified) {
      return toast.warn('Please verify your email address to continue.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    // if (!this.props.user.social.isGoogleConnected && !this.props.user.social.isMicrosoftConnected) {
    //     this.props.onSignInclickOauth('/myaccount');
    //     return window.location.replace(`${apiurl}/v1/oauth/auth/google`);
    // }

    // if (this.props.user.social.oauthTokenStatus === enums.oauthTokenStatus.INVALID && this.props.user.social.isGoogleConnected) {
    //     this.props.onSignInclickOauth('/myaccount');
    //     return window.location.replace(`${apiurl}/v1/oauth/auth/google`);
    // }

    this.props.onSignInclickOauth('/myaccount');
    return window.location.replace(`${apiurl}/v1/oauth/auth/google`);
  };

  onMicrosoftConnect = () => {
    const { user } = this.props;

    if (!user.primaryImg && user.userType !== 2) {
      return toast.warn('Please upload a profile picture to continue.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (!user.isEmailVerified) {
      return toast.warn('Please verify your email address to continue.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    // if (!this.props.user.social.isGoogleConnected && !this.props.user.social.isMicrosoftConnected) {
    //     this.props.onSignInclickOauth('/myaccount');
    //     return window.location.replace(`${apiurl}/v1/oauth/auth/microsoft`);
    // }

    // if (this.props.user.social.oauthTokenStatus === enums.oauthTokenStatus.INVALID && this.props.user.social.isMicrosoftConnected) {
    //     this.props.onSignInclickOauth('/myaccount');
    //     return window.location.replace(`${apiurl}/v1/oauth/auth/microsoft`);
    // }

    this.props.onSignInclickOauth('/myaccount');
    return window.location.replace(`${apiurl}/v1/oauth/auth/microsoft`);
  };

  onTooltipClick = () => {
    if (!this.state.edit) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    return this.setState({ edit: !this.state.edit });
  };

  handleOutsideClick = (e) => {
    if (this.editelem && this.editelem.contains(e.target)) {
      return;
    }

    this.onTooltipClick();
  };

  render() {
    const { social } = this.props.user;

    return (
      <div
        className={this.state.edit ? cx(styles.personal, styles.cardborder) : styles.personal}
        ref={(editelem) => {
          this.editelem = editelem;
        }}
      >
        <div className={styles.ahead}>
          <img className={styles.aheadimg} src={calendar_ico} alt="calendar" />
          <p className={styles.atitle}>Calendar Settings</p>
          {this.props.user.userType === enums.userType.ap && (
            <p className={styles.rerrorc}>* Required</p>
          )}

          <div className={styles.tooltip}>
            <EditTooltip onClick={this.onTooltipClick} active={this.state.edit} />
          </div>
        </div>
        {this.state.edit ? (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <p className={styles.alisub}>
                Update your preferred calendar that you already use for all your scheduled
                appointments. This will allow a two-way synchronisation between your newly assigned
                Conrati calendar and your connected personal calendar.
              </p>
              <div className={styles.calendarli}>
                <button className={styles.calndarbtn} onClick={this.OnGoogleConnect}>
                  <img src={calendar_google} alt="Google Calndar" />
                </button>
                <button className={styles.calndarbtn} onClick={this.onMicrosoftConnect}>
                  <img src={calendar_exchange} alt="Exchange Calndar" />
                </button>
              </div>
              <p className={styles.alisubc}>
                Personal calendar information will remain private and will not be seen by anyone
                else but you.
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.acbody}>
            <div className={styles.alitem}>
              <p className={styles.alisub}>
                {social && social.oauthTokenStatus === enums.oauthTokenStatus.INVALID
                  ? "Your calendar's token has expired. Please re-select the calendar you use and re-authorise the account"
                  : `Choose a preferred calendar that you already use for all your scheduled
                  appointments`}
                . This will allow a two-way synchronisation between your newly assigned Conrati
                calendar and your connected personal calendar.
              </p>
              <div className={styles.calendarli}>
                <button className={styles.calndarbtnn}>
                  <img src={calendar_google} alt="Google Calndar" />
                  {social &&
                    social.isGoogleConnected &&
                    social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                      <div className={styles.cselected}>
                        <img src={selected_ico} alt="Selected" width="17px" height="17px" />
                      </div>
                    )}
                </button>
                <button className={styles.calndarbtnn}>
                  <img src={calendar_exchange} alt="Exchange Calndar" />
                  {social &&
                    social.isMicrosoftConnected &&
                    social.oauthTokenStatus === enums.oauthTokenStatus.VALID && (
                      <div className={styles.cselected}>
                        <img src={selected_ico} alt="Selected" width="17px" height="17px" />
                      </div>
                    )}
                </button>
              </div>
              <div className={styles.emailc}>
                <div>
                  <p className={styles.emailct}>Email address associated with your calendar</p>
                  {social && social.oauthTokenStatus === enums.oauthTokenStatus.INVALID ? (
                    <p className={styles.alisub}>
                      {social && social.email
                        ? `${social.email} (EXPIRED)`
                        : 'Calendar Not Connected'}
                    </p>
                  ) : (
                    <p className={styles.alisub}>
                      {social && social.email ? social.email : 'Calendar Not Connected'}
                    </p>
                  )}
                </div>
              </div>
              <p className={styles.alisub}>
                Personal calendar information will remain private and will not be seen by anyone
                else but you.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CalendarDetails;
