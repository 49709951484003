/* eslint-disable */
import React, { Component } from 'react';
import styles from '../Myaccount.module.css';
import EditInput from '../../Common/EditInput/EditInput';
import EditSelect from '../../Common/EditSelect/EditSelect';
import EditRadio from '../../Common/EditRadio/EditRadio';
import enums from '../../../config/enum';

function ConsumerForm(props) {
  const { parentState, user, onChange, onCheck, onCheckSmallExempt, onSelActive, onSelectChange } =
    props;
  const { address, city, postalCode } = parentState;
  const userCountry = user.countryId;

  const countries = props.countries.map((country) => ({
    value: country.countryId,
    label: country.countryName,
  }));

  const selectedCountry = userCountry
    ? countries.filter((arr) => {
        return arr.value === userCountry;
      })
    : null;

  return (
    <>
      <div className={styles.acedit}>
        <EditInput
          htmlFor="Address"
          label="Address"
          value={address}
          placeholder="Address"
          onChange={onChange}
          name="address"
          required
        />
        <EditInput
          htmlFor="City"
          label="City"
          value={city}
          placeholder="City"
          onChange={onChange}
          name="city"
          required
        />
        <EditInput
          htmlFor="Postal Code"
          label="Postal Code"
          value={postalCode}
          placeholder="Postal Code"
          onChange={onChange}
          name="postalCode"
          required
        />
        <EditSelect
          label="Country"
          options={countries}
          selected={selectedCountry}
          placeholder="Select Country"
          onChange={(e) => onSelectChange(e, 'country')}
          onActive={onSelActive}
          isDisabled={!!selectedCountry}
        />
      </div>
    </>
  );
}

export default ConsumerForm;
