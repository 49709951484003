export const searchHeroSectionChips = [
	'Depressed',
	'Anxiety',
	'Burnout',
	'Trauma',
	'ADHD',
	'Couples Counsellor',
	'Psychologist',
	'Counsellor',
];

export const consultationTopics = [
	'Depression',
	'Postpartum Depression',
	'Anxiety',
	'Social Anxiety',
	'Bipolar',
	'ADHD',
	'OCD',
	'Panic Disorder',
	'Insomnia',
	'Burnout',
	'Psycho-somatic',
	'PTSD',
];

// Usernames to present user card in therapist in action
export const therapistUserNames: string[] = [
	'arturkuligowski1',
	'paulakingwill1',
	'marinapretorius1',
];

const therapistUserNamesDev: string[] = [
	'peterstephon1',
	'harmansmith1',
];

// Get therapist user names
export function getTherapistUserNames(): string[] {
	if (process.env.REACT_APP_STAGE === 'production') {
		return therapistUserNames;
	}

	return therapistUserNamesDev;
}
