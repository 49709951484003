import React from 'react';
import PropTypes from 'prop-types';
import styles from './PaymentPage.module.css';

export default function BookingSessionDetails(props) {
  return (
    <>
      <div className={styles.bookingDetailItem}>
        <div className={styles.bookingDetailTitle}>Selected Special Offer</div>
        <div className={styles.bookingDetailDescription}>{props.offerDescription}</div>
      </div>

      <div className={styles.bookingDetailItem}>
        <div className={styles.bookingDetailTitle}>Description</div>
        <div className={styles.bookingDetailDescription}>{props.bookingDescription}</div>
      </div>

      <div className={styles.bookingDetailItem}>
        <div className={styles.bookingDetailTitle}>Video Call Length</div>
        <div className={styles.bookingDetailDescription}>{props.videoCallLength}</div>
      </div>

      <div className={styles.bookingDetailItem}>
        <div className={styles.bookingDetailTitle}>Amount</div>
        <div className={styles.bookingDetailDescription}>
          {props.currencyCode} {props.amountText}
        </div>
      </div>
    </>
  );
}

BookingSessionDetails.propTypes = {
  offerDescription: PropTypes.string.isRequired,
  bookingDescription: PropTypes.string.isRequired,
  videoCallLength: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};
