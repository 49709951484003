import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Register.module.css';
import Input from '../../Common/Input/Input';
import checkbox from '../../Common/assets/check.svg';
import Email from '../Email/Email';
import enums from '../../../config/enum';
import { apiurl, alert, isValidEmail, isValidPassword } from '../../../utils';
import linkedico from '../../Common/assets/linked-color.svg';
import googleico from '../../Common/assets/google.svg';
import messages from '../../../config/message';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      ap: false,
      ar: false,
      userType: 0,
      verify: false,
      passwordvalid: false,
      focus: false,
    };
  }

  componentDidMount() {
    this.getUrlParams();
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;
    if (
      prevProps.userState.user.email !== userState.user.email &&
      !userState.user.isEmailVerified
    ) {
      this.switchToVerify();
    }
  }

  getUrlParams = () => {
    const { location } = this.props;
    const serchparams = new URLSearchParams(location.search);
    if (serchparams.has('redirect') && serchparams.get('redirect') === 'booking') {
      this.setState({ ar: true, ap: false });
      this.setState({ userType: enums.userType.ar });
    }

    if (serchparams.has('user_type')) {
      const type = serchparams.get('user_type');
      if (type === 'ar') {
        this.setState({ ar: true, ap: false });
        this.setState({ userType: enums.userType.ar });
      }

      if (type === 'ap') {
        this.setState({ ar: false, ap: true });
        this.setState({ userType: enums.userType.ap });
      }
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'password') {
      if (isValidPassword(e.target.value)) {
        this.setState({ passwordvalid: true });
      } else {
        this.setState({ passwordvalid: false });
      }
    }
  };

  handleSubmit = () => {
    const { email, password, userType } = this.state;
    const { onRegisterUser } = this.props;
    if (isValidEmail(email) && isValidPassword(password) && password && userType > 0) {
      onRegisterUser({
        user_type: userType,
        login_type: enums.loginType.email,
        email,
        password,
      });
    }
  };

  handleCheckBox = (type) => {
    if (type === 'ar') {
      this.setState({ ar: true, ap: false });
      this.setState({ userType: enums.userType.ar });
    }

    if (type === 'ap') {
      this.setState({ ar: false, ap: true });
      this.setState({ userType: enums.userType.ap });
    }
  };

  handleSocial = (type) => {
    const { userType } = this.state;
    const { onUserOauthData, onSocialRegisterUserType } = this.props;
    if (userType > 0) {
      onUserOauthData('/register');
      onSocialRegisterUserType(userType);
      if (type === enums.oauthType.linkedin) {
        return window.location.replace(`${apiurl}/v1/oauth/auth/linkedin`);
      }

      if (type === enums.oauthType.Google) {
        return window.location.replace(`${apiurl}/v1/oauth/auth/google`);
      }

      return 'Rediredted to oauth page';
    }
    return alert(messages.alertAccountType);
  };

  handleSkip = () => {
    const { history } = this.props;
    history.replace('/search');
  };

  handleFocus = (focus) => {
    if (focus) {
      this.setState({ focus: true });
    }
  };

  registerComponent = () => {
    const rchecked = cx(styles.rchecked, styles.rcbox);
    const { ap, ar, passwordvalid, focus, email, password, userType } = this.state;
    return (
      <>
        <div className={styles.rinfo}>
          <p>Register a new account</p>
        </div>
        <div className={styles.rcheck}>
          <div className={styles.rchecki}>
            <div className={styles.rap}>
              <div
                className={ap ? rchecked : styles.rcbox}
                onClick={() => this.handleCheckBox('ap')}
                onKeyPress={() => console.log('Key press')}
                role="button"
                tabIndex="0"
              >
                {ap ? <img src={checkbox} alt="checked" width="15px" height="15px" /> : ''}
              </div>
              <div className={styles.rtext}>
                <p className={styles.rtexth}>Advice Provider</p>
                <p className={styles.rtexts}>Consultant</p>
              </div>
            </div>
            <div className={styles.rap}>
              <div
                className={ar ? rchecked : styles.rcbox}
                onClick={() => this.handleCheckBox('ar')}
                onKeyPress={() => console.log('Key press')}
                role="button"
                tabIndex="0"
              >
                {ar ? <img src={checkbox} alt="checked" width="15px" height="15px" /> : ''}
              </div>
              <div className={styles.rtext}>
                <p className={styles.rtexth}>Advice Receiver</p>
                <p className={styles.rtexts}>Advisee</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ror}>or</div>
        <div className={styles.sbtnbox}>
          <div className={styles.sbtn}>
            <button
              type="button"
              className={styles.sbtnc}
              onClick={() => this.handleSocial(enums.oauthType.linkedin)}
            >
              <img src={linkedico} alt="" width="22px" />
              <p className={styles.sbtntext}>Register with LinkedIn</p>
            </button>
            <button
              type="button"
              className={styles.sbtnc}
              onClick={() => this.handleSocial(enums.oauthType.Google)}
            >
              <img src={googleico} alt="" width="22px" />
              <p className={styles.sbtntext}>Register with Google</p>
            </button>
          </div>
          <div className={styles.sbtnemail}>
            <p className={styles.sbtntitle}>Use your email address</p>
            <div className={styles.rform}>
              <Input
                type="email"
                name="email"
                htmlFor="Email"
                label="Email address"
                placeholder="Email address"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
              />
              <Input
                type="password"
                name="password"
                htmlFor="Password"
                label="Password"
                placeholder="Password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                tooltip={!passwordvalid}
                tooltipmsg="Password must contain minimum 6 characters, including at least 1 uppercase letter, 1 lowercase letter and 1 number or symbol."
              />
            </div>
          </div>
        </div>
        {focus && (
          <div className={styles.rterms}>
            <p className={styles.rbold}>Using an email address to register ?</p>
            <p>
              For best results use your preferred email address as important information regarding
              bookings and payments will be sent to this address.
            </p>
          </div>
        )}
        <div className={styles.rterms}>
          <p>
            By creating an account, you agree to Conrati’s{' '}
            <a target="_blank" rel="noopener noreferrer" href="/legal/terms-and-condtions">
              Terms and Conditions
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="/legal/code-of-conduct">
              Code of Conduct
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="/legal/privacy-policy">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="/company/costs-billing">
              Costs and Billing.
            </a>
          </p>
        </div>
        <div className={styles.snext}>
          <button
            type="button"
            disabled={!(isValidPassword(password) && isValidEmail(email) && userType > 0)}
            className={styles.snextbtn}
            onClick={this.handleSubmit}
          >
            Next
          </button>
        </div>
      </>
    );
  };

  switchToVerify() {
    this.setState({ verify: true });
  }

  render() {
    const { verify } = this.state;
    const { userState, history } = this.props;
    return (
      <>
        <div className={styles.signin}>
          {verify ? (
            <Email
              isLoggedIn={userState.isLoggedIn}
              email={userState.user.email}
              history={history}
            />
          ) : (
            this.registerComponent()
          )}
        </div>
      </>
    );
  }
}

Register.propTypes = {
  userState: PropTypes.shape({
    user: PropTypes.object,
    isLoggedIn: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    replace: PropTypes.func,
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  onUserOauthData: PropTypes.func.isRequired,
  onSocialRegisterUserType: PropTypes.func.isRequired,
};

export default Register;
