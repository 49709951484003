import React from 'react';
import * as Icons from '@conrati/icons';
import { capitalize } from '../utils/naming-convention';

interface IconProps {
	name: string;
	width: number;
	height: number;
	title: string;
}

interface ImageModule {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any; // Using any special case
}

export const Icon = ({
	name,
	width,
	height,
	title,
}: IconProps): JSX.Element => {
	const IconsModules = Icons as unknown as ImageModule;

	const Logo = IconsModules[capitalize(name)];

	return <Logo width={width + 'px'} height={height + 'px'} title={title} />;
};

export default Icon;
