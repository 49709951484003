import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from './EditSelect.module.css';
import { wordCount } from '../../../utils';

class EditSelectMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      required: false,
      options: [],
      note: props.note,
    };
  }

  componentDidMount() {
    const { selected, required, options } = this.props;

    if (selected) {
      this.setState({
        selectedOption: selected,
      });
    }

    if (required && selected.length === 0) {
      this.setState({
        required: true,
      });
    }

    if (options) {
      this.setState({ options });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selected) {
      this.setState({
        selectedOption: newProps.selected,
      });
    }

    if (newProps.options) {
      this.setState({ options: newProps.options });
    }
  }

  handleChange = (selectedOption) => {
    const { onChange, required } = this.props;
    // this.setState({ selectedOption });
    onChange(selectedOption);

    let stateObject = {};

    if (
      (selectedOption === null || (Array.isArray(selectedOption) && selectedOption.length === 0)) &&
      required
    ) {
      stateObject = { required: true };
    } else if (
      this.props.maxWordLimit &&
      Array.isArray(selectedOption) &&
      !(selectedOption.length >= 4 && selectedOption.length <= 10) &&
      required
    ) {
      stateObject = { note: this.props.note, required: true };
    } else {
      stateObject = { required: false, note: '' };
    }

    this.setState(stateObject);
  };

  handleBlur = (e, type) => {
    e.preventDefault();

    const { onActive } = this.props;
    if (type === 1) {
      onActive(1);
    }

    if (type === 2) {
      onActive(2);
    }
  };

  render() {
    const { selectedOption, required, options, note } = this.state;
    const { label, selecttype, placeholder, isDisabled, maxWordLimit } = this.props;

    const customStyles = {
      control: (provided, state) => {
        let controlStyle = {
          ...provided,
          borderRadius: '9px',
          boxShadow: 'none',
          borderColor: state.isFocused ? '#254f89' : '#d4d4d4',
          '&:hover': {
            borderColor: state.isFocused ? '#254f89' : '#d4d4d4',
          },
        };

        if (required) {
          return {
            ...controlStyle,
            borderColor: '#f98181',
            '&:hover': {
              borderColor: '#f98181',
            },
          };
        }

        return controlStyle;
      },
      singleValue: (provided) => {
        const fontSize = '0.8125em';
        const color = '#555';

        return { ...provided, fontSize, color };
      },
      placeholder: (provided) => {
        const fontSize = '0.8125em';
        const color = 'rgb(211, 198, 198);';

        return { ...provided, fontSize, color };
      },
    };

    const isValidNewOption = (inputValue, value) => {
      const count = wordCount(inputValue);

      if (count === 0 && Array.isArray(value) && value.length >= 4 && value.length <= 10) {
        return true;
      }

      if (count > 4) {
        return false;
      }

      return true;
    };

    return (
      <div className={styles.select}>
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
        {selecttype === 'fixed' ? (
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={isDisabled || false}
            onFocus={(e) => this.handleBlur(e, 1)}
            onBlur={(e) => this.handleBlur(e, 2)}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
          />
        ) : (
          <CreatableSelect
            value={selectedOption}
            defaultValue={selectedOption}
            onChange={this.handleChange}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            isDisabled={isDisabled || false}
            onFocus={(e) => this.handleBlur(e, 1)}
            onBlur={(e) => this.handleBlur(e, 2)}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable
            formatCreateLabel={(userInput) => `${userInput}`}
            isValidNewOption={maxWordLimit ? isValidNewOption : true}
          />
        )}
        {required && !note && <p className={styles.error}>{label} Required</p>}
        {required && note && <p className={styles.error}>{note}</p>}
      </div>
    );
  }
}

EditSelectMulti.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onActive: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  selecttype: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  note: PropTypes.string,
  maxWordLimit: PropTypes.bool,
};

EditSelectMulti.defaultProps = {
  isDisabled: false,
  selecttype: '',
  selected: [],
  note: '',
  maxWordLimit: false,
};

export default EditSelectMulti;
