import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

export default function PsychTestResult({ result, resultLong }) {
  return (
    <div className={style.resultContainer}>
      <h1 className={style.resultHeading}>Test Result</h1>
      <div className={style.resultContent}>
        <p className={style.resultShort}>
          YOUR RESULT: <strong>{result}</strong>
        </p>
        <p className={style.resultLong} dangerouslySetInnerHTML={{ __html: resultLong }} />
        <p>
          <strong>
            Conrati offers therapists that you can TALK to about depression. Some therapists offer a
            free first-time session. See more below.
          </strong>
        </p>
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link
          to="/categories/psychologist?utm_source=self&utm_medium=page&utm_campaign=depression_test"
          target="_open"
          className={style.resultButton}
        >
          Find a therapist
        </Link>
      </div> */}
    </div>
  );
}

PsychTestResult.propTypes = {
  result: PropTypes.string.isRequired,
  resultLong: PropTypes.string.isRequired,
};
